/**
 * This file contains components related to authentication and authorization.
 * It includes components for requiring authentication to access certain routes and
 * authenticating routes.
 */

import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { AuthenticationLoader } from 'components/Authentication/AuthenticationLoader';
import { ROUTES } from 'components/navigation/Constants';
import { useAuth } from 'providers/AuthProvider';
import { ManufacturingLocationProvider } from 'providers/ManufacturingLocationProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { getMsalUserInfo, isAzureTokenExpired } from 'shared/helpers/msal.helper';
import { AuthenticationError } from './AuthenticationError';

/**
 * Component to require authentication for accessing certain routes.
 * Redirects to the sign-in page if the user is not logged in.
 * If the user is logged in but doesn't have required information, it shows an authentication error.
 */
export const RequireAuth = () => {
  const location = useLocation();
  const { isLoggedIn, user, signOut } = useAuth();
  const { instance: msalInstance } = useMsal();
  const account = msalInstance.getActiveAccount();

  // Check if the Azure token is expired with a 60-second buffer. This is to ensure that the token is not expired when making graphql requests.
  const isTokenExpired = isAzureTokenExpired(account, 60);

  // Redirect to sign-in page if the user is not logged in or the Azure token is expired.
  if (!isLoggedIn || isTokenExpired) {
    return <Navigate to={ROUTES.SIGN_IN_PATH} state={{ from: location }} replace />;
  }

  // The authorizer will fail if a user doesn't have employeeId, so log out any user without this property set.
  if (!user || !user.employeeId) {
    return <AuthenticationError error={new Error('User configured incorrectly.')} btnAction={() => signOut()} />;
  }

  return (
    <ManufacturingLocationProvider>
      <Outlet />
    </ManufacturingLocationProvider>
  );
};

/**
 * Component to authenticate routes.
 * It fetches user information using MSAL and sets authentication status accordingly.
 */
export const AuthenticateRoute = () => {
  /**
   * Object containing the MSAL instance.
   */
  const { instance: msalInstance } = useMsal();

  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const { setAuth } = useAuth();

  /**
   * Function to fetch user information using MSAL account.
   * Sets authentication status based on the fetched user information.
   * @param account - MSAL account information.
   */
  const fetchMsalUserInfo = useCallback(
    async (account: AccountInfo | null) => {
      try {
        if (!account) {
          setAuth({ user: null, account: null });
          setIsAuthenticating(false);
          return;
        }
        const user = await getMsalUserInfo(msalInstance, account);

        setAuth({ user, account });
      } catch (err) {
        const error = err as Error;
        console.error(error);
        setAuth({ user: null, account: null });
      } finally {
        setIsAuthenticating(false);
      }
    },
    [msalInstance, setAuth]
  );

  const fetchMsalUserInfoRef = useRef(fetchMsalUserInfo);

  /**
   * Effect hook to update the reference of the fetchMsalUserInfo function whenever it changes.
   * @param fetchMsalUserInfo - Function to fetch MSAL user information.
   */
  useEffect(() => {
    fetchMsalUserInfoRef.current = fetchMsalUserInfo;
  }, [fetchMsalUserInfo]);

  /**
   * Effect hook to fetch MSAL active account information and trigger the fetchMsalUserInfo function.
   * It runs whenever the msalInstance changes.
   * @param msalInstance - MSAL instance object.
   */
  useEffect(() => {
    const account = msalInstance.getActiveAccount();
    fetchMsalUserInfoRef.current(account);
  }, [msalInstance]);

  if (isAuthenticating) return <AuthenticationLoader />;
  return <RequireAuth />;
};
