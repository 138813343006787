import { HomeIcon } from '@heroicons/react/24/outline';
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { ROUTES } from 'components/navigation/Constants';
import { useAuth } from 'providers/AuthProvider';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Images from '../../../assets/images';
import LabSelector from './LabSelector';

const { LMSLogo } = Images;

/**
 * Generates a string with the given classes.
 * @param classes - Array of class names.
 * @returns Concatenated string of class names.
 */
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

/**
 * Enum for navigation page names.
 */
enum NavPageNames {
  Home,
  Search,
}

/**
 * Navigation items.
 */
const navigation = [
  { name: NavPageNames.Home, href: ROUTES.ROOT, icon: HomeIcon, current: true },
  { name: NavPageNames.Search, href: ROUTES.SEARCH_PATH, icon: MagnifyingGlassIcon, current: false },
];

/**
 * Sidebar component.
 * @returns JSX Element Sidebar component
 */
const Sidebar: React.FC = () => {
  const [expandSidebar, setExpandSidebar] = useState<boolean>();
  const [currentTab, setCurrentTab] = useState<NavPageNames>(NavPageNames.Home);
  const { pathname } = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    setExpandSidebar(true);
  }, []);

  useEffect(() => {
    setCurrentTab(() => {
      if (
        pathname === ROUTES.ROOT ||
        pathname === ROUTES.DASHBOARD ||
        pathname === ROUTES.SIGN_IN_PATH ||
        pathname === ROUTES.SIGN_IN_SSO_PATH ||
        pathname === ROUTES.SIGN_IN_EMAIL_PATH ||
        pathname === ROUTES.SIGN_OUT_PATH ||
        pathname === ROUTES.REDIRECT_PATH ||
        pathname === ROUTES.INVOICE_ORDER_LOOKUP ||
        pathname === ROUTES.ORDER_ENTRY ||
        pathname === ROUTES.NEW_ORDER
      ) {
        return NavPageNames.Home;
      } else {
        return NavPageNames.Search;
      }
    });
  }, [pathname]);

  return (
    <>
      <div className={`${expandSidebar ? '' : 'hidden'} h-screen flex overflow-hidden bg-gray-100`}>
        {/* Static sidebar for desktop */}
        <div className="hidden bg-indigo-700 md:flex md:flex-shrink-0">
          <div className="flex flex-col w-64">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="absolute ml-2 pl-56 pt-2">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setExpandSidebar(prevState => !prevState)}
                data-qa="collapseSideNavButton"
              >
                <span className="sr-only">Close panel</span>
                <ChevronDoubleLeftIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
            <div className="flex flex-col h-0 flex-1">
              <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
                <div className="flex items-center flex-shrink-0 px-4 justify-center">
                  <img className="w-28 pr-2" src={LMSLogo} alt="LMS" />
                </div>
                <LabSelector />
                <nav className="mt-5 flex-1 px-2 space-y-1">
                  {navigation.map(item => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className={classNames(
                        currentTab === item.name
                          ? 'bg-indigo-800 text-white'
                          : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )}
                      state={{ searchValue: undefined }} // Ensures that the previous search value is cleared whenever a tab is selected (LMS1-4847).
                      onClick={() => setCurrentTab(item.name)}
                    >
                      <item.icon aria-hidden="true" className="mr-3 h-6 w-6 text-indigo-300" />
                      {NavPageNames[item.name]}
                    </Link>
                  ))}
                </nav>
              </div>
              <div className="flex-shrink-0 flex border-t border-indigo-800 p-4">
                <div className="flex-shrink-0 w-full group block">
                  <div className="flex items-center">
                    <div>
                      <img
                        className="h-9 w-9 rounded-full"
                        src={user?.avatar || 'https://avatars.githubusercontent.com/u/25190530?v=4'}
                        alt=""
                      />
                    </div>
                    {user?.displayName && (
                      <div className="ml-3">
                        <div className="text-sm font-medium text-white">{user.displayName}</div>
                        {/* <p className="text-xs font-medium text-indigo-200 group-hover:text-white">View profile</p> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${!expandSidebar ? '' : 'md:hidden'} h-screen flex overflow-hidden`}>
        {/* Static sidebar for desktop */}
        <div className="bg-indigo-700 flex flex-shrink-0 pr-px">
          <div className="flex flex-col w-10">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="absolute pl-3 pt-2 md:block hidden">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => setExpandSidebar(prevState => !prevState)}
                data-qa="expandSideNavButton"
              >
                <span className="sr-only">Close panel</span>
                <ChevronDoubleRightIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            </div>
            <div className="flex-grow">
              <div className="mt-12 px-2">
                <img className="h-7" src={LMSLogo} alt="LMS" />
              </div>
              <nav className="mt-6 flex-1 space-y-1 px-1">
                {navigation.map(item => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      currentTab === item.name
                        ? 'bg-indigo-800 text-white'
                        : 'text-white hover:bg-indigo-600 hover:bg-opacity-75',
                      'group flex items-center py-2 px-1 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon className="h-6 w-6 text-indigo-300" aria-hidden="true" />
                  </Link>
                ))}
              </nav>
            </div>
            <div className="flex-shrink-0 flex border-t border-indigo-800 py-4">
              <Link to="#" className="flex-shrink-0 w-full group block">
                <div className="flex items-center">
                  <div className="mx-auto">
                    <img
                      className="inline-block h-6 w-6 rounded-full"
                      src={user?.avatar || 'https://avatars.githubusercontent.com/u/25190530?v=4'}
                      alt=""
                    />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
