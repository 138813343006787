/**
 * Props for the StandardTitleAndValue component.
 */
interface StandardTitleAndValueProps {
  /**
   * The title to be displayed.
   */
  title: string;
  /**
   * The value to be displayed.
   */
  value: string;
  /**
   * Additional CSS class name for styling.
   */
  className?: string;
}

/**
 * The StandardTitleAndValue component displays a title and its corresponding value in a standard format.
 * @param title - The title to be displayed.
 * @param value - The value to be displayed.
 * @param className - Additional CSS class name for styling.
 * @returns JSX element representing the StandardTitleAndValue component.
 */
export const StandardTitleAndValue: React.FC<StandardTitleAndValueProps> = ({ title, value, className }) => {
  return (
    <div className={className}>
      <div className="text-gray-500 font-medium text-sm mb-1">{title}</div>
      <div className="text-gray-900 font-normal">{value}</div>
    </div>
  );
};
