/**
 * represents available shipping partners
 */
export enum ShippingCarrier {
  FedEx = 'FedEx',
  UPS = 'UPS',
  FTA = 'FTA',
  DoctorPickup = 'Doctor Pickup',
  GSO = 'GSO',
  OnTrac = 'OnTrac',
  USPS = 'USPS',
  LocalPickup = 'Local Pick Up',
}

/**
 * represents various shipping services available
 */
export enum ShippingService {
  SATURDAY_DELIVERY = 'Saturday Delivery',
  EXPRESS = 'Express',
  GROUND = 'Ground',
  NEXT_DAY_AIR_EARLY_AM = 'NextDayAirEarlyAM',
  SIGNATURE_REQUIRED = 'Signature Required',
  FIRST = 'First',
  SECOND_DAY_AIR = '2ndDayAir',
  NEXT_DAY_AIR = 'NextDayAir',
  NOON_PRIORITY_SERVICE = 'NoonPriorityService',
  SUNRISE = 'Sunrise',
  NEXT_DAY_AIR_SAVER = 'NextDayAirSaver',
  PRIORITY = 'Priority',
  CALIFORNIA_PARCEL_SERVICE = 'CaliforniaParcelService',
  COD_FEE = 'COD Fee',
  INTERNATIONAL_ECONOMY = 'INTERNATIONAL_ECONOMY',
  INTERNATIONAL_PRIORITY = 'INTERNATIONAL_PRIORITY',
  INTERNATIONAL_FIRST = 'INTERNATIONAL_FIRST',
  STANDARD_OVERNIGHT = 'STANDARD_OVERNIGHT',
  PRIORITY_OVERNIGHT = 'PRIORITY_OVERNIGHT',
  FIRST_OVERNIGHT = 'FIRST_OVERNIGHT',
  ALASKA = 'Alaska',
  HAWAII = 'Hawaii',
  PUERTO_RICO = 'PuertoRico',
  SECOND_DAY_AIR_ALASKA = '2ndDayAirAlaska',
  SECOND_DAY_AIR_HAWAII = '2ndDayAirHawaii',
  SECOND_DAY_AIR_PUERTO_RICO = '2ndDayAirPuertoRico',
  GROUND_ADVANTAGE = 'GroundAdvantage',
  FEDEX_2_DAY = 'FEDEX_2_DAY',
}

export enum DO_NOT_SHIP_PREFERENCE_ENUM {
  NONE = 'NONE',
  PERMANENT = 'PERMANENT',
  TEMPORARY = 'TEMPORARY',
}
