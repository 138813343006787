/**
 * Props for the TrackingNumberButton component.
 */
interface TrackingNumberButtonProps {
  /**
   * Function to handle the event when the user clicks to show the tracking number input.
   */
  onShowTrackingNumber: () => void;
}

/**
 * Component to display a button for showing the tracking number input.
 * @param onShowTrackingNumber - Function to handle the event when the user clicks to show the tracking number input.
 * @returns JSX element representing the tracking number button.
 */
export const TrackingNumberButton: React.FC<TrackingNumberButtonProps> = ({ onShowTrackingNumber }) => {
  return (
    <button
      className="flex flex-row text-indigo-600 items-center text-sm justify-items-center focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 underline"
      type="button"
      onClick={onShowTrackingNumber}
      data-qa="useExistingTrackingLink"
    >
      Or use existing tracking number
    </button>
  );
};
