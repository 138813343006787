import { useEffect, useRef } from 'react';

/**
 * Hook to help keep track of the previous value of any variable.
 *
 * @example
 * ```ts
 *  const previousValue = usePrevious(currentValue);
 * ```
 */
export const usePrevious = <T,>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
};
