import { RadioOption } from 'shared/models';
import { cn } from 'shared/utils';
import RequiredIndicator from '../RequiredIndicator';
import { ResetButton } from '../ResetButton';

/**
 * Props for the Radio component.
 */
interface RadioProps {
  /** The label for the radio group. */
  label?: string;
  /** The selected value of the radio group. */
  value: string;
  /** The options for the radio group. */
  options: RadioOption[];
  /** Function to handle value change. */
  onValueChange: (value: string) => void;
  /** The id for the radio group. */
  id?: string;
  /** Indicates if the radio group has an error. */
  hasError?: boolean;
  /** Indicates if the radio group is required. */
  isRequired?: boolean;
  /** Indicates if the radio group should have a border. */
  border?: boolean;
  /** Additional CSS classes for styling. */
  className?: string;
  /** Indicates if the radio group is able to reset. */
  isAbleToReset?: boolean;
  /** Function to handle resetting the radio group. */
  onValueReset?: () => void;
  /** Indicates if the radio group is disabled. */
  disabled?: boolean;
  /** Additional CSS class for radio inputs. */
  radioClass?: string;
}

/**
 * Component for rendering a radio group.
 *
 * @param label - The label for the radio group.
 * @param value - The selected value of the radio group.
 * @param options - The options for the radio group.
 * @param onValueChange - Function to handle value change.
 * @param id - The id for the radio group.
 * @param hasError - Indicates if the radio group has an error.
 * @param isRequired - Indicates if the radio group is required.
 * @param border - Indicates if the radio group should have a border.
 * @param className - Additional CSS classes for styling.
 * @param isAbleToReset - Indicates if the radio group is able to reset.
 * @param onValueReset - Function to handle resetting the radio group.
 * @param disabled - Indicates if the radio group is disabled.
 * @param radioClass - Additional CSS class for radio inputs.
 * @returns JSX.Element - The rendered component.
 */
const Radio: React.FC<RadioProps> = ({
  label,
  value,
  options,
  onValueChange,
  id,
  hasError,
  isRequired,
  border = true,
  className,
  isAbleToReset,
  onValueReset,
  disabled,
  radioClass,
}) => {
  const valueChangeHandler = (value: string) => {
    onValueChange(value);
  };

  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} className="ml-1" />;

  return (
    <div>
      {!!label && (
        <label className="text-sm font-medium text-gray-700 flex" htmlFor={id} data-testid="radioLabel">
          {label}
          {resetButton}
          {isRequired && <RequiredIndicator />}
        </label>
      )}
      {!label && resetButton}
      <div
        className={cn(
          className,
          'rounded-md block mt-1 text-sm w-full py-2 bg-white',
          { 'focus:ring-red-500': hasError, 'focus:ring-indigo-500': !hasError },
          { 'px-3 shadow-sm border': border },
          {
            'focus:border-red-500 border-red-500': hasError && border,
            'focus:border-indigo-500': !hasError && border,
          }
        )}
        id={id}
      >
        {options.map(option => (
          <div className="leading-8 flex" key={option.label + option.value}>
            <input
              id={option.label + option.value}
              type="radio"
              defaultValue={option.value}
              name={`${label}-${id}`}
              checked={value === option.value}
              data-testid={option.label + option.value}
              onChange={e => valueChangeHandler(e.target.value)}
              className={cn(radioClass, 'border-gray-300 mr-2 mt-2 mb-2')}
              disabled={disabled}
            />
            <label
              data-testid={`radioLabel_${id}`}
              className="block text-gray-700 text-sm font-medium mt-1"
              htmlFor={option.label + option.value}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Radio;
