import classNames from 'classnames';
import Modal from 'components/common/Modal';
import UploadIcon from 'components/common/UploadIcon';
import { useAuth } from 'providers/AuthProvider';
import React, { useEffect, useState } from 'react';
import { getPresignedUploadUrls } from 'shared/api/preSignedUrls.api';
import { mimeToExtensionMapping } from 'shared/constants/mine-to-extension-mapping.constants';
import { FileUploadState } from 'shared/enums';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { LocalOrderFileAttachments } from 'shared/models';
import ConfigService from 'shared/services/config.service';
import { v4 as uuidv4 } from 'uuid';
import BadFilesModal from '../BadFilesModal';
import FileCell from '../FileCell';

/**
 * Props for the UploadCard component.
 */
interface UploadCardProps {
  /**
   * The ID of the order.
   */
  orderId: string;
  /**
   * Array of file attachments for the order.
   */
  fileAttachments: LocalOrderFileAttachments[];
  /**
   * Callback function triggered when file attachments change.
   */
  onFileAttachmentsChange: (fileAttachments: LocalOrderFileAttachments[]) => void;
}

/**
 * The UploadCard component allows users to upload files for an order.
 * @param orderId - The ID of the order.
 * @param fileAttachments - Array of file attachments for the order.
 * @param onFileAttachmentsChange - Callback function triggered when file attachments change.
 * @returns JSX element representing the UploadCard component.
 */
const UploadCard: React.FC<UploadCardProps> = ({ orderId, fileAttachments, onFileAttachmentsChange }) => {
  const [fileDraggingIn, setFileDraggingIn] = useState<boolean>(false);
  const [showFileErrorModal, setShowFileErrorModal] = useState<boolean>(false);
  const [badFiles, setBadFiles] = useState<File[]>();
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const fileConfigurations = ConfigService.fileConfigurations;
  const validFileUploadSize = fileConfigurations.validFileUploadSize;
  const validFileExtensionsLowercase = fileConfigurations.validFileExtensions;
  const { fetcher } = useLazyQueryFetcher(getPresignedUploadUrls);

  const [attachments, setAttachments] = useState<LocalOrderFileAttachments[]>(fileAttachments); // [LocalOrderFileAttachments]
  const onFileAttachmentsChangeRef = React.useRef(onFileAttachmentsChange);

  useEffect(() => {
    /**
     * Used for setting attachments for existing orders that were still loading when this component was rendered.
     * In all other scenarios, setAttachments should be used to modify what files are loaded and this should propagate to the order.
     * Please be careful if modifying this if statement, since this could potentially cause an infinite loop.
     * Please see LMS1-6247, LMS1-6248, and LMS1-6249.
     */
    if (fileAttachments.length > 0 && attachments.length === 0) {
      setAttachments(fileAttachments);
    }
  }, [attachments.length, fileAttachments]);

  useEffect(() => {
    onFileAttachmentsChangeRef.current = onFileAttachmentsChange;
  }, [onFileAttachmentsChange]);

  useEffect(() => {
    onFileAttachmentsChangeRef.current(attachments); // patch to the order, there's a transformation happening here from Attachment to OrderAttachmentInput
  }, [attachments]);

  /**
   * Callback when a new file is selected by the user
   * @param event - react input change event
   * @returns
   */
  const addFileHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    addFileAttachments(event.target.files);
    event.target.value = '';
  };

  /**
   * Callback when a file is removed
   */
  const removeFileHandler = (uuid: string) => {
    const foundIndex = attachments.findIndex(item => item.id === uuid);
    if (foundIndex > -1) {
      attachments.splice(foundIndex, 1);
      onFileAttachmentsChange(attachments);
      setAttachments(attachments);
    }
  };

  /**
   * Callback when the file is finished uploading
   */
  const uploadFileHandler = (uuid: string) => {
    setAttachments(attachments => {
      const fileAttachments = attachments.map(item => {
        if (item.id === uuid) {
          item.uploadStatus = FileUploadState.Uploaded;
        }
        return item;
      });
      return fileAttachments;
    });
  };

  /**
   * Attempts to map the file extension for a given file. Defaults to using the file name.
   * @param file - The file for which to return the extension.
   * @returns the extension for the target file.
   */
  const getFileExtension = (file: File): string => {
    // Attempts to find the file extension using a mime mapping file.
    let fileExtension = mimeToExtensionMapping[file.type] || '';
    // If no extension is found using the mapping file, tries to parse the file name.
    if (!fileExtension && file.name) {
      const alternativeFileExtension = file.name.split('.').pop();
      fileExtension = alternativeFileExtension ? `.${alternativeFileExtension}` : '';
    }
    // Returns the extension for the target file.
    return fileExtension;
  };

  /**
   * Add array of new files to the list
   * @param files - file list data
   */
  const addFileAttachments = async (files: FileList) => {
    // check if we are allowed to upload these files, ignore the invalid
    const badFiles = findBadFiles(files);
    const filteredFiles = filterInvalidFiles(files);

    // if some bad files exist we show the user that we won't upload them
    if (badFiles.length) {
      showErrorModal(badFiles);
    }

    if (filteredFiles.length) {
      // loop and set up the data we'll use just for the FileCell
      const localAttachments = filteredFiles.map(item => {
        const fileType = item.type ? item.type : item.name.split('.').pop();
        return {
          id: uuidv4(),
          name: item.name,
          size: item.size,
          extension: getFileExtension(item),
          uploadProperties: {
            fileName: item.name,
            filePath: '',
            fileType: fileType,
          },
          file: item,
          fileUrl: '',
          canDelete: true,
          uploadStatus: FileUploadState.Waiting,
          createdBy: currentUserName,
          createdDate: new Date().toISOString(),
        };
      });

      setAttachments(attachments => [...attachments, ...localAttachments]);
      // proceed to get presigned upload urls for valid files
      const preSignedUrls = await fetcher(filteredFiles);

      // add the upload url to the attachment
      preSignedUrls.forEach((url, index) => {
        // count backwards to attach to our new files only
        localAttachments[index].uploadProperties.filePath = url.url;
        localAttachments[index].fileUrl = fileConfigurations.fileDownloadUrl.replace('{0}', url.location);
      });

      setAttachments(attachments => {
        return attachments.map(item => {
          const foundIndex = localAttachments.findIndex(localItem => localItem.id === item.id);
          if (foundIndex >= 0) {
            item.uploadProperties.filePath = localAttachments[foundIndex].uploadProperties.filePath;
            item.fileUrl = localAttachments[foundIndex].fileUrl;
          }
          return item;
        });
      });
    }
  };

  /**
   * Check the rules of a valid file (extension and size (10MB))
   * @param file - The file passed for validation
   * @returns
   */
  const isFileValid = (file: File): boolean => {
    // The file extension (contains a dot).
    const fileExtensionWithDot = getFileExtension(file);
    // Removes the leading dot for comparison to the acceptable file extensions in the configuration.
    const fileExtension = fileExtensionWithDot.substring(1).toLowerCase();
    return !!fileExtension && validFileExtensionsLowercase.includes(fileExtension) && file.size <= validFileUploadSize;
  };

  /**
   * Return a list of all files not valid
   * @param files - The files data
   * @returns
   */
  const findBadFiles = (files: FileList) => {
    return Array.from(files).filter(file => !isFileValid(file));
  };

  /**
   * Return a list of only valid files
   * @param files - The files data
   * @returns
   */
  const filterInvalidFiles = (files: FileList) => {
    return Array.from(files).filter(file => isFileValid(file));
  };

  /**
   * show the erorr modal with the list of invalid files
   * @param badFiles - array of bad files
   */
  const showErrorModal = (badFiles: File[]) => {
    setShowFileErrorModal(true);
    setBadFiles(badFiles);
  };

  /**
   * handle dropping a file onto the UI
   * @param event - drag event
   */
  const dropHandler = (event: React.DragEvent<HTMLDivElement>) => {
    setFileDraggingIn(false);
    event.preventDefault();
    event.stopPropagation();

    addFileAttachments(event.dataTransfer.files);
  };

  /**
   * Do nothing on drag over
   * @param ev - drag event
   */
  const dragOverHandler = (ev: React.DragEvent<HTMLDivElement>) => {
    ev.preventDefault();
  };

  return (
    <div className="mb-6">
      <div
        className={classNames(
          'flex flex-col justify-center mb-2 border-dashed border-2 border-gray-200 bg-white rounded-md relative z-20',
          {
            'border-indigo-600': fileDraggingIn,
          }
        )}
      >
        <div
          className="text-center w-full"
          onDrop={e => dropHandler(e)}
          onDragOver={e => dragOverHandler(e)}
          onDragEnter={() => setFileDraggingIn(true)}
          onDragLeave={() => setFileDraggingIn(false)}
        >
          <div className="py-7 h-full">
            <div className="mx-auto h-12 w-12 text-gray-400">
              <UploadIcon />
            </div>
            <div className="flex justify-center text-sm text-gray-600 text-center mb-1">
              <label
                htmlFor={`file-upload-${orderId}`}
                className="cursor-pointer bg-transparent rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <span>Upload a file</span>
                <input
                  id={`file-upload-${orderId}`}
                  multiple
                  name="file-upload"
                  type="file"
                  className="sr-only"
                  onChange={e => addFileHandler(e)}
                  accept={validFileExtensionsLowercase.map(value => `.${value}`).join(',')}
                />
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <div className="text-xs text-gray-500">
              {validFileExtensionsLowercase.map(value => value.toUpperCase()).join(', ')} up to{' '}
              {validFileUploadSize / 1000000}MB
            </div>
          </div>
        </div>
      </div>
      {attachments.map((fileAttachment, index) => (
        <FileCell
          key={index}
          fileAttachment={fileAttachment}
          onUploadFile={(uuid: string) => uploadFileHandler(uuid)}
          onRemoveFile={(uuid: string) => removeFileHandler(uuid)}
        />
      ))}
      {showFileErrorModal && (
        <Modal onClose={() => setShowFileErrorModal(false)}>
          <BadFilesModal badFiles={badFiles} onClose={() => setShowFileErrorModal(false)} />
        </Modal>
      )}
    </div>
  );
};

export default UploadCard;
