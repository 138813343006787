import { OrderEntryAccountProvider } from 'providers/OrderEntryAccountProvider';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import AccountNumber from '../AccountNumber/AccountNumber';
import PatientInfo from '../PatientInfo/PatientInfo';
import ProviderSelector from '../ProviderSelector/ProviderSelector';
import ShippingAddressSelector from '../ShippingAddressSelector/ShippingAddressSelector';

/**
 * Component for displaying account details.
 * @returns JSX element representing the AccountDetail component.
 */
const AccountDetail: React.FC = () => {
  const { orderLoading } = useContext(OrderModuleContext);
  return (
    <div className="bg-white p-6 pb-8 w-full">
      <div className="grid grid-cols-4 gap-6">
        <OrderEntryAccountProvider>
          <AccountNumber skeletonLoader={orderLoading} />
          <ProviderSelector skeletonLoader={orderLoading} />
          <ShippingAddressSelector skeletonLoader={orderLoading} />
        </OrderEntryAccountProvider>
      </div>
      <PatientInfo />
    </div>
  );
};

export default AccountDetail;
