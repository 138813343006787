import { FacetItemOption } from './FacetItemOption';
import { Facet } from './types';

/**
 * Props for the FacetItem component.
 */
interface FacetItemProps<TFacet extends Facet, TOption> {
  /** The facet object. */
  facet: TFacet;
  /** The currently selected values for the facet. */
  selectedValues: Record<TFacet['id'], string[]>;
  /** Function to handle changes in selected values. */
  onSelectedValuesChange: (selectedValues: Record<TFacet['id'], string[]>, option: TOption) => void;
}

/**
 * Component for rendering a facet item.
 *
 * @param facet - The facet object.
 * @param onSelectedValuesChange - Function to handle changes in selected values.
 * @param selectedValues - The currently selected values for the facet.
 * @returns JSX.Element - The rendered component.
 */
export const FacetItem = <TFacet extends Facet, TOption = TFacet['options'][number]>({
  facet,
  onSelectedValuesChange,
  selectedValues,
}: FacetItemProps<TFacet, TOption>) => {
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>, option: TOption) => {
    const { checked, value } = e.target;
    const newSelectedValues = { ...selectedValues };
    const id = facet.id as TFacet['id'];
    if (checked) {
      newSelectedValues[id] = [...newSelectedValues[id], value];
    } else {
      newSelectedValues[id] = newSelectedValues[id].filter(v => v !== value);
    }
    onSelectedValuesChange(newSelectedValues, { ...option, checked });
  };

  return (
    <div className="flex flex-col gap-2 border-t border-gray-300 px-4 py-3">
      <div className="font-medium mb-2">{facet.name}</div>
      <div className="flex flex-col gap-2">
        {facet.options.map(option => {
          return (
            <FacetItemOption
              key={option.id}
              option={option}
              defaultChecked={selectedValues[facet.id as TFacet['id']].includes(option.value.toString())}
              onValueChange={e => onValueChange(e, option as TOption)}
            />
          );
        })}
      </div>
    </div>
  );
};
