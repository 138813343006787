import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import DropdownMenuButton from 'components/common/DropdownMenuButton';
import ModuleHeader from 'components/common/ModuleHeader';
import { RefreshButton } from 'components/common/RefreshButton/RefreshButton';
import { ROUTES } from 'components/navigation/Constants';
import { UnInvoiceModal } from 'pages/OrderDetailPage/CaseDetailStatusContent/UnInvoiceModal/UnInvoiceModal';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InvoiceMenuType, invoiceMenuItems } from 'shared/constants/case-detail.constants';
import { ACTIONS } from 'shared/enums/permission';
import { isCaseLockedForInvoicing } from 'shared/helpers/invoice/invoice.helper';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { CreatedOrder } from 'shared/models';
import { checkCaseIsInvoiced, isAccountCreditHold, isAccountOnCod, isProductCodReturnedItem } from 'shared/utils';
import { AdjustCodAmountModal } from '../modals/AdjustCodAmountModal';
import { ExistingTrackingNumberModal } from './ExistingTrackingNumberModal/ExistingTrackingNumberModal';

/**
 * Props interface for the UnInvoiceButton component.
 */
interface UnInvoiceButtonProps {
  /**
   * The order for which uninvoice is being performed.
   */
  order: CreatedOrder;
  /**
   * The account number associated with the order.
   */
  accountNumber: string;
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
}

/**
 * Component for un-invoicing an order.
 * @param order - The order for which uninvoice is being performed.
 * @param accountNumber - The account number associated with the order.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 */
const UnInvoiceButton: React.FC<UnInvoiceButtonProps> = ({ order, accountNumber, isLoading }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { getManufacturingLocation } = useManufacturingLocation();
  const manufacturingLocation = getManufacturingLocation(order?.originFacilityId ?? 0);
  const labCutoffTime = manufacturingLocation?.invoiceCutoffTimeUtc;
  const { allowVisible } = useRoleBasedAccessControl(ACTIONS.UNINVOICE);

  /**
   * Callback function for confirming un-invoicing.
   * Nav to INVOICE_ORDER_LOOKUP
   */
  const onConfirmUnInvoice = () => {
    navigate(ROUTES.INVOICE_ORDER_LOOKUP);
  };

  if (!allowVisible) return null;

  return (
    <>
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-700 hover:text-white bg-indigo-50 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => setShowModal(true)}
        data-testid="uninvoice"
        data-qa="uninvoice"
        disabled={isCaseLockedForInvoicing(order, labCutoffTime) || isLoading}
      >
        Uninvoice
      </button>
      <UnInvoiceModal
        showModal={showModal}
        timezone={manufacturingLocation?.tzIdentifier || ''}
        onCloseModal={() => setShowModal(false)}
        onConfirmUnInvoice={onConfirmUnInvoice}
        accountNumber={accountNumber}
      />
    </>
  );
};

/**
 * Props interface for the InvoiceSubmitButton component.
 */
interface InvoiceSubmitButtonProps {
  /**
   * Flag indicating whether to display the label button.
   */
  displayLabelBtn: boolean;
  /**
   * Callback function for submitting the invoice.
   */
  onSubmit: (printLabel: boolean) => void;
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
}

/**
 * Component for submitting an invoice.
 * @param displayLabelBtn - Flag indicating whether to display the label button.
 * @param onSubmit - Function to handle the submission of the invoice.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 */
const InvoiceSubmitButton: React.FC<InvoiceSubmitButtonProps> = ({ displayLabelBtn, onSubmit, isLoading }) => {
  const { account, invoiceShippingInput } = useInvoicingDetail();
  const [showInvoiceExistingTrackingAlertModal, setShowInvoiceExistingTrackingAlertModal] = useState(false);
  const { inValidTrackingNumber, showTrackingNumberInput } = invoiceShippingInput;

  /**
   * Callback function for handling the submission of the invoice.
   * @param printLabel - Flag indicating whether to print the label.
   */
  const handleSubmit = (printLabel: boolean) => {
    if (showTrackingNumberInput && inValidTrackingNumber) {
      setShowInvoiceExistingTrackingAlertModal(true);
    } else {
      onSubmit(printLabel);
    }
  };
  /**
   * Callback function for closing the ExistingTrackingNumberModal.
   */
  const onCloseModal = () => {
    setShowInvoiceExistingTrackingAlertModal(false);
  };

  return (
    <>
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => handleSubmit(true)}
        data-testid="invoice"
        data-qa="invoiceAndPrintButton"
        disabled={isAccountCreditHold(account?.standing) || isLoading}
      >
        Submit
      </button>
      {showInvoiceExistingTrackingAlertModal && (
        <ExistingTrackingNumberModal
          onClose={onCloseModal}
          onConfirm={() => {
            onSubmit(displayLabelBtn);
            onCloseModal();
          }}
        />
      )}
    </>
  );
};

/**
 * Component for rendering the content of the InvoiceHeader.
 * @param onCancel - Callback function for canceling the invoice submission.
 * @param onSubmit - Callback function for submitting the invoice.
 * @param onRefresh - Callback function for refreshing the invoice details.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param isOrderProcessing - Flag indicating whether the order is being processed.
 */
const Content: React.FC<Omit<InvoiceHeaderProps, 'title'>> = ({
  onCancel,
  onSubmit,
  onRefresh,
  isOrderProcessing,
  isLoading,
}) => {
  const { invoiceShippingInput, invoiceCases, account, codAmount, setCodAmount } = useInvoicingDetail();
  const [isAdjustCodAmountModalOpen, setIsAdjustCodAmountModalOpen] = useState(false);

  const isDoctorPickup = invoiceShippingInput.isDoctorPickup || false;
  const firstInvoiceCase = invoiceCases[0];
  const isInvoicedCase = checkCaseIsInvoiced(firstInvoiceCase?.status);

  // Determines whether the user is able to adjust the COD amount.
  const isAbleToAdjustCod = useMemo(() => {
    const isCodReturnedItemInInvoice = invoiceCases.some(order =>
      order.orderItems.find(orderItem => isProductCodReturnedItem(orderItem.productCode))
    );
    return isCodReturnedItemInInvoice && isAccountOnCod(account?.standing) && !isInvoicedCase;
  }, [account?.standing, invoiceCases, isInvoicedCase]);

  /**
   * Handles a click event on a menu item.
   * @param menuItem - The menu item that was clicked.
   */
  const handleMenuClick = (menuItem: string) => {
    if (menuItem === InvoiceMenuType.AdjustCodAmount) {
      setIsAdjustCodAmountModalOpen(true);
    }
  };

  /**
   * Handles closing the AdjustCodAmountModal component.
   */
  const handleAdjustCodAmountModalClose = () => {
    setIsAdjustCodAmountModalOpen(false);
  };

  /**
   * Sets the new COD amount and closes the AdjustCodAmountModal component.
   * @param adjustedCodAmount - The new COD amount to set.
   */
  const handleAdjustCodAmountModalSubmit = (adjustedCodAmount: number) => {
    setCodAmount(adjustedCodAmount);
    handleAdjustCodAmountModalClose();
  };

  return (
    <>
      {isOrderProcessing && (
        <div className="mr-3">
          <RefreshButton onClick={onRefresh} />
        </div>
      )}
      <div className="mr-3">
        <DropdownMenuButton
          menuItems={invoiceMenuItems({ isAbleToAdjustCod: isAbleToAdjustCod })}
          onMenuClick={handleMenuClick}
          rightIcon={<EllipsisVerticalIcon className="h-5 w-5" aria-label="chevron-down" />}
        />
      </div>

      <div className="group relative">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={onCancel}
          data-testid="cancel"
          data-qa="cancelButton"
          disabled={isLoading}
        >
          Cancel
        </button>
      </div>

      {checkCaseIsInvoiced(firstInvoiceCase?.status) ? (
        <UnInvoiceButton
          accountNumber={account?.billingAccountId || ''}
          order={firstInvoiceCase}
          isLoading={isLoading}
        />
      ) : (
        <InvoiceSubmitButton displayLabelBtn={isDoctorPickup} onSubmit={onSubmit} isLoading={isLoading} />
      )}

      {isAdjustCodAmountModalOpen && (
        <AdjustCodAmountModal
          codAmount={codAmount}
          onClose={handleAdjustCodAmountModalClose}
          onSubmit={handleAdjustCodAmountModalSubmit}
        />
      )}
    </>
  );
};

/**
 * Props interface for the InvoiceHeader component.
 */
interface InvoiceHeaderProps {
  /**
   * The title of the invoice.
   */
  title: string;
  /**
   * Callback function for handling the submission of the invoice.
   * @param printLabel - Flag indicating whether to print the invoice label.
   */
  onSubmit: (printLabel: boolean) => void;
  /**
   * Callback function for canceling the submission of the invoice.
   */
  onCancel: () => void;
  /**
   * Callback function for refreshing the invoice details.
   */
  onRefresh: () => void;
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
  /**
   * Flag indicating whether the order is currently being processed.
   */
  isOrderProcessing?: boolean;
}
/**
 * Component for rendering the header of an invoice.
 * @param title - The title of the invoice.
 * @param onSubmit - Callback function for submitting the invoice.
 * @param onCancel - Callback function for canceling the submission of the invoice.
 * @param onRefresh - Callback function for refreshing the invoice details.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param isOrderProcessing - Flag indicating whether the order is currently being processed.
 */
const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({ title, isLoading, ...props }) => {
  return (
    <ModuleHeader title={title}>
      <div className="flex -mt-0.5">
        <Content {...props} isLoading={isLoading} />
      </div>
    </ModuleHeader>
  );
};

export default InvoiceHeader;
