import segmentPlugin from '@analytics/segment';
import { AnalyticsInstance, init } from 'analytics';
import { ProjectInfo } from 'shared/models/projectInfo';
import { UserInfo } from 'types/common';

class AnalyticsService {
  #instance: AnalyticsInstance = init({
    app: 'LMS',
  });
  #defaultTrackData: {
    deployedEnvironment: string;
    deployedVersion: string;
    deployedApplication: string;
    hostname: string;
  } = {
    deployedEnvironment: '',
    deployedVersion: '',
    deployedApplication: '',
    hostname: window.location.hostname,
  };

  init(writeKey: string, projectInfo: ProjectInfo | null) {
    if (!writeKey) console.warn('No Segment write key provided.');
    if (!projectInfo?.deployedEnvironment) throw new Error('No deployed environment found.');

    this.#instance = init({
      app: 'LMS',
      version: projectInfo.deployedVersion,
      plugins: [
        segmentPlugin({
          writeKey,
        }),
      ],
    });
    this.#defaultTrackData = {
      deployedEnvironment: projectInfo.deployedEnvironment,
      deployedVersion: projectInfo.deployedVersion,
      deployedApplication: projectInfo.deployedApplication,
      hostname: window.location.hostname,
    };
  }

  /**
   * Identify a user so session details can be sent in each event the user performs.
   * @param user - the user to identify.
   */
  identify(user: UserInfo) {
    const { employeeId, avatar, roles, ...rest } = user;
    this.#instance.identify(rest.email, {
      ...rest,
    });
  }

  /**
   * Track when a page loads.
   * @param data - page data.
   */
  page(data: { path: string; referrer?: string }) {
    this.#instance.page({
      ...data,
      ...this.#defaultTrackData,
    });
  }

  /**
   * Track custom events.
   * @param eventName - event name to track.
   * @param properties - event properties.
   */
  track(eventName: string, properties?: Record<string, string | number | boolean | object | null>) {
    this.#instance.track(eventName, {
      ...properties,
      ...this.#defaultTrackData,
    });
  }

  /**
   * Reset analytics state (e.g. clear user info).
   */
  reset = () => {
    this.#instance.reset();
  };
}

const Analytics = new AnalyticsService();
export { Analytics as AnalyticsService };
