import { FC } from 'react';

/**
 * Props for the ShippingPreferenceGreenBox component.
 */
interface ShippingPreferenceGreenBoxProps {
  /** An array of shipping preference descriptions. */
  descriptions: string[];
}

/**
 * Displays a box containing shipping preference descriptions.
 * @param descriptions - An array of shipping preference descriptions.
 * @returns The rendered ShippingPreferenceGreenBox component or null if no descriptions are provided.
 */
export const ShippingPreferenceGreenBox: FC<ShippingPreferenceGreenBoxProps> = ({ descriptions }) => {
  if (!descriptions.length) return null;
  return (
    <div className="bg-teal-100 p-3 border border-teal-500 rounded-lg" data-testid="preferenceGreenBox">
      <div className="text-sm">
        <div className="text-teal-900 font-medium">Account Shipping Preferences</div>
        <ul className="list-inside list-disc mt-1 space-y-1 text-teal-900 font-normal">
          {descriptions.map(description => (
            <li className="ml-3" key={description}>
              {description}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
