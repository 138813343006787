import { ShippingOption } from 'API';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useRef } from 'react';
import { TrackingNumberButton } from './TrackingNumberButton/TrackingNumberButton';
import { TrackingNumberInput } from './TrackingNumberInput/TrackingNumberInput';

/**
 * Props interface for the TrackingNumber component.
 */
interface TrackingNumberProps {
  /**
   * Array of shipping options to display tracking number for.
   */
  shippingOptions: ShippingOption[];
}

/**
 * Component renders and manage add Existing Tracking Number in invoicing page.
 * @param shippingOptions - Array of shipping options to display tracking number for.
 * @returns JSX element representing the tracking number component.
 */
export const TrackingNumber: React.FC<TrackingNumberProps> = () => {
  const { invoiceShippingInput, shippingOptions, setInvoiceShippingInput, setShippingOptions } = useInvoicingDetail();
  const originalShippingOptionsRef = useRef<ShippingOption[]>(shippingOptions);
  const { showTrackingNumberInput, carrierPreference, servicePreference, isSignatureRequiredPreference } =
    invoiceShippingInput;

  const onShowTrackingNumber = () => {
    setInvoiceShippingInput({ showTrackingNumberInput: true });
  };

  /**
   * When removing an existing tracking number, resets the values associated with that tracking number.
   * This includes the number itself, as well as any values that are tracking number specific.
   */
  const onHideTrackingNumber = () => {
    setInvoiceShippingInput({
      trackingNumberBundledOrder: undefined,
      showTrackingNumberInput: false,
      trackingNumber: '',
      carrier: carrierPreference,
      shippingService: servicePreference,
      isSignatureRequired: isSignatureRequiredPreference ?? false,
    });

    // Entering a tracking number updates the shipping option to its associated carrier in TrackingNumberInput.tsx.
    // This restores the original shipping options when user removes the tracking number.
    setShippingOptions(originalShippingOptionsRef.current);
  };

  return showTrackingNumberInput ? (
    <TrackingNumberInput onHideTrackingNumber={onHideTrackingNumber} />
  ) : (
    <TrackingNumberButton onShowTrackingNumber={onShowTrackingNumber} />
  );
};
