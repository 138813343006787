import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { cn } from 'shared/utils';
import Button from '../Button/Button';

/**
 * Props for the RefreshButton component.
 */
interface RefreshButtonProps extends React.ComponentProps<typeof Button> {
  /** Function to be called when the button is clicked. */
  onClick: () => void;
  /** Additional CSS classes for styling. */
  className?: string;
  /** Indicates whether the button is in a loading state. */
  isLoading?: boolean;
  /** Text to be displayed alongside the icon. */
  text?: string;
}

/**
 * Component for rendering a refresh button.
 *
 * @param className - Additional CSS classes for styling.
 * @param isLoading - Indicates whether the button is in a loading state.
 * @param text - Text to be displayed alongside the icon.
 * @param props - Additional props for the Button component.
 * @returns JSX.Element - The rendered component.
 */
export const RefreshButton: React.FC<RefreshButtonProps> = ({ className, isLoading, text, ...props }) => {
  return (
    <Button variant="bordered" className={cn('p-2.5', className)} title="Refresh" disabled={isLoading} {...props}>
      <div className="flex gap-2 items-center">
        <ArrowPathIcon className="h-4" />
        {text}
      </div>
    </Button>
  );
};
