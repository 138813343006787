import { EnclosedItemCategory, Order, OrderEnclosedItem } from 'API';
import { Table } from 'components/common/Table/Table';
import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import { useCallback, useContext, useMemo } from 'react';
import { fileColumns, inboundEnclosedColumns, outboundEnclosedColumns } from 'shared/constants/case-detail.constants';

/**
 * Props of the CaseFiles component.
 */
interface CaseFilesProps {
  /**
   * An array of file attachments.
   */
  fileAttachments?: Order['fileAttachments'];
  /**
   * An array of enclosed items.
   */
  enclosedItems?: Order['enclosedItems'];
}

/**
 * Component for displaying case files including file attachments and enclosed items.
 *
 * @param CaseFilesProps - The properties of the CaseFiles component.
 * @returns A JSX element representing the CaseFiles component.
 */
const CaseFiles: React.FC<CaseFilesProps> = ({ fileAttachments = [], enclosedItems = [] }) => {
  const { caseDetails } = useContext(CaseDetailModuleContext);

  /**
   * Function to add UTC conversion time zone code to enclosed item.
   * @param item - The enclosed item to which UTC conversion time zone code is added.
   * @returns The enclosed item with UTC conversion time zone code added.
   */
  const getEnclosedItem = useCallback(
    (item: OrderEnclosedItem) => {
      return {
        ...item,
        utcConversionTimeZoneCode: caseDetails?.utcConversionTimeZoneCode || '',
      };
    },
    [caseDetails?.utcConversionTimeZoneCode]
  );

  /**
   * Memoized array of inbound enclosed items with UTC conversion time zone code added.
   * @returns The array of inbound enclosed items with UTC conversion time zone code added.
   */
  const inboundEnclosedItems = useMemo(() => {
    return enclosedItems.filter(item => item.shippingType === EnclosedItemCategory.Inbound).map(getEnclosedItem);
  }, [enclosedItems, getEnclosedItem]);

  /**
   * Memoized array of outbound enclosed items with UTC conversion time zone code added.
   * @returns The array of outbound enclosed items with UTC conversion time zone code added.
   */
  const outboundEnclosedItems = useMemo(() => {
    return enclosedItems.filter(item => item.shippingType === EnclosedItemCategory.Outbound).map(getEnclosedItem);
  }, [enclosedItems, getEnclosedItem]);

  /**
   * Memoized array of file attachments with UTC conversion time zone code added.
   * @returns The array of file attachments with UTC conversion time zone code added.
   */
  const attachments = useMemo(() => {
    return fileAttachments.map(attachment => {
      return {
        ...attachment,
        utcConversionTimeZoneCode: caseDetails?.utcConversionTimeZoneCode || '',
      };
    });
  }, [caseDetails?.utcConversionTimeZoneCode, fileAttachments]);

  return (
    <div className="p-6">
      <div className="flex flex-col gap-6">
        <Table label="Files" rows={attachments} columns={fileColumns} pageSize={attachments.length} maxHeight="none" />
        <Table
          label="Inbound - Enclosed Items"
          rows={inboundEnclosedItems}
          columns={inboundEnclosedColumns}
          pageSize={inboundEnclosedItems?.length}
          maxHeight="none"
        />
        <Table
          label="Outbound - Enclosed Items"
          rows={outboundEnclosedItems}
          columns={outboundEnclosedColumns}
          pageSize={outboundEnclosedItems.length}
          maxHeight="none"
        />
      </div>
    </div>
  );
};

export default CaseFiles;
