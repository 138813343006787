import { PlusCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';

/**
 * Props for the AddButton component.
 */
interface AddButtonProps {
  /**
   * The button text.
   */
  text: string;
  /**
   * The click handler function.
   */
  onClick: () => void;
  /**
   * The ID of the button.
   */
  id: string;
  /**
   * Additional class for text color.
   */
  textColorClass?: string;
  /**
   * Boolean indicating whether the button is disabled.
   */
  disabled?: boolean;
}

/**
 * Component for displaying an add button.
 * @param text - The button text.
 * @param onClick - The click handler function.
 * @param id - The ID of the button.
 * @param textColorClass - Additional class for text color.
 * @param disabled - Boolean indicating whether the button is disabled.
 * @returns JSX.Element representing the AddButton component.
 */
const AddButton: React.FC<AddButtonProps> = ({ text, onClick, id, textColorClass, disabled }) => {
  return (
    <div className="py-2">
      <button
        className="flex flex-row items-center justify-items-center active:outline-none active:ring-2 active:ring-offset-2 active:ring-indigo-500"
        type="submit"
        onClick={event => {
          event.preventDefault();
          onClick();
        }}
        id={id}
        data-qa={id}
        data-testid={id}
        disabled={disabled}
      >
        <PlusCircleIcon className={classNames(textColorClass || 'text-indigo-700', 'h-5 w-5')} />
        <p className={classNames(textColorClass || 'text-indigo-700', 'px-2.5 font-medium text-sm')}>{text}</p>
      </button>
    </div>
  );
};

export default AddButton;
