import { GraphQLResult, GraphQLSubscription } from '@aws-amplify/api';
import {
  DeliveryMethod,
  DoctorLabelAvailableSubscription,
  InvoiceAvailableSubscription,
  ShippingLabelAvailableSubscription,
} from 'API';
import Images from 'assets/images';
import { useAppConfig } from 'providers/AppProvider';
import { useToast } from 'providers/ToastProvider';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { subscribeToDoctorPickupLabel, subscribeToInvoice, subscribeToShippingLabel } from 'shared/api/invoice.api';
import { PrintType, openLabelPrintPage, print } from 'shared/api/print.api';
import { ToastNotificationType } from 'shared/enums';
import { fetchPrintInvoiceTotalAmount } from 'shared/helpers/printing/invoice.helper';
import { fetchPrintShippingLabel } from 'shared/helpers/printing/shipping.helper';
import { InvoiceSummaryRouteState, InvoicedOrder } from 'shared/models/invoicing-shipping';
import { useSubscription } from './useSubscription';

/**
 * A custom hook that subscribes to invoice printing label events and returns an array of invoiced orders.
 * @returns An array of invoiced orders.
 */
export const useInvoicePrintingLabelSubscription = () => {
  const { sessionId } = useAppConfig();
  const [invoicedOrders, setInvoicedOrders] = useState<Array<InvoicedOrder>>([]);
  const [bundleId, setBundleId] = useState<string | undefined>(undefined);
  const { state } = useLocation();
  const previousInvoice = state as InvoiceSummaryRouteState;
  const previousInvoiceOrders = useMemo(() => previousInvoice?.orders, [previousInvoice]);
  const navigate = useNavigate();
  const printLabelData = useRef(previousInvoice?.printLabelData);
  const opposingImageFilePaths = previousInvoice?.printLabelData?.opposingImageFilePaths;
  const adjustOpposingImageSrc = Images.Base64EncodedImages['adjust-opposing-header'].base64;
  const previousBundleId = useMemo(() => previousInvoice?.bundleId, [previousInvoice]);
  const toast = useToast();
  const isPrintLabelData = !!previousInvoice?.printLabelData;
  useEffect(() => {
    if (previousInvoiceOrders && previousInvoiceOrders.length) {
      setInvoicedOrders(
        previousInvoiceOrders.map(order => ({
          ...order,
          disableShippingLabelReprint: isPrintLabelData,
          disableInvoiceReprint: isPrintLabelData,
        }))
      );
    }
    if (previousBundleId) {
      setBundleId(previousBundleId);
    }
    (async () => {
      if (opposingImageFilePaths && opposingImageFilePaths.length) {
        const opposingImageFilePathsUrls = opposingImageFilePaths.map((path: string) => {
          const htmlString = `
          <html>
          <style>
            body {
                margin: 0;
                padding: 0;
            }

            .header-image {
              width: 816px;
            }

            .text-container {
              text-align: center;
              font-weight: bold;
              font-family: sans-serif;
              font-size: 14px;
              margin: 15px 0;
            }

            .images-container {
              text-align: center;
            }

            .adjust-opposing-image {
              width: 550px;
              padding-bottom: 10px;
            }
            </style>

            <body>
              <div class="header-container">
                <img class="header-image" src="${adjustOpposingImageSrc}" />
              </div>
              <div class="text-container">
                NOTE: During design, this case required an adjustment to one or more 
                <br />
                opposing cusps or adjacent contact teeth. See the image below for specific
                <br />
                locations of the adjustments made as well as the amount of material removed.
              </div>
              <div class="images-container">
                <img class="adjust-opposing-image"
                  src="${path}"
                />
              </div>
            </body>
          </html>`;
          return {
            printContent: htmlString,
            displayContent: htmlString,
          };
        });

        try {
          await print(PrintType.HTML, opposingImageFilePathsUrls);
        } catch {
          opposingImageFilePaths.forEach(file => {
            openLabelPrintPage(file);
          });
        }
      }
    })();
  }, [
    previousInvoiceOrders,
    toast,
    isPrintLabelData,
    opposingImageFilePaths,
    previousBundleId,
    adjustOpposingImageSrc,
  ]);

  /**
   * Subscribes to the invoice printing label event.
   */
  useSubscription(
    {
      onInitialize() {
        const { printLabelData, orders } = previousInvoice || {};
        if (!printLabelData || !orders.length) return [];

        const array = orders.map(({ orderNumber }) => {
          return subscribeToInvoice({
            OrderNumber: orderNumber,
            SessionId: sessionId,
          });
        });
        return array;
      },
      async onSuccess({ value }) {
        const { data } = value as GraphQLResult<GraphQLSubscription<InvoiceAvailableSubscription>>;
        const orderNumber = data?.invoiceAvailable?.OrderNumber || '';
        const invoiceTotalAmount = await fetchPrintInvoiceTotalAmount(orderNumber);
        setInvoicedOrders(invoicedOrders => {
          const updatedInvoicedOrders = invoicedOrders.map(order => {
            if (orderNumber === order.orderNumber) {
              return {
                ...order,
                invoiceTotalAmount,
                disableInvoiceReprint: false,
              };
            }
            return order;
          });
          return updatedInvoicedOrders;
        });
      },
      onError({ index }) {
        const invoicedOrder = invoicedOrders[index];
        toast.notify(
          `Failed to auto print the Label for ${invoicedOrder.orderNumber}, please try printing manually.`,
          ToastNotificationType.Error
        );
      },
    },
    []
  );

  /**
   * Subscribes to the shipping printing label event.
   */
  useSubscription(
    {
      onInitialize() {
        const { printLabelData, orders } = previousInvoice || {};
        if (!printLabelData || !orders.length) return [];

        const array = orders.map(({ orderNumber }) => {
          const type = printLabelData.type;
          const subscribe =
            type === DeliveryMethod.DoctorPickup ? subscribeToDoctorPickupLabel : subscribeToShippingLabel;
          return subscribe({
            OrderNumber: orderNumber,
            SessionId: sessionId,
          });
        });
        navigate('.', { relative: 'route', replace: true, state: undefined });
        return array;
      },
      async onSuccess({ value }) {
        const { type, printInboundLabel } = printLabelData.current || {};
        if (!type) throw new Error('Type is required.');

        let orderNumber = '';
        if (type === DeliveryMethod.DoctorPickup) {
          orderNumber =
            (value as GraphQLResult<GraphQLSubscription<DoctorLabelAvailableSubscription>>)?.data?.doctorLabelAvailable
              ?.OrderNumber || '';
        } else {
          orderNumber =
            (value as GraphQLResult<GraphQLSubscription<ShippingLabelAvailableSubscription>>)?.data
              ?.shippingLabelAvailable?.OrderNumber || '';
        }
        try {
          await fetchPrintShippingLabel({
            orderNumber,
            type,
            printInboundLabel,
          });
        } catch {
          console.error('Error printing shipping label.');
        }
        setInvoicedOrders(invoicedOrders => {
          const updatedInvoicedOrders = invoicedOrders.map(order => {
            if (orderNumber === order.orderNumber) {
              return {
                ...order,
                disableShippingLabelReprint: false,
              };
            }
            return order;
          });
          return updatedInvoicedOrders;
        });
      },
      onError({ index, error }) {
        console.error(error);
        const invoicedOrder = invoicedOrders[index];
        toast.notify(
          `Failed to auto print shipping Label for ${invoicedOrder.orderNumber}, please try printing manually.`,
          ToastNotificationType.Error
        );
      },
    },
    []
  );

  return { invoicedOrders: invoicedOrders, bundleId };
};
