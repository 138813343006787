import { OrderProductAttributeInput } from 'API';
import Images from 'assets/images';
import Modal from 'components/common/Modal';
import MultiSelectDropdown from 'components/common/MultiSelectDropdown/MultiSelectDropdown';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { AttributeType } from 'shared/enums';
import { getAttributeToothValue, sortToothValue } from 'shared/helpers/order-entry/order-entry.helper';
import { DiagramAttribute } from 'shared/models';
import { ALL_TEETH, isValidToothNumber } from 'shared/utils';
import ToothChartSingle from '../ToothChartSingle';

/**
 * Props for the ToothSingle component.
 */
interface ToothSingleProps {
  /**
   * The attribute of the tooth.
   */
  attribute: DiagramAttribute;
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * Indicates whether the component is disabled.
   */
  disabled: boolean;
  /**
   * Callback function invoked when the attribute changes.
   */
  onAttributeChange: (attribute: OrderProductAttributeInput) => void;
}

/**
 * The ToothSingle component allows users to select individual teeth (single type).
 * @param attribute - The attribute of the tooth.
 * @param itemId - The ID of the item.
 * @param disabled - Indicates whether the component is disabled.
 * @param onAttributeChange - Callback function invoked when the attribute changes.
 * @returns JSX element representing the ToothSingle component.
 */
const ToothSingle: React.FC<ToothSingleProps> = ({ attribute, onAttributeChange, itemId, disabled }) => {
  const [showToothChart, setShowToothChart] = useState<boolean>(false);
  const { order } = useContext(OrderModuleContext);
  const [toothNumbers, setToothNumbers] = useState<string[]>(() => {
    if (!attribute.value) return [];
    return attribute.value.replace(/#/g, '').split(',');
  });
  const foundIndex = order.orderItems.findIndex(item => item.id === itemId);

  const { onSubmitAlertCount } = useContext(OrderModuleContext);
  const toothNumberElementRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    !toothNumberElementRef.current?.value.length && toothNumberElementRef.current?.focus();
  }, []);
  const onAttributeChangeHandler = (values: string[]) => {
    const toothValue = getAttributeToothValue(values);
    // Ensure hashtag is sent because Service and Pega is expecting it.
    const attributeInput: OrderProductAttributeInput = {
      value: toothValue,
      name: attribute.name,
      type: attribute.type,
      quantity: 1,
    };
    onAttributeChange(attributeInput);
  };
  useEffect(() => {
    const newAttribute = order.orderItems[foundIndex].attributes.filter(
      att => att.name === AttributeType.ToothString
    )[0];

    const newValues = newAttribute?.value ? newAttribute.value?.split(',').map(data => data.replace('#', '')) : [];
    if (newValues.length !== toothNumbers.length) {
      setToothNumbers(sortToothValue(newValues));
    }
  }, [order, itemId, foundIndex, toothNumbers]);

  const toothNumberChangeHandler = (values: string[]) => {
    if (values.some(s => !isValidToothNumber(s))) {
      return;
    }

    const removalDuplicateValue = [...new Set(values)];
    onAttributeChangeHandler(removalDuplicateValue);
    setToothNumbers(sortToothValue(values));
  };

  const toothChartHandler = (value: string) => {
    // If no value, clear the input.
    if (!value) {
      onAttributeChangeHandler([]);
      // Unselect value if we already have the value.
    } else if (toothNumbers.includes(value)) {
      const unSelectedValue = toothNumbers.filter(p => p !== value);
      onAttributeChangeHandler(unSelectedValue);
    } else {
      // Remove duplicates and set new values.
      const removalDuplicateValue = [...new Set([...toothNumbers, value])];
      onAttributeChangeHandler(removalDuplicateValue);
    }
  };

  const isToothStringRequired = attribute.isRequired ?? false;

  const dropdownOptions = useMemo(() => {
    return ALL_TEETH.map(tooth => {
      return {
        label: `#${tooth}`,
        value: `${tooth}`,
      };
    });
  }, []);

  const selectedToothNumbers = useMemo(() => {
    return toothNumbers.map(t => ({ value: t.toString(), label: `#${t.toString()}` }));
  }, [toothNumbers]);
  return (
    <>
      <div className="grid grid-cols-4 gap-x-6 gap-y-4">
        <div className="flex-1">
          <MultiSelectDropdown
            label="Tooth #"
            dropdownOptions={dropdownOptions}
            values={selectedToothNumbers}
            onSelectedValueChange={selected => {
              !disabled && toothNumberChangeHandler(selected.map(value => value.value));
            }}
            dataQa="toothNumber"
            isRequired={isToothStringRequired && !disabled}
            onSubmitAlert={!!onSubmitAlertCount}
            placeholder="Search"
            isDisabled={!!disabled}
          />
        </div>
        {!disabled && (
          <div className="pt-6">
            <button
              type="button"
              className="inline-flex items-center px-3 py-2.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => setShowToothChart(true)}
              data-qa="openToothChartButton"
            >
              <img src={Images.Shape} alt="Tooth" className="h-4 w-4 mr-3" />
              Open Tooth Chart
            </button>
          </div>
        )}
      </div>
      {showToothChart && (
        <Modal
          onClose={() => {
            setShowToothChart(false);
          }}
        >
          <ToothChartSingle
            selected={toothNumbers}
            onSelect={selectedTooth => {
              toothChartHandler(selectedTooth);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ToothSingle;
