import { FC } from 'react';
import { ColorCheckBox } from '../ColorCheckBox/ColorCheckBox';

/**
 * Component for displaying shipping preference color elements.
 *
 * @returns React component for shipping preference color elements.
 */
export const ShippingPreferenceColorElement: FC = () => {
  return (
    <div className="mt-4 flex flex-row gap-4 justify-end">
      <ColorCheckBox variant="teal" label="Preference" labelClassName="text-gray-500" />
      <ColorCheckBox variant="orange" label="Override" labelClassName="text-gray-500" />
    </div>
  );
};
