import { Address } from 'API';

/**
 * Props interface for the AddressSelection component.
 */
interface AddressSelectionProps {
  /**
   * The entered address.
   */
  enteredAddress: Address;
  /**
   * The suggested address.
   */
  suggestedAddress: Address;
  /**
   * Indicates whether the address is verified.
   */
  isAddressVerified: boolean;
  /**
   * Callback function invoked when an address is selected.
   */
  onAddressSelection: (address: Address) => void;
  /**
   * Callback function invoked when the user wants to edit the address.
   */
  onEditAddress: () => void;
}

/**
 * Represents the Address Selection component.
 * This component allows users to select between entered and suggested addresses.
 * It provides options to use the entered address, edit it, or use the suggested address.
 * @param enteredAddress - The entered address.
 * @param suggestedAddress - The suggested address.
 * @param isAddressVerified - Indicates whether the address is verified.
 * @param onAddressSelection - Callback function invoked when an address is selected.
 * @param onEditAddress - Callback function invoked when the user wants to edit the address.
 */
const AddressSelection: React.FC<AddressSelectionProps> = ({
  enteredAddress,
  suggestedAddress,
  isAddressVerified,
  onAddressSelection,
  onEditAddress,
}) => {
  return (
    <div className="my-6 pl-20">
      <div>
        <input
          id="enteredAddress"
          name="address"
          type="radio"
          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          onClick={() => onAddressSelection(enteredAddress)}
          defaultChecked={!isAddressVerified}
          data-qa="enteredAddress"
        />
        <label htmlFor="enteredAddress" className="ml-3 text-sm font-medium">
          Use Entered Address
        </label>
        <div>
          <div className="ml-7 text-sm text-gray-500" data-qa="enteredStreet1Label">
            {enteredAddress.street1}
          </div>
          <div className="ml-7 text-sm text-gray-500" data-qa="enteredStreet2Label">
            {enteredAddress.street2}
          </div>
          <div
            className="ml-7 text-sm text-gray-500"
            data-qa="enteredCityLabel"
          >{`${enteredAddress.city}, ${enteredAddress.state} ${enteredAddress.zipcode}`}</div>
          <div onClick={onEditAddress} className="ml-7 text-sm text-indigo-600 cursor-pointer">
            Edit Address
          </div>
        </div>
      </div>
      {isAddressVerified ? (
        <div className="mt-3">
          <input
            id="suggestedAddress"
            name="address"
            type="radio"
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            onClick={() => onAddressSelection(suggestedAddress)}
            defaultChecked={isAddressVerified}
            data-qa="suggestedAddress"
          />
          <label htmlFor="suggestedAddress" className="ml-3 text-sm font-medium">
            Use Suggested Address
          </label>
          <div>
            <div className="ml-7 text-sm text-gray-500" data-qa="suggestedStreet1Label">
              {suggestedAddress?.street1}
            </div>
            <div className="ml-7 text-sm text-gray-500" data-qa="suggestedStreet2Label">
              {suggestedAddress?.street2}
            </div>
            <div className="ml-7 text-sm text-gray-500" data-qa="suggestedCityLabel">{`${suggestedAddress?.city}, ${
              suggestedAddress?.state ?? ''
            } ${suggestedAddress?.zipcode}`}</div>
          </div>
        </div>
      ) : (
        <div className="mt-3">
          <label className="text-red-500 text-sm ml-7">Address could not be verified.</label>
        </div>
      )}
    </div>
  );
};

export default AddressSelection;
