import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DialogBox } from 'components/common/Dialog/DialogBox';

/**
 * Props interface for the ExistingTrackingNumberModal component.
 */
interface ExistingTrackingNumberModalProps {
  /**
   * Callback function invoked when the modal is closed.
   */
  onClose: () => void;
  /**
   * Callback function invoked when the user confirms the action in the modal.
   */
  onConfirm: () => void;
}

/**
 * Component for displaying a modal when attempting to submit an invoice without a tracking number.
 * @param onClose - Callback function invoked when the modal is closed.
 * @param onConfirm - Callback function invoked when the user confirms the action in the modal.
 * @returns JSX element representing the modal.
 */
export const ExistingTrackingNumberModal: React.FC<ExistingTrackingNumberModalProps> = ({ onClose, onConfirm }) => {
  return (
    <DialogBox
      icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
      title="Invoice without existing tracking number?"
      onCancel={onClose}
      cancelText="Back"
      confirmText="Yes, Invoice & Print"
      onConfirm={onConfirm}
    >
      <div className="text-gray-500 text-sm">
        A new tracking number will be created according to the shipping method selected since no tracking number was
        entered or the tracking number entered is invalid
      </div>
    </DialogBox>
  );
};
