import { TechnicalPreferencesQuery, TechnicalPreferencesQueryVariables } from 'API';
import { technicalPreferencesQuery } from 'graphql/queries';
import Http from './http';

/**
 * Retrieves technical preferences for a given customer's billing account via AppSync.
 * @param query - Contains the customer's billing account and provider ID.
 * @returns technical preferences for an array of products that have been configured for the target customer's billing account.
 */
export const getTechnicalPreferences = async (query: TechnicalPreferencesQueryVariables) => {
  const response = await Http.handleGraphqlOperation<TechnicalPreferencesQuery>(technicalPreferencesQuery, query);
  const result = Http.processGraphqlResponse(response.technicalPreferences, 'TechnicalPreferences');
  return result.preferencesByAccountAndProvider;
};
