import classNames from 'classnames';
import { useEffect, useRef } from 'react';

/**
 * Props for the SuggestionList component.
 */
interface SuggestionListProps {
  /**
   * Array of filtered suggestions.
   */
  filteredSuggestions: string[];
  /**
   * Index of the active suggestion.
   */
  activeSuggestionIndex: number;
  /**
   * Data attribute for testing.
   */
  dataQa: string;
  /**
   * Function to handle selection of a suggestion.
   */
  onSelect: (item: string) => void;
  /**
   * Function to handle change of active suggestion.
   */
  onActiveSuggestionChange: (suggestion: number) => void;
}

/**
 * Component representing a list of suggestions.
 * @param filteredSuggestions - Array of filtered suggestions.
 * @param activeSuggestionIndex - Index of the active suggestion.
 * @param dataQa - Data attribute for testing.
 * @param onSelect - Function to handle selection of a suggestion.
 * @param onActiveSuggestionChange - Function to handle change of active suggestion.
 * @returns JSX.Element representing the SuggestionList component.
 */
const SuggestionList: React.FC<SuggestionListProps> = ({
  filteredSuggestions,
  activeSuggestionIndex,
  dataQa,
  onSelect,
  onActiveSuggestionChange,
}) => {
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<HTMLDivElement>(null);
  // Helps ensure that we are tying element styles to actual computed values.
  const MAX_ITEMS_PER_PAGE = 6;
  const ELEMENT_HEIGHT_DEFAULT = 36;
  const menuItemRefHeight =
    menuItemRef && menuItemRef.current ? menuItemRef.current.offsetHeight : ELEMENT_HEIGHT_DEFAULT;
  // Scrolls to the target element at the top of the list whenever we reach the bottom of said list.
  // This creates a paginated look - please see LMS1-4967.
  useEffect(() => {
    if (menuContainerRef.current && menuItemRef.current) {
      const containerRect = menuContainerRef.current.getBoundingClientRect();
      const elementRect = menuItemRef.current.getBoundingClientRect();
      // If the active element goes below or above the view of the container element, scroll to the top of the selected/highlighted element.
      if (elementRect.bottom > containerRect.bottom || elementRect.top < containerRect.top) {
        const menuItemRefTop = menuItemRef?.current?.offsetTop || 0;
        menuContainerRef.current.scrollTo({ top: menuItemRefTop });
      }
    }
  }, [activeSuggestionIndex]);

  const adjustDropdownHeight = () => {
    const dropdown = menuContainerRef?.current?.getBoundingClientRect();
    const minHeight = 3 * ELEMENT_HEIGHT_DEFAULT;
    const defaultHeight = MAX_ITEMS_PER_PAGE * ELEMENT_HEIGHT_DEFAULT + 80;
    const viewportHeight = window.innerHeight;
    if (!menuContainerRef.current || !dropdown?.top) {
      return `${defaultHeight}px`;
    }

    const availableSpace = viewportHeight - dropdown.top - 10; // -10 is for dropdown bottom visibility

    /**
     * return minHeight if availableSpace is lower than height of 3 items
     * return maxHeight if availableSpace is higher than default maxHeight which is MAX_ITEMS_PER_PAGE * ELEMENT_HEIGHT_DEFAULT
     * added 80px for more visibility
     */
    return `${Math.min(Math.max(minHeight, availableSpace), defaultHeight)}px`;
  };
  return (
    <div
      className="z-50 absolute bg-white mt-1 py-1 border rounded-md shadow-sm w-full max-h-72 overflow-y-auto"
      data-qa={`${dataQa}Dropdown`}
      data-testid={`${dataQa}Dropdown`}
      ref={menuContainerRef}
      style={{
        maxHeight: adjustDropdownHeight(),
      }}
    >
      {filteredSuggestions.map((suggestion, index) => (
        <div
          className={classNames(
            {
              'text-white bg-indigo-600 cursor-default': index === activeSuggestionIndex,
              'cursor-pointer': index !== activeSuggestionIndex,
            },
            'text-sm px-3 py-2'
          )}
          style={{ maxHeight: MAX_ITEMS_PER_PAGE * menuItemRefHeight + 'px' }}
          key={`${suggestion}-${index}`}
          onMouseDown={() => onSelect(suggestion)}
          onMouseMove={() => onActiveSuggestionChange(index)}
          ref={activeSuggestionIndex === index ? menuItemRef : null}
          data-qa={`${dataQa}Option`}
          data-testid={`${suggestion}Option`}
        >
          {suggestion}
        </div>
      ))}
    </div>
  );
};

export default SuggestionList;
