import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SpecialDiscountSlice, createSpecialDiscountSlice } from './createSpecialDiscountSlice';

export const addDiscountModalStore = create<SpecialDiscountSlice>()(
  devtools(
    (...args) => ({
      ...createSpecialDiscountSlice(...args),
    }),
    { serialize: true }
  )
);

export const useAddDiscountModalStore = addDiscountModalStore;
