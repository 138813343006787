import { ToolTip } from 'components/common/ToolTip/ToolTip';
import { ColorCheckBox } from 'components/invoicing/ShippingDetail/ColorCheckBox/ColorCheckBox';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useEffect } from 'react';
import { getCheckBoxVariant } from 'shared/helpers/invoice/invoice.helper';

/**
 * Props for the SaturdayDeliveryCheckbox component.
 */
interface IProps {
  /**
   * A boolean value indicating whether the Saturday delivery checkbox should be disabled.
   */
  disabled?: boolean;
}

/**
 * Saturday delivery checkbox component.
 * Allows users to toggle the option for Saturday delivery.
 * If external tracking number is available, the option will be disabled by default.
 * @param disabled - A boolean value indicating whether the Saturday delivery checkbox should be disabled.
 * @returns JSX element representing the Saturday delivery checkbox.
 */
export const SaturdayDeliveryCheckbox: React.FC<IProps> = ({ disabled }) => {
  const { invoiceShippingInput, setInvoiceShippingInput, doNotShipOnSaturday } = useInvoicingDetail();
  const { trackingNumberBundledOrder, isSaturdayDelivery } = invoiceShippingInput;

  /**
   * Handles the change event for the Saturday delivery checkbox.
   * Updates the isSaturdayDelivery property in the invoiceShippingInput state.
   * @param checked - Boolean value indicating whether the checkbox is checked.
   */
  const onChange = (checked: boolean) => {
    setInvoiceShippingInput({ isSaturdayDelivery: checked });
  };

  const saturdayEntryFound = doNotShipOnSaturday();
  const variant = getCheckBoxVariant(!!saturdayEntryFound, isSaturdayDelivery);

  /**
   * If external trackingNumber is available then this option will be disabled by default, but we need to render-
   * the isSignatureRequired as per the added tracking number's signature required property as per the AC-
   * So here we have to validate invoiceShippingInput.trackingNumber too.
   */
  useEffect(() => {
    if (disabled && !invoiceShippingInput.trackingNumber) {
      setInvoiceShippingInput({ isSaturdayDelivery: false });
    }
  }, [disabled, setInvoiceShippingInput, invoiceShippingInput.trackingNumber]);

  return (
    <div className="group relative">
      <ColorCheckBox
        label="Saturday Delivery"
        checked={isSaturdayDelivery}
        onChange={onChange}
        variant={variant}
        disabled={!!trackingNumberBundledOrder || !!disabled}
        isClickable
        labelClassName={disabled ? 'opacity-30' : undefined}
      />
      {disabled && <ToolTip text="Saturday delivery option is not available for your current shipping selection" />}
    </div>
  );
};
