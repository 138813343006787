import { ProductFullAttribute, TechnicalPreference } from 'API';
import { getAttributeQuantity } from 'shared/helpers/attribute/attribute.helper';
import { LocalOrderProductAttributeInput } from 'shared/models';
import { getProductAttributeByNameAndType } from '../order-entry.helper';

/**
 * Returns the initial value for the target attribute.
 * If they have a preference and are eligible to have it auto-filled, returns that value.
 * @param attributeValue - the target attribute's value, if it exists.
 * @param isNewOrPendingOrder - True if this is a new or pending order. False otherwise.
 * @param isNewProduct - True if this product does not currently exist in this order. False otherwise.
 * @param preferenceValue - the target attribute's preference, if one exists.
 * Returns the initial value for the target attribute/add-on.
 */
export const getInitialAttributeValue = (
  attributeValue = '',
  isNewOrPendingOrder: boolean,
  isNewProduct: boolean,
  preferenceValue?: string
) => {
  // Only new products and new pending orders are eligible to have their preference values auto-filled.
  const isPreferenceAutoFillEligible = isNewProduct || isNewOrPendingOrder;
  // If a preference exists and is eligible to be auto-filled, uses it as the default option whenever the attribute doesn't already have a value.
  if (preferenceValue && isPreferenceAutoFillEligible) {
    return attributeValue || preferenceValue;
  } else {
    // Defaults to empty string if not eligible for auto-fill.
    return attributeValue;
  }
};

/**
 * Returns the target order item attributes with eligible preferences applied.
 * @param technicalPreferences - The technical preferences for the target product.
 * @param orderItemAttributes - The currently selected product attributes.
 * @param productAttributes - The product attributes available for selection for the target product.
 * @param isNewOrPendingOrder - True if this is a new or pending order. False otherwise.
 * @param isNewProduct - True if this product does not currently exist in this order. False otherwise.
 * Returns the target order item attributes with eligible preferences applied.
 */
export const orderItemAttributesWithPreferencesApplied = (
  technicalPreferences: TechnicalPreference[],
  orderItemAttributes: LocalOrderProductAttributeInput[],
  productAttributes: ProductFullAttribute[],
  isNewOrPendingOrder: boolean,
  isNewProduct: boolean
) => {
  const attributeNamesAlreadyInOrderItem: string[] = [];
  const attributesToReturn = orderItemAttributes.map(attribute => {
    const preferenceValue =
      technicalPreferences?.find(preference => preference.attributeName === attribute.name)?.attributeValue || '';
    const newAttributeValue = getInitialAttributeValue(
      attribute.value,
      isNewOrPendingOrder,
      isNewProduct,
      preferenceValue
    );
    const data = {
      ...attribute,
      value: newAttributeValue,
      defaultValue: preferenceValue,
    };
    const foundProductAttribute = getProductAttributeByNameAndType(productAttributes, attribute.name, attribute.type);

    attributeNamesAlreadyInOrderItem.push(attribute.name);

    return {
      ...data,
      quantity:
        data.quantity ||
        getAttributeQuantity({
          ...data,
          attributeValueType: foundProductAttribute?.attributeValueType,
        }),
    };
  });

  // Adds attributes to the order for which a preference exists, if they are eligible and aren't currently in the order.
  productAttributes.forEach(productAttribute => {
    const preferenceValue = technicalPreferences.find(
      preference => preference.attributeName === productAttribute.name
    )?.attributeValue;
    const isAttributeAlreadyInOrderItem = attributeNamesAlreadyInOrderItem.includes(productAttribute.name);
    if (preferenceValue && !isAttributeAlreadyInOrderItem) {
      const newAttributeValue = getInitialAttributeValue('', isNewOrPendingOrder, isNewProduct, preferenceValue);
      const attributeData = {
        name: productAttribute.name,
        type: productAttribute.type,
        value: newAttributeValue,
        defaultValue: preferenceValue,
      };
      attributesToReturn.push({
        ...attributeData,
        quantity: getAttributeQuantity({ ...attributeData, attributeValueType: productAttribute.attributeValueType }),
      });
    }
  });

  return attributesToReturn;
};

/**
 * Returns the target order item add-ons with eligible preferences applied.
 * @param orderItemAddOns - The order item add-ons for the target product.
 * @param addOnsAndServices - The add-ons and services available for this order.
 * @param isNewOrPendingOrder - True if this is a new or pending order. False otherwise.
 * @param isNewProduct - True if this product does not currently exist in this order. False otherwise.
 * @returns - The order item add-ons with eligible preferences applied.
 */
export const orderItemAddOnsWithPreferencesApplied = (
  orderItemAddOns: LocalOrderProductAttributeInput[],
  addOnsAndServices: ProductFullAttribute[],
  isNewOrPendingOrder: boolean,
  isNewProduct: boolean
) => {
  return orderItemAddOns.map(attribute => {
    const preferenceValue = addOnsAndServices.find(addOn => addOn.name === attribute.name)?.defaultValue || '';
    const newAttributeValue = getInitialAttributeValue(
      attribute.value,
      isNewOrPendingOrder,
      isNewProduct,
      preferenceValue
    );
    const attributeQuantity = newAttributeValue && attribute.quantity ? attribute.quantity || 1 : 0;
    return {
      ...attribute,
      quantity: attributeQuantity,
      value: newAttributeValue,
      defaultValue: preferenceValue,
    };
  });
};
