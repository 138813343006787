import { PublicClientApplication } from '@azure/msal-browser';
import { Config, ConfigData, GetConfigsQuery } from 'API';
import { Amplify } from 'aws-amplify';
import axios from 'axios';
import { getMsalConfig } from 'components/Authentication/msalAuthConfig';
import { devServiceConfig } from 'configurations/service-configurations/dev-configuration';
import { prodServiceConfig } from 'configurations/service-configurations/prod-configuration';
import { qaServiceConfig } from 'configurations/service-configurations/qa-configuration';
import { uatServiceConfig } from 'configurations/service-configurations/uat-configuration';
import { getConfigs } from 'graphql/queries';
import Http from 'shared/api/http';
import { DeployedEnvironments, DeployedEnvironmentsNameMapping } from 'shared/constants/deployed-constants';
import { PrintHtmlType } from 'shared/enums/print-html-type';
import { ProjectInfo } from 'shared/models/projectInfo';
import { ServiceConfig } from 'shared/models/service-configs';

/**
 * class for configuring various services, instances etc.
 */
class ConfigurationService {
  serviceConfigs: ServiceConfig;
  projectInfo: ProjectInfo | null = null;
  apiUrl: string;
  private _configs: ConfigData;
  private msalInstance!: PublicClientApplication;

  constructor() {
    this.apiUrl = '';
    this._configs = {
      __typename: 'ConfigData',
      id: '',
      lastUpdatedDateUTC: '',
      sections: [],
    };

    this.serviceConfigs = {
      clientId: '',
      tenantId: '',
    };
  }

  getApiKey(): string | null {
    return this.projectInfo?.getApiKey() ?? '';
  }

  getAppVersion(): string {
    return this.projectInfo?.getAppVersion() ?? '';
  }

  getEnv(): string {
    return this.projectInfo?.getAppEnvironment() ?? '';
  }

  /**
   * Load configs required for app to load.
   */
  async load(sessionId: string): Promise<void> {
    if (!this.projectInfo) {
      this.projectInfo = await this.getProjectInfo();
      console.group('Project Info');
      console.log(`Current version: ${this.projectInfo.getAppVersion()}`);
      console.log(`Date Deployed: ${new Date(this.projectInfo.deployedTime)}`);
      console.log(`Deployed By: ${this.projectInfo.deployedBy}`);
      console.groupEnd();
    }

    this.setEnvironmentConfigs(this.projectInfo.getAppEnvironment());

    if (!DeployedEnvironmentsNameMapping[this.projectInfo.deployedEnvironment]) {
      throw new Error('Invalid environment mapping.');
    }

    // Setup MSAL Authentication
    this.msalSetup();

    // Need to run before we interact with any of the amplify libraries.
    Amplify.configure({
      aws_appsync_graphqlEndpoint: this.projectInfo.apiUrl,
      aws_project_region: 'us-east-1',
      aws_appsync_apiKey: this.projectInfo.apiKey, // Pulled from CICDDeployments -> Cloudfront database on deploy.
      aws_appsync_authenticationType: 'API_KEY',
      graphql_headers: async () => ({
        'gl-user-session-id': sessionId, // Set Custom Request Headers for non-AppSync GraphQL APIs
      }),
    });

    const response = await Http.handleGraphqlOperation<GetConfigsQuery>(getConfigs, undefined, 'API_KEY');
    this._configs = Http.processGraphqlResponse(response.getConfigs, 'ConfigData');
  }

  /**
   * Get the section by name from configs.
   * @param sectionName - section name in config manager.
   * @returns Config or null if section doesn't exist.
   */
  getSection(sectionName: string): Config | null {
    if (!this._configs) {
      throw new Error('Call load() before trying to get values');
    }

    for (const section of this._configs.sections) {
      if (section.name.toLowerCase() === sectionName.toLowerCase()) {
        return section;
      }
    }

    return null;
  }

  /**
   * Get a value within a config section.
   * @param sectionName - section name in config manager.
   * @param keyName - value key we want to pull.
   * @returns configuration value keyed to keyName from section Name
   */
  getConfigValue(sectionName: string, keyName: string): string | null {
    if (!this._configs) {
      throw new Error('Call load() before trying to get values');
    }

    const section = this.getSection(sectionName);

    if (section) {
      for (const setting of section.settings) {
        if (setting.key && setting.key.toLowerCase() === keyName.toLowerCase()) {
          return setting.value || null;
        }
      }
    }

    return null;
  }

  /**
   * Method to get correct printer url.
   * @param service - the print service we are trying to access.
   * @param printHtmlType - I = Invoice, D = Default, W = WorkOrder
   * @returns
   */
  getPrinterUrl(service: string, printHtmlType: PrintHtmlType = PrintHtmlType.Default) {
    const urlBase = 'http://localhost:4960/api';
    switch (service) {
      case 'PrintShippingLabel':
        return `${urlBase}/print?multiple=true`;
      case 'PrintHTML':
        return `${urlBase}/print/design-images?printType=${printHtmlType}`;
      case 'ListPrinters':
        return `${urlBase}/printers`;
      case 'GetDefaultWorkOrderPrinter':
        return `${urlBase}/print-configuration?printType=D`;
      case 'GetDefaultInvoicePrinter':
        return `${urlBase}/print-configuration?printType=I`;
      case 'SetDefaultPrinter':
        return `${urlBase}/print-configuration`;
      default:
        throw new Error('Invalid print URL service request');
    }
  }

  /**
   * retrieves project configuration from `projectInfo.json` file
   * @returns project config object containing details like apiKey, apiUrl, author etc.,
   */
  async getProjectInfo(): Promise<ProjectInfo> {
    try {
      const projectInfoResponse = await axios.get<ProjectInfo>('/projectInfo.json');
      if (!projectInfoResponse) {
        throw new Error(`Unable to retrieve project info.json`);
      }
      return new ProjectInfo(projectInfoResponse.data);
    } catch (err) {
      console.error('An Error Occurred', err);
      throw err;
    }
  }

  /**
   * sets a configuration basing environment specified
   * @param environment - current environment, see {@link DeployedEnvironments}
   */
  setEnvironmentConfigs(environment: string) {
    switch (environment) {
      case DeployedEnvironments.LMSPROD:
        this.serviceConfigs = prodServiceConfig;
        break;
      case DeployedEnvironments.LMSUAT:
        this.serviceConfigs = uatServiceConfig;
        break;
      case DeployedEnvironments.LMSQA:
        this.serviceConfigs = qaServiceConfig;
        break;
      default:
        this.serviceConfigs = devServiceConfig;
        break;
    }
  }

  /**
   * initializes MSAL related configs
   */
  private msalSetup() {
    const { clientId, tenantId } = this.serviceConfigs;

    if (!clientId || !tenantId) {
      throw new Error('Client ID or Tenant ID not defined.');
    }

    const msalConfig = getMsalConfig({ clientId, tenantId });
    this.msalInstance = new PublicClientApplication(msalConfig);
  }

  /**
   * returns MSAL instance
   */
  getMSALInstance() {
    return this.msalInstance;
  }

  /**
   * File configurations coming from Config Manager.
   */
  get fileConfigurations(): {
    downloadOnlyFileExtensions: string[];
    validFileExtensions: string[];
    validFileUploadSize: number;
    fileDownloadUrl: string;
  } {
    return {
      downloadOnlyFileExtensions: JSON.parse(this.getConfigValue('Settings', 'DownloadOnlyFileExtensions') || '[]').map(
        (value: string) => value.toLowerCase()
      ),
      validFileExtensions: JSON.parse(this.getConfigValue('Settings', 'ValidExtensions') || '[]').map((value: string) =>
        value.toLowerCase()
      ),
      validFileUploadSize: parseInt(this.getConfigValue('Settings', 'ValidFileUploadSize') || '10000000', 10),
      fileDownloadUrl: this.getConfigValue('APIEndpoints', 'DownloadFile') || '',
    };
  }
}

const ConfigService = new ConfigurationService();
export default ConfigService;
