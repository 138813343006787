import { CaseDiscountType } from 'API';
import { FC } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Props for the ActionButtons component.
 */
interface ActionButtonsProps {
  /**
   * Indicates whether the buttons are disabled.
   */
  disabled?: boolean;
  /**
   * Callback function to close the modal.
   */
  onClose: () => void;
  /**
   * Callback function to apply the discounts.
   */
  onApply: () => void;
}

/**
 * Component for action buttons in the discount modal.
 *
 * @param onClose - Callback function to close the modal.
 * @param onApply - Callback function to apply the discounts.
 * @param disabled - Indicates whether the buttons are disabled.
 * @returns an action buttons component.
 */
export const ActionButtons: FC<ActionButtonsProps> = ({ onClose, onApply, disabled }) => {
  const discounts = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const reason = useAddDiscountModalStore(state => state.specialDiscount.reason);
  const setReason = useAddDiscountModalStore(state => state.specialDiscount.setReason);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const setDiscountErrors = useAddDiscountModalStore(state => state.specialDiscount.setDiscountErrors);
  const discountAppliedFor = useAddDiscountModalStore(state => state.specialDiscount.discountAppliedFor);

  const onApplyHandler = () => {
    let isValid = true;
    if (selectedDiscountType === CaseDiscountType.PartOfCase) {
      discounts.forEach((discount, index) => {
        const { isPercentage, percentageValue, dollarAmount, itemId } = discount;
        const isItemIdValid = !!itemId;

        setDiscountErrors(index, { itemId: !isItemIdValid });
        if (isPercentage) {
          setDiscountErrors(index, { percentageValue: percentageValue <= 0 });
        } else {
          setDiscountErrors(index, { dollarAmount: dollarAmount <= 0 });
        }

        if (!isItemIdValid || (isPercentage ? percentageValue <= 0 : dollarAmount <= 0)) {
          isValid = false;
        }
      });
    }

    if (!reason.value) {
      setReason({ error: true });
      isValid = false;
    }

    if (!isValid) return;
    onApply();
  };

  return (
    <div className="flex flex-row-reverse h-16 bg-gray-50 px-4 py-3 rounded-b-lg">
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        data-testid="apply"
        onClick={onApplyHandler}
        disabled={disabled || !!(discountAppliedFor && discountAppliedFor === CaseDiscountType.EntireCase)}
      >
        Apply
      </button>
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={onClose}
        data-testid="cancel"
        disabled={disabled}
      >
        Cancel
      </button>
    </div>
  );
};
