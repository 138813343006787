import { OrderType, PackageType, ShippingOption } from 'API';
import Label from 'components/common/Label';
import Radio from 'components/common/Radio/Radio';
import { packageTypeConfig } from 'configurations/InvoiceConfiguration';
import _, { unionBy } from 'lodash';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useEffect, useMemo } from 'react';
import { AttributeName } from 'shared/enums';
import { findCaseAttributesByAttributeProperty } from 'shared/helpers/invoice/invoice.helper';
import { getOrderType } from 'shared/helpers/order-entry/order-entry.helper';

/**
 * Props for the PackageTypeElement component.
 */
interface PackageTypeElementProps {
  /** The shipping options available. */
  shippingOptions: ShippingOption[];
}

/**
 * Component for selecting package type.
 * @param shippingOptions - The shipping options available.
 * @returns The PackageTypeElement component.
 */
export const PackageTypeElement: React.FC<PackageTypeElementProps> = ({ shippingOptions }) => {
  const { setInvoiceShippingInput, invoiceShippingInput, outboundApplicableCase } = useInvoicingDetail();

  /**
   * Handles the selection of package type.
   *
   * @param packageType - The selected package type.
   */
  const onSelectPackageType = (packageType: PackageType) => {
    setInvoiceShippingInput({ packageType, carrier: '', shippingService: '' });
  };

  /**
   * If the order is digital or CT Scan, set the package type to envelope
   */
  useEffect(() => {
    if (outboundApplicableCase?.orderSource && outboundApplicableCase?.externalOrderNumber) {
      const isDigitalOrder =
        getOrderType(outboundApplicableCase.externalOrderNumber, outboundApplicableCase.orderSource) ===
        OrderType.Digital;
      const foundCTScan = findCaseAttributesByAttributeProperty(
        outboundApplicableCase.orderItems,
        AttributeName.CTScan,
        'name'
      )[0];
      const isCTScan = foundCTScan?.value === 'Yes';
      /**
       * If the order is digital or CT Scan or isCTScanEligible is true, set the package type to envelope
       */
      if (isDigitalOrder || isCTScan || outboundApplicableCase.isCTScanEligible) {
        setInvoiceShippingInput({ packageType: PackageType.Envelope, carrier: '', shippingService: '' });
      }
    }
  }, [
    outboundApplicableCase?.orderItems,
    outboundApplicableCase?.externalOrderNumber,
    outboundApplicableCase?.isCTScanEligible,
    outboundApplicableCase?.orderSource,
    setInvoiceShippingInput,
  ]);

  /**
   * Memoized radio options for package types.
   */
  const radioOptions = useMemo(() => {
    const packageTypes = unionBy(shippingOptions, 'package')
      .map(option => {
        if (!option.package)
          return {
            label: '',
            value: '',
          };
        const config = _.cloneDeep(packageTypeConfig[option.package]);
        if (config) {
          if (config.secondaryLabelField && !invoiceShippingInput.trackingNumberBundledOrder) {
            // if no option set, set the label to empty string
            config.label = option[config.secondaryLabelField]
              ? `${config.label} (${option[config.secondaryLabelField]})`
              : '';
          }
          return config;
        } else {
          return {
            label: option.package,
            value: option.package,
          };
        }
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });

    // if there is no label, don't show it
    return packageTypes.filter(pt => pt.label);
  }, [invoiceShippingInput.trackingNumberBundledOrder, shippingOptions]);

  return (
    <div className="my-4 sm:max-w-xs">
      <Label required htmlFor="packageType">
        Package Type
      </Label>
      <Radio
        id="packageType"
        options={radioOptions}
        value={invoiceShippingInput.packageType || ''}
        onValueChange={value => onSelectPackageType(value as PackageType)}
        disabled={!!invoiceShippingInput.trackingNumberBundledOrder}
      />
    </div>
  );
};
