import { AccountProvider } from 'API';

/**
 * returns an user friendly name for a given provider
 *
 * @param provider - an object representing a provider
 */
export const getProviderName = (provider: AccountProvider): string => {
  return `${provider.salutation ? `${provider.salutation}. ` : ''}${provider.firstName} ${provider.lastName}`;
};

/**
 * returns a user-friendly name from a given list of providers using a given `providerId`
 * @param providers - list of provider
 * @param providerId - id of provider of interest
 */
export const getProviderNameById = (providers: AccountProvider[] | undefined, providerId: string): string => {
  if (!providers) return '';
  const provider = providers.find(p => p.providerId === +providerId);
  if (!provider) return '';
  return getProviderName(provider);
};
