/**
 * represents the order type
 */
export enum OrderTypeName {
  /**
   * represents an RMA order item
   */
  RmaOrder = 'RmaOrder',

  /**
   * normal work order
   */
  Order = 'Order',

  PendingOrder = 'PendingOrder',

  /**
   * if the order is originated from legacy system, say Elektra
   */
  LegacyOrder = 'LegacyOrder',
}

export interface IPendingOrderData {
  __typename: 'PendingOrder';
}

export interface IOrderData {
  __typename: 'Order';
}

export interface ILegacyOrderData {
  __typename: 'LegacyOrder';
}

export interface IRmaOrderData {
  __typename: 'RmaOrder';
}
