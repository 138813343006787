import ToothChart from './ToothChart/ToothChart';

/**
 * Props for the ToothChartSingle component.
 */
export interface ToothChartSingleProps {
  /**
   * The selected teeth.
   */
  selected: string[];
  /**
   * Callback function invoked when a tooth is selected.
   */
  onSelect: (selected: string) => void;
}

/**
 * The ToothChartSingle component renders a single tooth chart.
 * @param selected - The selected teeth.
 * @param onSelect - Callback function invoked when a tooth is selected.
 * @returns JSX element representing the ToothChartSingle component.
 */
const ToothChartSingle: React.FC<ToothChartSingleProps> = ({ selected, onSelect }) => {
  return (
    <>
      <ToothChart
        selected={!selected ? [selected] : selected.map(s => s)}
        blocked={[]}
        onSelect={selected => onSelect(selected.toString())}
        onClear={() => onSelect('')}
      />
    </>
  );
};

export default ToothChartSingle;
