import { FacetFilter } from 'components/common/FacetFilter/FacetFilter';
import { useState } from 'react';
import { searchFilterFacets } from 'shared/constants/search.constants';
import { SearchFilterFacet } from 'types/common';

/**
 * Input props for {@link SearchFilter}
 */
interface SearchFilterProps {
  /**
   * call back function indicating user's intention to un/select a filter
   * @param facetId - represents which facet filter is changed,
   * @param option - represents which option user has un/selected
   */
  onFilterChange?: (facetId: SearchFilterFacet['id'], option: SearchFilterFacet['options'][number]) => void;

  /**
   * call back function indicating user's intention to apply new set of search filter values
   * @param selectedValues - set of current selected filters
   */
  onSelectedValuesChange?: (selectedValues: Record<SearchFilterFacet['id'], string[]>) => void;

  /**
   * call back function indicating user's intention to clear all applied filters
   */
  onClear?: () => void;
}

/**
 * Renders a component, presenting user option to un/select/clear filter queries
 *
 * @param onFilterChange - call back function indicating user's intention to un/select a filter
 * @param onSelectedValuesChange - call back function indicating user's intention to apply new set of search filter values
 * @param onClear - call back function indicating user's intention to clear all applied filters
 */
export const SearchFilter = ({ onFilterChange, onSelectedValuesChange, onClear }: SearchFilterProps) => {
  const [filterValues, setFilterValues] = useState<Record<SearchFilterFacet['id'], string[]>>({
    type: [],
    caseStatus: [],
  });

  /**
   * function to handle, change in applied filter values
   *
   * @param selectedValues - list of applied filter values
   */
  const onSelectedValuesChangeHandle = (selectedValues: Record<SearchFilterFacet['id'], string[]>) => {
    setFilterValues(selectedValues);
    onSelectedValuesChange?.(selectedValues);
  };

  return (
    <FacetFilter
      label={'Filter Results'}
      facets={searchFilterFacets}
      selectedValues={filterValues}
      onSelectedValuesChange={onSelectedValuesChangeHandle}
      onValueChange={onFilterChange}
      onClear={onClear}
    />
  );
};
