import { defaultServiceConfig } from 'configurations/service-configurations/default-configuration';

import { RumInitConfiguration, datadogRum } from '@datadog/browser-rum';
import { UserInfo } from 'types/common';

export interface DatadogConfigValue {
  applicationId: string;
  clientToken: string;
  sessionSampleRate?: number;
  sessionReplaySampleRate?: number;
  defaultPrivacyLevel?: RumInitConfiguration['defaultPrivacyLevel'];
  trackUserInteractions?: boolean;
  trackResources?: boolean;
  trackLongTasks?: boolean;
}

export const datadogRumInit = (env: string, datadogValue: string | null) => {
  if (!env) {
    return console.error('environment value is not defined');
  }
  if (!datadogValue) {
    console.warn(
      `[${env}]: Datadog Rum monitoring config setting was not found. Default setting is applied for Datadog Rum.`
    );
  }
  const value = JSON.parse(datadogValue || '{}') as DatadogConfigValue;
  const {
    sessionSampleRate = 0,
    sessionReplaySampleRate = 0,
    defaultPrivacyLevel = 'mask-user-input',
    trackUserInteractions = true,
    trackResources = true,
    trackLongTasks = true,
    applicationId,
    clientToken,
  } = value || {};

  if (!applicationId || !clientToken) {
    return console.warn('applicationId or clientToken is not defined for Datadog Rum');
  }

  const initConfiguration = datadogRum.getInitConfiguration();
  if (initConfiguration) return;

  datadogRum.init({
    ...defaultServiceConfig.datadogRum,
    applicationId,
    clientToken,
    env: env.toLowerCase(),
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    trackUserInteractions,
    trackResources,
    trackLongTasks,
    sessionSampleRate,
    sessionReplaySampleRate,
    defaultPrivacyLevel: defaultPrivacyLevel,
  });
  datadogRum.startSessionReplayRecording();
};

export const datadogRumSetUser = (user: UserInfo | null) => {
  if (user) {
    datadogRum.setUser({
      id: user.email || 'N/A',
      name: user.displayName || `${user.firstName} ${user.lastName}`.trim() || 'N/A',
      email: user.email || 'N/A',
      employeeId: user.employeeId || 'N/A',
    });
  } else {
    datadogRum.clearUser();
  }
};
