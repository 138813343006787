import { CaseAlert, TechnicalPreference } from 'API';
import _ from 'lodash';
import React, { PropsWithChildren, useState } from 'react';
import { CreatedOrder } from 'shared/models';

/**
 * Represents the context shape for the case detail module.
 */
interface Context {
  caseLoading: boolean;
  caseDetails?: CreatedOrder;
  technicalPreferences: TechnicalPreference[];
  caseAlerts: CaseAlert[];
}

/**
 * Represents the context actions for the case detail module.
 */
interface ActionsContext {
  setCaseLoading: (isLoading: boolean) => void;
  setCaseDetails: (caseDetails: CreatedOrder) => void;
  setTechnicalPreferences: (technicalPreferences: TechnicalPreference[]) => void;
  setCaseAlerts: (caseAlerts: CaseAlert[]) => void;
}

/**
 * Initial context for the case detail module actions.
 */
export const CaseDetailModuleActionsInitialContext: ActionsContext = {
  setCaseLoading: (isLoading: boolean) => {
    console.log('Is this really ', isLoading);
  },
  setCaseDetails: (caseData: CreatedOrder) => {
    console.log(caseData);
  },
  setTechnicalPreferences: (technicalPreferences: TechnicalPreference[]) => {
    return technicalPreferences;
  },
  setCaseAlerts: () => {
    return;
  },
};

/**
 * Initial state for the case detail module context.
 */
const initialState: Context = {
  caseLoading: false,
  technicalPreferences: [],
  caseAlerts: [],
};

/**
 * Context for managing the state of the case detail module.
 */
export const CaseDetailModuleContext = React.createContext<Context>(_.cloneDeep(initialState));
export const CaseDetailModuleActionsContext = React.createContext<ActionsContext>(
  CaseDetailModuleActionsInitialContext
);

/**
 * Provider component for the case detail module context.
 * @param children - Child components.
 * @param props - Additional properties for context.
 */
export const CaseDetailModuleProvider: React.FC<PropsWithChildren<Partial<Context>>> = ({ children, ...props }) => {
  const initialStateCopy = _.cloneDeep(initialState);
  const [caseLoading, setCaseLoading] = useState<boolean>(props.caseLoading || initialStateCopy.caseLoading);
  const [caseDetails, setCaseDetails] = useState<CreatedOrder | undefined>(
    props.caseDetails || initialStateCopy.caseDetails
  );
  const [technicalPreferences, setTechnicalPreferences] = useState<TechnicalPreference[]>([]);
  const [caseAlerts, setCaseAlerts] = useState<CaseAlert[]>([]);

  return (
    <>
      <CaseDetailModuleContext.Provider value={{ caseLoading, caseDetails, technicalPreferences, caseAlerts }}>
        <CaseDetailModuleActionsContext.Provider
          value={{ setCaseLoading, setCaseDetails, setTechnicalPreferences, setCaseAlerts }}
        >
          {children}
        </CaseDetailModuleActionsContext.Provider>
      </CaseDetailModuleContext.Provider>
    </>
  );
};

/**
 * Custom hook for accessing case detail module context and actions.
 * @returns Context and actions for the case detail module.
 */
export const useCaseDetail = () => {
  const { caseLoading, caseDetails } = React.useContext(CaseDetailModuleContext);
  const { setCaseLoading, setCaseDetails } = React.useContext(CaseDetailModuleActionsContext);

  return {
    caseLoading,
    caseDetails,
    setCaseLoading,
    setCaseDetails,
  };
};
