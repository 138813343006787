/**
 * represent the nature of toast being shown,
 * so toast theming can be accordingly modified
 */
export enum ToastNotificationType {
  Success,
  Error,
  Warning,
  Info,
}
