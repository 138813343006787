import { Menu, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { DropdownMenu } from 'types/dropdown-menu';

/**
 * Props for the DropdownMenuButton component.
 */
interface DropdownMenuButtonProps {
  /**
   * An array of menu items.
   */
  menuItems: DropdownMenu[];
  /**
   * The caption for the dropdown menu button.
   */
  caption?: string;
  /**
   * A string to be used as the value for data-qa attribute.
   */
  dataQa?: string;
  /**
   * Handler function called when a menu item is clicked.
   */
  onMenuClick: (menuItem: string) => void;
  /**
   * The icon to be displayed on the left side of the caption.
   */
  leftIcon?: React.ReactNode;
  /**
   * The icon to be displayed on the right side of the caption.
   */
  rightIcon?: React.ReactNode;
  /**
   * Handler function called when the button gains focus.
   */
  onFocus?: () => void;
}

/**
 * Component for rendering a dropdown menu button.
 * @param menuItems - An array of menu items.
 * @param caption - The caption for the dropdown menu button.
 * @param dataQa - A string to be used as the value for data-qa attribute.
 * @param onMenuClick - Handler function called when a menu item is clicked.
 * @param leftIcon - The icon to be displayed on the left side of the caption.
 * @param rightIcon - The icon to be displayed on the right side of the caption.
 * @param onFocus - Handler function called when the button gains focus.
 * @returns JSX.Element representing the DropdownMenuButton component.
 */
const DropdownMenuButton: React.FC<DropdownMenuButtonProps> = ({
  caption,
  menuItems,
  onMenuClick,
  leftIcon,
  rightIcon,
  onFocus,
}) => {
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            onFocus={onFocus}
            className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
            aria-label={caption || 'Options'}
          >
            <div className="flex gap-1 items-center">
              {leftIcon}
              {caption && <div className="mr-1 ml-2">{caption}</div>}
              {rightIcon}
            </div>
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {menuItems.map(menuItem => (
                <Menu.Item key={menuItem.id} disabled={menuItem.disabled}>
                  {({ active }) => (
                    <Link
                      to="#"
                      className={classNames('block px-4 py-2 text-sm', {
                        'bg-gray-100 text-gray-900': active,
                        'text-gray-700': !active,
                        'bg-gray-50 text-gray-900 opacity-30 cursor-not-allowed': !!menuItem.disabled,
                      })}
                      onClick={e => {
                        e.preventDefault();
                        onMenuClick(menuItem.name);
                      }}
                    >
                      <div className={classNames('flex gap-1 items-center', menuItem.className)}>
                        {menuItem.icon && <div>{menuItem.icon}</div>}
                        {menuItem.name}
                      </div>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default DropdownMenuButton;
