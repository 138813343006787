/**
 * The FooterLegend component displays a legend for the footer indicating different types of data sources.
 * @returns JSX element representing the legend for the footer
 */
const FooterLegend: React.FC = () => {
  return (
    <>
      <div className="py-2 text-gray-500 text-xs flex flex-row-reverse">
        Override
        <p className="ml-4 mr-1 inline-block h-3 w-3 border border-orange-500 bg-orange-100 rounded mt-0.5 p-px"></p>
        Preference
        <p className="ml-4 mr-1 inline-block h-3 w-3 border border-teal-500 bg-teal-100 rounded mt-0.5"></p>
        From Data Capture
        <p className="ml-4 mr-1 inline-block h-3 w-3 border border-blue-500 bg-blue-100 rounded mt-0.5"></p>
        <p className="text-xs ml-1 text-red-500">* Required Fields</p>
      </div>
    </>
  );
};

export default FooterLegend;
