import { OrderItem } from 'API';
import { cloneDeep } from 'lodash';
import { NEW_CASE } from 'shared/constants/constants';
import { getDefaultOrderInput } from 'shared/helpers/order-entry/order-entry.helper';
import { LocalOrderInput } from 'shared/models';
import { v4 as uuidv4 } from 'uuid';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type ExcludeBundleOrderKey = 'id' | 'isInvalid';

export interface BundleLocalOrderInput extends LocalOrderInput {
  id: string;
  isInvalid: boolean;
}

export interface BundledSplitCase {
  orderNumber: string;
  orderItems: OrderItem[];
}

interface State {
  /**
   * The selected tab id
   */
  selectedTab: string;
  /**
   * The list of orders
   */
  orders: BundleLocalOrderInput[];
  /**
   * The list of bundled cases (used for validating summary page of bundle split case)
   */
  bundledSplitCases: BundledSplitCase[];
  /**
   * The count of submit alert (used for validating bundle split case)
   */
  submitAlertCount: number;
  /**
   * The count of order updates for all orders (used for displaying a confirmation message if greater than 0)
   */
  orderBundleUpdateCount: number;
  /**
   * The initial order data (used for checking if the order has been updated)
   */
  initialOrderData: Record<string, LocalOrderInput>;
}

/**
 * Represents the types of the actions that can be performed on the bundle split case store.
 */
interface Actions {
  setSelectedTab: (selectedTab: string) => void;

  setOrders: (orders: BundleLocalOrderInput[]) => void;
  addOrder: (order: Omit<BundleLocalOrderInput, ExcludeBundleOrderKey>) => void;
  findOrder: (id: string) => BundleLocalOrderInput | undefined;
  deleteOrder: (id: string) => void;
  updateOrder: (updatedOrder: Partial<BundleLocalOrderInput> & { id: string }) => void;
  clearOrders: () => void;
  isValidAllOrders: () => boolean;

  checkIfBundleSplitIsActive: () => boolean;
  checkIfOrderIsExisting: (orderNumber: string) => boolean;

  setBundledSplitCases: (bundledSplitCases: BundledSplitCase[]) => void;

  increaseSubmitAlertCount: () => void;
  decreaseSubmitAlertCount: () => void;
  resetSubmitAlertCount: () => void;

  increaseOrderBundleUpdateCount: () => void;
  resetOrderBundleUpdateCount: () => void;
  addInitialOrderData: (orderId: string, orderInput: LocalOrderInput) => void;
  updateInitialOrderData: (orderId: string, orderInput: Partial<LocalOrderInput>) => void;
}

/**
 * Represents the combined types of the bundle split case store.
 */
export type BundleSplitCaseStore = State & Actions;

/**
 * Generates a new bundle order record with a unique id.
 */
export const generateBundleOrderRecord = (orderNumber: string): BundleLocalOrderInput => ({
  ...getDefaultOrderInput(),
  id: uuidv4(),
  orderNumber,
  isInvalid: false,
});

/**
 * Initializes the bundle split case store.
 */
export const useBundleSplitCaseStore = create<BundleSplitCaseStore>()(
  devtools(
    (set, get) => ({
      selectedTab: '',
      setSelectedTab: selectedTab => set({ selectedTab }),
      orders: [],
      setOrders: orders => {
        const { setSelectedTab } = get();
        set({ orders });
        setSelectedTab(orders[orders.length - 1]?.id || '');
      },
      findOrder: id => {
        const { orders } = get();
        return orders.find(order => order.id === id);
      },
      addOrder: order => {
        const { setSelectedTab, checkIfOrderIsExisting } = get();
        if (checkIfOrderIsExisting(order.orderNumber || '')) return;
        const id = uuidv4();
        set(state => ({ orders: [...state.orders, { ...order, id, isInvalid: false }] }));
        setSelectedTab(id);
      },
      deleteOrder: id => {
        const { setSelectedTab, orders } = get();
        const filteredOrders = orders.filter(order => order.id !== id);
        set({ orders: filteredOrders });
        setSelectedTab(filteredOrders.at(-1)?.id || '');
      },
      updateOrder: updatedOrder => {
        return set(state => ({
          orders: state.orders.map(order => {
            if (updatedOrder.id === order.id) {
              return { ...order, ...updatedOrder };
            }
            return order;
          }),
        }));
      },
      clearOrders: () => set({ orders: [] }),
      isValidAllOrders: () => {
        const { orders } = get();
        return orders.every(order => !order.isInvalid);
      },
      checkIfBundleSplitIsActive: () => {
        const { orders } = get();
        return orders.length > 1;
      },
      checkIfOrderIsExisting: orderNumber => {
        const { orders } = get();
        return orderNumber !== NEW_CASE && !!orders.find(order => order.orderNumber === orderNumber);
      },
      bundledSplitCases: [],
      setBundledSplitCases: bundledSplitCases => set({ bundledSplitCases }),

      initialOrderData: {},
      addInitialOrderData: (orderId, orderData) => {
        set(state => ({
          initialOrderData: {
            ...state.initialOrderData,
            [orderId]: cloneDeep(orderData),
          },
        }));
      },
      updateInitialOrderData: (orderId, orderData) => {
        set(state => ({
          initialOrderData: {
            ...state.initialOrderData,
            [orderId]: {
              ...state.initialOrderData[orderId],
              ...cloneDeep(orderData),
            },
          },
        }));
      },

      submitAlertCount: 0,
      increaseSubmitAlertCount: () => set(state => ({ submitAlertCount: state.submitAlertCount + 1 })),
      decreaseSubmitAlertCount: () => set(state => ({ submitAlertCount: state.submitAlertCount - 1 })),
      resetSubmitAlertCount: () => set({ submitAlertCount: 0 }),

      orderBundleUpdateCount: 0,
      increaseOrderBundleUpdateCount: () =>
        set(state => ({ orderBundleUpdateCount: state.orderBundleUpdateCount + 1 })),
      resetOrderBundleUpdateCount: () => set({ orderBundleUpdateCount: 0 }),
    }),
    { serialize: true }
  )
);
