import Sidebar from 'components/common/SideNav/SideNav';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

/**
 * renders a layout with a sidebar
 */
export const SidebarLayout: FC = () => {
  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Sidebar />
      <Outlet />
    </div>
  );
};
