import axios from 'axios';
import { PrintHtmlType } from 'shared/enums/print-html-type';
import ConfigService from 'shared/services/config.service';
import Http from './http';

export enum PrintType {
  ShippingLabel,
  HTML,
}

interface IPrintContent {
  printContent: string;
  displayContent: string;
}

export const openLabelPrintPage = (labelURL: string) => {
  openUrlInWindow(labelURL, '385px', '650px');
};

/**
 * Open html in a new window.
 * @param html - html to set.
 * @param width - width of window.
 * @param height  - height of window.
 */
export const openHtmlInWindow = (html: string, width = 'auto', height = '100%') => {
  if (!html) {
    throw Error('Invalid html content');
  }
  const contentWindow = window.open('', '_blank', `top=0, left=0, height=${height}, width=${width}`);
  contentWindow?.document.open();
  contentWindow?.document.write(html);

  // Complete writing to the document. We need this to ensure any inline js in the html is ran.
  contentWindow?.document.close();
};

/**
 * Open a URL in a new window.
 * @param url - the url to open.
 * @param width - width of window.
 * @param height - height of window.
 */
export const openUrlInWindow = (url: string, width = 'auto', height = '100%') => {
  window.open(url, '_blank', `top=0, left=0, height=${height}, width=${width}`);
};

/**
 * Print HTML content via the print streamer service.
 * If autoPrint is set to false in Config Manager, don't print but open a window instead.
 *
 * @param printType - the type printing we are doing (label, html, etc.).
 * @param printContent - the list of content that we want to print. Can be HTML or URLs.
 * @param printHtmlType - print type
 * @returns void promise.
 */
export const print = async (printType: PrintType, printContent: IPrintContent[], printHtmlType?: PrintHtmlType) => {
  const shippingLabelAutoPrint = ConfigService.getConfigValue('Settings', 'ShippingLabelAutoPrint');
  const htmlAutoPrint = ConfigService.getConfigValue('Settings', 'HtmlAutoPrint');
  const isShippingLabelAutoPrintDisabled = shippingLabelAutoPrint === 'false';
  const isHtmlAutoPrintDisabled = htmlAutoPrint === 'false';

  if (!printContent) {
    throw new Error('Invalid print content.');
  }

  switch (printType) {
    case PrintType.ShippingLabel:
      if (isShippingLabelAutoPrintDisabled) {
        console.info('Shipping Label auto-print set to false, opening window.');
        printContent.map(content => openLabelPrintPage(content.displayContent));
        return Promise.resolve();
      }
      await printLabel(printContent.map(content => content.printContent));
      break;
    case PrintType.HTML:
      if (isHtmlAutoPrintDisabled) {
        console.info('HTML (i.e. work order) auto-print set to false, opening window.');
        printContent.map(html => openHtmlInWindow(html.displayContent));
        return Promise.resolve();
      }
      await Promise.all(printContent.map(content => printHTML(content.printContent, printHtmlType)));
      break;
    default:
      return Promise.resolve();
  }
};

export const printLabel = async (labelUrls: string[]) => {
  const url = ConfigService.getPrinterUrl('PrintShippingLabel');
  const response = await axios.post(
    url,
    labelUrls.map(docUrl => {
      return {
        documentUrl: docUrl,
      };
    })
  );
  return response.data;
};

/**
 * sends a print order for a printer in network
 * @param html - HTML content to print
 * @param type - print type
 */
export const printHTML = async (html: string, type?: PrintHtmlType) => {
  const url = ConfigService.getPrinterUrl('PrintHTML', type);
  const htmlB64 = Http.convertStringToB64(html);
  const response = await axios.post(url, { html: htmlB64 });
  return response.data;
};

/**
 * fetches details of available printers
 */
export const getAllPrinters = async () => {
  const url = ConfigService.getPrinterUrl('ListPrinters');
  const response = await axios.get(url);
  return response.data;
};

export const getDefaultWorkOrderPrinter = async () => {
  const url = ConfigService.getPrinterUrl('GetDefaultWorkOrderPrinter');
  const response = await axios.get(url);
  return response.data;
};

export const getDefaultInvoicePrinter = async () => {
  const url = ConfigService.getPrinterUrl('GetDefaultInvoicePrinter');
  const response = await axios.get(url);
  return response.data;
};

export const setDefaultPrinter = async (printType: string, printer: string, printerTray: string) => {
  const url = ConfigService.getPrinterUrl('SetDefaultPrinter');
  const response = await axios.post(url, { printType, printer, printerTray });
  return response.data;
};
