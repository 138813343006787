import { FC, ReactNode } from 'react';
import { ListInfoItem } from './ListInfoItem/ListInfoItem';

/**
 * Props for the ListTypeBox component.
 */
interface ListTypeBoxProps {
  /**
   * Specifies whether the component is in loading state.
   */
  isLoading?: boolean;
  /**
   * Array of items to be displayed in the list.
   */
  items: {
    /**
     * Label for the list item.
     */
    label: string;
    /**
     * Value for the list item.
     */
    value: ReactNode;
  }[];
}

/**
 * Component for displaying a list of items with labels and values.
 * @param isLoading - Specifies whether the component is in loading state.
 * @param items - Array of items to be displayed in the list.
 * @returns JSX element representing the list of items.
 */
export const ListTypeBox: FC<ListTypeBoxProps> = ({ items, isLoading }) => {
  return (
    <div className="bg-white border p-4 sm:rounded-lg shadow-sm">
      <div className="flex flex-col gap-1">
        {items.map((item, index) => (
          <ListInfoItem key={`${item.label}-${index}`} isLoading={isLoading} testId={item.label} label={item.label}>
            {item.value}
          </ListInfoItem>
        ))}
      </div>
    </div>
  );
};
