import { OrderOriginatingSystem, ShippingChargeType } from 'API';
import { AccountStandingBadge } from 'components/common/AccountStandingBadge';
import { ShippingAddressText } from 'components/common/ShippingAddressText/ShippingAddressText';
import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import { useContext, useMemo } from 'react';
import { InvoiceAccount } from 'shared/api/invoice.api';
import { getShippingCharge } from 'shared/helpers/order-detail/order-detail.helper';
import { convertToCurrency, getFormattedDate, getOrderCodAmount, getPatientFullName } from 'shared/utils';

/**
 * Props for the CaseOverviewLeftPanel component.
 */
interface CaseOverviewLeftPanelProps {
  /** The account associated with the invoice. */
  account: InvoiceAccount | null;
}

/**
 * Component responsible for rendering the left panel of a case overview,
 * displaying various details related to the case.
 * @param account - The account associated with the invoice.
 * @returns JSX.Element - The rendered component.
 */
const CaseOverviewLeftPanel: React.FC<CaseOverviewLeftPanelProps> = ({ account }) => {
  const { caseDetails } = useContext(CaseDetailModuleContext);
  const {
    billingAccountId,
    externalOrderNumber,
    orderSource,
    patientFirstName,
    patientLastName,
    providerName,
    status,
  } = caseDetails || {};
  const labOrigin = caseDetails?.originFacility;
  const accountStanding = account?.standing;
  const accountStatus = account?.status;
  const shippingDetails = caseDetails?.shipping;
  const { shippingCharges, codAmount: orderShippingCodAmount } = shippingDetails || {};

  // The COD amount will either be the shipping COD amount (if the order is invoiced) or the COD amount for the target account.
  const codAmount = useMemo(
    () => getOrderCodAmount(account?.finance?.codAmount ?? 0, orderShippingCodAmount ?? 0, status),
    [account?.finance?.codAmount, orderShippingCodAmount, status]
  );

  const codFeeRate = useMemo(() => {
    return getShippingCharge(shippingCharges || [], ShippingChargeType.Cod);
  }, [shippingCharges]);

  const totalDiscountAmount = caseDetails?.totalDiscountAmount || 0;
  const totalAmount = caseDetails?.totalAmount || 0;

  const CodAmountSection = () => {
    return !codAmount ? null : (
      <>
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">COD Amount</div>
          <div
            className="text-base text-gray-900 w-2/5 text-right"
            data-qa="codAmountLabel"
            data-testid="codAmountLabel"
          >
            {convertToCurrency(codAmount)}
          </div>
        </div>
        <hr className="h-px my-0.5 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">COD Total</div>
          <div className="text-base text-gray-900 w-2/5 text-right" data-qa="codTotalLabel" data-testid="codTotalLabel">
            {convertToCurrency(codAmount + totalAmount)}
          </div>
        </div>
      </>
    );
  };

  const fullPatientName = getPatientFullName(patientFirstName, patientLastName);

  const shippingChargesSummary = (label: string, shippingType: ShippingChargeType) => {
    const testId = `${shippingType.toLocaleLowerCase()}AmountLabel`;
    return (
      <div className="flex">
        <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">{label}</div>
        <div className="text-base text-gray-900 w-2/5 text-right" data-qa={testId} data-testid={testId}>
          {convertToCurrency(getShippingCharge(shippingCharges || [], shippingType).amount)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="p-4 rounded-lg bg-white border border-gray-200 flex flex-col gap-4">
        <div>
          <div className="text-sm text-gray-500">
            {fullPatientName || !caseDetails?.patientId ? 'Patient Name' : 'Patient Id'}
          </div>
          <div className="text-base text-gray-900" data-qa="patientNameLabel" data-testid="patientNameLabel">
            {caseDetails && `${fullPatientName || caseDetails.patientId || 'Unknown Patient'}`}
          </div>
        </div>
        {externalOrderNumber && orderSource && (
          <div>
            <div className="text-sm text-gray-500">External Case ID</div>
            <div className="text-base text-gray-900" data-qa="externalCaseIdLabel" data-testid="externalCaseIdLabel">
              {externalOrderNumber}{' '}
              <span className="text-xs text-gray-500" data-qa="orderSource" data-testid="orderSource">
                ({orderSource})
              </span>
            </div>
          </div>
        )}
        <div>
          <div className="text-sm text-gray-500">Provider Name</div>
          <div className="text-base text-gray-900" data-qa="providerNameLabel" data-testid="providerNameLabel">
            {providerName || 'N/A'}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Account #</div>
          <div className="text-base text-gray-900 flex" data-qa="accountIdLabel" data-testid="accountIdLabel">
            {billingAccountId}
            {accountStanding && accountStatus && (
              <AccountStandingBadge accountStanding={accountStanding} accountStatus={accountStatus} />
            )}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Shipping Address</div>
          {shippingDetails?.address ? (
            <ShippingAddressText address={shippingDetails?.address} separateIntoTwoLine />
          ) : (
            'N/A'
          )}
        </div>
      </div>

      <div className="p-4 rounded-lg bg-white border border-gray-200 flex flex-col gap-4">
        <div>
          <div className="text-sm text-gray-500">Created on</div>
          <div className="text-base text-gray-900" data-qa="createdDateLabel" data-testid="createdDateLabel">
            {caseDetails
              ? getFormattedDate(caseDetails.createdDate, caseDetails.utcConversionTimeZoneCode, true)
              : 'N/A'}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Origin Facility</div>
          <div className="text-base text-gray-900" data-qa="originFacility" data-testid="originFacility">
            {labOrigin || 'N/A'}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Date Received</div>
          <div className="text-base text-gray-900" data-qa="receivedDateLabel" data-testid="receivedDateLabel">
            {caseDetails?.labReceivedDate
              ? getFormattedDate(caseDetails.labReceivedDate, caseDetails.utcConversionTimeZoneCode, true)
              : 'N/A'}
          </div>
        </div>
        <div>
          <div className="text-sm text-gray-500">Estimated Ship Date</div>
          <div
            className="text-base text-gray-900"
            data-qa="estimatedShipDateLabel"
            data-testid="estimatedShipDateLabel"
          >
            {caseDetails?.shipping?.estimatedShipDate
              ? getFormattedDate(caseDetails.shipping.estimatedShipDate, caseDetails?.utcConversionTimeZoneCode)
              : 'N/A'}
          </div>
        </div>
        {caseDetails?.invoiceDate && (
          <div>
            <div className="text-sm text-gray-500">Invoiced Date</div>
            <div className="text-base text-gray-900" data-qa="invoicedDateLabel" data-testid="invoicedDateLabel">
              {caseDetails
                ? getFormattedDate(caseDetails.invoiceDate, caseDetails.utcConversionTimeZoneCode, false)
                : 'N/A'}
            </div>
          </div>
        )}
        {caseDetails?.invoiceBy && (
          <div>
            <div className="text-sm text-gray-500">Invoiced By</div>
            <div className="text-base text-gray-900" data-qa="invoicedByLabel" data-testid="invoicedByLabel">
              {caseDetails.invoiceBy}
            </div>
          </div>
        )}
      </div>

      <div className="p-4 rounded-lg bg-white border border-gray-200 flex flex-col gap-1">
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">Subtotal</div>
          <div className="text-base text-gray-900 w-2/5 text-right" data-qa="subTotalLabel" data-testid="subTotalLabel">
            {convertToCurrency(caseDetails?.subtotalAmount ?? 0)}
          </div>
        </div>
        {caseDetails?.originatingSystem !== OrderOriginatingSystem.DlPlus && (
          <>
            {shippingChargesSummary('Shipping Inbound', ShippingChargeType.Inbound)}
            {shippingChargesSummary('Shipping Outbound', ShippingChargeType.Outbound)}
          </>
        )}
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">Discounts</div>
          <div
            className="text-base text-gray-900 w-2/5 text-right"
            data-qa="discountAmountLabel"
            data-testid="discountAmountLabel"
          >
            {totalDiscountAmount
              ? `-${convertToCurrency(totalDiscountAmount)}`
              : convertToCurrency(totalDiscountAmount)}
          </div>
        </div>
        {!!codFeeRate.amount && (
          <div className="flex">
            <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">COD Fee</div>
            <div className="text-base text-gray-900 w-2/5 text-right" data-qa="codFeeLabel" data-testid="codFeeLabel">
              {convertToCurrency(codFeeRate.amount)}
            </div>
          </div>
        )}
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">Tax</div>
          <div
            className="text-base text-gray-900 w-2/5 text-right"
            data-qa="taxAmountLabel"
            data-testid="taxAmountLabel"
          >
            {convertToCurrency(caseDetails?.totalTaxAmount ?? 0)}
          </div>
        </div>
        <hr className="h-px my-0.5 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">Invoice Total</div>
          <div
            className="text-base text-gray-900 w-2/5 text-right"
            data-qa="totalAmountLabel"
            data-testid="totalAmountLabel"
          >
            {convertToCurrency(totalAmount)}
          </div>
        </div>
        <CodAmountSection />
      </div>
      <div className="p-4 rounded-lg bg-white border border-gray-200 flex flex-col gap-1">
        <div className="flex">
          <div className="text-sm text-gray-500 flex-grow my-0.5 text-left">Notes</div>
        </div>
        <div className="flex">
          <div>{caseDetails?.notes || 'N/A'}</div>
        </div>
      </div>
    </>
  );
};

export default CaseOverviewLeftPanel;
