import { CheckIcon } from '@heroicons/react/24/outline';
import { Order } from 'API';
import { ROUTES } from 'components/navigation/Constants';
import { useAuth } from 'providers/AuthProvider';
import { useToast } from 'providers/ToastProvider';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastNotificationType } from 'shared/enums';
import { convertOrderToLocalOrderInputWithCoupons } from 'shared/helpers/order-detail/order-detail.helper';
import { getBaseProductOrderItem } from 'shared/helpers/order-entry/order-entry.helper';
import { LocalOrderInput } from 'shared/models';
import { generateUniqueId } from 'shared/utils';
import { BundleLocalOrderInput, useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { OrderEntryStatusModal } from '../OrderEntryStatusModal/OrderEntryStatusModal';

/**
 * Props interface for the ExchangeCaseCreationModal component.
 */
interface ExchangeCaseCreationModalProps {
  /**
   * Flag to indicate if the exchange is being processed.
   */
  isProcessingExchange: boolean;
  /**
   * Array of pending orders for the exchange.
   */
  exchangePendingOrders: Order[];
  /**
   * Function to set the pending orders for the exchange.
   */
  setExchangePendingOrders: (orders: Order[]) => void;
  /**
   * Flag to indicate if the user is being redirected to the order entry page.
   * This is used to redirect the user to the order entry page with the first exchange pending order number.
   * Don't use this prop if you have a scenario where you have to handle multiple exchange pending orders.
   * default false
   */
  isRedirectingToOrderEntry?: boolean;
}

/**
 * Component for displaying the modal for creating an exchange case.
 * @param isProcessingExchange - Flag to indicate if the exchange is being processed.
 * @param exchangePendingOrders - Array of pending orders for the exchange.
 * @param setExchangePendingOrders - Function to set the pending orders for the exchange.
 * @param isRedirectingToOrderEntry - Flag to indicate if the user is being redirected to the order entry page.
 * @returns JSX element representing the ExchangeCaseCreationModal component.
 */
export const ExchangeCaseCreationModal: FC<ExchangeCaseCreationModalProps> = ({
  isProcessingExchange,
  exchangePendingOrders,
  setExchangePendingOrders,
  isRedirectingToOrderEntry = false,
}) => {
  const setOrders = useBundleSplitCaseStore(state => state.setOrders);
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();
  const currentUserName = user?.displayName || '';
  const providerName = exchangePendingOrders[0]?.providerName || 'Unknown';
  const billingAccountId = exchangePendingOrders[0]?.billingAccountId || 'Unknown';

  const onConfirmExchangeOrder = async () => {
    try {
      // Convert the exchange pending orders to local orders input with coupons values
      const promises = exchangePendingOrders.map(order => {
        return convertOrderToLocalOrderInputWithCoupons(order, currentUserName);
      });
      const localOrders = await Promise.all(promises);
      // Filter out any invalid local orders
      const filteredLocalOrders = localOrders.filter((localOrder): localOrder is LocalOrderInput => !!localOrder);
      // Convert the local orders to bundle local order input
      const bundleExchangeOrders = filteredLocalOrders.map(localOrder => {
        const bundleLocalOrderInput: BundleLocalOrderInput = {
          ...localOrder,
          id: generateUniqueId(),
          isInvalid: false,
          orderItems: localOrder.orderItems.map(getBaseProductOrderItem),
        };
        return bundleLocalOrderInput;
      });

      setOrders(bundleExchangeOrders);
      setExchangePendingOrders([]);

      /**
       * If the isRedirectingToOrderEntry is true, then we will to redirect to the order entry page with the first exchange pending order number.
       * Below is the code that only works for the first exchange pending order number.
       * Currently we are not supporting to pass multiple exchange pending orders through the redirection URL.
       * At the time of writing this code, we can only pass the first exchange pending order number to the order entry page.
       */
      if (isRedirectingToOrderEntry) {
        const firstExchangePendingOrderNumber = bundleExchangeOrders[0].orderNumber;
        navigate({
          pathname: ROUTES.NEW_ORDER,
          search: `?orderNumber=${firstExchangePendingOrderNumber}`,
        });
      }
    } catch (error) {
      console.error('Error while converting order to local order input', error);
      toast.notify('Failed to create new case', ToastNotificationType.Error);
    }
  };

  return (
    <>
      {isProcessingExchange && (
        <OrderEntryStatusModal
          title="Processing exchange and generating new case"
          message="A new case with the new material type is being generated"
          isLoading
        />
      )}
      {!isProcessingExchange && !!exchangePendingOrders.length && (
        <OrderEntryStatusModal
          showCloseButton={false}
          title="New case created"
          message={`A new case has been created for ${providerName} (Account # ${billingAccountId}).`}
          actionText="Okay"
          actionVariant="primary"
          icon={
            <div className="bg-green-100 rounded-full p-4">
              <CheckIcon className="h-9 w-9 text-green-600" />
            </div>
          }
          onAction={onConfirmExchangeOrder}
        />
      )}
    </>
  );
};
