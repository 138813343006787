import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { CheckIcon } from '@heroicons/react/24/solid';
import { CaseAlertStatus } from 'API';
import classNames from 'classnames';
import moment from 'moment';
import { LocalCaseAlert, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useState } from 'react';
import { CaseAlertDetails } from 'shared/constants/case-alerts/details.constants';
import { getDateInShortFormat, getPatientFullName } from 'shared/utils';

/**
 * Represents the props for the AlertDetails component.
 */
interface AlertDetailsI {
  caseAlert: LocalCaseAlert; // The case alert object.
  selectAlert: (caseAlert: LocalCaseAlert) => void; // Function to select the alert.
  isOpenByDefault: boolean; // Indicates whether the details should be open by default.
}

/**
 * If the target alert is valid, returns the alert details.
 * @param eachAlert - The alert object that is being targeted.
 * @param index - The index of the target alert.
 * @returns the target alert's details.
 */
export const AlertDetails = (props: AlertDetailsI) => {
  const { caseAlert, selectAlert, isOpenByDefault } = props;
  const { createdDate, expireDate, patientFirstName, patientLastName } = caseAlert;
  const { order } = useContext(OrderModuleContext);
  // By default, the alert cards are closed.
  const [isOpen, setIsOpen] = useState(isOpenByDefault);

  const createdDateMoment = moment(createdDate);
  const createdDateString = createdDateMoment.format('MM/DD/YYYY');
  const createdTimeString = createdDateMoment.format('hh:mm a');
  const expirationDate = getDateInShortFormat(expireDate, order?.utcConversionTimeZone);

  /**
   * Returns a label to use for the selection button, depending on the status of that case alert in the current session.
   * @param caseAlert - The alert object that is being targeted.
   * @returns the label to use for the selection button.
   */
  const getSelectionButtonLabel = (caseAlert: LocalCaseAlert) => {
    const isSelected = caseAlert.status === CaseAlertStatus.Applied;
    return (
      <div className="flex align-middle">
        {isSelected && <CheckIcon className="h-4 w-4 mx-auto text-gray-700 mr-2" />}
        <div>{isSelected ? 'Selected' : 'Select'}</div>
      </div>
    );
  };

  const alertHeader = () => {
    return getPatientFullName(patientFirstName, patientLastName) || '(Incorrect or no patient info given on Rx)';
  };

  return (
    <div className="flex flex-col py-3 mx-4 border-b border-indigo-200" data-testid="caseAlertDetails">
      <div className="flex flex-row">
        <button
          type="button"
          className="inline-flex items-center border border-transparent text-sm"
          data-qa="toggleButton"
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5 mx-auto text-indigo-900" aria-hidden="true" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 mx-auto text-indigo-900" aria-hidden="true" />
          )}
          <div className="text-indigo-900 text-lg ml-2 font-semibold text-left">{alertHeader()}</div>
        </button>
      </div>
      <div className="text-indigo-700 text-xs ml-8 mt-1">
        Created {createdDateString} at {createdTimeString}
      </div>
      <div className={classNames(isOpen ? 'flex flex-col mb-1 mt-4 ml-8' : 'hidden')}>
        <CaseAlertDetails caseAlert={caseAlert} />
        <div className="d-flex align-middle mt-2 mb-2 space-x-2">
          <button
            type="button"
            className="inline-flex items-center px-4 py-3 border shadow-sm border-gray-300 text-sm leading-4 font-semibold rounded-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white-500"
            data-qa="selectAlertButton"
            onClick={() => selectAlert(caseAlert)}
          >
            {getSelectionButtonLabel(caseAlert)}
          </button>
        </div>
        <div className="mt-2 mb-2 text-xs">
          <p className="mb-2" data-testid="caseAlertDetailsExpiresOn">
            Expires on {expirationDate}
          </p>
        </div>
      </div>
    </div>
  );
};
