import { useEffect, useState } from 'react';
import { cn } from 'shared/utils';
import { ToggleState } from '../../shared/enums/toggle-state';
import { ToolTip } from './ToolTip/ToolTip';

/**
 * Props for the SelectedText component.
 */
interface SelectedTextProps {
  /** The currently selected option. */
  selectedOption: string;
  /** The value of the option. */
  value: string;
  /** Whether the option is disabled. */
  disabled?: boolean;
  /** Handler function for when the option is clicked. */
  onClick: (value: string) => void;
  /** Whether it is the first option in the list. */
  first?: boolean;
  /** Custom class name for the selected option. */
  selectedClassName?: string;
}

/**
 * Represents a selectable option in a toggle group.
 * @param selectedOption - The currently selected option.
 * @param value - The value of the option.
 * @param disabled - Indicates whether the option is disabled.
 * @param onClick - Handler function invoked when the option is clicked.
 * @param first - Indicates if it is the first option in the list.
 * @param selectedClassName - Custom class name for the selected option.
 * @returns A button representing the option.
 */
const SelectedText: React.FC<SelectedTextProps> = ({
  selectedOption,
  value,
  selectedClassName,
  onClick,
  disabled,
  first,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={cn('border w-full py-2', {
      'border-gray-300 bg-white': selectedOption === value,
      'border-gray-300': selectedOption !== value,
      [`${selectedClassName}`]: !!selectedClassName && selectedOption === value,
      'rounded-l-md border-r-0': !!first,
      'rounded-r-md': !first,
    })}
    onClick={() => onClick(value)}
  >
    {value}
  </button>
);

export interface ToggleOption {
  /** The value of the toggle option. */
  value: ToggleState;
  /** Tooltip text to display when hovering over the option. */
  toolTipText?: string;
  /** Whether the option is disabled. */
  disabled?: boolean;
}

/**
 * Props for the Toggle component.
 */
interface ToggleProps {
  /** The list of toggle options. */
  options: ToggleOption[];
  /** The currently selected option. */
  selectedOption?: ToggleOption;
  /** Handler function invoked when a new option is selected. */
  onToggle: (selectedOption: ToggleOption) => void;
  /** The data-qa attribute value for testing purposes. */
  dataQa?: string;
  /** The width of the toggle component. */
  width?: string;
  /** Custom class name for the component. */
  className?: string;
  /** Custom class name for the selected option. */
  selectedClassName?: string;
}

/**
 * Component representing a toggle group.
 * @param options - The list of toggle options.
 * @param selectedOption - The currently selected option.
 * @param onToggle - Handler function invoked when a new option is selected.
 * @param width - The width of the toggle component.
 * @param className - Custom class name for the component.
 * @param selectedClassName - Custom class name for the selected option.
 * @param dataQa - The data-qa attribute value for testing purposes.
 * @returns A toggle group component.
 */
const Toggle: React.FC<ToggleProps> = ({
  options,
  selectedOption: selected,
  onToggle,
  width,
  className,
  selectedClassName,
  dataQa,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    setSelectedOption(selected?.value || '');
  }, [selected?.value]);

  const setValue = (value: string) => {
    setSelectedOption(value);
    onToggle(options.find(state => state.value === value) || options[0]);
  };

  return (
    <>
      <div
        className={cn(
          'flex text-sm py-px font-medium rounded-md w-72 text-gray-900 focus:outline-none',
          className,
          width
        )}
        data-qa={dataQa}
      >
        {options.map((option, index) => {
          return (
            <div className="w-full group relative" key={option.value}>
              <SelectedText
                disabled={option.disabled}
                selectedOption={selectedOption}
                value={option.value}
                onClick={setValue}
                selectedClassName={selectedClassName}
                first={index === 0}
              />
              {option.toolTipText && <ToolTip text={option.toolTipText} />}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Toggle;
