import { CaseAlertsQueryVariables, CaseAlertStatus } from 'API';
import axios from 'axios';
import classNames from 'classnames';
import { TogglePanel } from 'components/common/TogglePanel';
import { usePrevious } from 'hooks/use-previous';
import moment from 'moment';
import { LocalCaseAlert, OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCaseAlerts } from 'shared/api/case-alerts.api';
import { ToastNotificationType } from 'shared/enums';
import useDebounce from 'shared/hooks/useDebounce';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { AlertCard, OrderAccountDetails } from './AlertCard/AlertCard';

/**
 * Props for the AlertPanel component.
 */
export interface AlertPanelProps {
  /**
   * Details of the order account.
   */
  orderAccountDetails: OrderAccountDetails;
}

/**
 * The AlertPanel component displays case alerts for an order.
 * @param orderAccountDetails - Details of the order account.
 * @returns JSX element representing the AlertPanel component.
 */
export const AlertPanel: React.FC<AlertPanelProps> = ({ orderAccountDetails }) => {
  const [showRightPanel, setShowRightPanel] = useState<boolean>(true);
  const { caseAlerts, order, noPatientInfoChecked } = useContext(OrderModuleContext);
  const { isCaseAlertPatientMatch, setCaseAlerts } = useContext(OrderModuleActionsContext);
  const { billingAccountId } = orderAccountDetails;
  const debouncedBillingAccNumberValue = useDebounce<string>(billingAccountId, 500);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const orderNumber = searchParams.get('orderNumber') || order.orderNumber || '';
  const toast = useContext(ToastContext);
  const { fetcher, loading } = useLazyQueryFetcher(getCaseAlerts);

  /**
   * Filters case alerts based on business requirements described below.
   * Returns filtered case alerts.
   */
  const filteredCaseAlerts = useMemo(() => {
    return (
      caseAlerts?.filter(caseAlert => {
        // As per Judy from the UX team, alerts that are applied should always be displayed.
        const isAppliedAlert = caseAlert.status === CaseAlertStatus.Applied;
        // As per Judy from the UX team, alerts with no patient information should always be displayed.
        const isAlertWithNoPatientInformation =
          !caseAlert.patientId && !caseAlert.patientFirstName && !caseAlert.patientLastName;
        // Alerts with matching patient information should be displayed.
        const isPatientMatch = isCaseAlertPatientMatch(caseAlert, {
          patientFirstName: order.patientFirstName || '',
          patientLastName: order.patientLastName || '',
          patientId: order.patientId || '',
          noPatientInfo: noPatientInfoChecked,
        });
        return isAppliedAlert || isAlertWithNoPatientInformation || isPatientMatch || false;
      }) || []
    );
  }, [
    caseAlerts,
    isCaseAlertPatientMatch,
    noPatientInfoChecked,
    order.patientFirstName,
    order.patientId,
    order.patientLastName,
  ]);

  // Used to determine whether or not the alerts information has changed.
  const activeAlerts = useMemo(() => filteredCaseAlerts.map(alert => alert.alertId).join(','), [filteredCaseAlerts]);
  const prevActiveAlerts = usePrevious(activeAlerts);

  /**
   * Opens the alerts panel if the current filtered alerts selection changes.
   * Otherwise, keeps them as-is, in case the user wants to minimize them.
   */
  useEffect(() => {
    if (activeAlerts && activeAlerts !== prevActiveAlerts) {
      setShowRightPanel(true);
    }
  }, [activeAlerts, prevActiveAlerts]);

  /**
   * Fetches the latest case alerts for the target billing account and provider id input.
   * @param props - The input containing the target billing account and provider id to retrieve case alerts for.
   */
  const fetchCaseAlerts = useCallback(
    async (props: CaseAlertsQueryVariables) => {
      try {
        const caseAlertResults = await fetcher(props);
        const caseAlertFilteredResults = caseAlertResults.caseAlerts
          .filter(eachAlert => {
            const isActive = !eachAlert.orderNumber && eachAlert.status === CaseAlertStatus.Active;
            const isSameOrder = eachAlert.orderNumber === orderNumber;
            const isExpired = eachAlert.expireDate && moment(eachAlert.expireDate).isBefore(moment());
            return (isActive || isSameOrder) && !isExpired;
          })
          .map((alert: LocalCaseAlert) => {
            if (alert.status) {
              alert.initialStatus = alert.status;
            }
            return alert;
          });
        setCaseAlerts(caseAlertFilteredResults);
      } catch (e) {
        if (
          (axios.isAxiosError(e) && (e.response?.status ?? 500) !== 404) ||
          (e instanceof Error && e.name !== 'NotFoundError')
        ) {
          toast.notify(
            'Unable to retrieve Alerts, if issue persists please contact ITSupport',
            ToastNotificationType.Error
          );
        }
        setCaseAlerts([]);
      }
    },
    [setCaseAlerts, orderNumber, toast, fetcher]
  );

  useEffect(() => {
    (async () => {
      if (debouncedBillingAccNumberValue) {
        const fetchCaseAlertsRequest: CaseAlertsQueryVariables = {
          input: { billingAccountId: debouncedBillingAccNumberValue },
        };
        await fetchCaseAlerts(fetchCaseAlertsRequest);
      }
    })();
  }, [debouncedBillingAccNumberValue, fetchCaseAlerts]);

  const updatePanelState = (currState: boolean) => {
    setShowRightPanel(currState);
  };

  return (
    <>
      <div
        className={classNames(
          {
            hidden: !showRightPanel || filteredCaseAlerts?.length === 0,
          },
          'w-80',
          'h-full'
        )}
      >
        <div className="absolute right-5 py-2">
          <TogglePanel showPanel={showRightPanel} updatePanelState={updatePanelState} />
        </div>
        <AlertCard loading={loading} filteredCaseAlerts={filteredCaseAlerts} />
      </div>
      <div className={classNames(!showRightPanel ? '' : 'hidden', 'w-8 my-2 px-2')}>
        <TogglePanel showPanel={showRightPanel} updatePanelState={updatePanelState} />
      </div>
    </>
  );
};
