import {
  Coupon,
  CreateOrderInput,
  Maybe,
  Order,
  OrderAttachmentInput,
  OrderItem,
  OrderItemInput,
  OrderResult,
  OrderType,
  PendingOrder,
  RelatedCasesQuery,
  ReturnType,
  RmaExchangeNewProductInput,
  RmaOrder,
  RmaOrderItem,
  SearchedOrder,
} from 'API';
import { FileUploadState } from 'shared/enums';
import { GetDataWithTypename, RemoveDataWithTypename } from 'types/generic';
import { LocalOrderProductAttributeInput } from '.';

export type SuccessOrderTypes = PendingOrder | Order;

// Add ID to make it easier to keep track of state locally.
export type LocalOrderItemInput = Omit<OrderItemInput, 'attributes' | 'addOns'> & {
  id: string;
  attributes: LocalOrderProductAttributeInput[];
  addOns: LocalOrderProductAttributeInput[];
  // RMA-specific fields:
  returnType?: ReturnType;
  returnReasons?: string[];
  isOldProductReturned?: boolean;
  newProduct?: RmaExchangeNewProductInput;
  isProcessSwap?: boolean;
  isLoaded?: boolean;
};

export type LocalOrderFileAttachments = OrderAttachmentInput & {
  uploadStatus?: FileUploadState;
  id: string;
  file?: File;
};

export enum LocalOrderType {
  RMA = 'RMA',
  Standard = 'Standard',
}

type LocalMetadata = {
  localOrderType: LocalOrderType;
  linkedOrderNumber?: Maybe<string>;
  previousOrderType?: OrderType;
};

export type LocalOrderInput = Omit<CreateOrderInput, 'orderItems' | 'fileAttachments' | 'coupons'> & {
  orderItems: LocalOrderItemInput[];
  fileAttachments: LocalOrderFileAttachments[];
  coupons: Coupon[];
  updatedBy?: string;
  localMetadata: LocalMetadata;
};

export type InvoiceOrderLookupOrder = Pick<
  Order,
  '__typename' | 'orderNumber' | 'status' | 'patientFirstName' | 'patientLastName' | 'patientId'
>;

export type InvoiceOrderLookupPendingOrder = Pick<PendingOrder, '__typename' | 'orderNumber' | 'status'>;

export type InvoiceOrderLookupSuccessTypes = InvoiceOrderLookupOrder | InvoiceOrderLookupPendingOrder;

export type InvoiceOrderLookupResult =
  | InvoiceOrderLookupOrder
  | InvoiceOrderLookupPendingOrder
  | Exclude<OrderResult, Order | PendingOrder>;

export type SearchedOrderMinimum = Omit<SearchedOrder, 'orderItems' | '__typename'>;

export interface PreviousOrderRouteState {
  orderId: string;
  orderItems: OrderItem[];
  errorMessage?: string;
  isReturnForCredit?: boolean;
}

export type CreatedOrder = Order | RmaOrder;

export type LmsOrder = RelatedOrder | RelatedRmaOrder | RelatedPendingOrder;

export type CreatedOrderItem = OrderItem | RmaOrderItem;

export type RelatedOrderDataResponse = RemoveDataWithTypename<
  RelatedCasesQuery['relatedCases'],
  'GenericError' | 'NotFoundError'
>;
export type RelatedOrderData = RelatedOrderDataResponse['orders'][number];

export type RelatedOrder = GetDataWithTypename<RelatedOrderData, 'Order'>;
export type RelatedRmaOrder = GetDataWithTypename<RelatedOrderData, 'RmaOrder'>;
export type RelatedPendingOrder = GetDataWithTypename<RelatedOrderData, 'PendingOrder'>;
export type RelatedLegacyOrder = GetDataWithTypename<RelatedOrderData, 'LegacyOrder'>;

export type RelatedOrderItem = RelatedOrder['orderItems'][number];
export type RelatedRmaOrderItem = RelatedRmaOrder['orderItems'][number];
