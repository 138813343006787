import classNames from 'classnames';
import Input from 'components/common/Input/Input';
import RequiredIndicator from 'components/common/RequiredIndicator';
import RequiredMessage from 'components/common/RequiredMessage/RequiredMessage';
import { ResetButton } from 'components/common/ResetButton';
import React, { useState } from 'react';

type AttributeTextInputProps = {
  value: string;
  onValueChange: (value: string) => void;
  onValueBlur?: (value: string) => void;
  isSubmitted: boolean;
  label?: string;
  id?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  inputRef?: React.RefObject<HTMLInputElement>;
  className?: string;
  isAbleToReset?: boolean;
  onValueReset?: () => void;
};

const AttributeTextInput: React.FC<AttributeTextInputProps> = ({
  value,
  onValueChange,
  onValueBlur,
  isSubmitted,
  label,
  isRequired,
  isDisabled,
  inputRef,
  id,
  className,
  isAbleToReset,
  onValueReset,
}) => {
  const [isTouched, setIsTouched] = useState<boolean>(false);
  // A validation error is shown when an attribute that has either been interacted with or submitted is required but has no current value.
  const hasValidationError = isRequired && !value && (isSubmitted || isTouched);

  // TODO: Replace different elements with commons components e.g. Input, InputError, Label

  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} />;

  return (
    <div className="relative">
      {label && (
        <div className="flex">
          <label data-testid={`textInputLabel_${id}`} htmlFor={id} className="flex text-sm font-medium text-gray-700">
            {label}
            {resetButton}
          </label>
          {isRequired && <RequiredIndicator />}
        </div>
      )}
      {!label && resetButton}
      <div className="mt-1 flex gap-2">
        <Input
          ref={inputRef}
          type="text"
          name={label || ''}
          id={id || ''}
          value={value}
          className={classNames(
            className,
            `w-full border outline-none focus:outline-none block sm:text-sm rounded-md shadow-sm ${
              isDisabled && 'bg-gray-50 border-gray-200 text-gray-400'
            }`
          )}
          onChange={e => onValueChange(e.target.value)}
          onBlur={e => {
            if (onValueBlur) onValueBlur(e.target.value);
            setIsTouched(true);
          }}
          isInvalid={hasValidationError}
          data-qa={id}
          data-testid={id}
          disabled={isDisabled}
          isFullWidth
        />
      </div>
      {hasValidationError && <RequiredMessage fieldName={label} />}
    </div>
  );
};

export default AttributeTextInput;
