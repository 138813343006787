import Images from 'assets/images';
import classNames from 'classnames';
import React from 'react';

/**
 * Props for the Loader component.
 */
interface LoaderProps {
  /** Indicates whether the loader should be shown. */
  show: boolean;
  /** Additional CSS class for styling. */
  className?: string;
  /** Indicates whether the loader should spin. */
  spin?: boolean;
}

/**
 * Component for displaying a loader.
 *
 * @param show - Indicates whether the loader should be shown.
 * @param className - Additional CSS class for styling.
 * @param spin - Indicates whether the loader should spin.
 * @returns JSX.Element - The rendered component.
 */
const Loader: React.FC<LoaderProps> = ({ show, className, spin }) => {
  return (
    <>
      {show && (
        <img
          src={Images.Spinner}
          className={classNames({
            [`${className || ''}`]: !!className,
            'text-white block h-fit w-fit': !className,
            'animate-spin': !!spin,
          })}
          alt="spinner"
        />
      )}
    </>
  );
};

export default Loader;
