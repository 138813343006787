import { FC } from 'react';
import {
  CaseHistoryAdditionalDetailsObject,
  getCaseHistoryEventTypeDisplay,
  LocalCaseHistoryItem,
  MANAGER_WORK_ITEM_EVENTS,
  SPLIT_BUNDLE_ORDER_EVENTS,
  TECHNICIAN_AND_ROUTING_EVENTS,
} from 'shared/constants/case-detail.constants';
import { jsonParser } from 'shared/utils';
import { AdditionalDetailsWithNewAndOld } from './components/AdditionalDetailsWithNewAndOld';
import { ManagerWorkItemEvent } from './components/ManagerWorkItemEvent';
import { SplitBundleOrderEvent } from './components/SplitBundleOrderEvent';
import { TechnicianOrRoutingEvent } from './components/TechnicianOrRoutingEvent';

/**
 * Props of CaseHistoryAdditionalDetails component.
 */
interface CaseHistoryAdditionalDetailsProps {
  /**
   * The row containing case history details.
   */
  row: LocalCaseHistoryItem;
  /**
   * The ID of the case history.
   */
  id: string;
}

/**
 * Component for displaying additional details of a case history.
 *
 * @param CaseHistoryAdditionalDetailsProps - The props of the CaseHistoryAdditionalDetails component.
 * @returns A JSX element representing the CaseHistoryAdditionalDetails component.
 */
export const CaseHistoryAdditionalDetails: FC<CaseHistoryAdditionalDetailsProps> = ({ row, id }) => {
  const { eventType, process, isProcessOverride, additionalDetails, reason, utcConversionTimeZoneCode } = row;

  const newData = jsonParser<CaseHistoryAdditionalDetailsObject>(additionalDetails?.new || null);
  const oldData = jsonParser<CaseHistoryAdditionalDetailsObject>(additionalDetails?.old || null);

  const isManagerWorkItemEvent = MANAGER_WORK_ITEM_EVENTS.includes(eventType);
  const isSplitBundleEvent = SPLIT_BUNDLE_ORDER_EVENTS.includes(eventType);
  const isTechnicianOrRoutingEvent = TECHNICIAN_AND_ROUTING_EVENTS.includes(eventType);
  const isFallbackEvent = !isManagerWorkItemEvent && !isSplitBundleEvent && !isTechnicianOrRoutingEvent;

  return (
    <div>
      {additionalDetails && (
        <AdditionalDetailsWithNewAndOld
          eventType={eventType}
          newData={newData}
          oldData={oldData}
          id={id}
          isFallbackEvent={isFallbackEvent}
          utcConversionTimeZoneCode={utcConversionTimeZoneCode}
        />
      )}
      {isManagerWorkItemEvent && (
        <ManagerWorkItemEvent
          eventType={eventType}
          isProcessOverride={isProcessOverride}
          process={process}
          NewManufacturingStatus={newData?.ManufacturingStatus}
          OldManufacturingStatus={oldData?.ManufacturingStatus}
          reason={reason}
        />
      )}
      {isSplitBundleEvent && <SplitBundleOrderEvent eventType={eventType} newData={newData} oldData={oldData} />}
      {isTechnicianOrRoutingEvent && <TechnicianOrRoutingEvent eventType={eventType} row={row} />}
      {isFallbackEvent && !additionalDetails && getCaseHistoryEventTypeDisplay(eventType)}
    </div>
  );
};
