import { AttributeRule } from 'API';
import AttributeSelectDropdown from 'components/order-entry/Products/Inputs/AttributeSelectDropdown/AttributeSelectDropdown';
import { useOrderEntryContext } from 'providers/OrderModuleProvider';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { DropdownModel, LocalOrderProductAttributeInput } from 'shared/models';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';
import { LocalAttributeAggregate } from 'types/order-entry';
import { AggregateChildAttribute } from './AggregateChildAttribute/AggregateChildAttribute';

/**
 * Finds the attribute condition based on the selected order item, attribute type, and selected tooth.
 * @param selectedOrderItem - The selected order item that contains attributes.
 * @param attributeType - The type of attribute to search for.
 * @param selectedTooth - The selected tooth value.
 * @returns The attribute condition that matches the given attribute type and selected tooth, or undefined if not found.
 */
const findAttributeCondition = <T extends { attributes: LocalOrderProductAttributeInput[] }>(
  selectedOrderItem: T | undefined,
  attributeType: string,
  selectedTooth: string
) => {
  return selectedOrderItem?.attributes.find(attr => attr.type === attributeType && attr.value === selectedTooth);
};

/**
 * Retrieves the attribute names from the given attribute rules.
 * @param attributeRules - An array of attribute rules.
 * @returns An array of attribute names.
 */
const getAttributeNamesFromAttributeRules = (attributeRules: AttributeRule[]) => {
  return attributeRules.flatMap(rule => rule.displayAttributes.map(attr => attr.name));
};

export type AggregateAttributeTypeEnumProps = Omit<LocalAttributeAggregate, 'attributeValueType'>;

export const AggregateAttributeTypeEnum: FC<AggregateAttributeTypeEnumProps> = ({
  attributeOptions,
  localTypeValue,
  type: attributeType,
  displayName,
  selectedTooth,
  defaultValue,
  isRequired,
  orderItemId,
}) => {
  const { order: localOrderData, updateOrderItem, onSubmitAlertCount, orderData } = useOrderEntryContext();

  const selectedOrderItem = useMemo(() => {
    return localOrderData?.orderItems.find(item => item.id === orderItemId);
  }, [localOrderData?.orderItems, orderItemId]);

  const productCode = selectedOrderItem?.productCode || '';
  const findProductFullAttributeByOption = useFetchCacheStore(state => state.findProductFullAttributeByOption);

  const selectedLocalAttribute = useMemo(() => {
    return findAttributeCondition(selectedOrderItem, localTypeValue, selectedTooth);
  }, [localTypeValue, selectedOrderItem, selectedTooth]);

  const nameValue = selectedLocalAttribute?.name || '';

  const getDefaultAttributeItem = useCallback(
    name => {
      /**
       * NOTE: The field `localTypeValue` comes from the custom built attribute and not the attribute being referenced in the attributeOptions array.
       * attributeOptions are really pointers to attributes that were returned when fetching product information.
       * Futurely, we may need to get the type directly from the attribute in case the actual attribute type can differ from the customized attribute type set
       * when building the attribute.
       *
       * Push the new attribute to the selected order item
       */
      const localInput: LocalOrderProductAttributeInput = {
        name: name,
        type: localTypeValue,
        value: selectedTooth,
        quantity: 1,
        isInvalid: isRequired ? !name : false,
      };
      return localInput;
    },
    [isRequired, localTypeValue, selectedTooth]
  );

  /**
   * Updates the name value of the selected attribute.
   * If the attribute is required, sets the isInvalid flag to true if the value is empty.
   * Removes attributes that are in the displayAttributeNames array from the selected order item.
   * Updates the order item with the updated attributes.
   *
   * @param currentValue - The new value for the attribute name.
   * @param previousValue - The previous value of the attribute name.
   */
  const onUpdateNameValue = useCallback(
    (currentValue: string, previousValue: string) => {
      if (!selectedOrderItem) return;

      if (selectedLocalAttribute) {
        selectedLocalAttribute.name = currentValue;
        selectedLocalAttribute.isInvalid = isRequired ? !currentValue : false;
      } else {
        selectedOrderItem.attributes.push(getDefaultAttributeItem(currentValue));
      }

      const productFullAttribute = findProductFullAttributeByOption(productCode, {
        name: previousValue,
        type: attributeType,
      });

      const displayAttributeNames = getAttributeNamesFromAttributeRules(productFullAttribute?.attributeRules || []);

      if (displayAttributeNames.length) {
        // Remove previously selected attributes if selectedTooth is equal to the attribute value and the attribute name or attribute type is in the displayAttributeNames array
        selectedOrderItem.attributes = selectedOrderItem.attributes.filter(attr => {
          /**
           * Check if the attribute name is in the displayAttributeNames array or the attribute type is in the displayAttributeNames array.
           * For Non-Inclusive/Inclusive Implant System, the attribute name is the type of the attribute.
           * We made this change to fix the issue where the attribute name is not in the displayAttributeNames array.
           * Issue ticket link - https://glidewell.atlassian.net/browse/LMS1-7363
           */
          const isAttributeExist =
            displayAttributeNames.includes(attr.name) || displayAttributeNames.includes(attr.type);
          if (attr.value === selectedTooth && isAttributeExist) return false;
          return true;
        });
      }

      updateOrderItem(selectedOrderItem.id, { attributes: selectedOrderItem.attributes });
    },
    [
      attributeType,
      findProductFullAttributeByOption,
      getDefaultAttributeItem,
      isRequired,
      productCode,
      selectedLocalAttribute,
      selectedOrderItem,
      selectedTooth,
      updateOrderItem,
    ]
  );

  /**
   * Create a new attribute if it doesn't exist
   */
  useEffect(() => {
    if (!selectedOrderItem) return; // if there is no selected order item, do nothing

    const foundAttribute = findAttributeCondition(selectedOrderItem, localTypeValue, selectedTooth);
    if (foundAttribute) return; // if the attribute already exists, do nothing

    let localNameValue = defaultValue || '';

    /**
     * If there is an orderData, find the attribute name from the orderData, otherwise use the default value
     * this logic is only used for edit case scenarios where the attribute name is already set in the orderData
     */
    if (orderData) {
      const orderItemData = orderData.orderItems.find(orderItem => {
        // Check if the order item id matches the selected order item id or the selected order item itemId
        return orderItem.itemId === selectedOrderItem.itemId || orderItem.itemId === selectedOrderItem.id;
      });
      const orderItemAttribute = findAttributeCondition(orderItemData, localTypeValue, selectedTooth);
      localNameValue = orderItemAttribute?.name ?? localNameValue;
    }

    selectedOrderItem.attributes.push(getDefaultAttributeItem(localNameValue));

    updateOrderItem(selectedOrderItem.id, { attributes: selectedOrderItem.attributes });
  }, [
    defaultValue,
    getDefaultAttributeItem,
    localTypeValue,
    orderData,
    selectedOrderItem,
    selectedTooth,
    updateOrderItem,
  ]);

  const options = useMemo<DropdownModel[]>(() => {
    return attributeOptions.map(option => {
      return { value: option, primaryLabel: option };
    });
  }, [attributeOptions]);

  return (
    <>
      <AttributeSelectDropdown
        label={displayName}
        options={options}
        value={nameValue}
        onValueChange={value => onUpdateNameValue(value, nameValue)}
        isRequired={isRequired ?? false}
        isSubmitted={onSubmitAlertCount > 0}
        defaultValue={defaultValue || ''}
      />
      {nameValue && (
        <AggregateChildAttribute
          attributeName={nameValue}
          attributeType={attributeType}
          selectedTooth={selectedTooth}
          productCode={productCode}
          orderItemId={orderItemId}
        />
      )}
    </>
  );
};
