import { OrderStatus } from 'API';
import OrderSearch from 'components/common/OrderSearch/OrderSearch';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { FC, useContext, useState } from 'react';
import { orderSearch } from 'shared/api/order.api';
import { NEW_CASE } from 'shared/constants/constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ACTIONS } from 'shared/enums/permission';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { cn } from 'shared/utils';
import styles from '../OrderEntryLookupPage.module.css';

/**
 * Input props for {@link OrderEntryLookupForm}
 */
interface OrderEntryLookupFormProps {
  /**
   * callback to handle submission of order number or user's intention to create a new case
   * @param orderNumber - submitted order number
   */
  onSubmit: (orderNumber: string) => Promise<void> | void;

  /**
   * used to decide if a button for creating new RMA order, should be shown
   */
  showNewRMAButton?: boolean;

  /**
   * class styles information applied to outer div to adjust spacing
   */
  className?: string;
}

/**
 * Renders a page facilitating entry point for new order entry/creation
 *
 * @param showNewRMAButton - used to decide if a button for creating new RMA order, should be shown
 * @param onSubmit - callback to handle submission of order number or user's intention to create a new case
 * @param className - class styles information applied to outer div to adjust spacing
 */
export const OrderEntryLookupForm: FC<OrderEntryLookupFormProps> = ({ showNewRMAButton, onSubmit, className }) => {
  const createCasePermission = useRoleBasedAccessControl(ACTIONS.CREATE_CASE);
  const alert = useContext(AlertModalContext);
  const [validationError, setValidationError] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const { fetcher: orderSearchFetcher } = useLazyQueryFetcher(orderSearch);

  /**
   * handles users intention to create / entry a new case
   *
   * if he does not have permission to create,
   * a warning modal is shown and further actions are not proceeded
   */
  const createOrderHandler = () => {
    if (createCasePermission.allowCreate) {
      onSubmit(NEW_CASE);
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
  };

  /**
   * handles submission of order number
   * resets the validation errors shown if already
   */
  const valueChangeHandler = () => {
    setValidationError('');
  };

  /**
   * function to handle, submission of order number
   *
   * @param orderNumber - order number submitted
   */
  const orderSearchHandler = async (orderNumber: string) => {
    let isSuccessful = false;
    setProcessing(true);
    setValidationError('');

    if (createCasePermission.allowCreate) {
      try {
        const searchResult = await orderSearchFetcher({ orderNumber, status: [OrderStatus.Pending] });
        if (searchResult.orders.length) {
          await onSubmit(orderNumber);
        } else {
          setProcessing(false);
          setValidationError(`Order information for ${orderNumber} not found.`);
        }
        isSuccessful = true;
      } catch (error) {
        setValidationError('Order does not exist.');
        setProcessing(false);
        console.error(error);
      }
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
    return isSuccessful;
  };

  return (
    <div className="flex flex-grow items-center justify-around">
      <div className={cn('rounded-2xl bg-white px-20 py-10', className)}>
        <div className={styles['order-entry-lookup-content']}>
          <OrderSearch
            id="orderSearch"
            label="Case #"
            errorMessage={validationError}
            showProgress={processing}
            onOrderSearch={orderSearchHandler}
            onValueChange={valueChangeHandler}
          />

          <div className="py-11 flex gap-3">
            <div className="flex-grow h-px border-b border-gray-300 pt-2.5"></div>
            <span className="flex-grow-0 text-sm text-gray-900">OR</span>
            <div className="flex-grow h-px border-b border-gray-300 pt-2.5"></div>
          </div>

          <button
            type="button"
            className="block w-full text-center border border-transparent text-sm py-2 rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={createOrderHandler}
            data-qa="createOrder"
          >
            Start A New Case
          </button>

          {showNewRMAButton && (
            <button
              type="button"
              className="block mt-2 w-full text-center border border-transparent text-sm py-2 rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              data-qa="returnMerchandise"
            >
              Create A New RMA
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
