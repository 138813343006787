import { ChevronRightIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';
import { NavigationPage } from 'shared/models';

/**
 * Props for the BreadCrumbs component.
 */
interface BreadCrumbsProps {
  /**
   * The array of navigation pages.
   */
  pages: NavigationPage[];
}

/**
 * Component for displaying breadcrumbs navigation.
 * @param pages - The array of navigation pages.
 * @returns JSX.Element representing the BreadCrumbs component.
 */
const BreadCrumbs: React.FC<BreadCrumbsProps> = ({ pages }) => {
  return (
    <>
      <nav className="flex" aria-label="Breadcrumb">
        <ol className="flex">
          {pages.map((page, index) => (
            <li key={page.title}>
              <div className="flex">
                {index > 0 && (
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 mx-4 text-gray-400" aria-hidden="true" />
                )}
                {page.current ? (
                  <label className="text-gray-500 text-sm font-medium">{page.title}</label>
                ) : (
                  <Link
                    to={page.href}
                    state={page?.routeState}
                    className="text-indigo-600 hover:text-indigo-800 text-sm font-medium"
                  >
                    {page.title}
                  </Link>
                )}
              </div>
            </li>
          ))}
        </ol>
      </nav>
    </>
  );
};

export default BreadCrumbs;
