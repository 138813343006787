import { DeliveryMethod, ShippingLabel, ShippingOrder, ShippingStatus, TrackingNumberItem } from 'API';
import classNames from 'classnames';
import { SkeletonBox } from 'components/common/Skeleton';
import { TableNoDataFound } from 'components/common/Table/TableNoDataFound';
import { ShippingServiceLabelKey, getShippingServiceLabel } from 'configurations/InvoiceConfiguration';
import { sortBy } from 'lodash';
import { ToastContext } from 'providers/ToastProvider';
import { useContext, useMemo } from 'react';
import { BaseConstants } from 'shared/constants/constants';
import { ToastNotificationType } from 'shared/enums';
import { getCarrierName } from 'shared/helpers/order-entry/order-entry.helper';
import { printShippingLabel } from 'shared/helpers/printing/print.helper';
import { CreatedOrder } from 'shared/models';
import { getDateAndTimeInShortFormat } from 'shared/utils';
import { TrackingNumberLink } from './TrackingNumberLink/TrackingNumberLink';

/**
 * Represents a table body cell in the CaseShipping component.
 * @param isVoided - Indicates whether the shipping order is voided
 * @param id - The id attribute of the cell
 */
const TBodyCell: React.FC<{ isVoided?: boolean; id?: string }> = ({ children, id, isVoided }) => {
  return (
    <td
      className={classNames('px-6 py-4 whitespace-nowrap text-sm font-normal', {
        'text-gray-700': !!isVoided,
        'text-gray-900': !isVoided,
      })}
      data-testid={id}
    >
      {children}
    </td>
  );
};

/**
 * Props for the TBodyRow component.
 */
interface TBodyRowProps {
  /** The status of the shipping order. */
  status: ShippingStatus;
  /** A function returning React nodes. */
  children: ({ isVoided }: { isVoided: boolean }) => React.ReactNode;
}

enum ShippingType {
  Inbound,
  Outbound,
}

type TrackingRowData = Omit<TrackingNumberItem, '__typename'> & { status: ShippingOrder['status']; type: ShippingType };
type MinimalShippingOrder = Pick<
  ShippingOrder,
  'createdBy' | 'createdDate' | 'trackingNumber' | 'status' | 'shippingLabelUrls' | 'carrier' | 'serviceName'
>;

/**
 * Represents a row in the table body of the CaseShipping component.
 */
const TBodyRow: React.FC<TBodyRowProps> = ({ children, status }) => {
  const isVoided = status === ShippingStatus.Voided;
  return <tr>{children({ isVoided })}</tr>;
};

/**
 * Props for the CaseShipping component.
 */
interface CaseShippingProps {
  /** The order associated with the cases. */
  order: CreatedOrder | undefined;
  /** An array of minimal shipping orders. */
  shippingData: MinimalShippingOrder[];
  /** A boolean indicating whether the component is in a loading state. */
  loading: boolean;
}
/**
 * Represents a component for displaying shipping information related to a case.
 * @param order - The order associated with the cases.
 * @param shippingData - An array of minimal shipping orders.
 * @param loading - A boolean indicating whether the component is in a loading state.
 * @returns JSX element representing the CaseShipping component.
 */
const CaseShipping: React.FC<CaseShippingProps> = ({ order, shippingData, loading }) => {
  const toast = useContext(ToastContext);

  const inboundShipping: TrackingRowData[] = useMemo(() => {
    return (
      order?.shipping?.inboundTrackingNumbers?.map(item => {
        const newObj = {
          ...item,
          status: ShippingStatus.Active,
          type: ShippingType.Inbound,
        };
        return newObj;
      }) || []
    );
  }, [order?.shipping?.inboundTrackingNumbers]);

  const outboundShipping = useMemo(() => {
    let outBoundData: TrackingRowData[] = [];
    if (order?.shipping?.deliveryMethod === DeliveryMethod.DoctorPickup) {
      outBoundData.push({
        trackingNumber: DeliveryMethod.DoctorPickup,
        insertedBy: order?.invoiceBy || 'N/A',
        insertionDate: order?.invoiceDate || 'N/A',
        status: ShippingStatus.Active,
        type: ShippingType.Outbound,
      });
    } else {
      outBoundData =
        order?.shipping?.outboundTrackingNumbers?.map(outbound => {
          return {
            ...outbound,
            status: ShippingStatus.Active,
            type: ShippingType.Outbound,
          };
        }) || [];
    }

    const parsedShippingData: TrackingRowData[] = shippingData
      .filter(item => item.status === ShippingStatus.Voided)
      .map(shippingItem => {
        return {
          trackingNumber: shippingItem.trackingNumber,
          insertedBy: shippingItem.createdBy,
          insertionDate: shippingItem.createdDate,
          status: shippingItem.status,
          type: ShippingType.Outbound,
          carrier: shippingItem.carrier,
          service: shippingItem.serviceName,
          shippingLabelUrls: shippingItem.shippingLabelUrls.map(item => {
            return {
              __typename: 'ShippingLabel',
              url: item.url || '',
              type: item.type,
            };
          }),
        };
      });

    outBoundData = [...outBoundData, ...parsedShippingData];
    return sortBy(outBoundData, item => item.insertionDate);
  }, [
    order?.invoiceBy,
    order?.invoiceDate,
    order?.shipping?.deliveryMethod,
    order?.shipping?.outboundTrackingNumbers,
    shippingData,
  ]);

  const shippingItemRows = useMemo(() => {
    return [...inboundShipping, ...outboundShipping];
  }, [inboundShipping, outboundShipping]);

  const printLabel = async (labels: ShippingLabel[] | undefined) => {
    try {
      await printShippingLabel(order?.orderNumber || '', false, labels || undefined);
    } catch (e) {
      const error = e as Error;
      toast.notify(error.message || 'Error printing shipping label.', ToastNotificationType.Error);
    }
  };

  const renderPrintButton = (tracking: TrackingRowData) => {
    return (
      <button
        key={'doctorPickUpPrintLabel'}
        type="button"
        className="inline-flex items-center px-3 py-2 mr-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        data-qa="printLabelButton"
        onClick={async () => {
          printLabel(tracking.shippingLabelUrls || undefined);
        }}
      >
        Shipping
      </button>
    );
  };

  const renderRowItems = () => (
    <>
      {shippingItemRows.map(tracking => {
        const isOutbound = tracking.type === ShippingType.Outbound;
        return (
          <TBodyRow key={tracking.insertionDate} status={tracking?.status}>
            {({ isVoided }) => (
              <>
                <TBodyCell isVoided={isVoided} id="shipping-trackingnumber">
                  <TrackingNumberLink
                    trackingNumber={
                      tracking.trackingNumber === DeliveryMethod.DoctorPickup
                        ? BaseConstants.No_Tracking
                        : tracking.trackingNumber
                    }
                    isVoided={isVoided}
                  />
                </TBodyCell>
                <TBodyCell isVoided={isVoided}>{isOutbound ? 'Outbound' : 'Inbound'}</TBodyCell>
                <TBodyCell isVoided={isVoided} data-testid="shipping-carrier">
                  {getCarrierName(tracking.trackingNumber, tracking.carrier || undefined)}
                </TBodyCell>
                <TBodyCell isVoided={isVoided}>
                  {tracking.service ? getShippingServiceLabel(tracking.service as ShippingServiceLabelKey) : 'N/A'}
                </TBodyCell>
                <TBodyCell isVoided={isVoided}>
                  {getDateAndTimeInShortFormat(tracking.insertionDate, order?.utcConversionTimeZoneCode)}
                </TBodyCell>
                <TBodyCell isVoided={isVoided}> {tracking.insertedBy}</TBodyCell>
                {isOutbound ? <TBodyCell>{renderPrintButton(tracking)}</TBodyCell> : <TBodyCell />}
              </>
            )}
          </TBodyRow>
        );
      })}
    </>
  );

  if (loading) {
    return (
      <div className="p-6 flex gap-2 flex-col">
        <SkeletonBox className="w-full h-8" />
        <SkeletonBox className="w-full h-8" />
        <SkeletonBox className="w-full h-8" />
      </div>
    );
  }

  return (
    <>
      <div className="p-6">
        <div className="shadow overflow-hidden border border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Tracking #
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Inbound/Outbound
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Carrier
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Carrier Service
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Added Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Added By
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Print Label
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {shippingItemRows.length ? renderRowItems() : <TableNoDataFound colSpan={7} />}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default CaseShipping;
