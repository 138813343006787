import { ProductAttributeValueType } from 'API';
import { Fragment } from 'react';
import { LocalAttributeAggregate } from 'types/order-entry';
import { AggregateAttributeTypeEnum } from './AggregateAttributeTypeEnum';

const components: Record<
  ProductAttributeValueType,
  React.FunctionComponent<Omit<LocalAttributeAggregate, 'attributeValueType'>>
> = {
  [ProductAttributeValueType.Enum]: AggregateAttributeTypeEnum,
  [ProductAttributeValueType.String]: Fragment, // TODO: not implemented yet (not needed for now)
  [ProductAttributeValueType.Bool]: Fragment, // TODO: not implemented yet (not needed for now)
  [ProductAttributeValueType.Number]: Fragment, // TODO: not implemented yet (not needed for now)
  [ProductAttributeValueType.ToothSelection]: Fragment, // TODO: not implemented yet (not needed for now)
  [ProductAttributeValueType.Diagram]: Fragment, // TODO: not implemented yet (not needed for now)
  [ProductAttributeValueType.Radio]: Fragment, // TODO: not implemented yet (not needed for now)
};

export type AttributeAggregateDynamicItemProps = LocalAttributeAggregate;

export const AttributeAggregateDynamicItem: React.FC<AttributeAggregateDynamicItemProps> = ({
  attributeValueType,
  ...restProps
}) => {
  const Component = attributeValueType ? components[attributeValueType] : Fragment;
  return <Component {...restProps} />;
};
