import { APP_PERMISSION_KEY } from 'shared/constants/role-based-access-control';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';

/**
 * Props for the RestrictedContent component.
 */
interface RestrictedContentProps {
  /** The permission required to render the content. */
  permission: APP_PERMISSION_KEY;
  /** The error message to be displayed if the user does not have the required permission. */
  errorMessage?: string;
}

/**
 * Component for rendering content based on user permissions.
 *
 * @param permission - The permission required to render the content.
 * @param errorMessage - The error message to be displayed if the user does not have the required permission.
 * @returns JSX.Element - The rendered component.
 */
export const RestrictedContent: React.FC<RestrictedContentProps> = ({ children, permission, errorMessage }) => {
  const { allowVisible } = useRoleBasedAccessControl(permission);

  return allowVisible ? <>{children}</> : <div className="text-red-500 text-sm">{errorMessage}</div>;
};
