import { GraphQLSubscription } from '@aws-amplify/api';
import {
  BaseOrderItem,
  BundleSplitOrderInput,
  CreateBundleSplitOrderMutation,
  CreateOrderMutation,
  CreateOrderMutationVariables,
  EnclosedItemsQuery,
  EnclosedItemsQueryVariables,
  OrderInvoiceLookupQuery,
  OrderInvoiceLookupQueryVariables,
  OrderItem,
  OrderNotificationAvailableSubscription,
  OrderNotificationAvailableSubscriptionVariables,
  OrderQuery,
  OrderQueryVariables,
  OrderSearchInput,
  OrderSearchQuery,
  OrderShippingQuery,
  OrderShippingQueryVariables,
  OrderSplitBundle,
  OrderTransactionSearchQuery,
  OrderTransactionSearchQueryVariables,
  RelatedCasesInput,
  RelatedCasesQuery,
  RelatedCasesSearchQuery,
  RelatedCasesSearchQueryVariables,
  RemoveBundledOrderMutation,
  RemoveBundledOrderMutationVariables,
  SearchedOrder,
  ShippingOrderTrackingNumberQuery,
  ShippingOrderTrackingNumberQueryVariables,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
  UpdateOrderStatusInput,
  UpdateOrderStatusMutation,
} from 'API';
import { API, graphqlOperation } from 'aws-amplify';
import {
  createBundleSplitOrder as createBundleSplitOrderMutation,
  createOrder as createOrderQuery,
  removeBundledOrder as removeBundleOrderMutation,
  updateOrder as updateOrderQuery,
  updateOrderStatus as updateOrderStatusMutation,
} from 'graphql/mutations';
import {
  enclosedItems,
  existingTrackingNumberQuery,
  orderInvoiceLookup,
  orderLookup,
  orderLookupFull,
  orderSearch as orderSearchQuery,
  orderShipping,
  relatedCases as relatedCasesQuery,
  relatedCasesSearchQuery,
  shippingOrderTrackingNumberQuery,
} from 'graphql/queries';
import { orderNotificationAvailableSubscription } from 'graphql/subscriptions';
import {
  convertOrderQueryToOrderType,
  normalizeRelatedCasesQueryResponse,
} from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrder } from 'shared/models';
import Http from './http';

type InvoiceLookupBundles = Omit<CreatedOrder['bundles'], 'splitBundle'> & {
  splitBundle?: {
    bundledOrderNumbers: Array<Pick<SearchedOrder, 'orderNumber'>>;
    bundleId: OrderSplitBundle['bundleId'];
  } | null;
};

type InvoiceLookupBundle = InvoiceLookupBundles | null;

export type InvoiceLookupOrder = Pick<
  CreatedOrder,
  | '__typename'
  | 'orderNumber'
  | 'status'
  | 'statusReason'
  | 'patientFirstName'
  | 'patientLastName'
  | 'patientId'
  | 'billingAccountId'
> & { orderItems: Array<BaseOrderItem & { addOns: OrderItem['addOns'] }> } & { bundles?: InvoiceLookupBundle | null };

/**
 * Returns enclosed items for inbound and outbound categories
 * @param input - inbound or outbound category
 * @returns enclosed items for a category
 */
export const getEnclosedItems = async (input: EnclosedItemsQueryVariables = {}) => {
  const response = await Http.handleGraphqlOperation<EnclosedItemsQuery>(enclosedItems, input);
  const result = Http.processGraphqlResponse(response.enclosedItems, 'EnclosedItemsWrapper');
  return result.enclosedItems;
};

/**
 * Creates a new work order
 * @param data - request body for creating a new work order
 * @returns object representing a created order
 */
export const createOrder = async (data: CreateOrderMutationVariables) => {
  const response = await Http.handleGraphqlOperation<CreateOrderMutation>(createOrderQuery, data);
  return Http.processGraphqlResponse(response.createOrder, 'Order');
};

/**
 * updates a given work order
 * @param data - request body for updating work order
 * @returns object representing an updated order
 */
export const updateOrder = async (data: UpdateOrderMutationVariables) => {
  const response = await Http.handleGraphqlOperation<UpdateOrderMutation>(updateOrderQuery, data);
  return Http.processGraphqlResponse(response.updateOrder, 'Order');
};

/**
 * Get an order by orderNumber - Excludes discounts.
 * @param variables - order lookup vars.
 * @returns SuccessOrderType - the parsed response so the results are normalized to the actual models and not the query models.
 */
export const getOrder = async (variables: OrderQueryVariables) => {
  const response = await Http.handleGraphqlOperation<OrderQuery>(orderLookup, variables);
  const result = Http.processGraphqlResponse(response.order, ['Order', 'PendingOrder', 'LegacyOrder', 'RmaOrder']);
  return convertOrderQueryToOrderType(result);
};

/**
 * Get an order by orderNumber - Full details including discounts.
 * @param variables - order lookup vars.
 * @returns SuccessOrderType - the parsed response so the results are normalized to the actual models and not the query models.
 */
export const getOrderFull = async (variables: OrderQueryVariables) => {
  const response = await Http.handleGraphqlOperation<OrderQuery>(orderLookupFull, variables);
  const result = Http.processGraphqlResponse(response.order, ['Order', 'PendingOrder', 'LegacyOrder', 'RmaOrder']);
  return convertOrderQueryToOrderType(result);
};

/**
 * Get an order by orderNumber for invoice order search page.
 * @param variables - order lookup vars.
 * @returns SuccessOrderType - object represents looked up invoice
 */
export const getOrderInvoiceLookup = async (variables: OrderInvoiceLookupQueryVariables) => {
  const response = await Http.handleGraphqlOperation<OrderInvoiceLookupQuery>(orderInvoiceLookup, variables);
  return Http.processGraphqlResponse(response.order, ['Order', 'PendingOrder', 'RmaOrder']);
};

/**
 * Search for orders based on input criteria.
 * @param input - order search input.
 * @returns object containing array of orders if any are found.
 */
export const orderSearch = async (input: OrderSearchInput) => {
  const response = await Http.handleGraphqlOperation<OrderSearchQuery>(orderSearchQuery, { input });
  return Http.processGraphqlResponse(response.orderSearch, 'SearchedOrderWrapper');
};
/**
 * Get related cases with orders output.
 * @param input - order search input.
 * @returns object containing array of orders if any are found.
 */
export const getRelatedCasesWithAddOnsAndServices = async (input: RelatedCasesInput) => {
  const response = await Http.handleGraphqlOperation<RelatedCasesQuery>(relatedCasesQuery, { input });
  const result = Http.processGraphqlResponse(response.relatedCases, 'RelatedCasesWrapper');
  return normalizeRelatedCasesQueryResponse(result);
};

/**
 * looks up cases related to a case
 *
 * @param variables - request body for fetching related cases
 *
 * @returns response body containing list of related search results and total number of results
 */
export const relatedCasesSearch = async (variables: RelatedCasesSearchQueryVariables) => {
  const response = await Http.handleGraphqlOperation<RelatedCasesSearchQuery>(relatedCasesSearchQuery, variables);
  return Http.processGraphqlResponse(response.relatedCasesSearch, 'SearchedOrderWrapper');
};

/**
 * updates order status for a given order number
 * @param input - request body for updating order status
 * @returns order with updated status
 */
export const updateOrderStatus = async (input: UpdateOrderStatusInput) => {
  const response = await Http.handleGraphqlOperation<UpdateOrderStatusMutation>(updateOrderStatusMutation, { input });
  return Http.processGraphqlResponse(response.updateOrderStatus, 'Order');
};

/**
 * fetches shipping details for an order
 * @param variables - order number
 * @returns response body representing shipping details of an order
 */
export const getOrderShipping = async (variables: OrderShippingQueryVariables) => {
  const response = await Http.handleGraphqlOperation<OrderShippingQuery>(orderShipping, variables);
  return Http.processGraphqlResponse(response.order, ['Order', 'RmaOrder']);
};

/**
 * returns a list of transactions
 * @param input - request body
 * @returns response body containing list of transactions (orders, pending orders, RmaOrders)
 */
export const validateExistingTrackingNumber = async (input: OrderTransactionSearchQueryVariables['input']) => {
  const response = await Http.handleGraphqlOperation<OrderTransactionSearchQuery>(existingTrackingNumberQuery, {
    input,
  });
  const result = Http.processGraphqlResponse(response.orderTransactionSearch, 'OrderTransactionWrapper');
  return result.transactions;
};

/**
 * fetches a list of available tracking numbers using `orderNumber`
 *
 * @param variables - order number
 *
 * @returns list of tracking numbers related to `orderNumber`
 */
export const getShippingOrderTrackingNumbers = async (variables: ShippingOrderTrackingNumberQueryVariables) => {
  const response = await Http.handleGraphqlOperation<ShippingOrderTrackingNumberQuery>(
    shippingOrderTrackingNumberQuery,
    variables
  );
  const result = Http.processGraphqlResponse(response.shippingOrder, 'ShippingOrderWrapper');
  return result.items;
};

/**
 * listens to available order notification
 * @param input - order number and session id
 *
 * @returns a graphql subscription
 */
export const subscribeToOrderNotificationAvailable = (input: OrderNotificationAvailableSubscriptionVariables) => {
  return API.graphql<GraphQLSubscription<OrderNotificationAvailableSubscription>>(
    graphqlOperation(orderNotificationAvailableSubscription, input)
  );
};

/**
 * creates bundle split order
 * @param input - orders, rma orders details
 *
 * @returns response body containing BundleSplitOrder
 */
export const createBundleSplitOrder = async (input: BundleSplitOrderInput) => {
  const response = await Http.handleGraphqlOperation<CreateBundleSplitOrderMutation>(createBundleSplitOrderMutation, {
    input,
  });
  return Http.processGraphqlResponse(response.createBundleSplitOrder, 'BundleSplitOrder');
};

/**
 * removes a bundle tied to a order
 * @param variables - order number, bundle id
 *
 * @returns response message from API
 */
export const removeBundledOrder = async (variables: RemoveBundledOrderMutationVariables) => {
  const response = await Http.handleGraphqlOperation<RemoveBundledOrderMutation>(removeBundleOrderMutation, variables);
  return Http.processGraphqlResponse(response.removeBundledOrder, 'RemoveBundledOrderResponse');
};
