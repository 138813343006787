import CurrencyInput from 'components/common/CurrencyInput/CurrencyInput';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { ToastContext } from 'providers/ToastProvider';
import { useContext, useState } from 'react';
import { ToastNotificationType } from 'shared/enums';

/**
 * Props interface for the AdjustCodAmountModal component.
 */
interface AdjustCodAmountModalI {
  /**
   * The current COD (Cash on Delivery) amount.
   */
  codAmount: number;
  /**
   * Callback function invoked when the modal is closed.
   */
  onClose: () => void;
  /**
   * Callback function invoked when the user submits an adjusted COD amount.
   * @param adjustedCodAmount - The new COD amount to be submitted.
   */
  onSubmit: (adjustedCodAmount: number) => void;
}

/**
 * A modal for adjusting the COD amount, as requested in LMS1-5228.
 * @param codAmount - The existing COD amount.
 * @param onClose - Handles the modal close event.
 * @param onSubmit - Handles the modal submit event.
 * @returns a modal for adjusting the COD amount.
 */
export const AdjustCodAmountModal = ({ codAmount, onClose, onSubmit }: AdjustCodAmountModalI) => {
  const toast = useContext(ToastContext);
  const [returnedInvoicedAmountInput, setReturnedInvoicedAmountInput] = useState('0');
  const [codAmountInput, setCodAmountInput] = useState(String(codAmount));

  /**
   * Handles confirmation of the adjust COD amount modal.
   */
  const handleOnConfirm = () => {
    try {
      const adjustedCodAmount = Number(returnedInvoicedAmountInput) + Number(codAmountInput);
      onSubmit(adjustedCodAmount);
    } catch (e) {
      const submitError = 'Something went wrong while adding the returned invoiced amount to the COD amount.';
      toast.notify(submitError, ToastNotificationType.Error);
      onClose();
    }
  };

  return (
    <DialogBox
      title="Adjust COD Amount"
      onCancel={onClose}
      confirmText="Submit"
      confirmButtonVariant="primary"
      onConfirm={handleOnConfirm}
      confirmButtonDisabled={!returnedInvoicedAmountInput || !codAmountInput}
      width="w-full max-w-xl"
    >
      <div className="text-base text-gray-500">
        Enter the returned case invoice amount and the COD amount to be invoiced. These two fields will be automatically
        added as the COD amount.
      </div>

      <div className="py-3" />

      <CurrencyInput
        id="returnedInvoicedAmount"
        label="Returned Invoiced Amount"
        value={returnedInvoicedAmountInput}
        onChange={setReturnedInvoicedAmountInput}
        isRequired
        infoMessageWhenEmpty="Value is required"
      />

      <div className="py-2" />

      <CurrencyInput
        id="invoiceCodAmount"
        label="COD Amount"
        value={codAmountInput}
        onChange={setCodAmountInput}
        isRequired
        infoMessageWhenEmpty="Value is required"
      />
    </DialogBox>
  );
};
