import { FC } from 'react';
import { LocalCaseHistoryItem, ROUTING_EVENTS } from 'shared/constants/case-detail.constants';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { LabelValue } from './LabelValue';
import { ProcessDisplay } from './ProcessDisplay';

/**
 * Props of TechnicianOrRoutingEvent component.
 */
interface TechnicianOrRoutingEventProps {
  /**
   * The type of case history event.
   */
  eventType: CaseHistoryEventType;
  /**
   * The row containing case history details.
   */
  row: LocalCaseHistoryItem;
}

/**
 * Component for displaying technician or routing events.
 * @param eventType - The type of case history event.
 * @param row - The row containing case history details.
 * @returns A JSX element representing the TechnicianOrRoutingEvent component.
 */
export const TechnicianOrRoutingEvent: FC<TechnicianOrRoutingEventProps> = ({ eventType, row }) => {
  const { isProcessOverride, process, productionFacility } = row;

  const isRoutingEvent = ROUTING_EVENTS.includes(eventType);

  return (
    <div className="flex flex-col gap-1">
      <ProcessDisplay isProcessOverride={isProcessOverride} process={process} />

      {isRoutingEvent && (
        <div className="flex flex-col gap-1">
          <LabelValue name="Production Facility" value={productionFacility || ''} />
        </div>
      )}
    </div>
  );
};
