import { ProductAttributeValueType, ProductFullAttribute, TechnicalPreference } from 'API';
import classNames from 'classnames';
import CurrencyInput from 'components/common/CurrencyInput/CurrencyInput';
import Dropdown from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import NumberInput from 'components/common/NumberInput/NumberInput';
import MultiSelectToothDropdown from 'components/order-entry/Products/Inputs/MultiSelectToothDropdown/MultiSelectToothDropdown';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext } from 'react';
import { AttributeType } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import { LocalOrderProductAttributeInput } from 'shared/models';
import { isPositiveNumber } from 'shared/utils';

interface AttributeValueI {
  addOnInOrder: LocalOrderProductAttributeInput;
  itemTeeth: string[];
  technicalPreferences?: TechnicalPreference[];
  updateAddOnValueHandler: (
    addOn: string,
    index: number,
    addOnType?: ProductAttributeValueType,
    addOnValue?: string
  ) => void;
  index: number;
  targetAddOnAndServiceItem?: ProductFullAttribute;
  isRequired?: boolean;
}

/**
 * Returns an input component for updating the target add-on/service item.
 * @param addOnInOrder - The target order item add-on/service item.
 * @param itemTeeth - The target order item's tooth string.
 * @param productPreference - The product preference for this add-oon/service, if any.
 * @param updateAddOnValueHandler - Handler method for updating the target add-on/service's value.
 * @param index - The target add-on/service's index within the target order item's add-on array.
 * @param targetAddOnAndServiceItem - The target order item add-on/service item's attribute information.
 * @param isRequired - Whether target target field is required.
 * @returns an input component for updating the target add-on/service item.
 */
const AddOnAndServiceItemValue = ({
  addOnInOrder,
  itemTeeth,
  technicalPreferences,
  updateAddOnValueHandler,
  index,
  targetAddOnAndServiceItem,
  isRequired,
}: AttributeValueI) => {
  const { onSubmitAlertCount } = useContext(OrderModuleContext);

  const targetAddOnAttribute = targetAddOnAndServiceItem;
  const targetAddOnAttributeValueType = targetAddOnAttribute?.attributeValueType;

  const addOnName = addOnInOrder?.name;
  // For special order parts amount and notes, uses a custom placeholder, as per UX design.
  let customPlaceholder = '';
  const isSpecialOrderPartsAmount = addOnName === SpecialOrderParts.Amount;
  const isSpecialOrderPartsNotes = addOnName === SpecialOrderParts.Notes;
  if (isSpecialOrderPartsAmount) {
    customPlaceholder = 'Enter total price';
  } else if (isSpecialOrderPartsNotes) {
    customPlaceholder = 'Enter any notes';
  }

  const addOnPreference = technicalPreferences?.find?.(preference => {
    const preferenceName = preference?.attributeName;
    return preferenceName && preferenceName === addOnName;
  });
  const addOnPreferenceValue = addOnPreference?.attributeValue || '';

  // By default, no special styling is applied. If there is a technical preference, special styling is applied in relation to whether the preference is selected or not.
  let inputClassName: string | undefined = undefined;

  const addOnValue = addOnInOrder?.value;

  if (addOnPreferenceValue && addOnValue === addOnPreferenceValue) {
    inputClassName = 'border border-teal-500 bg-teal-100';
  } else if (addOnPreferenceValue && addOnValue !== addOnPreferenceValue) {
    inputClassName = 'border border-orange-500 bg-orange-100';
  }

  /**
   * Returns invalid tooth selection error message.
   * @returns invalid tooth selection error message.
   */
  const getInvalidToothSelection = useCallback(() => {
    if (!addOnInOrder || !addOnInOrder.value) return '';
    if (addOnName === AttributeType.MissingTooth) return '';
    const array = addOnInOrder.value.replace(/#/g, '').split(',');
    const inValidTooth = array.find(value => !itemTeeth.includes(value));
    return inValidTooth ? `Invalid tooth selection. Please remove #${inValidTooth} tooth from the selection.` : '';
  }, [addOnInOrder, addOnName, itemTeeth]);

  // Stores an input component for the target add-on/service item's value type.
  let attributeComponent;
  if (addOnName === SpecialOrderParts.Amount) {
    attributeComponent = (
      <CurrencyInput
        id={`${addOnName}AttributeNumber`}
        value={addOnValue}
        onChange={value => {
          updateAddOnValueHandler(addOnName, index, ProductAttributeValueType.Number, value);
        }}
        onSubmitAlert={!!onSubmitAlertCount && addOnValue.length === 0}
        placeholder={customPlaceholder || 'Enter a price'}
        className={inputClassName}
        isRequired={isRequired}
        infoMessageWhenEmpty="Must be entered before invoicing"
      />
    );
  } else if (targetAddOnAttributeValueType === ProductAttributeValueType.String) {
    attributeComponent = (
      <Input
        id={`productAttributeValueStringInput-${index}`}
        name={`productAttributeValueStringInput-${index}`}
        value={addOnValue}
        placeholder={customPlaceholder || 'Enter a value'}
        isInvalid={(isRequired && !!onSubmitAlertCount && addOnValue.length === 0) || false}
        onChange={e => updateAddOnValueHandler(addOnName, index, ProductAttributeValueType.String, e.target.value)}
        data-testid={`productAttributeValueStringInput-${index}`}
        className={inputClassName}
        isRequired={isRequired}
      />
    );
  } else if (
    targetAddOnAttributeValueType === ProductAttributeValueType.Bool ||
    targetAddOnAttributeValueType === ProductAttributeValueType.Enum
  ) {
    attributeComponent = (
      <Dropdown
        data={(targetAddOnAttributeValueType === ProductAttributeValueType.Bool
          ? ['Yes', 'No']
          : targetAddOnAttribute?.attributeOptions ?? []
        ).map(a => ({ primaryLabel: a, value: a }))}
        selected={{
          primaryLabel: addOnValue || '',
          value: addOnValue || '',
        }}
        isRequired={isRequired}
        setSelected={e => updateAddOnValueHandler(addOnName, index, ProductAttributeValueType.Enum, e.value)}
        onSubmitAlert={!!onSubmitAlertCount && addOnValue.length === 0}
        data-testid="productAttributeValueBooleanOrEnumInput"
        className={inputClassName}
      />
    );
  } else if (targetAddOnAttributeValueType === ProductAttributeValueType.Number) {
    attributeComponent = (
      <NumberInput
        id={`${addOnName}AttributeNumber`}
        value={addOnValue}
        onChange={value => {
          if (value && !isPositiveNumber(value)) return;
          updateAddOnValueHandler(addOnName, index, ProductAttributeValueType.Number, value);
        }}
        onSubmitAlert={!!onSubmitAlertCount && addOnValue.length === 0}
        placeholder={customPlaceholder || 'Enter a quantity'}
        min={1}
        className={inputClassName}
        isRequired={isRequired}
      />
    );
  } else if (targetAddOnAttributeValueType === ProductAttributeValueType.ToothSelection) {
    attributeComponent = (
      <MultiSelectToothDropdown
        itemTeeth={itemTeeth}
        attributeValue={addOnValue || ''}
        onSelectedValueChange={selected => {
          updateAddOnValueHandler(addOnName, index, ProductAttributeValueType.ToothSelection, selected);
        }}
        testId="addOnToothStringInput"
        isRequired={isRequired}
        className={inputClassName}
        requiredMessageTitle="Tooth"
        errorMessage={getInvalidToothSelection()}
      />
    );
  }

  if (!attributeComponent) return null;

  return (
    <div className={classNames({ 'flex-grow': addOnName })}>
      {attributeComponent}
      {/* Display error message when order is submitted without specifying attribute value. No need to display for special order parts amount or notes, which are optional. */}
      {!!onSubmitAlertCount && !isSpecialOrderPartsAmount && !isSpecialOrderPartsNotes && addOnValue === '' ? (
        <InputError message="Please enter a value" />
      ) : null}
    </div>
  );
};

export default AddOnAndServiceItemValue;
