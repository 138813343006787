import Images from 'assets/images';
import classNames from 'classnames';

/**
 * Props for the ResetButton component.
 */
export interface ResetButtonProps {
  /**
   * Handler for resetting the value.
   */
  onValueReset: () => void;
  /**
   * Additional class names for styling.
   */
  className?: string;
}

/**
 * Component for a reset button.
 * @param props - The props for the ResetButton component.
 * @returns JSX.Element representing the ResetButton component.
 */
export const ResetButton = (props: ResetButtonProps) => {
  const { onValueReset, className } = props;
  return (
    <button onClick={onValueReset}>
      <img
        src={Images?.ResetIcon}
        alt="reset"
        className={classNames(className, 'cursor-pointer')}
        width={20}
        height={20}
      />
    </button>
  );
};
