import { ShippingOption } from 'API';
import ShippingDetail from 'components/invoicing/ShippingDetail/ShippingDetail';
import { FetchShippingOptionsInput } from 'types/common';

/**
 * Props for the ShippingSection component.
 */
interface ShippingSectionProps {
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading: boolean;

  /**
   * Function to fetch shipping options.
   * @param data - The input data for fetching shipping options.
   * @returns A promise that resolves to an array of shipping options.
   */
  fetchShippingOptions: (data: FetchShippingOptionsInput) => Promise<ShippingOption[]>;
}

/**
 * Component for the shipping section.
 *
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param fetchShippingOptions - Function to fetch shipping options.
 */
export const ShippingSection: React.FC<ShippingSectionProps> = ({ isLoading, fetchShippingOptions }) => {
  return (
    <div className="flex flex-col">
      <div className="font-medium">2. Select shipping</div>
      <ShippingDetail isLoading={isLoading} fetchShippingOptions={fetchShippingOptions} />
    </div>
  );
};
