export const testSubscription = /* GraphQL */ ``;

export const workOrderSubscription = /* GraphQL */ `
  subscription workOrderAvailable($OrderNumber: String!, $SessionId: String!) {
    workOrderAvailable(OrderNumber: $OrderNumber, SessionId: $SessionId) {
      OrderNumber
      SessionId
    }
  }
`;

export const invoiceSubscription = /* GraphQL */ `
  subscription invoiceAvailable($OrderNumber: String!, $SessionId: String!) {
    invoiceAvailable(OrderNumber: $OrderNumber, SessionId: $SessionId) {
      OrderNumber
      SessionId
    }
  }
`;

export const shippingLabelSubscription = /* GraphQL */ `
  subscription shippingLabelAvailable($OrderNumber: String!, $SessionId: String!) {
    shippingLabelAvailable(OrderNumber: $OrderNumber, SessionId: $SessionId) {
      OrderNumber
      SessionId
    }
  }
`;

export const doctorLabelSubscription = /* GraphQL */ `
  subscription doctorLabelAvailable($OrderNumber: String!, $SessionId: String!) {
    doctorLabelAvailable(OrderNumber: $OrderNumber, SessionId: $SessionId) {
      OrderNumber
      SessionId
      PickupLabelHtml
      CreatedDate
    }
  }
`;

export const orderNotificationAvailableSubscription = /* GraphQL */ `
  subscription orderNotificationAvailable($OrderNumber: String!, $SessionId: String!) {
    orderNotificationAvailable(OrderNumber: $OrderNumber, SessionId: $SessionId) {
      OrderNumber
      SessionId
      NotificationEvent
    }
  }
`;
