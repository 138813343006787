import { ListQuery, ListQueryVariables } from 'API';
import { listCategory } from 'graphql/queries';
import Http from './http';

/**
 * fetches a list of categories using {@link ListCategory}
 *
 * @param input - category
 *
 * @returns a list of categories
 */
export const getCategoryList = async (input: ListQueryVariables) => {
  const response = await Http.handleGraphqlOperation<ListQuery>(listCategory, input);
  const result = Http.processGraphqlResponse(response.list, 'List');
  return result.listItems;
};
