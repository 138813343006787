import { ReturnType } from 'API';
import { FC } from 'react';
import { returnTypeColors } from 'shared/constants/case-detail.constants';
import { getReturnTypeLabel } from 'shared/helpers/return-type.helper';

/**
 * Props for the ReturnTypeBadge component.
 */
interface ReturnTypeBadgeProps {
  /**
   * The type of return.
   */
  type: ReturnType;
}

/**
 * Component for displaying a badge representing the return type.
 * @param type - The type of return.
 * @returns JSX.Element representing the ReturnTypeBadge component.
 */
export const ReturnTypeBadge: FC<ReturnTypeBadgeProps> = ({ type }) => {
  const nameToUse = getReturnTypeLabel(type);
  return (
    <div className="pt-0.5">
      <span
        className={`text-sm font-medium ${`text-${returnTypeColors[type]}-800 bg-${returnTypeColors[type]}-100`} rounded-full px-3 py-1`}
      >
        {nameToUse}
      </span>
    </div>
  );
};
