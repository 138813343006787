import {
  Account,
  AccountQuery,
  CustomerAccountSearchInput,
  CustomerAccountSearchQuery,
  CustomerSearchRecord,
} from 'API';
import { account as getAccountDetailById, customerAccountSearchQuery } from 'graphql/queries';
import Http from './http';

/**
 * Returns an Account based on the accountId
 * @param accountId - represents id of account of interest
 * @returns Account of interest
 */
export const accountLookup = async ({ accountId }: { accountId: string }): Promise<Account> => {
  const response = await Http.handleGraphqlOperation<AccountQuery>(getAccountDetailById, {
    billingAccountId: accountId,
  });
  return Http.processGraphqlResponse(response.account, 'Account');
};

/**
 * Returns an array of customer account search records
 * @param input - Argument containing a search term
 * @returns a list of results for customer accounts search query
 */
export const accountSearch = async (input: CustomerAccountSearchInput): Promise<CustomerSearchRecord[]> => {
  const response = await Http.handleGraphqlOperation<CustomerAccountSearchQuery>(customerAccountSearchQuery, { input });
  const result = Http.processGraphqlResponse(response.customerAccountSearch, 'CustomerAccountSearchResponse');
  return result.accounts;
};
