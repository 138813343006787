import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { DropdownOption } from 'shared/models';
import InputError from '../InputError';
import Label from '../Label';
import { ResetButton } from '../ResetButton';

/**
 * Props for the SelectDropdown component.
 */
interface SelectDropdownProps {
  /** Label for the dropdown. */
  label?: string;
  /** Additional class name for the label. */
  labelClassName?: string;
  /** The current selected value of the dropdown. */
  value: string;
  /** An array of options for the dropdown. */
  options: DropdownOption[];
  /** Callback function to handle value change. */
  onValueChange: (value: string) => void;
  /** Callback function to handle onBlur event. */
  onBlurHandler?: (value: string) => void;
  /** The HTML id attribute for the dropdown. */
  id?: string;
  /** Boolean indicating whether the dropdown has an error. */
  hasError?: boolean;
  /** Placeholder text for the dropdown. */
  placeholder?: string;
  /** Error message to display when there is an error. */
  errorMessage?: string;
  /** Ref object for the HTML select element. */
  inputRef?: React.RefObject<HTMLSelectElement>;
  /** Boolean indicating whether to hide the default option. */
  hideDefaultOption?: boolean;
  /** Boolean indicating whether the dropdown is disabled. */
  disabled?: boolean;
  /** Boolean indicating whether the dropdown is required. */
  isRequired?: boolean;
  /** Additional class name for the dropdown. */
  className?: string;
  /** Boolean indicating whether a reset button is enabled. */
  isAbleToReset?: boolean;
  /** Callback function to handle reset action. */
  onValueReset?: () => void;
  /** Boolean indicating whether to show chevron up and down. */
  showChevronUpDown?: boolean;
}

/**
 * A customizable select dropdown component.
 * @param label - Label for the dropdown.
 * @param labelClassName - Additional class name for the label.
 * @param value - The current selected value of the dropdown.
 * @param placeholder - Placeholder text for the dropdown.
 * @param options - An array of options for the dropdown.
 * @param onValueChange - Callback function to handle value change.
 * @param onBlurHandler - Callback function to handle onBlur event.
 * @param id - The HTML id attribute for the dropdown.
 * @param hasError - Boolean indicating whether the dropdown has an error.
 * @param errorMessage - Error message to display when there is an error.
 * @param inputRef - Ref object for the HTML select element.
 * @param hideDefaultOption - Boolean indicating whether to hide the default option.
 * @param disabled - Boolean indicating whether the dropdown is disabled.
 * @param isRequired - Boolean indicating whether the dropdown is required.
 * @param className - Additional class name for the dropdown.
 * @param isAbleToReset - Boolean indicating whether a reset button is enabled.
 * @param onValueReset - Callback function to handle reset action.
 * @param showChevronUpDown - Boolean indicating whether to show chevron up and down.
 * @returns JSX.Element representing the SelectDropdown component.
 */
const SelectDropdown: React.FC<SelectDropdownProps> = ({
  label,
  labelClassName,
  value,
  placeholder,
  options,
  onValueChange,
  onBlurHandler,
  id,
  hasError,
  errorMessage,
  inputRef,
  hideDefaultOption,
  disabled,
  isRequired,
  className,
  isAbleToReset,
  onValueReset,
  showChevronUpDown,
}) => {
  const [selectedValue, setSelectedValue] = useState<string>();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const valueChangeHandler = (value: string) => {
    setSelectedValue(value);
    onValueChange(value);
  };

  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} className="ml-1" />;

  return (
    <>
      {!!label && (
        <Label required={isRequired} htmlFor={id} labelClassName={classNames(labelClassName, 'flex')}>
          {label}
          {resetButton}
        </Label>
      )}
      {!label && resetButton}
      <select
        className={classNames(
          className,
          {
            'text-gray-600': placeholder,
            'focus:ring-red-500 focus:border-red-500 border-red-500': hasError,
            'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': !hasError,
            'select-bg-arrow-up-and-down': showChevronUpDown,
          },
          'rounded-md block text-sm w-full shadow-sm'
        )}
        value={selectedValue}
        defaultValue={placeholder || selectedValue}
        onChange={e => valueChangeHandler(e.target.value)}
        onBlur={e => onBlurHandler && onBlurHandler(e.target.value)}
        id={id}
        data-testid={id}
        data-qa={id}
        ref={inputRef}
        disabled={disabled}
      >
        {placeholder && (
          <option className="text-red-200" value="">
            {placeholder}
          </option>
        )}
        {!hideDefaultOption &&
          !placeholder &&
          options.length >= 1 &&
          !options.some(attr => attr.value === null || attr.value === '') && (
            <option value="" className="bg-white"></option>
          )}
        {options
          .filter(att => att)
          .map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      {hasError && errorMessage && <InputError message={errorMessage} />}
    </>
  );
};

export default SelectDropdown;
