import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import InputError from '../InputError';
import Label from '../Label';

/**
 * Props for the Radio component.
 */
interface YesNoProps {
  /**
   * The label for the radio buttons.
   */
  label?: string;
  /**
   * The current value of the radio buttons.
   */
  value: boolean | undefined;
  /**
   * Function to handle value changes.
   */
  onValueChange: (value: boolean) => void;
  /**
   * The id of the radio buttons.
   */
  id?: string;
  /**
   * Specifies whether the input is required or not.
   */
  isRequired?: boolean;
  /**
   * The error message to display.
   */
  errorMessage?: string;
  /**
   * Specifies whether to show the error message or not.
   */
  showErrorMessage?: boolean;
  /**
   * Specifies whether the input is invalid or not.
   */
  isInvalid?: boolean;
}

/**
 * Component for displaying radio buttons for Yes/No options.
 * @param label - The label for the radio buttons.
 * @param onValueChange - Function to handle value changes.
 * @param value - The current value of the radio buttons.
 * @param id - The id of the radio buttons.
 * @param isRequired - Specifies whether the input is required or not.
 * @param errorMessage - The error message to display.
 * @param showErrorMessage - Specifies whether to show the error message or not.
 * @param isInvalid - Specifies whether the input is invalid or not.
 * @returns JSX.Element representing the Radio component.
 */
const Radio: React.FC<YesNoProps> = ({
  label,
  onValueChange,
  value,
  id,
  isRequired,
  errorMessage,
  showErrorMessage = true,
  isInvalid = false,
}) => {
  const isError = isInvalid || !!errorMessage;
  return (
    <div>
      {!!label && (
        <Label htmlFor={id} required={isRequired} labelClassName={'block flex mb-2'}>
          {label}
        </Label>
      )}
      <div id={id} className="flex">
        <button
          className={classNames('rounded-md items-center h-8 w-44 mr-4 flex justify-center align-center', {
            'bg-green-500 text-white': value,
            'bg-white text-green-500 border-gray-300 border': !value,
            'bg-white border-red-500': isError,
          })}
          onClick={() => onValueChange(true)}
        >
          <CheckIcon className={classNames('text-sm font-bold h-4 w-4 stroke-2')} />
        </button>

        <button
          className={classNames('rounded-md items-center h-8 w-44 mr-4 flex justify-center align-center', {
            'bg-red-500 text-white': value === false,
            'bg-white text-red-500 border-gray-300 border': value || value === undefined,
            'bg-white border-red-500': isError,
          })}
          onClick={() => onValueChange(false)}
        >
          <XMarkIcon className={classNames('text-sm font-bold h-4 w-4 stroke-2')} />
        </button>
      </div>
      {showErrorMessage && <InputError message={errorMessage} />}
    </div>
  );
};

export default Radio;
