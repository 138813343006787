import { ProductAttributeValueType, ProductFullAttribute, TechnicalPreference } from 'API';
import classNames from 'classnames';
import AddButton from 'components/common/AddButton';
import { cloneDeep, find } from 'lodash';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { AttributeType } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import { LocalOrderItemInput, LocalOrderProductAttributeInput } from 'shared/models';
import { isRestorationTypeSingle } from 'shared/utils';
import { v4 as uuidv4 } from 'uuid';
import { AddOnAndServiceItem } from './AddOnAndServiceItem/AddOnAndServiceItem';

/**
 * Props for the AddOn component.
 */
interface AddOnProps {
  /**
   * The ID of the order item.
   */
  orderItemId: string;
  /**
   * List of available addons and services.
   */
  addonsAndServices: ProductFullAttribute[];
  /**
   * List of technical preferences.
   */
  technicalPreferences?: TechnicalPreference[];
}

/**
 * The AddOn component manages the display and interaction of add-ons and services for an order item.
 * Users can add, update, or remove add-ons and services.
 * @param orderItemId - The ID of the order item.
 * @param addonsAndServices - List of available addons and services.
 * @param technicalPreferences - List of technical preferences.
 * @returns JSX element representing the AddOn component.
 */
const AddOn: React.FC<AddOnProps> = ({ orderItemId, addonsAndServices, technicalPreferences }) => {
  const { order } = useContext(OrderModuleContext);
  const { patchOrder, getOrderItemSelectedTeeth } = useContext(OrderModuleActionsContext);
  const newOrderItems: LocalOrderItemInput[] = useMemo(() => [...order.orderItems], [order]);
  const foundOrderItemIndex = newOrderItems.findIndex(item => item.id === orderItemId);
  const orderItemExistsOnOrder = foundOrderItemIndex > -1;
  const addOnsAndServicesInOrder = newOrderItems?.[foundOrderItemIndex]?.addOns;
  const addOnElementRef = useRef<HTMLSelectElement>(null);
  const itemTeeth = getOrderItemSelectedTeeth(orderItemId);

  // focus the new addOn field, when user clicks add
  useEffect(() => {
    if (addonsAndServices.length && !addonsAndServices[addonsAndServices.length - 1]?.name.length) {
      addOnElementRef.current?.focus();
    }
  }, [addonsAndServices]);

  // Will add addon
  const addAddOnHandler = () => {
    if (orderItemExistsOnOrder) {
      newOrderItems[foundOrderItemIndex].addOns.push({
        name: '',
        type: '',
        value: '',
        quantity: 1,
        id: uuidv4(),
      });

      patchOrder({
        orderItems: newOrderItems,
      });
    }
  };

  // Will update name of addon in order and also value if it's provided
  const updateAddOnValueHandler = (
    addOn: string,
    index: number,
    addOnType?: ProductAttributeValueType,
    addOnValue?: string
  ) => {
    if (!addOn && !addOnValue) {
      return;
    }

    if (orderItemExistsOnOrder) {
      const foundAddons = newOrderItems[foundOrderItemIndex].addOns;
      const foundAddonItem = foundAddons[index];
      // Clones the add-on item to retain knowledge of the original value.
      const foundAddonItemClone = cloneDeep(foundAddonItem);
      const targetProductAddOn = addonsAndServices.find(addon => addon.name === addOn);
      const targetProductAddOnType = targetProductAddOn?.type;
      let quantity = 1;
      const attributeValueType = targetProductAddOn?.attributeValueType;
      const orderItem = newOrderItems[foundOrderItemIndex];

      // Business Logic: For Number types, set the quantity equal to the value EXCEPT if the add on is of a currency type (currently not supported by backend).
      if (addOnType === ProductAttributeValueType.Number && addOnValue && addOn !== SpecialOrderParts.Amount) {
        quantity = +addOnValue;
      }

      // Business Logic: For Tooth Selection types, set quantity to the amount of teeth.
      if (addOnType === ProductAttributeValueType.ToothSelection && addOnValue) {
        quantity = addOnValue.split(',').length;
      }

      // Business logic is to set quanity to value if type is number
      if (foundAddonItemClone && targetProductAddOnType) {
        foundAddonItemClone.name = addOn;
        foundAddonItemClone.type = targetProductAddOnType;
        foundAddonItemClone.value = addOnValue || '';
        foundAddonItemClone.quantity = quantity;

        // Cycles through the target add-on's attributeRules to ensure that any children are added to the order.
        targetProductAddOn.attributeRules?.forEach(rule => {
          rule.displayAttributes.forEach(displayAttribute => {
            const childName = displayAttribute.name;
            const isChildInOrder = foundAddons.find(addon => addon.name === childName);
            if (!isChildInOrder) {
              foundAddons.push({
                name: childName,
                value: '',
                type: AttributeType.AddOn,
                quantity: 1,
              });
            }
          });
        });
        if (addOn === AttributeType.MissingTooth) {
          const missingToothArray = foundAddonItemClone.value.split(',');
          const filteredAddonsExpectMissingTooth = foundAddons.filter(
            addon => addon.name !== AttributeType.MissingTooth
          );
          filteredAddonsExpectMissingTooth.forEach(addon => {
            const addonValue = addon.value.split(',');
            const filteredMissingTooth = addonValue.filter(tooth => !missingToothArray.includes(tooth));
            addon.value = filteredMissingTooth.join(',');
            // Finds the product attribute for the target addon in order to check its attribute value type.
            const mappedProductAddon = addonsAndServices.find(productAttribute => productAttribute.name === addon.name);
            // If the addon being edited is of type tooth selection and the current target addon is also of type tooth selection, resets the target addon's quantity to reflect the change.
            if (
              attributeValueType === ProductAttributeValueType.ToothSelection &&
              mappedProductAddon?.attributeValueType === ProductAttributeValueType.ToothSelection
            ) {
              addon.quantity = filteredMissingTooth.length; // set quantity to the amount of teeth only if it's tooth selection addon attributes
            }
          });
        }

        // Sets the order item's value to that of its clone, which has been altered in this function.
        newOrderItems[foundOrderItemIndex].addOns[index] = foundAddonItemClone;

        // If the target add-on doesn't exist, it means it's being added.
        // Defaults to its current value. If no value is provided, defaults to its default value (which is its preference).
        if (!foundAddonItem.name) {
          //set default value for single type initially if itemTeeth length ===1
          if (
            attributeValueType === ProductAttributeValueType.ToothSelection &&
            isRestorationTypeSingle(orderItem.restorationType) &&
            itemTeeth.length === 1 &&
            !addOnValue
          ) {
            foundAddonItemClone.value = `#${itemTeeth[0]}`;
          } else {
            foundAddonItemClone.value = foundAddonItemClone.value || targetProductAddOn.defaultValue || '';
          }
        }
        // Sets the order item's value to that of its clone, which has been altered in this function.
        newOrderItems[foundOrderItemIndex].addOns[index] = foundAddonItemClone;

        patchOrder({
          orderItems: newOrderItems,
        });
      }
    }
  };

  // This will remove an addon by id that was generated for it
  const removeAddOnHandler = (index: number, _addOnName: string, clearField: boolean, itemChildNames: string[]) => {
    if (clearField) {
      newOrderItems[foundOrderItemIndex].addOns.splice(index, 1);
    } else {
      const addOnValue = newOrderItems[foundOrderItemIndex].addOns[index];
      newOrderItems[foundOrderItemIndex].addOns[index] = {
        ...addOnValue,
        name: '',
        value: '',
      };
    }
    // When removing an add-on, also removes its children (other add-ons in the same row, which are determined using attributeRules).
    newOrderItems[foundOrderItemIndex].addOns = newOrderItems[foundOrderItemIndex].addOns.filter(
      addOn => !itemChildNames.includes(addOn.name)
    );
    patchOrder({
      orderItems: newOrderItems,
    });
  };

  const getSelectedToothRange = useCallback(
    (toothArray: string[], addonInOrder: LocalOrderProductAttributeInput) => {
      if (addonInOrder.name === AttributeType.MissingTooth) return toothArray;
      const missingTooth = find(addOnsAndServicesInOrder, { name: AttributeType.MissingTooth });
      if (!missingTooth || !missingTooth.value) return toothArray;
      const missingToothArray = missingTooth.value.replace(/#/g, '').split(',');
      return toothArray.filter(tooth => !missingToothArray.includes(tooth));
    },
    [addOnsAndServicesInOrder]
  );

  return (
    <>
      <label
        className={classNames('block text-gray-700 text-sm font-medium', {
          'mb-3': addonsAndServices && !!addonsAndServices.length,
        })}
      >
        Add-ons &amp; Services
      </label>
      {addOnsAndServicesInOrder && !!addOnsAndServicesInOrder.length && (
        <div className="flex-col">
          {addOnsAndServicesInOrder.map((addOnInOrder, index) => (
            <AddOnAndServiceItem
              key={'AddOnAndServiceItem' + (addOnInOrder?.id || index)}
              addOnInOrder={addOnInOrder}
              addonsAndServices={addonsAndServices}
              addOnsAndServicesInOrder={addOnsAndServicesInOrder}
              index={index}
              technicalPreferences={technicalPreferences}
              removeAddOnHandler={removeAddOnHandler}
              updateAddOnValueHandler={updateAddOnValueHandler}
              itemTeeth={getSelectedToothRange(itemTeeth, addOnInOrder)}
            />
          ))}
        </div>
      )}
      {/* Hide add button when all attribute options have been chosen */}
      {addonsAndServices.length !== addOnsAndServicesInOrder?.length && (
        <AddButton id="addAddOnButton" text="Add New" onClick={addAddOnHandler} data-testid="addAddOnButton" />
      )}
    </>
  );
};

export default AddOn;
