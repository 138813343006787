import { FC } from 'react';
import { CaseHistoryAdditionalDetailsObject } from 'shared/constants/case-detail.constants';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { ChangesForTargetField } from './ChangesForTargetField';

/**
 * Props of SplitBundleOrderEvent component.
 */
export interface SplitBundleOrderEventProps {
  /**
   * The type of case history event.
   */
  eventType: CaseHistoryEventType;
  /**
   * The old data object containing additional details of the case history.
   */
  oldData?: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The new data object containing additional details of the case history.
   */
  newData?: CaseHistoryAdditionalDetailsObject | null;
}

/**
 * Component for displaying split bundle order events.
 * @param eventType - The type of case history event.
 * @param oldData - The old data object containing additional details of the case history.
 * @param newData - The new data object containing additional details of the case history.
 * @returns A JSX element representing the SplitBundleOrderEvent component.
 */
export const SplitBundleOrderEvent: FC<SplitBundleOrderEventProps> = ({ oldData, newData }) => {
  const items = [
    {
      name: 'Bundle Id',
      oldValue: oldData?.BundleId,
      newValue: newData?.BundleId,
    },
    {
      name: 'Bundle Order Numbers',
      oldValue: oldData?.BundleOrderNumbers,
      newValue: newData?.BundleOrderNumbers,
    },
    {
      name: 'Detached Order Numbers',
      oldValue: oldData?.DetachedOrderNumbers,
      newValue: newData?.DetachedOrderNumbers,
    },
  ];
  return (
    <div className="mt-1">
      {items.map(item => (
        <ChangesForTargetField
          key={item.name}
          oldFieldValue={item.oldValue}
          newFieldValue={item.newValue}
          fieldName={item.name}
        />
      ))}
    </div>
  );
};
