import { EnclosedItemCategory, OrderEnclosedItem, OrderEnclosedItemInput, OrderStatus } from 'API';
import EnclosedItems from 'components/common/EnclosedItems/EnclosedItems';
import InputError from 'components/common/InputError';
import { SkeletonBorder, SkeletonBox } from 'components/common/Skeleton';
import moment from 'moment';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { ReactNode, useMemo } from 'react';
import { getDefaultOrderItem, someEnclosedItemsHaveMetalArticulator } from 'shared/helpers/invoice/invoice.helper';
import { getProviderNameById } from 'shared/helpers/provider.helper';
import { CreatedOrder, CreatedOrderItem } from 'shared/models';
import { getPatientFullName } from 'shared/utils';
import { InvoiceErrorMessage } from '../../../shared/enums/invoice-error-message';
import ProductDetail from '../ProductDetail/ProductDetail';
import ProductDetailHeader from '../ProductDetail/ProductDetailHeader/ProductDetailHeader';
import { EditAndRemoveButtonContainer } from './EditAndRemoveButtonContainer/EditAndRemoveButtonContainer';
import { InvoicedEnclosedItems } from './InvoicedEnclosedItems';

/**
 * Props interface for the CaseInfoItem component.
 */
interface CaseInfoItemProps {
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
  /**
   * Label for the information.
   */
  label: string;
  /**
   * Value to be displayed.
   */
  value: ReactNode;
  /**
   * Test ID for testing purposes.
   */
  testId: string;
}

/**
 * Component for displaying individual case information.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param label - Label to display.
 * @param value - Value to be displayed.
 * @param testId - Test ID for testing purposes.
 * @returns JSX element representing the component.
 */
const CaseInfoItem: React.FC<CaseInfoItemProps> = ({ isLoading, testId, label, value }) => {
  return (
    <div className="flex">
      <label className="w-32 mr-2 leading-6 text-sm font-medium text-gray-500">{label}</label>
      <label className="text-gray-900" data-qa={testId} data-testid={testId}>
        {isLoading ? <SkeletonBorder className="h-5 w-40" /> : value}
      </label>
    </div>
  );
};

/**
 * Props interface for the OrderDetail component.
 */
interface OrderDetailProps {
  /**
   * The invoicing case data.
   */
  invoicingCase: CreatedOrder;
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
  /**
   * The number of cases.
   */
  caseCount?: number;
  /**
   * Flag indicating whether the case is already invoiced.
   */
  isInvoicedCase?: boolean;
}

/**
 * Component for rendering order details.
 * @param invoicingCase - The invoicing case data.
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param caseCount - The number of cases.
 * @param isInvoicedCase - Flag indicating whether the case is already invoiced.
 * @returns JSX element representing the component.
 */
const OrderDetail: React.FC<OrderDetailProps> = ({
  caseCount = 0,
  isLoading: invoicingLoading,
  invoicingCase,
  isInvoicedCase,
}) => {
  const {
    orderNumber,
    patientFirstName,
    patientLastName,
    patientId,
    providerName,
    providerId,
    customerDueDate,
    orderItems,
    enclosedItems,
  } = invoicingCase;

  const fullPatientName = getPatientFullName(patientFirstName, patientLastName);
  const { updateCase, account, invoiceCases } = useInvoicingDetail();

  const onChangeEnclosedItem = (items: OrderEnclosedItem[] | OrderEnclosedItemInput[]) => {
    updateCase({ orderNumber: invoicingCase.orderNumber, enclosedItems: items as OrderEnclosedItem[] });
  };

  /**
   * Handles rendering product details depending on whether the order is canceled.
   * @returns product details with a special, simplified version if the order is canceled.
   */
  const ProductDetailsWrapper = () => {
    const isOrderCanceled = invoicingCase.status === OrderStatus.Cancelled;
    if (isOrderCanceled) {
      return (
        <div className="flex-col p-4 border-b last:border-b-0">
          <ProductDetailHeader description="Canceled Case - Returned Items" quantity={1} unitPrice={0} />
          <div className="uppercase text-sm text-gray-500 sm:flex sm:flex-row-reverse mt-4">
            <div className="ml-12 text-gray-900">$0.00</div> Total:
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {(orderItems as CreatedOrderItem[]).map((product: CreatedOrderItem, index) => (
            <ProductDetail key={`${product.productName}-${index}`} product={product} count={orderItems.length} />
          ))}
        </div>
      );
    }
  };

  const blockEnclosedItems = useMemo(() => {
    return invoiceCases.length > 1 && someEnclosedItemsHaveMetalArticulator(enclosedItems);
  }, [enclosedItems, invoiceCases]);

  return (
    <>
      <div className="bg-white border px-6 py-4 sm:rounded-lg shadow-sm">
        <div className="flex">
          <div className="flex-grow flex">
            <div className="flex-col gap-1">
              <CaseInfoItem isLoading={invoicingLoading} testId="orderIdLabel" label="Case #" value={orderNumber} />
              <CaseInfoItem
                isLoading={invoicingLoading}
                testId="patientNameLabel"
                label={fullPatientName ? 'Patient Name' : 'Patient Id'}
                value={fullPatientName || patientId || 'Not Provided'}
              />
              <CaseInfoItem
                isLoading={invoicingLoading}
                testId="providerNameLabel"
                label="Provider Name"
                value={getProviderNameById(account?.providers, providerId) || providerName || 'Not Provided'}
              />
              <CaseInfoItem
                isLoading={invoicingLoading}
                testId="dueDateLabel"
                label="Due Date"
                value={customerDueDate ? moment(customerDueDate).format('MM/DD/YYYY') : 'Not Provided'}
              />
              {invoicingCase.externalOrderNumber && invoicingCase.orderSource && (
                <CaseInfoItem
                  isLoading={invoicingLoading}
                  testId="externalCaselabel"
                  label="External Case #"
                  value={`${invoicingCase.externalOrderNumber} (${invoicingCase.orderSource})`}
                />
              )}
            </div>
          </div>

          {!invoicingLoading && !isInvoicedCase && (
            <EditAndRemoveButtonContainer caseCount={caseCount} caseNumber={orderNumber} />
          )}
        </div>
        <div className="mt-4 sm:rounded-lg border w-full col-span-4">
          <div className="flex border-b px-4 py-2 font-medium text-sm text-gray-500 bg-gray-50 sm:rounded-t-lg">
            <div className="flex-grow py-0.5">Product</div>
            <div className="flex-none w-12 py-0.5 text-center">Qty</div>
            <div className="flex-none w-40 text-right py-0.5">Unit Price</div>
          </div>

          {invoicingLoading ? (
            <ProductDetail isLoading={invoicingLoading} product={getDefaultOrderItem()} count={0} />
          ) : (
            <ProductDetailsWrapper />
          )}
        </div>

        {invoicingCase.notes && (
          <div className="mt-4 text-sm">
            <div className="font-medium text-gray-700 mb-2">Notes</div>
            {invoicingLoading ? (
              <SkeletonBox className="h-5" />
            ) : (
              <p className="mt-2 sm:rounded-lg border w-full text-gray-900 p-4 whitespace-pre-line">
                {invoicingCase.notes}
              </p>
            )}
          </div>
        )}

        {isInvoicedCase ? (
          <InvoicedEnclosedItems enclosedItems={enclosedItems} />
        ) : (
          <div className="mt-4 w-full">
            <EnclosedItems
              category={EnclosedItemCategory.Outbound}
              isDataLoading={invoicingLoading}
              enclosedItems={enclosedItems}
              onChangeItem={onChangeEnclosedItem}
              onRemoveItem={onChangeEnclosedItem}
              blockEnclosedItems={blockEnclosedItems}
              invalidCodeText={blockEnclosedItems ? 'articulator' : undefined} // Error message should only show if articulator is part of bundled case as per LMS1-3670/Josh
            />
            {blockEnclosedItems && <InputError message={InvoiceErrorMessage.ARTICULATOR_BUNDLE} />}
          </div>
        )}
      </div>
    </>
  );
};

export default OrderDetail;
