import LMSLogo from './LMS.svg';
import AllLabsLogo from './all-labs.svg';
import Base64EncodedImages from './base64-encoded-images.json';
import CrownWorldLogo from './crown-world.svg';
import EdgelineLogo from './edgeline.svg';
import FedexLogo from './fedex.svg';
import GlidewellLogo from './glidewell.svg';
import GLSLogo from './gls.svg';
import GulfStarLogo from './gulf-star.svg';
import HubRxLogo from './hub-rx.svg';
import Loader from './loader.svg';
import OnTracLogo from './on-trac.svg';
import PacificEdgeLogo from './pacific-edge.svg';
import ResetIcon from './reset.svg';
import RiversideLogo from './riverside-icon.png';
import Shape from './shape.svg';
import LMSSignInLogo from './sign-in-logo.svg';
import Spinner from './spinner.svg';
import UpsLogo from './ups.svg';
import USPSLogo from './usps.svg';

const Images = {
  CrownWorldLogo,
  AllLabsLogo,
  GlidewellLogo,
  EdgelineLogo,
  HubRxLogo,
  GulfStarLogo,
  PacificEdgeLogo,
  LMSLogo,
  Spinner,
  Shape,
  Loader,
  FedexLogo,
  UpsLogo,
  RiversideLogo,
  ResetIcon,
  OnTracLogo,
  USPSLogo,
  GLSLogo,
  LMSSignInLogo,
  Base64EncodedImages,
};

export default Images;
