import {
  Attribute,
  Brand,
  Classifications,
  ClassificationsQuery,
  Product,
  ProductAttributeQuery,
  ProductBrandQuery,
  ProductFullQuery,
  ProductQuery,
  ProductReturnRequirement,
  ProductReturnRequirementQuery,
} from 'API';
import axios from 'axios';
import {
  classificationsQuery,
  getAttribute,
  getProductBrandQuery,
  productFullQuery,
  productQuery,
  productReturnRequirementQuery,
} from 'graphql/queries';
import Http from './http';

/**
 * fetches a list of available product classifications
 *
 * @returns a list of classifications
 */
export const getProductClassifications = async (): Promise<Classifications[]> => {
  const response = await Http.handleGraphqlOperation<ClassificationsQuery>(classificationsQuery);
  const result = Http.processGraphqlResponse(response.classifications, 'ClassificationsWrapper');
  return result.classifications;
};

/**
 * fetches list of product details using product codes
 * @param productCodes - list of product codes whose product details are required
 * @returns a list of products related to given product codes
 */
export const getProducts = (productCodes: string[]) => {
  const requests: Promise<Product>[] = [];
  productCodes.forEach(productCode => {
    requests.push(getProduct(productCode));
  });

  return axios.all(requests);
};

/**
 * Get product data by product code.
 * @param productCode - Product query input
 * @returns Product object if found
 */
export const getProduct = async (productCode: string): Promise<Product> => {
  const response = await Http.handleGraphqlOperation<ProductQuery>(productQuery, { productCode });
  return Http.processGraphqlResponse(response.product, 'Product');
};

/**
 * Retrieves the full details of a product.
 * @param productCode - The code of the product to retrieve.
 * @returns The full details of the product.
 */
export const getProductFull = async (productCode: string) => {
  const response = await Http.handleGraphqlOperation<ProductFullQuery>(productFullQuery, { productCode });
  return Http.processGraphqlResponse(response.productFull, 'ProductFull');
};

/**
 * fetches product attribute details using attribute name
 * @param attribute - attribute name
 * @returns attribute data related to attributeName given
 */
export const getProductAttribute = async (attribute: string): Promise<Attribute> => {
  const response = await Http.handleGraphqlOperation<ProductAttributeQuery>(getAttribute, { attributeName: attribute });
  return Http.processGraphqlResponse(response.productAttribute, 'Attribute');
};

/**
 * Get product brand data by product code.
 * @param productCode - Product brand input
 * @returns Product brand object if found
 */
export const getProductBrand = async (productCode: string): Promise<Brand[]> => {
  const response = await Http.handleGraphqlOperation<ProductBrandQuery>(getProductBrandQuery, { productCode });
  const result = Http.processGraphqlResponse(response.productBrand, 'BrandWrapper');
  return result.brands;
};

/**
 * fetches product return requirement using product code
 * @param productCode - product code of item whose return requirements are required
 * @returns whether isReturnRequired for a given product code
 */
export const productReturnRequirement = async (productCode: string): Promise<ProductReturnRequirement> => {
  const response = await Http.handleGraphqlOperation<ProductReturnRequirementQuery>(productReturnRequirementQuery, {
    productCode,
  });
  return Http.processGraphqlResponse(response.productReturnRequirement, 'ProductReturnRequirement');
};
