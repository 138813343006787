import { useEffect, useRef } from 'react';

/**
 * Custom hook that returns a mutable ref object that holds the previous value of the provided value.
 * @param value - The value to track and store the previous value of.
 * @returns A mutable ref object that holds the previous value of the provided value.
 */
export const usePreviousRef = <T,>(value: T) => {
  const ref = useRef<T>(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref;
};
