import { isEqual } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This is a TypeScript function that sets up a fetcher to fetch data and provides a refetch function,
 * data, loading, error, and initialized states.
 *
 * @param fetcher - `fetcher` is a function that takes in a parameter of type `TParam` and
 * returns a Promise that resolves to some data. The type of the data returned by the Promise is
 * inferred from the return type of the `fetcher` function.
 *
 * @param parameter - `parameter` is an optional object parameter that can be passed to the
 * `useFetcher` hook. It should be an object that matches the type of the argument expected by the
 * `fetcher` function. It can also have an additional `skip` property which, if set to `true`, will
 *
 * @returns The `useFetcher` hook returns an object with the following properties:
 * - `fetcher`: A function that can be used to fetch the data
 * - `refetch`: A function that can be used to refetch the data
 * - `data`: The data that was fetched
 * - `loading`: A boolean that indicates whether the data is currently being fetched
 * - `error`: An error object that contains information about any errors that occurred while fetching the data
 * - `initialized`: A boolean that indicates whether the hook has been initialized
 */
export const useFetcher = <
  TFetcher extends (firstArg: Parameters<TFetcher>[0]) => Promise<Awaited<ReturnType<TFetcher>>>,
  TParam
>(
  fetcher: TFetcher,
  ...[parameter]: TParam extends undefined ? [] : [Parameters<TFetcher>[0] & { skip?: boolean }]
) => {
  const lazyFetcherRef = useRef(fetcher);
  // Determine if the fetcher should be skipped.
  // The fetcher should be skipped if the fetcher is not fully initialized by the parameter.
  const internalSkip = parameter?.skip ?? false;

  // Set up states for data, loading, error, and initialized.
  const [data, setData] = useState<Awaited<ReturnType<TFetcher>> | null>(null);
  const [loading, setLoading] = useState<boolean>(!internalSkip);
  const [error, setError] = useState<Error | null>(null);
  const parameterRef = useRef<Parameters<TFetcher>[0]>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const mountedRef = useRef(false);

  /**
   * Set up a ref to the fetcher for future use.
   */
  useEffect(() => {
    lazyFetcherRef.current = fetcher;
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, [fetcher]);

  /**
   * Set up a fetcher function that will be used to fetch data.
   */
  const fetchData = useCallback(
    async <TParamFetch>(...[param]: TParamFetch extends undefined ? [] : [Parameters<TFetcher>[0]]) => {
      setLoading(true);
      setError(null);
      try {
        const newData = await lazyFetcherRef.current(Object.keys(param || {}).length ? param : undefined);
        mountedRef.current && setData(newData);
        return newData;
      } catch (err) {
        mountedRef.current && setError(err as Error);
        throw err;
      } finally {
        if (mountedRef.current) {
          setLoading(false);
          setInitialized(true);
          parameterRef.current = param;
        }
      }
    },
    []
  );

  /**
   * Fetch data immediately when the hook is first mounted.
   */
  useEffect(() => {
    const { skip, ...restParam } = parameter || {};

    const paramEquality = isEqual(restParam, parameterRef.current);
    const skipValue = skip ?? internalSkip ?? false;
    if (skipValue || paramEquality) return;

    // NOTE: Removed console.error on catch to avoid flooding the console (lots of different 404s).
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    fetchData(restParam).catch(() => {});
    parameterRef.current = restParam;
  }, [fetchData, parameter, internalSkip]);

  /**
   * sets up a re-fetcher function
   */
  const refetch = useCallback(
    async <TParamFetch extends Parameters<TFetcher>[0]>(
      ...[param]: TParamFetch extends undefined ? [] : [Parameters<TFetcher>[0]]
    ) => {
      fetchData(param);
    },
    [fetchData]
  );

  return {
    fetcher: fetchData,
    refetch: refetch,
    data,
    loading,
    error,
    initialized,
  };
};
