import { ReactNode } from 'react';
import { cn } from 'shared/utils';
import { THeadCell } from './THeadCell';
import { TableColumn, TableRow } from './types';

/**
 * Props for the THead component.
 */
interface THeadProps<TColumn extends TableColumn<TRow>, TRow extends TableRow> {
  /**
   * Array of column configurations.
   */
  columns: TColumn[];
  /**
   * Array of row data.
   */
  rows?: TRow[];
  /**
   * Render prop for customizing header cells.
   */
  children?: ({ column }: { column: TColumn }) => ReactNode;
  /**
   * The current order by which the table is sorted.
   */
  orderBy?: string;
  /**
   * Callback function invoked when the order by value changes.
   */
  onChangeOrderBy?: (orderBy: string) => void;
  /**
   * Custom header content.
   */
  header?: ReactNode;
  /**
   * Indicates whether the table header border is rounded (default: false).
   */
  borderRounded?: boolean;
  /**
   * Additional classes for styling.
   */
  className?: string;
}

/**
 * Component representing the table head.
 * @param columns - Array of column configurations.
 * @param rows - Array of row data.
 * @param children - Render prop for customizing header cells.
 * @param orderBy - The current order by which the table is sorted.
 * @param onChangeOrderBy - Callback function invoked when the order by value changes.
 * @param header - Custom header content.
 * @param borderRounded - Indicates whether the table header border is rounded (default: false).
 * @param className - Additional classes for styling.
 * @returns JSX.Element representing the THead component.
 */
export const THead = <TColumn extends TableColumn<TRow>, TRow extends TableRow>({
  columns,
  children,
  orderBy,
  onChangeOrderBy,
  header,
  borderRounded,
  className,
}: THeadProps<TColumn, TRow>) => {
  return (
    <thead
      className={cn('bg-gray-50 sticky top-0', {
        'rounded-t-xl': borderRounded,
      })}
    >
      {header && (
        <tr className="border-t-1 border-gray-300">
          <td colSpan={12}>{header}</td>
        </tr>
      )}
      <tr>
        {columns.map(column => (
          <THeadCell
            key={column.id}
            id={column.id}
            sortable={column.sortable}
            orderBy={orderBy}
            onChangeOrderBy={onChangeOrderBy}
            className={cn(className, column.className)}
          >
            {typeof children === 'function' ? children({ column }) : column.name}
          </THeadCell>
        ))}
      </tr>
    </thead>
  );
};
