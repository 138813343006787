import React, { createContext, useCallback, useContext } from 'react';

import { ManufacturingLocation } from 'API';
import { InitialLoader } from 'components/common/InitialLoader/InitialLoader';
import { getManufacturingLocations } from 'shared/api/manufacturing-location.api';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { QueryFetchTemplate } from 'templates/QueryFetchTemplate/QueryFetchTemplate';

/**
 * Props of the manufacturing location context.
 */
export interface IManufacturingLocationContext {
  /**
   * Array of manufacturing locations.
   */
  manufacturingLocations: ManufacturingLocation[];
  /**
   * Function to get a manufacturing location by its ID.
   * @param locationId - The ID of the manufacturing location to retrieve.
   * @returns The manufacturing location with the specified ID, if found; otherwise, undefined.
   */
  getManufacturingLocation: (locationId: number) => ManufacturingLocation | undefined;
  /**
   * Function to get a manufacturing location by its name.
   * @param locationName - The name of the manufacturing location to retrieve.
   * @returns The manufacturing location with the specified name, if found; otherwise, undefined.
   */
  getManufacturingLocationByName: (locationName: string) => ManufacturingLocation | undefined;
}

/**
 * Context for managing manufacturing locations.
 */
const ManufacturingLocationContext = createContext<IManufacturingLocationContext | undefined>(undefined);

/**
 * Provider component for managing manufacturing locations.
 */
export const ManufacturingLocationProvider: React.FC = ({ children }) => {
  const { data, loading, error } = useQueryFetcher(getManufacturingLocations);

  const getManufacturingLocation = useCallback(
    (locationId: number) => {
      if (!data) return undefined;
      return data.find(location => location.locationId === locationId);
    },
    [data]
  );

  const getManufacturingLocationByName = useCallback(
    (locationName: string) => {
      if (!data) return undefined;
      return data.find(location => location.locationName.toLowerCase() === locationName.toLowerCase());
    },
    [data]
  );

  return (
    <QueryFetchTemplate
      loading={loading}
      error={error}
      data={data}
      loadingContent={<InitialLoader />}
      noDataContent={<div className="text-xl text-red-500 mt-6 mx-4">No data found at manufacturing locations.</div>}
    >
      {({ data: manufacturingLocations }) => (
        <ManufacturingLocationContext.Provider
          value={{ getManufacturingLocation, manufacturingLocations, getManufacturingLocationByName }}
        >
          {children}
        </ManufacturingLocationContext.Provider>
      )}
    </QueryFetchTemplate>
  );
};

/**
 * Custom hook for accessing the manufacturing location context.
 */
export const useManufacturingLocation = () => {
  const value = useContext(ManufacturingLocationContext);
  if (!value) throw new Error('useManufacturingLocation must be used within ManufacturingLocationProvider');
  return value;
};
