import { TabConfig } from 'shared/models/tab-config';

/**
 * Props of the TabStrip component.
 */
interface TabStripProps {
  /**
   * An array of tab configurations.
   */
  tabs: TabConfig[];
  /**
   * A function to handle tab selection.
   * @param tab - The selected tab.
   */
  onTabSelect: (tab: TabConfig) => void;
}

/**
 * Component for rendering a tab strip.
 * @param tabs - An array of tab configurations.
 * @param onTabSelect - A function to handle tab selection.
 * @returns A JSX element representing the TabStrip component.
 */
const TabStrip: React.FC<TabStripProps> = ({ tabs, onTabSelect }) => {
  return (
    <>
      <div className="flex gap-10">
        {tabs.map(tab => (
          <div
            key={tab.title}
            className={`${
              tab.current ? 'text-indigo-600 border-b-2 border-indigo-500' : 'text-gray-500'
            } pb-4 text-sm font-medium cursor-pointer`}
            onClick={() => onTabSelect(tab)}
            data-qa={tab.title.replace(/\s/g, '') + 'Link'}
          >
            {tab.title}
            {!!tab.badgeCount && (
              <span
                className={`${
                  tab.current ? 'text-indigo-800 bg-indigo-100' : 'text-gray-500 bg-gray-100'
                } ml-2 px-2 py-0.5 rounded-xl text-xs`}
              >
                {tab.badgeCount}
              </span>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default TabStrip;
