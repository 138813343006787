import { CaseHistoryItem } from 'API';
import { Table } from 'components/common/Table/Table';
import { cloneDeep } from 'lodash';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { useMemo, useState } from 'react';
import { LocalCaseHistoryItem, caseHistoryColumns } from 'shared/constants/case-detail.constants';
import { cn } from 'shared/utils';
import { CaseHistoryFilterPanel } from './FilterPanel';

/**
 * Enum representing filter categories.
 */
export enum FilterCategory {
  Products = 'product',
  ActionTypes = 'actionTypes',
  Departments = 'department',
}

/**
 * Type representing checked filters.
 */
export type CheckedFilters = Record<FilterCategory, string[] | undefined>;

/**
 * Interface representing the properties of the CaseHistory component.
 */
interface CaseHistoryI {
  caseHistoryItems: CaseHistoryItem[];
}

/**
 * Component for displaying case history.
 *
 * @param caseHistoryItems- case history items array.
 * @returns A JSX element representing the CaseHistory component.
 */
const CaseHistory: React.FC<CaseHistoryI> = ({ caseHistoryItems }) => {
  const [checkedFilters, setCheckedFilters] = useState<CheckedFilters>({
    [FilterCategory.Products]: [],
    [FilterCategory.ActionTypes]: [],
    [FilterCategory.Departments]: [],
  });
  const { getManufacturingLocation } = useManufacturingLocation();

  /**
   * Returns case history that matches the checked filter checkboxes and is sorted according to the most recent process step first.
   */
  const filteredCaseHistory = useMemo(() => {
    const newFilteredCaseHistory = cloneDeep(caseHistoryItems as LocalCaseHistoryItem[]);
    return newFilteredCaseHistory.filter(caseHistoryItem => {
      const { actionType = '', products } = caseHistoryItem;
      const productFilters = checkedFilters.product;
      // Evaluates to true if there are no product filters or if the product filters contain any of the products for this case history item.
      const hasProduct = productFilters?.length
        ? !!products?.find(product => productFilters?.includes(product.productName + ' ' + product.toothString))
        : true;
      // Evaluates to true if there are no process filters or if the process filters contain the process step for this case history item.
      const actionTypeFilters = checkedFilters.actionTypes;
      const hasActionType = actionTypeFilters?.length ? !!actionType && actionTypeFilters?.includes(actionType) : true;
      // Evaluates to true if there are no department filters or if the department filters contain the department for this case history item.
      const departmentFilters = checkedFilters.department;
      const hasDepartment = departmentFilters?.length
        ? products?.some(product => product.department && departmentFilters.includes(product.department))
        : true;
      // Shows the case history item if it meets all of the filter criteria.
      return hasProduct && hasActionType && hasDepartment;
    });
  }, [caseHistoryItems, checkedFilters.actionTypes, checkedFilters.department, checkedFilters.product]);

  /**
   * Returns production facility rows to be used as labels above rows where the production facility has changed in the case history table.
   */
  // TODO: Uncomment later if necessary for Glidewell.
  // const getProductionFacilityRows = useCallback((filteredCaseHistory: CaseHistoryItem[]) => {
  //   const newProductionFacilityRows: LabelRow[] = [];
  //   filteredCaseHistory.forEach((caseHistoryItem, index) => {
  //     const lastProductionFacility = newProductionFacilityRows[newProductionFacilityRows.length - 1]?.label;
  //     const currentProductionFacility = caseHistoryItem.productionFacility;
  //     if (lastProductionFacility !== currentProductionFacility) {
  //       newProductionFacilityRows.push({
  //         label: currentProductionFacility,
  //         index,
  //       });
  //     }
  //   });
  //   return newProductionFacilityRows;
  // }, []);

  const caseHistoryColumnsParsed = useMemo(() => {
    return caseHistoryColumns(getManufacturingLocation);
  }, [getManufacturingLocation]);

  return (
    <div className="flex p-6 gap-4">
      <div
        className={cn('flex flex-col gap-2', {
          'w-1/4': !!caseHistoryItems.length,
        })}
      >
        {caseHistoryItems.length > 0 && (
          <CaseHistoryFilterPanel
            caseHistoryItems={caseHistoryItems}
            checkedFilters={checkedFilters}
            setCheckedFilters={setCheckedFilters}
          />
        )}
      </div>
      <div className="flex-grow overflow-x-auto">
        <Table
          rows={filteredCaseHistory}
          columns={caseHistoryColumnsParsed}
          maxHeight="full"
          showPagination={filteredCaseHistory.length > 10}
        />
      </div>
    </div>
  );
};

export default CaseHistory;
