import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';

/**
 * Props of the ChangesForTargetField component.
 */
export interface ChangesForTargetFieldProps {
  /**
   * The old value of the target field.
   */
  oldFieldValue?: string;
  /**
   * The new value of the target field.
   */
  newFieldValue?: string;
  /**
   * The name of the target field.
   */
  fieldName: string;
}

/**
 * Returns a component for visualizing the changes for the target field.
 * @param oldFieldValue - The old value of the target field.
 * @param newFieldValue - The new value of the target field.
 * @param fieldName - The name of the target field.
 * @returns a component for visualizing the changes for the target field.
 */
export const ChangesForTargetField: FC<ChangesForTargetFieldProps> = ({ oldFieldValue, newFieldValue, fieldName }) => {
  const oldDataLength = !!oldFieldValue?.length;
  const newDataLength = !!newFieldValue?.length;
  const hasChanges = oldDataLength || newDataLength;
  if (!hasChanges) return null;
  return (
    <div className="mb-2">
      <div className="font-semibold">{fieldName}:</div>
      <div className="flex flex-1 justify-between">
        <div className="block">{oldFieldValue || 'N/A'}</div>

        <div className="mx-6">
          <ArrowRightIcon className="w-5 h-5" />
        </div>

        <div className="block">{newFieldValue || 'N/A'}</div>
      </div>
    </div>
  );
};
