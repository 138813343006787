import { QueryFetchErrorContent } from 'components/common/QueryFetchErrorContent/QueryFetchErrorContent';
import { useToast } from 'providers/ToastProvider';
import { useEffect } from 'react';
import { ToastNotificationType } from 'shared/enums';

type DataLoadingContent<TLoadingContent, TData> = TLoadingContent extends undefined ? TData : NonNullable<TData>;

export interface QueryFetchTemplateProps<TData, TLoadingContent> {
  loading: boolean;
  data: TData;
  children: (props: { isLoading: boolean; data: DataLoadingContent<TLoadingContent, TData> }) => React.ReactNode;
  error?: Error | null;
  loadingContent?: TLoadingContent;
  noDataContent?: React.ReactNode;
  showErrorToast?: boolean;
  renderErrorContent?: (error: Error) => React.ReactNode;
}

/**
 * This component is used to handle render the loading, error and no data states for a query fetcher.
 *
 * @param loading - The loading state of the query fetcher.
 * @param error - The error state of the query fetcher.
 * @param data - The data state of the query fetcher.
 * @param children - The render prop to render the data state.
 *
 * @param loadingContent - The loading content to render when the query fetcher is loading.
 * @defaultValue loadingContent - undefined
 *
 * @param noDataContent - The no data content to render when the query fetcher has no data.
 * @defaultValue - noDataContent undefined
 *
 * @param showErrorToast - Whether to show the error toast when the query fetcher has an error.
 * @defaultValue showErrorToast true
 *
 * @param renderErrorContent - The render prop to render the error state.
 * @defaultValue renderErrorContent undefined
 *
 * @returns Returns the JSX.Element of the component.
 *
 * @example
 *
 * Example 1: With global loading content
 *
 * ```ts
 * <QueryFetchTemplate
 *   loading={loading}
 *   error={error}
 *   data={data}
 *   loadingContent={<div>Loading...</div>}
 *   noDataContent={<div>No data</div>}
 * >
 *   {({ data }) => {
 *     return <div>{data}</div>;
 *   }}
 * </QueryFetchTemplate>
 *```
 *
 * Example 2: With custom error content
 * ```ts
 * <QueryFetchTemplate
 *   loading={loading}
 *   error={error}
 *   data={data}
 *   loadingContent={<div>Loading...</div>}
 *   noDataContent={<div>No data</div>}
 *   showErrorToast={false}
 *   renderErrorContent={(error) => <div>{error.message}</div>}
 * >
 *   {({ data }) => {
 *     return <div>{data}</div>;
 *   }}
 * </QueryFetchTemplate>
 *```
 *
 * Example 3: With custom loading content for a individual component
 * ```ts
 * <QueryFetchTemplate
 *   loading={loading}
 *   error={error}
 *   data={data}
 *   noDataContent={<div>No data</div>}
 * >
 *   {({ isLoading, data }) => {
 *     return isLoading ? <div>Loading...</div> : <div>{data}</div>;
 *   }}
 *```
 */
export const QueryFetchTemplate = <TData, TLoadingContent extends React.ReactNode>({
  loading,
  error,
  data,
  children,
  showErrorToast = true,
  renderErrorContent,
  loadingContent,
  noDataContent,
}: QueryFetchTemplateProps<TData, TLoadingContent>): JSX.Element => {
  const toast = useToast();

  useEffect(() => {
    if (showErrorToast && error) {
      toast.notify(error.message, ToastNotificationType.Error);
    }
  }, [error, toast, showErrorToast]);

  if (loading) {
    return (
      <>
        {loadingContent ||
          children({ isLoading: true, data } as {
            isLoading: boolean;
            data: DataLoadingContent<TLoadingContent, TData>;
          })}
      </>
    );
  }
  if (error && !showErrorToast) return <QueryFetchErrorContent error={error} render={renderErrorContent} />;
  if (!data) return <>{noDataContent}</>;
  return <>{children({ isLoading: false, data })}</>;
};
