import { useFetcher } from './useFetcher';

/**
 * This TypeScript function returns a modified version of the useFetcher hook that skips the initial
 * fetch and omits the re-fetch function.
 *
 * @param fetcher - The `fetcher` parameter is a function that takes an argument of a
 * specific type (defined by `Parameters<TFetcher>[0]`) and returns a Promise that resolves to an
 * unknown value. The type of the argument and the return value are determined by the type of the
 * `fetcher` function
 *
 * @returns The `useLazyQueryFetcher` function returns an object that is the same as the object
 * returned by the `useFetcher` hook, except that the `re-fetch` function is omitted. This means that
 * the returned object can be used to execute a query lazily (i.e. only when needed), but it cannot be
 * used to manually trigger a re-fetch of the query.
 */
export const useLazyQueryFetcher = <
  TFetcher extends (arg: Parameters<TFetcher>[0]) => Promise<Awaited<ReturnType<TFetcher>>>
>(
  fetcher: TFetcher
) => {
  const result = useFetcher(fetcher, { skip: true });
  return result as Omit<typeof result, 'refetch'>;
};
