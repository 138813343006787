import { ROUTES } from 'components/navigation/Constants';
import { OrderEntryContainer } from 'components/order-entry/OrderEntryContainer/OrderEntryContainer';
import { useInvoiceLocationState } from 'hooks/useInvoiceLocationState';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BlockerFunction, useBlocker, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { NEW_CASE } from 'shared/constants/constants';
import { INITIAL_NEW_ORDER_INPUT_DATA } from 'shared/constants/order.constants';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { checkOrderValueChanged } from 'shared/helpers/order-detail/order-detail.helper';
import { AnalyticsService } from 'shared/services/analytics.service';
import { generateBundleOrderRecord, useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { shallow } from 'zustand/shallow';
import { ConfirmNavigationModal } from './ConfirmNavigationModal/ConfirmNavigationModal';
import { TabContent } from './TabContent/TabContent';
import { TabHeader } from './TabHeader/TabHeader';

const OrderEntryBundleSplitPage: FC = () => {
  const [searchParams] = useSearchParams();
  const orderNumber = searchParams.get('orderNumber');
  const ref = useRef<React.ComponentRef<typeof OrderEntryContainer>>(null);
  const { state, pathname } = useLocation();
  const redirectPath = state?.redirectPath;
  const [isOpenNavigationModal, setIsOpenNavigationModal] = useState(false);
  const navigate = useNavigate();
  const invoiceLocationState = useInvoiceLocationState();
  const isNewCasePage = pathname.includes(ROUTES.NEW_ORDER);
  const [isOrderSubmitted, setIsOrderSubmitted] = useState(false);

  const {
    selectedTab,
    setOrders,
    orders,
    setBundledSplitCases,
    resetSubmitAlertCount,
    resetOrderBundleUpdateCount,
    initialOrderData,
  } = useBundleSplitCaseStore(
    state => ({
      selectedTab: state.selectedTab,
      setOrders: state.setOrders,
      orders: state.orders,
      setBundledSplitCases: state.setBundledSplitCases,
      resetSubmitAlertCount: state.resetSubmitAlertCount,
      orderBundleUpdateCount: state.orderBundleUpdateCount,
      resetOrderBundleUpdateCount: state.resetOrderBundleUpdateCount,
      initialOrderData: state.initialOrderData,
    }),
    shallow
  );

  /**
   * Checks if the order has been updated.
   */
  const isOrderValueChanged = useMemo(() => {
    // If there are no orders, we will consider it as not updated.
    if (orders.length === 0) return false;

    // If the user is on the new case page and there are more than one order, we will consider it as updated.
    if (isNewCasePage) {
      if (orders.length > 1) return true;
      return checkOrderValueChanged(INITIAL_NEW_ORDER_INPUT_DATA, orders[0]);
    }

    return orders.some(order => {
      const orderDataInput = initialOrderData[order.id];
      if (!orderDataInput) return false;
      return checkOrderValueChanged(orderDataInput, order);
    });
  }, [orders, isNewCasePage, initialOrderData]);

  /**
   * this function used to help to block the user to redirect to different page when they made some changes in the order form
   */
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      // Once the order is submitted, then we shouldn't block the router navigation to redirect to next page
      if (isOrderSubmitted) return false;
      // If the order is updated and the user attempts to go to a different page, we will launch a confirmation modal.
      return isOrderValueChanged && currentLocation.pathname !== nextLocation.pathname;
    },
    [isOrderValueChanged, isOrderSubmitted]
  );

  /**
   * Prevents navigation if the order has been updated.
   * The order update count is tracked via patchOrder.
   */
  const navigationBlocker = useBlocker(shouldBlock);

  /**
   * Reset the blocker if the order is not submitted and isOrderValueChanged is not changed.
   */
  useEffect(() => {
    if (navigationBlocker.state === 'blocked' && !isOrderSubmitted && !isOrderValueChanged) {
      navigationBlocker.reset();
    }
  }, [navigationBlocker, isOrderValueChanged, isOrderSubmitted]);

  /**
   * Resets order information and navigates to the previous page.
   */
  const onNavigationConfirm = () => {
    setOrders([]);
    resetSubmitAlertCount();
    resetOrderBundleUpdateCount();
    AnalyticsService.track(AnalyticsEventName.CaseEntryCanceled);
  };

  /**
   * Upon selecting the cancel button, a confirmation modal will be shown if any changes were made to the order.
   */
  const cancelHandler = () => {
    if (isNewCasePage) {
      navigate(ROUTES.ORDER_ENTRY);
    } else {
      navigate(redirectPath || -1, { state: invoiceLocationState }); // if we are in case edit, we need to pass the state to the next page, The reason is, Currently Invoicing Bundle split page expects this state to work properly.
    }

    if (!isOrderValueChanged) {
      onNavigationConfirm();
    }
  };

  /**
   * Initialize tabs, if orderNumber is provided in the url, then we will have one tab.
   * Clear orders on unmount.
   */
  useEffect(() => {
    setOrders([generateBundleOrderRecord(orderNumber ? orderNumber : NEW_CASE)]);
    setBundledSplitCases([]);

    return () => {
      setOrders([]);
    };
  }, [orderNumber, setBundledSplitCases, setOrders]);

  /**
   * This will launch the browser's default confirmation message before reloading the page.
   */
  useEffect(() => {
    const beforeUnload = (event: BeforeUnloadEvent) => {
      return isOrderValueChanged && (event.returnValue = 'Are you sure? If you reload your changes will be lost.');
    };
    window.addEventListener('beforeunload', beforeUnload);
    return () => {
      window.removeEventListener('beforeunload', beforeUnload);
    };
  }, [isOrderValueChanged]);

  /**
   * Whenever order value changes, we need set order submitted is false.
   * By doing this, we can make sure that navigation blocker validation will work properly
   */
  useEffect(() => {
    if (!orders.length) return;
    // Whenever order value changes, we need set order submitted is false. So that navigation blocker will work properly
    setIsOrderSubmitted(false);
  }, [orders]);

  /**
   * handles submission of order entered,
   * in background, if any validations fails, automatically communicated to UI
   */
  const onSubmit = async () => {
    setIsOrderSubmitted(true); // It's meant for navigation blocker use case
    await ref.current?.submitHandler();
  };

  return (
    <div className="flex flex-col overflow-hidden h-full flex-1">
      <TabHeader
        orders={orders}
        onSubmit={onSubmit}
        onCancel={cancelHandler}
        setIsOrderSubmitted={setIsOrderSubmitted}
      />
      <div className="overflow-y-auto flex-grow">
        {orders.map(order => {
          return (
            <TabContent
              key={order.id}
              ref={ref}
              orderNumber={order.orderNumber || ''}
              isActive={selectedTab === order.id}
              orderId={order.id}
            />
          );
        })}
      </div>

      {navigationBlocker && (
        <ConfirmNavigationModal
          isOpenModal={isOpenNavigationModal}
          onConfirm={onNavigationConfirm}
          onCloseModal={() => {
            setIsOpenNavigationModal(false);
          }}
          blocker={navigationBlocker}
        />
      )}
    </div>
  );
};

export default OrderEntryBundleSplitPage;
