import { useEffect, useState } from 'react';
import CheckboxInput from '../CheckboxInput';
import { FacetOption } from './types';

/**
 * Props for the FacetItemOption component.
 */
interface FacetItemOptionProps<TOptionValue> {
  /** The facet option object. */
  option: FacetOption<TOptionValue>;
  /** Function to handle value changes. */
  onValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /** Indicates if the checkbox is initially checked. */
  defaultChecked?: boolean;
}

/**
 * Component for rendering a facet item option.
 *
 * @param option - The facet option object.
 * @param onValueChange - Function to handle value changes.
 * @param defaultChecked - Indicates if the checkbox is initially checked.
 * @returns JSX.Element - The rendered component.
 */
export const FacetItemOption = <TOptionValue extends string>({
  defaultChecked = false,
  option,
  onValueChange,
}: FacetItemOptionProps<TOptionValue>) => {
  const [checked, setChecked] = useState(defaultChecked);
  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(e.target.checked);
    onValueChange(e);
  };
  return (
    <div className="flex gap-2 items-center">
      <CheckboxInput
        id={option.id}
        checked={checked}
        label={option.name}
        onChange={onChange}
        inLine={true}
        checkBoxAtStart={true}
        value={Array.isArray(option.value) ? option.value.join() : option.value}
      />
    </div>
  );
};
