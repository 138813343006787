import { SkeletonBox } from 'components/common/Skeleton';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { FC, useMemo } from 'react';
import AddBundleCase from './AddBundleCase/AddBundleCase';

/**
 * Props interface for the AddBundleCaseContainer component.
 */
interface AddBundleCaseContainerProps {
  /**
   * Boolean indicating whether data is loading.
   */
  isLoading: boolean;
  /**
   * The billing account ID.
   */
  billingAccountId: string;
}

/**
 * Container component for adding a bundle case.
 * @param isLoading - Boolean indicating whether data is loading.
 * @param billingAccountId - The billing account ID.
 */
export const AddBundleCaseContainer: FC<AddBundleCaseContainerProps> = ({ isLoading, billingAccountId }) => {
  const { invoiceCases } = useInvoicingDetail();
  const existingCaseIds = useMemo(() => invoiceCases.map(c => c.orderNumber), [invoiceCases]);

  if (isLoading) return <SkeletonBox className="w-1/2 h-8" />;
  return <AddBundleCase accountId={billingAccountId} existingCaseIds={existingCaseIds} />;
};
