import { AttributeType, CountryCode } from 'shared/enums';

export const CarrierRegexListConfiguration: { carrier: string; regex: string }[] = [
  {
    carrier: 'UPS',
    regex:
      '\\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|T\\d{3} ?\\d{4} ?\\d{3})\\b',
  },
  {
    carrier: 'USPS',
    regex:
      '\\b((420 ?\\d{5} ?)?(91|92|93|94|95|01|03|04|70|23|13)\\d{2} ?\\d{4} ?\\d{4} ?\\d{4} ?\\d{4}( ?\\d{2})?)\\b',
  },
  {
    carrier: 'USPS',
    regex: '\\b((M|P[A-Z]?|D[C-Z]|LK|E[A-C]|V[A-Z]|R[A-Z]|CP|CJ|LC|LJ) ?\\d{3} ?\\d{3} ?\\d{3} ?[A-Z]?[A-Z]?)\\b',
  },
  { carrier: 'USPS', regex: '\\b(82 ?\\d{3} ?\\d{3} ?\\d{2})\\b' },
  { carrier: 'FedEx', regex: '\\b(((96\\d\\d|6\\d)\\d{3} ?\\d{4}|96\\d{2}|\\d{4}) ?\\d{4} ?\\d{4}( ?\\d{3})?)\\b' },
  { carrier: 'OnTrac', regex: '\\b([CD]\\d{14})\\b' },
  { carrier: 'DHL', regex: '\\b(\\d{4}[- ]?\\d{4}[- ]?\\d{2}|\\d{3}[- ]?\\d{8}|[A-Z]{3}\\d{7})\\b' },
  { carrier: 'GLS', regex: '^EZ\\d{2}[A-Z](\\d[A-Z]){2}\\d{11}$' },
  { carrier: 'GLS', regex: '^EZ\\d{2}[A-Z]{3}\\d{13}$' },
];

export const Carriers: { id: string; name: string; url: string }[] = [
  { id: 'dhl', name: 'DHL', url: 'https://www.dhl.com/us-en/home/tracking.html?tracking-id=' },
  { id: 'fedex', name: 'FedEx', url: 'https://www.fedex.com/fedextrack/?trknbr=' },
  { id: 'ups', name: 'UPS', url: 'https://www.ups.com/track?loc=en_US&tracknum=' },
  {
    id: 'usps',
    name: 'USPS',
    url: 'https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=',
  },
  { id: 'ontrac', name: 'OnTrac', url: 'https://www.ontrac.com/tracking/?number=' },
  { id: 'gls', name: 'GLS', url: 'https://www.aftership.com/track/' },
  { id: 'dropoff', name: 'Dropoff', url: '' },
  { id: 'fta', name: 'FTA', url: '' },
  { id: 'no tracking', name: 'No Tracking', url: '' },
  { id: 'business reply', name: 'Business Reply', url: '' },
];

export const DigitalFileTypes: string[] = ['.dicom', '.stl', '.dcm'];

export const CarrierExceptions: { trackingNumber: string; carrier: string }[] = [
  { trackingNumber: 'Dropoff', carrier: 'Dropoff' },
  { trackingNumber: 'Lab Driver Pickup', carrier: 'FTA' },
  { trackingNumber: 'No Tracking', carrier: 'No Tracking' },
  { trackingNumber: 'Business Reply', carrier: 'Business Reply' },
  { trackingNumber: 'DoctorPickup', carrier: 'Doctor Pickup' },
];

export const DocumentFileTypes: string[] = ['doc', 'docx', 'txt', 'pdf'];
export const ImageFileTypes: string[] = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
export const DesignFileTypes: string[] = ['stl'];

export const ShippingCountries: { id: string; name: string }[] = [
  { id: CountryCode.UnitedStates, name: 'United States' },
  { id: CountryCode.PuertoRico, name: 'Puerto Rico' },
];

export const States: { id: number; abbreviation: string; name: string }[] = [
  { id: 1, name: 'Alabama', abbreviation: 'AL' },
  { id: 2, name: 'Alaska', abbreviation: 'AK' },
  { id: 3, name: 'Arizona', abbreviation: 'AZ' },
  { id: 4, name: 'Arkansas', abbreviation: 'AR' },
  { id: 5, name: 'California', abbreviation: 'CA' },
  { id: 6, name: 'Colorado', abbreviation: 'CO' },
  { id: 7, name: 'Connecticut', abbreviation: 'CT' },
  { id: 8, name: 'Delaware', abbreviation: 'DE' },
  { id: 9, name: 'Florida', abbreviation: 'FL' },
  { id: 10, name: 'Georgia', abbreviation: 'GA' },
  { id: 11, name: 'Hawaii', abbreviation: 'HI' },
  { id: 12, name: 'Idaho', abbreviation: 'ID' },
  { id: 13, name: 'Illinois', abbreviation: 'IL' },
  { id: 14, name: 'Indiana', abbreviation: 'IN' },
  { id: 15, name: 'Iowa', abbreviation: 'IA' },
  { id: 16, name: 'Kansas', abbreviation: 'KS' },
  { id: 17, name: 'Kentucky', abbreviation: 'KY' },
  { id: 18, name: 'Louisiana', abbreviation: 'LA' },
  { id: 19, name: 'Maine', abbreviation: 'ME' },
  { id: 20, name: 'Maryland', abbreviation: 'MD' },
  { id: 21, name: 'Massachusetts', abbreviation: 'MA' },
  { id: 22, name: 'Michigan', abbreviation: 'MI' },
  { id: 23, name: 'Minnesota', abbreviation: 'MN' },
  { id: 24, name: 'Mississippi', abbreviation: 'MS' },
  { id: 25, name: 'Missouri', abbreviation: 'MO' },
  { id: 26, name: 'Montana', abbreviation: 'MT' },
  { id: 27, name: 'Nebraska', abbreviation: 'NE' },
  { id: 28, name: 'Nevada', abbreviation: 'NV' },
  { id: 29, name: 'New Hampshire', abbreviation: 'NH' },
  { id: 30, name: 'New Jersey', abbreviation: 'NJ' },
  { id: 31, name: 'New Mexico', abbreviation: 'NM' },
  { id: 32, name: 'New York', abbreviation: 'NY' },
  { id: 33, name: 'North Carolina', abbreviation: 'NC' },
  { id: 34, name: 'North Dakota', abbreviation: 'ND' },
  { id: 35, name: 'Ohio', abbreviation: 'OH' },
  { id: 36, name: 'Oklahoma', abbreviation: 'OK' },
  { id: 37, name: 'Oregon', abbreviation: 'OR' },
  { id: 38, name: 'Pennsylvania', abbreviation: 'PA' },
  { id: 39, name: 'Rhode Island', abbreviation: 'RI' },
  { id: 40, name: 'South Carolina', abbreviation: 'SC' },
  { id: 41, name: 'South Dakota', abbreviation: 'SD' },
  { id: 42, name: 'Tennessee', abbreviation: 'TN' },
  { id: 43, name: 'Texas', abbreviation: 'TX' },
  { id: 44, name: 'Utah', abbreviation: 'UT' },
  { id: 45, name: 'Vermont', abbreviation: 'VT' },
  { id: 46, name: 'Virginia', abbreviation: 'VA' },
  { id: 47, name: 'Washington', abbreviation: 'WA' },
  { id: 48, name: 'Washington, D.C.', abbreviation: 'DC' },
  { id: 49, name: 'West Virginia', abbreviation: 'WV' },
  { id: 50, name: 'Wisconsin', abbreviation: 'WI' },
  { id: 51, name: 'Wyoming', abbreviation: 'WY' },
];

export const DefaultPonticDesign = 'Modify Lap';

const {
  ColorAttribute,
  Facility,
  ImplantAttribute,
  PonticDesign,
  ProcessAttribute,
  Quantity,
  ReportGrouping,
  Service,
  Shade,
  Thickness,
  TierPricing,
  ToothString,
  ToothStringMissingTooth,
  AddOn,
  Clearance,
  Occlusion,
} = AttributeType;

export const OrderEntryAttributesConfigs = {
  ColorAttribute,
  Facility,
  ImplantAttribute,
  PonticDesign,
  ProcessAttribute,
  Quantity,
  ReportGrouping,
  Service,
  Shade,
  Thickness,
  TierPricing,
  ToothString,
  ToothStringMissingTooth,
  AddOn,
};

export const WordOrderAttributesConfig: string[] = [
  Clearance,
  ColorAttribute,
  Facility,
  ImplantAttribute,
  Occlusion,
  PonticDesign,
  ProcessAttribute,
  Quantity,
  Service,
  Shade,
  Thickness,
  TierPricing,
  ToothString,
  AddOn,
];
