/**
 * various states in upload process
 */
export enum FileUploadState {
  Waiting,
  Uploading,
  Uploaded,
  Error,
  Cancelled,
}
