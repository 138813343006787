import { Address, AddressType } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import Modal from 'components/common/Modal';
import { unionBy } from 'lodash';
import { OrderEntryAccountContext } from 'providers/OrderEntryAccountProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext, useMemo, useState } from 'react';
import { getFormattedAddress, getFormattedOrderAddress, getShippingAddressOption } from 'shared/helpers/util.helper';
import { DropdownModel } from 'shared/models';
import AlternateAddress from '../AlternateAddress/AlternateAddress';

/**
 * Props for the ShippingAddressSelector component.
 */
interface ShippingAddressProps {
  /**
   * Indicates whether to display the skeleton loader.
   */
  skeletonLoader?: boolean;
}

/**
 * Component responsible for managing the selection of shipping addresses.
 *
 * @param skeletonLoader - Indicates whether to display the skeleton loader.
 * @returns a shipping address selector component.
 */
const ShippingAddressSelector: React.FC<ShippingAddressProps> = ({ skeletonLoader }) => {
  const alternateAddressOption = 'alternateAddress';
  const [showAlternateAddressModal, setShowAlternateAddressModal] = useState<boolean>(false);
  const [alternateAddress, setAlternateAddress] = useState<Address>();
  const { account } = useContext(OrderEntryAccountContext);
  const { order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const { patchOrder, isOrderItemExchangeReturnType } = useContext(OrderModuleActionsContext);

  const shippingAddressChangeHandler = (selected: DropdownModel) => {
    if (selected.value === alternateAddressOption) {
      setShowAlternateAddressModal(true);
      return;
    }
    let newSelectedAddress;

    if (alternateAddress && selected.value === getFormattedAddress(alternateAddress)) {
      newSelectedAddress = alternateAddress;
    }

    account?.addresses.forEach(address => {
      if (getFormattedAddress(address) === selected.value) {
        newSelectedAddress = address;
      }
    });

    if (newSelectedAddress) {
      patchOrder({
        shippingAddress: {
          street1: newSelectedAddress.street1,
          street2: newSelectedAddress.street2,
          city: newSelectedAddress.city,
          state: newSelectedAddress.state,
          zipcode: newSelectedAddress.zipcode,
          country: newSelectedAddress.country,
          type: newSelectedAddress.type,
        },
      });
    }
  };

  const alternateAddressSaveHandler = (address: Address) => {
    if (account) {
      setAlternateAddress({ ...address });

      patchOrder({
        shippingAddress: {
          street1: address.street1,
          street2: address.street2,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode,
          country: address.country,
          type: address.type,
        },
      });
      setShowAlternateAddressModal(false);
    }
  };

  const alternateAddressCloseHandler = () => {
    if (order.shippingAddress) return setShowAlternateAddressModal(false);
    if (account?.addresses[0]) {
      const address = account?.addresses[0];
      patchOrder({
        shippingAddress: {
          street1: address.street1,
          street2: address.street2,
          city: address.city,
          state: address.state,
          zipcode: address.zipcode,
          country: address.country,
          type: address.type,
        },
      });
      setShowAlternateAddressModal(false);
    }
  };

  const accountAddresses = useMemo(() => {
    if (!account) return [];
    const addresses = account.addresses.filter(
      address => address.type.toLowerCase() === AddressType.Shipping.toLowerCase()
    );
    return addresses.map(address => getShippingAddressOption({ address, secondaryLabel: '(SHIPPING)' }));
  }, [account]);

  const orderShippingAddresses = useMemo(() => {
    if (!order) return [];
    const isAlternateAddress = order.shippingAddress.type.toLowerCase() === AddressType.Alternate.toLowerCase();
    const shippingAddress: Address = {
      __typename: 'Address',
      ...order.shippingAddress,
    };

    // Used to control whether secondary label shows.
    const formattedAddressExists = !!getFormattedAddress(shippingAddress);
    return [
      getShippingAddressOption({
        address: shippingAddress,
        secondaryLabel: formattedAddressExists ? (isAlternateAddress ? '(ALTERNATE)' : '(SHIPPING)') : '',
        secondaryLabelClassColor: isAlternateAddress ? 'text-orange-500' : '',
      }),
    ];
  }, [order]);

  const dropdownOptions = useMemo(() => {
    const options = [...accountAddresses, ...orderShippingAddresses];
    const removedDuplicateOptions = unionBy(options, 'value');
    removedDuplicateOptions.push({
      primaryLabel: '+ Add an alternate address',
      value: alternateAddressOption,
      secondaryLabel: '',
      defaultColorClass: 'text-indigo-600',
    });
    return removedDuplicateOptions;
  }, [accountAddresses, orderShippingAddresses]);

  const selectedShippingAddress = useMemo(() => {
    const selectedOption = dropdownOptions.find(option =>
      option.value.includes(getFormattedOrderAddress(order?.shippingAddress))
    );
    const defaultOption = {
      primaryLabel: '',
      secondaryLabel: '',
      value: '',
    };
    return selectedOption || defaultOption;
  }, [order, dropdownOptions]);

  const hasExchangeItem = useMemo(() => isOrderItemExchangeReturnType(), [isOrderItemExchangeReturnType]);

  return (
    <>
      <div className="col-span-2">
        <Dropdown
          label="Shipping Address"
          disabled={hasExchangeItem || !account || !dropdownOptions.length}
          data={dropdownOptions}
          selected={selectedShippingAddress}
          setSelected={address => shippingAddressChangeHandler(address)}
          isRequired={true}
          onSubmitAlert={!!onSubmitAlertCount}
          skeletonLoader={skeletonLoader}
          isShowLabelWhenDisabled={hasExchangeItem}
        />
      </div>
      {showAlternateAddressModal && (
        <Modal onClose={() => alternateAddressCloseHandler()}>
          <AlternateAddress
            onSave={address => alternateAddressSaveHandler(address)}
            onClose={() => alternateAddressCloseHandler()}
          />
        </Modal>
      )}
    </>
  );
};

export default ShippingAddressSelector;
