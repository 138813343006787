import { ROUTES, routeMetadata } from 'components/navigation/Constants';
import { Location, Params } from 'react-router-dom';
import { ValueOf } from 'types/generic';

/**
 * Get the route config if it is defined by reverse engineering the pathname from location with the route path params.
 * @param pathname - raw pathname from Location.
 * @param params - the route params.
 * @returns the found route config or undefined.
 */
export const findRouteConfig = (pathname: Location['pathname'], params: Params<string>) => {
  let parsedRoute = pathname;

  for (const key in params) {
    const paramAtKey = params[key] || '';
    if (paramAtKey) {
      parsedRoute = parsedRoute.replace(paramAtKey, `:${key}`);
    }
  }

  return parsedRoute in routeMetadata ? routeMetadata[parsedRoute as ValueOf<typeof ROUTES>] : undefined;
};

/**
 * constructs a navigation route for showing order details using `orderNumber`
 *
 * @param orderNumber - order number of concerned order
 */
export const getOrderDetailPath = (orderNumber: string) => {
  return ROUTES.ORDER_DETAIL.replace(':id', orderNumber);
};

/**
 * constructs a navigation route for creating a new invoice using `orderNumber`
 *
 * @param orderNumber - order number of concerned order
 */
export const getInvoiceCreatePath = (orderNumber: string) => {
  return ROUTES.INVOICE_CREATE.replace(':id', orderNumber);
};

/**
 * constructs a navigation route for updating an existing order using `orderNumber`
 *
 * @param orderNumber - order number of concerned order
 */
export const getUpdateCasePath = (orderNumber: string) => {
  return ROUTES.UPDATE_ORDER.replace(':id', orderNumber);
};
