import { ExclamationTriangleIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { ROUTES } from 'components/navigation/Constants';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NEW_CASE } from 'shared/constants/constants';
import { AttributeType } from 'shared/enums';
import { getDefaultOrderInput, getProductName } from 'shared/helpers/order-entry/order-entry.helper';
import { LocalOrderItemInput, LocalOrderType } from 'shared/models';
import { isRestorationTypeBridge } from 'shared/utils';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { v4 as uuidv4 } from 'uuid';
import { shallow } from 'zustand/shallow';

/**
 * Props for the TabStrip component.
 */
interface TabStripProps {
  /**
   * The ID of the currently selected product.
   */
  selectedProductId: string;
  /**
   * Callback function invoked when a tab is selected.
   */
  onTabSelect: (id: string) => void;
  /**
   * Callback function invoked when a new tab is added.
   */
  onTabAdd: () => void;
  /**
   * Callback function invoked when a tab is removed.
   */
  onTabRemove: (id: string) => void;
}

/**
 * The TabStrip component displays tabs for each product in the order.
 * @param selectedProductId - The ID of the currently selected product.
 * @param onTabSelect - Callback function invoked when a tab is selected.
 * @param onTabAdd - Callback function invoked when a new tab is added.
 * @param onTabRemove - Callback function invoked when a tab is removed.
 * @returns JSX element representing the TabStrip component.
 */
const TabStrip: React.FC<TabStripProps> = ({ selectedProductId, onTabSelect, onTabAdd, onTabRemove }) => {
  const [showRMACaseAlertModal, setShowRMACaseAlertModal] = useState(false);
  const { order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const navigate = useNavigate();
  const { addOrder, setOrders } = useBundleSplitCaseStore(
    state => ({
      addOrder: state.addOrder,
      setOrders: state.setOrders,
    }),
    shallow
  );
  const { getMaterialAndResorationType, checkOrderItemIsValid } = useContext(OrderModuleActionsContext);
  const defaultTitle = 'New Product';

  const getProductNameFromCode = (productCode: string) => {
    const materialAndRestoration = getMaterialAndResorationType(productCode);
    if (!materialAndRestoration) return '';
    return getProductName(materialAndRestoration.materialName, materialAndRestoration.restorationName);
  };

  const renderToothNumber = (index: number) => {
    if (order.orderItems.length > 1) {
      const toothValue = order.orderItems[index].attributes.find(item => item.name === AttributeType.ToothString);
      const isBridge = isRestorationTypeBridge(order.orderItems[index].restorationType);
      if (toothValue?.value) {
        return ` ${!isBridge ? toothValue.value : toothValue.value.replace(/#(\d+).*#(\d+)/, '#$1-#$2')}`;
      }
    }
  };

  const hasError = useCallback(
    (item: LocalOrderItemInput) => {
      return onSubmitAlertCount > 0 && !checkOrderItemIsValid(item);
    },
    [onSubmitAlertCount, checkOrderItemIsValid]
  );
  const products = order.orderItems;
  const hasNewTab = products.findIndex(orderItem => orderItem.restorationType === '') > -1;

  const { id: orderUpdateId } = useParams<{ id: string }>();
  const isOrderUpdate = !!orderUpdateId;

  /**
   * Resets the page to only contain top-most order information.
   * This runs specifically when the user tries to add another product to an existing RMA order.
   */
  const handleRmaCaseAlertConfirmOnOrderUpdate = () => {
    const {
      billingAccountId,
      providerId,
      providerName,
      shippingAddress,
      patientFirstName,
      patientId,
      patientLastName,
      customerDueDate,
      originFacility,
      originFacilityId,
    } = order;
    setOrders([
      {
        ...getDefaultOrderInput(),
        billingAccountId,
        providerId,
        providerName,
        shippingAddress,
        patientFirstName,
        patientId,
        patientLastName,
        customerDueDate,
        originFacility,
        originFacilityId,
        id: uuidv4(),
        isInvalid: false,
        orderNumber: NEW_CASE,
      },
    ]);
    /**
     * Because NEW_ORDER and UPDATE_ORDER share the same page now,
     * this will just refresh the data on the page you are currently on
     * without actually redirecting you to a new page (please see LMS1-5116).
     */
    navigate(ROUTES.NEW_ORDER, {
      state: {},
    });
  };

  /**
   * Depending on whether we are in case edit mode, either resets the page to only contain top-most order information (case edit),
   * or adds a new order to the current bundle (case creation).
   */
  const handleRMACaseAlertModalClose = () => {
    if (isOrderUpdate) {
      handleRmaCaseAlertConfirmOnOrderUpdate();
    } else {
      // As per LMS1-5203, enclosed items, coupons and notes from the original order do not get copied over.
      addOrder({
        ...order,
        localMetadata: {
          localOrderType: LocalOrderType.Standard,
        },
        orderNumber: NEW_CASE,
        originalOrderNumber: '',
        orderItems: [],
        coupons: [],
        enclosedItems: [],
        notes: undefined,
      });
    }
    setShowRMACaseAlertModal(false);
  };

  const rmaCaseAlertModalText = useMemo(() => {
    return isOrderUpdate
      ? 'Create a new case if you need to add another product. You will be redirected to the New Case page.'
      : 'Create a new case that will be bundled with the RMA/MP case if you need to add another product.';
  }, [isOrderUpdate]);

  return (
    <>
      <div className="px-6 pt-1 bg-white border-b border-gray-200 over-flow flex gap-6 flex-wrap w-full">
        {products.map((product, index) => {
          const productHasError = hasError(product);
          return (
            <div
              className={classNames('flex', {
                'border-b-2 border-indigo-600 text-indigo-600': product.id === selectedProductId && !productHasError,
                'border-b-2 border-white': product.id !== selectedProductId,
                'text-gray-500': product.id !== selectedProductId,
                'border-b-2 border-red-500': product.id === selectedProductId && productHasError,
              })}
              key={product.id}
              data-testid={`productTab-${index}`}
            >
              {productHasError && <ExclamationCircleIcon className="h-5 w-5 text-red-500 mt-4 ml-1" />}

              <div
                onClick={() => onTabSelect(product.id)}
                className={classNames('row-span-2 text-sm font-medium cursor-pointer py-4 px-1', {
                  'text-red-500': productHasError,
                })}
                data-qa={`tab${product.productCode}`}
                data-testid={`tab${product.productCode}`}
              >
                {product.productCode ? getProductNameFromCode(product.productCode) || 'Unknown Product' : defaultTitle}
                {renderToothNumber(index)}
              </div>
              {index > 0 && (
                <button className="py-4 px-1" onClick={() => onTabRemove(product.id)} data-qa={'removeTabButton'}>
                  <XCircleIcon className="h-5 w-5 text-indigo-500" />
                </button>
              )}
            </div>
          );
        })}

        <div className="flex flex-1 justify-end mb-1" data-testid="btn">
          <div className="my-auto">
            <button
              onClick={() => {
                if (/^((rma|mp)\d)-/i.test(order.orderNumber || '')) {
                  setShowRMACaseAlertModal(true);
                } else {
                  onTabAdd();
                }
              }}
              type="button"
              disabled={hasNewTab}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              data-qa="addTabButton"
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              Add Product/Service
            </button>
          </div>
        </div>
      </div>
      {showRMACaseAlertModal && (
        <DialogBox
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          title="Can’t add product to an RMA or MP case"
          onCancel={() => setShowRMACaseAlertModal(false)}
          cancelText="Cancel"
          confirmText="Create New Case"
          confirmButtonVariant="primary"
          onConfirm={handleRMACaseAlertModalClose}
        >
          <div className="text-gray-500 text-sm">{rmaCaseAlertModalText}</div>
        </DialogBox>
      )}
    </>
  );
};

export default TabStrip;
