import { UninvoiceMutation, UninvoiceMutationVariables } from 'API';
import Http from './http';
import { uninvoice } from 'graphql/mutations';

/**
 * api request to un-invoice a work order
 * @param params - orderNumber, updatedBy, utcConversionTimeZone
 * @returns whether un-invoicing is successful
 */
export const unInvoice = async (params: UninvoiceMutationVariables) => {
  const response = await Http.handleGraphqlOperation<UninvoiceMutation>(uninvoice, params);
  return Http.processGraphqlResponse(response.uninvoice, 'UnInvoiceResponse');
};
