import { Coupon, Credit, Deduction, OrderStatus } from 'API';
import { Table } from 'components/common/Table/Table';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useMemo } from 'react';
import { CaseCouponItem, caseCouponColumns } from 'shared/constants/case-detail.constants';
import CaseCredits from './CaseCredits/CaseCredits';
import CaseDiscounts from './CaseDiscounts/CaseDiscounts';

/**
 * Props of the CaseDeductions component.
 */
interface CaseDeductionsProps {
  /**
   * An array of coupons.
   */
  coupons: Coupon[];
  /**
   * An array of discounts.
   */
  discounts: Deduction[];
  /**
   * An array of credits.
   */
  credits: Credit[];
  /**
   * The order status.
   */
  orderStatus?: OrderStatus;
  /**
   * A function to refresh the order.
   */
  orderRefresh: () => void;
  /**
   * A boolean indicating whether the order is processing.
   */
  isOrderProcessing: boolean;
}

/**
 * Component for displaying deductions related to a case.
 * @param coupons - An array of coupons.
 * @param discounts - An array of discounts.
 * @param credits - An array of credits.
 * @param orderStatus - The order status.
 * @param orderRefresh - A function to refresh the order.
 * @param isOrderProcessing - A boolean indicating whether the order is processing.
 * @returns A JSX element representing the CaseDeductions component.
 */
const CaseDeductions: React.FC<CaseDeductionsProps> = ({
  coupons,
  discounts,
  credits,
  orderStatus,
  orderRefresh,
  isOrderProcessing,
}) => {
  const { caseDetails } = useCaseDetail();

  // Parses coupon information into a more friendly format, which will be displayed in the Coupons table below.
  const caseCouponRows: CaseCouponItem[] = useMemo(() => {
    const utcConversionTimeZoneCode = caseDetails?.utcConversionTimeZoneCode;
    return coupons.map(({ code, createdBy, createdDate, discountAmount, discountType, status }) => ({
      code,
      createdBy,
      createdDate,
      discountAmount,
      discountType,
      reason: '',
      status,
      utcConversionTimeZoneCode,
    }));
  }, [caseDetails?.utcConversionTimeZoneCode, coupons]);

  return (
    <div className="p-6">
      <CaseDiscounts discounts={discounts} orderStatus={orderStatus} isOrderProcessing={isOrderProcessing} />
      <div className="mb-6">
        <Table label="Coupons" columns={caseCouponColumns} rows={caseCouponRows} loading={false} />
      </div>
      <CaseCredits
        credits={credits}
        orderStatus={orderStatus}
        orderRefresh={orderRefresh}
        isOrderProcessing={isOrderProcessing}
      />
    </div>
  );
};

export default CaseDeductions;
