import { AlertMessage } from 'components/common/Alert/AlertMessage';
import React from 'react';
import toast, { ToastOptions, Toaster } from 'react-hot-toast';
import { ToastNotificationType } from 'shared/enums';
import { v4 as uuidv4 } from 'uuid';

/**
 * Represents the type of the toast state.
 */
export interface ToastState {
  /**
   * Function to display a toast notification.
   * @param message - The message to display in the notification.
   * @param notificationType - The type of notification (success, error, warning, etc.).
   * @param notifyOptions - Additional options for customizing the notification.
   */
  notify: (message: string, notificationType: ToastNotificationType, notifyOptions?: ToastOptions) => void;
  /**
   * Function to remove a toast notification.
   * @param toastId - The ID of the toast notification to remove. If not provided, removes all notifications.
   */
  remove: (toastId?: string | undefined) => void;
}

/**
 * Context for managing toast notifications.
 */
export const ToastContext = React.createContext<ToastState>({
  notify: () => {
    console.log();
  },
  remove: () => {
    return;
  },
});

/**
 * Provider component for managing toast notifications.
 */
export const ToastProvider: React.FC = ({ children }) => {
  const state = {
    notify: (message: string, notificationType: ToastNotificationType, notifyOptions?: ToastOptions) => {
      const id = notifyOptions?.id || uuidv4();
      toast.custom(<AlertMessage message={message} type={notificationType} toastId={id} showCloseIcon />, {
        ...notifyOptions,
        id,
        duration: notifyOptions?.duration || 5000,
      });
    },
    remove: (toastId?: string | undefined) => {
      toast.remove(toastId);
    },
  };

  return (
    <>
      <Toaster toastOptions={{ duration: 5000 }} />
      <ToastContext.Provider value={state}>{children}</ToastContext.Provider>
    </>
  );
};

/**
 * Custom hook for accessing the toast context.
 * @returns The toast context.
 */
export const useToast = () => {
  return React.useContext(ToastContext);
};
