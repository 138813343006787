import { ShippingOption } from 'API';
import { SkeletonBox } from 'components/common/Skeleton';
import { sumBy } from 'lodash';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { FC, useEffect, useState } from 'react';
import { ToastNotificationType } from 'shared/enums';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { FetchShippingOptionsInput } from 'types/common';
import { CarrierElement } from './CarrierElement/CarrierElement';
import { PackageTypeElement } from './PackageTypeElement/PackageTypeElement';
import { ShippingPreferenceColorElement } from './ShippingPreferenceColorElement/ShippingPreferenceColorElement';
import { ShippingPreferenceElement } from './ShippingPreferenceElement/ShippingPreferenceElement';
import { TrackingNumber } from './TrackingNumber/TrackingNumber';

/**
 * Component for displaying loading skeleton for shipping details.
 */
const ShippingDetailLoading = () => {
  return (
    <div className="flex flex-col gap-3 mt-2">
      <SkeletonBox className="w-full h-8" />
      <SkeletonBox className="w-full h-8" />
    </div>
  );
};

/**
 * Props interface for the ShippingDetailContent component.
 */
interface ShippingDetailContentProps {
  /**
   * Array of shipping options to display.
   */
  shippingOptions: ShippingOption[];
}

/**
 * Component for rendering the content of shipping details.
 * @param shippingOptions - Array of shipping options to display.
 * @returns JSX element representing the content of shipping details.
 */
const ShippingDetailContent: FC<ShippingDetailContentProps> = ({ shippingOptions }) => {
  if (!shippingOptions.length) return <div className="text-sm text-gray-500 mt-2">No shipping options found.</div>;
  return (
    <div className="flex flex-col">
      <TrackingNumber shippingOptions={shippingOptions} />
      <ShippingPreferenceElement />
      <PackageTypeElement shippingOptions={shippingOptions} />
      <CarrierElement shippingOptions={shippingOptions} />
      <ShippingPreferenceColorElement />
    </div>
  );
};

/**
 * Props interface for the ShippingDetail component.
 */
interface ShippingDetailProps {
  /**
   * Flag indicating whether the component is in loading state.
   */
  isLoading: boolean;
  /**
   * Function to fetch shipping options.
   */
  fetchShippingOptions: (data: FetchShippingOptionsInput) => Promise<ShippingOption[]>;
}

/**
 * Component for displaying shipping details.
 * @param isLoading - Flag indicating whether the component is in loading state.
 * @param fetchShippingOptions - Function to fetch shipping options.
 * @returns JSX element representing the shipping details.
 */
const ShippingDetail: FC<ShippingDetailProps> = ({ isLoading: invoiceLoading, fetchShippingOptions }) => {
  const { fetcher, loading } = useLazyQueryFetcher(fetchShippingOptions);
  const { invoiceCases, account, shippingOptions, setShippingOptions, outboundApplicableCase, codAmount } =
    useInvoicingDetail();
  const toast = useToast();

  const isLoading = loading || invoiceLoading;
  const [prevInvoiceLength, prevSetInvoiceLength] = useState(1);
  const [invoiceLength, setInvoiceLength] = useState(1);

  useEffect(() => {
    setInvoiceLength(invoiceCases.length);
  }, [invoiceCases]);

  useEffect(() => {
    const fetchShippingOptions = async () => {
      try {
        if (!account || invoiceLength === prevInvoiceLength || !outboundApplicableCase) return;
        const subtotalAmount = sumBy(invoiceCases, 'subtotalAmount');

        const shippingOptionsData = await fetcher({
          invoiceCase: outboundApplicableCase,
          account,
          subtotalAmount,
          initialCodAmount: codAmount,
        });
        setShippingOptions(shippingOptionsData);
        prevSetInvoiceLength(invoiceLength);
      } catch (err) {
        const error = err as Error;
        toast.notify(error.message, ToastNotificationType.Error);
      }
    };
    fetchShippingOptions();
  }, [
    account,
    fetcher,
    invoiceCases,
    invoiceLength,
    prevInvoiceLength,
    toast,
    setShippingOptions,
    outboundApplicableCase,
    codAmount,
  ]);

  if (isLoading) return <ShippingDetailLoading />;
  return <ShippingDetailContent shippingOptions={shippingOptions} />;
};

export default ShippingDetail;
