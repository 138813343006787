import { useAccount } from '@azure/msal-react';
import Button from 'components/common/Button/Button';
import { ROUTES } from 'components/navigation/Constants';
import { SignInLayout } from 'layouts/SignInLayout';
import { useAuth } from 'providers/AuthProvider';
import React from 'react';
import { Location, Navigate, NavigateProps, useLocation } from 'react-router-dom';

/**
 * Renders a page presenting Signing with SSO page
 */
const SignInWithSSO: React.FC = () => {
  const { signIn } = useAuth();
  const account = useAccount();

  const { state } = useLocation();

  const fromLocation: Location = state?.from;
  const pathname: NavigateProps['to'] = fromLocation?.pathname || ROUTES.ROOT;

  /**
   * callback function to handle sign in
   */
  const signInHandler = async () => {
    await signIn();
  };

  if (account) return <Navigate to={pathname} replace />;

  return (
    <SignInLayout>
      <div className="flex flex-col gap-3 items-center">
        <Button id="signInButton" onClick={signInHandler}>
          Sign in with SSO
        </Button>

        {/* <Link
          to={SIGN_IN_EMAIL_PATH}
          className="block mt-2 w-full text-center py-2 rounded text-indigo-700  text-base leading-6 font-medium
"
        >
          Sign in with email instead
        </Link> */}
      </div>
    </SignInLayout>
  );
};

export default SignInWithSSO;
