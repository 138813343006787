import ChevronRightIcon from '@heroicons/react/24/solid/ChevronRightIcon';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ACTIONS } from 'shared/enums/permission';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';

/**
 * Prop type for {@link TaskButton}
 */
interface TaskButtonProps {
  /**
   * id of task button
   */
  id: string;

  /**
   * represents the text to shown on {@link TaskButton}
   */
  caption: string;

  /**
   * the path to navigate if the {@link TaskButton} is clicked
   */
  navigationPath: string;
}

/**
 * Renders a Call-to-Action button for starting a task.
 * If the user doesn't have appropriate permissions for CTA, throws an error modal.
 * @param id - The id of the task button.
 * @param caption - The text to be shown.
 * @param navigationPath - The path to navigate if this button is clicked.
 * @returns A JSX element representing the TaskButton component.
 * @example
 * ```tsx
 * <TaskButton
 *   id="startTask"
 *   caption="Start Task"
 *   navigationPath="/tasks"
 * />
 * ```
 */

const TaskButton: React.FC<TaskButtonProps> = ({ id, caption, navigationPath }) => {
  const createCasePermission = useRoleBasedAccessControl(ACTIONS.CREATE_CASE);
  const invoiceCasePermission = useRoleBasedAccessControl(ACTIONS.INVOICE_CASE);
  const alert = useContext(AlertModalContext);
  const navigate = useNavigate();
  const clickHandler = () => {
    if (id === 'orderEntry') {
      if (createCasePermission.allowCreate) {
        navigate(navigationPath);
      } else {
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    } else if (id === 'invoicing') {
      if (invoiceCasePermission.allowCreate) {
        navigate(navigationPath);
      } else {
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    }
  };

  return (
    <button
      type="button"
      className="flex-grow w-full inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={clickHandler}
      id={id}
      data-qa={id}
    >
      <span className="flex-grow text-left">{caption}</span>
      <ChevronRightIcon className="flex-none -mr-0.5 h-5 w-5" aria-hidden="true" />
    </button>
  );
};

export default TaskButton;
