import { OrderSearchSortColumn, SearchedOrder } from 'API';
import { Table } from 'components/common/Table/Table';
import { useNavigate } from 'react-router-dom';
import { orderSearch } from 'shared/api/order.api';
import { orderHistoryColumns } from 'shared/constants/order.constants';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';

/**
 * Component to present a history of recently accessed case records.
 * @returns - A JSX element representing the TaskHistory component
 */
const TaskHistory: React.FC = () => {
  const navigate = useNavigate();
  // Fetching recent case records
  const { loading, data } = useQueryFetcher(orderSearch, {
    size: 10,
    sortColumn: OrderSearchSortColumn.ReceivedDate,
  });

  /**
   * Handles click on a row in the table.
   * Navigates to the detail page of the clicked order.
   * @param row - The clicked row object representing a searched order.
   */
  const rowClick = (row: SearchedOrder) => {
    navigate(getOrderDetailPath(row.orderNumber));
  };

  return (
    <>
      <div className="flex gap-4">
        <h2 className="text-xl font-bold text-gray-900 mt-0.5">Case History</h2>
      </div>

      <Table
        rows={data?.orders || []}
        columns={orderHistoryColumns}
        loading={loading}
        maxHeight="full"
        handleRowClick={rowClick}
        hoverRow
      />
    </>
  );
};

export default TaskHistory;
