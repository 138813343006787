import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { CaseHistoryPreferenceObject } from 'shared/constants/case-detail.constants';
import { convertCamelCaseToTitleCase } from 'shared/utils';

/**
 * Returns a component for visualizing the old or new version of a preference for a given additional details item.
 * @param attribute - The preference that was changed.
 * @returns a component for visualizing the old or new version of a preference for a given additional details item.
 */
export const PrintPreference = (attribute: object) => {
  const key = Object.keys(attribute)[0];
  const value = Object.values(attribute)[0];
  return (
    <div>
      <ul className="list-disc">
        <li className="ml-6">
          {convertCamelCaseToTitleCase(key)}: {value}
        </li>
      </ul>
    </div>
  );
};

export interface AdditionalDetailsPreferenceI {
  caseHistoryPreferenceObject: CaseHistoryPreferenceObject;
  id: string;
}

/**
 * Returns a component for visualizing the preference changes for a particular case history item.
 * @param caseHistoryPreferenceObject - contains information about the old and new preference data.
 * @param id - the partial id to use as the key for this component.
 * @returns a component for visualizing the preference changes for a particular case history item.
 */
export const AdditionalDetailsPreference = ({ caseHistoryPreferenceObject, id }: AdditionalDetailsPreferenceI) => {
  const { oldData, newData, name } = caseHistoryPreferenceObject;
  const hasOldData = !!oldData?.length;
  const hasNewData = !!newData?.length;
  if (!hasOldData && !hasNewData) return null;

  return (
    <div className="flex flex-1 justify-between mb-2">
      {hasOldData && (
        <div className="block">
          <strong>{name} Override</strong>
          {oldData?.map((item, index) => (
            <div key={`old-${id}-${index}`}>
              <PrintPreference {...item} />
            </div>
          ))}
        </div>
      )}
      {hasOldData && hasNewData && (
        <div className="mx-6">
          <ArrowRightIcon className="w-5 h-5" />
        </div>
      )}
      {hasNewData && (
        <div className="block">
          <strong>{name} Override</strong>
          {newData?.map((item, index) => (
            <div key={`new-${id}-${index}`}>
              <PrintPreference {...item} />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
