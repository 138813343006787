import { OrderBundle, OrderStatus, SearchedOrder } from 'API';
import { LabelRow, Table } from 'components/common/Table/Table';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { removeBundledOrder } from 'shared/api/order.api';
import { CaseBundlesWithTimezone, caseBundlesColumns } from 'shared/constants/case-bundles/case-bundles.constants';
import { ToastNotificationType } from 'shared/enums';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { checkCaseIsInvoiced } from 'shared/utils';
import RemoveBundleModal from './RemoveBundleModal';

/**
 * Props for the NoDataFound component.
 */
interface NoDataFoundProps {
  bundleType: string; // bundle type
  count: number; // Number of col span
}

/**
 * Returns a component for notifying the user that there is no data in this table.
 * @param bundleType - The type of bundle (inbound/outbound) for use as a label.
 * @param count -  Number of col span
 * @returns component for notifying the user that there is no data in this table.
 */
const NoDataFound: React.FC<NoDataFoundProps> = ({ bundleType, count }) => {
  return (
    <tr>
      <td className="text-center py-10 text-gray-500 text-sm" colSpan={count}>
        No {bundleType} bundles for this case
      </td>
    </tr>
  );
};

/**
 * Props for the CaseBundles component.
 */
interface CaseBundlesProps {
  orderNumber: string; // The order number associated with the case
  bundles: OrderBundle | undefined | null; // Information about the bundles associated with the case
  caseStatus: OrderStatus | undefined; // The status of the case
  bundleId: string | null; // The ID of the bundle
}

/**
 * Returns a component that displays other orders with the same bundle ID in two tables.
 * @param caseBundles - An array of orders with the same bundle ID as the current order.
 * @param orderNumber - The current order number.
 * @returns a component that displays other orders with the same bundle ID in two tables.
 */
const CaseBundles: React.FC<CaseBundlesProps> = ({ bundles, orderNumber, caseStatus, bundleId }) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [outboundTableData, setOutboundTableData] = useState<CaseBundlesWithTimezone[] | []>([]);
  const [removingOrderNumber, setRemovingOrderNumber] = useState('');
  const toast = useContext(ToastContext);
  const { fetcher: removeFromBundleFetcher, loading: removeFromBundleLoading } =
    useLazyQueryFetcher(removeBundledOrder);
  const { setCaseDetails, caseDetails } = useCaseDetail();

  const isCaseInvoiced = useMemo(() => checkCaseIsInvoiced(caseStatus), [caseStatus]);

  const inboundBundles = useMemo(() => {
    if (!bundles?.inboundBundle) return [];
    // Sort the inbound bundles so that the current order is always at the top.
    return bundles?.inboundBundle?.bundledOrderNumbers.sort((a, b) => {
      if (a.orderNumber === orderNumber) {
        return -1;
      } else if (b.orderNumber === orderNumber) {
        return 1;
      }
      return 0;
    });
  }, [bundles, orderNumber]);

  useEffect(() => {
    const merged = isCaseInvoiced
      ? bundles?.outboundBundle?.bundledOrderNumbers || []
      : bundles?.splitBundle?.bundledOrderNumbers || [];
    const mergedArraySorted = merged.sort((a, b) => {
      if (a.orderNumber === orderNumber) {
        return -1;
      } else if (b.orderNumber === orderNumber) {
        return 1;
      }
      return 0;
    });

    let detachedOrders: CaseBundlesWithTimezone[] = [];

    // Only add detached orders if there is a bundleId (which is only truthy if split bundle and not invoiced).
    if (bundleId) {
      detachedOrders = (bundles?.splitBundle?.detachedOrderNumbers || []).map(order => {
        return {
          ...order,
          isDetached: true,
        };
      });
    }

    const mergedArray = [...mergedArraySorted, ...detachedOrders];

    if (mergedArray.length) {
      setOutboundTableData(mergedArray);
    } else {
      setOutboundTableData([]);
    }
  }, [bundleId, bundles, caseStatus, isCaseInvoiced, orderNumber]);

  const columns = caseBundlesColumns(orderNumber, bundles?.inboundBundle?.chargedOrderNumber || '', []);

  const bundleSplitCaseNumbers = useMemo(
    () => bundles?.splitBundle?.bundledOrderNumbers.map(item => item.orderNumber),
    [bundles?.splitBundle]
  );

  const columnsOut = caseBundlesColumns(
    orderNumber,
    bundles?.outboundBundle?.chargedOrderNumber || '',
    bundleSplitCaseNumbers,
    true,
    row => onRemoveClickFromTable(row)
  );

  const onRemoveClickFromTable = (row: SearchedOrder) => {
    setShowRemoveModal(true);
    setRemovingOrderNumber(row.orderNumber);
  };

  const onRemoveConfirm = async () => {
    try {
      await removeFromBundleFetcher({
        bundleId: bundleId || '',
        orderNumber: removingOrderNumber,
      });

      // Update case details with new bundle data so the view gets updated without needing to refresh.
      if (caseDetails?.bundles?.splitBundle) {
        const newSplitBundleArray = caseDetails.bundles.splitBundle.bundledOrderNumbers.filter(
          data => data.orderNumber !== removingOrderNumber
        );
        const detachedOrder = caseDetails.bundles.splitBundle.bundledOrderNumbers.find(
          data => data.orderNumber === removingOrderNumber
        );
        const newDetachedOrders = caseDetails.bundles.splitBundle.detachedOrderNumbers || [];
        if (detachedOrder) newDetachedOrders.push(detachedOrder);
        const newCaseBundles: OrderBundle = {
          ...caseDetails?.bundles,
          splitBundle: {
            ...caseDetails.bundles.splitBundle,
            bundledOrderNumbers: newSplitBundleArray,
            detachedOrderNumbers: newDetachedOrders,
          },
        };
        setCaseDetails({ ...caseDetails, bundles: newCaseBundles });
      }

      toast.notify(
        `Case #${removingOrderNumber} has been removed from bundle #${bundleId}`,
        ToastNotificationType.Success
      );
    } catch (e) {
      console.error(e);
      toast.notify(`Error removing bundle`, ToastNotificationType.Error);
    } finally {
      setRemovingOrderNumber('');
      setShowRemoveModal(false);
    }
  };

  // The label row to show when there is at least one detached order for split bundle before the case is invoiced.
  const outboundBundleLabelRows: LabelRow[] | undefined = useMemo(() => {
    const firstIndexOfDetached = outboundTableData.findIndex(data => data.isDetached);
    if (firstIndexOfDetached > -1 && bundleId) {
      return [
        {
          index: firstIndexOfDetached,
          label: 'REMOVED FROM BUNDLE',
        },
      ];
    }

    return undefined;
  }, [bundleId, outboundTableData]);

  return (
    <div className="p-6">
      <div className="flex flex-col gap-6">
        <Table
          label="Inbound Bundle"
          rows={inboundBundles}
          columns={columns}
          noDataFound={<NoDataFound bundleType="inbound" count={columns.length} />}
        />
        <Table
          label={<>Outbound Bundle {bundleId ? <span className="text-gray-500">({bundleId})</span> : ''}</>}
          rows={outboundTableData}
          columns={columnsOut}
          labelRows={outboundBundleLabelRows}
          noDataFound={<NoDataFound bundleType="outbound" count={columnsOut.length} />}
        />
        {showRemoveModal && (
          <RemoveBundleModal
            onCancel={() => setShowRemoveModal(false)}
            bundleId={bundleId || 'N/A'}
            orderNumber={removingOrderNumber}
            onConfirm={onRemoveConfirm}
            isLoading={removeFromBundleLoading}
          />
        )}
      </div>
    </div>
  );
};

export default CaseBundles;
