import { subscribeToInvoice } from 'shared/api/invoice.api';
import { printInvoice } from './print.helper';
import { SubscriptionObject } from 'providers/SubscriptionProvider';

/**
 * Fetches the total amount of an invoice and returns it.
 * @param orderNumber - The order number of the invoice to fetch.
 * @returns The total amount of the invoice.
 * @throws An error if there was a problem printing the invoice.
 */
export const fetchPrintInvoiceTotalAmount = async (orderNumber: string) => {
  let totalAmount = 0;
  try {
    // TODO: Should be getting invoice html from subscription payload to pass into below function so we don't have to fetch.
    const printInvoiceCallback = (invoiceTotal: number) => {
      totalAmount += invoiceTotal;
    };
    await printInvoice(orderNumber, undefined, printInvoiceCallback);
    return totalAmount;
  } catch (error) {
    console.error(`Error printing invoice for order ${orderNumber}: ${error}`);
    return totalAmount;
  }
};

interface HandlePrintInvoiceI {
  orderNumber: string;
  sessionId: string;
  subId: string;
  addSubscription: (subscription: SubscriptionObject) => void;
  callback?: (shippingCharge: number) => void;
}

/**
 * Handles printing an invoice.
 * @param addSubscription - The method for adding a subscription to an array of subscriptions.
 * @param orderNumber - The order number of the target case.
 * @param sessionId - The session ID.
 * @param subId - The subscription ID.
 * @param callback - Callback to fire once subscription is triggered.
 */
export const handlePrintInvoiceSubscription = ({
  addSubscription,
  orderNumber,
  sessionId,
  subId,
  callback,
}: HandlePrintInvoiceI) => {
  let totalAmount = 0;
  // set up a subscription to listen for work order ready
  const sub = subscribeToInvoice({
    OrderNumber: orderNumber,
    SessionId: sessionId,
  }).subscribe({
    next: async () => {
      // no need to keep this open
      sub.unsubscribe();
      try {
        // TODO: Should be getting invoice html from subscription payload to pass into below function so we don't have to fetch.
        const printInvoiceCallback = (invoiceTotal: number) => {
          totalAmount += invoiceTotal;
        };
        await printInvoice(orderNumber, undefined, printInvoiceCallback);
      } catch (e) {
        console.error('Error printing invoice: ', e);
      } finally {
        if (callback) {
          callback(totalAmount);
        }
      }
    },
    error: (error: string) => console.warn(error),
  });

  // add this sub to context so we could access it from anywhere
  addSubscription({ uuid: subId, subscription: sub });
};
