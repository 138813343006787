import { FC, ReactNode } from 'react';
import { cn } from 'shared/utils';

/**
 * Props for the Badge component.
 */
interface BadgeProps {
  /** The text to display within the badge. */
  text: ReactNode;
  /** The variant style of the badge. */
  variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
  /** Additional CSS classes for styling. */
  className?: string;
}

const variantClasses = {
  primary: 'bg-purple-100 text-purple-800',
  secondary: 'bg-gray-100 text-gray-800',
  success: 'bg-green-100 text-green-800',
  danger: 'bg-red-100 text-red-800',
  warning: 'bg-yellow-100 text-yellow-800',
  info: 'bg-teal-100 text-teal-800',
  light: 'bg-gray-100 text-gray-800',
  dark: 'bg-gray-800 text-gray-100',
};

/**
 * Component for rendering a badge.
 *
 * @param text - The text to display within the badge.
 * @param variant - The variant style of the badge.
 * @param className - Additional CSS classes for styling.
 * @returns JSX.Element - The rendered badge.
 */
export const Badge: FC<BadgeProps> = ({ text, variant = 'secondary', className }) => {
  return (
    <span
      className={cn(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        variantClasses[variant],
        className
      )}
    >
      {text}
    </span>
  );
};
