import { OrderEntryContainer } from 'components/order-entry/OrderEntryContainer/OrderEntryContainer';
import { OrderModuleProvider } from 'providers/OrderModuleProvider';
import React from 'react';
import { cn } from 'shared/utils';

/**
 * Input props for {@link TabContent}
 */
interface TabContentProps {
  /**
   * represents order id of order
   */
  orderId: string;

  /**
   * represents order number of order
   */
  orderNumber: string;

  /**
   * indicates if current tab is active
   */
  isActive: boolean;
}

/**
 * wrapper for [Order Entry Container]{@link OrderEntryContainer}
 *
 * @param orderNumber - represents order number of order
 * @param orderId - represents order id of order
 * @param isActive - indicates if current tab is active
 */
export const TabContent = React.forwardRef<React.ComponentRef<typeof OrderEntryContainer>, TabContentProps>(
  ({ orderNumber, isActive, orderId }, ref) => {
    return (
      <div
        className={cn('flex flex-col h-full', {
          hidden: !isActive,
        })}
      >
        <OrderModuleProvider isBundleSplitCase orderId={orderId}>
          <OrderEntryContainer ref={ref} isBundleSplitCase orderNumber={orderNumber} orderId={orderId} />
        </OrderModuleProvider>
      </div>
    );
  }
);
