import { OrderNotificationType } from 'API';
import { useAppConfig } from 'providers/AppProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { subscribeToOrderNotificationAvailable } from 'shared/api/order.api';
import { ZenObservable } from 'zen-observable-ts';

export interface NotificationEventState {
  isOrderNotificationProcessing: boolean;
  orderNotificationEvent: OrderNotificationType | null;
}

/**
 * this hook can be useful to asynchronously notify subscriber
 * for order events like case price / tax computation completion
 *
 * @param orderNumber - number of order whose price / tax collection needs to be done
 *
 * @returns an array of
 *  emitted event,
 *  a function to reset the notification event,
 *  and function for unsubscribing for
 */
export const useOrderNotificationAvailable = (orderNumber: string | undefined) => {
  const { sessionId } = useAppConfig();
  const [orderNotificationEvent, setOrderNotificationEvent] = useState<OrderNotificationType | null>(null);

  const subscription = useRef<ZenObservable.Subscription>();
  const resetNotificationEvent = useCallback(() => {
    setOrderNotificationEvent(null);
  }, []);

  const unsubscribe = useCallback(() => {
    if (subscription.current && !subscription.current.closed) {
      subscription.current.unsubscribe();
    }
  }, []);

  useEffect(() => {
    if (!orderNumber || !sessionId) return unsubscribe();

    setOrderNotificationEvent(null);

    const orderNotification$ = subscribeToOrderNotificationAvailable({
      OrderNumber: orderNumber,
      SessionId: sessionId,
    }).subscribe({
      next(result) {
        const orderNotificationAvailable = result.value.data?.orderNotificationAvailable;
        if (!orderNotificationAvailable) {
          console.error('orderNotificationAvailable is returning empty value!');
        }
        setOrderNotificationEvent(orderNotificationAvailable?.NotificationEvent || null);
      },
      error(errorValue) {
        console.error('Error subscribing to orderNotificationAvailable', errorValue);
        setOrderNotificationEvent(null);
        unsubscribe();
      },
    });
    subscription.current = orderNotification$;
    return () => {
      unsubscribe();
    };
  }, [sessionId, orderNumber, resetNotificationEvent, unsubscribe]);

  return { orderNotificationEvent, resetNotificationEvent, unsubscribe };
};
