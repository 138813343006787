import { ReturnType } from 'API';

/**
 * returns a user-friendly text representing `returnType`
 *
 * @param returnType - return type of RMA order
 */
export const getReturnTypeLabel = (returnType: ReturnType): string => {
  switch (returnType) {
    case ReturnType.ReturnForCredit:
      return 'Return for Credit';
    default:
      return returnType;
  }
};
