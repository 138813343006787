import { ROUTES } from 'components/navigation/Constants';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../common/Button/Button';

/**
 * Props interface for AuthenticationError component.
 */
interface AuthenticationErrorProps {
  error: Error; // Error object representing the authentication error.
  btnAction?: () => void; // Optional function to be executed when the button is clicked.
}

/**
 * Component to display authentication error message and provide option to navigate to sign-in page.
 * @param error - Error object representing the authentication error.
 * @param btnAction - Optional function to be executed when the button is clicked.
 */
export const AuthenticationError: React.FC<AuthenticationErrorProps> = ({ error, btnAction }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="flex min-h-40 bg-white p-6 gap-2 text-red-600">
          <h1 className="text-xl font-bold whitespace-nowrap">Authentication Error:</h1>
          <h2 className="text-lg font-semibold">{error.message}</h2>
        </div>
        <Button
          id="gotToSignin"
          variant="bordered"
          onClick={() => {
            if (btnAction) return btnAction();

            navigate(ROUTES.SIGN_IN_SSO_PATH, {
              state: {
                from: location,
              },
            });
          }}
        >
          Go to Sign In
        </Button>
      </div>
    </div>
  );
};
