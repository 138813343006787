import { ToolTip } from 'components/common/ToolTip/ToolTip';
import { FC } from 'react';

/**
 * Props for the DisplayDiscountItemTooltip component.
 */
interface DisplayDiscountItemsProps {
  /**
   * An array of items to be displayed in the tooltip.
   */
  items: string[];

  /**
   * The text to be displayed as the tooltip trigger.
   */
  text: string;

  /**
   * Additional CSS class for styling.
   */
  className?: string;
}

/**
 * Component to display a tooltip for a list of discount items.
 * @param items - An array of items to be displayed in the tooltip.
 * @param text - The text to be displayed as the tooltip trigger.
 * @param className - Additional CSS class for styling.
 * @returns JSX.Element
 */
export const DisplayDiscountItemTooltip: FC<DisplayDiscountItemsProps> = ({ items, text, className }) => {
  return (
    <ToolTip text={text} className={className}>
      <ul className="list-inside list-disc">
        {items.map((componentText, index) => (
          <li key={index}>{componentText}</li>
        ))}
      </ul>
    </ToolTip>
  );
};
