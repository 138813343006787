/**
 * represents codes for special keys in a keyboard,
 * used to identify the keystrokes pressed by user
 */
export enum KeyCode {
  Enter = 'Enter',
  Tab = 'Tab',
  Down = 'ArrowDown',
  Up = 'ArrowUp',
  Escape = 'Escape',
  Backspace = 'Backspace',
}
