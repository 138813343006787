import Label from 'components/common/Label';

interface AttributeAggregateNoItemProps {
  label: string;
  isRequired: boolean;
}
export const AttributeAggregateNoItem: React.FC<AttributeAggregateNoItemProps> = ({ label, isRequired }) => {
  return (
    <div className="col-span-4 mb-2">
      <Label required={isRequired} labelClassName="flex">
        {label}
      </Label>
      <div className="text-gray-500 text-xs ml-3 mt-1">Select Teeth First</div>
    </div>
  );
};
