import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { SearchedOrder, SearchedOrderItem } from 'API';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { cn } from 'shared/utils';

/**
 * Input props for {@link ExpandableOrders}
 */
interface ExpandableOrdersProps {
  /**
   * item representing a search result
   */
  order: SearchedOrder;
}

/**
 * Renders a search result item, capable of extending its view to accommodate few more products
 * if more than one product is assigned to case, shows a toggle to view more than one product
 *
 * @param order - item representing a result of search
 */
const ExpandableOrders: React.FC<ExpandableOrdersProps> = ({ order }) => {
  const [showMore, setShowMore] = useState(false);

  const firstItem = order.orderItems[0];
  const additionalItems = order.orderItems.slice(1, order.orderItems.length);

  /**
   * Renders a summary of a product in a case
   *
   * @param item - item representing result of a search
   * @param index - index of this item, in the list
   */
  const productJSX = (item: SearchedOrderItem, index: number): JSX.Element => {
    let attributes = '';
    if (item.attributes.length > 0) {
      attributes += item.attributes[0].name + ': ' + item.attributes[0].value;
      if (item.attributes.length > 1) {
        attributes += ', ' + item.attributes[1].name + ': ' + item.attributes[1].value;
      }
    }

    // build a jsx element to use
    // include product name and first two attributes
    return (
      <div className="pb-2" key={index}>
        {item.productName}
        {attributes && (
          <>
            <br />
            <span className="text-gray-500">{attributes}</span>
          </>
        )}
      </div>
    );
  };

  /**
   * on initial render, sets {@link showMore} to false, collapsing extended view
   */
  useEffect(() => {
    setShowMore(false);
  }, [order.orderItems]);

  return (
    <>
      {firstItem && productJSX(firstItem, 0)}
      {additionalItems.length > 0 && (
        <>
          <Link
            to=""
            onClick={e => {
              // Prevents page refresh
              e.preventDefault();
              // Toggles the show more chevron
              setShowMore(prevShowMore => !prevShowMore);
            }}
            className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
          >
            Show
            {showMore ? (
              <>
                <span className="px-1">less</span>
                <ChevronUpIcon className="w-5 h-5" aria-hidden="true" />
              </>
            ) : (
              <>
                <span className="px-1">more</span>
                <ChevronDownIcon className="w-5 h-5" aria-hidden="true" />
              </>
            )}
          </Link>
          <div className={cn({ hidden: !showMore }, 'pt-2')}>
            <div>{additionalItems.map((product, index) => productJSX(product, index))}</div>
          </div>
        </>
      )}
    </>
  );
};

export default ExpandableOrders;
