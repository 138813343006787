import { XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { LOWER_TEETH, UPPER_TEETH } from 'shared/utils';

/**
 * Props for the ToothChart component.
 */
export interface ToothChartProps {
  /**
   * Array of selected tooth numbers.
   */
  selected: string[];
  /**
   * Array of blocked tooth numbers.
   */
  blocked: number[];
  /**
   * Callback function invoked when a tooth is selected.
   */
  onSelect: (selected: number) => void;
  /**
   * Callback function invoked to clear tooth selection.
   */
  onClear: () => void;
}

/**
 * The ToothChart component displays a chart of teeth with selection capabilities.
 * @param selected - Array of selected tooth numbers.
 * @param blocked - Array of blocked tooth numbers.
 * @param onSelect - Callback function invoked when a tooth is selected.
 * @param onClear - Callback function invoked to clear tooth selection.
 * @returns JSX element representing the ToothChart component.
 */
const ToothChart: React.FC<ToothChartProps> = ({ selected, blocked, onSelect, onClear }) => {
  const upper: number[] = UPPER_TEETH;
  const lower: number[] = LOWER_TEETH;

  const getToothStyling = (toothNumber: string) => {
    if (selected.includes(toothNumber)) {
      return 'bg-indigo-800 text-white';
    } else if (blocked.includes(+toothNumber)) {
      return 'bg-gray-300 cursor-not-allowed';
    } else {
      return 'bg-white';
    }
  };

  return (
    <>
      <div className="gap-4 p-6">
        <div className="mb-2 text-center">
          <label className="text-gray-700 text-sm">Click to select/deselect a tooth</label>
        </div>
        <div className="flex flex-col text-xl text-gray-600 font-medium bg-indigo-200 py-3 px-16 rounded-full">
          <div className="flex">
            {upper.map(tooth => (
              <div
                key={tooth}
                className={classNames(
                  getToothStyling(tooth.toString()),
                  'border-2 border-gray-400 w-8 text-center pt-1.5 rounded-t-xl rounded-b-md h-10 cursor-pointer'
                )}
                onClick={() => {
                  !blocked.includes(tooth) && onSelect(tooth);
                }}
                data-qa={`tooth${tooth}`}
                data-testid={`tooth${tooth}`}
              >
                {tooth}
              </div>
            ))}
          </div>
          <div className="flex">
            {lower.map(tooth => (
              <div
                key={tooth}
                className={classNames(
                  getToothStyling(tooth.toString()),
                  'border-2 border-gray-400 w-8 text-center pt-1 rounded-b-xl rounded-t-md h-10 cursor-pointer'
                )}
                onClick={() => {
                  !blocked.includes(tooth) && onSelect(tooth);
                }}
                data-qa={`tooth${tooth}`}
                data-testid={`tooth${tooth}`}
              >
                {tooth}
              </div>
            ))}
          </div>
        </div>
        <div className="text-center mt-2">
          <button
            type="button"
            className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onClear}
            data-qa="clearSelectionButton"
            data-testid="clearSelectionButton"
          >
            <XMarkIcon className="h-4 mr-2 text-gray-700" />
            Clear Selection
          </button>
        </div>
      </div>
    </>
  );
};

export default ToothChart;
