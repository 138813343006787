import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { DiscountCaseItem } from './DiscountCaseItem';

/**
 * Props for the DiscountCaseItems component.
 */
interface DiscountCaseItemsProps {
  /**
   * List of options for order item dropdown.
   */
  options: OrderItemDropdownModel[];
}

/**
 * Component for displaying multiple discount case items in the discount modal.
 *
 * @param options - List of options for order item dropdown.
 * @returns a discount case items component.
 */
export const DiscountCaseItems: FC<DiscountCaseItemsProps> = ({ options }) => {
  const discounts = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const newDiscount = useAddDiscountModalStore(state => state.specialDiscount.newDiscount);
  const deleteDiscount = useAddDiscountModalStore(state => state.specialDiscount.deleteDiscount);
  const updateDiscount = useAddDiscountModalStore(state => state.specialDiscount.updateDiscount);
  const setDiscountErrors = useAddDiscountModalStore(state => state.specialDiscount.setDiscountErrors);

  const onAddDiscount = () => {
    newDiscount();
  };

  return (
    <div className="flex flex-col gap-2 mt-3">
      {discounts.map((discount, index) => (
        <DiscountCaseItem
          key={`${discount.orderItemId}-${index}`}
          {...discount}
          orderItemOptions={options}
          deleteDiscount={key => deleteDiscount(index, key)}
          updateDiscount={(data, key) => updateDiscount(index, data, key)}
          setDiscountErrors={errors => setDiscountErrors(index, errors)}
        />
      ))}
      <div className="mt-1">
        <button
          onClick={onAddDiscount}
          className="gap-1 inline-flex flex-row text-indigo-600 items-center text-sm justify-items-center focus-within:outline-none underline"
          type="button"
          data-qa="useAddSpecialDiscount"
          disabled={options.length === discounts.length}
        >
          <PlusCircleIcon className="w-5 h-5" />
          Add Another Discount
        </button>
      </div>
    </div>
  );
};
