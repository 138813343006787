import AlertModal from 'components/common/AlertModal';
import React, { useCallback, useContext, useEffect, useState } from 'react';

/**
 * Represents the state and methods for managing an alert modal.
 */
export interface AlertModalState {
  /**
   * Displays the alert modal with the provided title and message.
   * @param title - The title of the alert modal.
   * @param message - The message to display in the alert modal.
   * @param callback - Optional callback function to execute after closing the modal.
   */
  show: (title: string, message: string, callback?: () => void) => void;
}

/**
 * Context for managing the alert modal state.
 */
export const AlertModalContext = React.createContext<AlertModalState>({
  show: () => {
    console.log();
  },
});

/**
 * Provider component for managing the alert modal state.
 */
export const AlertModalProvider: React.FC = ({ children }) => {
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [onCloseModal, setOnCloseCallback] = useState<(() => void) | undefined>(undefined);
  const state = {
    /**
     * Displays the alert modal with the provided title and message.
     * @param title - The title of the alert modal.
     * @param message - The message to display in the alert modal.
     * @param callback - Optional callback function to execute after closing the modal.
     */
    show: (title: string, message: string, callback?: () => void) => {
      setTitle(title);
      setMessage(message);
      setShowModal(true);
      setOnCloseCallback(() => callback);
    },
  };

  /**
   * Handles modal closure and executes the callback if provided.
   */
  const handleModalClose = useCallback(() => {
    if (onCloseModal && !showModal) {
      onCloseModal();
    }
  }, [onCloseModal, showModal]);
  useEffect(() => {
    handleModalClose();
  }, [showModal, handleModalClose]);

  return (
    <>
      <AlertModalContext.Provider value={state}>{children}</AlertModalContext.Provider>
      {showModal && <AlertModal title={title} message={message} showModal={showModal} setShowModal={setShowModal} />}
    </>
  );
};

/**
 * Hook for accessing the alert modal context.
 * @returns The alert modal state and methods.
 * @throws Throws an error if used outside of AlertModalProvider.
 */
export const useAlertModal = () => {
  const alertModal = useContext(AlertModalContext);
  if (!alertModal) throw new Error('useAlertModal must be used within AlertModalProvider');
  return alertModal;
};
