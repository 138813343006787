import React from 'react';
import { cn } from 'shared/utils';

/**
 * Props for the Button component.
 */
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /** The variant style of the button. */
  variant?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | 'info' | 'light' | 'dark' | 'bordered';
  /** The size of the button. */
  size?: 'sm' | 'md' | 'lg';
  /** The data-testid attribute for testing. */
  dataTestId?: string;
}

const colorVariant = {
  primary: 'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
  secondary: 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500',
  danger: 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
  success: 'bg-green-600 hover:bg-green-700 focus:ring-green-500',
  warning: 'bg-yellow-600 hover:bg-yellow-700 focus:ring-yellow-500',
  info: 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500',
  light: 'bg-gray-100 text-gray-800 hover:bg-gray-200 focus:ring-gray-100',
  dark: 'bg-gray-800 text-gray-100 hover:bg-gray-900 focus:ring-gray-800',
  bordered: 'border border-gray-300 bg-white text-gray-800 hover:bg-gray-50 focus:ring-gray-200',
};

const sizeVariant = {
  sm: 'px-2 py-1 text-sm',
  md: 'px-4 py-2 text-base',
  lg: 'px-6 py-3 text-lg',
};

/**
 * Component for rendering a common Button.
 *
 * @param variant - The variant style of the button.
 * @param size - The size of the button.
 * @param dataTestId - The data-testid attribute for testing.
 * @param className - Additional CSS classes for styling.
 * @param restProps - Additional HTML button attributes.
 * @returns JSX.Element - The rendered button.
 */
const Button: React.FC<ButtonProps> = ({ variant = 'primary', size = 'md', dataTestId, className, ...restProps }) => {
  return (
    <button
      className={cn(
        'w-full flex items-center justify-center text-center text-white border text-base font-medium rounded-md shadow-sm border-transparent',
        colorVariant[variant],
        sizeVariant[size],
        className
      )}
      data-testid={dataTestId}
      {...restProps}
    />
  );
};

export default Button;
