import { OrderProductAttributeInput, ProductFullAttribute } from 'API';
import NumberInput from 'components/common/NumberInput/NumberInput';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext, useMemo } from 'react';
import { AttributeType } from 'shared/enums';
import { isPositiveNumberWithDecimal } from 'shared/utils';
import AttributeSelectDropdown from '../Inputs/AttributeSelectDropdown/AttributeSelectDropdown';

/**
 * Props for the Alloy component.
 */
interface AlloyProps {
  /**
   * List of available attributes.
   */
  attributes: ProductFullAttribute[];
  /**
   * Indicates whether the alloy selection is required.
   */
  isRequired: boolean;
  /**
   * Callback function to handle attribute change.
   */
  onAttributeChange: (attribute: OrderProductAttributeInput) => void;
  /**
   * The ID of the product.
   */
  productId: number;
  /**
   * Indicates whether the component is disabled.
   */
  disabled?: boolean;
  /**
   * The alloy attribute in the order.
   */
  alloyInOrder: OrderProductAttributeInput;
}

/**
 * The Alloy component manages the display and interaction of alloy attribute for an order item.
 * Users can select the alloy type and specify the alloy weight.
 * @param attributes - List of available attributes.
 * @param isRequired - Indicates whether the alloy selection is required.
 * @param onAttributeChange - Callback function to handle attribute change.
 * @param productId - The ID of the product.
 * @param disabled - Indicates whether the component is disabled.
 * @param alloyInOrder - The alloy attribute in the order.
 * @returns JSX element representing the Alloy component.
 */
const Alloy: React.FC<AlloyProps> = ({
  attributes,
  isRequired,
  onAttributeChange,
  productId,
  disabled,
  alloyInOrder,
}) => {
  const { onSubmitAlertCount } = useContext(OrderModuleContext);
  const { name: selectedAlloyType, value: selectedAlloyWeight } = alloyInOrder;

  /**
   * Sets the new alloy type and alloy weight in alloyInOrder.
   * @param newType - the new alloy type to set.
   * @param newWeight - the new alloy weight to set.
   */
  const handleAlloySelection = (newType: string, newWeight: string) => {
    // By default, the alloy name is unselected, until a matching alloy is found.
    // Regardless, we can still set the alloy weight.
    let alloyInput: OrderProductAttributeInput = {
      name: '',
      quantity: 0,
      type: AttributeType.Alloy,
      value: newWeight,
    };
    // In order for the alloy name to be set, an alloy type must first be selected.
    const matchingAttribute = attributes.find(a => a.name === newType);
    if (matchingAttribute) {
      alloyInput = {
        name: matchingAttribute.name,
        quantity: 1,
        type: matchingAttribute.type,
        value: newWeight,
      };
    }
    onAttributeChange(alloyInput);
  };

  /**
   * Handles setting a new alloy type.
   * @param newType - the new alloy type to use.
   */
  const onAlloyTypeChangeHandler = (newType: string) => {
    handleAlloySelection(newType, selectedAlloyWeight);
  };

  /**
   * Handles setting a new alloy weight.
   * @param newWeight - the new alloy weight to use.
   */
  const onAlloyWeightChangeHandler = (newWeight: string) => {
    const weightToUse = newWeight && isPositiveNumberWithDecimal(newWeight) ? newWeight : '0';
    handleAlloySelection(selectedAlloyType, weightToUse);
  };

  const alloyWeightValue = useMemo(() => selectedAlloyWeight || '0', [selectedAlloyWeight]);

  return (
    <div className="grid grid-cols-4 gap-x-6 gap-y-4">
      <AttributeSelectDropdown
        isRequired={isRequired}
        label="Alloy Type"
        options={attributes.map(a => ({ primaryLabel: a.name, value: a.name }))}
        onValueChange={onAlloyTypeChangeHandler}
        value={selectedAlloyType}
        isSubmitted={onSubmitAlertCount > 0}
        isDisabled={disabled}
        isShowLabelWhenDisabled
      />
      <div>
        <NumberInput
          id={`${productId}AttributeNumber`}
          inputRightPadding="2.5rem"
          isDisabled={disabled}
          label="Alloy Weight"
          min={0}
          onChange={onAlloyWeightChangeHandler}
          onSubmitAlert={onSubmitAlertCount > 0}
          placeholder="Enter weight"
          rightSideText="dwt"
          value={alloyWeightValue}
          warningText={!alloyWeightValue || alloyWeightValue === '0' ? 'Must be entered before invoicing' : ''}
        />
      </div>
    </div>
  );
};

export default Alloy;
