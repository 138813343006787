import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { FC, useState } from 'react';
import { cn } from 'shared/utils';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { shallow } from 'zustand/shallow';

/**
 * Input props for {@link SingleTabOrder}
 */
interface SingleTabOrderProps {
  index: number;
  id: string;
  orderNumber: string;
  isInvalid?: boolean;
}

/**
 *
 * @param props - {@link SingleTabOrderProps}
 */
export const SingleTabOrder: FC<SingleTabOrderProps> = props => {
  const { id, orderNumber, isInvalid, index } = props;
  const { selectedTab, setSelectedTab, deleteOrder } = useBundleSplitCaseStore(
    state => ({ selectedTab: state.selectedTab, setSelectedTab: state.setSelectedTab, deleteOrder: state.deleteOrder }),
    shallow
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const isActive = selectedTab === id;

  return (
    <div
      className={cn('flex', {
        'border-b-2 border-indigo-600 text-indigo-600': isActive && !isInvalid,
        'border-b-2 border-white': !isActive,
        'text-gray-500': !isActive,
        'border-b-2 border-red-500': isActive && isInvalid,
      })}
      data-testid={`tab-${index}`}
    >
      {isInvalid && <ExclamationCircleIcon className="h-5 w-5 text-red-500 mt-4 ml-1" />}

      <div
        onClick={() => setSelectedTab(id)}
        className={cn('row-span-2 text-sm font-medium cursor-pointer py-4 px-1', {
          'text-red-500': isInvalid,
        })}
      >
        {orderNumber}
      </div>
      {isActive && (
        <button className="py-4 px-1" onClick={() => setShowDeleteModal(true)} data-qa={`removeTabButton-${index}`}>
          <XCircleIcon
            className={cn('h-5 w-5 text-indigo-500', {
              'text-red-500': isInvalid,
            })}
          />
        </button>
      )}

      {showDeleteModal && (
        <DialogBox
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          title="Remove case from bundle?"
          onCancel={() => setShowDeleteModal(false)}
          cancelText="Cancel"
          confirmText="Remove"
          onConfirm={() => {
            deleteOrder(id);
            setShowDeleteModal(false);
          }}
        >
          <div className="text-gray-500 text-sm">Any info you entered so far for this case will be lost.</div>
        </DialogBox>
      )}
    </div>
  );
};
