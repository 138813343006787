/**
 * Component to display when no data is found for the table.
 * @param colSpan - The number of columns that the component should span.
 * @returns JSX.Element representing the TableNoDataFound component.
 */
export const TableNoDataFound: React.FC<{ colSpan: number }> = ({ colSpan, children }) => {
  return (
    <tr>
      <td colSpan={colSpan} className="text-gray-500 text-center py-5">
        {children || 'No results found.'}
      </td>
    </tr>
  );
};
