import { OrderOriginatingSystem, ShippingChargeType } from 'API';
import classNames from 'classnames';
import { SkeletonBox } from 'components/common/Skeleton';
import { find } from 'lodash';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { FC, useMemo } from 'react';
import { ShippingService } from 'shared/enums/shipping-carrier';
import { getOutboundDiscountAmount, getShippingCharge } from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrder } from 'shared/models';
import { checkCaseIsInvoiced, cn, convertToCurrency, isAccountOnCod } from 'shared/utils';

/**
 * Props interface for the SkeletonWrapper component.
 */
interface SkeletonWrapperProps {
  /**
   * Additional CSS classes for styling.
   */
  className?: string;
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
}

/**
 * Wrapper component for displaying skeleton loading state.
 */
const SkeletonWrapper: FC<SkeletonWrapperProps> = ({ className, children, isLoading }) => {
  return isLoading ? <SkeletonBox className={cn('h-3 w-full pl-8', className)} /> : <>{children}</>;
};

/**
 * Props interface for the InvoiceSummary component.
 */
interface InvoiceSummaryProps {
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading?: boolean;
  /**
   * The invoice case details.
   */
  invoiceCase: CreatedOrder;
  /**
   * Flag indicating whether the shipping charge is applicable for this case.
   */
  isChargeApplicableCase?: boolean;
}

/**
 * Component for displaying invoice summary details.
 *
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param invoiceCase - The invoice case details.
 * @param isChargeApplicableCase - Flag indicating whether the shipping charge is applicable for this case.
 */
const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({ isLoading, invoiceCase, isChargeApplicableCase }) => {
  const { getSelectedShippingData, account, invoiceShippingInput, codAmount } = useInvoicingDetail();

  const { outboundRate: localOutboundRate, outboundDiscountPercentage } = getSelectedShippingData();
  const bundledOrder = invoiceShippingInput.trackingNumberBundledOrder;
  const isBundledWithExistingTracking = !!bundledOrder && !invoiceShippingInput.inValidTrackingNumber;
  /**
   * Only include outbound if viewing charge eligible case and we are not bundling with existing tracking number or
   * existing tracking number case is not charge eligible.
   */
  const includeOutbound = isChargeApplicableCase && (!isBundledWithExistingTracking || !bundledOrder?.isChargeEligible);
  const isInvoicedCase = checkCaseIsInvoiced(invoiceCase?.status);

  const selectedShippingOption = useMemo(
    () => getSelectedShippingData().selectedShippingOption,
    [getSelectedShippingData]
  );

  const inboundRate = useMemo(() => {
    return getShippingCharge(invoiceCase.shipping?.shippingCharges || [], ShippingChargeType.Inbound);
  }, [invoiceCase.shipping?.shippingCharges]);

  const invoicedOutboundRate = useMemo(() => {
    return getShippingCharge(invoiceCase.shipping?.shippingCharges || [], ShippingChargeType.Outbound);
  }, [invoiceCase.shipping?.shippingCharges]);

  const codFeeRate = useMemo(() => {
    return getShippingCharge(invoiceCase.shipping?.shippingCharges || [], ShippingChargeType.Cod);
  }, [invoiceCase.shipping?.shippingCharges]);

  const outboundRate = useMemo(() => {
    // If the case is invoiced, return the outbound rate from the case details
    if (isInvoicedCase) return invoicedOutboundRate;

    const result = {
      amount: 0,
      discountAmount: 0,
    };
    // If the case is not invoiced and includeOutbound is true then return the selected outbound rate
    if (includeOutbound) {
      result.amount = localOutboundRate;
      result.discountAmount = getOutboundDiscountAmount(localOutboundRate, outboundDiscountPercentage);
    }
    // If the case is not invoiced and includeOutbound is false then return both amount and discount as 0
    return result;
  }, [includeOutbound, isInvoicedCase, outboundDiscountPercentage, invoicedOutboundRate, localOutboundRate]);

  const codFee = useMemo(() => {
    if (isInvoicedCase) return codFeeRate.amount; // if the case is invoiced, return the cod fee from the case details

    const accountCurrencyCode = account?.currencyCode;
    if (!selectedShippingOption || !accountCurrencyCode) return 0;

    const charges = selectedShippingOption.charges;
    const codFeeItem = find(charges, { service: ShippingService.COD_FEE });
    return find(codFeeItem?.currencies, { currencyCode: accountCurrencyCode })?.price || 0;
  }, [isInvoicedCase, codFeeRate.amount, account?.currencyCode, selectedShippingOption]);

  const totalDiscountAmount = useMemo(() => {
    let totalDiscountAmount = invoiceCase.totalDiscountAmount ?? 0;

    if (isInvoicedCase) return totalDiscountAmount;
    /**
     * If the case is not invoiced and includeOutbound is true then add the outbound rate discount amount to the total discount amount.
     * Because the outbound discount amount is already included in the total discount amount if the case is invoiced.
     */
    if (includeOutbound) {
      totalDiscountAmount += outboundRate.discountAmount;
    }
    return totalDiscountAmount;
  }, [includeOutbound, invoiceCase.totalDiscountAmount, isInvoicedCase, outboundRate.discountAmount]);

  const getInvoiceTotal = (isCodAmount?: boolean) => {
    let totalAmount = invoiceCase.totalAmount || 0;

    if (isCodAmount && codAmount) {
      totalAmount += codAmount;
    }

    // If the case is invoiced, return the total amount from the case details
    if (isInvoicedCase) return convertToCurrency(totalAmount);

    // If the case is not invoiced and includeOutbound is true then add the outbound rate amount and cod fee to the total amount
    if (includeOutbound) {
      totalAmount += outboundRate.amount;
      totalAmount += codFee;

      // Only subtract the outbound discount amount from the total if the case is not invoiced and includeOutbound is true. Because the discount amount is already included in the total amount if the case is invoiced.
      totalAmount -= outboundRate.discountAmount;
    }

    return convertToCurrency(totalAmount);
  };

  // Used as a flag to display the COD amount and total.
  const isShowCod = useMemo(() => {
    if (!account) return false;
    return isAccountOnCod(account.standing) || !!codAmount;
  }, [account, codAmount]);

  const isDlPlus = invoiceCase?.originatingSystem === OrderOriginatingSystem.DlPlus;

  return (
    <div>
      <div className="font-medium text-sm text-gray-500 pb-2 border-b border-gray-200 mb-2.5">
        {isLoading ? <SkeletonBox className="h-3" /> : `Case # ${invoiceCase.orderNumber}`}
      </div>

      <div className="flex">
        <div className="flex flex-col flex-grow gap-2 text-gray-500 font-medium text-sm text-right mt-0.5">
          <div>Subtotal</div>
          {!isDlPlus && <div>Shipping Inbound</div>}
          {!isDlPlus && <div>Shipping Outbound</div>}
          {!!totalDiscountAmount && <div>Discounts</div>}
          {!!codFee && <div>COD Fee</div>}
          <div>Tax</div>
        </div>
        <div
          className={classNames(
            'w-24 flex flex-col text-gray-900 text-base text-right justify-center items-end',
            isLoading ? 'gap-4' : 'gap-1'
          )}
        >
          <SkeletonWrapper isLoading={isLoading}>
            <div data-qa="subTotalLabel">{convertToCurrency(invoiceCase.subtotalAmount)}</div>
          </SkeletonWrapper>
          <SkeletonWrapper isLoading={isLoading}>
            {!isDlPlus && <div data-qa="shippingInboundLabel">{convertToCurrency(inboundRate.amount)}</div>}
          </SkeletonWrapper>

          <SkeletonWrapper isLoading={isLoading}>
            {!isDlPlus && <div data-qa="shippingOutboundLabel">{convertToCurrency(outboundRate.amount)}</div>}
          </SkeletonWrapper>
          {!!totalDiscountAmount && (
            <SkeletonWrapper isLoading={isLoading}>
              <div data-qa="couponsLabel">
                {totalDiscountAmount
                  ? `-${convertToCurrency(totalDiscountAmount)}`
                  : convertToCurrency(totalDiscountAmount)}
              </div>
            </SkeletonWrapper>
          )}
          {!!codFee && (
            <>
              <SkeletonWrapper isLoading={isLoading}>
                <div data-qa="codFeeLabel">{convertToCurrency(codFee)}</div>
              </SkeletonWrapper>
            </>
          )}
          <SkeletonWrapper isLoading={isLoading}>
            <div data-qa="taxLabel">{convertToCurrency(invoiceCase.totalTaxAmount)}</div>
          </SkeletonWrapper>
        </div>
      </div>

      <div className="3xl:ml-10 ml-16 mt-1 border-t border-gray-200 mb-1"></div>
      <div className="flex justify-end items-center">
        <div className="flex-grow text-sm font-bold text-right text-gray-700 mt-0.5">Invoice Total</div>
        <div className="w-24 font-bold text-gray-700 text-right" data-qa="invoiceTotalLabel">
          <SkeletonWrapper isLoading={isLoading} className="pl-8 w-full">
            {getInvoiceTotal()}
          </SkeletonWrapper>
        </div>
      </div>
      {isShowCod && (
        <>
          <div className="flex justify-end items-center">
            <div className="flex-grow text-sm text-right text-gray-700 mt-0.5">COD Amount</div>
            <div className="w-24 text-gray-700 text-right" data-qa="codAmountLabel">
              <SkeletonWrapper isLoading={isLoading} className="pl-8 w-full">
                {convertToCurrency(codAmount)}
              </SkeletonWrapper>
            </div>
          </div>
          <div className="3xl:ml-10 ml-16 mt-1 border-t border-gray-200 mb-1"></div>
          <div className="flex justify-end items-center">
            <div className="flex-grow text-sm font-bold text-right text-gray-700 mt-0.5">COD Total</div>
            <div className="w-24 font-bold text-gray-700 text-right" data-qa="codTotalLabel">
              <SkeletonWrapper isLoading={isLoading} className="pl-8 w-full">
                {getInvoiceTotal(true)}
              </SkeletonWrapper>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InvoiceSummary;
