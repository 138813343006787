/**
 * Return if a field is valid or not based on whether it has been touched or not and if the field error exists.
 * If you want to take into account whether the form has been submitted or not, pass in the submit count.
 *
 * @param fieldTouched - whether the field has been touched or not.
 * @param fieldError - field error
 * @param submitCount - the form submit count
 * @returns true if field has invalid input, else false
 */
export const isFormikFieldInvalid = (fieldTouched: boolean, fieldError: string | undefined, submitCount?: number) => {
  return (submitCount === undefined || submitCount > 0) && fieldTouched && !!fieldError && fieldError !== undefined;
};
