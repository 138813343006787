/**
 * list of supported error messages
 */
export enum ErrorMessage {
  INVALID_ACCOUNT_NUMBER = 'Invalid Account #',
  Order_Get_Fail = 'Failed to get the order, please try again',
  INVALID_ORDER_ID = 'Invalid Order ID',
  GENERAL_ERROR_MESSAGE = 'Something went wrong, please try again later.',
  MISSING_ALLOY_TYPE_OR_WEIGHT = 'Missing alloy type and/or weight',
  CANCELED_DISCARDED_CASE = 'This case was canceled and discarded. Discarded cases cannot be invoiced.',
  FORWARD_CASE = 'Forward case to call center',
  QUANTITY_MISMATCH = 'Quantity does not match pricing attribute',
  CASE_CREATION_NOT_ALLOWED_CREDIT_HOLD = 'Case creation is not allowed because Credit Hold account status',
  CANNOT_PROCESS_ADJUST = 'Cannot process Adjust without returned product',
  CANNOT_PROCESS_CREDIT = 'Cannot process credit without returned product',
  PRODUCT_RETURN_REQUIRED = 'Product required to be returned',
  INVALID_RETURN_TYPE_TITLE = 'Cannot create case with different return types',
  INVALID_RETURN_TYPE_DESCRIPTION = 'An RMA case with multiple types of return cannot be created. Separate cases will need to be created to support different return types.',
  TOTAL_DISCOUNT_AMOUNT_ERROR_MESSAGE = 'Total discount amount cannot be greater than the total amount.',
}

/**
 * list of common alerts
 */
export enum CommonAlerts {
  CANNOT_PROCESS_RMA = 'Cannot process the RMA',
  SEND_BACK_TO_SHIPPING_SPECIALIST = 'Send the case to a Shipping Specialist',
  CANNOT_PROCESS_RMA_EXCHANGE_TITLE = 'Cannot process the exchange without returned product',
  CANNOT_PROCESS_RMA_EXCHANGE_DESCRIPTION = 'A new case will now be created without applying a credit to the old case',
}
