/**
 * order item attributes to filter
 */
export enum OrderItemsToFilter {
  /**
   * orders which are cancelled and marked for sending back to customer, will have a placeholder orderItem with
   * productCode = 'OCRETURN', in some screens this is considered as an error and an error message is shown accordingly,
   * so filtering out such items out helps avoid false positives
   *
   * for further information refer https://glidewell.atlassian.net/browse/LMS1-5854?focusedCommentId=68680
   */
  OcReturn = 'OCRETURN',
}
