/**
 * list of analytics event names
 */
export enum AnalyticsEventName {
  CaseCanceled = 'Case Canceled',
  CaseContinued = 'Case Continued',
  CaseUpdated = 'Case Updated',
  CasePaused = 'Case Paused',
  RxViewed = 'Rx Viewed',
  CaseDetailsTabViewed = 'Case Details Tab Viewed',
  CaseHistoryFiltersCleared = 'Case History Filters Cleared',
  CaseHistoryFilterApplied = 'Case History Filter Applied',
  CaseDeleted = 'Case Deleted',
  CaseInvoiced = 'Case Invoiced',
  CaseUninvoiced = 'Case Uninvoiced',
  PrintWorkOrderClicked = 'Print Work Order Clicked',
  PrintInvoiceClicked = 'Print Invoice Clicked',
  PrintShippingLabelClicked = 'Print Shipping Label Clicked',
  CaseDeductionAdded = 'Case Deduction Added',
  CaseDeductionRemoved = 'Case Deduction Removed',
  CaseCreditAdded = 'Case Credit Added',
  CaseCreditRemoved = 'Case Credit Removed',
  SearchSubmitted = 'Search Submitted',
  SearchResultSelected = 'Search Result Selected',
  InvoiceLoadedError = 'Invoice Loaded - Error',
  InvoiceCanceled = 'Invoice Canceled',
  CaseEntryCanceled = 'Case Entry Canceled',
  CaseCreated = 'Case Created',
  CaseCreatedError = 'Case Created - Error',
  CaseEntryRelatedCasesCollapsed = 'Case Entry Related Cases Collapsed',
  CaseEntryRelatedCaseViewed = 'Case Entry Related Case Viewed',
}
