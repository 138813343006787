import { CaseDiscountType } from 'API';
import { SpecialDiscountTypeOption } from 'types/common';

/**
 * available discount type labels while applying discocunt to part of case
 */
export const PART_OF_CASE_DISCOUNT_TYPE = {
  INBOUND_SHIPPING: 'Inbound Shipping',
  OUTBOUND_SHIPPING: 'Outbound Shipping',
} as const;

/**
 * discount types labels
 */
export const DISCOUNT_TYPE_LABELS = {
  ...PART_OF_CASE_DISCOUNT_TYPE,
  ENTIRE_CASE: 'Entire Case',
  PART_OF_CASE: 'Part of Case',
} as const;

/**
 * represents supported discount types
 *
 * @see DiscountCaseType
 */
export const SPECIAL_DISCOUNT_TYPE_LISTS: SpecialDiscountTypeOption[] = [
  {
    id: 'entire-case',
    value: CaseDiscountType.EntireCase,
    label: DISCOUNT_TYPE_LABELS.ENTIRE_CASE,
  },
  {
    id: 'part-of-case',
    value: CaseDiscountType.PartOfCase,
    label: DISCOUNT_TYPE_LABELS.PART_OF_CASE,
  },
];
