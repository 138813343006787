import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { ClockIcon, XCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutoCompleteOption } from 'types/common';

/**
 * Props for the AutoCompleteSearchInput component.
 */
export interface AutoCompleteSearchInputProps {
  /** The list of options for auto-complete. */
  options: AutoCompleteOption[];
  /** The currently selected value. */
  value?: string;
  /** Function to handle value change. */
  onValueChange?: (option: AutoCompleteOption | null) => void;
  /** Placeholder text for the input field. */
  placeholder?: string;
  /** Flag to hide the clock icon. */
  hideClockIcon?: boolean;
  /** Flag to show the selected icon. */
  showSelectedIcon?: boolean;
  /** React node for the left icon. */
  leftIcon?: React.ReactNode;
  /** Label for the input field. */
  label?: string;
  /** Flag to autofocus on the input field. */
  autoFocus?: boolean;
}

const filterOptions = (options: AutoCompleteOption[], query: string) => {
  if (query === '') return options;

  return options.filter(option =>
    option.name.toLowerCase().replace(/\s+/g, '').includes(query?.toLowerCase().replace(/\s+/g, ''))
  );
};

/**
 * Component for rendering an auto-complete search input.
 *
 * @param options - The list of options for auto-complete.
 * @param value - The currently selected value.
 * @param onValueChange - Function to handle value change.
 * @param placeholder - Placeholder text for the input field.
 * @param hideClockIcon - Flag to hide the clock icon.
 * @param showSelectedIcon - Flag to show the selected icon.
 * @param leftIcon - React node for the left icon.
 * @param label - Label for the input field.
 * @param autoFocus - Flag to autofocus on the input field.
 * @returns JSX.Element - The rendered auto-complete search input.
 */
export const AutoCompleteSearchInput: FC<AutoCompleteSearchInputProps> = ({
  options,
  value,
  onValueChange,
  placeholder,
  showSelectedIcon = false,
  hideClockIcon = true,
  label,
  autoFocus,
}) => {
  const [selected, setSelected] = useState(() => {
    return options.find(option => option.value === value) || null;
  });
  const [query, setQuery] = useState(value || '');

  const optionsRef = useRef<AutoCompleteOption[]>([]);

  useEffect(() => {
    optionsRef.current = sortBy([...options], 'name');
  }, [options]);

  const filteredOptions = useMemo(() => {
    return filterOptions(optionsRef.current, query);
  }, [query]);

  const onSelectChange = useCallback(
    (option: AutoCompleteOption | null) => {
      setSelected(option);
      onValueChange?.(option);
    },
    [onValueChange]
  );

  useEffect(() => {
    const selectedOption = optionsRef.current.find(option => option.value === value);
    if (selectedOption) setSelected(selectedOption);
    if (!selectedOption && value) {
      setSelected({
        id: optionsRef.current.length + 1,
        name: value,
        value,
      });
    }
  }, [value]);

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuery(value);
  };

  const onAddNewItem = () => {
    if (!query) return;
    const newOption: AutoCompleteOption = {
      id: optionsRef.current.length + 1,
      name: query,
      value: query,
    };
    setQuery('');
    optionsRef.current = [...optionsRef.current, newOption];
  };

  return (
    <div className="w-full">
      <Combobox value={selected} onChange={onSelectChange}>
        <div className="relative mt-1">
          {label && <Combobox.Label className="font-medium text-gray-800 text-sm leading-5">{label}</Combobox.Label>}
          <div className="relative w-full cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm">
            <Combobox.Input
              className="focus:ring-indigo-500 focus:border-indigo-500 border-2 focus:border-2 border-gray-300
              shadow-sm text-sm rounded-md w-full pr-10 bg-white"
              onChange={onInputValueChange}
              placeholder={placeholder || 'Search'}
              displayValue={(item: AutoCompleteOption | null) => item?.name || ''}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Tab') return e.preventDefault();
                if (e.key === 'Enter') return onAddNewItem();
              }}
              data-qa="autoCompleteSearchInput"
              autoFocus={autoFocus}
            />
            {selected ? (
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2"
                onClick={() => onSelectChange(null)}
              >
                <XCircleIcon aria-hidden="true" className="h-5 w-5 text-gray-400 hover:text-gray-500" />
              </Combobox.Button>
            ) : (
              <Combobox.Button
                className="absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400 hover:text-indigo-500"
                onClick={() => onAddNewItem()}
              >
                <MagnifyingGlassIcon aria-hidden="true" className="h-5 w-5" />
              </Combobox.Button>
            )}
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options
              className={classNames(
                'absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                {
                  hidden: !filteredOptions.length,
                }
              )}
            >
              {!!query.length && (
                <Combobox.Option
                  value={{ id: optionsRef.current.length + 1, name: query, value: query }}
                  className={({ active }) =>
                    classNames('relative cursor-pointer select-none py-2 pr-4 hidden', {
                      'text-white bg-indigo-600': active,
                      'text-gray-900': !active,
                      'pl-10': showSelectedIcon,
                      'pl-4': !showSelectedIcon,
                    })
                  }
                >
                  {query}
                </Combobox.Option>
              )}
              {filteredOptions.map(option => (
                <Combobox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames('relative cursor-pointer select-none py-2 pr-4', {
                      'text-white bg-indigo-600': active,
                      'text-gray-900': !active,
                      'pl-10': showSelectedIcon,
                      'pl-4': !showSelectedIcon,
                    })
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {option.name}
                      </span>
                      {selected && showSelectedIcon ? (
                        <span
                          className={classNames('absolute inset-y-0 left-0 flex items-center pl-3', {
                            'text-white': active,
                            'text-indigo-600': !active,
                          })}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                      {!hideClockIcon && (
                        <span
                          className={classNames('absolute inset-y-0 right-0 flex items-center pr-3', {
                            'text-white': active,
                            'text-gray-500': !active,
                          })}
                        >
                          <ClockIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};
