import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ListCategory } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import Dropdown from 'components/common/Dropdown/Dropdown';
import React, { useMemo, useState } from 'react';
import { getCategoryList } from 'shared/api/category-list.api';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { QueryFetchTemplate } from 'templates/QueryFetchTemplate/QueryFetchTemplate';

/**
 * Props for the RemoveBundleModal component.
 */
interface RemoveBundleModalProps {
  onCancel: () => void; // Function to handle cancellation of the modal
  bundleId: string; // The ID of the bundle to be removed
  orderNumber: string; // The order number associated with the bundle
  isLoading?: boolean; // Indicates whether the component is in a loading state
  onConfirm: (reason: string) => void; // Function to handle confirmation of the removal, takes the reason as input
}

/**
 * A modal component for removing an order from a bundle.
 * This component allows users to select a reason for the removal and confirms the action.
 *
 * @param onCancel - Function to handle cancellation of the modal
 * @param bundleId - The ID of the bundle to be removed
 * @param orderNumber - The order number associated with the bundle
 * @param isLoading - Indicates whether the component is in a loading state
 * @param onConfirm - Function to handle confirmation of the removal, takes the reason as input
 * @returns JSX element representing the RemoveBundleModal component
 */
const RemoveBundleModal: React.FC<RemoveBundleModalProps> = ({
  onCancel,
  bundleId,
  orderNumber,
  isLoading,
  onConfirm,
}) => {
  const [onRemoveReason, onSelectedChange] = useState<string>('');
  const { loading, data, error } = useQueryFetcher(getCategoryList, {
    category: ListCategory.RemoveFromBundleReasons,
  });
  const reasons = useMemo(() => data?.map(item => ({ primaryLabel: item.name, value: item.name })), [data]);

  return (
    <DialogBox
      title={`Remove order from bundle ${bundleId}`}
      icon={<MinusCircleIcon className="h-5 w-5 text-black" />}
      cancelText="Cancel"
      onCancel={onCancel}
      confirmText="Done"
      onConfirm={() => onConfirm(onRemoveReason)}
      confirmButtonVariant="primary"
      iconBgColor="gray"
      width="w-full max-w-xl"
      titleClassName="mb-2"
      confirmButtonDisabled={isLoading}
    >
      <div className="text-sm text-gray-500 mb-6">Case #{orderNumber} will be removed from bundle.</div>
      <div className="flex-grow">
        <QueryFetchTemplate
          loading={loading}
          error={error}
          data={data}
          noDataContent={<div>Error loading reasons</div>}
        >
          {({ isLoading }) =>
            isLoading ? (
              <div>Loading...</div>
            ) : (
              <Dropdown
                data={reasons}
                label="Reason"
                selected={
                  onRemoveReason
                    ? { primaryLabel: onRemoveReason, value: onRemoveReason }
                    : { primaryLabel: '', value: '' }
                }
                setSelected={select => {
                  onSelectedChange(select.value);
                }}
                errorMessage={''}
                placeholder="Select"
                isRequired
              />
            )
          }
        </QueryFetchTemplate>
      </div>
    </DialogBox>
  );
};

export default RemoveBundleModal;
