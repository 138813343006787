import Modal from 'components/common/Modal';
import { ReactNode } from 'react';
import { cn } from 'shared/utils';
import Button, { ButtonProps } from '../Button/Button';

/**
 * Props for the DialogBox component.
 */
interface DialogBoxProps {
  /** The title of the dialog box. */
  title: string;
  /** Callback function invoked when the cancel button is clicked. */
  onCancel: () => void;
  /** Optional callback function invoked when the confirm button is clicked. */
  onConfirm?: () => void;
  /** Optional icon to display in the dialog box. */
  icon?: ReactNode;
  /** Custom content for the cancel button. */
  cancelButton?: ReactNode;
  /** The text to display on the cancel button. */
  cancelText?: string;
  /** Custom CSS class for the cancel button. */
  cancelButtonClassName?: string;
  /** Variant of the cancel button. */
  cancelButtonVariant?: ButtonProps['variant'];
  /** Custom content for the confirm button. */
  confirmButton?: ReactNode;
  /** The text to display on the confirm button. */
  confirmText?: string;
  /** Custom CSS class for the confirm button. */
  confirmButtonClassName?: string;
  /** Variant of the confirm button. */
  confirmButtonVariant?: ButtonProps['variant'];
  /** Indicates whether the confirm button is disabled. */
  confirmButtonDisabled?: boolean;
  /** The width of the dialog box. */
  width?: string;
  /** Indicates whether clicking outside the dialog box closes it. */
  outSideClickClose?: boolean;
  /** Indicates whether to show the close button in the dialog box. */
  showCloseButton?: boolean;
  /** Background color of the icon. */
  iconBgColor?: 'red' | 'gray';
  /** Custom CSS class for the title. */
  titleClassName?: string;
}

/**
 * Component for displaying a dialog box.
 *
 * @param title - The title of the dialog box.
 * @param onCancel - Callback function invoked when the cancel button is clicked.
 * @param onConfirm - Optional callback function invoked when the confirm button is clicked.
 * @param icon - Optional icon to display in the dialog box.
 * @param cancelButton - Custom content for the cancel button.
 * @param cancelText - The text to display on the cancel button.
 * @param cancelButtonClassName - Custom CSS class for the cancel button.
 * @param cancelButtonVariant - Variant of the cancel button.
 * @param confirmButton - Custom content for the confirm button.
 * @param confirmText - The text to display on the confirm button.
 * @param confirmButtonClassName - Custom CSS class for the confirm button.
 * @param confirmButtonVariant - Variant of the confirm button.
 * @param confirmButtonDisabled - Indicates whether the confirm button is disabled.
 * @param width - The width of the dialog box.
 * @param outSideClickClose - Indicates whether clicking outside the dialog box closes it.
 * @param showCloseButton - Indicates whether to show the close button in the dialog box.
 * @param iconBgColor - Background color of the icon.
 * @param titleClassName - Custom CSS class for the title.
 * @returns JSX.Element - The rendered component.
 */
export const DialogBox: React.FC<DialogBoxProps> = ({
  icon,
  title,
  width = 'w-1/4',
  onCancel,
  onConfirm,
  children,
  cancelText = 'Cancel',
  cancelButtonVariant = 'bordered',
  cancelButtonClassName,
  cancelButton,
  confirmText = 'Submit',
  confirmButtonVariant = 'danger',
  confirmButtonClassName,
  confirmButtonDisabled,
  confirmButton,
  outSideClickClose,
  showCloseButton,
  iconBgColor,
  titleClassName,
}) => {
  return (
    <Modal onClose={onCancel} width={width} outSideClickClose={outSideClickClose} showCloseButton={showCloseButton}>
      <div className="p-6">
        <div className="flex gap-4">
          {icon && (
            <div
              className={cn(
                {
                  [`bg-${iconBgColor}-100`]: iconBgColor,
                  'bg-red-100': !iconBgColor,
                },
                'rounded-full p-2 h-full'
              )}
            >
              {icon}
            </div>
          )}
          <div className="flex flex-col flex-grow">
            <p className={cn('text-md font-medium text-gray-900 mr-4 mb-4', titleClassName)}>{title}</p>
            <div>{children}</div>
          </div>
        </div>
      </div>
      <div className="flex justify-end mt-2 bg-gray-50 p-3 gap-3 rounded-b-lg">
        {cancelButton ? (
          cancelButton
        ) : (
          <div>
            <Button variant={cancelButtonVariant} onClick={onCancel} className={cn('text-sm', cancelButtonClassName)}>
              {cancelText}
            </Button>
          </div>
        )}
        {confirmButton ? (
          confirmButton
        ) : (
          <div>
            <Button
              variant={confirmButtonVariant}
              className={cn('text-sm', confirmButtonClassName)}
              onClick={onConfirm}
              disabled={confirmButtonDisabled}
            >
              {confirmText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  );
};
