import { CaseDiscountType } from 'API';
import { AlertMessage } from 'components/common/Alert/AlertMessage';
import Label from 'components/common/Label';
import { FC, useMemo } from 'react';
import { availableCredits, availableDiscounts } from 'shared/api/discount.api';
import { PART_OF_CASE_DISCOUNT_TYPE } from 'shared/constants/invoice-data.constants';
import { ToastNotificationType } from 'shared/enums';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { DiscountModalType } from '../../types';
import { DiscountCaseItems } from './DiscountCaseItems';
import { DiscountCaseType } from './DiscountCaseType';

/**
 * Component for selecting the discount type and items for discount.
 * @returns DiscountFor component.
 */
export const DiscountFor: FC = () => {
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const isEntireCase = selectedDiscountType === CaseDiscountType.EntireCase;
  const discountAppliedFor = useAddDiscountModalStore(state => state.specialDiscount.discountAppliedFor);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);

  const currentOrder = useAddDiscountModalStore(state => state.currentOrder);
  const orderNumber = currentOrder?.orderNumber || '';

  // if orderNumber is not available, then we don't need to make any api calls
  const skipBothApiCalls = !orderNumber;

  const { data: discountItems } = useQueryFetcher(availableDiscounts, {
    orderNumber,
    // if skipBothApiCalls is true or discountModalType is Credit, then we don't need to make available discounts api call
    skip: skipBothApiCalls || discountModalType === DiscountModalType.Credit,
  });

  const { data: creditItems } = useQueryFetcher(availableCredits, {
    orderNumber,
    // if skipBothApiCalls is true or discountModalType is SpecialDiscount, then we don't need to make available credits api call
    skip: skipBothApiCalls || discountModalType === DiscountModalType.SpecialDiscount,
  });

  const orderItemOptions = useMemo<OrderItemDropdownModel[]>(() => {
    const isSpecialDiscountModal = discountModalType === DiscountModalType.SpecialDiscount;
    const items = isSpecialDiscountModal ? discountItems : creditItems;

    if (!items) return [];

    /**
     * Filter items with no available amount unless the item is outbound shipping and we are looking at special discounts.
     * Map to dropdown model.
     */
    const options = items
      .filter(item => {
        return (
          item.availableAmount > 0 ||
          (isSpecialDiscountModal && item.productCode === PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING)
        );
      })
      .map(item => {
        const { description, orderItemId, productCode, availableAmount } = item;
        return {
          productCode,
          description,
          orderItemId,
          availableAmount,
          primaryLabel: description,
          value: description,
        };
      });

    return options;
  }, [creditItems, discountItems, discountModalType]);

  return (
    <div className="flex flex-col gap-1">
      <Label required={true}>Discount for</Label>
      {!discountAppliedFor && <DiscountCaseType />}
      {discountAppliedFor === CaseDiscountType.EntireCase && (
        <AlertMessage
          type={ToastNotificationType.Info}
          className="w-full"
          message={`${discountModalType} was already applied to the entire case. If you want to apply a ${discountModalType.toLowerCase()} to a specific item, please remove the existing ${discountModalType.toLowerCase()} and create a new "Part of Case" deduction.`}
        />
      )}
      {!isEntireCase && <DiscountCaseItems options={orderItemOptions} />}
    </div>
  );
};
