import { BundleSplitOrderInput, Order, OrderStatus, ReturnType } from 'API';
import Button from 'components/common/Button/Button';
import { ROUTES } from 'components/navigation/Constants';
import { ExchangeCaseCreationModal } from 'components/order-entry/OrderEntryContainer/ExchangeCaseCreatedModal';
import { TabHeaderDropdownMenu } from 'components/order-entry/TabHeaderDropdownMenu/TabHeaderDropdownMenu';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useToast } from 'providers/ToastProvider';
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createBundleSplitOrder } from 'shared/api/order.api';
import { NEW_CASE } from 'shared/constants/constants';
import { VALIDATE_BUNDLE_SPLIT_SUBMIT_TOPIC } from 'shared/constants/pub-sub.constants';
import { ToastNotificationType } from 'shared/enums';
import {
  checkOrderItemByReturnType,
  getBundleSuccessToastMessage,
  getCreateOrderAndRmaOrderInput,
} from 'shared/helpers/order-entry/order-entry.helper';
import { pubSub } from 'shared/helpers/pub-sub';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { LocalOrderType } from 'shared/models';
import { getShippingAddress } from 'shared/utils';
import { BundleSplitCaseStore, BundledSplitCase, useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { shallow } from 'zustand/shallow';
import { SingleTabOrder } from './SingleTabOrder/SingleTabOrder';

/**
 * Input props for {@link TabHeader}
 */
interface TabHeaderProps {
  /**
   * represents the products/services user enters
   */
  orders: BundleSplitCaseStore['orders'];

  /**
   * submit handler
   */
  onSubmit: () => Promise<void>;

  /**
   * cancellation handler
   */
  onCancel: () => void;

  /**
   * a set state handler to indicate order submission in progress,
   * to block navigation, indicate progress on UI etc.,
   * @param value - represents if order submission in progress or not
   */
  setIsOrderSubmitted: (value: boolean) => void;
}

/**
 * Renders a Header bar for Case Entry / Edit page
 *
 * @param orders - represents the products/services user enters
 * @param onSubmit - submit handler
 * @param onCancel - cancellation handler
 * @param setIsOrderSubmitted - a set state handler to indicate order submission in progress
 * to block navigation, indicate progress on UI etc.,
 */
export const TabHeader: FC<TabHeaderProps> = ({
  orders,
  onSubmit: singleCaseSubmit,
  onCancel,
  setIsOrderSubmitted,
}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { id: orderUpdateId } = useParams<{ id: string }>();
  const { search } = useLocation();
  const isOrderUpdate = !!orderUpdateId;
  const isPendingOrder = !!search;
  const alert = useContext(AlertModalContext);
  const [isProcessingExchange, setIsProcessingExchange] = useState(false);
  const [exchangePendingOrders, setExchangePendingOrders] = useState<Array<Order>>([]);
  const { fetcher: createBundleSplitOrderFetcher } = useLazyQueryFetcher(createBundleSplitOrder);

  /**
   * Returns the title for the order bundle page header.
   * This does not get used for bundles.
   */
  const title = useMemo(() => {
    const searchParams = new URLSearchParams(search);
    const orderNumber = searchParams.get('orderNumber') || orderUpdateId || '';
    /**
     * Since this title is only shown for single orders, we can safely reference orders index 0.
     * If this is an RMA order, we want to use the order number from the order itself, since it gets patched to the order after it is "linked".
     * If this is not an RMA order, we should use the order number from the search params, since it's immediately available when the page first loads.
     */
    const { orderNumber: firstOrderNumber, localMetadata } = orders[0] || {};
    const isFirstOrderAnRmaOrder = localMetadata?.localOrderType === LocalOrderType.RMA;
    const newOrderNumberPreference = isFirstOrderAnRmaOrder
      ? firstOrderNumber || orderNumber
      : orderNumber || firstOrderNumber;
    const newOrderTitle = newOrderNumberPreference || 'New Case';
    const editOrderTitle = 'Edit ' + orderNumber;
    return isOrderUpdate ? editOrderTitle : newOrderTitle;
  }, [isOrderUpdate, orderUpdateId, orders, search]);

  const { setOrders, setBundledSplitCases, isValidAllOrders, increaseSubmitAlertCount, checkIfBundleSplitIsActive } =
    useBundleSplitCaseStore(state => {
      return {
        setOrders: state.setOrders,
        setBundledSplitCases: state.setBundledSplitCases,
        isValidAllOrders: state.isValidAllOrders,
        checkIfBundleSplitIsActive: state.checkIfBundleSplitIsActive,
        increaseSubmitAlertCount: state.increaseSubmitAlertCount,
      };
    }, shallow);

  const showMultiTab = checkIfBundleSplitIsActive();

  const isBundleSplitCaseProcessing = useRef(false);

  /**
   * check all added cases in the bundle having same shipping address
   */
  const isAllSameAddress = useCallback(() => {
    const baseAddress = orders[0]?.shippingAddress;
    return orders.every(order => getShippingAddress(order?.shippingAddress) === getShippingAddress(baseAddress));
  }, [orders]);

  /**
   * Subscribe to validate bundle split submit topic, which is published from OrderModuleProvider component.
   * when user clicks on submit bundle button will trigger useBundleSplitCaseStore subscription, which will check whether all orders are valid or not.
   * If all orders are valid, submit bundle
   * If any order is invalid, do not submit bundle
   * If bundle is already submitted, do not submit again
   */
  useEffect(() => {
    isBundleSplitCaseProcessing.current = false;
    const unsubscribe = pubSub.subscribe(VALIDATE_BUNDLE_SPLIT_SUBMIT_TOPIC, async () => {
      const isAllOrderValid = isValidAllOrders();
      if (!isAllOrderValid || isBundleSplitCaseProcessing.current) {
        return; // if any order is invalid, do not submit bundle or if bundle is already processing, do not process again
      }

      if (!isAllSameAddress()) {
        return alert.show(
          'Address Mismatch',
          `Added cases cannot be bundled because the shipping address doesn't match.`
        );
      }

      try {
        setIsLoading(true);
        setIsOrderSubmitted(true); // It's meant for navigation blocker use case
        isBundleSplitCaseProcessing.current = true;

        const localOrders = orders.map(order => {
          return {
            ...order,
            orderNumber: order.orderNumber === NEW_CASE ? '' : order.orderNumber,
          };
        });

        const input: BundleSplitOrderInput = {
          orders: [],
          rmaOrders: [],
        };

        localOrders.forEach(({ id, isInvalid, ...order }) => {
          const data = getCreateOrderAndRmaOrderInput(order);
          if (data.rmaOrder) {
            input.rmaOrders.push(data.rmaOrder);
          } else if (data.order) {
            input.orders.push(data.order);
          }
        });
        const isExchangeReturn = localOrders.some(localOrder =>
          checkOrderItemByReturnType(localOrder.orderItems, ReturnType.Exchange)
        );
        // set isProcessingExchange to true if any order has exchange return type
        setIsProcessingExchange(isExchangeReturn);

        const result = await createBundleSplitOrderFetcher(input);
        const combinedOrders = [...result.rmaOrders, ...result.orders];

        if (!combinedOrders.length) {
          toast.notify('Failed to create multiple case bundles', ToastNotificationType.Error);
          return;
        }

        const bundledSplitCases: BundledSplitCase[] = combinedOrders.map(order => ({
          orderNumber: order.orderNumber,
          orderItems: order.orderItems.map(orderItem => ({
            ...orderItem,
            __typename: 'OrderItem',
          })),
        }));

        setBundledSplitCases(bundledSplitCases);

        // check whether there are any exchange pending orders
        const exchangePendingOrders = result.orders.filter(order => {
          const isPending = order.status === OrderStatus.Pending;
          // check whether the order is linked to RMA order
          const isLinkedRMAOrder = input.rmaOrders.some(rmaOrder => rmaOrder.orderNumber === order.linkedOrderNumber);
          // return only pending orders which are linked to RMA orders
          return isPending && isLinkedRMAOrder;
        });

        // if there are any exchange pending orders and isExchangeReturn is true, show exchange case creation modal
        if (isExchangeReturn && exchangePendingOrders.length) {
          setIsProcessingExchange(false);
          setExchangePendingOrders(exchangePendingOrders);
        } else {
          const successMessage = getBundleSuccessToastMessage(bundledSplitCases);
          toast.notify(successMessage, ToastNotificationType.Success);
          // set orders to empty array and navigate to order entry page if there are no exchange pending orders
          setOrders([]);
          navigate({
            pathname: ROUTES.ORDER_ENTRY,
          });
        }
      } catch (error) {
        console.error(error);
        setIsOrderSubmitted(false); // It's meant for navigation blocker use case
        setIsProcessingExchange(false); // reset isProcessingExchange to false if there is any error
        toast.notify('Failed to create multiple case bundles', ToastNotificationType.Error);
      } finally {
        setIsLoading(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [
    isValidAllOrders,
    navigate,
    orders,
    setBundledSplitCases,
    setOrders,
    toast,
    alert,
    isAllSameAddress,
    createBundleSplitOrderFetcher,
    setIsOrderSubmitted,
  ]);

  /**
   * increase submit alert count, so that we can invoke useBundleSplitCaseStore subscription to check whether all orders are valid or not
   */
  const multiCaseSubmit = () => {
    increaseSubmitAlertCount();
  };

  /**
   * function to handle submission of entered order
   */
  const onSubmit = async () => {
    if (showMultiTab) {
      multiCaseSubmit();
    } else {
      setIsLoading(true);
      await singleCaseSubmit();
      setIsLoading(false);
    }
  };

  /**
   * Returns the text for us to show in the order submit button.
   */
  const submitButtonText = useMemo(() => {
    if (showMultiTab) {
      return 'Submit Bundle';
    } else if (isOrderUpdate) {
      return 'Save Changes';
    } else {
      return 'Submit';
    }
  }, [showMultiTab, isOrderUpdate]);

  return (
    <div className="px-6 pt-2 bg-white border-b border-gray-200 over-flow flex gap-6 items-center flex-wrap w-full">
      {showMultiTab ? (
        orders.map((order, index) => {
          return (
            <SingleTabOrder
              key={order.id}
              index={index}
              orderNumber={order.orderNumber || ''}
              id={order.id}
              isInvalid={order.isInvalid}
            />
          );
        })
      ) : (
        <h2 data-testid="orderEntryBundleTabHeaderTitle" className="text-3xl font-bold text-gray-900">
          {title}
        </h2>
      )}
      <div className="flex flex-1 justify-end mb-2">
        <div className="flex gap-3 items-center">
          <TabHeaderDropdownMenu isExistingOrder={isOrderUpdate || isPendingOrder} />
          <Button variant="bordered" onClick={onCancel}>
            Cancel
          </Button>
          <Button
            data-testid="orderEntryBundleTabHeaderSubmitButton"
            type="button"
            variant="primary"
            onClick={onSubmit}
            className="whitespace-nowrap"
            disabled={isLoading}
          >
            {submitButtonText}
          </Button>
        </div>
      </div>
      <ExchangeCaseCreationModal
        isProcessingExchange={isProcessingExchange}
        exchangePendingOrders={exchangePendingOrders}
        setExchangePendingOrders={setExchangePendingOrders}
      />
    </div>
  );
};
