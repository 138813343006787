import {
  Address,
  AddressValidationInput,
  AddressValidationResponse,
  LegacyAddress,
  ValidateAddressQuery,
  ValidateAddressQueryVariables,
} from 'API';
import { validateAddress } from 'graphql/queries';
import Http from './http';

/**
 * verifies address given
 *
 * @param address - address to be verified
 *
 * @returns a response body indicating address is validated
 */
export const verifyAddress = async (address: Address | LegacyAddress): Promise<AddressValidationResponse> => {
  const input: AddressValidationInput = {
    street1: address.street1,
    street2: address.street2,
    city: address.city,
    state: address.state,
    zip: address.zipcode,
    country: address.country,
  };

  const data: ValidateAddressQueryVariables = {
    input,
  };

  const response = await Http.handleGraphqlOperation<ValidateAddressQuery>(validateAddress, data);
  return Http.processGraphqlResponse(response.validateAddress, 'AddressValidationResponse');
};
