import { CalendarIcon } from '@heroicons/react/24/outline';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Input from '../Input/Input';
import InputError from '../InputError';
import { SkeletonBorder } from '../Skeleton';

/**
 * Props for the DateInput component.
 */
interface DateInputProps {
  /** The ID of the input element. */
  id: string;
  /** The value of the input element. */
  value?: string;
  /** Callback function invoked when the value changes. */
  onValueChange: (value: string) => void;
  /** The placeholder text for the input element. */
  placeholder: string;
  /** Indicates whether to show a skeleton loader. */
  skeletonLoader?: boolean;
  /** Custom CSS class names. */
  customClass?: string;
  /** The format of the date (default: MM/DD/yyyy). */
  format?: string;
}

/**
 * Component for handling date input.
 *
 * @param id - The ID of the input element.
 * @param value - The value of the input element.
 * @param onValueChange - Callback function invoked when the value changes.
 * @param placeholder - The placeholder text for the input element.
 * @param skeletonLoader - Indicates whether to show a skeleton loader.
 * @param customClass - Custom CSS class names.
 * @param format - The format of the date (default: MM/DD/yyyy).
 * @returns JSX.Element - The rendered component.
 */
const DateInput: React.FC<DateInputProps> = ({
  id,
  value,
  onValueChange,
  placeholder = '',
  skeletonLoader,
  customClass,
  format,
}) => {
  const [customerDateError, setCustomerDateError] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isLastKeyBackspace, setIsLastKeyBackspace] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const FORMAT = format || 'MM/DD/yyyy';
  const FULL_DATE_LENGTH = FORMAT.length; // 10 represents MM/DD/YYYY (including the slashes)

  useEffect(() => {
    const currentRef = inputRef.current;

    // Set if key used was backspace or not.
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Backspace') {
        setIsLastKeyBackspace(true);
      } else {
        setIsLastKeyBackspace(false);
      }
    };

    if (currentRef) {
      inputRef.current?.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [onValueChange]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const dateString = event.target.value;
    let newDateString = event.target.value;
    const dateStringNoSlashes = dateString.replace(/\D/g, '');

    const monthNumbers = dateStringNoSlashes.substring(0, 2);
    const dayNumbers = dateStringNoSlashes.substring(2, 4);
    const defaultYearNumbers = new Date().getFullYear();

    if (!isLastKeyBackspace) {
      if (dateStringNoSlashes && dateStringNoSlashes.length > 1 && dateStringNoSlashes.length < 4) {
        // Add forward slash after month is entered.
        newDateString = `${monthNumbers}/${dateStringNoSlashes.substring(2, 3)}`;
      } else if (dateStringNoSlashes.length === 4) {
        // Business Requirement: auto set the year after month and day are entered.
        newDateString = `${monthNumbers}/${dayNumbers}/${defaultYearNumbers}`;
      } else if (dateStringNoSlashes.length > 4) {
        // Split the dateString by / to track for month/day modification
        const dateSplitBySlashes = dateString.split('/');

        const currentMonthNumbers = dateSplitBySlashes[0];
        const currentDayNumbers = dateSplitBySlashes[1];
        const currentYearNumbers = dateSplitBySlashes[2];

        const isMonthModified = currentMonthNumbers?.length < 2;
        const isDayModified = currentDayNumbers?.length < 2;

        // We will be using value from dateSplitBySlashes because
        // monthNumbers and dayNumber is not correct after modification
        if (isMonthModified || isDayModified) {
          newDateString = `${currentMonthNumbers}/${currentDayNumbers}/${currentYearNumbers}`;
        } else {
          // Add forward slash after day is entered.
          newDateString = `${monthNumbers}/${dayNumbers}/${dateStringNoSlashes.substring(
            4,
            dateStringNoSlashes.length
          )}`.substring(0, 10);
        }
      }
    }

    // Year will be default to the current year when user inputted Past Year. See comment from Maksud in LMS1-6376.
    if (newDateString.length === FULL_DATE_LENGTH && moment(new Date(newDateString)).year() < moment().year()) {
      newDateString = `${monthNumbers}/${dayNumbers}/${defaultYearNumbers}`;
    }

    /**
     * Check if months greater than 12
     * Check if day greater than 31
     * Check if date string is not full length
     * Check if date is a valid date
     */
    if (
      (dateStringNoSlashes && parseInt(monthNumbers) > 12) ||
      (dateStringNoSlashes && dateStringNoSlashes.length > 3 && parseInt(dayNumbers) > 31) ||
      (newDateString.length > 0 && newDateString.length < FULL_DATE_LENGTH) ||
      !moment(newDateString, FORMAT, true).isValid() ||
      newDateString.length !== FORMAT.length
    ) {
      setCustomerDateError(true);
    } else {
      setCustomerDateError(false);
    }

    onValueChange(newDateString);
  };

  const isInvalid = () => {
    if (value) {
      return !!customerDateError && isTouched && value.length > 0;
    }
  };

  return (
    <>
      {skeletonLoader ? (
        <SkeletonBorder />
      ) : (
        <>
          <div className="relative">
            <Input
              type="text"
              id={id}
              name={id}
              value={value || ''}
              onChange={handleChange}
              onBlur={() => setIsTouched(true)}
              placeholder={placeholder}
              isInvalid={isInvalid()}
              hideIcons={true}
              className={classNames(customClass)}
              ref={inputRef}
            />

            <div className="absolute right-2 top-2 flex">
              {/* Show a message if startDate is full length and the selected date is in the past */}
              {!customerDateError &&
                value &&
                value.length === FULL_DATE_LENGTH &&
                moment(new Date(value)).endOf('day').isBefore(moment()) && (
                  <div className="text-sm text-yellow-800 mr-2" data-testid="pastDateText">
                    Past Date
                  </div>
                )}
              {isInvalid() && <ExclamationCircleIcon className="mr-2 h-5 w-5 text-red-500" />}
              <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
          {isInvalid() && <InputError message="This is an invalid date" forceHide={!isTouched} testId="dateInput" />}
        </>
      )}
    </>
  );
};

export default DateInput;
