import moment from 'moment';

/**
 * returns a date object with local time zone
 *
 * @param date - date instance
 * @param timeZone - timezone of interest
 */
export const getLocaleTimeZoneDate = (date: Date | string, timeZone?: string) => {
  const localTimeZone = moment.tz.guess();
  return moment(new Date(date)).tz(timeZone || localTimeZone);
};

/**
 * returns a date object with local time zone, in specified date format
 * @param date - date instance
 * @param format - format to be used for date
 * @param timeZone - timezone of interest
 */
export const getLocaleTimeZoneDateWithFormat = ({
  date,
  format = 'MM/DD/YYYY',
  timeZone,
}: {
  date: Date | string;
  format?: string;
  timeZone?: string;
}) => {
  return getLocaleTimeZoneDate(date, timeZone).format(format);
};
