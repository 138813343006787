import { ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/24/outline';
import { useToast } from 'providers/ToastProvider';
import { FC, useState } from 'react';
import { ToastNotificationType } from 'shared/enums';
import ConfigService from 'shared/services/config.service';
import { downloadBlobFile } from 'shared/utils';
import Loader from '../Loader/Loader';

/**
 * Props for the InnerContent component.
 */
interface InnerContentProps {
  /**
   * Text to display.
   */
  text: string;
  /**
   * Icon component.
   */
  icon: React.ComponentType<{ className?: string }>;
}

/**
 * Inner content component displaying text and an icon.
 * @param text - Text to display.
 * @param icon - Icon component.
 * @returns JSX.Element representing the InnerContent component.
 */
const InnerContent: FC<InnerContentProps> = ({ text, icon: Icon }) => {
  return (
    <div className="flex justify-start items-center gap-1">
      <Icon className="cursor-pointer text-indigo-500 h-5 w-5" aria-hidden="true" />
      <div className="font-medium text-md">{text}</div>
    </div>
  );
};

/**
 * Props for the TableViewFile component.
 */
interface TableViewFileProps {
  /**
   * URL of the file to view/download.
   */
  fileUrl: string;
  /**
   * Name of the file.
   */
  fileName: string;
  /**
   * Label for the action button (default: 'View').
   */
  label?: string;
}

/**
 * Component to view/download a file.
 * @param fileUrl - URL of the file.
 * @param children - Child elements.
 * @param label - Label for the action button (default: 'View').
 * @param fileName - Name of the file.
 * @returns JSX.Element representing the TableViewFile component.
 */
export const TableViewFile: React.FC<TableViewFileProps> = ({ fileUrl, children, label = 'View', fileName }) => {
  const [isDownloading, setIsDownloading] = useState(false);
  const toast = useToast();
  const isDownloadable = ConfigService.fileConfigurations.downloadOnlyFileExtensions.some(type =>
    fileName.toLowerCase().endsWith(type)
  );
  const text = isDownloadable ? 'Download' : label;
  const icon = isDownloadable ? ArrowDownTrayIcon : EyeIcon;

  const onClickHandler = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isDownloadable) return; // Do not download the file if it is not a downloadable type

    e.preventDefault(); // Prevent the default behavior of the anchor tag

    try {
      setIsDownloading(true);
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      downloadBlobFile(blob, fileName); // Download the file

      toast.notify(`${fileName} downloaded successfully`, ToastNotificationType.Success);
    } catch (error) {
      console.error('Error downloading file:', error);
      toast.notify(`Error downloading file: ${fileName}`, ToastNotificationType.Error);
    } finally {
      setIsDownloading(false);
    }
  };

  if (isDownloading) {
    return (
      <div className="flex items-center gap-1">
        <Loader show spin className="w-4 h-4" />
        <div>Downloading file...</div>
      </div>
    );
  }

  return (
    <a
      href={fileUrl}
      onClick={onClickHandler}
      data-qa="viewFileButton"
      data-testid="viewFileButton"
      className="text-indigo-500 hover:text-indigo-900"
      target="_blank"
      rel="noreferrer"
    >
      {children || <InnerContent text={text} icon={icon} />}
    </a>
  );
};
