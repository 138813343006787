import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useEffect, useRef, useState } from 'react';

/**
 * Props for the AlertModal component.
 */
interface AlertModalProps {
  /**
   * The title of the alert modal.
   */
  title: string;
  /**
   * The message of the alert modal.
   */
  message: string;
  /**
   * Boolean indicating whether the modal should be shown.
   */
  showModal: boolean;
  /**
   * Function to toggle the visibility of the modal.
   */
  setShowModal: (show: boolean) => void;
}

/**
 * Component for displaying an alert modal.
 * @param title - The title of the alert modal.
 * @param message - The message of the alert modal.
 * @param showModal - Boolean indicating whether the modal should be shown.
 * @param setShowModal - Function to toggle the visibility of the modal.
 * @returns JSX.Element representing the AlertModal component.
 */
const AlertModal: React.FC<AlertModalProps> = ({ title, message, showModal, setShowModal }) => {
  const [open, setOpen] = useState(showModal);
  const okButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    okButtonRef.current?.focus();
  }, []);

  const closeModal = () => {
    setOpen(false);
    setShowModal(false);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => closeModal()}
                    data-qa="closeButton"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="text-center mb-5">
                  <div className="m-auto flex items-center justify-center md:h-12 md:w-12 rounded-full bg-red-100 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                </div>
                <div className="sm:flex justify-center">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                      data-qa="alertTitleLabel"
                      data-testid="alertModalHeading"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500" data-qa="alertBodyLabel">
                        {message.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:mt-6 sm:mt-4 sm:flex w-full items-center">
                  <button
                    type="button"
                    className="rounded-md border px-4 py-2 text-base font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:text-sm w-full"
                    onClick={() => closeModal()}
                    ref={okButtonRef}
                    data-qa="okButton"
                  >
                    Okay
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default AlertModal;
