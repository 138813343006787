import classNames from 'classnames';
import { FC } from 'react';
import { cn } from 'shared/utils';
import { SkeletonBox } from './SkeletonBox';

/**
 * Props for the SkeletonBorder component.
 */
interface SkeletonBorderProps {
  /**
   * Additional class name for the component.
   */
  className?: string;
  /**
   * Class name for the skeleton element.
   */
  skeletonClassName?: string;
}

/**
 * Skeleton border component.
 * @param className - Additional class name for the component.
 * @param skeletonClassName - Class name for the skeleton element.
 * @returns JSX.Element representing the SkeletonBorder component.
 */
export const SkeletonBorder: FC<SkeletonBorderProps> = ({ className, skeletonClassName }) => {
  return (
    <div className={cn('mt-1 flex-grow rounded-md border border-gray-300 p-1.5 h-6', className)}>
      <SkeletonBox className={classNames('h-full', skeletonClassName)} />
    </div>
  );
};
