import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import React, { FC } from 'react';
import { cn } from 'shared/utils';
import Label from '../Label';
import { SkeletonBorder } from '../Skeleton';

/**
 * Props for the InputLabel component.
 */
interface InputLabelProps {
  /** The label text. */
  label?: string;
  /** The HTML ID attribute. */
  id?: string;
  /** Indicates if the input is required. */
  isRequired?: boolean;
  /** The type of the input. */
  type: InputProps['type'];
}

/**
 * Component for displaying an input label.
 *
 * @param label - The label text.
 * @param id - The HTML ID attribute.
 * @param isRequired - Indicates if the input is required.
 * @param type - The type of the input.
 * @returns JSX.Element - The rendered component.
 */
const InputLabel: FC<InputLabelProps> = ({ label, id, isRequired, type }) => (
  <>
    {label && (
      <Label htmlFor={id || 'inputLabel'} required={isRequired} inline={type === 'checkbox'}>
        {label}
      </Label>
    )}
  </>
);

/**
 * Props for the Input component.
 */
interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
  /** Indicates if the input is invalid. */
  isInvalid?: boolean;
  /** The icon to display alongside the input. */
  icon?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;
  /** Indicates whether to show the icon. */
  showIcon?: boolean;
  /** Indicates whether to hide icons. */
  hideIcons?: boolean;
  /** Indicates whether to show a submission alert. */
  onSubmitAlert?: boolean;
  /** Indicates whether to show a skeleton loader. */
  skeletonLoader?: boolean;
  /** The label text. */
  label?: string;
  /** Indicates if the input is required. */
  isRequired?: boolean;
  /** Indicates whether the input should be full width. */
  isFullWidth?: boolean;
}

/**
 * Component for rendering an input element.
 *
 * @param props - The input component props.
 * @returns JSX.Element - The rendered component.
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      type = 'text',
      className,
      isInvalid,
      icon,
      showIcon,
      autoComplete,
      skeletonLoader,
      label,
      isRequired,
      hideIcons,
      isFullWidth,
      ...props
    },
    ref
  ) => {
    const inValidClassName = isInvalid ? 'focus:ring-red-500 focus:border-red-500 border-red-500 text-red-900' : '';
    const isCheckBox = type === 'checkbox';

    return (
      <div className={classNames({ 'w-full': isFullWidth })}>
        <>
          {!isCheckBox && <InputLabel label={label} id={id} isRequired={isRequired} type={type} />}
          {skeletonLoader ? (
            <SkeletonBorder className="h-9" />
          ) : (
            <>
              <div className="relative">
                <input
                  {...props}
                  ref={ref}
                  id={id}
                  autoComplete={autoComplete || 'off'}
                  type={type}
                  className={cn(
                    'rounded-md border-gray-300 disabled:bg-gray-100',
                    {
                      'mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600': isCheckBox,
                      [`text-sm w-full ${inValidClassName}`]: !isCheckBox,
                    },
                    className
                  )}
                  data-qa={`${id}Input`}
                  data-testid={`${id}Input`}
                />
                {isCheckBox && <InputLabel label={label} id={id} isRequired={isRequired} type={type} />}
                {!hideIcons && (isInvalid || (!!icon && !!showIcon)) && (
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                    {icon && showIcon ? icon : <ExclamationCircleIcon className="h-5 text-red-500" />}
                  </div>
                )}
              </div>
            </>
          )}
        </>
      </div>
    );
  }
);

export default Input;
