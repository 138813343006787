import { CaseDiscountType } from 'API';
import { DiscountModalType } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/types';
import { CreatedOrder } from 'shared/models';
import { StateCreator } from 'zustand';

const DEFAULT_DISCOUNT_KEY = '';

/**
 * Defines the types of the discount state.
 */
export interface DiscountState {
  orderItemId: string;
  productCode: string;
  percentageValue: number;
  dollarAmount: number;
  isPercentage: boolean;
  description: string;
  itemId: string;
  errors: {
    itemId: boolean;
    percentageValue: boolean;
    dollarAmount: boolean;
  };
}

/**
 * Defines the types of the special discount state.
 */
export interface SpecialDiscountState {
  selectedCaseId: string;
  reason: {
    value: string;
    error: boolean;
  };
  discounts: DiscountState[];
  note: string;
  selectedDiscountType: CaseDiscountType;
  discountAppliedFor: CaseDiscountType | '';
}

/**
 * Defines the types of the actions that can be performed on the special discount state.
 */
export interface SpecialDiscountAction {
  setSelectedDiscountType: (selectedDiscountType: SpecialDiscountState['selectedDiscountType']) => void;
  setDiscountAppliedFor: (discountAppliedFor: SpecialDiscountState['discountAppliedFor']) => void;
  setReason: (reason: Partial<SpecialDiscountState['reason']>) => void;
  setNote: (note: string) => void;
  setDiscounts: (discounts: DiscountState[]) => void;
  newDiscount: () => void;
  updateDiscount: (index: number, discount: Partial<DiscountState>, key: string) => void;
  deleteDiscount: (index: number, key: string) => void;
  resetDiscount: () => void;
  setSelectedCaseId: (selectedCaseId: string) => void;
  setDiscountErrors: (index: number, errors: Partial<DiscountState['errors']>) => void;
}

/**
 * Defines the combined types of the special discount state and its actions.
 */
export type SpecialDiscountStore = SpecialDiscountState & SpecialDiscountAction;

/**
 * Defines the types of the special discount slice.
 */
export interface SpecialDiscountSlice {
  discountModalType: DiscountModalType;
  setDiscountModalType: (discountModalType: DiscountModalType) => void;
  specialDiscount: SpecialDiscountStore;
  resetSpecialDiscount: () => void;
  currentOrder: CreatedOrder | null | undefined;
  setCurrentOrder: (order: SpecialDiscountSlice['currentOrder']) => void;
  maxDiscountValue: Record<
    string,
    {
      maxPercentage: number;
      maxDollar: number;
    }
  >;
  getMaxDiscountValue: (
    key: string,
    discounts?: DiscountState[]
  ) => Record<string, { maxPercentage: number; maxDollar: number }>;
  getMaxTotalDollarAmount: (key: string, totalAmount?: number) => number;
}

/**
 * Creates the default discount data.
 * @returns The default discount data.
 */
export const getDefaultDiscountData = (): DiscountState => ({
  orderItemId: '',
  percentageValue: 0,
  dollarAmount: 0,
  isPercentage: true,
  productCode: '',
  description: '',
  itemId: '',
  errors: {
    itemId: false,
    percentageValue: false,
    dollarAmount: false,
  },
});

const DEFAULT_SPECIAL_DISCOUNT_STATE: SpecialDiscountState = {
  selectedCaseId: '',
  reason: {
    value: '',
    error: false,
  },
  discounts: [],
  note: '',
  selectedDiscountType: CaseDiscountType.EntireCase,
  discountAppliedFor: '',
};

/**
 * Creates the special discount slice using Zustand's StateCreator.
 */
export const createSpecialDiscountSlice: StateCreator<SpecialDiscountSlice> = (set, get) => ({
  discountModalType: DiscountModalType.SpecialDiscount,
  setDiscountModalType(discountModalType) {
    return set({
      discountModalType,
    });
  },
  specialDiscount: {
    ...DEFAULT_SPECIAL_DISCOUNT_STATE,
    setDiscountAppliedFor(discountAppliedFor) {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discountAppliedFor,
        },
      }));
    },
    setReason: reason => {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          reason: {
            ...state.specialDiscount.reason,
            ...reason,
          },
        },
      }));
    },
    setNote: note => {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          note,
        },
      }));
    },
    setSelectedDiscountType: selectedDiscountType => {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          selectedDiscountType,
        },
      }));
    },
    setDiscounts: discounts => {
      const maxDiscountValue = get().getMaxDiscountValue(DEFAULT_DISCOUNT_KEY, discounts);
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts,
        },
        maxDiscountValue: {
          ...state.maxDiscountValue,
          ...maxDiscountValue,
        },
      }));
    },
    newDiscount: () => {
      const discount = getDefaultDiscountData();
      const { specialDiscount, getMaxDiscountValue } = get();
      const discounts = [...specialDiscount.discounts, discount];
      const maxDiscountValue = getMaxDiscountValue(DEFAULT_DISCOUNT_KEY, discounts);
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts,
        },
        maxDiscountValue: {
          ...state.maxDiscountValue,
          ...maxDiscountValue,
        },
      }));
    },
    updateDiscount: (index, discount, key) => {
      const { specialDiscount, getMaxDiscountValue } = get();
      const discounts = specialDiscount.discounts.map((d, i) => {
        if (i !== index) return d;
        return { ...d, ...discount };
      });
      const maxDiscountValue = getMaxDiscountValue(key, discounts);
      if (maxDiscountValue[key].maxPercentage > 100) return;
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts,
        },
        maxDiscountValue: {
          ...state.maxDiscountValue,
          ...maxDiscountValue,
        },
      }));
    },
    deleteDiscount: (index, key) => {
      const { specialDiscount, getMaxDiscountValue } = get();
      const discounts = specialDiscount.discounts.filter((_, i) => i !== index);
      const maxDiscountValue = getMaxDiscountValue(key, discounts);

      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts,
        },
        maxDiscountValue: {
          ...state.maxDiscountValue,
          ...maxDiscountValue,
        },
      }));
    },
    resetDiscount: () => {
      const discounts = [getDefaultDiscountData()];
      const maxDiscountValue = get().getMaxDiscountValue(DEFAULT_DISCOUNT_KEY, discounts);

      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts,
        },
        maxDiscountValue: {
          ...state.maxDiscountValue,
          ...maxDiscountValue,
        },
      }));
    },

    setSelectedCaseId: selectedCaseId => {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          selectedCaseId,
        },
      }));
    },
    setDiscountErrors: (index, errors) => {
      return set(state => ({
        specialDiscount: {
          ...state.specialDiscount,
          discounts: state.specialDiscount.discounts.map((d, i) => {
            if (i !== index) return d;
            return { ...d, errors: { ...d.errors, ...errors } };
          }),
        },
      }));
    },
  },
  currentOrder: null,
  setCurrentOrder(order) {
    return set({
      currentOrder: order,
    });
  },
  maxDiscountValue: {
    [DEFAULT_DISCOUNT_KEY]: {
      maxPercentage: 0,
      maxDollar: 0,
    },
  },
  getMaxDiscountValue: (key, discounts) => {
    const { discounts: stateDiscounts } = get().specialDiscount;
    const localDiscounts = discounts || stateDiscounts;
    const filteredDiscounts = localDiscounts.filter(discount => discount.itemId === key);
    const percentage = filteredDiscounts.reduce((acc, curr) => acc + curr.percentageValue, 0);
    const dollar = filteredDiscounts.reduce((acc, curr) => acc + curr.dollarAmount, 0);
    return { [key]: { maxPercentage: +percentage.toFixed(2), maxDollar: +dollar.toFixed(2) } };
  },
  getMaxTotalDollarAmount: (key, totalAmount) => {
    const { getMaxDiscountValue } = get();
    const maxDiscountValue = getMaxDiscountValue(key);
    const { maxDollar, maxPercentage } = maxDiscountValue[key];
    if (!totalAmount) return 0;
    const balanceTotalAmountForPercentage = totalAmount * (maxPercentage / 100);
    const balanceTotalAmount = totalAmount - balanceTotalAmountForPercentage - maxDollar;

    return balanceTotalAmount > 0 ? +balanceTotalAmount.toFixed(2) : 0;
  },
  resetSpecialDiscount: () => {
    return set(state => ({
      specialDiscount: {
        ...state.specialDiscount,
        ...DEFAULT_SPECIAL_DISCOUNT_STATE,
      },
    }));
  },
});
