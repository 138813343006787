import React from 'react';
import { LocalOrderEnclosedItem } from 'shared/models';
import { cn, isPositiveNumber, nullFunction } from 'shared/utils';
import RemoveButton from '../RemoveButton';
import SearchableDropdown from '../SearchableDropdown';

/**
 * Props for the EnclosedItemEntry component.
 */
interface EnclosedItemEntryProps {
  /**
   * The currently selected enclosed item.
   */
  selectedItem: LocalOrderEnclosedItem;

  /**
   * The options for selecting an enclosed item.
   */
  selectedItemOptions: string[];

  /**
   * The error message for an invalid item code.
   */
  invalidCodeText?: string;

  /**
   * Function to handle removing the item.
   */
  onRemoveItem: () => void;

  /**
   * Function to handle changing the item details.
   */
  onChangeItem: (key: keyof Pick<LocalOrderEnclosedItem, 'itemCode' | 'quantity'>, value: string) => void;
}

/**
 * Component for displaying and editing an enclosed item entry.
 * @param selectedItem - The currently selected enclosed item.
 * @param selectedItemOptions - The options for selecting an enclosed item.
 * @param invalidCodeText - The error message for an invalid item code.
 * @param onRemoveItem - Function to handle removing the item.
 * @param onChangeItem - Function to handle changing the item details.
 * @returns JSX element representing the enclosed item entry.
 */
const EnclosedItemEntry: React.FC<EnclosedItemEntryProps> = ({
  selectedItem,
  selectedItemOptions,
  invalidCodeText,
  onRemoveItem,
  onChangeItem,
}) => {
  const onChangeItemHandle = (key: keyof Pick<LocalOrderEnclosedItem, 'itemCode' | 'quantity'>, value: string) => {
    onChangeItem(key, value);
  };

  const itemCodeErrorMessage = selectedItem.errors?.itemCode;
  const quantityErrorMessage = selectedItem.errors?.quantity;
  return (
    <div className="flex mb-2 items-start">
      <div className="flex-grow">
        <SearchableDropdown
          options={selectedItemOptions}
          selected={selectedItem.itemCode || ''}
          onSelectedChange={selected => onChangeItemHandle('itemCode', selected)}
          dataQa="enclosedItemSelect"
          enableAutoFocus={!itemCodeErrorMessage && !selectedItem.itemCode}
          placeholder="Search"
          errorMessage={itemCodeErrorMessage}
          showErrorBorderOnly={true}
          isRequired
          forceErrorState={!!invalidCodeText && selectedItem.itemCode.toLowerCase().includes(invalidCodeText)}
        />
      </div>
      <div className={cn('pl-3 flex-none w-32 relative')}>
        {selectedItem?.quantity ? (
          <div className=" absolute left-5 top-1/2 transform -translate-y-1/2 sm:max-w-xs sm:text-sm ">Qty:</div>
        ) : (
          nullFunction()
        )}
        <input
          type="number"
          value={selectedItem.quantity || ''}
          onChange={e => {
            const value = e.target.value;
            if (value && !isPositiveNumber(value)) return;
            onChangeItemHandle('quantity', value);
          }}
          min={1}
          placeholder="Select Qty"
          className={cn(
            'max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md',
            {
              'focus:ring-red-500 focus:border-red-500 border-red-500 text-red-900':
                quantityErrorMessage && !selectedItem.quantity,
              'pl-10': selectedItem.quantity,
            }
          )}
          data-qa="quantity"
          data-testid="enclosedQuantityInput"
        />
      </div>
      <RemoveButton id="removeEnclosedItemButton" onRemove={onRemoveItem} className="m-0 mt-3" />
    </div>
  );
};

export default EnclosedItemEntry;
