import { TrashIcon } from '@heroicons/react/24/solid';
import { OrderStatus } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { updateOrderStatus } from 'shared/api/order.api';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { AnalyticsService } from 'shared/services/analytics.service';

interface DeleteCaseModalProps {
  showModal: boolean;
  onCloseModal: () => void;
}

export const DeleteCaseModal: FC<DeleteCaseModalProps> = ({ showModal, onCloseModal }) => {
  const { id: orderNumber } = useParams<{ id: string }>();
  const { fetcher } = useLazyQueryFetcher(updateOrderStatus);
  const toast = useToast();
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const { setCaseDetails, caseDetails } = useCaseDetail();

  const handleCaseDelete = async () => {
    if (!orderNumber) return toast.notify('Invalid order number', ToastNotificationType.Error);
    try {
      await fetcher({ orderNumber: orderNumber, orderStatus: OrderStatus.Deleted, updatedBy: currentUserName });

      AnalyticsService.track(AnalyticsEventName.CaseDeleted, {
        caseNumber: orderNumber,
        caseStatus: OrderStatus.Deleted,
      });

      if (caseDetails) {
        setCaseDetails({
          ...caseDetails,
          status: OrderStatus.Deleted,
          updatedBy: currentUserName,
          updatedDate: new Date().toISOString(),
        });
      }
      onCloseModal();
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message, ToastNotificationType.Error);
    }
  };

  return (
    <>
      {showModal && (
        <DialogBox
          title="Delete this Case"
          icon={<TrashIcon className="h-5 w-5 text-red-600" />}
          cancelText="Go Back"
          onCancel={onCloseModal}
          confirmText="Delete Case"
          onConfirm={handleCaseDelete}
        >
          <div>
            <p className="text-sm text-gray-500">
              This case is no longer required. This case can be restored at a later time if it is deleted by mistake.
            </p>
            <p className="text-sm text-gray-800 mt-5">
              If the case was requested to be canceled by the customer, please go back and use CANCEL CASE instead.
            </p>
          </div>
        </DialogBox>
      )}
    </>
  );
};
