import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { isNaN } from 'lodash';
import { FC } from 'react';
import { LocalCaseHistoryItem } from 'shared/constants/case-detail.constants';
import { UTC_DATE_FORMAT_REGEX } from 'shared/constants/constants';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { getAdditionalDetailFallbackRecord } from 'shared/helpers/order-detail/order-detail.helper';
import { getDateInShortFormat, isDate, isObject } from 'shared/utils';
import { AdditionalDetailOldAndNewValueItem } from 'types/case-details';

/**
 * Component for displaying additional details with old and new values.
 * @param name - The name of the field.
 * @param oldValue - The old value of the field.
 * @param newValue - The new value of the field.
 * @param utcConversionTimeZoneCode - The timezone code for UTC conversion.
 * @returns A JSX element representing the AdditionalDetailOldAndNewValue component.
 */
export const AdditionalDetailOldAndNewValue: FC<AdditionalDetailOldAndNewValueItem> = ({
  name,
  oldValue,
  newValue,
  utcConversionTimeZoneCode,
}) => {
  let oldValueDisplay = oldValue ? oldValue.toString() : ''; // default to an empty string
  let newValueDisplay = newValue ? newValue.toString() : ''; // default to an empty string

  // if oldValueDisplay is a utc date string, format it
  if (UTC_DATE_FORMAT_REGEX.test(oldValueDisplay) && isDate(oldValueDisplay)) {
    oldValueDisplay = getDateInShortFormat(oldValueDisplay, utcConversionTimeZoneCode);
  }

  // if newValueDisplay is a utc date string, format it
  if (UTC_DATE_FORMAT_REGEX.test(newValueDisplay) && isDate(newValueDisplay)) {
    newValueDisplay = getDateInShortFormat(newValueDisplay, utcConversionTimeZoneCode);
  }

  // If both oldValue and newValue is object, then we need to render the fallback component
  // this logic will make sure that will show all the information to the user even if the nested object values
  if (isObject(oldValue) || isObject(newValue)) {
    const oldData = isObject(oldValue) ? oldValue : null;
    const newData = isObject(newValue) ? newValue : null;
    const value = Number(name); // if name is a number, then it's not a key, it's a index of an array
    return (
      <div className="flex flex-col gap-1 mb-2">
        {isNaN(value) && <div className="font-bold">{name}:</div>}
        <div className="ml-2 ">
          <AdditionalDetailsFallback
            oldData={oldData}
            newData={newData}
            utcConversionTimeZoneCode={utcConversionTimeZoneCode}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="font-semibold">{name}:</div>
      <div className="flex flex-1 justify-between items-start">
        {oldValueDisplay && <div className="flex-1">{oldValueDisplay}</div>}
        {oldValueDisplay && newValueDisplay && (
          <div className="mx-6">
            <ArrowRightIcon className="w-5 h-5" />
          </div>
        )}
        {newValueDisplay && <div className="flex-1">{newValueDisplay}</div>}
      </div>
    </div>
  );
};

/**
 * Props of the AdditionalDetailsFallback component.
 */
export interface AdditionalDetailsFallbackProps<TData extends Record<string, unknown>> {
  /**
   * The type of case history event.
   */
  eventType?: CaseHistoryEventType;
  /**
   * The new additional details.
   */
  newData: TData | null | undefined;
  /**
   * The old additional details.
   */
  oldData: TData | null | undefined;
  /**
   * The keys to exclude from displaying.
   */
  excludeKeys?: string[];
  /**
   * The timezone code for UTC conversion.
   */
  utcConversionTimeZoneCode: LocalCaseHistoryItem['utcConversionTimeZoneCode'];
}

/**
 * Component for displaying additional details when fallback is required.
 * @param eventType - The type of case history event.
 * @param newData - The new additional details.
 * @param oldData - The old additional details.
 * @param excludeKeys - The keys to exclude from displaying.
 * @param utcConversionTimeZoneCode - The timezone code for UTC conversion.
 * @returns A JSX element representing the AdditionalDetailsFallback component.
 */
export const AdditionalDetailsFallback = <TData extends Record<string, unknown>>({
  newData,
  oldData,
  excludeKeys,
  utcConversionTimeZoneCode,
}: AdditionalDetailsFallbackProps<TData>) => {
  const additionalDetailFallbackRecord = getAdditionalDetailFallbackRecord(
    oldData,
    newData,
    excludeKeys,
    utcConversionTimeZoneCode
  );
  return (
    <div className="flex flex-col gap-2">
      {Object.values(additionalDetailFallbackRecord).map(item => (
        <AdditionalDetailOldAndNewValue key={item.name} {...item} />
      ))}
    </div>
  );
};
