import { CaseAlert, ForwardCaseAlertDetail, UpdateCaseAlertDetail } from 'API';
import { Fragment } from 'react';
import { isValidCaseAlertData } from 'shared/helpers/order-detail/case-alert-validator.helper';
import { CancelCaseAlert } from './cancel-case.constants';
import { CaseAlertDetailItemHOC } from './detail-item-hoc.constants';
import { UpdateCaseAlert } from './update-case.constants';

export interface CaseAlertDetailsI {
  caseAlert: CaseAlert;
}

export const CaseAlertDetails = ({ caseAlert }: CaseAlertDetailsI) => {
  const { cancelCase, updateCase, forwardCase, other } = caseAlert;
  const caseAlertId = caseAlert.alertId;

  const checkUpdateIsValid = (alert: UpdateCaseAlertDetail) => {
    return (
      isValidCaseAlertData(alert.product?.new || '') ||
      isValidCaseAlertData(alert.shade?.new || '') ||
      isValidCaseAlertData(alert.toothNumber?.new || '')
    );
  };

  const forwardCaseDetails = (data: ForwardCaseAlertDetail) => {
    const detailsText = `Send Case to Plastering: ${data.sendToPlastering ? 'Yes' : 'No'}`;
    return (
      <>
        {detailsText}
        {data.routeTo && <br />}
        {data.routeTo}
      </>
    );
  };

  return (
    <Fragment key={caseAlertId}>
      {updateCase &&
        updateCase.map(
          uCase =>
            checkUpdateIsValid(uCase) && (
              <UpdateCaseAlert key={'update' + caseAlertId} updateCase={uCase} className="mb-2 pb-1" />
            )
        )}
      {cancelCase &&
        cancelCase.map(
          cCase =>
            isValidCaseAlertData(cCase.reason) && (
              <CancelCaseAlert key={'update' + caseAlertId} cancelCase={cCase} className="mb-3 pb-1" />
            )
        )}
      {forwardCase &&
        forwardCase.map(fCase => (
          <CaseAlertDetailItemHOC key={'forward' + caseAlertId} title="Forward Case" className="mb-3 pb-1">
            {fCase.reason && isValidCaseAlertData(fCase.reason) && (
              <CaseAlertDetailItemHOC title="">{fCase.reason}</CaseAlertDetailItemHOC>
            )}
            {forwardCaseDetails(fCase)}
          </CaseAlertDetailItemHOC>
        ))}
      {other &&
        other.map(
          oCase =>
            oCase?.notes &&
            isValidCaseAlertData(oCase.notes) && (
              <CaseAlertDetailItemHOC
                key={'other' + caseAlertId}
                title="Additional Notes/Other Alerts:"
                className="mb-2"
              >
                {oCase.notes}
              </CaseAlertDetailItemHOC>
            )
        )}
    </Fragment>
  );
};
