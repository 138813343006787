import { ROUTES } from 'components/navigation/Constants';
import { useAuth } from 'providers/AuthProvider';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Renders a page for sign out process
 */
export const SignOutPage: React.FC = () => {
  const { reset } = useAuth();
  const navigate = useNavigate();

  /**
   * resets the login details of user and navigates to sign in page
   */
  useEffect(() => {
    reset();
    navigate(ROUTES.SIGN_IN_PATH, { replace: true });
  }, [reset, navigate]);

  return null;
};
