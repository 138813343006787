import { AttributeRule, ProductFullAttribute } from 'API';
import { useMemo } from 'react';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';
import { AttributeAggregateDynamicItem } from '../AggregateDynamicItem';

interface AggregateAttributeRuleProps {
  /**
   * The name of the attribute that is used to filter the attributes
   */
  attributeNameValue: string;
  /**
   * The attribute rule object that contains the display attributes
   */
  attributeRule: AttributeRule;
  /**
   * The selected tooth of the user
   */
  selectedTooth: string;
  /**
   * The product code of the product
   */
  productCode: string;
  /**
   * The order item id of the order item
   */
  orderItemId: string;
}

/**
 *  This component is responsible for rendering the rules of the attribute
 */
export const AggregateAttributeRule: React.FC<AggregateAttributeRuleProps> = ({
  attributeNameValue,
  attributeRule,
  selectedTooth,
  productCode,
  orderItemId,
}) => {
  const attributeValue = attributeRule.attributeValue;
  const findProductFullAttributeByOption = useFetchCacheStore(state => state.findProductFullAttributeByOption);

  const mappedAttributes = useMemo(() => {
    const displayAttributes = attributeRule.displayAttributes || [];

    const attributes = displayAttributes.map(displayAttribute => {
      const { name } = displayAttribute;
      return findProductFullAttributeByOption(productCode, { name });
    });

    return attributes;
  }, [attributeRule.displayAttributes, findProductFullAttributeByOption, productCode]);

  /**
   * Filter the attributes based on the rule attribute value
   */
  const filteredDisplayAttributes = useMemo(() => {
    const attributes = mappedAttributes.filter((attribute): attribute is ProductFullAttribute => {
      // if the attribute is undefined, don't display it
      if (!attribute) return false;

      // if the rule attribute value is empty, display all attributes
      if (!attributeValue) return true;

      // if the attribute name value is equal to the rule attribute value, display the attribute otherwise don't display it
      return attributeNameValue === attributeValue;
    });

    return attributes;
  }, [attributeNameValue, attributeValue, mappedAttributes]);

  return (
    <>
      {filteredDisplayAttributes.map(displayAttribute => (
        <AttributeAggregateDynamicItem
          key={displayAttribute.name}
          {...displayAttribute}
          selectedTooth={selectedTooth}
          localTypeValue={displayAttribute.name}
          orderItemId={orderItemId}
        />
      ))}
    </>
  );
};
