import { cn } from 'shared/utils';

/**
 * Props for the TBodyCell component.
 */
interface TBodyCellProps {
  /**
   * Unique identifier for the table body cell.
   */
  id: string;
  /**
   * Additional classes for styling.
   */
  className?: string;
}

/**
 * Component representing a cell in the table body.
 * @param id - Unique identifier for the table body cell.
 * @param className - Additional classes for styling.
 * @returns JSX.Element representing the TBodyCell component.
 */
export const TBodyCell: React.FC<TBodyCellProps> = ({ children, className, id }) => {
  return (
    <td id={id} className={cn('px-6 py-4 text-sm text-gray-700', className)}>
      {children}
    </td>
  );
};
