import {
  AvailableCreditsInput,
  AvailableCreditsQuery,
  AvailableDiscountsInput,
  AvailableDiscountsQuery,
  Coupon,
  CouponQuery,
  CouponQueryVariables,
  CreateCreditInput,
  CreateCreditMutation,
  CreateDeductionInput,
  CreateDeductionMutation,
  DeleteCreditMutation,
  DeleteCreditMutationVariables,
  DeleteDeductionMutation,
  DeleteDeductionMutationVariables,
  UpdateDeductionMutation,
  UpdateDeductionMutationVariables,
} from 'API';
import { createCredit, createDeduction, deleteCredit, deleteDeductions, updateDeductions } from 'graphql/mutations';
import { availableCreditsQuery, availableDiscountsQuery, couponQuery } from 'graphql/queries';
import Http from './http';

/**
 * fetches a coupon details
 *
 * @param variables - coupon code
 *
 * @returns response body containing coupon details
 */
export const couponLookup = async (variables: CouponQueryVariables): Promise<Coupon> => {
  const response = await Http.handleGraphqlOperation<CouponQuery>(couponQuery, variables);
  return Http.processGraphqlResponse(response.coupon, 'Coupon');
};

/**
 * creates a discount for given order number
 *
 * @param input - orderNumber, discount type, reason
 *
 * @returns response body representing deduction
 */
export const createDiscount = async (input: CreateDeductionInput) => {
  const response = await Http.handleGraphqlOperation<CreateDeductionMutation>(createDeduction, { input });
  return Http.processGraphqlResponse(response.createDeduction, 'Deduction');
};

/**
 * cancels a discount applied to an order
 *
 * @param variables - discount id
 *
 * @returns represents response body containing api response
 */
export const removeDiscount = async (variables: DeleteDeductionMutationVariables) => {
  const response = await Http.handleGraphqlOperation<DeleteDeductionMutation>(deleteDeductions, variables);
  return Http.processGraphqlResponse(response.deleteDeduction, 'DeleteDeductionResponse');
};

/**
 * updates a given discount using discount id
 *
 * @param variables - discountId
 *
 * @returns response body containing deduction update
 */
export const updateDiscount = async (variables: UpdateDeductionMutationVariables) => {
  const response = await Http.handleGraphqlOperation<UpdateDeductionMutation>(updateDeductions, variables);
  return Http.processGraphqlResponse(response.updateDeduction, 'Deduction');
};

/**
 * creates a credit for given order number
 *
 * @param input - request body containing orderNumber, creditId, creditItems, totalCreditItems
 *
 * @returns a response object representing
 */
export const createCredits = async (input: CreateCreditInput) => {
  const response = await Http.handleGraphqlOperation<CreateCreditMutation>(createCredit, { input });
  return Http.processGraphqlResponse(response.createCredit, 'Credit');
};

/**
 * deletes a credit using `creditNumber`
 *
 * @param variables - `creditNumber` and `updatedBy`
 *
 * @returns a result message form API
 */
export const deleteCredits = async (variables: DeleteCreditMutationVariables) => {
  const response = await Http.handleGraphqlOperation<DeleteCreditMutation>(deleteCredit, variables);
  return Http.processGraphqlResponse(response.deleteCredit, 'DeleteCreditResponse');
};

/**
 * fetches a list of available discounts for an order
 *
 * @param input - `orderNumber` of a given order
 *
 * @returns list of discounts available
 */
export const availableDiscounts = async (input: AvailableDiscountsInput) => {
  const response = await Http.handleGraphqlOperation<AvailableDiscountsQuery>(availableDiscountsQuery, { input });
  const result = Http.processGraphqlResponse(response.availableDiscounts, 'AvailableDiscountsData');
  return result?.deductions || [];
};

/**
 * fetches a list of available credits for an order
 *
 * @param input - `orderNumber`
 *
 * @returns list of available credits
 */
export const availableCredits = async (input: AvailableCreditsInput) => {
  const response = await Http.handleGraphqlOperation<AvailableCreditsQuery>(availableCreditsQuery, { input });
  const result = Http.processGraphqlResponse(response.availableCredits, 'AvailableCreditsData');
  return result?.creditItems || [];
};
