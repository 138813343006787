import { OrderEnclosedItem } from 'API';
import { FC, useMemo } from 'react';

/**
 * Props interface for the InvoicedEnclosedItems component.
 */
interface InvoicedEnclosedItemsProps {
  /**
   * The list of enclosed items.
   */
  enclosedItems: OrderEnclosedItem[];
}

/**
 * Component for rendering enclosed items for invoiced cases.
 * @param enclosedItems - The list of enclosed items.
 * @returns JSX element representing the component.
 */
export const InvoicedEnclosedItems: FC<InvoicedEnclosedItemsProps> = ({ enclosedItems }) => {
  const items = useMemo(() => {
    return enclosedItems.map(item => {
      return {
        label: item.itemCode,
        value: item.quantity.toString(),
      };
    });
  }, [enclosedItems]);

  // If there are no items, return null to render nothing
  if (!items.length) return null;

  return (
    <div className="flex flex-col mt-4 w-80">
      <label
        className="block mb-2 text-sm font-medium text-gray-700"
        htmlFor="enclosedItems"
        data-qa="enclosedItemsLabel"
        data-testid="enclosedItemsLabel"
      >
        Enclosed Items
      </label>
      <div className="sm:rounded-lg border w-full">
        <div className="flex border-b px-4 py-2 font-medium text-sm text-gray-500 bg-gray-50 sm:rounded-t-lg">
          <div className="flex-grow py-0.5">Item Code</div>
          <div className="flex-none w-12 py-0.5 text-center">Qty</div>
        </div>
        <div className="flex flex-col px-4 py-2 gap-2 text-sm">
          {items.map(item => (
            <div className="flex gap-2" key={item.label}>
              <div className="flex-grow">{item.label}</div>
              <div className="flex-none w-12 text-center">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
