import { useState } from 'react';
import ToothChart from '../ToothChart/ToothChart';

/**
 * Props for the ToothChartRange component.
 */
export interface ToothChartSingleProps {
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * Callback function invoked when the first tooth is selected.
   */
  firstToothHandler: (tooth: string) => void;
  /**
   * Callback function invoked when teeth are selected.
   */
  onSelect: (first: string, last: string) => void;
  /**
   * Array of blocked tooth numbers.
   */
  blocked: number[];
  /**
   * Array of initially selected tooth numbers.
   */
  selected: string[];
}

/**
 * The ToothChartRange component displays a range selection of teeth.
 * @param itemId - The ID of the item.
 * @param firstToothHandler - Callback function invoked when the first tooth is selected.
 * @param onSelect - Callback function invoked when teeth are selected.
 * @param blocked - Array of blocked tooth numbers.
 * @param selected - Array of initially selected tooth numbers.
 * @returns JSX element representing the ToothChartRange component.
 */
const ToothChartRange: React.FC<ToothChartSingleProps> = ({ onSelect, blocked, firstToothHandler, selected }) => {
  const [selectedTooth, setSelectedTooth] = useState<string[]>(selected);

  const toothSelectHandler = (toothNumber: number) => {
    const toothNumberString = `${toothNumber}`;
    // when none or 2 already selected clear then set first tooth
    if (selectedTooth.length === 0 || selectedTooth.length > 1) {
      setSelectedTooth([toothNumberString]);
      firstToothHandler(toothNumberString);
      onSelect(toothNumberString, toothNumberString);
      // when 1 already selected, onSelect then push range to selected
    } else {
      const teeth = [];
      if (toothNumber > +selectedTooth) {
        onSelect(selectedTooth[0].toString(), toothNumberString);
        for (let i = +selectedTooth[0]; i <= toothNumber; i++) {
          teeth.push(i.toString());
        }
      } else {
        firstToothHandler(toothNumberString);
        onSelect(toothNumberString, selectedTooth[0].toString());
        for (let i = toothNumber; i <= +selectedTooth[0]; i++) {
          teeth.push(i.toString());
        }
      }
      setSelectedTooth(teeth);
    }
  };

  return (
    <>
      <ToothChart
        selected={selectedTooth.length ? selectedTooth : selected}
        blocked={blocked}
        onSelect={selected => toothSelectHandler(selected)}
        onClear={() => {
          onSelect('', '');
          setSelectedTooth([]);
        }}
      />
    </>
  );
};

export default ToothChartRange;
