import { ExclamationTriangleIcon, TrashIcon } from '@heroicons/react/24/solid';
import { CaseDiscountType, CreateDeductionInput, Deduction, DiscountType, OrderMetaData, OrderStatus } from 'API';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { Table } from 'components/common/Table/Table';
import { AddSpecialDiscountModal } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/AddSpecialDiscountModal';
import { DiscountModalType } from 'components/SpecialDiscount/SpecialDiscount/AddSpecialDiscountModal/types';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useAuth } from 'providers/AuthProvider';
import { useCaseDetail } from 'providers/CaseDetailModuleProvider';
import { useToast } from 'providers/ToastProvider';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { createDiscount, removeDiscount, updateDiscount } from 'shared/api/discount.api';
import { caseDeductionColumns, CaseDeductionItem } from 'shared/constants/case-detail.constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { ACTIONS } from 'shared/enums/permission';
import { convertDeductionToItems } from 'shared/helpers/order-detail/order-detail.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { AnalyticsService } from 'shared/services/analytics.service';
import { checkCaseIsInvoiced } from 'shared/utils';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Props for the CaseDiscounts component.
 */
interface CaseDiscountsProps {
  discounts: Deduction[]; // List of discounts associated with the case
  orderStatus: OrderStatus | undefined; // Status of the order
  isOrderProcessing: boolean; // Indicates whether the order is currently being processed
}

/**
 * Interface representing the data structure for a discount to be removed.
 */
interface DiscountForRemove {
  discountId: string; // The ID of the discount to be removed
  description: string; // Description of the discount to be removed
}

/**
 * Component for displaying and managing case discounts.
 * This component allows users to view, add, and remove discounts associated with a case.
 * @param discounts - List of discounts associated with the case.
 * @param orderStatus - Status of the order.
 * @param isOrderProcessing - Indicates whether the order is currently being processed.
 * @returns JSX element representing the CaseDiscounts component.
 */
const CaseDiscounts: React.FC<CaseDiscountsProps> = ({ discounts, orderStatus, isOrderProcessing }) => {
  const { user } = useAuth();

  const { caseDetails: order, setCaseDetails } = useCaseDetail();

  const {
    discounts: selectedDiscounts,
    note,
    reason: selectedReason,
    selectedDiscountType,
  } = useAddDiscountModalStore(state => state.specialDiscount);
  const setCurrentOrder = useAddDiscountModalStore(state => state.setCurrentOrder);
  const alert = useContext(AlertModalContext);
  const [isDeletingSpecialDiscount, setIsDeletingSpecialDiscount] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [discountForRemove, setDiscountForRemove] = useState<DiscountForRemove>();
  const toast = useToast();
  const applyDiscountPermission = useRoleBasedAccessControl(ACTIONS.APPLY_DISCOUNT);

  const { fetcher: createDiscountFetcher, loading } = useLazyQueryFetcher(createDiscount);
  const { fetcher: removeDiscountFetcher } = useLazyQueryFetcher(removeDiscount);
  const { fetcher: updateDiscountFetcher } = useLazyQueryFetcher(updateDiscount);

  // Metadata "loading" state, to be used following add or remove discount
  // Please see LMS1-4981 and lms-ui MR 1063.
  const metaDataOnAddOrRemoveDiscount: OrderMetaData = {
    isPricingBeingCalculated: true,
    isTaxBeingCalculated: true,
    __typename: 'OrderMetaData',
  };

  /**
   * Handles removing a special discount from the case.
   */
  const removeSpecialDiscount = async () => {
    const discount = discounts.find(item => item.discountId === discountForRemove?.discountId);

    if (!discount) return; // if we can't find it, just return

    const isEntireCase = discount.type === CaseDiscountType.EntireCase;

    const updateCaseDetails = () => {
      // If the discount is for the entire case, we can just clear the discounts array
      if (isEntireCase) {
        discounts.splice(0, 1);
      } else {
        const index = discount.deductions.findIndex(item => item.description === discountForRemove?.description);
        discount.deductions.splice(index, 1); // Only remove the item after the update/remove api is successful
      }

      if (order) {
        setCaseDetails({
          ...order,
          appliedDiscounts: [...discounts],
          // Updates the order metadata to start a pricing/tax subscription and ensure we refetch when the data becomes available.
          metadata: metaDataOnAddOrRemoveDiscount,
        });
        setShowConfirmModal(false);
      }
    };

    setIsDeletingSpecialDiscount(true);
    try {
      /**
       * If there is only one deduction, then we delete the item by discountId and if there are more than one, then we update the discount
       * Because we don't have a way to delete a single deduction from the database
       *
       * That's why we need to check the length of the deductions array here
       *
       * If length is greater than 1, we can just update the discount with the remaining deductions
       */
      if (discount.deductions.length <= 1 || isEntireCase) {
        await removeDiscountFetcher({ discountId: discount.discountId });
      } else {
        const filteredDeductions = discount.deductions.filter(
          item => item.description !== discountForRemove?.description
        );
        const mappedDeductions = filteredDeductions.map(item => {
          return {
            ...item,
            description: item.description || '',
            orderItemId: item.orderItemId || '',
          };
        });

        const { discountId, __typename, ...newDiscount } = discount;
        await updateDiscountFetcher({
          discountId: discountId,
          input: { ...newDiscount, type: selectedDiscountType, deductions: mappedDeductions },
        });
      }

      AnalyticsService.track(AnalyticsEventName.CaseDeductionRemoved, {
        caseNumber: order?.orderNumber || '',
      });
      updateCaseDetails();
    } catch (err) {
      toast.notify('Failed to remove special discount.', ToastNotificationType.Error);
    }
    setIsDeletingSpecialDiscount(false);
  };

  /**
   * Callback function to handle delete button press for a discount.
   *
   * @param row - The discount item to be deleted
   */
  const onDeletePress = useCallback(
    (row: CaseDeductionItem) => {
      if (applyDiscountPermission.allowDelete) {
        // description is the unique key for the discount item
        setDiscountForRemove({ discountId: row.discountId, description: row.description });
        setShowConfirmModal(true);
      } else {
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
      }
    },
    [alert, applyDiscountPermission.allowDelete]
  );

  // Tack on the remove discount column so we can more easily define its functionality
  const tableCols = useMemo(() => {
    const isCaseInvoiced = checkCaseIsInvoiced(orderStatus);
    const cols = [...caseDeductionColumns];

    // Only show the remove column if the case is not invoiced
    if (!isCaseInvoiced) {
      cols.push({
        id: '',
        name: '',
        render: ({ row }: { row: CaseDeductionItem }) => {
          return (
            <div className="flex" title={'Adjustments must be done in the accounting software.'}>
              <TrashIcon
                className="h-6 w-6 cursor-pointer mt-1 ml-1"
                aria-hidden="true"
                onClick={() => {
                  if (isOrderProcessing) {
                    return toast.notify(
                      'Case processing the pricing. Please wait until processing is complete.',
                      ToastNotificationType.Info
                    );
                  }
                  onDeletePress(row);
                }}
              />
            </div>
          );
        },
      });
    }
    return cols;
  }, [isOrderProcessing, onDeletePress, orderStatus, toast]);

  const onOpenModal = () => {
    if (applyDiscountPermission.allowCreate) {
      setCurrentOrder(order);
      setModal(true);
    } else {
      alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
    }
  };

  const onCloseModal = () => {
    setModal(false);
  };

  /**
   * Applies a discount to the case.
   * Creates a new discount based on the user's input and updates the case details accordingly.
   */
  const onDiscountApply = async () => {
    const newDiscountInput: CreateDeductionInput = {
      createdBy: user?.displayName || '',
      createdDate: new Date().toISOString(),
      type: selectedDiscountType,
      deductions: selectedDiscounts.map(item => {
        return {
          orderItemId: item.orderItemId,
          discountType: item.isPercentage ? DiscountType.Percentage : DiscountType.Amount,
          discountAmount: item.dollarAmount,
          productCode: item.productCode,
          value: item.percentageValue,
          description: item.description,
        };
      }),
      notes: note,
      orderNumber: order?.orderNumber || '',
      reason: selectedReason.value,
    };

    try {
      await createDiscountFetcher(newDiscountInput);

      AnalyticsService.track(AnalyticsEventName.CaseDeductionAdded, {
        caseNumber: order?.orderNumber || '',
        discountType: selectedDiscountType,
        deductionReason: selectedReason.value,
        deductionTypes: selectedDiscounts.reduce((acc: string[], item) => {
          const deductionType = item.isPercentage ? DiscountType.Percentage : DiscountType.Amount;
          if (acc.includes(deductionType)) return acc;
          return [...acc, deductionType];
        }, []),
      });

      if (order) {
        setCaseDetails({
          ...order,
          // Updates the order metadata to start a pricing/tax subscription and ensure we refetch when the data becomes available.
          metadata: metaDataOnAddOrRemoveDiscount,
        });
      }
      onCloseModal();
    } catch (err) {
      toast.notify(
        'Failed to add special discount. Please make sure you entered valid values.',
        ToastNotificationType.Error
      );
    }
  };

  const discountRows = useMemo(() => convertDeductionToItems(discounts), [discounts]);

  return (
    <>
      <div className="mb-6">
        <Table
          label="Special Discounts"
          columns={tableCols}
          rows={discountRows}
          loading={false}
          showPagination={discountRows.length > 10}
        />
        {!checkCaseIsInvoiced(orderStatus) && (
          <button
            type="button"
            className="text-sm my-4 py-2 w-36 text-center font-medium rounded-md text-gray-900 bg-white border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={onOpenModal}
            data-qa={'dataQa'}
            disabled={isOrderProcessing}
          >
            + Add discount
          </button>
        )}
      </div>

      <AddSpecialDiscountModal
        showModal={modal}
        onClose={onCloseModal}
        onApply={onDiscountApply}
        type={DiscountModalType.SpecialDiscount}
        isLoading={loading}
      />
      {showConfirmModal && (
        <DialogBox
          title="Are you sure you want to remove this discount?"
          onCancel={() => setShowConfirmModal(false)}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText="Remove"
          confirmButtonDisabled={isDeletingSpecialDiscount}
          onConfirm={removeSpecialDiscount}
        >
          <div className="text-base text-gray-500"></div>
        </DialogBox>
      )}
    </>
  );
};

export default CaseDiscounts;
