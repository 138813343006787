import { SubscriptionsContext } from 'providers/SubscriptionProvider';
import { DependencyList, useContext, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'zen-observable-ts';

/**
 * Options for the useSubscription hook.
 */
interface SubscriptionOptions {
  /**
   * A function that returns an array of Observables or a single Observable.
   * These Observables will be subscribed to when the component mounts.
   */
  onInitialize: () => Observable<unknown>[] | Observable<unknown>;

  /**
   * A function that will be called when all the Observables from `onInitialize` have emitted at least once.
   * @param result - An object containing the latest values emitted by each Observable in `onInitialize`, as well as an `index` property indicating the order in which the Observables were passed to `onInitialize`.
   */
  onSuccess: (result: Record<string, unknown> & Record<'index', number>) => Promise<void> | void;

  /**
   * An optional function that will be called if any of the Observables from `onInitialize` emit an error.
   * @param error - An object containing the error that was emitted, as well as an `index` property indicating the order in which the Observable that emitted the error was passed to `onInitialize`.
   */
  onError?: (error: Record<'error', Error> & Record<'index', number>) => void;

  /**
   * If `true`, the Observables from `onInitialize` will be unsubscribed from after `onSuccess` or `onError` is called.
   * Defaults to `false`.
   */
  unSubscribedAfterResponse?: boolean;
}

/**
 * Subscribes to one or more observables and executes callbacks on success or error.
 * @param options - An object containing the following properties:
 *   - onInitialize: A function that returns an array of observables to subscribe to.
 *   - onSuccess: A function that is called when an observable emits a value. It receives an object containing the emitted value and the index of the observable in the array.
 *   - onError: A function that is called when an observable emits an error. It receives an object containing the error and the index of the observable in the array.
 *   - unSubscribedAfterResponse: A boolean indicating whether to unsubscribe from the observable after a response is received. Defaults to true.
 * @param deps - An array of dependencies to watch for changes. When any of these dependencies change, the hook will re-subscribe to the observables.
 */
export const useSubscription = (options: SubscriptionOptions, deps: DependencyList = []) => {
  const { onInitialize, onSuccess, onError, unSubscribedAfterResponse = true } = options;
  const onInitializeRef = useRef(onInitialize);
  const onSuccessRef = useRef(onSuccess);
  const onErrorRef = useRef(onError);
  const onSuccessCalledRef = useRef(new Map<Observable<unknown>, boolean>());
  const onErrorCalledRef = useRef(new Map<Observable<unknown>, boolean>());
  const subscriptions = useContext(SubscriptionsContext);

  useEffect(() => {
    onInitializeRef.current = onInitialize;
  }, [onInitialize]);

  useEffect(() => {
    onSuccessRef.current = onSuccess;
  }, [onSuccess]);

  useEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  useEffect(() => {
    const initialize = onInitializeRef.current?.();
    if (!initialize) return;
    const newSubscriptions = Array.isArray(initialize) ? initialize : [initialize];

    const subs = newSubscriptions.map(sub => {
      const subscription = sub.subscribe({
        next: async (result: Record<string, unknown>) => {
          const index = newSubscriptions.indexOf(sub);
          try {
            if (onSuccessCalledRef.current.get(sub)) return;
            onSuccessCalledRef.current.set(sub, true);
            await onSuccessRef.current?.({
              ...result,
              index,
            });
          } catch (error) {
            onErrorCalledRef.current.set(sub, true);
            onErrorRef.current?.({
              index: newSubscriptions.indexOf(sub),
              error: error as Error,
            });
          } finally {
            unSubscribedAfterResponse && subscription.unsubscribe();
          }
        },
        error: (error: string) => {
          console.error(`Error subscribing to ${error}`);
          unSubscribedAfterResponse && subscription.unsubscribe();
          if (onErrorCalledRef.current.get(sub)) return;
          onErrorCalledRef.current.set(sub, true);
          onErrorRef.current?.({
            index: newSubscriptions.indexOf(sub),
            error: new Error(error),
          });
        },
      });
      subscriptions.addSubscription({ uuid: uuidv4(), subscription });
      return subscription;
    });
    return () => {
      subs.forEach(sub => {
        if (sub.closed || unSubscribedAfterResponse) return;
        sub.unsubscribe();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
