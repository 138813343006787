/**
 * Component for displaying a required indicator.
 * @returns JSX Element RequiredIndicator component
 */
const RequiredIndicator: React.FC = () => {
  return (
    <>
      <div className="inline-flex text-sm font-medium ml-1 text-red-500">*</div>
    </>
  );
};

export default RequiredIndicator;
