/**
 * represents various materials available
 */
export enum Material {
  BruxZirSolidZirconia = 'BruxZir Solid Zirconia',
  BruxZirEsthetic = 'BruxZir Esthetic',
}

/**
 * indicates if the order created is via Digitally or conventional method
 */
export enum OrderType {
  DIGITAL = 'DIGITAL',
  CONVENTIONAL = 'CONVENTIONAL',
}

/**
 * indicates different states in loading a product
 */
export enum ProductState {
  ProductSelection,
  AttributesLoading,
  AttributesRendered,
  AttributesError,
}
