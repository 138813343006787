import { AttributeDisplayType, ProductFullAttribute } from 'API';
import { FC, useMemo } from 'react';
import { AttributeAggregate } from '../AttributeAggregate/AttributeAggregate';

interface AttributeDisplayTypeToothDownProps {
  attributes: Array<ProductFullAttribute & { displayType: AttributeDisplayType.ToothDown }>;
  selectedTeeth: string[];
  orderItemId: string;
}
export const AttributeDisplayTypeToothDown: FC<AttributeDisplayTypeToothDownProps> = ({
  attributes,
  selectedTeeth,
  orderItemId,
}) => {
  // Group attributes by attributeGroupName
  const groupedAttributes = useMemo(() => {
    return attributes.reduce((acc: Record<string, ProductFullAttribute[]>, attribute) => {
      // If attributeGroupName is null or undefined for some reason, then we don't want to group it
      if (!attribute.attributeGroupName) return acc;

      if (!acc[attribute.attributeGroupName]) {
        acc[attribute.attributeGroupName] = [];
      }

      acc[attribute.attributeGroupName] = [...acc[attribute.attributeGroupName], attribute];

      return acc;
    }, {});
  }, [attributes]);

  return (
    <>
      {Object.entries(groupedAttributes).map(([label, attributes]) => {
        return (
          <AttributeAggregate
            key={label}
            groupLabel={label}
            attributes={attributes}
            selectedTeeth={selectedTeeth}
            orderItemId={orderItemId}
          />
        );
      })}
    </>
  );
};
