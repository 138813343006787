import { CaseAlert } from 'API';
import { Table } from 'components/common/Table/Table';
import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import React, { useContext, useMemo } from 'react';
import { caseAlertColumns } from 'shared/constants/case-alerts/case-alerts.constants';

/**
 * Props of the CaseAlerts component.
 */
interface CaseAlertsProps {
  /**
   * An array of case alerts.
   */
  caseAlerts: CaseAlert[];
}

/**
 * Component for displaying case alerts.
 * @param caseAlerts - An array of case alerts.
 * @returns A JSX element representing the CaseAlerts component.
 */
const CaseAlerts: React.FC<CaseAlertsProps> = ({ caseAlerts }) => {
  const { caseDetails } = useContext(CaseDetailModuleContext);

  const alerts = useMemo(() => {
    return caseAlerts.map(alert => {
      return {
        ...alert,
        utcConversionTimeZoneCode: caseDetails?.utcConversionTimeZoneCode || '',
      };
    });
  }, [caseAlerts, caseDetails?.utcConversionTimeZoneCode]);

  return (
    <div className="p-6">
      <div className="flex flex-col gap-6">
        {caseAlerts.length > 0 ? (
          <Table label="Selected Alerts" rows={alerts} columns={caseAlertColumns} />
        ) : (
          <div className="mt-20 self-center">There are no alerts to show</div>
        )}
      </div>
    </div>
  );
};

export default CaseAlerts;
