import { UpdateCaseAlertDetail } from 'API';
import { isValidCaseAlertData } from 'shared/helpers/order-detail/case-alert-validator.helper';
import { CaseAlertDetailItemHOC } from './detail-item-hoc.constants';

export interface UpdateCaseAlertI {
  updateCase: UpdateCaseAlertDetail;
  className?: string;
}

export const UpdateCaseAlert = ({ className, updateCase }: UpdateCaseAlertI) => {
  const { product, toothNumber, shade, notes } = updateCase;
  const newProduct = product?.new || '';
  const newToothNumber = toothNumber?.new || '';
  const oldToothNumber = toothNumber?.original || '';
  const newShade = shade?.new || '';
  const oldShade = shade?.original || '';

  return (
    <CaseAlertDetailItemHOC title="Case Details" className={className}>
      <div className="ml-1 text-sm">
        {newProduct && <li className="mb-2">Update Product to [{newProduct}]</li>}
        {isValidCaseAlertData(oldToothNumber) && isValidCaseAlertData(newToothNumber) && (
          <li className="mb-2">
            Update ToothNumber from [{oldToothNumber}] to [{newToothNumber}]
          </li>
        )}
        {isValidCaseAlertData(oldShade) && isValidCaseAlertData(newShade) && (
          <li className="mb-2">
            Update Shade from [{oldShade}] to [{newShade}]
          </li>
        )}
      </div>
      {notes && (
        <CaseAlertDetailItemHOC title="Notes" className="mt-3">
          {notes}
        </CaseAlertDetailItemHOC>
      )}
    </CaseAlertDetailItemHOC>
  );
};
