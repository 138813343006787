import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { createProductFullCacheSlice, ProductFullCacheSlice } from './slices/createProductFullCacheSlice';
import {
  createProductReturnRequirementCacheSlice,
  ProductReturnRequirementCacheSlice,
} from './slices/createProductReturnRequirementCacheSlice';

export type FetchCacheStore = ProductFullCacheSlice & ProductReturnRequirementCacheSlice;

export const useFetchCacheStore = create<FetchCacheStore>()(
  devtools(
    (...args) => ({
      ...createProductFullCacheSlice(...args),
      ...createProductReturnRequirementCacheSlice(...args),
    }),
    { serialize: true }
  )
);
