import Axios from 'axios';
import { FileUploadResponse } from 'shared/models/file-upload-response';

/**
 * uploads a file to AWS
 *
 * @param file - represents a file to be uploaded
 * @param uploadUrl - upload url
 * @param uploadProgressEventCallback - callback to indicate progress of upload process
 * @param abortController - represents a controller object that allows you to abort one or more Web requests as and when desired.
 *
 * @returns FileUploadResponse - response body containing related file paths, awsFileName etc.,
 */
export const uploadFile = async (
  file: File,
  uploadUrl: string,
  uploadProgressEventCallback: (event: ProgressEvent) => void,
  abortController: AbortController
) => {
  const config = {
    onUploadProgress: uploadProgressEventCallback, // our progress callback
    signal: abortController.signal, // our abort controls
    headers: {
      'Content-Type': '', // Set to empty string to prevent incorrect type being sent and files not being able to be viewed.
    },
  };

  const response = await Axios.put<FileUploadResponse>(uploadUrl, file, config);
  return response.data;
};
