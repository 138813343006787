import { CreateRmaOrderInput, CreateRmaOrderMutation, NextRmaOrderQuery } from 'API';
import { createRmaOrder as createRmaOrderMutation } from 'graphql/mutations';
import { nextRmaOrderQuery } from 'graphql/queries';
import Http from './http';

/**
 * fetches next RMA order number
 * @param orderNumber - order number for which next RMA order number needs to generated
 * @returns next RMA order number
 */
export const nextRmaOrder = async (orderNumber: string) => {
  const response = await Http.handleGraphqlOperation<NextRmaOrderQuery>(nextRmaOrderQuery, { orderNumber });
  return Http.processGraphqlResponse(response.nextRmaOrder, 'NextRmaOrder');
};

/**
 * API request to create a new RMA order
 * @param input - request body required for creating a new RMA order
 * @returns response body representing a created order
 */
export const createRmaOrder = async (input: CreateRmaOrderInput) => {
  const response = await Http.handleGraphqlOperation<CreateRmaOrderMutation>(createRmaOrderMutation, { input: input });
  return Http.processGraphqlResponse(response.createRmaOrder, 'RmaOrder');
};
