import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { FC } from 'react';
import { Blocker } from 'react-router-dom';

const navigationModal = {
  title: 'Are you sure you would like to cancel?',
  subtitle: 'Any progress will be lost',
  confirmText: 'Yes, Cancel',
  cancelText: 'Go Back',
};

/**
 * Input props for {@link ConfirmNavigationModal}
 */
interface ConfirmNavigationModalProps {
  /**
   *
   */
  blocker: Blocker;

  /**
   *
   */
  isOpenModal: boolean;

  /**
   *
   */
  onCloseModal: () => void;

  /**
   *
   */
  onConfirm: () => void;
}

/**
 * @param blocker -
 * @param isOpenModal -
 * @param onCloseModal -
 * @param onConfirm -
 */
export const ConfirmNavigationModal: FC<ConfirmNavigationModalProps> = ({
  blocker,
  isOpenModal,
  onCloseModal,
  onConfirm,
}) => {
  const isNavigationBlocked = blocker.state === 'blocked';
  const isOpen = isNavigationBlocked || isOpenModal;

  /**
   *
   */
  const onNavigationCancel = () => {
    if (isNavigationBlocked) {
      blocker.reset?.();
    }
    onCloseModal();
  };

  /**
   *
   */
  const onNavigationConfirm = () => {
    if (isNavigationBlocked) {
      blocker.proceed?.();
    }
    onConfirm();
    onCloseModal();
  };

  return (
    <>
      {isOpen && (
        <DialogBox
          title={navigationModal.title}
          onCancel={onNavigationCancel}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText={navigationModal.confirmText}
          cancelText={navigationModal.cancelText}
          onConfirm={onNavigationConfirm}
          outSideClickClose={false}
        >
          <div className="text-base text-gray-500">{navigationModal.subtitle}</div>
        </DialogBox>
      )}
    </>
  );
};
