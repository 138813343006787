import { CaseHistoryQuery, CaseHistoryQueryVariables } from 'API';
import { caseHistoryQuery } from 'graphql/queries';
import Http from './http';

/**
 * Retrieves case alerts for a given customer's billing account and provider ID via AppSync.
 * @param props - An object container a customer's billing account and provider ID.
 * @returns case alert results for the given criteria.
 */
export const getCaseHistory = async (props: CaseHistoryQueryVariables) => {
  const response = await Http.handleGraphqlOperation<CaseHistoryQuery>(caseHistoryQuery, props);
  return Http.processGraphqlResponse(response.caseHistory, 'HistoryResults');
};
