import { Dialog } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import { OrderItem, ReturnType } from 'API';
import Button from 'components/common/Button/Button';
import Modal from 'components/common/Modal';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { ToastNotificationType } from 'shared/enums';
import {
  filterOrderItemAttributesByTypeAndName,
  getDefaultOrderItemInput,
  getExchangeCaseNotes,
} from 'shared/helpers/order-entry/order-entry.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { LocalOrderItemInput, LocalOrderType } from 'shared/models';
import { ClassificationProduct } from 'shared/models/classification-product';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';

/**
 * Props for the CreateRmaErrorModal component.
 */
export interface CreateRmaErrorModalI {
  /**
   * The title of the modal.
   */
  title: string;

  /**
   * Whether the case is an exchange.
   */
  isExchange: boolean;

  /**
   * Function to close the modal.
   */
  onClose: () => void;

  /**
   * Function to handle redirect after order submit.
   *
   * @param orderNumber - The order number.
   * @param orderItems - The order items.
   * @param errorMessage - The error message.
   */
  handleRedirectAfterOrderSubmit: (orderNumber: string, orderItems: OrderItem[], errorMessage: string) => void;
}

/**
 * Component responsible for displaying a modal for RMA (Return Merchandise Authorization) errors.
 *
 * @param title - The title of the modal.
 * @param onClose - Function to close the modal.
 * @param isExchange - Whether the case is an exchange.
 * @param handleRedirectAfterOrderSubmit - Function to handle redirect after order submit.
 * @returns a modal component for RMA errors.
 */
export const CreateRmaErrorModal: FC<CreateRmaErrorModalI> = ({
  title,
  onClose,
  children,
  isExchange,
  handleRedirectAfterOrderSubmit,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();
  const { order, products } = useContext(OrderModuleContext);
  const { setSubmitAlertCount, patchOrder } = useContext(OrderModuleActionsContext);

  const fetchProductFull = useFetchCacheStore(state => state.fetchProductFull);
  const { fetcher: getProductFullFetcher } = useLazyQueryFetcher(fetchProductFull);

  const productInfoRef = useRef<Record<string, ClassificationProduct>>({});

  /**
   * At the moment of writing this comment, totally we have 475 products. So, We can store all the products with their product code as a key.
   * This will help us to avoid unnecessary looping through the products array to find the product by product code.
   */
  useEffect(() => {
    /**
     * Each product code will be unique and each product code have the materialName and restorationName value.
     */
    products.forEach(product => {
      productInfoRef.current[product.productCode] = product;
    });
  }, [products]);

  const handleOnSubmit = async () => {
    if (!isExchange) {
      const errorMessage = `Unable to apply credit to Case #${
        order.originalOrderNumber || order.orderNumber
      }. Please send the case to a Shipping Specialist`;

      // Resets the create RMA error modal data.
      onClose();
      // Handles the redirect after the RMA confirmation, Pass orderNumber as '' to avoid rendering Last case view in OrderEntry page
      handleRedirectAfterOrderSubmit('', [], errorMessage);
      return;
    }

    /**
     * Filter the exchange order items that have new product and not duplicate product code.
     */
    const filteredExchangeOrderItems = order.orderItems.filter((item, index, self) => {
      // Check if the order item is an exchange.
      const isExchange = item.returnType === ReturnType.Exchange;
      // Check if the order item has new product.
      const hasNewProduct = !!item.newProduct?.productCode;
      // Check if the any new product code is duplicate.
      const isDuplicateProductCode =
        self.findIndex(t => t.newProduct?.productCode === item.newProduct?.productCode) !== index;
      // Return the exchange order items that have new product and not duplicate product code.
      return isExchange && hasNewProduct && !isDuplicateProductCode;
    });

    // Get the new product codes from the filtered exchange order items.
    const newExchangeProductCodes = filteredExchangeOrderItems.map(item => {
      return item.newProduct ? item.newProduct.productCode : '';
    });

    //  Fetch the product details for the new product codes.
    const productDetailPromises = newExchangeProductCodes.map(productCode => getProductFullFetcher(productCode));

    try {
      setIsLoading(true);
      // Wait for all the product details to be fetched.
      const productDetails = await Promise.all(productDetailPromises);
      // Map the order items to the new order items with the product details.
      const orderItems = order.orderItems.map(orderItem => {
        // Find the product detail for the order item.
        const productDetail = productDetails.find(
          productDetail => productDetail.data?.productCode === orderItem.newProduct?.productCode
        );

        const productDetailProductCode = productDetail?.data?.productCode || '';
        const productDetailProductName = productDetail?.data?.productName || '';
        const productDetailAttributes = productDetail?.data?.attributes || [];

        const productInfo = productInfoRef.current[productDetailProductCode] || {};

        const newOrderItem: LocalOrderItemInput = {
          ...getDefaultOrderItemInput(),
          material: productInfo.materialName || '',
          restorationType: productInfo.restorationName || '',
          productCode: productDetailProductCode,
          productName: productDetailProductName,
        };

        // As per LMS1-7251, add-ons, services and attributes from an exchange case should not be transferred over to the new case.
        if (!isExchange) {
          newOrderItem.addOns = filterOrderItemAttributesByTypeAndName(orderItem, productDetailAttributes, 'addOns');
          newOrderItem.services = filterOrderItemAttributesByTypeAndName(
            orderItem,
            productDetailAttributes,
            'services'
          );
          newOrderItem.attributes = filterOrderItemAttributesByTypeAndName(
            orderItem,
            productDetailAttributes,
            'attributes'
          );
        }

        return newOrderItem;
      });

      // reset the submit alert count and patch the order with the new order items.
      setSubmitAlertCount(0);

      const { notes, originalOrderNumber } = order;
      const notesToAppend = isExchange && originalOrderNumber ? getExchangeCaseNotes(originalOrderNumber) : '';

      // Replace the existing rma order input with the new normal order input.
      patchOrder({
        notes: `${notes || ''} ${notesToAppend}`.trim() || undefined, // The business has requested that exchange cases for which the original product was required to be returned but wasn't must include this notice in the order notes section.
        orderNumber: '',
        originalOrderNumber: '',
        orderItems: orderItems,
        localMetadata: {
          localOrderType: LocalOrderType.Standard,
        },
      });
    } catch (error) {
      console.error('Error fetching product details:', error);
      toast.notify('Failed to fetch product details', ToastNotificationType.Error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal width="w-1/4" onClose={onClose} outSideClickClose={false}>
      <div className="gap-4 p-6 text-center mb-3">
        <div className="mb-5">
          <div className="m-auto flex items-center justify-center md:h-12 md:w-12 rounded-full bg-red-100 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
        </div>
        <div>
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            {title}
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 max-w-xl">{children}</p>
          </div>
        </div>
      </div>

      <div className="w-100 px-4 pb-4">
        <Button variant="primary" disabled={isLoading} onClick={handleOnSubmit} data-qa="createRmaErrorModalOkayButton">
          {isLoading ? 'Loading...' : 'Okay'}
        </Button>
      </div>
    </Modal>
  );
};
