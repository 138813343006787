/**
 * represents various possible case history event types
 */
export enum CaseHistoryEventType {
  Created = 'order.created',
  OrderTransactionCreated = 'orders.order.transaction.created',
  OrderTransactionUpdated = 'orders.order.transaction.updated',
  StatusUpdate = 'order.status.updated',
  InvoicePending = 'orders.order.invoice.pending',
  Invoiced = 'orders.order.transaction.invoiced',
  PreferenceOverride = 'order.preferenceoverride',
  InvoiceVoided = 'orders.order.invoice.voided',
  LocationScan = 'routing.audit.order.created',
  LocationScanCheckIn = 'routing.orders.order.checkin',
  TechnicianTaskRecorder = 'technician.tasks.task.recorded',
  TechnicianProcessUpdated = 'technician.order.process.updated',
  ManagerProcessUpdated = 'technician.order.status.updated',
  ManagerWorkItemResolved = 'technician.manager.workitem.resolved',
  ManagerWorkItemCreated = 'technician.manager.workitem.created',
  ManagerWorkItemUpdated = 'technician.manager.workitem.updated',
  SplitBundleOrderRemoved = 'splitbundleorder.removed',
  SpecialDiscountCreated = 'specialdiscount.created',
  SpecialDiscountDeleted = 'specialdiscount.deleted',
  CouponApplied = 'marketing.order.coupon.applied',
  CouponDeleted = 'marketing.order.coupon.deleted',
}
