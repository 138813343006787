import { DialogBox } from 'components/common/Dialog/DialogBox';
import Dropdown from 'components/common/Dropdown/Dropdown';
import { FC, useMemo, useState } from 'react';
import { CreatedOrder, DropdownModel } from 'shared/models';

/**
 * Props interface for the InvoiceCaseSelectModal component.
 */
export interface InvoiceCaseSelectModalProps {
  onClose: () => void; //Function to close the modal.
  invoicedOrders: CreatedOrder[]; //Array of invoiced orders.
  type: 'invoice' | 'shippingLabel'; //Type of the modal ('invoice' or 'shippingLabel')
  /**
   * Function to handle the reprint action.
   * @param orderNumber - The order number of the selected case.
   * @param type - Type of the action ('invoice' or 'shippingLabel').
   * @returns A promise that resolves to a boolean indicating the success of the reprint action.
   */
  reprintSubmitHandler: (orderNumber: string, type: 'invoice' | 'shippingLabel') => Promise<boolean>;
}

/**
 * Component to display a modal for selecting a case to reprint invoice or shipping label.
 * @param onClose - Function to close the modal.
 * @param invoicedOrders - Array of invoiced orders.
 * @param type - Type of the modal ('invoice' or 'shippingLabel').
 * @param reprintSubmitHandler - Function to handle the reprint action.
 */
export const InvoiceCaseSelectModal: FC<InvoiceCaseSelectModalProps> = ({
  onClose,
  invoicedOrders,
  type,
  reprintSubmitHandler,
}) => {
  const [selectedCaseId, setSelectedCaseId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isInvoiceReprint = type === 'invoice';
  const title = `Select a case to ${isInvoiceReprint ? 'reprint invoice' : 'reprint shipping label'}`;
  const confirmText = isInvoiceReprint ? 'Reprint Invoice' : 'Reprint Shipping Label';

  /**
   * Handles the confirmation action.
   * Triggers the reprint submit handler and closes the modal if successful.
   */
  const onConfirm = async () => {
    setIsLoading(true);
    const success = await reprintSubmitHandler(selectedCaseId, type);
    setIsLoading(false);
    success && onClose();
  };

  const selectedOption: DropdownModel = useMemo(() => {
    return {
      value: selectedCaseId,
      primaryLabel: selectedCaseId,
    };
  }, [selectedCaseId]);

  const options = useMemo<DropdownModel[]>(() => {
    return invoicedOrders.map(order => {
      return {
        value: order.orderNumber,
        primaryLabel: order.orderNumber,
      };
    });
  }, [invoicedOrders]);

  return (
    <DialogBox
      title={title}
      onCancel={onClose}
      confirmButtonVariant="primary"
      confirmText={confirmText}
      onConfirm={onConfirm}
      confirmButtonDisabled={isLoading || !selectedCaseId}
      outSideClickClose={false}
    >
      <div className="my-4">
        <Dropdown
          isRequired
          label="Select a case"
          selected={selectedOption}
          setSelected={option => {
            setSelectedCaseId(option?.value || '');
          }}
          data={options}
        />
      </div>
    </DialogBox>
  );
};
