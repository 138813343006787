import { ProductFullAttribute } from 'API';
import AttributeSelectDropdown from 'components/order-entry/Products/Inputs/AttributeSelectDropdown/AttributeSelectDropdown';
import { useOrderEntryContext } from 'providers/OrderModuleProvider';
import { AttributeAggregateDynamicItem } from './AggregateDynamicItem/AggregateDynamicItem';

export interface AttributeAggregateItemProps {
  attributes: ProductFullAttribute[];
  groupLabel: string;
  selectedTooth: string;
  orderItemId: string;
}

export const AttributeAggregateItem: React.FC<AttributeAggregateItemProps> = ({
  attributes,
  groupLabel,
  selectedTooth,
  orderItemId,
}) => {
  const { onSubmitAlertCount } = useOrderEntryContext();
  return (
    <div className="col-span-4 grid grid-cols-4 gap-x-6 gap-y-4">
      <AttributeSelectDropdown
        label={groupLabel}
        options={[{ value: selectedTooth, primaryLabel: selectedTooth }]}
        value={selectedTooth}
        onValueChange={() => undefined}
        isRequired={true}
        isSubmitted={onSubmitAlertCount > 0}
        isDisabled
        isShowLabelWhenDisabled
      />
      {attributes.map(item => (
        <AttributeAggregateDynamicItem
          key={item.name}
          {...item}
          selectedTooth={selectedTooth}
          localTypeValue={item.type}
          orderItemId={orderItemId}
        />
      ))}
    </div>
  );
};
