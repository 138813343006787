import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { ToastNotificationType } from 'shared/enums';
import { cn } from 'shared/utils';

/**
 * Props for the AlertMessage component.
 */
interface AlertMessageProps {
  /** The message to display in the alert. */
  message: string;
  /** The type of toast notification. */
  type: ToastNotificationType;
  /** The ID of the toast notification. */
  toastId?: string;
  /** Flag to show or hide the close icon. */
  showCloseIcon?: boolean;
  /** Additional CSS classes for styling. */
  className?: string;
}

/**
 * Standard alert messages to display throughout the application.
 * Show/Hide can be controlled by toast or by local state.
 * If toastId is passed then we are controlling with the toast library.
 * Else, we are controlling with local state.
 *
 * @param param - props for configuring theming and functionality of alert message
 * @returns React Functional Component
 */
export const AlertMessage: React.FC<AlertMessageProps> = ({ message, type, showCloseIcon, className, toastId }) => {
  const [showNotification, setShowNotification] = useState<boolean>(true);

  let color = 'green';
  switch (type) {
    case ToastNotificationType.Error:
      color = 'red';
      break;
    case ToastNotificationType.Warning:
      color = 'yellow';
      break;
    case ToastNotificationType.Info:
      color = 'blue';
      break;
  }

  const LeftIcon = type !== ToastNotificationType.Success ? ExclamationCircleIcon : CheckCircleIcon;

  /**
   * Handle removing the alert from the DOM.
   * If there is a toast, let toast library handle removing.
   * Else, let local state handle it.
   */
  const onClose = () => {
    if (toastId) {
      toast.remove(toastId);
    } else {
      setShowNotification(false);
    }
  };

  if (!toastId && !showNotification) return null;

  return (
    <div
      className={cn(
        'flex items-center w-1/2 py-4 px-5 rounded-md text-sm font-medium gap-4 shadow-sm border-l-4',
        `bg-${color}-50`,
        `border-${color}-400`,
        className
      )}
    >
      <div className="rounded-full">
        <LeftIcon className={cn('h-5', `text-${color}-400`)} />
      </div>
      <div className={cn('flex-grow', `text-${color}-800`)}>{message}</div>
      {showCloseIcon && (
        <div>
          <XMarkIcon className={cn('h-4 cursor-pointer', `text-${color}-500`)} onClick={onClose} />
        </div>
      )}
    </div>
  );
};
