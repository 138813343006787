// Messages
export const NETWORK_OFFLINE_MESSAGE = 'You appear to be offline or have a slow network connection.';
export const NETWORK_RECONNECTED_MESSAGE = 'Network reconnected.';

// Classes
export const OFFLINE_ICON_COLOR = 'text-red-600';
export const RECONNECTED_ICON_COLOR = 'text-green-600';

// Test IDs
export const NETWORK_STATUS_TEXT_TEST_ID = 'network-status-text';
export const NETWORK_STATUS_WIFI_ICON_TEST_ID = 'network-status-wifi-icon';

// Window Event Types
export const WINDOW_EVENT_TYPE_OFFLINE = 'offline';
export const WINDOW_EVENT_TYPE_ONLINE = 'online';
