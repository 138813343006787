import {
  CaseAlert,
  CaseAlertStatus,
  Classifications,
  LmsOrder,
  OrderStatus,
  ProductFullAttribute,
  ReturnType,
  TechnicalPreference,
} from 'API';
import { cloneDeep, findIndex, isEqual } from 'lodash';
import moment from 'moment';
import React, { PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PRODUCT_RETURN_REQUIRED_VALIDATION_RETURN_TYPES } from 'shared/constants/case-rma.constants';
import { VALIDATE_BUNDLE_SPLIT_SUBMIT_TOPIC } from 'shared/constants/pub-sub.constants';
import { AttributeName, AttributeType, ErrorMessage, FileUploadState } from 'shared/enums';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import {
  filterRmaOrderItemsByReturnType,
  getCarrierName,
  getDefaultOrderInput,
  getOrderType,
  isDigitalOrder as isDigitalOrderFunc,
} from 'shared/helpers/order-entry/order-entry.helper';
import {
  orderItemAddOnsWithPreferencesApplied,
  orderItemAttributesWithPreferencesApplied,
} from 'shared/helpers/order-entry/technical-preferences/order-entry-technical-preferences.helper';
import { pubSub } from 'shared/helpers/pub-sub';
import { isRmaLocalOrder } from 'shared/helpers/util.helper';
import { LocalOrderInput, LocalOrderItemInput, LocalOrderProductAttributeInput } from 'shared/models';
import { ClassificationProduct } from 'shared/models/classification-product';
import { getSelectedToothRange, isProductCodReturnedItem, isRestorationTypeBridge } from 'shared/utils';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';
import { shallow } from 'zustand/shallow';
import { isQuantityAttribute } from '../shared/helpers/attribute/attribute.helper';
import { AlertModalContext } from './AlertModalProvider';

/**
 * Represents the current order state. Useful when we don't want to wait for context to full update and propagate.
 */
let orderLocal: LocalOrderInput;

/**
 * Get the most recent version of the order.
 * @returns
 */
export const getLocalOrder = () => {
  return orderLocal;
};

export interface OrderError {
  title: string;
  message: string;
}

/**
 * represents sets of the required attributes for a given product code
 */
export interface RequiredAttributes {
  [productCode: string]: string[];
}

/**
 * represents sets of the tier pricing attributes for a given product code
 */
export interface TierPricingAttributes {
  [productCode: string]: string[];
}

export interface LocalCaseAlert extends CaseAlert {
  initialStatus?: CaseAlertStatus;
  isVisible?: boolean;
}

export interface TechnicalPreferencesByProductCode {
  [productCode: string]: TechnicalPreference[];
}

export interface ApplyTechnicalPreferencesToTargetOrderItemI {
  technicalPreferences: TechnicalPreference[];
  addOnsAndServices: ProductFullAttribute[];
  targetOrderItemId: string;
  isNewOrPendingOrder: boolean;
  productAttributes: ProductFullAttribute[];
}

type OrderItemUpdate = Partial<Omit<LocalOrderItemInput, 'id'>>;

/**
 * represents various state objects available for child components
 */
interface Context {
  /**
   * represents an object holding user input while creating/editing a new work order
   */
  order: LocalOrderInput;

  /**
   * represents whether an order loading in progress
   */
  orderLoading: boolean;

  /**
   * represents no of times submit button is clicked
   * useful in determining whether user is entering/editing a input form for first time
   */
  onSubmitAlertCount: number;

  /**
   * indicates whether the state of `No Patient Info` radio button is checked
   */
  noPatientInfoChecked: boolean;

  /**
   * list of classifications available
   */
  classifications: Classifications[];

  /**
   * list of classification products available
   */
  products: ClassificationProduct[];

  /**
   * date format to use
   */
  dateFormat: string;

  /**
   * list of required attributes for a product / service
   */
  requiredAttributes: RequiredAttributes;

  /**
   * list of tier pricing attributes available in a product / service
   */
  tierPricingAttributes: TierPricingAttributes;

  /**
   * list of related case alerts for a given order
   */
  caseAlerts: LocalCaseAlert[];

  /**
   * indicates whether an order is digital one
   */
  isDigitalOrder: boolean;

  /**
   * indicates the status of an order
   */
  orderStatus?: OrderStatus;

  /**
   * Indicates whether the order is part of a bundle split case.
   * Optional, influences the processing or display of the order.
   */
  isBundleSplitCase?: boolean;

  /**
   * represents an uniquely identifiable id for an order
   */
  orderId: string;

  /**
   * We consider the order to be updated when all the order items in the order have the isLoaded flag set to true.
   * The isIgnoreUpdateCount flag will prevent the count from being updated.
   */
  orderUpdateCount: number;

  /**
   * represents a list of technical preferences keyed to productCode
   */
  technicalPreferencesByProductCode: TechnicalPreferencesByProductCode;

  /**
   * indicates the id of currently selected order item
   */
  selectedOrderItemId: string;

  /**
   * indicates selected order item
   */
  selectedOrderItem?: LocalOrderItemInput;

  /**
   * The order data from the API.
   * Please note that this is not the same as the order state, which is the local state for the order entry form.
   * care should be taken not to modify this state with the local order state.
   */
  orderData: LmsOrder | null;

  /**
   * required implant attributes for a given order item
   */
  requiredImplantItems: string[];

  /**
   * indicates if original order is created using legacy system
   */
  originalOrderIsLegacy: boolean;
}

/**
 * actions which can be performed using this provider
 */
interface ActionsContext {
  setOrderLoading: (isLoading: boolean) => void;
  patchOrder: (orderData: Partial<LocalOrderInput>, isIgnoreUpdateCount?: boolean) => void;
  setSubmitAlertCount: (count: number) => void;
  setNoPatientInfoChecked: (checked: boolean) => void;
  setProductClassifications: (classifications: Classifications[]) => void;
  getMaterialAndResorationType: (productCode: string) => ClassificationProduct | undefined;
  setRequiredAttributes: (requiredAttributes: RequiredAttributes) => void;
  hasValidQuantities: (orderItem: LocalOrderItemInput) => boolean;
  hasValidReturnTypes: () => boolean;
  setTierPricingAttributes: (tierPricingAttributes: TierPricingAttributes) => void;
  isValidOrder: () => OrderError | undefined;
  setDateFormat: (format: string) => void;
  getOrderItemSelectedTeeth: (id: string) => string[];
  setCaseAlerts: (caseAlerts: LocalCaseAlert[]) => void;
  resetCaseAlertSelections: (patientInfo: PatientInformation) => void;
  updateOrderItem: (itemId: string, orderItem: OrderItemUpdate) => void;
  checkOrderItemIsValid: (orderItem: LocalOrderItemInput) => boolean;
  setOrderStatus: (orderStatus: OrderStatus) => void;
  isCaseAlertPatientMatch: (caseAlert: LocalCaseAlert, patientInfo: PatientInformation) => boolean;
  increaseOrderUpdateCount: () => void;
  applyTechnicalPreferencesToTargetOrderItem: (
    props: ApplyTechnicalPreferencesToTargetOrderItemI
  ) => LocalOrderItemInput | undefined;
  setTechnicalPreferencesByProductCode: (props: TechnicalPreferencesByProductCode) => void;
  setSelectedOrderItemId: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<LocalOrderInput>>;
  setOrderData: React.Dispatch<React.SetStateAction<LmsOrder | null>>;
  setRequiredImplantsItems: (requiredImplantItems: string[]) => void;
  isOrderItemExchangeReturnType: (orderItemId?: string) => boolean;
  isValidProductReturnRequired: (orderItem: LocalOrderItemInput) => boolean;
  setOriginalOrderIsLegacy: (isLegacy: boolean) => void;
}

export interface PatientInformation {
  patientFirstName?: string;
  patientLastName?: string;
  patientId?: string;
  noPatientInfo?: boolean;
}

/**
 * represents an initial default state for placeholder,
 * before data from APIs is hydrated
 */
export const initialState: Context = {
  order: getDefaultOrderInput(),
  orderLoading: false,
  onSubmitAlertCount: 0,
  noPatientInfoChecked: false,
  classifications: [],
  products: [],
  dateFormat: 'MM/DD/yyyy',
  requiredAttributes: {},
  tierPricingAttributes: {},
  caseAlerts: [],
  orderStatus: undefined,
  isDigitalOrder: false,
  orderId: '',
  orderUpdateCount: 0,
  technicalPreferencesByProductCode: {},
  selectedOrderItemId: '',
  orderData: null,
  requiredImplantItems: [],
  originalOrderIsLegacy: false,
};

export const OrderModuleActionsInitialContext: ActionsContext = {
  setOrderLoading: (isLoading: boolean) => {
    console.log('Is this really ', isLoading);
  },
  patchOrder: (orderData: Partial<LocalOrderInput>) => {
    console.log(orderData);
  },
  setSubmitAlertCount: (count: number) => {
    console.log(count);
  },
  setNoPatientInfoChecked: (checked: boolean) => {
    console.log(checked);
  },
  setProductClassifications: () => {
    return;
  },
  hasValidQuantities: (orderItem: LocalOrderItemInput) => {
    console.log(`hasValidQuantities => orderItem: ${JSON.stringify(orderItem)}`);
    return true;
  },
  hasValidReturnTypes: () => {
    return true;
  },
  getMaterialAndResorationType: (productCode: string): ClassificationProduct => {
    const initialContext: ClassificationProduct = {
      productCode,
      materialName: '',
      restorationName: '',
    };
    return initialContext;
  },
  setRequiredAttributes: (requiredAttributes: RequiredAttributes) => {
    return requiredAttributes;
  },
  setTierPricingAttributes: (tierPricingAttributes: TierPricingAttributes) => {
    return tierPricingAttributes;
  },

  isValidOrder: (): OrderError | undefined => {
    return undefined;
  },
  checkOrderItemIsValid: (orderItem: LocalOrderItemInput) => {
    console.log(orderItem);
    return true;
  },
  setDateFormat: (format: string) => {
    console.log(format);
  },
  getOrderItemSelectedTeeth: (id: string) => {
    console.log(id);
    return [];
  },
  setCaseAlerts: (caseAlerts: CaseAlert[]) => {
    return caseAlerts;
  },
  resetCaseAlertSelections: () => {
    return;
  },
  updateOrderItem: (itemId: string, orderItem: OrderItemUpdate) => {
    console.log(itemId, orderItem);
  },
  setOrderStatus: (orderStatus: OrderStatus) => {
    return orderStatus;
  },
  isCaseAlertPatientMatch: () => {
    return true;
  },
  increaseOrderUpdateCount: () => undefined,
  applyTechnicalPreferencesToTargetOrderItem: () => undefined,
  setTechnicalPreferencesByProductCode: () => undefined,
  setSelectedOrderItemId: () => undefined,
  setOrder: () => undefined,
  setOrderData: () => undefined,
  setRequiredImplantsItems: (requiredImplantItems: string[]) => {
    console.log('Is this really ', requiredImplantItems);
  },
  isOrderItemExchangeReturnType: function (orderItemId?: string): boolean {
    console.log('orderItemId ', orderItemId);
    return false;
  },
  isValidProductReturnRequired: () => {
    return true;
  },
  setOriginalOrderIsLegacy: (isLegacy: boolean) => {
    console.log('isLegacy ', isLegacy);
  },
};

export const OrderModuleContext = React.createContext<Context>(cloneDeep(initialState));
export const OrderModuleActionsContext = React.createContext<ActionsContext>(OrderModuleActionsInitialContext);

export const OrderModuleProvider: React.FC<PropsWithChildren<Partial<Context>>> = ({
  children,
  isBundleSplitCase,
  orderId,
  ...props
}) => {
  const initialStateCopy = cloneDeep(initialState);
  const [orderLoading, setOrderLoading] = useState<boolean>(initialStateCopy.orderLoading);
  const [requiredImplantItems, setRequiredImplantsItems] = useState<string[]>(
    props.requiredImplantItems || initialStateCopy.requiredImplantItems
  );
  /**
   * The order state for the order entry form. Used as the local state for the order entry form.
   */
  const [order, setOrder] = useState<LocalOrderInput>(props.order || initialStateCopy.order);
  const [onSubmitAlertCount, setSubmitAlertCount] = useState<number>(
    props.onSubmitAlertCount || initialStateCopy.onSubmitAlertCount
  );
  const [noPatientInfoChecked, setNoPatientInfoChecked] = useState<boolean>(
    props.noPatientInfoChecked || initialStateCopy.noPatientInfoChecked
  );
  const [classifications, setClassifications] = useState<Classifications[]>(
    props.classifications || initialStateCopy.classifications
  );
  const [products, setProducts] = useState<ClassificationProduct[]>(props.products || initialStateCopy.products);
  const [dateFormat, setDateFormat] = useState<string>(props.dateFormat || initialState.dateFormat);
  const [requiredAttributes, setRequiredAttributes] = useState<RequiredAttributes>({});
  const [tierPricingAttributes, setTierPricingAttributes] = useState<TierPricingAttributes>({});

  const [caseAlerts, setCaseAlerts] = useState<LocalCaseAlert[]>([]);
  const [orderStatus, setOrderStatus] = useState<OrderStatus | undefined>(props.orderStatus);
  const [orderUpdateCount, setOrderUpdateCount] = useState(0);
  const [technicalPreferencesByProductCode, setTechnicalPreferencesByProductCode] = useState({});
  const [selectedOrderItemId, setSelectedOrderItemId] = useState('');

  /**
   * The order data from the API.
   * Please note that this is not the same as the order state, which is the local state for the order entry form.
   * Please don't modify this state with the local order state.
   */
  const [orderData, setOrderData] = useState<LmsOrder | null>(null);

  const [originalOrderIsLegacy, setOriginalOrderIsLegacy] = useState<boolean>(
    props.originalOrderIsLegacy || initialStateCopy.originalOrderIsLegacy
  );
  const alert = useContext(AlertModalContext);

  const selectedOrderItem = useMemo(
    () => order?.orderItems?.find(item => item.id === selectedOrderItemId),
    [order, selectedOrderItemId]
  );

  const navigate = useNavigate();
  const isOrderItemExchangeReturnType = useCallback(
    (orderItemId?: string): boolean => {
      if (orderItemId) {
        const orderItem = order.orderItems.find(item => item.id === orderItemId);
        return orderItem?.returnType === ReturnType.Exchange;
      } else {
        return order.orderItems.some(item => item.returnType === ReturnType.Exchange);
      }
    },
    [order.orderItems]
  );

  const applyTechnicalPreferencesToTargetOrderItem = useCallback(
    ({
      technicalPreferences,
      addOnsAndServices,
      targetOrderItemId,
      isNewOrPendingOrder,
      productAttributes,
    }: ApplyTechnicalPreferencesToTargetOrderItemI) => {
      let updatedOrderItem: LocalOrderItemInput | undefined = undefined;
      setOrder(previousOrder => {
        // Updates the order object so that any eligible preferences get applied.
        const orderItemsWithPreferencesApplied = previousOrder.orderItems.map(orderItem => {
          const isNewProduct =
            !orderItem.itemId ||
            !previousOrder.orderItems.map(orderItem => orderItem.itemId).includes(orderItem.itemId);
          // Preferences are only applied to the target order item.
          if (targetOrderItemId === orderItem.id) {
            orderItem.attributes = orderItemAttributesWithPreferencesApplied(
              technicalPreferences,
              orderItem.attributes,
              productAttributes,
              isNewOrPendingOrder,
              isNewProduct
            );
            orderItem.addOns = orderItemAddOnsWithPreferencesApplied(
              orderItem.addOns,
              addOnsAndServices,
              isNewOrPendingOrder,
              isNewProduct
            );
          }
          updatedOrderItem = orderItem;
          return orderItem;
        });
        return {
          ...previousOrder,
          orderItems: orderItemsWithPreferencesApplied,
        };
      });
      return updatedOrderItem;
    },
    []
  );

  /**
   * Handles setting required attributes by assigning them to the list maintained for each product code.
   */
  const handleSetRequiredAttributes = (reqAttributes: RequiredAttributes) => {
    setRequiredAttributes(previousReqAttributes => {
      return { ...previousReqAttributes, ...reqAttributes };
    });
  };

  /**
   * Handles setting tier pricing attributes by assigning them to the list maintained for each product code.
   */
  const handleSetTierPricingAttributes = (tierPricingAttributes: TierPricingAttributes) => {
    setTierPricingAttributes(prevTierPricingAttributes => {
      return { ...prevTierPricingAttributes, ...tierPricingAttributes };
    });
  };

  const {
    updateOrder,
    addOrder,
    findOrder,
    isValidAllOrders,
    setSelectedTab,
    submitAlertCount,
    increaseOrderBundleUpdateCount,
  } = useBundleSplitCaseStore(
    state => ({
      updateOrder: state.updateOrder,
      addOrder: state.addOrder,
      findOrder: state.findOrder,
      isValidAllOrders: state.isValidAllOrders,
      setSelectedTab: state.setSelectedTab,
      submitAlertCount: state.submitAlertCount,
      increaseOrderBundleUpdateCount: state.increaseOrderBundleUpdateCount,
    }),
    shallow
  );

  const { returnRequirementCache, fetchProductReturnRequirement } = useFetchCacheStore(
    state => ({
      returnRequirementCache: state.returnRequirementCache,
      fetchProductReturnRequirement: state.fetchProductReturnRequirement,
    }),
    shallow
  );

  const increaseOrderUpdateCount = useCallback(() => {
    setOrderUpdateCount(prev => prev + 1);
    increaseOrderBundleUpdateCount();
  }, [increaseOrderBundleUpdateCount]);

  const getOrderItemSelectedTeeth = useCallback(
    (orderItemId: string): string[] => {
      const orderItem = order.orderItems.find(item => item.id === orderItemId);

      if (orderItem) {
        const toothStringAtt = orderItem.attributes.find(
          att => att.name === AttributeName.ToothString && att.type === AttributeType.ToothString
        );
        if (toothStringAtt && toothStringAtt.value) {
          return toothStringAtt.value.split(',').map(tooth => tooth.replace('#', ''));
        }
      }

      return [];
    },
    [order.orderItems]
  );

  /**
   * Handle updates to the order.
   * Updates only the keys passed in for newOrderData.
   */
  const patchOrder = useCallback(
    (newOrderData: Partial<LocalOrderInput>, isIgnoreUpdateCount?: boolean) => {
      let isOrderUnchanged = false;
      setOrder(prevState => {
        orderLocal = {
          ...prevState,
          ...newOrderData,
        };
        // If there is only one field being patched in the order (which is the case with most input fields), checks if the value has changed at all.
        // If the value has not changed, there is no need to count it as an order update.
        const patchedFields = Object.keys(newOrderData);
        if (patchedFields.length === 1) {
          const targetField = patchedFields[0] as keyof LocalOrderInput;
          const previousValue = prevState[targetField];
          const newValue = newOrderData[targetField];
          if (isEqual(previousValue, newValue)) {
            isOrderUnchanged = true;
          }
        }
        return {
          ...prevState,
          ...newOrderData,
        };
      });
      // We consider the order to be updated when all the order items in the order have the isLoaded flag set to true.
      // The isIgnoreUpdateCount flag will prevent the count from being updated.
      const isOrderUpdate =
        !isIgnoreUpdateCount &&
        orderLocal?.orderItems.length > 0 &&
        !orderLocal.orderItems.find(orderItem => !orderItem.isLoaded);
      if (isOrderUpdate && !isOrderUnchanged) {
        increaseOrderUpdateCount();
      }
    },
    [increaseOrderUpdateCount]
  );

  /**
   * Gets all classifications and sets both classifications and productClassifications (flat)
   */
  const setProductClassifications = useCallback((classifications: Classifications[]) => {
    setClassifications(classifications);

    const data: ClassificationProduct[] = [];
    classifications.forEach(classification => {
      classification.restorations.forEach(restoration => {
        data.push({
          productCode: restoration.productCode,
          materialName: classification.materialName,
          restorationName: restoration.restorationName,
        });
      });
    });
    setProducts(data);
  }, []);

  const isAddonsAndServicesValid = useCallback((orderItem: LocalOrderItemInput) => {
    /**
     * here we just need to check all addons has its value as we are combining the addOns =[...orderItem.addOns,orderItem.services]
     * so all added services and addons will be there inside orderItem.addons until the we call submitOrder from orderEntryContainer
     * and isValidateOrder is called before submitOrder so checking inside orderItem.addOns will validate all added services and addons
     */
    return filterEmptySpecialOrderPartsAddonsForTargetOrderItemAddOns(orderItem.addOns).every(addon => {
      return !!addon.value;
    });
  }, []);

  const isPonticValid = (orderItem: LocalOrderItemInput): boolean => {
    const isPonticValid = orderItem.attributes
      .filter(att => att.type === AttributeType.PonticDesign)
      .every(att => {
        return (!!att.value && !!att.name) || (!att.value && !att.name);
      });

    return isPonticValid;
  };

  /**
   * No validation around alloys for now.
   */
  const isAlloyValid = (orderItem: LocalOrderItemInput): boolean => {
    const isValid = orderItem.attributes
      .filter(att => att.type === AttributeType.Alloy)
      .every(() => {
        return true;
      });

    return isValid;
  };

  const isProductionFacilityValid = ({
    manufacturingLocation,
    daysInLab,
    productCode,
  }: LocalOrderItemInput): boolean => {
    const isProductionFacilityValid = !!manufacturingLocation;
    // Permits days in lab to be 0 for COD Returned Item Logistics, since there is no time in lab.
    const isCodReturnedItem = isProductCodReturnedItem(productCode);
    const isCodReturnedItemWithNoDaysInLab = isCodReturnedItem && daysInLab === 0;
    const daysInLabValid = !!daysInLab || isCodReturnedItemWithNoDaysInLab;
    return isProductionFacilityValid && daysInLabValid;
  };

  const isValidProductReturnRequired = useCallback(
    (orderItem: LocalOrderItemInput) => {
      if (!orderItem.returnType) return true; // If the returnType is not set, return true.

      // If the returnType is not in the PRODUCT_RETURN_REQUIRED_VALIDATION_RETURN_TYPES, then there is no need to check if the product return is required or not.
      if (!PRODUCT_RETURN_REQUIRED_VALIDATION_RETURN_TYPES.includes(orderItem.returnType)) return true;

      const productReturnRequirement = returnRequirementCache[orderItem.productCode];

      if (!productReturnRequirement) return true; // If the product return requirement is not fetched, return true.

      const isProductReturnRequired = productReturnRequirement.isReturnRequired ?? false;
      const isOldProductReturned = orderItem.isOldProductReturned ?? false;

      // If isProductReturnRequired is false, then there is no need to check if the old product is returned or not.
      if (!isProductReturnRequired) return true;

      const filteredCreditItems = filterRmaOrderItemsByReturnType(order.orderItems, ReturnType.ReturnForCredit);
      const filteredNonProductReturnedCreditItems = filterRmaOrderItemsByReturnType(
        order.orderItems,
        ReturnType.ReturnForCredit,
        false
      );
      const isSameProductReturnedLength = filteredCreditItems.length === filteredNonProductReturnedCreditItems.length;

      /**
       * If the length of the credit items and the credit items that are not returned are the same, then the product return required value is valid.
       */
      if (isSameProductReturnedLength) return true;

      // If isProductReturnRequired is true, then the old product must be returned.
      return isProductReturnRequired === isOldProductReturned;
    },
    [order.orderItems, returnRequirementCache]
  );

  /**
   * checks if RMA order has a valid return type
   *
   * @param orderItem - RMA order item to perform validation
   *
   * @returns whether given RMA order has valid return type
   */
  const isValidRmaOrderItemExchange = useCallback((orderItem: LocalOrderItemInput) => {
    const isExchange = orderItem.returnType === ReturnType.Exchange;
    // If the order item is not an Exchange, then there is no need to valid exchange order item. so return true.
    if (!isExchange) return true;
    // newProduct is required for exchange order item.
    return !!orderItem.newProduct;
  }, []);

  /**
   * checks if a given RMA order is valid or not
   *
   * @param orderItem - RMA order item to perform validations
   *
   * @returns whether given RMA order is valid
   */
  const isRMAOrderItemValid = useCallback(
    (orderItem: LocalOrderItemInput): boolean => {
      if (!isRmaLocalOrder(order)) return true;
      const isReturnTypeValid = !!orderItem.returnType;
      const isReturnReasonsValid = !!orderItem.returnReasons;
      const isOldProductReturnedValid = orderItem.isOldProductReturned !== undefined;

      const isValid = isReturnTypeValid && isReturnReasonsValid && isOldProductReturnedValid;

      const isValidExchange = isValidRmaOrderItemExchange(orderItem);
      const isValidProductReturnRequiredValue = isValidProductReturnRequired(orderItem);

      return isValid && isValidExchange && isValidProductReturnRequiredValue;
    },
    [isValidProductReturnRequired, isValidRmaOrderItemExchange, order]
  );

  /**
   * Gets associated material and restoration by product code
   *
   * @param productCode - The code of the product.
   * @returns associated material and restoration type
   */
  const getMaterialAndResorationType = (productCode: string) => {
    if (!classifications) return;

    return products.find(product => product.productCode === productCode);
  };

  /**
   * checks if a given local order item input is a satisfying all validations
   *
   * @param orderItem - local order item input to validate
   *
   * @returns whether the order item is valid
   */
  const checkOrderItemIsValid = (orderItem: LocalOrderItemInput): boolean => {
    return (
      !orderItem ||
      (isPonticValid(orderItem) &&
        hasValidQuantities(orderItem) &&
        isAddonsAndServicesValid(orderItem) &&
        hasValidAttributes(orderItem) &&
        hasValidImplantAttributes(orderItem) &&
        isProductionFacilityValid(orderItem) &&
        isRMAOrderItemValid(orderItem) &&
        isAlloyValid(orderItem) &&
        hasValidReturnTypes())
    );
  };

  /**
   * Filters empty special order parts addons from the target order item addons.
   * @param addOns - The target addons to filter.
   * @returns filtered empty special order parts addons.
   */
  const filterEmptySpecialOrderPartsAddonsForTargetOrderItemAddOns = (
    addOns: LocalOrderProductAttributeInput[]
  ): LocalOrderProductAttributeInput[] => {
    return addOns.filter(addon => {
      const isSpecialOrderParts = addon.name === SpecialOrderParts.Amount || addon.name === SpecialOrderParts.Notes;
      const value = addon.value;
      const isSpecialOrderPartsAddOnWithoutValue = (isSpecialOrderParts && !value) || false;
      return !isSpecialOrderPartsAddOnWithoutValue;
    });
  };

  /**
   * Filters the local order object so that any special order parts add-ons without values are removed, as per LMS1-4079.
   * @returns filtered local order object.
   */
  const filterEmptySpecialOrderPartsAddons = useCallback((orderData: LocalOrderInput): LocalOrderInput => {
    const localOrder = cloneDeep(orderData);
    localOrder.orderItems = localOrder.orderItems.map(item => {
      const localOrderItem = cloneDeep(item);
      localOrderItem.addOns = filterEmptySpecialOrderPartsAddonsForTargetOrderItemAddOns(localOrderItem.addOns);
      return localOrderItem;
    });
    return localOrder;
  }, []);

  /**
   * checks whether quantity equals sum of all Tier Pricing attributes
   *
   * for further information, refer https://glidewell.atlassian.net/browse/LMS1-6298
   */
  const hasValidQuantities = useCallback(
    (orderItem: LocalOrderItemInput) => {
      const quantityAttribute = orderItem.attributes.find(isQuantityAttribute);
      if (!quantityAttribute) return true;
      const tierPricingProductAttributes = tierPricingAttributes[orderItem.productCode] || [];
      if (!tierPricingProductAttributes.length) return true;
      return (
        Number(quantityAttribute.value) ===
        orderItem.attributes
          .filter(attribute => tierPricingProductAttributes.includes(attribute.name))
          .reduce((sum, attribute) => sum + Number(attribute.value), 0)
      );
    },
    [tierPricingAttributes]
  );

  /**
   * Common functions to validate each orderItem inside order
   */
  const hasValidAttributes = useCallback(
    (orderItem: LocalOrderItemInput) => {
      // At the moment, this approach only supported for aggregate attribute type
      const isInvalid = orderItem.attributes.some(attribute => attribute.isInvalid);
      if (isInvalid) return false; // if any attribute is invalid, return false

      const requiredProductAttributes = requiredAttributes[orderItem.productCode] || [];
      if (requiredProductAttributes.length > 0) {
        const hasNoTooth =
          orderItem.attributes.findIndex(att => att.name === AttributeName.NoTooth && att.value === 'Yes') > -1;
        return !orderItem.attributes.some(attribute => {
          if (hasNoTooth && attribute.name === AttributeName.ToothString && attribute.value === '') return false;

          // Checks to see if the current attribute is required.
          const isAttributeRequired = requiredProductAttributes.includes(attribute.name);
          // Validate both first and last tooth numbers are entered for bridge cases.
          if (
            attribute.name === AttributeName.ToothString &&
            isRestorationTypeBridge(orderItem.restorationType) &&
            attribute.value.split(',').length < 2 &&
            // ToothString attribute can be optional for some cases like  Biotemps Inlay/Onlay Bridge and Biotemps Bridge.
            isAttributeRequired
          ) {
            return true;
          }

          return isAttributeRequired && attribute.value === '';
        });
      }
      return true;
    },
    [requiredAttributes]
  );

  /**
   * Checks whether the product conforms valid return type combinations
   * for more info, refer https://glidewell.atlassian.net/browse/LMS1-6408
   *
   * Invalid return type combinations
   *
   * Exchange + Remake
   * Exchange + Adjust
   * Exchange + Return for Credit
   *
   * Return for Credit + Remake
   * Return for Credit + Adjust
   */
  const hasValidReturnTypes = useCallback(
    (orderItems: LocalOrderItemInput[] = order.orderItems) => {
      const returnTypes = orderItems.map(item => item.returnType);
      return (
        !hasMoreThanOneType(ReturnType.ReturnForCredit, returnTypes) &&
        !hasMoreThanOneType(ReturnType.Exchange, returnTypes)
      );
    },
    [order.orderItems]
  );

  const hasMoreThanOneType = (returnType: ReturnType, returnTypes: (ReturnType | undefined)[]) => {
    return returnTypes.includes(returnType) && returnTypes.some(item => item !== returnType);
  };

  /**
   * Check if there are any required implant attributes for a given order item and validate
   * that all teeth have an attribute set (business requirement).
   *
   * @param orderItem - the order item to validate.
   */
  const hasValidImplantAttributes = useCallback(
    (orderItem: LocalOrderItemInput) => {
      const itemHasRequiredImplants = requiredImplantItems.includes(orderItem.id);
      if (!itemHasRequiredImplants) return true;
      const selectedTeeth = getOrderItemSelectedTeeth(orderItem.id);

      // Every tooth is required to have an implant attribute set.
      return getSelectedToothRange(orderItem.addOns, selectedTeeth).every(tooth => {
        return orderItem.attributes.some(
          att => att.type === AttributeType.ImplantAttribute && att.value.replace('#', '') === tooth
        );
      });
    },
    [getOrderItemSelectedTeeth, requiredImplantItems]
  );

  /**
   * Validate order based on business rules.
   * @param alert - alert state to set modal.
   * @param isOrderUpdate - true/false if is order update.
   * @returns true/false if order is valid.
   */
  const isValidOrder = useCallback(
    (orderData: LocalOrderInput = order): OrderError | undefined => {
      // Filters the local order object so that any special order parts add-ons without values are removed, as per LMS1-4079.
      const localOrder: LocalOrderInput = filterEmptySpecialOrderPartsAddons(orderData);

      const isPatientInfoValid = () => {
        const patientFirstName = localOrder.patientFirstName?.trim();
        const patientLastName = localOrder.patientLastName?.trim();
        const patientId = localOrder.patientId?.trim();
        return noPatientInfoChecked || patientFirstName || patientLastName || patientId;
      };

      const isShippingValid = () => {
        return localOrder.inboundTrackingNumbers.length;
      };

      // TODO: Add Pontic to attributes
      const isAllAttributesValid = () => {
        return localOrder.orderItems.every(hasValidAttributes);
      };

      // Check if there are any required implant attributes and all of them have value in attribute.
      const isAllRequiredImplantsValid = () => {
        return localOrder.orderItems.every(hasValidImplantAttributes);
      };

      // Check to see if Addons have values
      const isAllAddonsAndServicesValid = () => {
        return localOrder.orderItems.every(orderItem => {
          return isAddonsAndServicesValid(orderItem);
        });
      };

      // Check to see that there are product(s)
      const productExists = () => {
        return localOrder.orderItems.length > 0;
      };

      // Check for required fields
      const isAllProductionFacilityValid = (): boolean => {
        const orderItems = localOrder.orderItems;
        const isProductionFacilityAndDaysInLabValid = orderItems.every(orderItem => {
          return isProductionFacilityValid(orderItem);
        });
        return isProductionFacilityAndDaysInLabValid;
      };

      const isAllPonticValid = (): boolean => {
        const orderItems = localOrder.orderItems;
        const isValid = orderItems.every(orderItem => {
          return isPonticValid(orderItem);
        });
        return isValid;
      };

      const isAlloyPresentAndValid = (): boolean => {
        const orderItems = localOrder.orderItems;
        const isValid = orderItems.every(orderItem => {
          return isAlloyValid(orderItem);
        });
        return isValid;
      };

      const isAllRMAFieldsValid = () => {
        const orderItems = localOrder.orderItems;
        return orderItems.every(isRMAOrderItemValid);
      };

      //Check all items in enclosedItems has itemCode and quantity
      const validateEnclosedItems = localOrder.enclosedItems.every(item => {
        return !!item.itemCode && !!item.quantity;
      });

      // Check if any required fields are missing
      if (
        !localOrder.billingAccountId?.trim().length ||
        !localOrder.providerId?.trim().length ||
        !localOrder.shippingAddress ||
        !isPatientInfoValid() ||
        !isShippingValid() ||
        !isAllAttributesValid() ||
        !isAllProductionFacilityValid() ||
        !isAllPonticValid() ||
        !productExists() ||
        !isAllAddonsAndServicesValid() ||
        !validateEnclosedItems ||
        !isAlloyPresentAndValid() ||
        !isAllRMAFieldsValid() ||
        !isAllRequiredImplantsValid()
      ) {
        return {
          title: 'Missing Required Fields',
          message: 'Please make sure all required fields are completed before submitting.',
        };
      }

      if (localOrder.externalOrderNumber && !localOrder.orderSource) {
        return {
          title: 'Missing Required Fields',
          message: 'If you are providing an external case ID, please also provide an order source.',
        };
      }

      if (!localOrder.externalOrderNumber && localOrder.orderSource) {
        return {
          title: 'Missing Required Fields',
          message: 'If you are providing an order source, please also provide an external case ID.',
        };
      }

      const hasInvalidTracking = (localOrder.inboundTrackingNumbers || []).some(trackingItem => {
        return !getCarrierName(trackingItem.trackingNumber);
      });

      if (!localOrder.orderItems.every(hasValidQuantities)) {
        return {
          title: 'Invalid Fields',
          message: 'Please make sure quantity matches tier pricing attributes.',
        };
      }

      // Check for invalid fields
      if (
        (!!localOrder.customerDueDate &&
          (!moment(localOrder.customerDueDate, dateFormat, true).isValid() ||
            localOrder.customerDueDate.length !== dateFormat.length)) ||
        hasInvalidTracking
      ) {
        return {
          title: 'Invalid Fields',
          message: 'Please make sure all required fields are valid before submitting.',
        };
      }

      if (!hasValidReturnTypes(localOrder.orderItems)) {
        return {
          title: ErrorMessage.INVALID_RETURN_TYPE_TITLE,
          message: ErrorMessage.INVALID_RETURN_TYPE_DESCRIPTION,
        };
      }

      // Please note that the file URL may already exist for an attachment, despite its upload status not being Uploaded yet.
      // Please see LMS1-5065.
      const filesUploaded = localOrder.fileAttachments.every(i => i.uploadStatus === FileUploadState.Uploaded);

      if (!filesUploaded) {
        return {
          title: 'File Upload Still In Progress',
          message: 'Please cancel or wait until all files finish uploading.',
        };
      }
    },
    [
      dateFormat,
      filterEmptySpecialOrderPartsAddons,
      hasValidAttributes,
      hasValidImplantAttributes,
      hasValidQuantities,
      hasValidReturnTypes,
      isAddonsAndServicesValid,
      isRMAOrderItemValid,
      noPatientInfoChecked,
      order,
    ]
  );

  /**
   * updates the order item using item id
   *
   * @param itemId - id of orderItem which needs to be updated
   * @param orderItem - payload to update the orderItem
   */
  const updateOrderItem: ActionsContext['updateOrderItem'] = useCallback((itemId, orderItem) => {
    setOrder(order => {
      const orderItemIndex = findIndex(order.orderItems, item => item.id === itemId);
      if (orderItemIndex < 0) return order;
      const newOrderItems = [...order.orderItems];
      newOrderItems[orderItemIndex] = { ...newOrderItems[orderItemIndex], ...orderItem };
      return {
        ...order,
        orderItems: newOrderItems,
      };
    });
  }, []);

  /**
   * Determines whether the patient information on the target case alerts matches the patient information currently entered on the order.
   *
   * @param caseAlert - The target case alert.
   * @param orderPatientInfo - The currently entered patient information on this order.
   *
   * @returns a boolean indicating whether the patient information on the target case alerts matches the patient information currently entered on the order.
   */
  const isCaseAlertPatientMatch = useCallback((caseAlert: CaseAlert, orderPatientInfo: PatientInformation) => {
    const patientFirstNameInitial = caseAlert.patientFirstName?.[0]?.toLowerCase() || '';
    const patientLastNameInitial = caseAlert.patientLastName?.[0]?.toLowerCase() || '';
    // The currently inputted first and last initials of the target patient.
    const isFirstNameInitialMatch =
      orderPatientInfo.patientFirstName?.toLowerCase().startsWith(patientFirstNameInitial) || false;
    const isLastNameInitialMatch =
      orderPatientInfo.patientLastName?.toLowerCase().startsWith(patientLastNameInitial) || false;
    return (
      ((!patientFirstNameInitial || !orderPatientInfo.patientFirstName || isFirstNameInitialMatch) &&
        (!patientLastNameInitial || !orderPatientInfo.patientLastName || isLastNameInitialMatch)) ||
      !!orderPatientInfo.noPatientInfo
    );
  }, []);

  /**
   * Resets case alerts to their initial statuses for alerts that are no longer possible options.
   * @param patientInfo - An object containing a field that was just changed on the order.
   */
  const resetCaseAlertSelections = useCallback(
    (patientInfo: PatientInformation) => {
      setCaseAlerts((oldCaseAlerts: LocalCaseAlert[]) => {
        return oldCaseAlerts.map(oldCaseAlert => {
          const oldCaseAlertClone = cloneDeep(oldCaseAlert);
          const isNonVisibleCaseAlert = !isCaseAlertPatientMatch(oldCaseAlert, {
            patientFirstName: patientInfo.patientFirstName || order.patientFirstName || '',
            patientLastName: patientInfo.patientLastName || order.patientLastName || '',
            patientId: patientInfo.patientId || order.patientId || '',
            noPatientInfo: patientInfo.noPatientInfo || noPatientInfoChecked,
          });
          if (oldCaseAlert.status !== CaseAlertStatus.Applied && isNonVisibleCaseAlert) {
            oldCaseAlertClone.status = oldCaseAlertClone.initialStatus;
          }
          return oldCaseAlertClone;
        });
      });
    },
    [isCaseAlertPatientMatch, noPatientInfoChecked, order.patientFirstName, order.patientId, order.patientLastName]
  );

  /**
   * determines if the order is a digitally created order
   */
  const isDigitalOrder = useMemo(() => {
    return isDigitalOrderFunc(getOrderType(order.externalOrderNumber, order.orderSource));
  }, [order.externalOrderNumber, order.orderSource]);

  /**
   * When order is updated, update the order in the bundle split case store.
   */
  useEffect(() => {
    if (!isBundleSplitCase || !orderId) return;
    const foundOrder = findOrder(orderId);
    if (foundOrder) {
      const updatedOrder = { ...foundOrder, ...order };
      const isInvalid = !!submitAlertCount && !!isValidOrder(updatedOrder);
      updateOrder({ ...updatedOrder, isInvalid });
    } else {
      addOrder(order);
    }
  }, [findOrder, isBundleSplitCase, order, orderId, updateOrder, addOrder, isValidOrder, submitAlertCount]);

  /**
   * Subscribe to the submit alert count to check if all orders are valid.
   * If so, publish to the topic to submit the bundle split case.
   * If not, show an alert.
   */
  useEffect(() => {
    if (!isBundleSplitCase || !orderId) return;

    let timeout: NodeJS.Timeout;
    const unsubscribe = useBundleSplitCaseStore.subscribe((state, prevState) => {
      if (state.submitAlertCount === prevState.submitAlertCount) return;

      clearTimeout(timeout); // clear timeout to prevent multiple publishes
      setSubmitAlertCount(state.submitAlertCount);
      const foundOrder = findOrder(orderId);
      if (!foundOrder) return;

      const submitError = isValidOrder(foundOrder);

      if (submitError) {
        const { title, message } = submitError;
        updateOrder({ ...foundOrder, isInvalid: true });
        setSelectedTab(foundOrder.id);
        return alert.show(title, message);
      }

      updateOrder({ ...foundOrder, isInvalid: false });

      // Timeout to prevent multiple publishes, Actually OrderModuleProvider is rendered for each tab when come to bundle split.
      // So, this subscribe is called for each and every tab. So, we need to prevent multiple publishes.
      timeout = setTimeout(() => {
        // Publish to topic to submit bundle split case. See TabHeader.tsx. This is a hacky way to do this.
        pubSub.publish(VALIDATE_BUNDLE_SPLIT_SUBMIT_TOPIC);
      });
    });

    return () => {
      unsubscribe();
    };
  }, [
    alert,
    findOrder,
    isBundleSplitCase,
    navigate,
    isValidAllOrders,
    isValidOrder,
    orderId,
    updateOrder,
    setSelectedTab,
  ]);

  /**
   * Fetch product return requirement for all order item product codes.
   */
  useEffect(() => {
    fetchProductReturnRequirement(order.orderItems);
  }, [order.orderItems, fetchProductReturnRequirement]);

  return (
    <>
      <OrderModuleContext.Provider
        value={{
          order,
          orderLoading,
          onSubmitAlertCount,
          noPatientInfoChecked,
          classifications,
          products,
          dateFormat,
          requiredAttributes,
          tierPricingAttributes,
          caseAlerts,
          orderStatus,
          isDigitalOrder,
          orderId: orderId || '',
          orderUpdateCount,
          technicalPreferencesByProductCode,
          selectedOrderItemId,
          selectedOrderItem,
          orderData,
          requiredImplantItems,
          originalOrderIsLegacy,
        }}
      >
        <OrderModuleActionsContext.Provider
          value={{
            setOrderLoading,
            setSubmitAlertCount,
            patchOrder,
            setNoPatientInfoChecked,
            setProductClassifications,
            getMaterialAndResorationType,
            hasValidQuantities,
            hasValidReturnTypes,
            isValidOrder,
            setDateFormat,
            setRequiredAttributes: handleSetRequiredAttributes,
            setTierPricingAttributes: handleSetTierPricingAttributes,
            getOrderItemSelectedTeeth,
            setCaseAlerts,
            resetCaseAlertSelections,
            updateOrderItem,
            checkOrderItemIsValid,
            setOrderStatus,
            isCaseAlertPatientMatch,
            increaseOrderUpdateCount,
            applyTechnicalPreferencesToTargetOrderItem,
            setTechnicalPreferencesByProductCode,
            setSelectedOrderItemId,
            setOrder,
            setOrderData,
            setRequiredImplantsItems,
            isOrderItemExchangeReturnType,
            setOriginalOrderIsLegacy,
            isValidProductReturnRequired,
          }}
        >
          <div className="flex flex-col min-w-0 flex-1 overflow-hidden bg-gray-50 relative">{children}</div>
        </OrderModuleActionsContext.Provider>
      </OrderModuleContext.Provider>
    </>
  );
};

/**
 * Custom hook that provides access to the OrderModuleContext and OrderModuleActionsContext.
 * Throws an error if used outside a OrderModuleProvider.
 *
 * @returns An object containing the context and action context.
 */
export const useOrderEntryContext = () => {
  const context = useContext(OrderModuleContext);
  const actionContext = useContext(OrderModuleActionsContext);
  if (!context || !actionContext) {
    throw new Error('useOrderContext must be used within a OrderModuleProvider');
  }
  return useMemo(() => ({ ...context, ...actionContext }), [context, actionContext]);
};
