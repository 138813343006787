import { ShippingOption } from 'API';
import RequiredMessage from 'components/common/RequiredMessage/RequiredMessage';
import { shippingCarrierLogo } from 'configurations/InvoiceConfiguration';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import React, { useEffect, useMemo } from 'react';
import { ShippingCarrier } from 'shared/enums';
import { CarrierListElement } from './CarrierListElement/CarrierListElement';
import { RequireSignatureCheckbox } from './RequireSignatureChekbox/RequireSignatureChekbox';
import { SaturdayDeliveryCheckbox } from './SaturdayDeliveryCheckbox/SaturdayDeliveryCheckbox';

/**
 * Props for the CarrierListsElement component.
 */
interface CarrierListsElementProps {
  /**
   * A record containing an array of ShippingOption objects grouped by carrier name.
   */
  groupByCarrierData: Record<string, ShippingOption[]>;
}

/**
 * Displays a list of carriers along with checkboxes for requiring signature and Saturday delivery.
 * Allows users to select their preferred carrier and configure required signature and Saturday delivery options.
 * @param groupByCarrierData - A record containing an array of ShippingOption objects grouped by carrier name.
 * @returns JSX element representing the CarrierListsElement component.
 */
export const CarrierListsElement: React.FC<CarrierListsElementProps> = ({ groupByCarrierData }) => {
  const { invoiceShippingInput, onSubmitAlertCount, setInvoiceShippingInput } = useInvoicingDetail();
  const {
    carrier: selectedCarrier,
    carrierPreference,
    isDoctorPickup,
    isSaturdayDelivery,
    isSignatureRequired,
    shippingService,
    trackingNumber,
  } = invoiceShippingInput;
  const carrierNames = useMemo(() => Object.keys(groupByCarrierData), [groupByCarrierData]);

  const shippingCarriers = useMemo(() => {
    const unAvailableCarriers: { logo: string; name: string }[] = [];

    // If the carrier is not in the shippingCarrierLogo, add it to the unAvailableCarriers.
    // This is to make sure that the all the available carriers is always listed in the UI if it is not in the shippingCarrierLogo
    carrierNames.forEach(carrierName => {
      if (!shippingCarrierLogo[carrierName]) {
        unAvailableCarriers.push({ logo: '', name: carrierName });
      }
    });

    // By doing this, we can make sure that the order of the carriers is the same as the order in the shippingCarrierLogo
    const filteredCarriers = Object.values(shippingCarrierLogo).filter(carrier => carrierNames.includes(carrier.name));

    return [...filteredCarriers, ...unAvailableCarriers];
  }, [carrierNames]);

  /**
   * Whenever the carrier is changed, we have to reset shippingService is empty string.
   * then only the shippingService will be set to the first service of the selected carrier. (See ShippingMethodElement.tsx component)
   * If the selected carrier is not available, then the first carrier will be selected.
   * If there are no carriers or if there is a tracking number entered, ignore updates
   */
  useEffect(() => {
    if (!shippingCarriers.length || trackingNumber) return;
    const carrier = shippingCarriers.find(data => data.name === selectedCarrier)
      ? selectedCarrier
      : shippingCarriers[0].name;
    setInvoiceShippingInput({
      carrier: carrier,
      shippingService: '',
      isDoctorPickup: carrier === ShippingCarrier.DoctorPickup,
    });
  }, [setInvoiceShippingInput, selectedCarrier, shippingCarriers, trackingNumber]);

  if (!shippingCarriers.length) return <div className="font-medium text-sm text-gray-700">No Carrier found</div>;

  const selectedCarrierData = groupByCarrierData[selectedCarrier] || [];

  /**
   * In order for the require signature checkbox to be enabled:
   * there must exist an option that matches the user's shipping service selection
   * there must exist an option that matches the user's Saturday delivery selection, if the user has it selected
   * there must exist an option with required signature as true
   */
  const allowRequireSignature = selectedCarrierData.some(
    carrier =>
      carrier.service === shippingService &&
      (!isSaturdayDelivery || carrier.isSaturdayDelivery === isSaturdayDelivery) &&
      carrier.requiresSignature
  );

  /**
   * In order for the Saturday delivery checkbox to be enabled:
   * there must exist an option that matches the user's shipping service selection
   * there must exist an option with Saturday delivery as true
   * there must exist an option that matches the user's require signature selection, if the user has it selected
   */
  const hasSaturdayDelivery = selectedCarrierData.some(
    carrier =>
      carrier.service === shippingService &&
      carrier.isSaturdayDelivery &&
      (!isSignatureRequired || carrier.requiresSignature === isSignatureRequired) // Signature required
  );

  return (
    <div className="flex flex-col gap-2 ">
      <div className="overflow-x-auto">
        <div className="grid grid-flow-col auto-cols-md">
          {shippingCarriers.map(carrier => (
            <CarrierListElement key={carrier.name} {...carrier} preferredCarrier={carrierPreference} />
          ))}
        </div>
      </div>
      {onSubmitAlertCount > 0 && !invoiceShippingInput.carrier && <RequiredMessage fieldName="Carrier" />}
      <RequireSignatureCheckbox disabled={isDoctorPickup || !allowRequireSignature} />
      <SaturdayDeliveryCheckbox disabled={isDoctorPickup || !hasSaturdayDelivery} />
    </div>
  );
};
