import { useEffect, useState } from 'react';

/**
 * The useDebounce hook is useful for delaying the execution of functions
 * or state updates until a specified time period has passed
 * without any further changes to the input value
 *
 * @param value - the value that needs to be debounced
 * @param delay - amount of time delay, after which latest value can be used
 */
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounce;
