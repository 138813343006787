import { ListTypeBox } from 'components/ListTypeBox/ListTypeBox';
import { ShippingServiceLabelKey, getShippingServiceLabel } from 'configurations/InvoiceConfiguration';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { FC, useEffect, useMemo } from 'react';
import { getSelectedShippingCharge } from 'shared/helpers/util.helper';

/**
 * Props for the InvoicedShippingDetail component.
 */
interface InvoicedShippingDetailProps {
  /**
   * Flag indicating whether the component is in a loading state.
   */
  isLoading: boolean;
}

/**
 * Component for displaying invoiced shipping details.
 *
 * @param isLoading - Flag indicating whether the component is in a loading state.
 */
export const InvoicedShippingDetail: FC<InvoicedShippingDetailProps> = ({ isLoading }) => {
  const { invoiceShippingInput, shipping, setInvoiceShippingInput, invoiceCases } = useInvoicingDetail();

  /**
   * Memoized shipping details for the invoiced case.
   * It returns an array of objects containing data to render Package Type, Carrier, Shipping method, Require Signature, and Saturday Delivery.
   */
  const invoicedShippingItems = useMemo(() => {
    const invoicedCase = invoiceCases[0];
    const outboundTrackingToUse = invoicedCase.shipping?.outboundTrackingNumbers?.[0];
    const items = [
      {
        label: 'Package Type',
        value: invoicedCase.shipping?.packageType || 'N/A',
      },
      {
        label: 'Carrier',
        value: outboundTrackingToUse?.carrier || 'N/A',
      },
      {
        label: 'Shipping Method',
        value: outboundTrackingToUse?.service
          ? getShippingServiceLabel(outboundTrackingToUse.service as ShippingServiceLabelKey)
          : 'N/A',
      },
      {
        label: 'Require Signature',
        value: invoiceShippingInput.isSignatureRequired ? 'Yes' : 'No',
      },
      {
        label: 'Saturday Delivery',
        value: invoiceShippingInput.isSaturdayDelivery ? 'Yes' : 'No',
      },
    ];
    return items;
  }, [invoiceCases, invoiceShippingInput.isSignatureRequired, invoiceShippingInput.isSaturdayDelivery]);

  // Needed so we can set require signature above. Eventually, require signature will be with tracking number item (outboundTrackingNumers) data.
  useEffect(() => {
    const selectedShippingCharge = getSelectedShippingCharge(shipping?.shippingCharges, shipping?.packageType);
    setInvoiceShippingInput(selectedShippingCharge);
  }, [shipping, setInvoiceShippingInput]);

  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium">2. Shipping</div>
      <div className="w-full">
        <ListTypeBox items={invoicedShippingItems} isLoading={isLoading} />
      </div>
    </div>
  );
};
