import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { CaseAlertStatus } from 'API';
import Loader from 'components/common/Loader/Loader';
import { cloneDeep } from 'lodash';
import { LocalCaseAlert, OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext } from 'react';
import { AlertDetails } from './AlertDetails';

/**
 * Represents details of an order account.
 */
export interface OrderAccountDetails {
  billingAccountId: string; // The ID of the billing account.
  patientFirstName: string; // The first name of the patient.
  patientLastName: string; // The last name of the patient.
  providerId: string; // The ID of the provider.
  noPatientInfo: boolean; // Indicates whether there is no patient information.
}

/**
 * Props for the AlertCard component.
 */
export interface AlertCardProps {
  loading: boolean; // Indicates whether the page is loading.
  filteredCaseAlerts: LocalCaseAlert[]; // List of filtered case alerts to display.
}

/**
 * Component responsible for displaying information about all of the alerts for the target account and provider IDs.
 * @param loading - Whether the page is loading.
 * @param filteredCaseAlerts - List of filtered case alerts to display.
 * @returns JSX Element
 */
export const AlertCard: React.FC<AlertCardProps> = ({ filteredCaseAlerts, loading }) => {
  const { caseAlerts } = useContext(OrderModuleContext);
  const { setCaseAlerts } = useContext(OrderModuleActionsContext);

  /**
   * Toggles selection of the target alert by setting its case status to either active or selected, based on its previous status.
   * @param caseAlert - The alert object that is being selected.
   */
  const selectAlert = (caseAlert: LocalCaseAlert) => {
    const targetCaseAlert = caseAlerts.findIndex(tCaseAlert => tCaseAlert.alertId === caseAlert.alertId);
    const updatedAlerts = cloneDeep(caseAlerts);

    const currentStatus = updatedAlerts[targetCaseAlert].status;
    updatedAlerts[targetCaseAlert].status =
      currentStatus === CaseAlertStatus.Active ? CaseAlertStatus.Applied : CaseAlertStatus.Active;
    setCaseAlerts(updatedAlerts);
  };

  return (
    <div className="text-sm text-indigo-700 font-normal min-h-full bg-indigo-50">
      <div className="border-b border-indigo-200 p-4">
        <div className="font-semibold text-lg text-gray-900 flex items-center my-2">
          <ExclamationTriangleIcon className="h-5 w-5 text-indigo-700 mr-2" aria-hidden="true" />
          <div className="text-indigo-900">Alerts</div>
        </div>
        <div>
          If there is a match below, click <span className="font-semibold">SELECT</span> and make any necessary updates
          to the case:
        </div>
      </div>
      {loading && (
        <div className="ml-10 mt-2">
          <Loader show={true} className="px-1 text-white" />
        </div>
      )}
      {filteredCaseAlerts.map(caseAlert => (
        <AlertDetails
          key={`caseAlert${caseAlert.alertId}`}
          caseAlert={caseAlert}
          selectAlert={selectAlert}
          isOpenByDefault={filteredCaseAlerts.length === 1}
        />
      ))}
    </div>
  );
};
