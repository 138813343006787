import { FC } from 'react';

/**
 * Component for displaying a badge indicating a process swap.
 * @returns JSX.Element representing the ProcessSwapBadge component.
 */
export const ProcessSwapBadge: FC = () => {
  return (
    <div className="pt-0.5">
      <span className="text-sm font-medium text-gray-800 bg-gray-100 rounded-full px-3 py-1">Process Swap</span>
    </div>
  );
};
