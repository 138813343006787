import { OrderProductAttributeInput } from 'API';
import Images from 'assets/images';
import Modal from 'components/common/Modal';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useEffect, useState } from 'react';
import { DiagramAttribute } from 'shared/models';
import { LOWER_TEETH, UPPER_TEETH } from 'shared/utils';
import ToothChartRange from '../ToothChartRange/ToothChartRange';
import styles from './ToothRange.module.css';

/**
 * Props for the ToothRange component.
 */
interface ToothRangeProps {
  /**
   * The attribute of the tooth range.
   */
  attribute: DiagramAttribute;
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * Callback function invoked when the attribute changes.
   */
  onAttributeChange: (attributeInput: OrderProductAttributeInput) => void;
}

/**
 * The ToothRange component allows users to select a range of teeth(Bridge types).
 * @param attribute - The attribute of the tooth range.
 * @param itemId - The ID of the item.
 * @param onAttributeChange - Callback function invoked when the attribute changes.
 * @returns JSX element representing the ToothRange component.
 */
const ToothRange: React.FC<ToothRangeProps> = ({ attribute, itemId, onAttributeChange }) => {
  const [showToothChart, setShowToothChart] = useState<boolean>(false);
  const upper: number[] = UPPER_TEETH;
  const lower: number[] = LOWER_TEETH;
  const [firstTooth, setFirstTooth] = useState<number>();
  const { onSubmitAlertCount } = useContext(OrderModuleContext);
  const { getOrderItemSelectedTeeth } = useContext(OrderModuleActionsContext);
  const [blockedTeeth, setBlockedTeeth] = useState<number[]>([]);
  const selectedTeeth = getOrderItemSelectedTeeth(itemId);
  let lastTooth = selectedTeeth.length > 1 ? selectedTeeth[selectedTeeth.length - 1] : '';

  useEffect(() => {
    if (selectedTeeth[0]) {
      setFirstTooth(+selectedTeeth[0]);
    }
  }, [selectedTeeth]);

  const toothChartSelectionHandler = (first: string, last: string) => {
    if (!first && !last) {
      setBlockedTeeth([]);
      setFirstTooth(undefined);
    } else {
      setFirstTooth(+first);
    }
    lastToothChangeHandler(first, last);
  };

  useEffect(() => {
    //block the respective teeth section on launch while editing
    if (selectedTeeth.length > 1) {
      const first = selectedTeeth[0];
      const noPoundFirstTooth = first ? +first.replace('#', '') : undefined;
      if (noPoundFirstTooth && noPoundFirstTooth < 17) {
        setBlockedTeeth(lower);
      } else {
        setBlockedTeeth(upper);
      }
    }
  }, [lower, upper, selectedTeeth]);

  const firstToothChangeHandler = (firstTooth: string) => {
    // Creating variable to number without # in front ex. '#7' => 7
    const noPoundFirstTooth = firstTooth ? +firstTooth.replace('#', '') : undefined;

    // Set state for first tooth
    setFirstTooth(noPoundFirstTooth);

    // If user reselects to no value then clear selected teeth, blocked teeth and last teeth
    if (!noPoundFirstTooth) {
      setBlockedTeeth([]);
      handleOnAttributeChange('');
      // If user chooses first tooth out of range of last tooth, clear the last tooth
    } else if (
      (lastTooth && +lastTooth < 17 && noPoundFirstTooth >= 17) ||
      (lastTooth && +lastTooth > 16 && noPoundFirstTooth <= 17) ||
      (lastTooth && +lastTooth <= noPoundFirstTooth)
    ) {
      handleOnAttributeChange('');
      // If user selects first tooth and last tooth but reselects a first tooth with the same range then update selected tooth state
    } else if (lastTooth && noPoundFirstTooth < +lastTooth) {
      const teeth: string[] = [];
      for (let i = noPoundFirstTooth; i <= +lastTooth; i++) {
        teeth.push(i.toString());
      }

      const teethArray: string[] = [];
      for (let i = +noPoundFirstTooth; i <= +lastTooth; i++) {
        teethArray.push(i.toString());
      }

      handleOnAttributeChange(teethArray.join(','));

      // If only first tooth in the lower jaw is selected
    } else if (noPoundFirstTooth < 17) {
      if (lastTooth && +lastTooth <= noPoundFirstTooth) {
        lastTooth = '';
      }
      setBlockedTeeth(lower);
      // If only first tooth in the upper jaw is selected
    } else {
      if (lastTooth && +lastTooth <= noPoundFirstTooth) {
        lastTooth = '';
      }
      setBlockedTeeth(upper);
    }
  };

  const lastToothChangeHandler = (first: string, last: string) => {
    const noPoundFirstTooth = first.replace('#', '');
    const noPoundLastTooth = last.replace('#', '');

    // setLastTooth(+noPoundLastTooth);
    const teeth: string[] = [];
    if (noPoundFirstTooth) {
      for (let i = +noPoundFirstTooth; i <= +noPoundLastTooth; i++) {
        teeth.push(i.toString());
      }
    }
    //ToothChartRange return first and last as same if we select only the first tooth or just one tooth
    //So added first === last to satisfy if user have entered only one tooth using the Modal
    handleOnAttributeChange(noPoundLastTooth === '' || first === last ? '' : teeth.join(','));
  };

  const handleOnAttributeChange = (teeth: string) => {
    onAttributeChange({
      name: attribute.name,
      type: attribute.type,
      value: teeth
        ? teeth
            .split(',')
            .map(value => `#${value}`)
            .join(',')
        : '',
      quantity: 1,
    });
  };

  /**
   * Sorts and returns first tooth options
   * @returns string[]
   */
  const firstToothOptions = (): string[] => {
    return upper
      .concat(lower)
      .sort((a, b) => a - b)
      .map(tooth => `#${tooth}`);
  };

  /**
   * Sorts and returns last tooth options bases on which first tooth is selected
   * @returns string[]
   */
  const lastToothOptions = (): string[] => {
    if (firstTooth && firstTooth < 17) {
      return upper
        .filter(tooth => tooth > firstTooth && tooth !== firstTooth)
        .sort((a, b) => a - b)
        .map(tooth => `#${tooth}`);
    } else if (firstTooth && firstTooth >= 17) {
      return lower
        .filter(tooth => tooth > firstTooth && tooth !== firstTooth)
        .sort((a, b) => a - b)
        .map(tooth => `#${tooth}`);
    } else {
      return firstToothOptions();
    }
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-x-6 gap-y-4">
        <div className="relative">
          <SearchableDropdown
            options={firstToothOptions()}
            selected={firstTooth ? `#${firstTooth}` : ''}
            onSelectedChange={selected => firstToothChangeHandler(selected)}
            id="firstTooth"
            dataQa="firstTooth"
            label="First Tooth"
            onSubmitAlert={onSubmitAlertCount > 0}
            placeholder="Search"
            isRequired={attribute.isRequired}
          />
          <span className={`${styles['dash-spacing']} absolute`}>-</span>
        </div>
        <div>
          <SearchableDropdown
            options={lastToothOptions()}
            selected={lastTooth ? `#${lastTooth}` : ''}
            onSelectedChange={selected => lastToothChangeHandler(firstTooth?.toString() ?? '', selected)}
            id="lastTooth"
            dataQa="lastTooth"
            label="Last Tooth"
            disabled={firstTooth === undefined}
            onSubmitAlert={onSubmitAlertCount > 0}
            placeholder="Search"
            isRequired={attribute.isRequired}
          />
        </div>
        <div className="pt-6">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowToothChart(true)}
            data-qa="openToothChartButton"
          >
            <img src={Images.Shape} alt="Tooth" className="h-4 w-4 mr-3" />
            Open Tooth Chart
          </button>
        </div>
      </div>
      {showToothChart && (
        <Modal onClose={() => setShowToothChart(false)}>
          <ToothChartRange
            itemId={itemId}
            firstToothHandler={firstToothChangeHandler}
            onSelect={(first, last) => toothChartSelectionHandler(first, last)}
            blocked={blockedTeeth}
            selected={selectedTeeth}
          />
        </Modal>
      )}
    </>
  );
};

export default ToothRange;
