import { PreSignedUploadUrlQuery, PreSignedUploadUrlQueryVariables } from 'API';
import { preSignedUploadUrl } from 'graphql/queries';
import Http from './http';

/**
 * generates a pre signed url from list of file names
 * @param fileList - list of files
 * @returns a list of pre signed urls
 */
export const getPresignedUploadUrls = async (fileList: File[]) => {
  const filenames: Array<string> = [];

  // pull out only the filenames
  fileList.forEach(file => {
    // As per LMS1-7128, encodes the file name to ensure special characters (i.e. #, +) are supported in the file path.
    filenames.push(encodeURIComponent(file.name));
  });

  // set up the graphql request
  const files: PreSignedUploadUrlQueryVariables = {
    files: {
      fileNames: filenames,
      category: 'caseCreation',
      createdBy: 'LMS',
    },
  };

  // call graphql api and parse results
  const response = await Http.handleGraphqlOperation<PreSignedUploadUrlQuery>(preSignedUploadUrl, files);
  const result = Http.processGraphqlResponse(response.preSignedUploadUrl, 'PreSignedUrlWrapper');

  return result.preSignedUrls;
};
