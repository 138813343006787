import { OrderProductAttribute } from 'API';
import { SkeletonBorder } from 'components/common/Skeleton';
import { FC, useCallback, useMemo } from 'react';
import { AttributeName, AttributeType } from 'shared/enums';
import { getCaseProductTitle } from 'shared/helpers/invoice/invoice.helper';
import { getProductUnits } from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrderItem } from 'shared/models';
import ConfigService from 'shared/services/config.service';
import { convertToCamelCase, convertToCurrency } from 'shared/utils';
import ProductDetailHeader from './ProductDetailHeader/ProductDetailHeader';

/**
 * Props interface for the ProductDetail component.
 */
interface ProductDetailProps {
  /**
   * Flag indicating whether the component is in loading state.
   */
  isLoading?: boolean;
  /**
   * The product information.
   */
  product: CreatedOrderItem;
  /**
   * The count of products.
   */
  count: number;
}

const quantityAttributeNames: string[] = [AttributeName.AdditionalTooth];

const ProductAttributeItem: FC<OrderProductAttribute> = ({ name, value }) => {
  if (quantityAttributeNames.includes(name)) {
    return (
      <>
        <span className="text-gray-500 text-sm">{name} #:</span> Qty: {value}
      </>
    );
  }
  return (
    <>
      {name}: {value}
    </>
  );
};

/**
 * Component for rendering product details.
 * @param isLoading - Flag indicating whether the component is in loading state.
 * @param product - The product information.
 * @param count - The count of products.
 * @returns JSX element representing the component.
 */
const ProductDetail: React.FC<ProductDetailProps> = ({ product, isLoading: invoicingLoading }) => {
  const productionFacility = product?.manufacturingLocation;

  // Retrieves supported attribute types to display.
  const supportedAttributeTypes: string[] = JSON.parse(
    ConfigService.getConfigValue('Settings', 'InvoicingSupportedAttributeTypes') || '[]'
  );

  /**
   * Filters the attributes of a product based on AC to render attribute items
   * @returns An array of filtered and transformed order product attributes.
   */
  const filteredAttributes = useMemo(
    () =>
      product.attributes
        .filter(attribute => attribute.value?.length)
        .filter(
          attribute =>
            (attribute.type === AttributeType.ToothString &&
              (attribute.name === AttributeType.MissingTooth || attribute.name === AttributeName.NoTooth)) ||
            supportedAttributeTypes.includes(attribute.type) ||
            attribute.name === AttributeType.CADPRESS
        )
        .filter(attribute => attribute.name !== AttributeName.BodyShade)
        .map(item => {
          // Transforming attribute value as per CADPRESS AC.
          const attributeValue = item.name === AttributeType.CADPRESS ? item.value + 'x' + item.quantity : item.value;
          return {
            ...item,
            value: attributeValue,
          };
        }),
    [product.attributes, supportedAttributeTypes]
  );

  /**
   * Filters add-ons or services based on pricing status.
   * function to render the addOns or services according to its price status, The priced attributes are shown below with its unit price.
   * The attributes without price are shown below the Product title along with department details
   * @param attributeType - The type of item to filter ('addOns' or 'services').
   * @param hasPrice - The pricing status to filter by.
   * @returns The filtered list of add-ons or services.
   */
  const getFilteredAddonsAndServices = useCallback(
    (attributeType: 'addOns' | 'services', hasPrice: boolean) => {
      // Determine the attribute type based on the input type
      const attType = attributeType === 'addOns' ? AttributeType.AddOn : AttributeType.Service;

      // Filter the product's add-ons or services based on type and pricing status
      return product[attributeType].filter(
        attribute => attribute.type === attType && !!attribute.unitPrice === hasPrice
      );
    },
    [product]
  );

  const renderProductAttribute = (label: string, value: string, qaAttribute: string) => {
    return (
      <div key={`${label}-${value}`} className="inline border-r border-gray-300 pr-1 mr-1 last:border-r-0">
        <div className="text-sm text-gray-500 inline" data-testid={label}>
          {label}:
        </div>
        <div className="ml-0.5 text-sm text-gray-900 inline" data-qa={qaAttribute}>
          {value}
        </div>
      </div>
    );
  };

  const totalQuantity = useMemo(() => {
    const { totalQuantity } = getProductUnits(product);
    return totalQuantity;
  }, [product]);

  /**
   * Renders priced items.
   *
   * @param item- OrderProductAttribute The item to render.
   * @returns The JSX element representing the rendered priced items.
   */
  const renderPricedAttribute = (item: OrderProductAttribute) => (
    <div key={item.name} data-testid={item.name} className="flex mt-2">
      <div className="flex-grow text-sm text-gray-900 inline border-b border-gray-200 py-2">
        <ProductAttributeItem {...item} />
      </div>
      <div
        className="flex-none w-12 text-center text-sm border-b border-gray-200 py-2"
        data-qa={convertToCamelCase(item.name, 'Label')}
      >
        {!quantityAttributeNames.includes(item.name) && item.quantity}
      </div>
      <div className="flex-none w-40 text-right text-sm border-b border-gray-200 py-2">
        {convertToCurrency(+(item.unitPrice || 0))}
      </div>
    </div>
  );

  return (
    <>
      <div className="flex-col p-4 border-b last:border-b-0">
        <ProductDetailHeader
          description={getCaseProductTitle(product)}
          quantity={totalQuantity}
          unitPrice={product.unitPrice || 0}
          isLoading={invoicingLoading}
        />
        <div className="flex">
          <div className="flex-grow" data-qa="productAttributesLabel" data-dataid="productAttributesLabel">
            {product.department && renderProductAttribute('Department', product.department, 'productDepartmentLabel')}
            {productionFacility &&
              renderProductAttribute('Production Facility', productionFacility, 'productProductionFacilityLabel')}
            {invoicingLoading ? (
              <SkeletonBorder className="h-6 w-11/12" />
            ) : (
              getFilteredAddonsAndServices('services', false).map(service =>
                renderProductAttribute(service.name, service.value, convertToCamelCase(service.name, 'Label'))
              )
            )}
            {invoicingLoading ? (
              <SkeletonBorder className="h-6 w-11/12" />
            ) : (
              getFilteredAddonsAndServices('addOns', false).map(addon =>
                renderProductAttribute(addon.name, addon.value, convertToCamelCase(addon.name, 'Label'))
              )
            )}
            {invoicingLoading ? (
              <SkeletonBorder className="h-6 w-11/12" />
            ) : (
              product.attributes.some(a => a.name === AttributeName.MissingTooth) &&
              product.attributes
                .filter(a => a.name === AttributeName.MissingTooth)
                .map(attribute =>
                  renderProductAttribute(attribute.name, attribute.value, convertToCamelCase(attribute.name, 'Label'))
                )
            )}
            {invoicingLoading ? (
              <SkeletonBorder className="h-6 w-11/12" />
            ) : (
              // Attributes with unit price are shown separately below, As per the AC we don't have to show it twice (here and priced item list below).
              filteredAttributes
                .filter(attribute => !attribute.unitPrice)
                .map(attribute => {
                  return renderProductAttribute(
                    attribute.name,
                    attribute.value,
                    convertToCamelCase(attribute.name, 'Label')
                  );
                })
            )}
          </div>
          <div className="flex-none w-12"></div>
          <div className="flex-none w-40"></div>
        </div>
        <div className="ml-4">
          {product.attributes
            .filter(attribute => attribute.type === AttributeType.Alloy)
            .map(({ name: attributeName, value: attributeValue }) => (
              <div key={attributeName} className="flex my-2 border-b border-gray-200 py-2">
                <div
                  className="flex-grow text-gray-900 text-sm"
                  data-qa="productDescriptionLabel"
                  data-testid="alloyAttributeDescriptionLabel"
                >
                  {attributeName}
                </div>
                <div
                  className=" flex-none  w-48 text-left text-sm"
                  data-qa="productQuantityLabel"
                  data-testid="alloyAttributeQuantityLabel"
                >
                  {attributeValue && attributeValue !== '0' ? `${attributeValue} dwt` : 'Missing'}
                </div>
                <hr className="h-px my-0.5 bg-gray-200 border-0 dark:bg-gray-700"></hr>
              </div>
            ))}
          {
            // Show all priced attributes.
            filteredAttributes
              .filter(attribute => attribute.unitPrice)
              .map(attribute => renderPricedAttribute(attribute))
          }
          {getFilteredAddonsAndServices('addOns', true).map(addOn => renderPricedAttribute(addOn))}
          {getFilteredAddonsAndServices('services', true).map(service => renderPricedAttribute(service))}
        </div>
        <div className="mt-2 flex flex-row-reverse">
          <div className="text-sm text-gray-900" data-qa="productTotalLabel">
            {!invoicingLoading && convertToCurrency(product.subtotalAmount ?? 0)}
          </div>
          <div>{invoicingLoading && <SkeletonBorder className="h-6 w-20" />}</div>
          <div className="uppercase text-sm text-gray-500 mr-8">Total:</div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
