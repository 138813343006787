import { OrderSearchInputFields, OrderSearchSortColumn, OrderStatus, SortOrder } from 'API';
import { FC, useEffect, useState } from 'react';
import { getSortableOrder } from 'shared/helpers/util.helper';
import { SearchFilterFacet } from 'types/common';
import { AutoSearchInput } from './AutoSearchInput/AutoSearchInput';
import { SearchFilter } from './SearchFilter/SearchFilter';
import { SearchTable } from './SearchTable/SearchTable';

/**
 * @param searchValue -
 * @param onChangeSearchValue -
 */
interface SearchDetailPageProps {
  /**
   * search value submitted, used to fetch search results
   */
  searchValue: string;

  /**
   * a callback to notify parent component, if search value is changed
   * @param value - new search query
   */
  onChangeSearchValue: (value: string) => void;
}

/**
 * Renders a page listing search results for submitted search value
 * search results can be narrowed using various filters available like case status, case, account etc.,
 *
 * @param searchValue - submitted search value
 * @param onChangeSearchValue - callback triggered if search value is changed here
 */
export const SearchDetailPage: FC<SearchDetailPageProps> = ({ searchValue, onChangeSearchValue }) => {
  const [type, setType] = useState<OrderSearchInputFields[]>([]);
  const [status, setStatus] = useState<OrderStatus[]>([]);
  const [sort, setSort] = useState<OrderSearchSortColumn>();
  const [order, setOrder] = useState<SortOrder>();
  const [page, setPage] = useState(1);

  /**
   * function to handle change in Case status filters
   *
   * @param option - represents list of selected case status
   */
  const onStatusChange = (option: string[]) => {
    // take the existing options
    const statusFilters = [...status];

    // loop through our new options
    option.forEach(item => {
      //convert string to order status
      const status = OrderStatus[item as keyof typeof OrderStatus];

      //select or deselect the options
      if (statusFilters.indexOf(status) === -1) {
        statusFilters.push(status);
      } else {
        statusFilters.splice(statusFilters.indexOf(status), 1);
      }
    });

    setStatus(statusFilters);
  };

  /**
   * function to handle change in Case type [filters]{@link OrderSearchInputFields}
   * @param option - represents filter type
   */
  const onTypeChange = (option: OrderSearchInputFields) => {
    const typeFilters = [...type];
    if (typeFilters.indexOf(option) === -1) {
      typeFilters.push(option);
    } else {
      typeFilters.splice(typeFilters.indexOf(option), 1);
    }

    setType(typeFilters);
  };

  /**
   * handles filters changes
   *
   * @param facetId - represents if filter change is of caseStatus or type
   * @param option - represents changed filter option
   */
  const onFilterChange = (facetId: SearchFilterFacet['id'], option: SearchFilterFacet['options'][number]) => {
    //must handle an array or single value
    const value = Array.isArray(option.value) ? [...option.value] : [option.value];

    if (facetId === 'caseStatus') {
      return onStatusChange(value);
    }

    if (facetId === 'type') {
      return onTypeChange(OrderSearchInputFields[option.value as keyof typeof OrderSearchInputFields]);
    }
  };

  /**
   * Sorts the filter results table by column either ascending or descending
   *
   * @param column - represents order by query in form of columnId-(asc|desc), eg:- patientName-desc
   */
  const handleOrderBy = (column: string) => {
    // the table JS sends us -column or column depending on sort order
    const { id: columnId, order } = getSortableOrder(column);
    setOrder(order === 'asc' ? SortOrder.Asc : SortOrder.Desc); // Generally up arrow is asc, down arrow is desc

    // map column id to sort API sort column
    switch (columnId) {
      case 'patientName':
        setSort(OrderSearchSortColumn.PatientName);
        break;
      case 'providerName':
        setSort(OrderSearchSortColumn.ProviderName);
        break;
      case 'labReceivedDate':
        setSort(OrderSearchSortColumn.ReceivedDate);
        break;
      case 'invoiceDate':
        setSort(OrderSearchSortColumn.InvoicedDate);
        break;
    }
  };

  /**
   * resets applied filters
   */
  const onClearHandler = () => {
    setStatus([]);
    setType([]);
    setSort(undefined);
    setOrder(undefined);
  };

  /**
   * resets {@link page} to 1, if search value or filters are changed
   */
  useEffect(() => {
    setPage(1);
  }, [searchValue, status, type, setPage]);

  return (
    <div className="flex gap-4 flex-col p-4 bg-white flex-grow">
      <AutoSearchInput
        placeholder="Search by case, account, or patient"
        searchValue={searchValue}
        setSearchValue={onChangeSearchValue}
        className="py-4 w-80"
      />
      <div className="flex gap-4">
        <div className="w-56 flex-shrink-0">
          <SearchFilter onFilterChange={onFilterChange} onClear={onClearHandler} />
        </div>
        <div className="flex-grow overflow-x-auto">
          <SearchTable
            term={searchValue}
            page={page}
            setPage={setPage}
            setSortBy={handleOrderBy}
            type={type}
            status={status}
            sort={sort}
            order={order}
          />
        </div>
      </div>
    </div>
  );
};
