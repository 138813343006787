import { ListTypeBox } from 'components/ListTypeBox/ListTypeBox';
import { DisplayDiscountItems } from 'components/SpecialDiscount/DisplayDiscountItems/DisplayDiscountItems';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { FC, useMemo } from 'react';
import { getDiscountCouponItem } from 'shared/helpers/invoice/invoice.helper';
import { DisplayDiscountItemType } from 'types/common';
import { OtherDiscountSection } from './OtherDiscountSection';

/**
 * Props interface for the InvoicedDiscountSection component.
 * It includes a boolean flag indicating whether the component is in a loading state.
 */
interface InvoicedDiscountSectionProps {
  isLoading: boolean;
}

/**
 * Component responsible for displaying invoiced discount details.
 * It renders a list of discount items, including coupons and other discounts, for invoiced cases.
 * @param isLoading - Indicates whether the component is in a loading state or ready to render.
 * @returns A JSX element representing the InvoicedDiscountSection component.
 */
export const InvoicedDiscountSection: FC<InvoicedDiscountSectionProps> = ({ isLoading }) => {
  const { invoiceCases } = useInvoicingDetail();

  /**
   * Memoized calculation of discount items for invoiced cases.
   * It parses applied coupons and other discounts from each invoice case and constructs an array of discount items.
   */
  const invoicedDiscountItems = useMemo(() => {
    // Parses the coupons applied to the case and transforms them into discount coupon items.
    const coupons: DisplayDiscountItemType[] = invoiceCases.flatMap(({ appliedCoupons, orderNumber }) => {
      return appliedCoupons?.map(coupon => getDiscountCouponItem(coupon, orderNumber)) || [];
    });
    const items = [
      {
        label: 'Coupons',
        value: <DisplayDiscountItems items={coupons} />,
      },
      {
        label: 'Other Discounts',
        value: <OtherDiscountSection invoiceCases={invoiceCases} />,
      },
    ];
    return items;
  }, [invoiceCases]);

  return (
    <div className="flex flex-col gap-2">
      <div className="font-medium">3. Discount</div>
      <div className="w-full">
        <ListTypeBox items={invoicedDiscountItems} isLoading={isLoading} />
      </div>
    </div>
  );
};
