import { DoNotShip } from 'API';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useEffect, useState } from 'react';
import { DO_NOT_SHIP_PREFERENCE_ENUM } from 'shared/enums';
import { DoNotShipElementModal } from './DoNotShipElementModal/DoNotShipElementModal';

/**
 * Props for the DoNotShipElement component.
 */
interface DoNotShipElementProps {
  /** The message indicating the do not ship condition. */
  doNotShipMessage: string | undefined;
  /** The current date and time within the do not ship condition. */
  currentDateTimeWithinDns: DoNotShip | undefined;
}

/**
 * Displays a modal element for handling do not ship conditions.
 * @param doNotShipMessage - The message indicating the do not ship condition.
 * @param currentDateTimeWithinDns - The current date and time within the do not ship condition.
 * @returns The rendered DoNotShipElement component.
 */
export const DoNotShipElement: React.FC<DoNotShipElementProps> = ({ doNotShipMessage, currentDateTimeWithinDns }) => {
  const [showModal, setShowModal] = useState(false);
  const { setInvoiceShippingInput } = useInvoicingDetail();

  useEffect(() => {
    // if there is no end date
    if (!currentDateTimeWithinDns?.end) {
      setInvoiceShippingInput({ doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM.PERMANENT });
      setShowModal(true);
      // if the current date is between start and end date
    } else if (currentDateTimeWithinDns) {
      setInvoiceShippingInput({ doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM.TEMPORARY });
      setShowModal(true);
    } else {
      // if no conditions are met, we want to prevent modal from showing
      setInvoiceShippingInput({ doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM.NONE });
    }
  }, [currentDateTimeWithinDns, setInvoiceShippingInput]);

  const onCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex flex-col" data-testid="donNotShipElement">
      {showModal && doNotShipMessage && (
        <DoNotShipElementModal doNotShipMessage={doNotShipMessage} onCloseModal={onCloseModal} />
      )}
    </div>
  );
};
