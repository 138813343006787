import { FC } from 'react';
import { CaseHistoryAdditionalDetailsObject, LocalCaseHistoryItem } from 'shared/constants/case-detail.constants';
import { ShippingTrackingNumbers } from '../ShippingTrackingNumbers';
import { AdditionalDetailsShippingFallback } from './AdditionalDetailsShippingFallback';

/**
 * Props of the AdditionalDetailsShipping component.
 */
export interface AdditionalDetailsShippingProps {
  /**
   * The old additional shipping details.
   */
  oldData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The new additional shipping details.
   */
  newData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The timezone code for UTC conversion.
   */
  utcConversionTimeZoneCode: LocalCaseHistoryItem['utcConversionTimeZoneCode'];
}

/**
 * Component for displaying additional shipping details.
 * @param oldData - The old additional shipping details.
 * @param newData - The new additional shipping details.
 * @param utcConversionTimeZoneCode - The timezone code for UTC conversion.
 * @returns JSX element representing the AdditionalDetailsShipping component.
 */
export const AdditionalDetailsShipping: FC<AdditionalDetailsShippingProps> = ({
  oldData,
  newData,
  utcConversionTimeZoneCode,
}) => {
  const oldShipping = oldData?.Shipping;
  const newShipping = newData?.Shipping;
  if (!oldShipping && !newShipping) return null;
  return (
    <>
      <ShippingTrackingNumbers
        name="Inbound Tracking Numbers"
        oldTrackingNumbers={oldShipping?.InboundTrackingNumbers}
        newTrackingNumbers={newShipping?.InboundTrackingNumbers}
      />

      <ShippingTrackingNumbers
        name="Outbound Tracking Numbers"
        oldTrackingNumbers={oldShipping?.OutboundTrackingNumbers}
        newTrackingNumbers={newShipping?.OutboundTrackingNumbers}
      />
      <AdditionalDetailsShippingFallback
        oldData={oldShipping}
        newData={newShipping}
        excludeKeys={['InboundTrackingNumbers', 'OutboundTrackingNumbers']}
        utcConversionTimeZoneCode={utcConversionTimeZoneCode}
      />
    </>
  );
};
