import { MsalProviderProps, useMsal } from '@azure/msal-react';
import { CustomNavigationClient } from 'components/CustomNavigationClient/CustomNavigationClient';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 *  This component is optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
 */
export const ClientSideNavigation: React.FC<MsalProviderProps> = ({ children, instance }) => {
  const { instance: msalInstance } = useMsal();
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  instance.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    const currentActiveAccount = msalInstance.getActiveAccount();

    // Default to using the first account if no account is active on page load
    if (!currentActiveAccount && accounts.length) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(accounts[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    setFirstRender(false);
  }, [msalInstance]);

  if (firstRender) return null;

  return <>{children}</>;
};
