/**
 * represents various levels of permissions available
 */
export enum PERMISSION_ENUM {
  ALL = 'ALL',
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  VISIBLE = 'VISIBLE',
}

/**
 * represents various actions that can be performed using LMS
 */
export enum ACTIONS {
  UNINVOICE,
  RESTORE_CANCELED_CASE,
  RESTORE_DELETED_CASE,
  EDIT_ALLOY_TYPE,
  EDIT_ALLOY_WEIGHT,
  INVOICE_CASE,
  CANCEL_CASE,
  CREATE_CASE,
  EDIT_CASE,
  DELETE_CASE,
  PRINT_WORKORDER_CASEPAN,
  PRINT_WORKORDER,
  APPLY_DISCOUNT,
  APPLY_CREDIT,
  EDIT_CASE_STATUS,
  EDIT_RETURN_TYPE,
}

/**
 * represents role of user using LMS
 *
 * Mapped string value is what we expect from Active Directory.
 */
export enum ROLE_ENUM {
  ACCOUNTING = 'Accounting',
  ADMIN = 'Admin',
  CSR = 'CSR',
  CSR_MANAGER = 'CSRManager',
  DISTRIBUTION = 'ProductionDistributor',
  EVALUATION = 'ProductionEvaluation',
  INTERNAL_LOGISTICS_TEAM_LEAD = 'InternalLogisticsTeamLead',
  SPECIALIST_TEAM = 'LMS:SpecialistTeam',
  LMS_TEAM_LEAD = 'LMS:TeamLead',
  HOTDESK = 'HOTDESK', // May not be used. No mapping in AD.
  METAL_ENTRY = 'MetalEntry',
  ORDER_PROCESSOR_1 = 'OrderProcessor',
  ORDER_PROCESSOR_2 = 'OrderProcessor2',
  PRODUCTION_ADMIN = 'ProductionAdmin',
  PRODUCTION_MANAGER = 'Manager',
  PRODUCTION_QC = 'ProductionQC',
  PRODUCTION_SCHEDULER = 'ProductionScheduler',
  PRODUCTION_TECHNITIAN = 'Technician',
  PRODUCTION_TEAM_LEAD = 'ProductionTeamLead',
  SHIPPING_MANAGER = 'ShippingManager',
  SHIPPING_SPECIALIST = 'SpecialistTeam',
  SHIPPING_TEAM_LEAD = 'ShippingTeamLead',
  TEAM_LEAD = 'TeamLead',
  TECHNICAL_ADVISOR = 'TechnicalAdvisor',
}
