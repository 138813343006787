import { CubeIcon, DocumentTextIcon, PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { uploadFile } from 'shared/api/upload.api';
import { FileType, FileUploadState } from 'shared/enums';
import { LocalOrderFileAttachments } from 'shared/models';
import { getFileType } from 'shared/utils';

/**
 * Props for the FileCell component.
 */
interface FileCellProps {
  /**
   * Key for identifying the file cell.
   */
  key: number;
  /**
   * The file attachment data.
   */
  fileAttachment: LocalOrderFileAttachments;
  /**
   * Callback function to upload a file.
   */
  onUploadFile: (uuid: string) => void;
  /**
   * Callback function to remove a file.
   */
  onRemoveFile: (uuid: string) => void;
}

/**
 * The FileCell component represents a single file attachment cell.
 * @param fileAttachment - The file attachment data.
 * @param onUploadFile - Callback function to upload a file.
 * @param onRemoveFile - Callback function to remove a file.
 * @returns JSX element representing the FileCell component.
 */
const FileCell: React.FC<FileCellProps> = ({ fileAttachment, onRemoveFile, onUploadFile }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [abortController] = useState<AbortController>(new AbortController());

  // handle file uploading
  useEffect(() => {
    const saveFile = async (file: File, uploadUrl: string, abortController: AbortController) => {
      try {
        // do the upload
        await uploadFile(file, uploadUrl, uploadProgressEvent, abortController);
        onUploadFile(fileAttachment.id);
      } catch (error) {
        fileAttachment.uploadStatus = FileUploadState.Error;
      }
    };

    if (fileAttachment.uploadProperties.filePath && fileAttachment.uploadStatus === FileUploadState.Waiting) {
      // handle file save
      if (fileAttachment.file) {
        fileAttachment.uploadStatus = FileUploadState.Uploading;
        saveFile(fileAttachment.file, fileAttachment.uploadProperties.filePath, abortController);
      }
    }
  }, [abortController, fileAttachment, onUploadFile]);

  // handle file download
  const downloadFileHandler = async () => {
    try {
      window.open(fileAttachment.fileUrl, '_blank'); // open the presigned download url in a new window
    } catch (err) {
      console.log(err);
    }
  };

  // cancel upload
  const cancelUploadHandler = () => {
    // cancel the request
    abortController.abort();

    // remove the file
    onRemoveFile(fileAttachment.id);
  };

  // upload progress bar
  const uploadProgressEvent = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setUploadProgress(percentCompleted);
  };

  // Get me a different icon based on the type of file
  const renderFileIcon = (fileName: string) => {
    const fileType = getFileType(fileName);

    switch (fileType) {
      case FileType.Document:
        return <DocumentTextIcon className="text-green-500 h-7 w-5 mr-2" />;
      case FileType.Image:
        return <PhotoIcon className="text-pink-500 h-7 w-5 mr-2" />;
      case FileType.Design:
        return <CubeIcon className="text-purple-500 h-7 w-5 mr-2" />;
      default:
        break;
    }
  };

  return (
    <>
      <div className="bg-gray-100 border rounded-md my-2 pt-2 m-auto" data-testid={'fileCell'}>
        <div className="flex px-4 pb-1">
          <div>{renderFileIcon(fileAttachment.name)}</div>
          <div className="flex-grow flex-1 text-gray-900 text-sm pt-1 break-all">{fileAttachment.name}</div>
          {fileAttachment.uploadStatus !== FileUploadState.Error && (
            <>
              {fileAttachment.size && (
                <div className="text-sm text-gray-400 pt-1 ml-2" data-qa="fileSizeLabel">
                  {(fileAttachment.size / 1000).toFixed(1)} KB
                </div>
              )}
              <div className="flex">
                {(fileAttachment.uploadStatus === FileUploadState.Uploading ||
                  fileAttachment.uploadStatus === FileUploadState.Waiting) && (
                  <svg
                    className="animate-spin ml-2 mt-1 h-5 w-5 text-black"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                {fileAttachment.uploadStatus === FileUploadState.Uploaded && (
                  <ArrowDownTrayIcon
                    className="cursor-pointer text-gray-500 h-7 w-5 ml-2"
                    onClick={downloadFileHandler}
                    data-qa="downloadFileButton"
                    data-testid="downloadFileButton"
                  />
                )}
                {fileAttachment.uploadStatus === FileUploadState.Uploaded &&
                  fileAttachment.createdBy !== 'DataCapture' && (
                    <TrashIcon
                      className="cursor-pointer text-gray-500 h-7 w-5 ml-2"
                      onClick={() => onRemoveFile(fileAttachment.id)}
                      data-qa="deleteFileButton"
                      data-testid="deleteFileButton"
                    />
                  )}
              </div>
            </>
          )}
          {fileAttachment.uploadStatus === FileUploadState.Error && (
            <div className="text-red-500 text-sm pt-1">Error Uploading</div>
          )}
          {(fileAttachment.uploadStatus === FileUploadState.Error ||
            fileAttachment.uploadStatus === FileUploadState.Uploading) && (
            <div className="flex">
              <XMarkIcon
                className="cursor-pointer text-gray-500 h-7 w-5 ml-2"
                onClick={cancelUploadHandler}
                data-qa="cancelUploadButton"
              />
            </div>
          )}
        </div>
        {fileAttachment.uploadStatus !== FileUploadState.Error &&
          fileAttachment.uploadStatus === FileUploadState.Uploading && (
            <div className="h-1 border-b-2 rounded-b-md border-blue-500" style={{ width: uploadProgress + '%' }}></div>
          )}
        {fileAttachment.uploadStatus === FileUploadState.Error && (
          <div
            className="h-1 border-b-2 rounded-b-md border-red-500"
            style={{ width: (uploadProgress ? uploadProgress : 100) + '%' }}
          ></div>
        )}
      </div>
    </>
  );
};

export default FileCell;
