import { CaseDetailModuleContext } from 'providers/CaseDetailModuleProvider';
import { useContext } from 'react';
import { InvoiceAccount } from 'shared/api/invoice.api';
import { CreatedOrderItem, TabConfig } from 'shared/models';
import CaseOverviewLeftPanel from '../CaseOverviewLeftPanel/CaseOverviewLeftPanel';
import CaseOverviewProduct from './CaseOverviewProduct/CaseOverviewProduct';

/**
 * Props for the CaseOverview component.
 */
interface CaseOverviewProps {
  /**
   * Function to handle tab selection.
   */
  tabSelectHandler: (tab: TabConfig) => void;
  /**
   * The account associated with the case.
   */
  account: InvoiceAccount | null;
}

/**
 * Component for rendering a case overview.
 * @param tabSelectHandler - Function to handle tab selection.
 * @param account - The account associated with the case.
 * @returns A JSX element representing the CaseOverview component.
 */
const CaseOverview: React.FC<CaseOverviewProps> = ({ tabSelectHandler, account }) => {
  const { caseDetails } = useContext(CaseDetailModuleContext);
  return (
    <>
      <div className="flex p-6 gap-6">
        <div className="w-1/4 flex flex-col gap-2">
          <CaseOverviewLeftPanel account={account} />
        </div>
        <div className="flex-grow">
          {caseDetails &&
            caseDetails.orderItems.map((product, index) => (
              <CaseOverviewProduct
                tabSelectHandler={tabSelectHandler}
                key={index}
                product={product as CreatedOrderItem}
                index={index}
                count={caseDetails?.orderItems.length}
              />
            ))}
        </div>
      </div>
      <div className="pb-2"></div>
    </>
  );
};

export default CaseOverview;
