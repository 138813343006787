import { OrderSearchInputFields, OrderSearchSortColumn, OrderStatus, SearchedOrder, SortOrder } from 'API';
import SelectDropdown from 'components/common/SelectDropdown/SelectDropdown';
import { Table } from 'components/common/Table/Table';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { orderSearch } from 'shared/api/order.api';
import { searchTableColumns } from 'shared/constants/search.constants';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { SearchedOrderMinimum } from 'shared/models';
import { AnalyticsService } from 'shared/services/analytics.service';
import { getDateInShortFormat } from 'shared/utils';
import ExpandableOrders from './ExpandableOrders';

/**
 * Input props for {@link SearchTable}
 */
interface SearchTableProps {
  /**
   * search term
   */
  term: string;

  /**
   * status of case filter
   */
  status?: OrderStatus[];

  /**
   * page number for pagination
   */
  page: number;

  /**
   * call back to indicate change in page selected
   * @param page - selected page number
   */
  setPage?: (page: number) => void;

  /**
   * call back to indicate sort by column state
   * @param column - selected column
   */
  setSortBy?: (column: string) => void;
  /**
   * indicates filter type {@link OrderSearchInputFields}
   */
  type?: OrderSearchInputFields[];

  /**
   * indicates {@link OrderSearchSortColumn | column} by which sorting needs to be done
   */
  sort?: OrderSearchSortColumn;

  /**
   * indicates ascending or descending for sorting
   */
  order?: SortOrder;
}

/**
 * Renders a search results table
 *
 * @param term - search term
 * @param status - status of case filter
 * @param page - page number for pagination
 * @param setPage - call back to indicate change in page selected
 * @param setSortBy - call back to indicate sort by column state
 * @param type - indicates filter type {@link OrderSearchInputFields}
 * @param sort - indicates {@link OrderSearchSortColumn} by which sorting needs to be done
 * @param order - indicates ascending or descending for sorting
 */
export const SearchTable: FC<SearchTableProps> = ({ term, status, page, setPage, setSortBy, type, sort, order }) => {
  const [pageSize, setPageSize] = useState('10');
  const { data, loading, initialized } = useQueryFetcher(orderSearch, {
    term,
    status: status || undefined,
    searchFields: type || undefined,
    sortColumn: sort || undefined,
    order: order || undefined,
    skip: !term,
    size: parseInt(pageSize),
    from: parseInt(pageSize) * (page - 1),
  });
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * function to handle page size to limit search results
   *
   * @param size - size of one page, results are limited to {@link size}
   */
  const changePageSize = (size: string) => {
    setPageSize(size);
    setPage?.(1);
  };

  /**
   * Navigates to the requested order number's case details page, with the search value saved.
   * @param orderNumber - The target order number to navigate to.
   */
  const navigateToOrderDetails = useCallback(
    (orderNumber: string) => {
      AnalyticsService.track(AnalyticsEventName.SearchResultSelected, {
        caseNumber: orderNumber,
        searchText: term,
        filterType: type || [],
        filterCaseStatus: status || [],
      });
      navigate(
        {
          pathname: getOrderDetailPath(orderNumber),
        },
        {
          state: {
            searchValue: term,
          },
        }
      );
    },
    [navigate, status, term, type]
  );

  /**
   * If the search term matches an order item,
   * automatically redirects to the case details page for that order number,
   * as per LMS1-5132.
   */
  useEffect(() => {
    if (!loading && initialized && data && term) {
      const matchOrderWithTerm = data?.orders.find(item => item.orderNumber === term);
      const matchingOrderNumber = matchOrderWithTerm?.orderNumber;
      const previousSearch = location.state?.previousSearch;
      // If there is an exact order number match for the current search term, auto navigates to that order's details page.
      // However, this auto-navigation will not occur if the previous search value equals the matching order number.
      // This is to support the back to search results feature without introducing an issue where we go from order details to the search results and then back to order details automatically.
      if (matchingOrderNumber && matchingOrderNumber !== previousSearch) {
        navigateToOrderDetails(matchOrderWithTerm.orderNumber);
      }
    }
  }, [data, navigate, term, initialized, loading, navigateToOrderDetails, location.state]);

  const rows = useMemo((): SearchedOrderMinimum[] => {
    if (!data) return [];

    const mapOrderFn = (order: SearchedOrder) => {
      // build product description and attributes elements
      const description = <ExpandableOrders order={order} />;
      const labReceivedDate = order.labReceivedDate || order.createdDate;

      // map values from the order to the table data
      return {
        orderNumber: order.orderNumber,
        patientName: order.patientFirstName + ' ' + order.patientLastName,
        productDescription: description,
        billingAccountId: order.billingAccountId,
        providerId: order.providerId,
        status: order.status,
        providerName: order.providerName,
        originatingSystem: order.originatingSystem,
        labReceivedDate: labReceivedDate
          ? getDateInShortFormat(labReceivedDate, order.utcConversionTimeZoneCode)
          : 'N/A',
        invoiceDate: order.invoiceDate
          ? getDateInShortFormat(order.invoiceDate, order.utcConversionTimeZoneCode)
          : 'N/A',
      };
    };

    return data.orders.map(mapOrderFn);
  }, [data]);

  return (
    <>
      <div className="flex justify-end">
        <div className="flex items-center pb-2">
          <SelectDropdown
            label="Results per page"
            options={[
              { label: '10', value: '10' },
              { label: '30', value: '30' },
              { label: '50', value: '50' },
              { label: '100', value: '100' },
            ]}
            onValueChange={select => changePageSize(select)}
            value={pageSize}
            id="pageSize"
            hideDefaultOption={true}
            labelClassName={'min-w-max pt-2 pr-3'}
            showChevronUpDown
          />
        </div>
      </div>
      <div className="w-full h-px bg-gray-300 mb-2" />
      <Table
        columns={searchTableColumns(navigateToOrderDetails)}
        rows={rows}
        loading={loading}
        page={page}
        setPage={setPage}
        pageSize={parseInt(pageSize)}
        handleSortBy={setSortBy}
        disableLocalSort
        showPagination
        totalCount={data?.total}
        isApiPaginated
        borderRounded
      />
    </>
  );
};
