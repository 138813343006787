import React, { useState } from 'react';
import { ZenObservable } from 'zen-observable-ts';

/**
 * Represents the shape of a subscription object.
 */
export interface SubscriptionObject {
  /**
   * Unique identifier for the subscription.
   */
  uuid: string;

  /**
   * The subscription instance.
   */
  subscription: ZenObservable.Subscription;
}

/**
 * Represents the shape of the subscription list context.
 */
interface SubscriptionList {
  /**
   * List of subscriptions.
   */
  subscriptions: SubscriptionObject[];
  /**
   * Function to add a subscription to the list.
   */
  addSubscription: (subscription: SubscriptionObject) => void;
}

/**
 * Context for managing subscriptions.
 */
export const SubscriptionsContext = React.createContext<SubscriptionList>({
  subscriptions: [],
  addSubscription: () => {
    console.log();
  },
});

/**
 * Provider component for managing subscriptions.
 */
export const SubscriptionsProvider: React.FC = ({ children }) => {
  const [subscriptions, setSubs] = useState<SubscriptionObject[]>([]);

  const addSubscription = (subscription: SubscriptionObject) => {
    setSubs([...subscriptions, subscription]);
  };

  return (
    <>
      <SubscriptionsContext.Provider value={{ subscriptions, addSubscription }}>
        {children}
      </SubscriptionsContext.Provider>
    </>
  );
};
