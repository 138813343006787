import { Transition } from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  ExclamationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import classNames from 'classnames';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { KeyCode } from 'shared/enums';
import { DropdownOption } from 'shared/models';
import Label from '../Label';
import RequiredMessage from '../RequiredMessage/RequiredMessage';
import { SkeletonBorder } from '../Skeleton';
import styles from './MultiSelectDropdown.module.css';

/**
 * Props for the MultiSelectDropdown component.
 */
interface MultiSelectDropdownProps {
  /** Label for the dropdown. */
  label?: string;
  /** Indicates if the dropdown options should be displayed on top. */
  displayOptionsOnTop?: boolean;
  /** Indicates if the dropdown is required. */
  isRequired?: boolean;
  /** Indicates if the dropdown is disabled. */
  isDisabled?: boolean;
  /** Indicates if an alert should be shown on submit. */
  onSubmitAlert?: boolean;
  /** Title for the required message. */
  requiredMessageTitle?: string;
  /** Dropdown options. */
  dropdownOptions: DropdownOption[];
  /** Selected values. */
  values: DropdownOption[];
  /** Data attribute for QA automation. */
  dataQa: string;
  /** Callback function for when the selected values change. */
  onSelectedValueChange: (val: DropdownOption[]) => void;
  /** Additional CSS class for styling. */
  className?: string;
  /** Placeholder text for the input. */
  placeholder?: string;
  /** Error message to display. */
  errorMessage?: string;
  /** Indicates if the dropdown is in a loading state. */
  isLoading?: boolean;
}

/**
 * Component for a multi-select dropdown.
 *
 * @param label - Label for the dropdown.
 * @param displayOptionsOnTop - Indicates if the dropdown options should be displayed on top.
 * @param isRequired - Indicates if the dropdown is required.
 * @param isDisabled - Indicates if the dropdown is disabled.
 * @param onSubmitAlert - Indicates if an alert should be shown on submit.
 * @param requiredMessageTitle - Title for the required message.
 * @param dropdownOptions - Dropdown options.
 * @param values - Selected values.
 * @param dataQa - Data attribute for QA automation.
 * @param onSelectedValueChange - Callback function for when the selected values change.
 * @param className - Additional CSS class for styling.
 * @param placeholder - Placeholder text for the input.
 * @param errorMessage - Error message to display.
 * @param isLoading - Indicates if the dropdown is in a loading state.
 * @returns JSX.Element - The rendered component.
 */
const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  label,
  displayOptionsOnTop,
  isRequired,
  isDisabled,
  onSubmitAlert,
  dropdownOptions,
  requiredMessageTitle,
  values,
  dataQa,
  onSelectedValueChange,
  className,
  errorMessage,
  placeholder = 'Select one or more',
  isLoading,
}) => {
  const [value, setValue] = useState<string>('');
  const [onHover, setOnHover] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [options, setOptions] = useState<DropdownOption[]>([]);
  const [previousSuggestionIndex, setPreviousSuggestionIndex] = useState<number>(0);

  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuContainerRef = useRef<HTMLDivElement>(null);
  const menuItemRef = useRef<HTMLDivElement>(null);
  const MAX_ITEMS_PER_PAGE = 6;
  const ELEMENT_HEIGHT_DEFAULT = 36;
  const elementHeight = menuItemRef && menuItemRef.current ? menuItemRef.current.offsetHeight : ELEMENT_HEIGHT_DEFAULT;

  useEffect(() => {
    if (menuContainerRef.current && menuItemRef.current) {
      const containerRect = menuContainerRef.current.getBoundingClientRect();
      const elementRect = menuItemRef.current.getBoundingClientRect();

      // If the active element goes below or above the view of the container element, scroll to the top of the selected/highlighted element.
      if (elementRect.bottom > containerRect.bottom || elementRect.top < containerRect.top) {
        menuContainerRef.current.scrollTo({
          top: onHover * elementRect.height,
        });
      }
    }
  }, [onHover, options.length, previousSuggestionIndex]);

  /**
   * Set options when they change.
   */
  useEffect(() => {
    setOptions(dropdownOptions);
  }, [dropdownOptions]);

  /**
   * Clear values if input becomes disabled.
   */
  useEffect(() => {
    if (isDisabled) {
      onSelectedValueChange([]);
    }
  }, [isDisabled, onSelectedValueChange]);

  /**
   * Handle opening dropdown when clicking anywhere on component by focusing the input
   * which triggers isOpen to be set to true.
   */
  const dropdownHandler = () => {
    !isDisabled && !isOpen && inputRef.current?.focus();
  };

  const selectorValueRemoval = (index: number) => {
    if (!isDisabled) {
      onSelectedValueChange(values.filter((_item, idx) => idx !== index));
    }
  };

  const inputChangeHandler = (e: { target: { value: string } }) => {
    setIsOpen(true);
    setValue(e.target.value);
    const option = dropdownOptions.filter(item =>
      item.label.toLowerCase().trim().includes(e.target.value.toLowerCase().trim())
    );
    setOptions(option);
    setOnHover(prevState => {
      setPreviousSuggestionIndex(prevState);
      // Ensures the currently active index is always the first option, whenever typing, as per LMS1-4570.
      return 0;
    });
  };

  const inputKeyHandler = (e: { key: string }) => {
    if (e.key === KeyCode.Down) {
      if (options.length - 1 !== onHover) {
        setOnHover(prevState => {
          setPreviousSuggestionIndex(prevState);
          return onHover + 1;
        });
      } else {
        setOnHover(() => {
          setPreviousSuggestionIndex(-1);
          return 0;
        });
      }
      setIsOpen(true);
    }
    if (e.key === KeyCode.Up) {
      if (onHover !== 0) {
        setOnHover(prevState => {
          setPreviousSuggestionIndex(prevState);
          return onHover - 1;
        });
      } else {
        setOnHover(prevState => {
          setPreviousSuggestionIndex(prevState);
          return options.length - 1;
        });
      }
      setIsOpen(true);
    }
    if (value === '' && e.key === KeyCode.Backspace) {
      onSelectedValueChange(values.filter((_item, idx) => idx !== values.length - 1));
      setIsOpen(false);
    }
    if (e.key === KeyCode.Enter) {
      if (value === '' && !isOpen) {
        setIsOpen(true);
        setOnHover(() => {
          setPreviousSuggestionIndex(-1);
          return 0;
        });
      } else {
        optionHandler(options[onHover]);
        setOnHover(() => {
          setPreviousSuggestionIndex(-1);
          return 0;
        });
      }
    }
    if (e.key === KeyCode.Tab) {
      setIsOpen(false);
      setValue('');
    }

    if (e.key === KeyCode.Escape) {
      setIsOpen(false);
    }
  };

  /**
   * Handle selecting and deselecting items.
   * @param item - item selected.
   */
  const optionHandler = (item: DropdownOption) => {
    const valueIndex = values.findIndex(ele => ele.value === item.value);
    const newValues = _.cloneDeep(values);
    if (valueIndex > -1) {
      newValues.splice(valueIndex, 1);
    } else {
      newValues.push(item);
    }
    onSelectedValueChange(newValues);
    setOptions(dropdownOptions);
    setValue('');
    setIsOpen(false);
  };

  const isSelected = useCallback(
    (item: DropdownOption): boolean => {
      return values.some(ele => ele.value === item.value);
    },
    [values]
  );

  const hasError =
    errorMessage || (!isOpen && isRequired && !isDisabled && !values.length && (isTouched || onSubmitAlert));

  // The focusin and focusout events allow event bubbling, as opposed to onFocus and onBlur.
  useEffect(() => {
    const onFocusIn = () => {
      setIsFocused(true);
      setIsOpen(true);
    };
    const onFocusOut = () => {
      setIsFocused(false);
      setIsOpen(false);
      setIsTouched(true);
    };
    const dropdownContainer = dropdownContainerRef.current;
    dropdownContainer?.addEventListener('focusin', onFocusIn);
    dropdownContainer?.addEventListener('focusout', onFocusOut);
    return () => {
      dropdownContainer?.removeEventListener('beforeunload', onFocusIn);
      dropdownContainer?.removeEventListener('beforeunload', onFocusOut);
    };
  }, []);

  return (
    <div className="relative w-full">
      {label && (
        <Label data-testid="label" required={isRequired}>
          {label}
        </Label>
      )}

      <div tabIndex={-1} ref={dropdownContainerRef}>
        <div
          className={classNames(
            className,
            'relative px-3 rounded-md shadow-sm focus:outline-none focus:ring-1 border',
            {
              'bg-gray-50 border-gray-300 text-gray-400': isDisabled,
              'bg-white border-gray-300': !isDisabled,
              'focus:ring-red-500 focus:border-red-500 border-red-500': hasError,
              'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300':
                !isRequired || !onSubmitAlert || !!values.length,
              'ring-indigo-500 border-indigo-500 outline-none ring-1': isFocused,
              'py-1': !!values.length,
              'py-2': !values.length,
            },
            styles['input-wrapper']
          )}
          onClick={dropdownHandler}
          data-qa={`${dataQa}Container`}
          data-testid={`${dataQa}Container`}
        >
          {isLoading ? (
            <SkeletonBorder />
          ) : (
            <div className="flex w-full items-center">
              <div className="flex flex-1 flex-wrap items-center w-9/12">
                {values
                  .sort(function (a, b) {
                    return +a.label - +b.label;
                  })
                  .map((item, index) => (
                    <div
                      className={classNames(
                        'flex items-center text-indigo-800 bg-indigo-100 rounded-xl text-xs p-0.5 pl-2 pr-1 my-1',
                        {
                          'mr-0.5': index !== values.length - 1,
                        }
                      )}
                      key={`${item.label}-${index}`}
                    >
                      {item.label}
                      <button
                        type="button"
                        className="inline-flex items-center ml-1 text-sm text-indigo-400 bg-transparent rounded-sm hover:bg-indigo-200 hover:text-indigo-900"
                        aria-label="Remove"
                        onClick={() => selectorValueRemoval(index)}
                      >
                        <XMarkIcon className="h-3 w-3" />
                        <span className="sr-only">Remove badge</span>
                      </button>
                    </div>
                  ))}
                <input
                  className={classNames(
                    `flex flex-1 ml-0.5 pl-1 py-0 text-sm border-none shadow-none outline-none placeholder:text-gray-400:placeholder:text-black mr-0.2 overflow-ellipsis`,
                    {
                      'bg-transparent': isDisabled,
                      'mr-6': hasError,
                    }
                  )}
                  style={{ outlineColor: 'transparent', minWidth: '50px' }}
                  placeholder={placeholder}
                  disabled={isDisabled}
                  ref={inputRef}
                  value={value}
                  onChange={inputChangeHandler}
                  onKeyDown={inputKeyHandler}
                  data-qa={dataQa}
                  data-testid={dataQa}
                  type="text"
                />
              </div>
              {isDisabled || hasError ? (
                <ChevronDownIcon
                  className={classNames('h-5 w-5', {
                    'text-gray-400': !hasError,
                    'text-red-500': hasError,
                  })}
                  aria-hidden="true"
                />
              ) : (
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              )}
            </div>
          )}
          {hasError && (
            <div className="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none">
              {<ExclamationCircleIcon className="h-5 text-red-500" />}
            </div>
          )}
        </div>
        <Transition
          show={isDisabled ? false : isOpen}
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`absolute ${
              displayOptionsOnTop && 'bottom-full mb-1'
            } z-40 w-full overflow-y-auto overscroll-auto mt-1 bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none`}
            style={{ maxHeight: MAX_ITEMS_PER_PAGE * elementHeight + 'px' }}
            ref={menuContainerRef}
            data-qa={`${dataQa}Options`}
            data-testid={`${dataQa}Options`}
          >
            {options.length !== 0 ? (
              options.map((item, index) => {
                const isValueSelected = isSelected(item);
                return (
                  <div
                    key={index}
                    ref={onHover === index ? menuItemRef : null}
                    className={classNames('cursor-default select-none relative py-2 text-gray-900 pl-3 pr-9', {
                      'bg-indigo-600': onHover === index,
                      'bg-white': onHover !== index,
                    })}
                    onMouseOver={() => {
                      !isDisabled &&
                        setOnHover(prevState => {
                          setPreviousSuggestionIndex(prevState);
                          return index;
                        });
                    }}
                    onClick={() => optionHandler(item)}
                    data-qa={`${dataQa}Option`}
                  >
                    <div
                      className={classNames('flex flex-1 text-sm', {
                        'text-white': onHover === index,
                        'text-black': onHover !== index,
                        'font-semibold': isValueSelected,
                        'font-normal': !isValueSelected,
                      })}
                    >
                      <CheckIcon
                        className={classNames('h-5 w-5', {
                          'text-white': onHover === index && isValueSelected,
                          'text-indigo-600': onHover !== index && isValueSelected,
                          'text-transparent': !isValueSelected,
                        })}
                        aria-hidden="true"
                      />
                      {item.label}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="bg-white text-gray-400 text-center p-1">No options</div>
            )}
          </div>
        </Transition>
      </div>
      {hasError && <RequiredMessage fieldName={label || requiredMessageTitle} errorMessage={errorMessage} />}
    </div>
  );
};

export default MultiSelectDropdown;
