import { ValueOf } from 'types/generic';

/**
 * Path for signing in.
 */
const SIGN_IN_PATH = '/signin';

/**
 * Routes object containing all application routes.
 */
export const ROUTES = {
  ROOT: '/',
  DASHBOARD: '/dashboard',
  ORDER_ENTRY: '/order-entry',
  NEW_ORDER: '/order-entry/neworder',
  UPDATE_ORDER: '/orders/update/:id',
  INVOICE_ORDER_LOOKUP: '/invoice/order-lookup',
  INVOICE_CREATE: '/invoice/:id',
  INVOICE_PRINT: '/invoice/print/:id',
  ORDER_DETAIL: '/orders/:id',
  SEARCH_PATH: '/search',
  SEARCH_CASE_PATH: '/search/case',
  SIGN_IN_PATH,
  SIGN_IN_SSO_PATH: `${SIGN_IN_PATH}/sso`,
  SIGN_IN_EMAIL_PATH: `${SIGN_IN_PATH}/email`,
  SIGN_OUT_PATH: '/logout',
  REDIRECT_PATH: '/redirect',
} as const;

/**
 * Prop Metadata for each route, containing the title.
 */
interface RouteMetadata {
  title: string;
}

/**
 * Metadata for each route in the application.
 */
export const routeMetadata: Record<ValueOf<typeof ROUTES>, RouteMetadata> = {
  [ROUTES.ROOT]: {
    title: 'Dashboard',
  },
  [ROUTES.DASHBOARD]: {
    title: 'Dashboard',
  },
  [ROUTES.ORDER_ENTRY]: {
    title: 'Case Entry',
  },
  [ROUTES.NEW_ORDER]: {
    title: 'New Case',
  },
  [ROUTES.UPDATE_ORDER]: {
    title: 'Update Case',
  },
  [ROUTES.INVOICE_ORDER_LOOKUP]: {
    title: 'Invoice Lookup',
  },
  [ROUTES.INVOICE_CREATE]: {
    title: 'Invoice',
  },
  [ROUTES.INVOICE_PRINT]: {
    title: 'Invoice Print',
  },
  [ROUTES.ORDER_DETAIL]: {
    title: 'Case Details',
  },
  [ROUTES.SEARCH_PATH]: {
    title: 'Case Search',
  },
  [ROUTES.SEARCH_CASE_PATH]: {
    title: 'Case Search Results',
  },
  [ROUTES.SIGN_IN_PATH]: {
    title: 'Sign In',
  },
  [ROUTES.SIGN_IN_SSO_PATH]: {
    title: 'Sign In SSO',
  },
  [ROUTES.SIGN_IN_EMAIL_PATH]: {
    title: 'Sign In Email',
  },
  [ROUTES.SIGN_OUT_PATH]: {
    title: 'Sign Out',
  },
  [ROUTES.REDIRECT_PATH]: {
    title: 'Redirect',
  },
} as const;
