import Button from 'components/common/Button/Button';
import { isRouteErrorResponse, useLocation, useNavigate, useRouteError } from 'react-router-dom';

/**
 * Component to display an error message.
 *
 * @param error - The error object.
 * @returns an error component.
 */
const ErrorComponent = ({ error }: { error: Error }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8 max-w-3xl m-auto">
      <div className="max-w-3xl mx-auto">
        <div className="flex flex-col min-h-40 bg-white p-6 gap-2 text-red-600">
          <h1 className="text-2xl font-bold whitespace-nowrap">Error Occurred</h1>
          <h2 className="text-lg font-semibold flex">
            <b>Error Message: </b> &nbsp; <div>{error.message}</div>
          </h2>
        </div>
        <div>
          <Button id="refresh" variant="bordered" onClick={() => navigate(location)}>
            Refresh
          </Button>
        </div>
      </div>
    </div>
  );
};

/**
 * Root error boundary component to handle route errors.
 *
 * @returns a root error boundary component.
 */
export const RootErrorBoundary = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <div>This page doesn't exist!</div>;
    }

    if (error.status === 401) {
      return <div>You aren't authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }
  }

  if (error instanceof Error) {
    return <ErrorComponent error={error} />;
  }

  return <div className="text-lg font-semibold flex text-center">Something went Wrong.</div>;
};
