import CheckboxInput from 'components/common/CheckboxInput';
import Input from 'components/common/Input/Input';
import MultiSelectDropdown from 'components/common/MultiSelectDropdown/MultiSelectDropdown';
import _ from 'lodash';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext, useMemo, useState } from 'react';
import { AttributeType } from 'shared/enums';
import { DropdownOption } from 'shared/models';

/**
 * Props for the Layered component.
 */
interface LayeredProps {
  /**
   * The ID of the item.
   */
  itemId: string;
}

/**
 * The Layered component handles the display and interaction of layered attributes for an order item.
 * Users can select manufacturing processes for individual teeth or all teeth.
 * @param itemId - The ID of the item.
 * @returns JSX element representing the Layered component.
 */
const Layered: React.FC<LayeredProps> = ({ itemId }) => {
  const { order } = useContext(OrderModuleContext);
  const { getOrderItemSelectedTeeth, patchOrder } = useContext(OrderModuleActionsContext);
  const selectedTeeth = getOrderItemSelectedTeeth(itemId);
  const orderItems = _.cloneDeep(order.orderItems);
  const item = useMemo(() => orderItems?.find(item => item.id === itemId), [itemId, orderItems]);
  const [allTeethChecked, setAllTeethChecked] = useState<boolean>(false);

  /**
   * Gets the layered attribute options.
   * @returns The layered attribute options.
   */
  const getLayeredAttribute = () => {
    const teeth = item?.attributes.find(attribute => attribute.name === 'Layered')?.value.split(',');
    if (teeth && teeth[0] !== '') {
      return teeth.map(tooth => {
        return { label: '#' + tooth, value: tooth };
      });
    } else {
      return [];
    }
  };
  /**
   * Sets the layered attribute value.
   * @param val - The selected values.
   */
  const setLayeredAttribute = (val: DropdownOption[]) => {
    setAllTeethChecked(false);
    const attribute = item?.attributes.find(attribute => attribute.name === 'Layered');
    if (attribute) {
      const teeth = val.map(tooth => tooth.value);
      attribute.value = teeth.join(',');
    } else {
      item?.attributes.push({
        name: 'Layered',
        type: AttributeType.ProcessAttribute,
        value: val[0].value,
        quantity: 1,
      });
    }

    patchOrder({
      orderItems: orderItems,
    });
  };
  /**
   * Handles toggling all teeth.
   */
  const allTeeth = () => {
    setAllTeethChecked(!allTeethChecked);

    let teeth = '';
    if (!allTeethChecked) {
      teeth = selectedTeeth.join(',');
    }

    const attribute = item?.attributes.find(attribute => attribute.name === 'Layered');
    if (attribute) {
      attribute.value = teeth;
    } else {
      item?.attributes.push({
        name: 'Layered',
        type: AttributeType.ProcessAttribute,
        value: teeth,
        quantity: 1,
      });
    }

    patchOrder({
      orderItems: orderItems,
    });
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-x-6 ">
        <div>
          <MultiSelectDropdown
            label="Manufacturing Process"
            dropdownOptions={selectedTeeth.map(tooth => {
              return { label: '#' + tooth, value: tooth };
            })}
            values={getLayeredAttribute()}
            onSelectedValueChange={setLayeredAttribute}
            dataQa="layeredAttribute"
            placeholder={getLayeredAttribute().length === 0 ? 'Tooth #' : ''}
          />
          <div className="mt-2">
            <CheckboxInput
              id={itemId}
              checked={allTeethChecked}
              onChange={allTeeth}
              label={'All Teeth'}
              inLine={true}
              checkBoxAtStart={true}
            />
          </div>
        </div>

        <div className="mt-6">
          <Input value={'Layered'} disabled={true} />
        </div>
      </div>
    </>
  );
};

export default Layered;
