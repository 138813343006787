import { AccountStandingBadge } from 'components/common/AccountStandingBadge';
import { ShippingAddressText } from 'components/common/ShippingAddressText/ShippingAddressText';
import { SkeletonBorder } from 'components/common/Skeleton';
import OrderDetail from 'components/invoicing/OrderDetail/OrderDetail';
import { getDefaultOrder } from 'mocks/order-entry.mock';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { AddBundleCaseContainer } from './AddBundleCaseContainer/AddBundleCaseContainer';

/**
 * Props interface for the VerifyCaseSection component.
 */
interface InvoiceDetailProps {
  /**
   * Boolean indicating whether data is loading.
   */
  isLoading: boolean;
  /**
   * Boolean indicating whether the case is invoiced.
   */
  isInvoicedCase: boolean;
}

/**
 * Component to display and verify case information.
 * @param isLoading - Boolean indicating whether data is loading.
 * @param isInvoicedCase - Boolean indicating whether the case is invoiced.
 */
export const VerifyCaseSection: React.FC<InvoiceDetailProps> = ({ isLoading: invoicingLoading, isInvoicedCase }) => {
  const { invoiceCases, shipping, account } = useInvoicingDetail();
  const firstInvoiceCase = invoiceCases[0];
  const billingAccountId = account?.billingAccountId || firstInvoiceCase?.billingAccountId || '';
  return (
    <>
      <div className="font-medium">1. Verify Case Information</div>
      <div className="p-4 flex-col space-y-1">
        <div className="flex">
          <div className="flex-none w-32 mr-3">
            <label className="text-gray-500 font-medium text-sm w-36">Account #</label>
          </div>
          <div className="flex flex-grow">
            <label className="text-gray-900 font-medium" data-qa="accountIdLabel" data-testid="accountIdLabel">
              {invoicingLoading ? <SkeletonBorder className="h-6 w-40" /> : billingAccountId}
            </label>
            {!invoicingLoading && account && (
              <AccountStandingBadge accountStanding={account.standing} accountStatus={account.status} />
            )}
          </div>
        </div>
        <div className="flex">
          <div className="flex-none w-32 mr-3">
            <label className="text-gray-500 font-medium text-sm w-36">Shipping Address</label>
          </div>
          <div className="flex flex-grow">
            <label className="text-gray-900 font-medium">
              {invoicingLoading ? (
                <SkeletonBorder className="h-6 w-40" />
              ) : (
                <ShippingAddressText address={shipping?.address} />
              )}
            </label>
          </div>
        </div>
      </div>
      <div className="space-y-4 2xl:pb-16">
        {invoicingLoading ? (
          <OrderDetail isLoading={invoicingLoading} invoicingCase={getDefaultOrder()} />
        ) : (
          invoiceCases.map(caseItem => (
            <OrderDetail
              key={caseItem.orderNumber}
              invoicingCase={caseItem}
              caseCount={invoiceCases.length}
              isInvoicedCase={isInvoicedCase}
            />
          ))
        )}
        {!isInvoicedCase && <AddBundleCaseContainer isLoading={invoicingLoading} billingAccountId={billingAccountId} />}
      </div>
    </>
  );
};
