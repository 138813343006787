import classNames from 'classnames';
import { AutoCompleteSearchInput } from 'components/common/AutoCompleteSearchInput/AutoCompleteSearchInput';
import { FC, useMemo } from 'react';
import { SEARCH_PATH_LOCAL_STORAGE_KEY } from 'shared/constants/local-storage-keys';
import { useLocalStorage } from 'shared/hooks/useStorage';
import { AutoCompleteOption } from 'types/common';

/**
 * Input props for {@link AutoSearchInput}
 */
interface AutoSearchInputProps {
  /**
   * search value
   */
  searchValue: string;

  /**
   * callback function triggered on change in search input
   * @param value - new search value submitted
   */
  setSearchValue: (value: string) => void;

  /**
   * represents label to be shown above search input
   */
  label?: string;

  /**
   * represents styling related info for outermost div
   */
  className?: string;

  /**
   * a placeholder string to show in search field
   */
  placeholder?: string;

  /**
   * should focus automatically or not
   */
  autoFocus?: boolean;
}

/**
 * Renders an input field capable of auto complete suggestions
 *
 * @param searchValue - search value
 * @param setSearchValue - callback function triggered on change in search input
 * @param label - represents label to be shown above search input
 * @param className - represents styling related info for outermost div
 * @param placeholder - a placeholder string to show in search field
 * @param autoFocus - should focus automatically or not
 */
export const AutoSearchInput: FC<AutoSearchInputProps> = ({
  searchValue,
  setSearchValue,
  label,
  className,
  placeholder,
  autoFocus,
}) => {
  // TODO: remove the stored values from the local storage after user logged out. Functionality is not implemented yet.
  const [values, setValues] = useLocalStorage<string[]>(SEARCH_PATH_LOCAL_STORAGE_KEY, []);

  const patientNameOptions = useMemo(() => {
    return values.map((v, index) => ({
      id: index,
      name: v,
      value: v,
    }));
  }, [values]);

  /**
   * callback function to handle if search value is changed
   *
   * @param option - auto-completion suggestion
   */
  const onValueChange = (option: AutoCompleteOption | null) => {
    if (!option) return setSearchValue('');

    const newValues = new Set([...values, option.value]);
    setValues([...newValues]);

    // wait for the new value to be added to the local storage list
    setTimeout(() => {
      setSearchValue(option.value);
    });
  };

  return (
    <div className={classNames('w-84', className)}>
      <AutoCompleteSearchInput
        label={label}
        options={patientNameOptions}
        value={searchValue}
        placeholder={placeholder || 'Search for a case'}
        hideClockIcon={false}
        onValueChange={onValueChange}
        autoFocus={autoFocus}
      />
    </div>
  );
};
