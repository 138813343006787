import { SkeletonBorder } from 'components/common/Skeleton';
import { convertToCurrency } from 'shared/utils';

/**
 * Interface for the ProductDetailHeader component props.
 */
interface ProductDetailHeaderI {
  /**
   * The description of the product.
   */
  description: string;
  /**
   * The quantity of the product.
   */
  quantity: number;
  /**
   * The unit price of the product.
   */
  unitPrice: number;
  /**
   * Flag indicating whether the component is in loading state.
   */
  isLoading?: boolean;
}

/**
 * Constructs and returns a header component for a given product's detail.
 * @param description - The product description.
 * @param isLoading - Whether the invoice is still loading.
 * @param quantity - The quantity of the product.
 * @param unitPrice - The unit price of the product.
 * @returns a header component for a given product's detail.
 */
const ProductDetailHeader: React.FC<ProductDetailHeaderI> = ({ description, isLoading, quantity, unitPrice }) => {
  return (
    <div className="flex mb-1">
      <div
        className="flex-grow text-base text-gray-900"
        data-qa="productDescriptionLabel"
        data-testid="productDescriptionLabel"
      >
        {isLoading ? <SkeletonBorder className="h-6 w-11/12" /> : description}
      </div>
      <div
        className="flex-none w-12 text-center text-sm"
        data-qa="productQuantityLabel"
        data-testid="productQuantityLabel"
      >
        {isLoading ? <SkeletonBorder className="h-6 w-20" /> : quantity}
      </div>
      <div
        className="flex-none w-40 text-right text-sm"
        data-qa="productUnitPriceLabel"
        data-testid="productUnitPriceLabel"
      >
        {isLoading ? <SkeletonBorder className="ml-20 h-6 w-20" /> : unitPrice ? convertToCurrency(unitPrice) : 'N/A'}
      </div>
    </div>
  );
};

export default ProductDetailHeader;
