import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { CaseHistoryAdditionalDetailsObject, LocalCaseHistoryItem } from 'shared/constants/case-detail.constants';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { getStatusDetailRecord } from 'shared/helpers/order-detail/order-detail.helper';
import { cn, isObjectFieldEmpty } from 'shared/utils';
import { AdditionalDetailsFallback } from './AdditionalDetailsFallback';
import { AdditionalDetailsShipping } from './AdditionalDetailsShipping/AdditionalDetailsShipping';
import { OrderItemDetails } from './OrderItemDetails/OrderItemDetails';
import { PreferenceOverrideDetails } from './PreferenceOverrideDetails';

/**
 * Props of the StatusDetail component.
 */
interface StatusDetailProps {
  /**
   * The status to display.
   */
  status?: string;
  /**
   * The reason for the status.
   */
  statusReason?: string;
  /**
   * Additional class name for styling purposes.
   */
  className?: string;
}

/**
 * Component for displaying the status and reason.
 * @param status - The status to display.
 * @param statusReason - The reason for the status.
 * @param className - Additional class name for styling purposes.
 * @returns A JSX element representing the StatusDetail component.
 */
export const StatusDetail: React.FC<StatusDetailProps> = ({ status, statusReason, className }) => (
  <div className={cn('flex flex-col gap-2', className)}>
    {status && (
      <div>
        <strong>Status:</strong> {status}
      </div>
    )}
    {statusReason && (
      <div>
        <strong>Reason:</strong> {statusReason}
      </div>
    )}
  </div>
);

/**
 * Props of the AdditionalDetailsWithNewAndOld component.
 */
export interface AdditionalDetailsWithNewAndOldProps {
  /**
   * The type of case history event.
   */
  eventType: CaseHistoryEventType;
  /**
   * The new additional details.
   */
  newData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The old additional details.
   */
  oldData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The ID associated with the case history.
   */
  id: string;
  /**
   * Indicates if it's a fallback event.
   */
  isFallbackEvent: boolean;
  /**
   * The timezone code for UTC conversion.
   */
  utcConversionTimeZoneCode: LocalCaseHistoryItem['utcConversionTimeZoneCode'];
}

/**
 * Component for displaying additional details with new and old data.
 * @param eventType - The type of case history event.
 * @param newData - The new additional details.
 * @param oldData - The old additional details.
 * @param id - The ID associated with the case history.
 * @param isFallbackEvent - Indicates if it's a fallback event.
 * @param utcConversionTimeZoneCode - The timezone code for UTC conversion.
 * @returns A JSX element representing the AdditionalDetailsWithNewAndOld component.
 */
export const AdditionalDetailsWithNewAndOld: FC<AdditionalDetailsWithNewAndOldProps> = ({
  newData,
  oldData,
  eventType,
  id,
  isFallbackEvent,
  utcConversionTimeZoneCode,
}) => {
  const localOldData = oldData || {};
  const localNewData = newData || {};

  const statusDetailRecord = getStatusDetailRecord(localOldData, localNewData);
  const isOldStatusEmpty = isObjectFieldEmpty(statusDetailRecord.old);
  const isNewStatusEmpty = isObjectFieldEmpty(statusDetailRecord.new);

  return (
    <div className="flex flex-col gap-2">
      {(!isOldStatusEmpty || !isNewStatusEmpty) && (
        <div className="flex flex-1 items-center justify-between">
          {<StatusDetail status={statusDetailRecord.old.status} statusReason={statusDetailRecord.old.statusReason} />}
          <div className="mx-6">
            <ArrowRightIcon className="w-5 h-5" />
          </div>
          {<StatusDetail status={statusDetailRecord.new.status} statusReason={statusDetailRecord.new.statusReason} />}
        </div>
      )}

      <OrderItemDetails oldData={oldData} newData={newData} />

      {eventType === CaseHistoryEventType.PreferenceOverride && (
        <PreferenceOverrideDetails oldData={oldData} newData={newData} id={id} />
      )}

      <AdditionalDetailsShipping
        oldData={oldData}
        newData={newData}
        utcConversionTimeZoneCode={utcConversionTimeZoneCode}
      />
      {isFallbackEvent && (
        <AdditionalDetailsFallback
          oldData={oldData}
          newData={newData}
          eventType={eventType}
          excludeKeys={[
            'Shipping',
            'OrderItems',
            'Status',
            'StatusReason',
            'Default',
            'ShippingPreference',
            'TechnicalPreference',
          ]}
          utcConversionTimeZoneCode={utcConversionTimeZoneCode}
        />
      )}
    </div>
  );
};
