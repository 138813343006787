/**
 * Component for displaying global fetch error content.
 *
 * @param error - The error object.
 * @returns JSX.Element - The rendered component.
 */
const GlobalFetchErrorContent: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <div className="flex flex-col">
      <div className="text-red-500">{error.message}</div>
    </div>
  );
};

/**
 * Props for the ErrorContent component.
 */
interface ErrorContentProps {
  /** The error object. */
  error: Error | null;
  /** Function to render the error. */
  render?: (error: Error) => React.ReactNode;
}

/**
 * Component for displaying query fetch error content.
 *
 * @param error - The error object.
 * @param render - Function to render the error.
 * @returns JSX.Element - The rendered component.
 */
export const QueryFetchErrorContent: React.FC<ErrorContentProps> = ({ error, render }) => {
  if (!error) return null;

  if (render) {
    return <>{render(error)}</>;
  }

  return <GlobalFetchErrorContent error={error} />;
};
