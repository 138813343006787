export enum DeployedEnvironments {
  LMSDEV = 'LMS-DEV',
  LMSQA = 'LMS-QA',
  LMSUAT = 'LMS-UAT',
  LMSPROD = 'LMS-PROD',
}

export const DeployedEnvironmentsNameMapping: Record<DeployedEnvironments, string> = {
  [DeployedEnvironments.LMSDEV]: 'dev',
  [DeployedEnvironments.LMSQA]: 'qa',
  [DeployedEnvironments.LMSUAT]: 'uat',
  [DeployedEnvironments.LMSPROD]: 'prod',
} as const;
