import { ManufacturingLocationQuery, ManufacturingLocationQueryVariables, ManufacturingLocationsQuery } from 'API';
import { manufacturingLocationQuery, manufacturingLocationsQuery } from 'graphql/queries';
import Http from './http';

/**
 * provides a details of a manufacturing location
 * like address, contact info, invoice cut-off time etc.,
 *
 * @param input - location id of manufacturing location
 *
 * @returns response body representing the manufacturing location requested
 */
export const getManufacturingLocation = async (input: ManufacturingLocationQueryVariables) => {
  const response = await Http.handleGraphqlOperation<ManufacturingLocationQuery>(manufacturingLocationQuery, input);
  return Http.processGraphqlResponse(response.manufacturingLocation, 'ManufacturingLocation');
};

/**
 * fetches a list of manufacturing locations available
 *
 * @returns a list of all available manufacturing locations
 */
export const getManufacturingLocations = async () => {
  const response = await Http.handleGraphqlOperation<ManufacturingLocationsQuery>(manufacturingLocationsQuery);
  const result = Http.processGraphqlResponse(response.manufacturingLocations, 'ManufacturingLocationsWrapper');
  return result.locations;
};
