import { DeliveryMethod } from 'API';
import { SubscriptionObject } from 'providers/SubscriptionProvider';
import { subscribeToDoctorPickupLabel, subscribeToShippingLabel } from 'shared/api/invoice.api';
import { printShippingLabel } from './print.helper';

/**
 * Fetches and prints the shipping label for an order.
 * @param data - An object containing the order number, whether to print the inbound label, and the delivery method type.
 * @returns A Promise that resolves when the shipping label has been printed.
 * @throws An error if there was an issue printing the shipping label.
 */
export const fetchPrintShippingLabel = async (data: {
  orderNumber: string;
  printInboundLabel?: boolean;
  type?: DeliveryMethod | null;
}) => {
  const { orderNumber, printInboundLabel, type } = data;
  // TODO: Should be getting shipping label from subscription payload to pass into below function so we don't have to fetch.
  try {
    await printShippingLabel(orderNumber, printInboundLabel);
  } catch {
    const error = `Error printing ${
      type && type === DeliveryMethod.DoctorPickup ? `doctor pickup` : ` shipping printing`
    } label.`;
    console.error(error);
    throw new Error(error);
  }
};

interface HandlePrintShippingLabelI {
  subId: string;
  orderNumber: string;
  sessionId: string;
  type?: DeliveryMethod | null;
  addSubscription: (subscription: SubscriptionObject) => void;
  printInboundLabel?: boolean;
  callback?: () => void;
}

/**
 * Handles printing a shipping label.
 * @param addSubscription - The method for adding a subscription to an array of subscriptions.
 * @param orderNumber - The order number of the target case.
 * @param sessionId - The session ID.
 * @param subId - The subscription ID.
 * @param type - the type of label we are subscribing to.
 * @param printInboundLabel - whether to print the inbound label or not.
 */
export const handlePrintShippingLabelSubscription = ({
  addSubscription,
  orderNumber,
  sessionId,
  type,
  subId,
  printInboundLabel,
  callback,
}: HandlePrintShippingLabelI) => {
  let subObject: SubscriptionObject;
  if (type && type === DeliveryMethod.DoctorPickup) {
    subObject = {
      uuid: subId,
      subscription: subscribeToDoctorPickupLabel({
        OrderNumber: orderNumber,
        SessionId: sessionId,
      }).subscribe({
        next: async () => {
          // no need to keep this open
          subObject.subscription.unsubscribe();

          // TODO: Should be getting shipping label from subscription payload to pass into below function so we don't have to fetch.
          try {
            await printShippingLabel(orderNumber, printInboundLabel);
          } catch {
            console.error('Error printing doctor pickup label.');
          } finally {
            if (callback) {
              callback();
            }
          }
        },
        error: (error: string) => console.warn(error),
      }),
    };
  } else {
    // set up a subscription to listen for work order ready
    subObject = {
      uuid: subId,
      subscription: subscribeToShippingLabel({
        OrderNumber: orderNumber,
        SessionId: sessionId,
      }).subscribe({
        next: async () => {
          // no need to keep this open
          subObject.subscription.unsubscribe();

          // TODO: Should be getting shipping label from subscription payload to pass into below function so we don't have to fetch.
          try {
            await printShippingLabel(orderNumber, printInboundLabel);
          } catch {
            console.error('Error printing shipping label.');
          } finally {
            if (callback) {
              callback();
            }
          }
        },
        error: (error: string) => console.warn(error),
      }),
    };
  }

  // add this sub to context so we could access it from anywhere
  addSubscription(subObject);
};
