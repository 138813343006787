import { DeployedEnvironments, DeployedEnvironmentsNameMapping } from 'shared/constants/deployed-constants';

/**
 * represents a project configuration object for an env
 */
export class ProjectInfo {
  private API_URL_TEMPLATE = 'https://lms-api.{0}.lms.glidewell.com/graphql';

  /**
   * an api key individual to service user/individual user for using different AWS services
   */
  apiKey: string;

  /**
   * api url endpoint to be pointed for different environment
   */
  apiUrl: string;

  /**
   * application name being deployed
   */
  deployedApplication: string;

  /**
   * represents the SHA of git commit which is being used for deployment
   */
  commitSha: string;

  /**
   * represents tag being used to identify the deployment
   */
  deployedVersion: string;

  /**
   * represents the environment to which application is being deployed
   */
  deployedEnvironment: DeployedEnvironments;

  /**
   * represents the user responsible for deploying the application
   */
  deployedBy: string;

  /**
   * represents the time at which deployment is initiated
   */
  deployedTime: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(data: any) {
    this.apiKey = data.apiKey || '';
    this.deployedEnvironment = data.deployedEnvironment || '';
    this.deployedVersion = data.deployedVersion || '';
    this.deployedApplication = data.deployedApplication || '';
    this.commitSha = data.deployedApplication || '';
    this.deployedBy = data.deployedBy || '';
    this.deployedTime = data.deployedTime || '';

    if (this.getAppEnvironment() === DeployedEnvironments.LMSPROD) {
      this.apiUrl = this.API_URL_TEMPLATE.replace('.{0}', '');
    } else {
      this.apiUrl = this.API_URL_TEMPLATE.replace('{0}', DeployedEnvironmentsNameMapping[this.deployedEnvironment]);
    }
  }

  /**
   * @returns the string representing environment to deploy
   */
  getAppEnvironment = (): string => {
    return this.deployedEnvironment;
  };

  /**
   * @returns the string representing the API key for accessing various AWS services
   */
  getApiKey = (): string => {
    return this.apiKey;
  };

  /**
   * @returns tag being used to identify the deployment
   */
  getAppVersion = (): string => {
    return this.deployedVersion;
  };
}
