import { Maybe } from 'API';
import { Table } from 'components/common/Table/Table';
import { useEffect, useState } from 'react';
import {
  relatedMPCaseTableColumns,
  relatedOriginalCaseTableColumns,
  relatedOtherCaseTableColumns,
  relatedRMACaseTableColumns,
} from 'shared/constants/case-detail.constants';
import { LmsOrder, RelatedOrder, RelatedPendingOrder, RelatedRmaOrder } from 'shared/models';
import { isOrderData, isPendingOrderData, isRmaOrderData } from 'shared/utils';

/**
 * Props for the CaseRelated component.
 */
interface RelatedCasesProps {
  /** The order number associated with the cases. */
  orderNumber: string;
  /** The original order number. */
  originalOrderNumber?: Maybe<string>;
  /** An array of LMS orders. */
  orders: LmsOrder[];
}

/**
 * Component to display an overview of a product in a case.
 * @param product - The product details.
 * @param index - The index of the product in the list.
 * @param count - The total count of products in the list.
 * @param tabSelectHandler - Handler function for selecting a tab.
 * @returns The JSX element representing the CaseOverviewProduct component.
 */
const CaseRelated: React.FC<RelatedCasesProps> = ({ orderNumber, originalOrderNumber, orders }) => {
  const [originalCase, setOriginalCase] = useState<RelatedOrder | RelatedRmaOrder>();
  const [mp, setMpCases] = useState<RelatedOrder[]>([]);
  const [rma, setRmaCases] = useState<RelatedRmaOrder[]>([]);
  const [other, setOtherCases] = useState<(RelatedOrder | RelatedPendingOrder)[]>([]);

  /**
   * Sort the orders into different buckets to be displayed in different tables
   */
  useEffect(() => {
    const mps: RelatedOrder[] = [];
    const rmas: RelatedRmaOrder[] = [];
    const others: (RelatedOrder | RelatedPendingOrder)[] = [];

    orders.forEach(order => {
      if (originalOrderNumber && order.orderNumber === originalOrderNumber && order.__typename !== 'PendingOrder') {
        setOriginalCase(order);
      } else if (order.orderNumber.includes('MP') && isOrderData(order)) {
        mps.push(order);
      } else if (isRmaOrderData(order)) {
        rmas.push(order);
      } else if (isOrderData(order) || isPendingOrderData(order)) {
        others.push(order);
      }
    });

    setMpCases(mps);
    setRmaCases(rmas);
    setOtherCases(others);
  }, [orderNumber, orders, originalOrderNumber]);

  return (
    <div className="p-6">
      {!originalCase && !mp.length && !rma.length && !other.length && (
        <div className="pt-24 text-center">There are no related cases to show</div>
      )}
      {originalCase && (
        <div className="mb-6">
          <Table label="Original" columns={relatedOriginalCaseTableColumns} rows={[originalCase]} loading={false} />
        </div>
      )}
      {!!mp.length && (
        <div className="mb-6">
          <Table label="Multi-Phase" columns={relatedMPCaseTableColumns} rows={mp} loading={false} />
        </div>
      )}
      {!!rma.length && (
        <div className="mb-6">
          <Table label="RMA" columns={relatedRMACaseTableColumns} rows={rma} loading={false} />
        </div>
      )}
      {!!other.length && (
        <div className="mb-6">
          <Table label="Other" columns={relatedOtherCaseTableColumns} rows={other} loading={false} />
        </div>
      )}
    </div>
  );
};

export default CaseRelated;
