import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext } from 'react';

/**
 * The Notes component allows users to add notes to an order.
 * @returns JSX element representing the Notes component.
 */
const Notes: React.FC = () => {
  const { order } = useContext(OrderModuleContext);
  const { patchOrder } = useContext(OrderModuleActionsContext);

  /**
   * Update order in context when notes change.
   * @param e - textarea change event.
   */
  const addNoteHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    patchOrder({
      notes: e.target.value || '',
    });
  };

  return (
    <>
      <div className="pb-6">
        <textarea
          value={order.notes || ''}
          className="rounded-md show-sm border-gray-300 text-sm w-full"
          rows={6}
          placeholder="Enter a note"
          onChange={addNoteHandler}
        ></textarea>
      </div>
    </>
  );
};

export default Notes;
