import { OrderProductAttributeInput, ProductFullAttribute } from 'API';
import NumberInput from 'components/common/NumberInput/NumberInput';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useMemo } from 'react';
import { ErrorMessage } from 'shared/enums';
import { LocalOrderItemInput } from 'shared/models';

/**
 * Props for the Quantity component.
 */
interface QuantityProps {
  /**
   * The attribute associated with the quantity.
   */
  attribute: ProductFullAttribute;
  /**
   * The order item for which the quantity is being set.
   */
  orderItem: LocalOrderItemInput;
  /**
   * Callback function to handle attribute changes.
   */
  onAttributeChange: (attributeInput: OrderProductAttributeInput) => void;
}

/**
 * The Quantity component allows setting the quantity for a specific attribute.
 * @param attribute - The attribute associated with the quantity.
 * @param orderItem - The order item for which the quantity is being set.
 * @param onAttributeChange - Callback function to handle attribute changes.
 * @returns JSX element representing the Quantity component.
 */
const Quantity: React.FC<QuantityProps> = ({ attribute, orderItem, onAttributeChange }) => {
  const { onSubmitAlertCount } = useContext(OrderModuleContext);
  const { hasValidQuantities } = useContext(OrderModuleActionsContext);

  const attributeContext = useMemo(() => {
    return orderItem.attributes?.find(attr => attr.name === attribute.name);
  }, [attribute.name, orderItem.attributes]);

  const errorMessage = useMemo<string | undefined>(() => {
    if (onSubmitAlertCount <= 0 || hasValidQuantities(orderItem)) return;
    return ErrorMessage.QUANTITY_MISMATCH;
  }, [orderItem, onSubmitAlertCount, hasValidQuantities]);

  return (
    <div>
      <NumberInput
        id={attribute.name}
        value={attributeContext?.value || 0}
        label={attributeContext?.name}
        isRequired={attribute.isRequired ?? false}
        onChange={value =>
          onAttributeChange({
            name: attribute.name,
            type: attribute.type,
            value,
            quantity: parseInt(value || '1', 10),
          })
        }
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default Quantity;
