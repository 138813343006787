import { CaseAlert, Order } from 'API';
import { TableColumn } from 'components/common/Table/types';
import { getDateAndTimeInShortFormat, getPatientFullName } from 'shared/utils';
import { CaseAlertDetails } from './details.constants';

type CaseAlertWithTimezone = CaseAlert & {
  utcConversionTimeZoneCode: Order['utcConversionTimeZoneCode'];
};

export const caseAlertColumns: TableColumn<CaseAlertWithTimezone>[] = [
  {
    id: 'name',
    name: 'Patient Name',
    render: ({ row }) => getPatientFullName(row.patientFirstName, row.patientLastName) || 'No Patient Info',
  },
  {
    id: 'reason',
    name: 'Reason',
    render: ({ row }) => <CaseAlertDetails caseAlert={row} />,
  },
  {
    id: 'createdBy',
    name: 'Added By',
  },
  {
    id: 'appliedDate',
    name: 'Date Applied',
    render: ({ row }) =>
      row.appliedDate ? getDateAndTimeInShortFormat(row.appliedDate, row.utcConversionTimeZoneCode) : '',
  },
];
