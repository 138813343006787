import { graphqlOperation, GraphQLOptions, GraphQLResult } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
import Axios, { AxiosResponse } from 'axios';
import { ROUTES } from 'components/navigation/Constants';
import { AZURE_AUTHENTICATION_ERROR } from 'shared/constants/constants';
import { getError, InternalErrorName } from 'shared/helpers/error-helper';
import { getAzureAuthenticationResult } from 'shared/helpers/msal.helper';
import history from 'shared/lib/history';
import ConfigService from 'shared/services/config.service';

type AppSyncData = { __typename: string; message?: string };
type ProcessGraphqlResponse<TData extends AppSyncData, TExpect extends string> = TData extends {
  __typename: TExpect;
}
  ? TData
  : never;

class HttpService {
  /**
   * Wrap get requests, add authorization header to request.
   * @param endpoint - endpoint to request.
   */
  async get<T>(endpoint: string): Promise<AxiosResponse<T>> {
    return Axios.get<T>(endpoint)
      .catch((err: Error) => {
        console.error(err);
        return {};
      })
      .then();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async post<T>(endpoint: string, data: any): Promise<AxiosResponse<T>> {
    return Axios.post<T>(endpoint, data)
      .catch((err: Error) => {
        console.error(err);
        return {};
      })
      .then();
  }

  /**
   * Handle graphql query responses in a uniform way.
   *
   * @param query -the graphql Query string.
   * @param data - any data we need to send as variables.
   * @param authMode - available auth options {@link GraphQLOptions#authMode}
   *
   * @returns The graphql result.
   */
  handleGraphqlOperation = async <T>(
    query: string,
    data?: object,
    authMode: GraphQLOptions['authMode'] = 'AWS_LAMBDA'
  ): Promise<T> => {
    let authToken: string | undefined = undefined;
    try {
      // Only add authToken if we are using AWS_LAMBDA authMode and we have an active account.
      if (authMode === 'AWS_LAMBDA') {
        const msalInstance = ConfigService.getMSALInstance();
        const activeAccount = msalInstance.getActiveAccount();
        const response = await getAzureAuthenticationResult(msalInstance, activeAccount);
        authToken = `Bearer ${response.idToken}`;
      }

      const response = (await API.graphql<GraphQLResult<T>>({
        ...graphqlOperation(query, data, authToken),
        authMode,
      })) as GraphQLResult<T>;
      if (response.data) {
        return response.data;
      }

      if (response.errors) {
        throw getError(response.errors[0].message, InternalErrorName);
      }

      throw getError('No data returned.', InternalErrorName);
    } catch (e) {
      // If we get an Azure authentication error, redirect to sign in page.
      if (e instanceof Error && e.name === AZURE_AUTHENTICATION_ERROR) {
        history.push(ROUTES.SIGN_IN_PATH);
      }
      if (e instanceof Error) {
        throw getError(e.message || 'Error processing request.', e.name || InternalErrorName);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const error = e as any;

      // API.graphql throws an error if GraphQLResult contains an "errors" array. We can still return the partial result.
      if (error.data) {
        return error.data;
      }

      throw getError(error.message || 'Error processing request.', error.name || InternalErrorName);
    }
  };

  /**
   * Process the graphql response and return the expected data type or throw an error.
   *
   * @param data - data from API.
   * @param expectedTypename - the `__typename` we are looking for. Done to support Unions which can return many types.
   *
   * @returns The data type we are expecting from API result.
   */
  processGraphqlResponse = <TData extends AppSyncData, TExpectType extends string>(
    data: TData,
    expectedTypename: TExpectType | TExpectType[]
  ) => {
    if (!expectedTypename.includes(data.__typename as TExpectType)) {
      throw getError(data.message || 'Internal Error', data.__typename);
    }

    return data as ProcessGraphqlResponse<TData, TExpectType>;
  };

  /**
   * Convert string to base64
   */
  convertStringToB64 = (string: string) => {
    return window.btoa(string);
  };
}

const Http = new HttpService();
export default Http;
