import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CreatedOrder } from 'shared/models';

/**
 * if we are in case edit, we need to pass the state to the next page,
 * The reason is, Currently Invoicing Bundle split page expects this state to work properly.
 */
export const useInvoiceLocationState = () => {
  const { id: orderNumber } = useParams<{ id: string }>();
  const { state } = useLocation();

  const invoiceState = useMemo(() => {
    const ordersScanned = state?.ordersScanned || [];

    if (!ordersScanned.length) return state;

    // If we are in invoice bundle split case edit mode, we need to update the scanned order with previousScannedInvoiceCase value. This is because of the current logic of the invoicing bundle split case.
    if (state?.previousScannedInvoiceCase) {
      state.ordersScanned = state.ordersScanned.map((order: CreatedOrder) => {
        // If the scanned order number matches the updating order number of the order, we need to update the scanned order with previousScannedOrder value.
        if (order.orderNumber === orderNumber) {
          return state.previousScannedInvoiceCase;
        }
        return order;
      });
    }
    return state;
  }, [orderNumber, state]);

  return invoiceState;
};
