import {
  CaseAlertsQuery,
  CaseAlertsQueryVariables,
  UpdateCaseAlertStatusesInput,
  UpdateCaseAlertStatusesMutation,
} from 'API';
import { updateCaseAlertStatuses as updateCaseAlertStatusesMutation } from 'graphql/mutations';
import { caseAlertsQuery } from 'graphql/queries';
import Http from './http';

/**
 * Retrieves case alerts for a given customer's billing account and provider ID via AppSync.
 * @param props - An object container a customer's billing account and provider ID.
 * @returns case alert results for the given criteria.
 */
export const getCaseAlerts = async (props: CaseAlertsQueryVariables) => {
  const response = await Http.handleGraphqlOperation<CaseAlertsQuery>(caseAlertsQuery, props);
  return Http.processGraphqlResponse(response.caseAlerts, 'CaseAlerts');
};

/**
 * Applies case alerts to a target order.
 * @param props - An object containing the target alerts, order number, and information about who is applying these alerts.
 * @returns the resulting backend response.
 */
export const updateCaseAlertStatuses = async (props: UpdateCaseAlertStatusesInput) => {
  const response = await Http.handleGraphqlOperation<UpdateCaseAlertStatusesMutation>(updateCaseAlertStatusesMutation, {
    input: props,
  });
  return Http.processGraphqlResponse(response.updateCaseAlertStatuses, 'UpdateCaseAlertsSuccess');
};
