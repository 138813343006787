import { FC } from 'react';
import { cn } from 'shared/utils';

/**
 * Props for the SkeletonBox component.
 */
interface SkeletonBoxProps {
  /**
   * Additional class name for the component.
   */
  className?: string;
}

/**
 * Skeleton box component.
 * @param className - Additional class name for the component.
 * @returns JSX.Element representing the SkeletonBox component.
 */
export const SkeletonBox: FC<SkeletonBoxProps> = ({ className }) => {
  return (
    <div className={cn('animate-pulse flex', className)}>
      <div className="rounded-sm bg-gray-300 flex-grow w-full"></div>
    </div>
  );
};
