/**
 * Props for the ProductTitleAndValue component.
 */
interface ProductTitleAndValueProps {
  /**
   * The title of the product.
   */
  title: string;
  /**
   * The value of the product.
   */
  value: string;
  /**
   * Additional CSS class name for styling.
   */
  className?: string;
}

/**
 * The ProductTitleAndValue component displays a title and its corresponding value.
 * @param title - The title of the product.
 * @param value - The value of the product.
 * @param className - Additional CSS class name for styling.
 * @returns JSX element representing the ProductTitleAndValue component.
 */
export const ProductTitleAndValue: React.FC<ProductTitleAndValueProps> = ({ title, value, className }) => {
  return (
    <li className={className}>
      <span className="text-gray-500 mr-2">{title}:</span>
      <span className="text-gray-900">{value}</span>
    </li>
  );
};
