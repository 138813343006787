import { Dialog } from '@headlessui/react';
import { CubeIcon, DocumentTextIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { FileType } from 'shared/enums';
import { getFileType } from 'shared/utils';

/**
 * Props for the BadFilesModal component.
 */
interface BadFilesModalProps {
  /**
   * An array of bad files to display.
   */
  badFiles?: File[];
  /**
   * Callback function to close the modal.
   */
  onClose: () => void;
}

/**
 * The BadFilesModal component displays a modal to inform users about restricted files.
 * @param badFiles - An array of bad files to display.
 * @param onClose - Callback function to close the modal.
 * @returns JSX element representing the BadFilesModal component.
 */
const BadFilesModal: React.FC<BadFilesModalProps> = ({ badFiles = [], onClose }) => {
  // Get me a different icon based on the type of file
  const renderFileIcon = (fileName: string) => {
    const fileType = getFileType(fileName);

    switch (fileType) {
      case FileType.Document:
        return <DocumentTextIcon className="text-green-500 h-7 w-5 mr-2" />;
      case FileType.Image:
        return <PhotoIcon className="text-pink-500 h-7 w-5 mr-2" />;
      case FileType.Design:
        return <CubeIcon className="text-purple-500 h-7 w-5 mr-2" />;
      default:
        break;
    }
  };

  return (
    <>
      <div className="flex">
        <div>
          <div className="gap-4 p-6">
            <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
              Restricted file{badFiles.length > 1 ? 's' : ''}
            </Dialog.Title>
            <div className="mt-2">
              <p className="text-sm text-gray-500 max-w-xl">
                The file{badFiles.length > 1 ? 's' : ''} you selected below cannot be uploaded. Please select a
                different file or try again.
              </p>
            </div>
            <div className="mt-4">
              {badFiles.map((file: File, index: number) => (
                <div key={index} className="flex">
                  <div>{renderFileIcon(file.name)}</div>
                  <div className="flex-grow flex-1 text-gray-900 text-sm pt-1 break-all">{file.name}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={onClose}
              data-qa="doneButton"
            >
              Okay
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BadFilesModal;
