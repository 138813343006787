interface IPubSub {
  events: Record<string, Array<(data: unknown) => void>>;
  subscribe: (event: string, callback: (data: unknown) => void) => VoidFunction;
  publish: (event: string, data: unknown) => unknown[];
  subscribeOnce: (event: string, callback: (data: unknown) => void) => VoidFunction;
}

export class PubSub implements IPubSub {
  events: Record<string, Array<(data: unknown) => void>>;

  /**
   * function to unsubscribe for the given topic
   */
  private unsubscribeOnce!: VoidFunction;
  constructor() {
    this.events = {};
  }

  /**
   * listens to events published to a topic
   *
   * @param event - name of topic
   * @param callback - callback function to handle an event
   */
  subscribe(event: string, callback: (data: unknown) => void) {
    if (!(event in this.events)) {
      this.events[event] = [];
    }
    this.events[event].push(callback);
    return () => {
      this.events[event] = this.events[event].filter(cb => cb !== callback);
    };
  }

  /**
   * publishes an event to a given topic
   *
   * @param event - topic name to which event should be published
   * @param data - data payload to publish to topic
   */
  publish(event: string, data?: unknown) {
    if (!(event in this.events)) {
      return [];
    }
    return this.events[event].map(callback => callback(data));
  }

  /**
   * listens to a single event on a given topic
   *
   * @param event - name of topic
   * @param callback - callback function to handle an event
   */
  subscribeOnce(event: string, callback: (data: unknown) => void) {
    if (event in this.events) return this.unsubscribeOnce;
    this.unsubscribeOnce = this.subscribe(event, callback);
    return this.unsubscribeOnce;
  }
}

export const pubSub = new PubSub();
