import {
  addressFragment,
  appliedCouponsFragment,
  appliedCreditsFragment,
  appliedDiscountsFragment,
  baseOrderItemFragment,
  bundleOrderFragment,
  caseAlertFragment,
  invoiceShippingFragment,
  legacyOrderFragment,
  orderEnclosedItemFragment,
  orderFragment,
  pendingOrderFullFragment,
  phoneFragment,
  relatedCasesLegacyOrderFragment,
  relatedCasesOrderFragment,
  relatedCasesRmaOrderFragment,
  rmaOrderFragment,
  searchedOrderFragment,
  shippingFragment,
  trackingNumberItemFragment,
} from './fragments';

/* ACCOUNT LOOKUP */

export const account = /* GraphQL */ `
  query account($billingAccountId: String!) {
    account(billingAccountId: $billingAccountId) {
      ... on Account {
        __typename
        type
        billingAccountId
        closedReason
        currencyCode
        status
        standing
        addresses {
          ...addressFragment
        }
        phoneNumbers {
          ...phoneFragment
        }
        emailAddresses {
          type
          emailAddress
        }
        labOrigin
        labOriginId
        phoneNumbers {
          country
          number
          type
        }
        practiceId
        practiceName
        practiceType
        providers {
          providerId
          firstName
          lastName
          middleName
          suffix
          salutation
          providerStatus
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${phoneFragment}
  ${addressFragment}
`;

/* INVOICE ACCOUNT LOOKUP */

export const invoiceAccount = /* GraphQL */ `
  query invoiceAccount($billingAccountId: String!) {
    account(billingAccountId: $billingAccountId) {
      ... on Account {
        __typename
        billingAccountId
        currencyCode
        status
        standing
        addresses {
          ...addressFragment
        }
        phoneNumbers {
          type
          number
          extension
          country
        }
        emailAddresses {
          type
          emailAddress
        }
        practiceName
        providers {
          providerId
          firstName
          lastName
          middleName
          suffix
          salutation
          providerStatus
        }
        finance {
          billingAccountId
          codAmount
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${addressFragment}
`;

/* ACCOUNT SEARCH */

export const customerAccountSearchQuery = /* GraphQL */ `
  query customerAccountSearch($input: CustomerAccountSearchInput!) {
    customerAccountSearch(input: $input) {
      __typename
      ... on CustomerAccountSearchResponse {
        __typename
        accounts {
          account {
            billingAccountId
            phoneNumbers {
              number
              type
            }
            practice {
              practiceId
              practiceName
            }
            shippingAddress
            standing
            status
          }
          fullName
          licenses
          phoneNumbers {
            number
            type
          }
          providerId
        }
      }
      ... on GenericError {
        __typename
        message
      }
    }
  }
`;

/* CLASSIFICATION */

export const classificationsQuery = /* GraphQL */ `
  query Classifications {
    classifications {
      __typename
      ... on ClassificationsWrapper {
        __typename
        classifications {
          materialName
          restorations {
            restorationName
            productCode
          }
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on BadRequestError {
        __typename
        message
      }
    }
  }
`;

/* CONFIGS */

export const getConfigs = /* GraphQL */ `
  query GetConfigs {
    getConfigs {
      __typename
      ... on ConfigData {
        __typename
        id
        description
        lastUpdatedDateUTC
        sections {
          name
          description
          settings {
            key
            value
            description
            overrides
          }
        }
      }
      ... on GenericError {
        __typename
        message
      }
    }
  }
`;

/* COUPON LOOKUP */

export const couponQuery = /* GraphQL */ `
  query coupon($couponCode: String!) {
    coupon(couponCode: $couponCode) {
      ... on Coupon {
        __typename
        currencyCode
        code
        activationDate
        createdDate
        createdBy
        discountAmount
        discountType
        expirationDate
        components {
          abbreviation
          type
          value
        }
        status
        usageLimit {
          accountLimit
          orderLimit
          rxLimit
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
`;

/* ENCLOSED ITEMS */

export const enclosedItems = /* GraphQL */ `
  query enclosedItems($category: EnclosedItemCategory) {
    enclosedItems(category: $category) {
      ... on EnclosedItemsWrapper {
        __typename
        enclosedItems {
          type
          itemCode
          category
        }
      }
      ... on GenericError {
        __typename
        message
      }
    }
  }
`;

/* ORDER */

export const orderLookup = /* GraphQL */ `
  query order($orderNumber: String!) {
    order(orderNumber: $orderNumber) {
      ... on PendingOrder {
        ...pendingOrderFullFragment
      }
      ... on Order {
        __typename
        ...orderFragment
        bundles {
          ...bundleOrderFragment
        }
      }
      ... on RmaOrder {
        __typename
        ...rmaOrderFragment
        bundles {
          ...bundleOrderFragment
        }
      }
      ... on LegacyOrder {
        ...legacyOrderFragment
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${baseOrderItemFragment}
  ${rmaOrderFragment}
  ${orderFragment}
  ${bundleOrderFragment}
  ${legacyOrderFragment}
  ${pendingOrderFullFragment}
`;

export const orderLookupFull = /* GraphQL */ `
  query orderFull($orderNumber: String!) {
    order(orderNumber: $orderNumber) {
      ... on PendingOrder {
        ...pendingOrderFullFragment
      }
      ... on Order {
        __typename
        ...orderFragment
        bundles {
          ...bundleOrderFragment
        }
        appliedCoupons {
          ...appliedCouponsFragment
        }
        appliedDiscounts {
          ...appliedDiscountsFragment
        }
        appliedCredits {
          ...appliedCreditsFragment
        }
      }
      ... on RmaOrder {
        __typename
        ...rmaOrderFragment
        bundles {
          ...bundleOrderFragment
        }
        appliedCoupons {
          ...appliedCouponsFragment
        }
        appliedDiscounts {
          ...appliedDiscountsFragment
        }
        appliedCredits {
          ...appliedCreditsFragment
        }
      }
      ... on LegacyOrder {
        ...legacyOrderFragment
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${baseOrderItemFragment}
  ${rmaOrderFragment}
  ${orderFragment}
  ${bundleOrderFragment}
  ${legacyOrderFragment}
  ${appliedCouponsFragment}
  ${appliedCreditsFragment}
  ${appliedDiscountsFragment}
  ${pendingOrderFullFragment}
`;

export const orderInvoiceLookup = /* GraphQL */ `
  query orderInvoiceLookup($orderNumber: String!) {
    order(orderNumber: $orderNumber) {
      ... on PendingOrder {
        __typename
        orderNumber
        status
        shipping {
          ...shippingFragment
        }
      }
      ... on RmaOrder {
        __typename
        orderNumber
        status
        bundles {
          splitBundle {
            bundledOrderNumbers {
              orderNumber
            }
            detachedOrderNumbers {
              orderNumber
            }
            bundleId
          }
        }
        statusReason
        patientFirstName
        patientLastName
        patientId
        billingAccountId
        totalDiscountAmount
        subtotalAmount
        orderItems {
          ...baseOrderItemFragment
          addOns {
            type
            name
            value
            quantity
            unitPrice
          }
        }
        shipping {
          ...shippingFragment
        }
      }
      ... on Order {
        __typename
        orderNumber
        status
        bundles {
          splitBundle {
            bundledOrderNumbers {
              orderNumber
            }
            detachedOrderNumbers {
              orderNumber
            }
            bundleId
          }
        }
        statusReason
        patientFirstName
        patientLastName
        patientId
        billingAccountId
        totalDiscountAmount
        subtotalAmount
        orderItems {
          ...baseOrderItemFragment
          addOns {
            type
            name
            value
            quantity
            unitPrice
          }
        }
        shipping {
          ...shippingFragment
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${baseOrderItemFragment}
  ${shippingFragment}
`;

/* ORDER SEARCH */

export const orderSearch = /* GraphQL */ `
  query orderSearch($input: OrderSearchInput!) {
    orderSearch(input: $input) {
      ... on SearchedOrderWrapper {
        __typename
        orders {
          ...searchedOrderFragment
        }
        total
      }
      ... on GenericError {
        message
      }
    }
  }
  ${searchedOrderFragment}
`;

/* Related cases*/
export const relatedCases = /* GraphQL */ `
  query relatedCases($input: RelatedCasesInput!) {
    relatedCases(input: $input) {
      __typename
      ... on RelatedCasesWrapper {
        orders {
          ... on PendingOrder {
            __typename
            orderNumber
            status
          }
          ... on RmaOrder {
            ...relatedCasesRmaOrderFragment
          }
          ... on Order {
            ...relatedCasesOrderFragment
          }
          ... on LegacyOrder {
            ...relatedCasesLegacyOrderFragment
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }

  ${relatedCasesLegacyOrderFragment}
  ${relatedCasesOrderFragment}
  ${relatedCasesRmaOrderFragment}
`;

/*  Related cases search */

export const relatedCasesSearchQuery = /* GraphQL */ `
  query relatedCasesSearch($input: RelatedCasesSearchInput!, $sort: [OpenSearchSort!], $limit: Int, $offset: Int) {
    relatedCasesSearch(input: $input, sort: $sort, limit: $limit, offset: $offset) {
      __typename
      ... on SearchedOrderWrapper {
        orders {
          ...searchedOrderFragment
        }
        total
      }
      ... on GenericError {
        message
      }
    }
  }
  ${searchedOrderFragment}
`;

/* PRODUCT */

export const productQuery = /* GraphQL */ `
  query product($productCode: String!) {
    product(productCode: $productCode) {
      ... on Product {
        __typename
        attributes {
          defaultValue
          isRequired
          attribute {
            attributeOptions
            attributeRules {
              attributeValue
              displayAttributes {
                name
                type
              }
            }
            attributeValueType
            isPricing
            name
            type
          }
          name
          type
          rank
        }
        components {
          abbreviation
          type
          value
        }
        productCode
        productName
        type
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

/* PRODUCT Full */

export const productFullQuery = /* GraphQL */ `
  query productFull($productCode: String!) {
    productFull(productCode: $productCode) {
      ... on ProductFull {
        __typename
        attributes {
          name
          type
          attributeOptions
          attributeRules {
            attributeValue
            displayAttributes {
              name
              type
            }
          }
          attributeValueType
          defaultValue
          displayName
          rank
          attributeGroupName
          displayType
          isPricing
          isRequired
          isVisible
          isCustomAttribute
        }
        components {
          abbreviation
          type
          value
        }
        productCode
        productName
        type
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

/* PRODUCT ATTRIBUTE */

export const getAttribute = /* GraphQL */ `
  query productAttribute($attributeName: String!) {
    productAttribute(attributeName: $attributeName) {
      ... on Attribute {
        name
        type
        attributeValueType
        attributeOptions
        attributeRules {
          attributeValue
          displayAttributes {
            name
            type
          }
        }
        isPricing
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
`;

/* PRODUCT BRAND */

export const getProductBrandQuery = /* GraphQL */ `
  query productBrand($productCode: String!) {
    productBrand(productCode: $productCode) {
      ... on BrandWrapper {
        __typename
        brands {
          siteId
          brandName
          products {
            productCode
            daysInLab {
              internal
              external
              digital
            }
            attributes {
              name
              type
              daysInLab {
                internal
                external
                digital
              }
            }
          }
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
`;

/* ADDRESS VALIDATION */
export const validateAddress = /* GraphQL */ `
  query validateAddress($input: AddressValidationInput!) {
    validateAddress(input: $input) {
      ... on AddressValidationResponse {
        street1
        street2
        city
        state
        zip
        company
        country
        object
        phone
        residential
        __typename
        verifications {
          delivery {
            success
            details {
              latitude
              longitude
              time_zone
            }
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const preSignedUploadUrl = /* GraphQL */ `
  query preSignedUploadUrl($files: PreSignedUrlFileInput!) {
    preSignedUploadUrl(files: $files) {
      ... on PreSignedUrlWrapper {
        __typename
        preSignedUrls {
          location
          fileName
          url
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const workOrder = /* GraphQL */ `
  query workOrder($orderNumber: String!) {
    workOrder(orderNumber: $orderNumber) {
      __typename
      ... on WorkOrder {
        html
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const orderInvoice = /* GraphQL */ `
  query orderInvoice($orderNumber: String!) {
    orderInvoice(orderNumber: $orderNumber) {
      ... on Invoice {
        __typename
        html
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const doctorPickupLabel = /* GraphQL */ `
  query doctorPickupLabel($orderNumber: String!) {
    doctorPickupLabel(orderNumber: $orderNumber) {
      __typename
      ... on DoctorPickupLabel {
        html
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const listCategory = /* GraphQL */ `
  query list($category: ListCategory!) {
    list(category: $category) {
      ... on List {
        __typename
        listItems {
          __typename
          category
          name
        }
      }
      ... on GenericError {
        __typename
        message
      }
    }
  }
`;

export const getShippingOptions = /* GraphQL */ `
  query shippingOptions($input: ShippingOptionsInput!) {
    shippingOptions(input: $input) {
      __typename
      ... on ShippingOptionsWrapper {
        shippingOptions {
          carrier
          cost
          rateId
          shipmentId
          service
          requiresSignature
          isSaturdayDelivery
          package
          predefinedPackage
          charges {
            shippingChargeId
            service
            taxCode
            currencies {
              currencyCode
              price
            }
            isTaxable
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const manufacturingLocationQuery = /* GraphQL */ `
  query manufacturingLocation($locationId: String!) {
    manufacturingLocation(locationId: $locationId) {
      __typename
      ... on ManufacturingLocation {
        isActive
        locationName
        locationId
        tzIdentifier
        invoiceCutoffTimeUtc
        addresses {
          type
          street1
          street2
          street3
          city
          state
          zipcode
          country
          neighborhood
          division
        }
        phoneNumbers {
          type
          number
          extension
          country
        }
        emailAddresses {
          type
          emailAddress
        }
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const manufacturingLocationsQuery = /* GraphQL */ `
  query manufacturingLocations {
    manufacturingLocations {
      __typename
      ... on ManufacturingLocationsWrapper {
        locations {
          isActive
          locationName
          locationId
          tzIdentifier
          invoiceCutoffTimeUtc
          addresses {
            type
            street1
            street2
            street3
            city
            state
            zipcode
            country
            neighborhood
            division
          }
          phoneNumbers {
            type
            number
            extension
            country
          }
          emailAddresses {
            type
            emailAddress
          }
        }
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const caseAlertsQuery = /* GraphQL */ `
  query caseAlerts($input: CaseAlertsSearchInput!) {
    caseAlerts(input: $input) {
      ... on CaseAlerts {
        __typename
        caseAlerts {
          ...caseAlertFragment
        }
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${caseAlertFragment}
`;

export const orderShipping = /* GraphQL */ `
  query orderShipping($orderNumber: String!) {
    order(orderNumber: $orderNumber) {
      __typename
      ... on Order {
        shipping {
          shippingCharges {
            id
            type
            amount
            discountAmount
            salesTaxAmount
            totalAmount
            carrier
            service
          }
          outboundTrackingNumbers {
            ...trackingNumberItemFragment
          }
          inboundTrackingNumbers {
            ...trackingNumberItemFragment
          }
          deliveryMethod
          doctorPickupLocation {
            labId
            labName
            address {
              ...addressFragment
            }
            phone {
              ...phoneFragment
            }
          }
        }
        totalAmount
      }
      ... on RmaOrder {
        shipping {
          shippingCharges {
            id
            type
            amount
            discountAmount
            salesTaxAmount
            totalAmount
            carrier
            service
          }
          outboundTrackingNumbers {
            ...trackingNumberItemFragment
          }
          inboundTrackingNumbers {
            ...trackingNumberItemFragment
          }
          deliveryMethod
          doctorPickupLocation {
            labId
            labName
            address {
              ...addressFragment
            }
            phone {
              ...phoneFragment
            }
          }
        }
        totalAmount
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${phoneFragment}
  ${addressFragment}
  ${trackingNumberItemFragment}
`;

export const technicalPreferencesQuery = /* GraphQL */ `
  query technicalPreferences($input: TechnicalPreferencesInput!) {
    technicalPreferences(input: $input) {
      ... on TechnicalPreferences {
        __typename
        preferencesByAccountAndProvider {
          billingAccountId
          preferences {
            attributeName
            attributeValue
            productCode
          }
          providerId
        }
      }
      ... on NotFoundError {
        __typename
        message
      }
      ... on GenericError {
        __typename
        message
      }
    }
  }
`;

export const existingTrackingNumberQuery = /* GraphQL */ `
  query orderTransactionSearch($input: OrderTransactionSearchInput!) {
    orderTransactionSearch(input: $input) {
      __typename
      ... on OrderTransactionWrapper {
        transactions {
          __typename
          ... on Order {
            orderNumber
            billingAccountId
            status
            statusReason
            invoiceDate
            originFacilityId
            utcConversionTimeZoneCode
            shipping {
              ...invoiceShippingFragment
            }
            orderItems {
              productCode
            }
            enclosedItems {
              ...orderEnclosedItemFragment
            }
          }
          ... on RmaOrder {
            orderNumber
            billingAccountId
            status
            statusReason
            invoiceDate
            originFacilityId
            utcConversionTimeZoneCode
            shipping {
              ...invoiceShippingFragment
            }
            orderItems {
              isOldProductReturned
              returnReasons
              returnType
              productCode
            }
            enclosedItems {
              ...orderEnclosedItemFragment
            }
          }
          ... on PendingOrder {
            status
          }
        }
      }
    }
  }
  ${addressFragment}
  ${orderEnclosedItemFragment}
  ${invoiceShippingFragment}
`;

export const shippingPreferencesQuery = /* GraphQL */ `
  query shippingPreferences($billingAccountId: String!) {
    shippingPreferences(billingAccountId: $billingAccountId) {
      __typename
      ... on ShippingPreferences {
        billingAccountId
        type
        doNotShip {
          day
          start
          end
        }
        preferredCarrier {
          carrier
          service
          start
          end
          options {
            signatureRequired
          }
        }
      }
      ... on LMSError {
        message
      }
    }
  }
`;

export const caseHistoryQuery = /* GraphQL */ `
  query caseHistory($orderNumber: String!) {
    caseHistory(orderNumber: $orderNumber) {
      __typename
      ... on HistoryResults {
        orderNumber
        caseHistoryItems {
          completedBy
          additionalDetails {
            new
            old
          }
          completedByTechId
          timeStamp
          process
          isProcessOverride
          managerId
          managerName
          manufacturingStatus
          orderNumber
          process
          productionFacility
          locationId
          utcConversionTimeZoneCode
          products {
            toothString
            productName
            productCode
            department
          }
          reason
          status
          eventType
          actionType
        }
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const shippingOrderTrackingNumberQuery = /* GraphQL */ `
  query shippingOrderTrackingNumber($orderNumber: String!) {
    shippingOrder(orderNumber: $orderNumber) {
      __typename
      ... on ShippingOrderWrapper {
        items {
          status
          trackingNumber
          createdBy
          createdDate
          carrier
          serviceName
          shippingLabelUrls {
            type
            url
          }
        }
      }
      ... on LMSError {
        message
      }
    }
  }
`;

export const nextRmaOrderQuery = /* GraphQL */ `
  query nextRmaOrder($orderNumber: String!) {
    nextRmaOrder(orderNumber: $orderNumber) {
      __typename
      ... on NextRmaOrder {
        orderNumber
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const productReturnRequirementQuery = /* GraphQL */ `
  query productReturnRequirement($productCode: String!) {
    productReturnRequirement(productCode: $productCode) {
      __typename
      ... on ProductReturnRequirement {
        productCode
        isReturnRequired
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const availableDiscountsQuery = /* GraphQL */ `
  query availableDiscounts($input: AvailableDiscountsInput!) {
    availableDiscounts(input: $input) {
      __typename
      ... on AvailableDiscountsData {
        orderNumber
        deductions {
          orderItemId
          description
          productCode
          availableAmount
        }
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const availableCreditsQuery = /* GraphQL */ `
  query availableCredits($input: AvailableCreditsInput!) {
    availableCredits(input: $input) {
      __typename
      ... on AvailableCreditsData {
        orderNumber
        creditItems {
          orderItemId
          description
          productCode
          availableAmount
        }
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;
