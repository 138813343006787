export const orderMetadataFragment = /* GraphQL */ `
  fragment orderMetadataFragment on OrderMetaData {
    isPricingBeingCalculated
    isTaxBeingCalculated
  }
`;

export const trackingNumberItemFragment = /* GraphQL */ `
  fragment trackingNumberItemFragment on TrackingNumberItem {
    trackingNumber
    insertionDate
    insertedBy
    carrier
    service
    shippingLabelUrls {
      type
      url
    }
  }
`;

export const baseOrderItemFragment = /* GraphQL */ `
  fragment baseOrderItemFragment on BaseOrderItem {
    itemId
    productName
    productCode
    attributes {
      type
      name
      value
      quantity
    }
  }
`;

export const orderProductAttributeFragment = /* GraphQL */ `
  fragment orderProductAttributeFragment on OrderProductAttribute {
    type
    name
    value
    quantity
    unitPrice
  }
`;

export const overridesFragment = /* GraphQL */ `
  fragment overridesFragment on OverrideField {
    field
    type
    old
    new
  }
`;

export const orderItemFragment = /* GraphQL */ `
  fragment orderItemFragment on OrderItem {
    __typename
    ...baseOrderItemFragment
    quantity
    quantityBackordered
    unitPrice
    lineItemNumber
    type
    department
    productDescription
    subtotalAmount
    totalTaxAmount
    totalDiscountAmount
    totalAmount
    coupons
    couponAmount
    specialDiscountAmount
    services {
      ...orderProductAttributeFragment
    }
    addOns {
      ...orderProductAttributeFragment
    }
    attributes {
      ...orderProductAttributeFragment
    }
    daysInLab
    orderNumber
    manufacturingLocation
    manufacturingLocationId
    createdDate
    createdBy
    updatedDate
    updatedBy
    overrides {
      ...overridesFragment
    }
  }
  ${baseOrderItemFragment}
  ${orderProductAttributeFragment}
  ${overridesFragment}
`;

export const legacyOrderItemFragment = /* GraphQL */ `
  fragment legacyOrderItemFragment on LegacyOrderItem {
    ...baseOrderItemFragment
    units
    quantity
    unitPrice
    lineItemNumber
    type
    department
    restorationType
    subtotalAmount
    totalTaxAmount
    totalDiscountAmount
    totalAmount
    coupons
    couponAmount
    specialDiscountAmount
    services {
      ...orderProductAttributeFragment
    }
    addOns {
      ...orderProductAttributeFragment
    }
    daysInLab
    manufacturingLocation
    manufacturingLocationId
    updatedBy
    updatedDate
  }
  ${baseOrderItemFragment}
  ${orderProductAttributeFragment}
`;

export const rmaOrderItemFragment = /* GraphQL */ `
  fragment rmaOrderItemFragment on RmaOrderItem {
    __typename
    ...baseOrderItemFragment
    itemId
    quantity
    quantityBackordered
    unitPrice
    lineItemNumber
    type
    department
    productDescription
    subtotalAmount
    totalTaxAmount
    totalDiscountAmount
    totalAmount
    coupons
    couponAmount
    specialDiscountAmount
    attributes {
      ...orderProductAttributeFragment
    }
    services {
      ...orderProductAttributeFragment
    }
    addOns {
      ...orderProductAttributeFragment
    }
    daysInLab
    orderNumber
    manufacturingLocation
    manufacturingLocationId
    createdDate
    createdBy
    updatedDate
    updatedBy
    isOldProductReturned
    returnReasons
    returnType
    newProduct {
      productName
      productCode
    }
    overrides {
      ...overridesFragment
    }
    isProcessSwap
  }
  ${baseOrderItemFragment}
  ${orderProductAttributeFragment}
  ${overridesFragment}
`;

export const orderEnclosedItemFragment = /* GraphQL */ `
  fragment orderEnclosedItemFragment on OrderEnclosedItem {
    __typename
    id
    type
    itemCode
    quantity
    createdBy
    createdDate
    shippingType
  }
`;

export const orderAttachmentFragment = /* GraphQL */ `
  fragment orderAttachmentFragment on OrderAttachment {
    __typename
    id
    name
    extension
    uploadProperties {
      fileName
      fileType
      filePath
    }
    size
    fileUrl
    createdBy
    createdDate
  }
`;

export const addressFragment = /* GraphQL */ `
  fragment addressFragment on Address {
    __typename
    type
    street1
    street2
    street3
    city
    state
    zipcode
    country
    neighborhood
    division
  }
`;
export const legacyAddressFragment = /* GraphQL */ `
  fragment legacyAddressFragment on LegacyAddress {
    type
    street1
    street2
    street3
    city
    state
    zipcode
    country
    neighborhood
    division
  }
`;
export const phoneFragment = /* GraphQL */ `
  fragment phoneFragment on Phone {
    __typename
    type
    number
    extension
    country
  }
`;

export const invoiceShippingFragment = /* GraphQL */ `
  fragment invoiceShippingFragment on Shipping {
    address {
      ...addressFragment
    }
    shippingMethod
    estimatedShipDate
    packageType
    shippingCharges {
      id
      type
      amount
      discountAmount
      salesTaxAmount
      totalAmount
      carrier
      service
      taxCode
    }
    shipmentId
    rateId
  }
`;

export const shippingFragment = /* GraphQL */ `
  fragment shippingFragment on Shipping {
    __typename
    codAmount
    address {
      type
      street1
      street2
      street3
      city
      state
      zipcode
      country
      neighborhood
      division
    }
    shippingMethod
    packageType
    shippingCharges {
      id
      type
      amount
      discountAmount
      salesTaxAmount
      totalAmount
      carrier
      service
    }
    estimatedShipDate
    inboundTrackingNumbers {
      ...trackingNumberItemFragment
    }
    outboundTrackingNumbers {
      ...trackingNumberItemFragment
    }
    deliveryMethod
    doctorPickupLocation {
      labId
      labName
      address {
        ...addressFragment
      }
      phone {
        ...phoneFragment
      }
    }
  }
  ${addressFragment}
  ${phoneFragment}
  ${trackingNumberItemFragment}
`;

export const legacyShippingFragment = /* GraphQL */ `
  fragment legacyShippingFragment on LegacyShipping {
    __typename
    legacyAddress: address {
      ...legacyAddressFragment
    }
    shippingMethod
    packageType
    shippingCharges {
      id
      type
      amount
      discountAmount
      salesTaxAmount
      totalAmount
      carrier
      service
    }
    estimatedShipDate
    legacyInboundTrackingNumbers: inboundTrackingNumbers {
      ...trackingNumberItemFragment
    }
    legacyOutboundTrackingNumbers: outboundTrackingNumbers {
      ...trackingNumberItemFragment
    }
    deliveryMethod
    legacyDoctorPickupLocation: doctorPickupLocation {
      labId
      labName
      address {
        ...legacyAddressFragment
      }
      phone {
        ...phoneFragment
      }
    }
    codAmount
  }
  ${legacyAddressFragment}
  ${phoneFragment}
  ${trackingNumberItemFragment}
`;

export const searchedOrderFragment = /* GraphQL */ `
  fragment searchedOrderFragment on SearchedOrder {
    orderNumber
    billingAccountId
    status
    providerId
    patientFirstName
    patientLastName
    patientId
    providerName
    createdDate
    utcConversionTimeZoneCode
    invoiceDate
    originatingSystem
    labReceivedDate
    orderItems {
      productCode
      productName
      attributes {
        ...orderProductAttributeFragment
      }
      returnReasons
      returnType
    }
  }
  ${orderProductAttributeFragment}
`;

export const relatedCasesOrderFragment = /* GraphQL */ `
  fragment relatedCasesOrderFragment on Order {
    __typename
    orderNumber
    status
    createdDate
    utcConversionTimeZoneCode
    invoiceDate
    originatingSystem
    labReceivedDate
    patientFirstName
    patientLastName
    patientId
    providerName
    orderItems {
      __typename
      productName
      attributes {
        ...orderProductAttributeFragment
      }
      addOns {
        ...orderProductAttributeFragment
      }
      services {
        ...orderProductAttributeFragment
      }
    }
  }
  ${orderProductAttributeFragment}
`;

export const relatedCasesRmaOrderFragment = /* GraphQL */ `
  fragment relatedCasesRmaOrderFragment on RmaOrder {
    __typename
    orderNumber
    status
    createdDate
    utcConversionTimeZoneCode
    invoiceDate
    originatingSystem
    labReceivedDate
    patientFirstName
    patientLastName
    patientId
    providerName
    orderItems {
      __typename
      productName
      attributes {
        ...orderProductAttributeFragment
      }
      addOns {
        ...orderProductAttributeFragment
      }
      services {
        ...orderProductAttributeFragment
      }
      returnType
      returnReasons
    }
  }
  ${orderProductAttributeFragment}
`;

export const relatedCasesLegacyOrderFragment = /* GraphQL */ `
  fragment relatedCasesLegacyOrderFragment on LegacyOrder {
    __typename
    orderNumber
    status
    legacyCreatedDate: createdDate
    legacyUtcConversionTimeZoneCode: utcConversionTimeZoneCode
    invoiceDate
    originatingSystem
    labReceivedDate
    patientFirstName
    patientLastName
    patientId
    legacyProviderName: providerName
    legacyOrderItems: orderItems {
      ...legacyOrderItemFragment
    }
  }
  ${legacyOrderItemFragment}
`;

export const baseOrderFragment = /* GraphQL */ `
  fragment baseOrderFragment on BaseOrder {
    orderNumber
    status
    fileAttachments {
      ...orderAttachmentFragment
    }
    enclosedItems {
      ...orderEnclosedItemFragment
    }
    patientFirstName
    patientId
    patientLastName
    isCTScanEligible
  }
  ${orderEnclosedItemFragment}
  ${orderAttachmentFragment}
`;

export const orderFragment = /* GraphQL */ `
  fragment orderFragment on Order {
    __typename
    ...baseOrderFragment
    billingAccountId
    statusReason
    pONumber
    sCINumber
    type
    paymentTerms
    transactionId
    authorizationId
    authorizationAmount
    creditRequestedDate
    originatingSystem
    orderSource
    providerId
    providerName
    labReceivedDate
    customerDueDate
    subtotalAmount
    totalDiscountAmount
    totalFreightCharges
    totalTaxAmount
    totalAmount
    currencyCode
    invoiceBy
    invoiceDate
    originFacility
    originFacilityId
    departmentId
    externalOrderNumber
    shipping {
      ...shippingFragment
    }
    addresses {
      ...addressFragment
    }
    orderItems {
      ...orderItemFragment
    }
    coupons
    notes
    utcConversionTimeZoneCode
    createdBy
    createdDate
    updatedBy
    updatedDate
    metadata {
      ...orderMetadataFragment
    }
    linkedOrderNumber
    originalOrderNumber
  }
  ${baseOrderFragment}
  ${shippingFragment}
  ${addressFragment}
  ${orderItemFragment}
  ${orderMetadataFragment}
`;

export const rmaOrderFragment = /* GraphQL */ `
  fragment rmaOrderFragment on RmaOrder {
    ...baseOrderFragment
    rmaOriginalOrderNumber: originalOrderNumber
    billingAccountId
    statusReason
    pONumber
    sCINumber
    type
    paymentTerms
    transactionId
    authorizationId
    authorizationAmount
    creditRequestedDate
    originatingSystem
    orderSource
    providerId
    providerName
    labReceivedDate
    customerDueDate
    subtotalAmount
    totalDiscountAmount
    totalFreightCharges
    totalTaxAmount
    totalAmount
    currencyCode
    invoiceBy
    invoiceDate
    originFacility
    originFacilityId
    departmentId
    externalOrderNumber
    shipping {
      ...shippingFragment
    }
    addresses {
      ...addressFragment
    }
    orderItems {
      ...rmaOrderItemFragment
    }
    coupons
    notes
    utcConversionTimeZoneCode
    createdBy
    createdDate
    updatedBy
    updatedDate
    metadata {
      ...orderMetadataFragment
    }
    linkedOrderNumber
  }
  ${baseOrderFragment}
  ${shippingFragment}
  ${addressFragment}
  ${rmaOrderItemFragment}
  ${orderMetadataFragment}
`;

export const legacyOrderFragment = /* GraphQL */ `
  fragment legacyOrderFragment on LegacyOrder {
    ...baseOrderFragment
    __typename
    billingAccountId
    statusReason
    type
    originatingSystem
    orderSource
    legacyProviderId: providerId
    legacyProviderName: providerName
    labReceivedDate
    customerDueDate
    subtotalAmount
    totalDiscountAmount
    totalFreightCharges
    totalTaxAmount
    totalAmount
    currencyCode
    invoiceBy
    invoiceDate
    legacyOriginFacility: originFacility
    legacyOriginFacilityId: originFacilityId
    legacyDepartmentId: departmentId
    externalOrderNumber
    shipping {
      ...legacyShippingFragment
    }
    legacyAddresses: addresses {
      ...legacyAddressFragment
    }
    legacyOrderItems: orderItems {
      ...legacyOrderItemFragment
    }
    coupons
    notes
    legacyUtcConversionTimeZoneCode: utcConversionTimeZoneCode
    createdBy
    legacyCreatedDate: createdDate
    updatedBy
    updatedDate
    inbundleOrders
  }
  ${baseOrderFragment}
  ${legacyShippingFragment}
  ${legacyAddressFragment}
  ${legacyOrderItemFragment}
`;

export const pendingOrderFullFragment = /* GraphQL */ `
  fragment pendingOrderFullFragment on PendingOrder {
    __typename
    ...baseOrderFragment
    billingAccountId
    pendingProviderId: providerId
    pendingProviderName: providerName
    shipping {
      ...shippingFragment
    }
    orderItems {
      __typename
      ...baseOrderItemFragment
      pendingServices: services {
        ...orderProductAttributeFragment
      }
      pendingAddOns: addOns {
        ...orderProductAttributeFragment
      }
    }
    linkedOrderNumber
    pendingOriginalOrderNumber: originalOrderNumber
    externalOrderNumber
    orderSource
  }
  ${baseOrderFragment}
  ${shippingFragment}
  ${baseOrderItemFragment}
  ${orderProductAttributeFragment}
`;

export const bundleOrderFragment = /* GraphQL */ `
  fragment bundleOrderFragment on OrderBundle {
    inboundBundle {
      chargedOrderNumber
      bundledOrderNumbers {
        ...searchedOrderFragment
      }
    }
    outboundBundle {
      chargedOrderNumber
      bundledOrderNumbers {
        ...searchedOrderFragment
      }
    }
    splitBundle {
      bundledOrderNumbers {
        ...searchedOrderFragment
      }
      detachedOrderNumbers {
        ...searchedOrderFragment
      }
      bundleId
    }
  }
  ${searchedOrderFragment}
`;

export const caseAlertFragment = /* GraphQL */ `
  fragment caseAlertFragment on CaseAlert {
    __typename
    alertId
    appliedBy
    appliedDate
    status
    createdBy
    createdDate
    updatedBy
    updatedDate
    providerId
    billingAccountId
    expireDate
    spokeTo
    orderNumber
    patientId
    patientFirstName
    patientLastName
    isPatientInfoIncomplete
    updateCase {
      product {
        original
        new
      }
      toothNumber {
        original
        new
      }
      shade {
        original
        new
      }
      shippingAddress {
        street1
        street2
        street3
        city
        state
        zipcode
        country
      }
      notes
    }
    cancelCase {
      product
      reason
      caseContent
      notes
    }
    forwardCase {
      sendToPlastering
      routeType
      routeTo
      reason
    }
    other {
      notes
    }
  }
`;

export const appliedCouponsFragment = /* GraphQL */ `
  fragment appliedCouponsFragment on Coupon {
    activationDate
    code
    components {
      abbreviation
      type
      value
    }
    createdDate
    createdBy
    currencyCode
    discountAmount
    discountType
    expirationDate
    status
    usageLimit {
      accountLimit
      orderLimit
      rxLimit
    }
  }
`;

export const appliedDiscountsFragment = /* GraphQL */ `
  fragment appliedDiscountsFragment on Deduction {
    __typename
    discountId
    orderNumber
    reason
    type
    deductions {
      productCode
      discountType
      value
      discountAmount
      orderItemId
      description
    }
    createdBy
    createdDate
    notes
  }
`;

export const appliedCreditsFragment = /* GraphQL */ `
  fragment appliedCreditsFragment on Credit {
    __typename
    creditNumber
    orderNumber
    billingAccountId
    patientId
    patientFirstName
    patientLastName
    currencyCode
    originFacilityId
    totalAmount
    totalDiscountAmount
    totalTaxAmount
    notes
    createdBy
    createdDate
    caseDiscountType
    reason
    creditItems {
      productName
      productCode
      creditType
      totalAmount
      totalTaxAmount
      couponCode
      quantity
      discountAmount
      value
      orderItemId
      description
      reason
    }
  }
`;
