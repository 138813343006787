import { ProductFullAttribute } from 'API';
import RequiredMessage from 'components/common/RequiredMessage/RequiredMessage';
import { ResetButton } from 'components/common/ResetButton';
import { ColorCheckBox, ColorCheckBoxProps } from 'components/invoicing/ShippingDetail/ColorCheckBox/ColorCheckBox';

/**
 * Props for the AttributeCheckbox component.
 */
interface AttributeCheckboxProps {
  /**
   * Indicates whether the checkbox is checked.
   */
  checked: boolean;
  /**
   * Callback function to handle checkbox change.
   */
  onChange: (checked: boolean) => void;
  /**
   * The attribute associated with the checkbox.
   */
  attribute: ProductFullAttribute;
  /**
   * Indicates whether the checkbox is required.
   */
  isRequired?: boolean;
  /**
   * Indicates whether the submit alert is active.
   */
  onSubmitAlert?: boolean;
  /**
   * Title for the required message.
   */
  requiredMessageTitle?: string;
  /**
   * Label for the checkbox.
   */
  label: string;
  /**
   * Indicates whether the checkbox can be reset.
   */
  isAbleToReset?: boolean;
  /**
   * Callback function to reset the checkbox value.
   */
  onValueReset?: () => void;
  /**
   * Variant for the checkbox.
   */
  variant?: ColorCheckBoxProps['variant'];
}

/**
 * The AttributeCheckbox component displays a checkbox for a specific attribute.
 * It allows users to select or deselect the attribute and provides optional reset functionality.
 * @param checked - Indicates whether the checkbox is checked.
 * @param onChange - Callback function to handle checkbox change.
 * @param attribute - The attribute associated with the checkbox.
 * @param isRequired - Indicates whether the checkbox is required.
 * @param onSubmitAlert - Indicates whether the submit alert is active.
 * @param requiredMessageTitle - Title for the required message.
 * @param label - Label for the checkbox.
 * @param isAbleToReset - Indicates whether the checkbox can be reset.
 * @param onValueReset - Callback function to reset the checkbox value.
 * @param variant - Variant for the checkbox.
 * @returns JSX element representing the AttributeCheckbox component.
 */
const AttributeCheckbox: React.FC<AttributeCheckboxProps> = ({
  checked,
  onChange,
  attribute,
  isRequired,
  onSubmitAlert,
  requiredMessageTitle,
  label,
  isAbleToReset,
  onValueReset,
  variant,
}) => {
  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} />;
  return (
    <div className="flex-1 align-center">
      <ColorCheckBox
        label={label}
        checked={checked}
        onChange={checked => {
          console.log(checked);
          onChange(checked);
        }}
        variant={variant}
        disabled={false}
        isClickable
        testId={attribute.name}
      />
      <div>{resetButton}</div>
      {isRequired && !checked && onSubmitAlert && (
        <RequiredMessage fieldName={attribute.name || requiredMessageTitle} />
      )}
    </div>
  );
};

export default AttributeCheckbox;
