import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { cn } from 'shared/utils';

/**
 * Props for the RemoveButton component.
 */
interface RemoveButtonProps {
  /**
   * Handler for the remove button click event.
   */
  onRemove: () => void;
  /**
   * The id attribute of the button.
   */
  id: string;
  /**
   * Additional class names for styling.
   */
  className?: string;
}

/**
 * Component for displaying a remove button.
 * @param onRemove - Handler for the remove button click event.
 * @param id - The id attribute of the button.
 * @param className - Additional class names for styling.
 * @returns JSX.Element representing the RemoveButton component.
 */
const RemoveButton: React.FC<RemoveButtonProps> = ({ onRemove, id, className }) => {
  return (
    <>
      <button
        className={cn('m-auto px-3 flex-none w-8', className)}
        onClick={() => onRemove()}
        id={id}
        data-qa={id}
        data-testid={id}
      >
        <MinusCircleIcon className="text-gray-400 h-5 w-5 cursor-pointer" />
      </button>
    </>
  );
};

export default RemoveButton;
