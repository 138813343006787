import {
  ArrowSmallRightIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentListIcon,
  MinusIcon,
} from '@heroicons/react/24/outline';
import { LmsOrder, OrderStatus, RelatedCasesSearchQueryVariables, SortOrder } from 'API';
import classNames from 'classnames';
import Modal from 'components/common/Modal';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { OverlayLoaderContext } from 'providers/OverlayLoaderProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { getOrderFull, relatedCasesSearch } from 'shared/api/order.api';
import { NEW_CASE } from 'shared/constants/constants';
import { caseStatusesForRelatedSearch } from 'shared/constants/invoice.constants';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { getOrderType } from 'shared/helpers/order-entry/order-entry.helper';
import { isRmaLocalOrder } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { AnalyticsService } from 'shared/services/analytics.service';
import { getPatientFullName, isCreatedOrder } from 'shared/utils';
import { LinkedCaseInfo } from './LinkedCaseInfo/LinkedCaseInfo';
import RMAModal from './RMAModal/RMAModal';
import { RelatedCaseView } from './RelatedCaseView';
import { SeparateCaseModal } from './SeparateCaseModal/SeparateCaseModal';
import { SplittingCaseModal } from './SplittingCaseModal/SplittingCaseModal';

/**
 * Props for the selectedCase state.
 */
interface SelectedCase {
  /**
   * The first name of the patient associated with the case.
   */
  patientFirstName: string;
  /**
   * The last name of the patient associated with the case.
   */
  patientLastName: string;
  /**
   * The name of the provider associated with the case.
   */
  providerName: string;
  /**
   * The order number of the case.
   */
  orderNumber: string;
  /**
   * The order items of the case.
   */
  orderItems: LmsOrder['orderItems'];
}

/**
 * Props for the RelatedCases component.
 */
interface RelatedCasesProps {
  /**
   * Indicates whether the component is in edit mode.
   */
  isEditMode: boolean;
}

/**
 * The RelatedCases component displays related cases and related case information.
 * @param isEditMode - Indicates whether the component is in edit mode.
 * @returns JSX element representing the RelatedCases component.
 */
export const RelatedCases: React.FC<RelatedCasesProps> = ({ isEditMode }) => {
  const [showRelatedCases, setShowRelatedCases] = useState<boolean>(false);
  const [showCaseRmaModal, setShowCaseRmaModal] = useState<boolean>(false);
  const [showSeparateCaseModal, setShowSeparateCaseModal] = useState(false);
  const [showSplittingCaseModal, setShowSplittingCaseModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState<SelectedCase>();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const overlayLoader = useContext(OverlayLoaderContext);

  const { order, orderStatus } = useContext(OrderModuleContext);
  const { patchOrder } = useContext(OrderModuleActionsContext);
  const { patientFirstName, patientLastName, billingAccountId, patientId } = order;

  const relatedCasesQueryVariables = useMemo(() => {
    const variables: RelatedCasesSearchQueryVariables = {
      input: {
        patientFirstName,
        patientLastName,
        billingAccountId,
        patientId,
        statuses: caseStatusesForRelatedSearch,
      },
      sort: {
        direction: SortOrder.Desc,
        field: 'createdDate',
      },
      limit: 50,
    };
    return variables;
  }, [patientFirstName, patientLastName, billingAccountId, patientId]);

  const { data: relatedCases } = useQueryFetcher(relatedCasesSearch, {
    ...relatedCasesQueryVariables,
    skip: (!order.patientFirstName && !order.patientLastName) || isEditMode,
  });

  const {
    fetcher: getOrderFetcher,
    loading: isGetOrderLoading,
    data: targetOrder,
    error: targetOrderError,
  } = useLazyQueryFetcher(getOrderFull);

  const isRmaOrder = isRmaLocalOrder(order);
  // Displays related cases only when related cases are loaded and the patient name is available.
  const isShowRelatedCasesCriteriaMet = !!(
    !isRmaOrder &&
    (relatedCases?.orders.length || selectedCase) &&
    (order.patientFirstName || order.patientLastName)
  );

  useEffect(() => {
    if (isShowRelatedCasesCriteriaMet) {
      setShowRelatedCases(true);
    }
  }, [isRmaOrder, relatedCases, order.patientFirstName, order.patientLastName, isShowRelatedCasesCriteriaMet]);

  // After the nextRmaOrder call is made and a new RMA case is loaded on the page, this useEffect will display the selected order information in this panel in the place of related cases.
  // Once an RMA case is in progress, related cases can no longer be selected.
  useEffect(() => {
    if (targetOrder) {
      const { orderNumber, patientFirstName, patientLastName, providerName, orderItems } = targetOrder;
      setSelectedCase({
        patientFirstName: patientFirstName || '',
        patientLastName: patientLastName || '',
        providerName: providerName || '',
        orderNumber,
        orderItems,
      });
      setShowRelatedCases(true);
    } else {
      // If no target order is set (meaning we are not in RMA mode or new exchange case), toggles the linked case view off.
      // This is important for returning to case edit from an RMA exchange order without the product returned.
      setSelectedCase(undefined);
    }
  }, [order?.originalOrderNumber, setSelectedCase, targetOrder]);

  const getOrder = useCallback(
    async (orderNumber: string) => {
      await getOrderFetcher({
        orderNumber,
      });
    },
    [getOrderFetcher]
  );

  const selectRelatedCase = async (orderNumber: string) => {
    await getOrder(orderNumber);
    setShowCaseRmaModal(true);
  };

  /**
   * Meant to run when target order is done loading.
   * The purpose is to patch the previousOrderType on the local order when in edit mode and looking at an RMA.
   * This is to display the Previous Case CONVENTIONAL | DIGITAL properly.
   */
  useEffect(() => {
    if (
      !isEditMode ||
      !isRmaOrder ||
      targetOrderError ||
      isGetOrderLoading ||
      !targetOrder ||
      order.localMetadata.previousOrderType
    ) {
      return;
    }

    patchOrder(
      {
        localMetadata: {
          ...order.localMetadata,
          previousOrderType: getOrderType(targetOrder.externalOrderNumber, targetOrder.orderSource),
        },
      },
      true
    );
  }, [isEditMode, isGetOrderLoading, isRmaOrder, order.localMetadata, patchOrder, targetOrder, targetOrderError]);

  /**
   * If in edit mode, original order must be fetched automatically as the user is not selecting it from related cases view.
   * If the target order has not been loaded and we are looking at an RMA, load the original case info.
   */
  useEffect(() => {
    if (isEditMode && !isGetOrderLoading && !targetOrder && isRmaOrder && order.localMetadata.linkedOrderNumber) {
      getOrder(order.localMetadata.linkedOrderNumber);
    }
  }, [getOrder, isEditMode, isGetOrderLoading, isRmaOrder, order.localMetadata.linkedOrderNumber, targetOrder]);

  // Shows a loader when get order is loading
  useEffect(() => {
    if (isGetOrderLoading) {
      overlayLoader.show('Loading');
    } else {
      overlayLoader.hide();
    }
  }, [isGetOrderLoading, overlayLoader]);

  const onArrowRightClick = () => {
    setShowRelatedCases(false);
    AnalyticsService.track(AnalyticsEventName.CaseEntryRelatedCasesCollapsed);
  };

  const isNewOrPendingCase = order.orderNumber === NEW_CASE || orderStatus === OrderStatus.Pending;
  const fullPatientName = getPatientFullName(order.patientFirstName, order.patientLastName);
  return (
    <>
      <div className={classNames('w-96 pb-10', { hidden: !showRelatedCases })}>
        <div className="border-b-2 p-4">
          <div className="font-medium flex justify-between">
            {selectedCase ? (
              <div className="flex">
                <ClipboardDocumentCheckIcon className="text-gray-700 h-7 w-5 mr-2" data-qa="selectedCaseIcon" />
                <div className="text-indigo-900 self-center">Linked Case Info</div>
              </div>
            ) : (
              <div className="flex">
                <ClipboardDocumentListIcon className="text-gray-700 h-7 w-5 mr-2" data-qa="relatedCasesIcon" />
                <div className="text-gray-700 self-center">Related Cases</div>
              </div>
            )}
            {!isEditMode && (
              <div className="cursor-pointer flex align-items-center" onClick={onArrowRightClick}>
                <MinusIcon className="h-7 w-5 transform rotate-90" />
                <ArrowSmallRightIcon className="h-7 w-5 ml-neg-10" />
              </div>
            )}
          </div>
        </div>
        {selectedCase ? (
          <LinkedCaseInfo
            orderNumber={selectedCase.orderNumber}
            providerName={selectedCase.providerName}
            patientFirstName={selectedCase.patientFirstName || ''}
            patientLastName={selectedCase.patientLastName || ''}
            orderItems={targetOrder?.orderItems || []}
          />
        ) : relatedCases?.orders?.length ? (
          relatedCases.orders.map((relatedOrder, index) => (
            <RelatedCaseView
              key={`${relatedOrder.orderNumber} - ${index}`}
              order={relatedOrder}
              selectRelatedCase={(orderNumber: string) => selectRelatedCase(orderNumber)}
              disableViewButton={isEditMode}
            />
          ))
        ) : (
          <div className="p-4">
            <div className="text-gray-700 text-center">
              No related cases found for <b>{fullPatientName + `${order.patientId ? `(${order.patientId})` : ''}`}</b>.
            </div>
          </div>
        )}
      </div>
      {showCaseRmaModal &&
        isCreatedOrder(targetOrder) && ( // Pending orders cannot be returned (since they have not been invoiced) so blocking them here.
          <>
            <Modal
              onClose={() => {
                setSelectedProducts([]);
                setShowCaseRmaModal(false);
                setSelectedCase(undefined);
              }}
            >
              <RMAModal
                onClose={() => {
                  setShowCaseRmaModal(false);
                }}
                onDismiss={() => setSelectedCase(undefined)}
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                patientName={order.patientFirstName + (order.patientLastName ? ' ' + order.patientLastName : '')}
                targetOrder={targetOrder}
                onBundleSplitSubmit={() => {
                  if (isNewOrPendingCase) {
                    setShowSplittingCaseModal(true);
                  } else {
                    setShowSeparateCaseModal(true);
                  }
                }}
              />
            </Modal>
          </>
        )}
      {isCreatedOrder(targetOrder) && (
        <SeparateCaseModal
          showModal={showSeparateCaseModal}
          onClose={() => setShowSeparateCaseModal(false)}
          onSubmit={() => {
            setShowSplittingCaseModal(true);
          }}
        />
      )}
      {showSplittingCaseModal && isCreatedOrder(targetOrder) && (
        <SplittingCaseModal
          targetOrder={targetOrder}
          selectedProducts={selectedProducts}
          isNewOrPendingCase={isNewOrPendingCase}
          message={`Creating case #${targetOrder.orderNumber}`}
          onClose={() => {
            setShowSplittingCaseModal(false);
          }}
        />
      )}
    </>
  );
};
