export interface CaseAlertDetailItemHocI {
  title: string;
  className?: string;
}

export const CaseAlertDetailItemHOC: React.FC<CaseAlertDetailItemHocI> = ({ children, className, title }) => {
  return (
    <div className={className}>
      <p className="mb-2">
        <b>{title}</b>
      </p>
      {children}
    </div>
  );
};
