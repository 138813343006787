import { Dialog } from '@headlessui/react';
import { OrderOriginatingSystem } from 'API';
import { AlertMessage } from 'components/common/Alert/AlertMessage';
import CheckboxInput from 'components/common/CheckboxInput';
import { Table } from 'components/common/Table/Table';
import { sum } from 'lodash';
import { OrderModuleActionsContext } from 'providers/OrderModuleProvider';
import { useContext, useMemo, useState } from 'react';
import { CaseTableRow, caseProductTableColumns, caseTableColumns } from 'shared/constants/case-rma.constants';
import { AttributeName, ToastNotificationType } from 'shared/enums';
import { isLMSOrder } from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrder, CreatedOrderItem } from 'shared/models';
import { convertToCurrency, getDateInShortFormat, isCreatedOrder, isRmaOrderData } from 'shared/utils';

/**
 * Props for the RMAModal component.
 */
interface RMAModalProps {
  /**
   * Callback function invoked when the modal is closed.
   */
  onClose: () => void;
  /**
   * Callback function invoked when the bundle split is submitted.
   */
  onBundleSplitSubmit: () => void;
  /**
   * The name of the patient.
   */
  patientName: string;
  /**
   * The target order data.
   */
  targetOrder: CreatedOrder;
  /**
   * The list of selected product IDs.
   */
  selectedProducts: string[];
  /**
   * Callback function to set the list of selected product IDs.
   */
  setSelectedProducts: (products: string[]) => void;
  /**
   * Callback function invoked when the RMA modal is dismissed.
   */
  onDismiss: () => void;
}

/**
 * The RMAModal component allows users to link a case as an RMA.
 * @param onClose - Callback function invoked when the modal is closed.
 * @param onBundleSplitSubmit - Callback function invoked when the bundle split is submitted.
 * @param patientName - The name of the patient.
 * @param targetOrder - The target order data.
 * @param selectedProducts - The list of selected product IDs.
 * @param setSelectedProducts - Callback function to set the list of selected product IDs.
 * @param onDismiss - Callback function invoked when the RMA modal is dismissed.
 * @returns JSX element representing the RMAModal component.
 */
const RMAModal: React.FC<RMAModalProps> = ({
  onClose,
  patientName,
  targetOrder,
  onBundleSplitSubmit,
  setSelectedProducts,
  selectedProducts,
  onDismiss,
}) => {
  const [requiredSelection, setRequiredSelection] = useState<boolean>(false);
  const isLegacyCase = useMemo(() => !isLMSOrder(targetOrder.originatingSystem), [targetOrder]);
  const { setOriginalOrderIsLegacy } = useContext(OrderModuleActionsContext);

  const handleSave = () => {
    if (isCreatedOrder(targetOrder)) {
      if (selectedProducts.length > 0) {
        onBundleSplitSubmit();
        onClose();
      } else {
        setRequiredSelection(true);
      }
      setOriginalOrderIsLegacy(isLegacyCase);
    }
  };

  const rows: CaseTableRow[] = useMemo(() => {
    const mapOrderFn = (order: CreatedOrder) => {
      const totalCreditedAmount = sum(order.appliedCredits?.map(credit => credit.totalAmount)) || 0;
      // map values from the order to the table data
      return [
        {
          orderNumber: order.orderNumber,
          patientName: order.patientFirstName + (order.patientLastName ? ' ' + order.patientLastName : ''),
          providerName: order.providerName,
          status: order.status,
          invoiceDate: order.invoiceDate
            ? getDateInShortFormat(order.invoiceDate, order.utcConversionTimeZoneCode)
            : 'N/A',
          totalAmount: convertToCurrency(order.totalAmount),
          totalCreditedAmount:
            order.originatingSystem === OrderOriginatingSystem.Lms ? convertToCurrency(totalCreditedAmount) : 'N/A',
          // The business requested to have N/A display for legacy systems, as per LMS1-6252.
          providerId: order.providerId,
        },
      ];
    };

    return mapOrderFn(targetOrder);
  }, [targetOrder]);

  const dynamicCaseProductTableColumns = useMemo(() => {
    setRequiredSelection(false); // turn off validation message when changing products

    const handleSelectProduct = (itemId: string) => {
      const products = [...selectedProducts];
      if (!products.includes(itemId)) {
        //checking weather array contain the id
        products.push(itemId); //adding to array because value doesnt exists
      } else {
        products.splice(products.indexOf(itemId), 1); //deleting
      }
      setSelectedProducts(products);
    };

    const dynamicColumns = [...caseProductTableColumns];
    dynamicColumns[0].render = ({ row }) => {
      return (
        <CheckboxInput
          id={row.itemId as string}
          checked={selectedProducts.includes(row.itemId as string)}
          onChange={() => handleSelectProduct(row.itemId as string)}
          label=""
        />
      );
    };
    return dynamicColumns;
  }, [selectedProducts, setSelectedProducts]);

  const productRows = useMemo(() => {
    const mapOrderFn = (orderItem: CreatedOrderItem) => {
      // map values from the order to the table data

      let toothString;
      let shade;
      orderItem.attributes.forEach(attribute => {
        if (attribute.name === AttributeName.ToothString) {
          toothString = attribute.value.replace(/#/g, '');
        }

        if (attribute.name === AttributeName.BodyShade) {
          shade = attribute.value;
        }
      });

      return {
        itemId: orderItem.itemId,
        productName: orderItem.productName,
        quantity: orderItem.quantity,
        toothString: toothString,
        shade: shade,
      };
    };

    if (isRmaOrderData(targetOrder)) {
      return targetOrder.orderItems.map(mapOrderFn);
    }
    return targetOrder.orderItems.map(mapOrderFn);
  }, [targetOrder]);

  /**
   * Dismisses (closes) the RMA modal and resets the user's product and target case selections.
   */
  const handleOnDismiss = () => {
    setSelectedProducts([]);
    onClose();
    onDismiss();
  };

  return (
    <>
      <div className="flex gap-4 p-6">
        <div>
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Related Cases - Link as RMA?
          </Dialog.Title>
          <div className="mt-2 mb-6">
            <p className="text-sm text-gray-500">
              If this is the correct case, select the product(s) being returned and then “Link Case” to create an RMA of
              this case. The case info will automatically transfer to the case entry form. If not, select “Dismiss”.
            </p>
          </div>
          {isLegacyCase && (
            <div className="mb-2 text-orange-700 text-sm">
              Note: This is a legacy case from {targetOrder.originatingSystem}. Manually enter all product info on the
              next page.
            </div>
          )}
          <Table columns={caseTableColumns} rows={rows} />
          {productRows.length > 0 && (
            <div className="RMAProductsTable">
              <Table
                columns={dynamicCaseProductTableColumns}
                rows={productRows}
                showPagination={productRows.length > 10}
              />
            </div>
          )}
          {rows[0] && patientName !== rows[0].patientName && (
            <div className="mt-4">
              <AlertMessage
                className="w-full"
                message="Patient name on the related case and entered case do not match. Please confirm that these cases are related before continuing."
                type={ToastNotificationType.Warning}
              />
            </div>
          )}
          {requiredSelection && (
            <div className="mt-4">
              <AlertMessage
                className="w-full"
                message="Product(s) selection is required."
                type={ToastNotificationType.Error}
              />
            </div>
          )}
        </div>
      </div>

      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleSave}
          data-qa="doneButton"
        >
          Link Case
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={handleOnDismiss}
          data-qa="cancelButton"
        >
          Dismiss
        </button>
      </div>
    </>
  );
};

export default RMAModal;
