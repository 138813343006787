import { AlertMessage } from 'components/common/Alert/AlertMessage';
import { FloatingTopContent } from 'components/common/FloatingTopContent/FloatingTopContent';
import ModuleContainer from 'components/common/ModuleContainer';
import ModuleHeader from 'components/common/ModuleHeader';
import { ROUTES } from 'components/navigation/Constants';
import { BundleSplitCaseSubmissionSummary } from 'components/order-entry/BundleSplitCaseSubmissionSummary/BundleSplitCaseSubmissionSummary';
import CaseSubmissionSummary from 'components/order-entry/CaseSubmissionSummary/CaseSubmissionSummary';
import { useAppConfig } from 'providers/AppProvider';
import { SubscriptionsContext } from 'providers/SubscriptionProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { subscribeToWorkOrder } from 'shared/api/workorder.api';
import { NEW_CASE } from 'shared/constants/constants';
import { ToastNotificationType } from 'shared/enums';
import { printWorkOrder } from 'shared/helpers/printing/print.helper';
import { PreviousOrderRouteState } from 'shared/models';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { v4 as uuidv4 } from 'uuid';
import { OrderEntryLookupForm } from './OrderEntryLookupForm/OrderEntryLookupForm';

const OrderEntryLookupPage: React.FC = () => {
  const [disableReprint, setDisableReprint] = useState(true);
  const [subscribeCalled, setSubscribeCalled] = useState<boolean>(false);
  const [prevRouteState, setPrevRouteState] = useState<PreviousOrderRouteState>();
  const { state: previousOrderState } = useLocation();
  const navigate = useNavigate();

  const toast = useContext(ToastContext);
  const subscriptions = useContext(SubscriptionsContext);
  const { sessionId } = useAppConfig();

  const bundledSplitCases = useBundleSplitCaseStore(state => state.bundledSplitCases);

  const previousOrderNumber = useMemo(() => prevRouteState?.orderId, [prevRouteState?.orderId]);
  const previousIsReturnForCredit = useMemo(
    () => prevRouteState?.isReturnForCredit,
    [prevRouteState?.isReturnForCredit]
  );

  /**
   * Maintain state for previous route state so the Last Case box is not removed from DOM
   * when we remove the route state.
   */
  useEffect(() => {
    if (previousOrderState) {
      setPrevRouteState(previousOrderState);
    }
  }, [previousOrderState]);

  /**
   * Handle subscribing to work order when coming from order entry page.
   * There will be an orderId in location state if coming from order entry page.
   */
  useEffect(() => {
    if (previousOrderNumber && !subscribeCalled) {
      const subId = uuidv4();
      const sub = subscribeToWorkOrder({
        OrderNumber: previousOrderNumber,
        SessionId: sessionId,
      }).subscribe({
        next: async () => {
          // no need to keep this open
          sub.unsubscribe();
          try {
            await printWorkOrder({ orderNumber: previousOrderNumber });
          } catch (e) {
            const error = e as Error;
            console.error('Error printing work order.', error);
            toast.notify(error.message || 'Error printing work order.', ToastNotificationType.Error);
          } finally {
            setDisableReprint(false);
          }
        },
        error: error => {
          console.warn(error);
          sub.unsubscribe();
        },
      });

      // add this sub to context so we could access it from anywhere
      subscriptions.addSubscription({ uuid: subId, subscription: sub });
      setSubscribeCalled(true);
      navigate('.', { relative: 'route', replace: true, state: undefined });
    }
  }, [navigate, previousOrderNumber, sessionId, subscribeCalled, subscriptions, toast]);

  const errorMessage = prevRouteState?.errorMessage;
  let notificationMessage = '';
  if (errorMessage) {
    notificationMessage = errorMessage;
  } else if (prevRouteState?.orderId) {
    notificationMessage = `Case # ${prevRouteState?.orderId} has been submitted successfully`;
  }

  const orderIdFromRmaId = prevRouteState?.orderId.split('-')[1];
  const creditNotificationMsg = `A credit for Case #${orderIdFromRmaId} have been submitted successfully for processing`;

  /**
   * handles submission of order number
   * navigates to case entry page
   *
   * @param orderNumber - order number submitted
   *
   * NOTE: for creation of new case, {@link NEW_CASE} should be passed
   *
   */
  const onSubmit = (orderNumber: string) => {
    navigate({
      pathname: ROUTES.NEW_ORDER,
      search: orderNumber === NEW_CASE ? '' : `?orderNumber=${orderNumber}`,
    });
  };

  return (
    <>
      <ModuleContainer>
        <ModuleHeader title="Case Entry" />
        {prevRouteState && (
          <>
            <FloatingTopContent>
              <div className="justify-items-center">
                {previousIsReturnForCredit && !errorMessage && (
                  <AlertMessage
                    className="mb-5 w-auto"
                    message={creditNotificationMsg}
                    type={ToastNotificationType.Success}
                    showCloseIcon
                  />
                )}
                <AlertMessage
                  className="w-auto"
                  message={notificationMessage || 'Something went wrong. Please try again later.'}
                  type={errorMessage ? ToastNotificationType.Warning : ToastNotificationType.Success}
                  showCloseIcon
                />
              </div>
            </FloatingTopContent>
            <div className="fixed bottom-8 right-8"></div>
          </>
        )}

        <div className="fixed bottom-8 right-8 space-y-4">
          {prevRouteState ? (
            <CaseSubmissionSummary
              orderId={prevRouteState.orderId}
              orderItems={prevRouteState.orderItems}
              disableReprint={disableReprint}
            />
          ) : (
            bundledSplitCases.map(order => (
              <BundleSplitCaseSubmissionSummary
                key={order.orderNumber}
                orderNumber={order.orderNumber}
                orderItems={order.orderItems}
              />
            ))
          )}
        </div>

        <OrderEntryLookupForm showNewRMAButton onSubmit={onSubmit} />
      </ModuleContainer>
    </>
  );
};

export default OrderEntryLookupPage;
