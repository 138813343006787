import { ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { ShortcutKeysContext } from 'providers/ShortcutKeysProvider';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ShortCutKeys } from 'shared/constants/shortcut-keys.constants';
import { KeyCode } from 'shared/enums';
import Label from './Label';
import RequiredMessage from './RequiredMessage/RequiredMessage';
import { ResetButton } from './ResetButton';
import { SkeletonBorder } from './Skeleton';
import SuggestionList from './SuggestionList';

interface SuggestionsListProps {
  filteredSuggestions: string[];
  activeSuggestionIndex: number;
  onSelect: (selected: string) => void;
  onActiveSuggestionChange: (index: number) => void;
  dataQa: string;
}

/**
 * Component representing a list of suggestions.
 * @param filteredSuggestions - Array of filtered suggestions.
 * @param activeSuggestionIndex - Index of the active suggestion.
 * @param onSelect - Function to handle selection of a suggestion.
 * @param onActiveSuggestionChange - Function to handle change of active suggestion.
 * @param dataQa - Data attribute for testing.
 * @returns JSX.Element representing the SuggestionsList component.
 */
const SuggestionsList: React.FC<SuggestionsListProps> = ({
  filteredSuggestions,
  activeSuggestionIndex,
  onSelect,
  onActiveSuggestionChange,
  dataQa,
}) => {
  const hasSuggestions = filteredSuggestions.length > 0;

  return (
    <SuggestionList
      filteredSuggestions={hasSuggestions ? filteredSuggestions : ['No Result found']}
      activeSuggestionIndex={hasSuggestions ? activeSuggestionIndex : -1}
      onSelect={selected => (hasSuggestions ? onSelect(selected) : null)}
      onActiveSuggestionChange={suggestion => (hasSuggestions ? onActiveSuggestionChange(suggestion) : null)}
      dataQa={dataQa}
    />
  );
};

interface SearchableDropdownProps {
  options: string[];
  selected: string;
  onSelectedChange: (selected: string) => void;
  dataQa: string;
  id?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  isRequired?: boolean;
  onSubmitAlert?: boolean;
  skeletonLoader?: boolean;
  enableAutoFocus?: boolean;
  className?: string;
  isAbleToReset?: boolean;
  onValueReset?: () => void;
  errorMessage?: string;
  showErrorBorderOnly?: boolean;
  forceErrorState?: boolean;
  overwriteName?: string;
}

/**
 * Component representing a searchable dropdown input.
 * @param options - Array of dropdown options.
 * @param selected - Selected option.
 * @param onSelectedChange - Function to handle selection change.
 * @param id - ID attribute for the input.
 * @param label - Label text for the input.
 * @param placeholder - Placeholder text for the input.
 * @param disabled - Indicates if the input is disabled.
 * @param isRequired - Indicates if the input is required.
 * @param onSubmitAlert - Indicates if an alert should be shown on form submission.
 * @param skeletonLoader - Indicates if a skeleton loader should be displayed.
 * @param enableAutoFocus - Indicates if the input should autofocus.
 * @param className - Additional class names for styling.
 * @param isAbleToReset - Indicates if the input value can be reset.
 * @param onValueReset - Function to handle value reset.
 * @param errorMessage - Error message to display.
 * @param showErrorBorderOnly - Indicates if the error border should be shown only.
 * @param forceErrorState - Indicates if the error state should be forced.
 * @param overwriteName - Overwrites the input name attribute.
 * @returns JSX.Element representing the SearchableDropdown component.
 */
const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  options,
  selected,
  onSelectedChange,
  id,
  label,
  placeholder,
  disabled,
  dataQa,
  isRequired,
  onSubmitAlert,
  skeletonLoader,
  enableAutoFocus,
  className,
  isAbleToReset,
  onValueReset,
  errorMessage,
  showErrorBorderOnly,
  forceErrorState,
  overwriteName,
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(-1);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const shortcutKeyCtx = useContext(ShortcutKeysContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsRef = useRef(options);

  useEffect(() => {
    optionsRef.current = options;
    setFilteredSuggestions(optionsRef.current);
  }, [options]);

  useEffect(() => {
    if (!selected.length) {
      setFilteredSuggestions(optionsRef.current);
    }
  }, [selected]);

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useEffect(() => {
    if (disabled) {
      return;
    } else if (shortcutKeyCtx.keyPressed === ShortCutKeys.AltM) {
      inputRef?.current?.focus();
      setShowSuggestions(true);
    }
  }, [disabled, shortcutKeyCtx.keyPressed]);

  useEffect(() => {
    if (enableAutoFocus) {
      inputRef?.current?.focus();
    }
  }, [enableAutoFocus]);

  const inputChangeHandler = (enteredValue: string) => {
    if (disabled) return;
    const unLinked = optionsRef.current.filter(state => state.toLowerCase().indexOf(enteredValue.toLowerCase()) > -1);
    setValue(enteredValue);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  const selectSuggestionHandler = (value: string) => {
    setFilteredSuggestions([]);
    setValue(value);
    setActiveSuggestionIndex(0);
    onSelectedChange(value);
    hideSuggestionList();
  };

  const inputKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (!e.code || disabled) {
      return;
    }
    const filteredSuggestionsLength = filteredSuggestions.length;

    if (e.code.startsWith(KeyCode.Enter)) {
      if (activeSuggestionIndex >= 0 && filteredSuggestions[activeSuggestionIndex]) {
        selectSuggestionHandler(filteredSuggestions[activeSuggestionIndex]);
      }
    } else if (e.code.startsWith(KeyCode.Down)) {
      if (!showSuggestions) {
        setShowSuggestions(true);
        setActiveSuggestionIndex(0);
        return;
      }

      if (activeSuggestionIndex < filteredSuggestionsLength - 1) {
        setActiveSuggestionIndex(activeSuggestionIndex + 1);
      } else {
        setActiveSuggestionIndex(0);
      }
    } else if (e.code.startsWith(KeyCode.Up)) {
      if (!showSuggestions) {
        setShowSuggestions(true);
        setActiveSuggestionIndex(filteredSuggestionsLength - 1);
        return;
      }

      if (activeSuggestionIndex > 0) {
        setActiveSuggestionIndex(activeSuggestionIndex - 1);
      } else {
        setActiveSuggestionIndex(filteredSuggestionsLength - 1);
      }
    } else if (e.code.startsWith(KeyCode.Escape)) {
      hideSuggestionList();
    }
  };

  const hideSuggestionList = () => {
    setShowSuggestions(false);
    setActiveSuggestionIndex(-1);
  };

  const resetInput = () => {
    if (value.length && !selected) {
      const match = optionsRef.current.filter(s => s.toLowerCase() === value.toLowerCase())[0];
      if (!match) {
        clearInputHandler();
      } else if (match !== value) {
        setValue(match);
        onSelectedChange(match);
      }
    } else if (selected && selected !== value) {
      clearInputHandler();
    }
    hideSuggestionList();
  };

  const clearInputHandler = (isFocusInput = false) => {
    setValue('');
    setFilteredSuggestions(optionsRef.current);
    onSelectedChange('');
    if (isFocusInput) {
      inputRef.current?.focus();
    }
  };

  const isFieldInvalidState = errorMessage || forceErrorState || (onSubmitAlert && !value && isRequired);
  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} className="ml-1" />;
  const onRightIconPress = () => {
    if (disabled) return;
    setShowSuggestions(true);
    inputRef.current?.focus();
  };

  const onFocusHandler = () => {
    if (disabled) return;
    const filteredSuggestions = optionsRef.current.filter(
      state => state.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    setFilteredSuggestions(filteredSuggestions);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
  };

  return (
    <div className="h-auto">
      {label && (
        <Label htmlFor={id} required={isRequired} labelClassName="flex">
          {label}
          {resetButton}
        </Label>
      )}
      {!label && resetButton}
      <div className="relative">
        {skeletonLoader ? (
          <SkeletonBorder className="h-10 pr-10 mt-0" />
        ) : (
          <input
            type="text"
            id={id}
            className={classNames(className, 'shadow-sm text-sm rounded-md w-full pr-10 overflow-ellipsis', {
              'focus:ring-red-500 focus:border-red-500 border-red-500': !showSuggestions && isFieldInvalidState,
              'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300': !isFieldInvalidState,
              'bg-gray-100 border-gray-200 text-gray-400': disabled,
              'bg-white': !disabled,
            })}
            value={overwriteName || value}
            placeholder={placeholder}
            onMouseDown={() => !disabled && setShowSuggestions(true)}
            onChange={e => inputChangeHandler(e.target.value)}
            onBlur={() => resetInput()}
            onKeyDown={e => inputKeyDownHandler(e)}
            ref={inputRef}
            data-qa={dataQa}
            data-testid={dataQa}
            disabled={disabled}
            autoComplete="off"
            onFocus={onFocusHandler}
          />
        )}
        {showSuggestions && !disabled && (
          <SuggestionsList
            dataQa={dataQa}
            filteredSuggestions={filteredSuggestions}
            onActiveSuggestionChange={setActiveSuggestionIndex}
            onSelect={selectSuggestionHandler}
            activeSuggestionIndex={activeSuggestionIndex}
          />
        )}
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center self-center h-10">
          {value?.length && !showSuggestions && !disabled ? (
            <XMarkIcon
              className="h-5 w-5 text-gray-400 cursor-pointer"
              aria-hidden="true"
              onClick={() => !disabled && clearInputHandler(true)}
            />
          ) : showSuggestions ? (
            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          ) : (
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
              onClick={() => onRightIconPress()}
            />
          )}
        </div>
      </div>
      {isFieldInvalidState && (
        <RequiredMessage fieldName={label} errorMessage={!showErrorBorderOnly ? errorMessage : ''} />
      )}
    </div>
  );
};

export default SearchableDropdown;
