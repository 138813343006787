import { ProductReturnRequirement } from 'API';
import { LocalOrderItemInput } from 'shared/models';
import { getProductReturnRequirements } from 'shared/utils';
import { StateCreator } from 'zustand';

interface State {
  returnRequirementCache: Record<string, ProductReturnRequirement>;
}

interface Actions {
  fetchProductReturnRequirement: (orderItems: LocalOrderItemInput[]) => Promise<ProductReturnRequirement[] | undefined>;
}

export type ProductReturnRequirementCacheSlice = State & Actions;

export const initialState: State = {
  returnRequirementCache: {},
};

export const createProductReturnRequirementCacheSlice: StateCreator<ProductReturnRequirementCacheSlice> = (
  set,
  get
) => ({
  ...initialState,
  fetchProductReturnRequirement: async orderItems => {
    const { returnRequirementCache } = get();
    // Filter out order items that already haven't been fetched
    const filteredOrderItems = orderItems.filter(item => !!item.productCode);
    if (!filteredOrderItems.length) return; // If there are no order items to fetch, return

    const cachedOrderItems = filteredOrderItems.filter(item => !!returnRequirementCache[item.productCode]);
    const nonCachedOrderItems = filteredOrderItems.filter(item => !returnRequirementCache[item.productCode]);
    const newCache = { ...returnRequirementCache };

    try {
      nonCachedOrderItems.forEach(data => {
        // Set the dummy data to prevent fetching the same data multiple times
        newCache[data.productCode] = {
          __typename: 'ProductReturnRequirement',
          isReturnRequired: false,
          productCode: data.productCode,
        };
      });

      set({ returnRequirementCache: newCache });

      // Fetch product return requirements for the order items
      const productReturnRequirements = await getProductReturnRequirements(nonCachedOrderItems);

      // Update the cache with the new product return requirements
      productReturnRequirements.forEach(data => {
        newCache[data.productCode] = data;
      });

      // Update the cache
      set({ returnRequirementCache: newCache });
      const cachedProductReturnRequirements = cachedOrderItems.map(item => {
        return returnRequirementCache[item.productCode];
      });
      // Return the product return requirements fetched from the server and the cached product return requirements
      return [...productReturnRequirements, ...cachedProductReturnRequirements];
    } catch (error) {
      console.error(error);
      nonCachedOrderItems.forEach(data => {
        delete newCache[data.productCode];
      });
      set({ returnRequirementCache: newCache });
    }
  },
});
