import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { getTrackingUrl } from 'shared/helpers/order-entry/order-entry.helper';

/**
 * Props for the TrackingNumberLink component.
 */
interface TrackingNumberLinkProps {
  /**
   * The tracking number.
   */
  trackingNumber: string;
  /**
   * A boolean indicating whether the tracking is voided.
   */
  isVoided: boolean;
}

/**
 * Component to display a tracking number link.
 * @param trackingNumber - The tracking number.
 * @param isVoided - A boolean indicating whether the tracking is voided.
 * @returns The JSX element representing the TrackingNumberLink component.
 */
export const TrackingNumberLink: FC<TrackingNumberLinkProps> = ({ trackingNumber, isVoided }) => {
  const tracking = getTrackingUrl(trackingNumber);
  if (!tracking.url) return <>{trackingNumber}</>;
  if (isVoided) return <>{trackingNumber} (Void)</>;
  return (
    <a
      className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
      href={tracking.url + tracking.search}
      target="_blank"
      rel="noreferrer"
    >
      {trackingNumber}
      <ArrowTopRightOnSquareIcon className="text-indigo-500 text-sm font-medium h-4 w-4 ml-2" />
    </a>
  );
};
