import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { getSortableOrder } from 'shared/helpers/util.helper';
import { cn } from 'shared/utils';

/**
 * Props for the THeadCell component.
 */
interface THeadCellProps {
  /**
   * Unique identifier for the table head cell.
   */
  id: string;
  /**
   * Indicates whether the column is sortable (default: false).
   */
  sortable?: boolean;
  /**
   * The current order by which the table is sorted.
   */
  orderBy?: string;
  /**
   * Callback function invoked when the order by value changes.
   */
  onChangeOrderBy?: (orderBy: string) => void;
  /**
   * Additional classes for styling.
   */
  className?: string;
}

/**
 * Component representing a header cell in a table.
 * @param id - Unique identifier for the table head cell.
 * @param sortable - Indicates whether the column is sortable (default: false).
 * @param orderBy - The current order by which the table is sorted.
 * @param onChangeOrderBy - Callback function invoked when the order by value changes.
 * @param className - Additional classes for styling.
 * @returns JSX.Element representing the THeadCell component.
 */
export const THeadCell: React.FC<THeadCellProps> = ({
  children,
  id,
  sortable,
  orderBy = '',
  onChangeOrderBy,
  className,
}) => {
  const { id: columnId, order } = getSortableOrder(orderBy);
  const isAsc = order === 'asc';

  const toggleSortHandler = () => {
    onChangeOrderBy?.(`${isAsc ? '-' : ''}${id}`);
  };

  return (
    <th
      scope={`col-${id}`}
      className={cn(
        'px-6 py-3 text-left whitespace-nowrap text-xs font-medium text-gray-500 uppercase tracking-wider',
        className
      )}
    >
      <div className="flex gap-2 items-center">
        <div className="flex-1">{children}</div>
        {sortable && (
          <button className="ml-1 flex flex-col" onClick={toggleSortHandler}>
            <div
              className={classNames('w-3 h-3 stroke-current stroke-2 text-gray-300', {
                'text-indigo-500': isAsc && columnId === id,
              })}
            >
              <ChevronUpIcon aria-hidden="true" />
            </div>
            <div
              className={classNames('w-3 h-3 -mt-1 stroke-current stroke-2 text-gray-300', {
                'text-indigo-500': !isAsc && columnId === id,
              })}
            >
              <ChevronDownIcon aria-hidden="true" />
            </div>
          </button>
        )}
      </div>
    </th>
  );
};
