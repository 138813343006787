import { CaseDiscountType } from 'API';
import { RadioInput } from 'components/common/RadioInput/RadioInput';
import { FC, useEffect } from 'react';
import { SPECIAL_DISCOUNT_TYPE_LISTS } from 'shared/constants/invoice-data.constants';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { SpecialDiscountTypeOption } from 'types/common';

/**
 * Props for the DiscountTypeRadio component.
 */
interface DiscountTypeRadioProps extends SpecialDiscountTypeOption {
  /**
   * The selected value of the radio input.
   */
  selectedValue: string;
  /**
   * Function to handle change of the selected value.
   */
  handleChange: (value: CaseDiscountType) => void;
  /**
   * Boolean flag indicating if the radio input is disabled.
   */
  disabled?: boolean;
}

/**
 * Component for displaying radio input for discount types.
 *
 * @param id - The id of the radio input.
 * @param value - The value of the radio input.
 * @param selectedValue - The selected value of the radio input.
 * @param label - The label for the radio input.
 * @param handleChange - Function to handle change of the selected value.
 * @param disabled - Boolean flag indicating if the radio input is disabled.
 * @returns DiscountTypeRadio component.
 */
const DiscountTypeRadio: FC<DiscountTypeRadioProps> = ({ id, value, selectedValue, label, handleChange, disabled }) => {
  return (
    <RadioInput
      type="radio"
      name={id}
      id={id}
      value={value}
      checked={selectedValue === value}
      onChange={e => handleChange(e.target.value as CaseDiscountType)}
      label={label}
      disabled={disabled}
    />
  );
};

export const DiscountCaseType: FC = () => {
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const setSelectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.setSelectedDiscountType);
  const resetDiscount = useAddDiscountModalStore(state => state.specialDiscount.resetDiscount);
  const setDiscounts = useAddDiscountModalStore(state => state.specialDiscount.setDiscounts);

  /**
   * Set default selected discount type if not selected
   */
  useEffect(() => {
    if (selectedDiscountType) return;
    setSelectedDiscountType(SPECIAL_DISCOUNT_TYPE_LISTS[0].value);
  }, [selectedDiscountType, setSelectedDiscountType]);

  const handleChange = (value: CaseDiscountType) => {
    setSelectedDiscountType(value);
    if (value === CaseDiscountType.EntireCase) {
      setDiscounts([]);
    } else {
      resetDiscount();
    }
  };

  return (
    <div className="mt-1 flex flex-col gap-1 w-full">
      {SPECIAL_DISCOUNT_TYPE_LISTS.map(discountType => (
        <DiscountTypeRadio
          key={discountType.id}
          {...discountType}
          selectedValue={selectedDiscountType}
          handleChange={handleChange}
        />
      ))}
    </div>
  );
};
