import CheckboxInput from 'components/common/CheckboxInput';
import Dropdown from 'components/common/Dropdown/Dropdown';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { DepartmentsListConfig } from 'configurations/DepartmentConfig';
import { usePrevious } from 'hooks/use-previous';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { ProductState } from 'shared/enums/product';
import {
  extractRestorationFromClassifications,
  sortProductMaterialNames,
} from 'shared/helpers/order-entry/order-entry.helper';
import { DropdownModel } from 'shared/models';

/**
 * Props for the ProductSearch component.
 */
interface ProductSearchProps {
  /**
   * Callback function invoked when a material and restoration are selected.
   */
  onMaterialAndRestorationSelect: (productCode: string | null) => void;
  /**
   * Flag indicating whether the component is disabled.
   */
  disabled: boolean;
  /**
   * The ID of the billing account associated with the product.
   */
  billingAccountId: string;
  /**
   * The state of the product.
   */
  productState: ProductState;
  /**
   * The label for the material dropdown.
   */
  materialLabel?: string;
  /**
   * Flag indicating whether to show the "Product Unknown" checkbox.
   */
  showProductUnknown?: boolean;
  /**
   * The initial material name.
   */
  initialMaterialName?: string;
  /**
   * The initial restoration name.
   */
  initialRestorationName?: string;
}

/**
 * The ProductSearch component allows users to search for materials and restorations.
 * @param onMaterialAndRestorationSelect - Callback function invoked when a material and restoration are selected.
 * @param disabled - Flag indicating whether the component is disabled.
 * @param billingAccountId - The ID of the billing account associated with the product.
 * @param productState - The state of the product.
 * @param materialLabel - The label for the material dropdown. (optional)
 * @param showProductUnknown - Flag indicating whether to show the "Product Unknown" checkbox. (optional)
 * @param initialMaterialName - The initial material name. (optional)
 * @param initialRestorationName - The initial restoration name. (optional)
 * @returns JSX element representing the ProductSearch component.
 */
const ProductSearch: React.FC<ProductSearchProps> = ({
  materialLabel = 'Material/Service',
  showProductUnknown = false,
  onMaterialAndRestorationSelect,
  disabled,
  billingAccountId,
  productState,
  initialMaterialName,
  initialRestorationName,
}) => {
  const [selectedMaterial, setSelectedMaterial] = useState<string>(initialMaterialName || '');
  const [selectedRestoration, setSelectedRestoration] = useState<string>(initialRestorationName || '');
  const [restorations, setRestorations] = useState<string[]>([]);
  const [isProductUnknown, setIsProductUnknown] = useState<boolean>(false);
  const [department, setDepartment] = useState<DropdownModel>({
    primaryLabel: '',
    secondaryLabel: '',
    value: '',
  });
  const [departmentOption, setDepartmentOption] = useState<DropdownModel[]>([]);
  const { onSubmitAlertCount, classifications, orderUpdateCount } = useContext(OrderModuleContext);
  const { increaseOrderUpdateCount } = useContext(OrderModuleActionsContext);

  const currentProductCode = useMemo(() => {
    if (!selectedMaterial || !selectedRestoration) return null;
    const classification = classifications.find(p => p.materialName === selectedMaterial);
    const classificationRestoration = classification?.restorations.find(r => r.restorationName === selectedRestoration);
    return classificationRestoration?.productCode || null;
  }, [classifications, selectedMaterial, selectedRestoration]);

  const prevProductCode = usePrevious(currentProductCode);

  /**
   * Clear the selected restoration if we ever have an error loading the product information.
   */
  useEffect(() => {
    if (productState === ProductState.AttributesError) {
      setSelectedRestoration('');
    }
  }, [productState]);

  /**
   * Sorts and set state for restorations with Single as first item then by alphabetic order
   */
  useEffect(() => {
    if (!selectedMaterial) {
      setRestorations([]);
      setSelectedRestoration('');
      return;
    }

    setRestorations(extractRestorationFromClassifications(classifications, selectedMaterial));
  }, [selectedMaterial, classifications, setRestorations, setSelectedRestoration]);

  /**
   * When the product code changes, call the method handler in the parent component with the new product code.
   */
  useEffect(() => {
    if (orderUpdateCount === 0) {
      return;
    }

    if (currentProductCode !== prevProductCode) {
      onMaterialAndRestorationSelect(currentProductCode);
    }
  }, [currentProductCode, onMaterialAndRestorationSelect, orderUpdateCount, prevProductCode]);

  useEffect(() => {
    const departmentsList = DepartmentsListConfig.find(d => d.SiteId === billingAccountId?.split('-')[0]);
    const options: DropdownModel[] | undefined = departmentsList?.Departments?.map(name => ({
      primaryLabel: name,
      value: name,
    }));
    options && setDepartmentOption(options);
    setDepartment({
      primaryLabel: '',
      secondaryLabel: '',
      value: '',
    });
  }, [billingAccountId]);

  const sortedClassificationOptions = useMemo(() => {
    return sortProductMaterialNames(classifications.map(c => c.materialName));
  }, [classifications]);

  const isLoading = useMemo(() => {
    return productState === ProductState.AttributesLoading;
  }, [productState]);

  return (
    <>
      <div className="flex w-full gap-6 mt-3">
        <div className="w-1/2">
          <SearchableDropdown
            options={sortedClassificationOptions}
            selected={selectedMaterial}
            onSelectedChange={newMaterial => {
              increaseOrderUpdateCount();
              setSelectedMaterial(newMaterial);
            }}
            id="material"
            dataQa="materialSelect"
            label={materialLabel}
            placeholder="Search materials"
            disabled={disabled || isProductUnknown}
            onSubmitAlert={onSubmitAlertCount ? !isProductUnknown : false}
            skeletonLoader={isLoading}
            isRequired
          />
        </div>
        {(isLoading || selectedMaterial) && (
          <div className="w-1/2">
            <SearchableDropdown
              options={restorations}
              selected={selectedRestoration}
              onSelectedChange={newRestoration => {
                increaseOrderUpdateCount();
                setSelectedRestoration(newRestoration);
              }}
              id="restoration"
              dataQa="restorationSelect"
              label="Restoration"
              placeholder="Search restorations"
              disabled={disabled}
              enableAutoFocus={!initialRestorationName}
              onSubmitAlert={!!onSubmitAlertCount}
              skeletonLoader={isLoading}
              isRequired
            />
          </div>
        )}
      </div>
      {showProductUnknown && !selectedMaterial && (
        <div>
          <div>
            <CheckboxInput
              id="productUnknown"
              checked={isProductUnknown}
              label={'Product Unknown'}
              onChange={() => {
                setIsProductUnknown(!isProductUnknown);
              }}
              inLine={true}
              checkBoxAtStart={true}
            />
          </div>
          {isProductUnknown && (
            <div className="w-1/2 mt-4">
              <Dropdown
                label="Department"
                data={departmentOption}
                selected={department}
                setSelected={e => {
                  setDepartment(e);
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ProductSearch;
