import { cn } from 'shared/utils';

/**
 * Props for the CheckboxInput component.
 */
interface CheckboxInputProps {
  /**
   * The id of the checkbox input.
   */
  id: string;
  /**
   * Whether the checkbox is checked or not.
   */
  checked: boolean;
  /**
   * Handler function for onChange event.
   */
  onChange: (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  /**
   * The label text for the checkbox.
   */
  label: string;
  /**
   * Whether to display the checkbox inline with other elements.
   */
  inLine?: boolean;
  /**
   * Whether to place the checkbox before the label.
   */
  checkBoxAtStart?: boolean;
  /**
   * The value attribute for the checkbox input.
   */
  value?: string;
  /**
   * Additional CSS class for the root element.
   */
  className?: string;
  /**
   * Additional CSS class for the label element.
   */
  labelClassName?: string;
  /**
   * Whether the checkbox is disabled or not.
   */
  disabled?: boolean;
}

/**
 * Component for rendering a checkbox input.
 * @param id - The id of the checkbox input.
 * @param checked - Whether the checkbox is checked or not.
 * @param onChange - Handler function for onChange event.
 * @param label - The label text for the checkbox.
 * @param inLine - Whether to display the checkbox inline with other elements.
 * @param checkBoxAtStart - Whether to place the checkbox before the label.
 * @param value - The value attribute for the checkbox input.
 * @param className - Additional CSS class for the root element.
 * @param labelClassName - Additional CSS class for the label element.
 * @param disabled - Whether the checkbox is disabled or not.
 * @returns JSX.Element representing the CheckboxInput component.
 */
const CheckboxInput: React.FC<CheckboxInputProps> = ({
  id,
  checked,
  onChange,
  label,
  inLine,
  checkBoxAtStart,
  value,
  className,
  labelClassName,
  disabled,
}) => {
  return (
    <div className={inLine ? 'flex items-start' : ''}>
      {label.length > 0 && (
        <label
          htmlFor={id}
          className={cn(
            'text-sm font-medium text-gray-700 block mt-0.5 break-all cursor-pointer',
            {
              'order-2': inLine && checkBoxAtStart,
              'opacity-60 cursor-default': disabled,
            },
            labelClassName
          )}
        >
          {label}
        </label>
      )}
      <input
        id={id}
        data-qa={id}
        data-testid={id}
        className={cn(
          'mr-2 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded shadow-sm block cursor-pointer',
          {
            'order-1': inLine && checkBoxAtStart,
            'mt-1': label.length > 0,
            'mt-3': label.length === 0,
          },
          className
        )}
        type="checkbox"
        checked={checked}
        onChange={e => onChange(e, e.target.checked)}
        value={value}
        disabled={disabled}
      />
    </div>
  );
};

export default CheckboxInput;
