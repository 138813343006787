import Label from '../Label';
import { FacetItem } from './FacetItem';
import { Facet } from './types';

/**
 * Props for the FacetFilter component.
 */
interface FacetFilterProps<TFacet extends Facet, TOption> {
  /** The label for the facet filter. */
  label: string;
  /** The list of facets to display. */
  facets: TFacet[];
  /** The currently selected values for each facet. */
  selectedValues: Record<TFacet['id'], string[]>;
  /** Function to handle changes in selected values. */
  onSelectedValuesChange: (selectedValues: Record<TFacet['id'], string[]>) => void;
  /** Optional function to handle changes in individual facet values. */
  onValueChange?: (facetId: TFacet['id'], option: TOption) => void;
  /** Optional function to handle clearing all selected values. */
  onClear?: () => void;
}

/**
 * Component for displaying and managing facet filters.
 * @param label - The label for the facet filter.
 * @param facets - The list of facets to display.
 * @param selectedValues - The currently selected values for each facet.
 * @param onSelectedValuesChange - Function to handle changes in selected values.
 * @param onValueChange - Optional function to handle changes in individual facet values.
 * @param onClear - Optional function to handle clearing all selected values.
 * @returns JSX element representing the facet filter.
 */
export const FacetFilter = <TFacet extends Facet, TOption = TFacet['options'][number]>({
  label,
  facets,
  selectedValues,
  onSelectedValuesChange,
  onValueChange,
  onClear,
}: FacetFilterProps<TFacet, TOption>) => {
  const onValueChangeHandle = (
    selectedValues: Record<TFacet['id'], string[]>,
    option: TOption,
    facetId: TFacet['id']
  ) => {
    onSelectedValuesChange(selectedValues);
    onValueChange?.(facetId, option);
  };

  const onClearHandler = () => {
    const newSelectedValues: Record<string, string[]> = {};
    facets.forEach(facet => {
      const facetId = facet.id;
      newSelectedValues[facetId] = [];
    });
    onSelectedValuesChange(newSelectedValues);
    onClear?.();
  };

  return (
    <div className="border border-gray-300 rounded-md text-sm leading-5">
      <div className="flex px-4 py-3">
        <Label>{label}</Label>
        <button className="ml-auto text-indigo-600" onClick={onClearHandler}>
          Clear
        </button>
      </div>
      {facets.map(facet => (
        <FacetItem
          key={facet.name}
          facet={facet}
          onSelectedValuesChange={(selectedValues, option: TOption) =>
            onValueChangeHandle(selectedValues, option, facet.id)
          }
          selectedValues={selectedValues}
        />
      ))}
    </div>
  );
};
