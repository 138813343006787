import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import React from 'react';
import { DISABLED_LINK_CLASSNAME } from 'shared/constants/constants';
import { ShippingCarrier } from 'shared/enums';
import { cn } from 'shared/utils';
import styles from './CarrierListElement.module.css';

/**
 * Props for the CarrierListElement component.
 */
interface CarrierListElementProps {
  /**
   * The name of the carrier.
   */
  name: string;
  /**
   * The URL of the carrier's logo.
   */
  logo: string;
  /**
   * The preferred carrier.
   */
  preferredCarrier: string;
}

/**
 * Component representing a carrier in the list of carriers.
 * Allows users to select a carrier for shipping.
 * @param name - The name of the carrier.
 * @param logo - The URL of the carrier's logo.
 * @param preferredCarrier - The preferred carrier.
 * @returns JSX element representing the carrier list element.
 */
export const CarrierListElement: React.FC<CarrierListElementProps> = ({ name, logo, preferredCarrier }) => {
  const { invoiceShippingInput, onSubmitAlertCount, setInvoiceShippingInput } = useInvoicingDetail();
  const { carrier, trackingNumber, isSignatureRequiredPreference } = invoiceShippingInput;
  const isActive = carrier === name;
  const isPreferredCarrier = preferredCarrier === carrier;

  /**
   * Handles the click event when a carrier is selected.
   * Updates the invoiceShippingInput state with the selected carrier.
   */
  const handleClick = () => {
    if (trackingNumber) return;
    const isDoctorPickup = name === ShippingCarrier.DoctorPickup;
    setInvoiceShippingInput({
      carrier: name,
      shippingService: '',
      isDoctorPickup,
      // If the carrier is doctor pickup, then the signature is not required, even if the preference is true
      isSignatureRequired: isDoctorPickup ? false : isSignatureRequiredPreference ?? false,
    });
  };
  return (
    <div
      className={cn(
        `text-sm font-medium text-gray-700 cursor-pointer border inline-flex items-center px-4 py-4 flex-grow first:rounded-l-md last:rounded-r-md`,
        {
          'bg-teal-100 border text-black border-teal-500': isActive && isPreferredCarrier,
          'bg-orange-100 text-black border border-orange-500': isActive && !isPreferredCarrier,
          'bg-transparent hover:bg-gray-50': !isActive && !isPreferredCarrier,
          'bg-indigo-500 border text-white border-indigo-500': isActive && !preferredCarrier,
          'border-red-500': onSubmitAlertCount > 0 && !invoiceShippingInput.carrier,
          [`${DISABLED_LINK_CLASSNAME} border-gray-300`]: !!trackingNumber,
        }
      )}
      onClick={handleClick}
    >
      <div data-testid={`${name}label`} className="text-center mx-auto">
        {logo && <img src={logo} className={`text-center object-contain ${styles.logo}`} alt={name} />}
        <div className="mt-2">{name}</div>
      </div>
    </div>
  );
};
