import { router } from 'components/navigation/router';
import { NetworkIndicator } from 'components/NetworkIndicator/NetworkIndicator';
import { AlertModalProvider } from 'providers/AlertModalProvider';
import { AppConfigProvider } from 'providers/AppProvider';
import { AuthProvider } from 'providers/AuthProvider';
import { OverlayLoaderProvider } from 'providers/OverlayLoaderProvider';
import { ShortcutKeysProvider } from 'providers/ShortcutKeysProvider';
import { SubscriptionsProvider } from 'providers/SubscriptionProvider';
import { ToastProvider } from 'providers/ToastProvider';
import React from 'react';
import { RouterProvider } from 'react-router-dom';

function App() {
  return (
    <React.StrictMode>
      <AppConfigProvider>
        <SubscriptionsProvider>
          <OverlayLoaderProvider>
            <ToastProvider>
              <AlertModalProvider>
                <AuthProvider>
                  <ShortcutKeysProvider>
                    <RouterProvider router={router} />
                  </ShortcutKeysProvider>
                </AuthProvider>
              </AlertModalProvider>
            </ToastProvider>
          </OverlayLoaderProvider>
        </SubscriptionsProvider>
      </AppConfigProvider>
      <NetworkIndicator />
    </React.StrictMode>
  );
}

export default App;
