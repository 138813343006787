import { PlusIcon } from '@heroicons/react/24/outline';
import { EnclosedItemCategory } from 'API';
import InputError from 'components/common/InputError';
import OrderSearch from 'components/common/OrderSearch/OrderSearch';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getOrderFull } from 'shared/api/order.api';
import { ErrorMessage } from 'shared/enums';
import {
  filterEnclosedItemsByShippingType,
  someCasesHaveMetalArticulator,
  someEnclosedItemsHaveMetalArticulator,
  validateMetalAlloyAttributes,
} from 'shared/helpers/invoice/invoice.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import {
  checkCaseInvalidInvoiceStatus,
  checkCaseIsInvoiced,
  getCaseStatusRecord,
  getShippingAddress,
  getSpiltBundleCaseInfo,
  isCreatedOrder,
} from 'shared/utils';
import { InvoiceErrorMessage } from '../../../../../../shared/enums/invoice-error-message';

const SHIPPING_ADDRESS_ERROR = "This case cannot be bundled because the account # or shipping address doesn't match.";

/**
 * Props interface for the AddBundleCase component.
 */
interface AddBundleCaseProps {
  /**
   * The billing account ID.
   */
  accountId: string;
  /**
   * Array of existing case IDs.
   */
  existingCaseIds: string[];
}

/**
 * Component for adding a case to a bundle.
 * @param accountId - The billing account ID.
 * @param existingCaseIds - Array of existing case IDs.
 */
const AddBundleCase: React.FC<AddBundleCaseProps> = ({ accountId, existingCaseIds }) => {
  const alert = useContext(AlertModalContext);
  const [showBundleButton, setShowBundleButton] = useState(true);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');
  const { fetcher, loading } = useLazyQueryFetcher(getOrderFull);
  const { shipping, newCase, spiltBundleCaseInfo, setSpiltBundleCaseInfo, invoiceCases } = useInvoicingDetail();

  const someCaseshaveArticulator = useMemo(() => {
    return someCasesHaveMetalArticulator(invoiceCases);
  }, [invoiceCases]);

  /**
   * Track changes to invoice cases and revalidate whether or not an articulator has been added.
   * If there is an articulator, show the bundle button again. If there is no longer an articulator and we are showing the articulator
   * error, set error to false.
   * Don't show the error if there is more than 1 case as we show the error under the enclosed items section in this scenario.
   */
  useEffect(() => {
    if (
      errorMessage === InvoiceErrorMessage.ARTICULATOR_BUNDLE &&
      (!someCaseshaveArticulator || (someCaseshaveArticulator && invoiceCases.length > 1))
    ) {
      setErrorMessage('');
    } else if (someCaseshaveArticulator && invoiceCases.length <= 1) {
      setShowBundleButton(true);
      setErrorMessage(InvoiceErrorMessage.ARTICULATOR_BUNDLE);
    }
  }, [errorMessage, invoiceCases.length, someCaseshaveArticulator]);

  /**
   * Validate whether or not there is an articulator. If yes, show error message.
   * If no, show case number input.
   */
  const onAddCaseClick = () => {
    const existingHasArticulator = someCasesHaveMetalArticulator(invoiceCases);
    setShowBundleButton(existingHasArticulator);
    setErrorMessage(existingHasArticulator ? InvoiceErrorMessage.ARTICULATOR_BUNDLE : '');
  };

  const showArticulatorErrorAlert = () => {
    alert.show(
      'Cannot bundle these cases',
      'Cases with metal articulators cannot be bundled. Remove any cases with metal articulators and invoice them separately.'
    );
  };

  /**
   * Handler for searching and adding a case to the bundle.
   *
   * @param caseId - The case ID to search and add to the bundle.
   * @returns A boolean indicating whether the search and addition were successful.
   */
  const onCaseSearchHandle = async (caseId: string) => {
    let isSuccessful = false;

    try {
      // Check if the caseId is empty
      if (!caseId.trim()) throw Error('Case Id is required.');

      // Check if the caseId already exists in the invoice
      if (existingCaseIds.some(id => id === caseId)) throw Error('Case Id already added to the invoice.');

      // Reset error message
      setErrorMessage('');

      // Fetch the order details using the caseId
      const order = await fetcher({ orderNumber: caseId });

      // If the order does not exist, throw an error
      if (!order) throw Error('Case does not exist. Please try another case number.');

      // Check if the billing account id matches
      if (accountId !== order.billingAccountId) {
        throw Error("Cases can't be bundled because the account number doesn’t match.");
      }

      // Check if the order type is valid
      if (!isCreatedOrder(order)) {
        throw Error(`Invalid case type: "${order.__typename}"`);
      }

      // Check if the case is already invoiced
      if (checkCaseIsInvoiced(order.status)) {
        throw Error('Case is already invoiced.');
      }

      // Check if the case has an invalid invoice status
      if (checkCaseInvalidInvoiceStatus(order.status, order.statusReason)) {
        throw Error(`Case is ${getCaseStatusRecord(order.status).displayName}.`);
      }

      // Check if patient information is missing
      if (!order.patientFirstName && !order.patientLastName && !order.patientId) {
        throw Error('Missing patient info.');
      }

      order.enclosedItems = filterEnclosedItemsByShippingType(order.enclosedItems, EnclosedItemCategory.Outbound);

      // Check if any enclosed items have a metal articulator
      if (someEnclosedItemsHaveMetalArticulator(order.enclosedItems)) {
        showArticulatorErrorAlert();
        throw Error(InvoiceErrorMessage.ARTICULATOR_BUNDLE);
      }

      // Check if the shipping address matches
      if (getShippingAddress(shipping?.address) !== getShippingAddress(order.shipping?.address)) {
        throw Error(SHIPPING_ADDRESS_ERROR);
      }

      // Validate metal alloy attributes
      if (!validateMetalAlloyAttributes(order.orderItems)) {
        throw Error(ErrorMessage.MISSING_ALLOY_TYPE_OR_WEIGHT);
      }

      const currentBundleCaseInfo = getSpiltBundleCaseInfo(order);
      const { isBundle, bundleId, bundledOrders } = currentBundleCaseInfo;

      /**
       * This logic only makes sure that the user can't add multiple different bundle case in a single invoice.
       * This logic is only for single bundle case scenario. If the user add multiple bundle case in a single invoice, We need to modify this logic.
       */
      if (
        isBundle &&
        spiltBundleCaseInfo.isBundle &&
        !spiltBundleCaseInfo.bundledOrderNumbers.includes(order.orderNumber)
      ) {
        throw Error(
          `The current case is already part of a ${spiltBundleCaseInfo.bundleId} bundle. So you can't add multiple bundle case in a single invoice. Please invoice it separately.`
        );
      }

      /**
       * If the case is part of a bundle, will set the bundle case info to the invoicing detail context
       * So that we can use it when user submit the invoice.
       */
      if (isBundle) {
        setSpiltBundleCaseInfo(currentBundleCaseInfo);
      }

      if (!location.state) {
        /**
         * If the location state is not available, we will set the location state with the ordersScanned, bundleId and bundledOrders.
         * this logic only for single bundle case scenario. If the user add multiple bundle case in a single invoice, We need to modify this logic.
         *
         * this is not a good approach. When we have a time, we need to refactor this logic.
         */
        location.state = {
          ordersScanned: [],
          bundleId: isBundle ? bundleId : null,
          bundledOrders: isBundle ? bundledOrders : [],
        };
      }

      // We need to keep track of the orders that have been scanned so that when user switch to another page and come back, we can still show the orders that have been scanned.
      // It's mainly needed for edit case scenario at the moment.
      location.state.ordersScanned = [...(location?.state?.ordersScanned || []), order];

      newCase(order);
      setShowBundleButton(true);
      isSuccessful = true;
    } catch (error) {
      if (error instanceof Error) {
        let errorMessage = error.message;
        if (error.name === 'NotFoundError') {
          errorMessage = 'Case does not exist. Please try another case number.';
        }
        setErrorMessage(errorMessage);
      }
    }
    return isSuccessful;
  };

  const valueChangeHandler = () => {
    setErrorMessage('');
  };

  return showBundleButton ? (
    <>
      <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={onAddCaseClick}
        data-testid="addOrder"
      >
        <PlusIcon className="h-6 w-6 mr-1 text-gray-500" />
        Add Case to Bundle
      </button>
      <InputError message={errorMessage} />
    </>
  ) : (
    <OrderSearch
      id="caseSearch"
      errorMessage={errorMessage}
      showProgress={loading}
      onOrderSearch={onCaseSearchHandle}
      label="Add Case To Bundle"
      onValueChange={valueChangeHandler}
    />
  );
};

export default AddBundleCase;
