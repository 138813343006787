import { ProductFull, ProductFullAttribute } from 'API';
import { getProductFull } from 'shared/api/product.api';
import { getError } from 'shared/helpers/error-helper';
import { CacheResponse } from 'types/generic';
import { StateCreator } from 'zustand';

interface State {
  productFullCache: Record<string, CacheResponse<ProductFull | null>>;
}

/**
 * Actions interface represents a collection of functions that can be used to interact with the productFullCache slice.
 */
interface Actions {
  /**
   * Fetches the productFullCache data for a given product code.
   * @param productCode - The code of the product.
   * @returns A promise that resolves to the productFullCache data for the given product code.
   */
  fetchProductFull: (productCode: string) => Promise<State['productFullCache'][string]>;

  /**
   * Retrieves the productFullCache data for a given product code.
   * @param productCode - The code of the product.
   * @returns The productFullCache data for the given product code, or undefined if it doesn't exist.
   */
  getProductFullCacheData: (productCode: string) => State['productFullCache'][string] | undefined;

  /**
   * Finds a productFullAttribute in the productFullCache data for a given product code and options.
   * @param productCode - The code of the product.
   * @param options - Partial options to filter the productFullAttributes.
   * @returns The first productFullAttribute that matches the given options, or undefined if no match is found.
   */
  findProductFullAttributeByOption: (
    productCode: string,
    options: Partial<ProductFullAttribute>
  ) => ProductFullAttribute | undefined;

  /**
   * Filters the productFullAttributes in the productFullCache data for a given product code and options.
   * @param productCode - The code of the product.
   * @param options - Partial options to filter the productFullAttributes.
   * @returns An array of productFullAttributes that match the given options.
   */
  filterProductFullAttributesByOption: (
    productCode: string,
    options: Partial<ProductFullAttribute>
  ) => ProductFullAttribute[];
}

export type ProductFullCacheSlice = State & Actions;

export const initialState: State = {
  productFullCache: {},
};

export const createProductFullCacheSlice: StateCreator<ProductFullCacheSlice> = (set, get) => ({
  ...initialState,
  fetchProductFull: async productCode => {
    const { productFullCache } = get();
    const cacheData = productFullCache[productCode];

    if (cacheData && !cacheData.isLoading && !cacheData.error && cacheData.data) return productFullCache[productCode];

    const newCache = { ...productFullCache };

    try {
      newCache[productCode] = { isLoading: true, data: null, error: null };
      set({ productFullCache: newCache });

      // Fetch product full data
      const result = await getProductFull(productCode);

      if (!result) {
        throw new Error(`Product not found with code: ${productCode}`);
      }

      newCache[result.productCode] = { isLoading: false, data: result, error: null };

      // Update the cache
      set({ productFullCache: newCache });
    } catch (err) {
      const error = err as Error;
      console.error(error);
      newCache[productCode] = { isLoading: false, data: null, error: getError(error.message, error.name) };
      set({ productFullCache: newCache });
    }
    return newCache[productCode];
  },
  getProductFullCacheData: productCode => get().productFullCache[productCode],
  findProductFullAttributeByOption: (productCode, options) => {
    const productFull = get().productFullCache[productCode]?.data;
    if (!productFull) return;

    return productFull.attributes.find(attribute =>
      Object.entries(options).every(([key, value]) => attribute[key as keyof ProductFullAttribute] === value)
    );
  },
  filterProductFullAttributesByOption: (productCode, options) => {
    const productFull = get().productFullCache[productCode]?.data;
    if (!productFull) return [];

    return productFull.attributes.filter(attribute =>
      Object.entries(options).every(([key, value]) => attribute[key as keyof ProductFullAttribute] === value)
    );
  },
});
