import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Fragment, useState } from 'react';

/**
 * Props for the Modal component.
 */
export interface ModalProps {
  /**
   * Callback function to close the modal.
   */
  onClose: () => void;
  /**
   * Width of the modal.
   */
  width?: string;
  /**
   * Flag indicating whether the modal can be closed by clicking outside of it.
   */
  outSideClickClose?: boolean;
  /**
   * Flag indicating whether to show the close button in the modal.
   */
  showCloseButton?: boolean;
  /**
   * Opacity of the overlay.
   */
  overlayOpacity?: string;
}

/**
 * Component for a customizable modal.
 * @param onClose - Callback function to close the modal.
 * @param width - Width of the modal.
 * @param children - Content of the modal.
 * @param outSideClickClose - Flag indicating whether the modal can be closed by clicking outside of it.
 * @param showCloseButton - Flag indicating whether to show the close button in the modal.
 * @param overlayOpacity - Opacity of the overlay.
 * @returns JSX.Element representing the Modal component.
 */
const Modal: React.FC<ModalProps> = ({
  onClose,
  width,
  children,
  outSideClickClose = true,
  showCloseButton = true,
  overlayOpacity = 'bg-opacity-75',
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = (forceClose: boolean) => {
    if (forceClose || outSideClickClose) {
      setOpen(false);
      onClose();
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={() => handleClose(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className={classNames(overlayOpacity, 'fixed inset-0 bg-gray-500 transition-opacity')} />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`${
                width ?? ''
              } inline-block align-bottom bg-white rounded-lg text-left overflow-visible shadow-xl transform transition-all sm:align-middle`}
            >
              {showCloseButton && (
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => handleClose(true)}
                    data-qa="modalClose"
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}

              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
