import React from 'react';

/**
 * Component for rendering content that floats at the top of the page.
 *
 * @param children - The content to be rendered inside the component.
 * @returns JSX.Element - The rendered component.
 */
export const FloatingTopContent: React.FC = ({ children }) => {
  return <div className="absolute top-24 mt-4 left-0 right-0 flex justify-center">{children}</div>;
};
