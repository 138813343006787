import { SearchedOrder } from 'API';
import { Link } from 'react-router-dom';
import { DISABLED_LINK_CLASSNAME } from 'shared/constants/constants';
import { caseInLabStatuses } from 'shared/constants/invoice.constants';
import { AttributeName } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { formatToothString } from 'shared/helpers/order-detail/order-detail.helper';
import { AnalyticsService } from 'shared/services/analytics.service';
import { cn, getCaseStatusRecord, getDateInShortFormat, getPatientFullName } from 'shared/utils';

/**
 * Props for the RelatedCaseView component.
 */
interface RelatedCaseViewProps {
  /**
   * The order details of the related case.
   */
  order: SearchedOrder;
  /**
   * Function to select the related case.
   * @param orderNumber - The order number of the selected case.
   */
  selectRelatedCase: (orderNumber: string) => void;
  /**
   * Boolean indicating whether the view button should be disabled.
   */
  disableViewButton: boolean;
}

/**
 * The RelatedCaseView component displays information about a related case.
 * @param order - The order details of the related case.
 * @param selectRelatedCase - Function to select the related case.
 * @param disableViewButton - Boolean indicating whether the view button should be disabled.
 * @returns JSX element representing the RelatedCaseView component.
 */
export const RelatedCaseView: React.FC<RelatedCaseViewProps> = ({
  order,
  selectRelatedCase,
  disableViewButton = false,
}) => {
  // Formats the invoice date, preferably using utcConversionTimeZoneCode.
  const invoiceDate = order.invoiceDate ? getDateInShortFormat(order.invoiceDate, order.utcConversionTimeZoneCode) : '';
  const isCaseInLabStatus = caseInLabStatuses.includes(order.status);
  const isViewBtnDisabled = isCaseInLabStatus || !!disableViewButton;

  const onViewClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (isViewBtnDisabled) return;
    e.preventDefault();
    selectRelatedCase(order.orderNumber);
    AnalyticsService.track(AnalyticsEventName.CaseEntryRelatedCaseViewed);
  };

  return (
    <>
      <div className="border-b-2 mx-6 py-4 text-gray-800">
        <div>
          <div className="flex justify-between">
            <div className="font-semibold">{getPatientFullName(order.patientFirstName, order.patientLastName)}</div>
            <div className="">
              <Link
                to=""
                onClick={onViewClick}
                className={cn('text-indigo-600 hover:text-indigo-800 text-sm font-medium flex', {
                  [DISABLED_LINK_CLASSNAME]: isViewBtnDisabled,
                })}
              >
                View
              </Link>
            </div>
          </div>

          <div className="text-xs">Case #{order.orderNumber}</div>
          <div className="text-xs text-gray-500">
            {invoiceDate ? <>Invoiced {invoiceDate}</> : <>{getCaseStatusRecord(order.status).displayName}</>}
          </div>
          <div className="text-sm">
            {order.orderItems && (
              <ul className="m-2 mb-0 px-4 list-disc">
                {order.orderItems.map(({ attributes, productCode, productName }, index) => {
                  /**
                   * Attempts to find a tooth string and body shade for the each order item and builds a string with them.
                   * It's possible one or both attributes won't exist.
                   * As per a conversation with Di, the tooth string should always come before the body shade.
                   */
                  const toothString = attributes.find(a => a.name === AttributeName.ToothString)?.value;
                  // Returns the tooth string in a more user-friendly format (i.e. #30-32, instead of #30,#31,#32).
                  const formattedToothString = toothString && formatToothString(toothString);
                  const bodyShade = attributes.find(a => a.name === AttributeName.BodyShade)?.value;
                  const productAttributeString = [formattedToothString, bodyShade].filter(Boolean).join(', ');

                  /**
                   * As per Di and the epic AC: https://glidewell.atlassian.net/browse/LMS1-5938
                   * The product code should be used for products without a product name, such as for order 2002091876 in QA.
                   */
                  const productNameToUse = productName || productCode;
                  return (
                    <li key={`${productNameToUse}-${index}`} className="break-words">
                      {`${productNameToUse} ${productAttributeString}`}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
