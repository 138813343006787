import { Order, OrderNotificationType } from 'API';
import { useToast } from 'providers/ToastProvider';
import { useEffect, useRef } from 'react';
import { ToastNotificationType } from 'shared/enums';
import { checkPriceOrTaxBeingCalculated } from 'shared/helpers/util.helper';
import { useOrderNotificationAvailable } from 'shared/hooks/useOrderNotificationAvailable';
import { AlertMessage } from '../Alert/AlertMessage';

/**
 * Props for the OrderProcessingNotification component.
 */
interface OrderProcessingNotificationProps {
  /** Metadata of the order. */
  orderMetaData: Order['metadata'];
  /** Order number. */
  orderNumber: string;
  /** Callback function to refresh the order. */
  onRefresh?: () => void;
}

/**
 * Component to display processing notification for an order.
 *
 * @param orderMetaData - Metadata of the order.
 * @param orderNumber - Order number.
 * @param onRefresh - Callback function to refresh the order.
 * @returns JSX.Element - The rendered component.
 */
export const OrderProcessingNotification: React.FC<OrderProcessingNotificationProps> = ({
  orderMetaData,
  orderNumber,
  onRefresh,
}) => {
  const isOrderProcessing = checkPriceOrTaxBeingCalculated(orderMetaData);
  const { orderNotificationEvent, resetNotificationEvent } = useOrderNotificationAvailable(orderNumber);

  const toast = useToast();
  const onRefreshRef = useRef(onRefresh);

  useEffect(() => {
    onRefreshRef.current = onRefresh;
  }, [onRefresh]);

  useEffect(() => {
    if (!orderNotificationEvent) return;

    if (orderNotificationEvent === OrderNotificationType.PricingCalculated) {
      toast.notify("Case's pricing calculation is completed", ToastNotificationType.Success, {
        id: 'price_calculated',
      });
    } else if (orderNotificationEvent === OrderNotificationType.TaxCalculated) {
      toast.notify("Case's tax calculation is completed", ToastNotificationType.Success, { id: 'tax_calculated' });
    }
    resetNotificationEvent();
    onRefreshRef.current?.();
  }, [orderNotificationEvent, toast, resetNotificationEvent]);

  if (!isOrderProcessing) return null;
  return (
    <AlertMessage
      message={`Case processing the ${
        orderMetaData?.isPricingBeingCalculated ? 'pricing' : 'tax'
      }, please wait for sometime...`}
      type={ToastNotificationType.Info}
      className="w-full"
    />
  );
};
