import { CaseHistoryItem } from 'API';
import CheckboxInput from 'components/common/CheckboxInput';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { AnalyticsService } from 'shared/services/analytics.service';
import { CheckedFilters, FilterCategory } from './CaseHistory';

/**
 * Represents the state of checkboxes for a filter item.
 */
interface FilterItemCheckboxes {
  [checkboxLabel: string]: boolean;
}

/**
 * Props FilterItem component.
 */
interface FilterItemI {
  /**
   * The label of the filter item.
   */
  label: string;
  /**
   * The category of the filter item.
   */
  category: FilterCategory;
  /**
   * The checkboxes associated with the filter item.
   */
  checkboxes: FilterItemCheckboxes;
  /**
   * A function to handle checkbox changes.
   *
   * @param id - The ID of the checkbox.
   * @param isChecked - The new checked state of the checkbox.
   * @param category - The category of the filter.
   */
  handleCheck: (id: string, isChecked: boolean, category: FilterCategory) => void;
}

/**
 * Represents a filter item component.
 * @param label - The label of the filter item.
 * @param category - The category of the filter item.
 * @param checkboxes - The checkboxes associated with the filter item.
 * @param handleCheck - A function to handle checkbox changes.
 * @returns A JSX element representing the FilterItem component.
 */
const FilterItem = (props: FilterItemI) => {
  const { checkboxes, handleCheck, label, category } = props;
  return (
    <div className="py-3 border-t-2 border-gray-300">
      <div className="text-gray-700 font-medium mb-1">{label}</div>
      {Object.keys(checkboxes).map((checkboxLabel, index) => {
        const isChecked = checkboxes[checkboxLabel] || false;
        const uniqueKey = 'caseHistoryFilterPanel' + checkboxLabel + index;
        return (
          <CheckboxInput
            id={uniqueKey}
            key={uniqueKey}
            checked={isChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleCheck(checkboxLabel, e.target.checked, category)
            }
            inLine
            checkBoxAtStart
            label={checkboxLabel}
          />
        );
      })}
      {Object.keys(checkboxes).length === 0 && 'N/A'}
    </div>
  );
};

/**
 * Represents the properties of the CaseHistoryFilterPanel component.
 */
interface CaseHistoryFilterPanelI {
  /**
   * An array of case history items.
   */
  caseHistoryItems: CaseHistoryItem[];
  /**
   * The checked filters.
   */
  checkedFilters: CheckedFilters;
  /**
   * A function to set the checked filters.
   *
   * @param checkedFilters - The new checked filters.
   */
  setCheckedFilters: (checkedFilters: CheckedFilters) => void;
}

/**
 * A component for filtering case history items.
 * @param caseHistoryItems - An array of case history items.
 * @param checkedFilters - The checked filters.
 * @param setCheckedFilters - A function to set the checked filters.
 * @returns A JSX element representing the CaseHistoryFilterPanel component.
 */
export const CaseHistoryFilterPanel = ({
  caseHistoryItems,
  checkedFilters,
  setCheckedFilters,
}: CaseHistoryFilterPanelI) => {
  const handleCheck = (checkboxLabel: string, isChecked: boolean, category: FilterCategory) => {
    AnalyticsService.track(AnalyticsEventName.CaseHistoryFilterApplied, {
      filterCategory: category,
      filterLabel: checkboxLabel,
      isChecked,
    });
    const newCheckedFilters = cloneDeep(checkedFilters);
    if (!isChecked) {
      newCheckedFilters[category] = newCheckedFilters[category]?.filter(filter => filter !== checkboxLabel);
    } else {
      newCheckedFilters[category]?.push(checkboxLabel);
    }
    setCheckedFilters(newCheckedFilters);
  };

  const [productFilters, setProductFilters] = useState<FilterItemCheckboxes>({});
  const [actionTypeFilters, setActionTypeFilters] = useState<FilterItemCheckboxes>({});
  const [departmentFilters, setDepartmentFilters] = useState<FilterItemCheckboxes>({});

  // Processes the case history response to extract unique product name, process step, and department values.
  // Uses the filter values to display checkable filter checkboxes to the user and to keep track of whether those checkboxes are checked or not.
  // Re-rendered whenever the case history response changes or if checkedFilters (the object used to filter case history data) is changed.
  useEffect(() => {
    const products: FilterItemCheckboxes = {};
    const actionTypes: FilterItemCheckboxes = {};
    const departments: FilterItemCheckboxes = {};
    caseHistoryItems.forEach(item => {
      const { actionType } = item;
      // Parses through each product to store a copy of its product name and potential tooth string in the products object for use with filtering.
      item.products?.forEach(product => {
        const { productName, toothString, department } = product;
        const productString = (productName || '') + (toothString ? ` ${toothString}` : '');
        if (!products[productString]) {
          products[productString] = checkedFilters.product?.includes(productString) || false;
        }
        if (department && !departments[department]) {
          departments[department] = checkedFilters.department?.includes(department) || false;
        }
      });
      if (actionType && !actionTypes[actionType]) {
        actionTypes[actionType] = checkedFilters.actionTypes?.includes(actionType) || false;
      }
    });

    setProductFilters(products);
    setActionTypeFilters(actionTypes);
    setDepartmentFilters(departments);
  }, [caseHistoryItems, checkedFilters.actionTypes, checkedFilters.department, checkedFilters.product]);

  const resetFilters = () => {
    AnalyticsService.track(AnalyticsEventName.CaseHistoryFiltersCleared);
    setCheckedFilters({
      [FilterCategory.Products]: [],
      [FilterCategory.ActionTypes]: [],
      [FilterCategory.Departments]: [],
    });
  };

  return (
    <div>
      <div className="flex justify-between align-middle py-2">
        <div className="text-gray-700 font-medium">Filter Results</div>
        <button
          className="inline-flex items-center text-sm leading-4 font-medium rounded-md text-indigo-600"
          onClick={resetFilters}
        >
          Clear All
        </button>
      </div>
      <FilterItem
        handleCheck={handleCheck}
        label="Products"
        category={FilterCategory.Products}
        checkboxes={productFilters}
      />
      <FilterItem
        handleCheck={handleCheck}
        label="Process Steps"
        category={FilterCategory.ActionTypes}
        checkboxes={actionTypeFilters}
      />
      <FilterItem
        handleCheck={handleCheck}
        label="Departments"
        category={FilterCategory.Departments}
        checkboxes={departmentFilters}
      />
    </div>
  );
};
