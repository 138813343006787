import { CheckBadgeIcon } from '@heroicons/react/24/solid';
import { Coupon } from 'API';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import Loader from 'components/common/Loader/Loader';
import { SkeletonBorder } from 'components/common/Skeleton';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { couponLookup } from 'shared/api/discount.api';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';

/**
 * Props for the CouponInput component.
 */
interface CouponInputProps {
  /** Indicates if the component is in a loading state. */
  isLoading?: boolean;
  /** The billing account ID. */
  billingAccountId: string;
  /** The provider ID. */
  providerId: string;
  /** Indicates if the coupon should be reset after being validated. */
  resetCouponAfterValid?: boolean;
  /** Callback function to update the coupon. */
  onUpdateCoupon: (coupon: Coupon | null) => void;
  /** Error message to display. */
  errorMessage?: string;
}

/**
 * Component for handling coupon input.
 *
 * @param isLoading - Indicates if the component is in a loading state.
 * @param billingAccountId - The billing account ID.
 * @param providerId - The provider ID.
 * @param resetCouponAfterValid - Indicates if the coupon should be reset after being validated.
 * @param onUpdateCoupon - Callback function to update the coupon.
 * @param errorMessage - Error message to display.
 * @returns JSX.Element - The rendered component.
 */
const CouponInputContent: React.FC<Omit<CouponInputProps, 'isLoading'>> = ({
  onUpdateCoupon,
  resetCouponAfterValid = true,
  errorMessage: initialErrorMessage,
  billingAccountId,
  providerId,
}) => {
  const { fetcher, loading } = useLazyQueryFetcher(couponLookup);
  const [errorMessage, setErrorMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isValidCoupon, setIsValidCoupon] = useState(false);

  const onUpdateCouponRef = useRef(onUpdateCoupon);
  const couponTypingRef = useRef(false);

  useEffect(() => {
    onUpdateCouponRef.current = onUpdateCoupon;
  }, [onUpdateCoupon]);

  useEffect(() => {
    setErrorMessage(initialErrorMessage ?? '');
  }, [initialErrorMessage]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase();
    setCouponCode(value);
    setErrorMessage('');
    setIsValidCoupon(false);
    onUpdateCoupon(null);
    couponTypingRef.current = true;
  };

  const handleBlur = () => {
    couponTypingRef.current = false;
  };

  const fetchCoupon = useCallback(
    async (couponCode: string) => {
      if (!billingAccountId || !providerId) return setErrorMessage('Please select a provider');
      if (!couponCode) return setErrorMessage('Please enter a coupon code');
      try {
        setErrorMessage('');
        const response = await fetcher({ couponCode });

        onUpdateCouponRef.current(response);
        setIsValidCoupon(true);
        if (resetCouponAfterValid) {
          setCouponCode('');
        }
      } catch (err) {
        const error = err as Error;
        if (error.name === 'NotFoundError') {
          setErrorMessage('Invalid coupon');
        } else {
          setErrorMessage('Error fetching coupon');
        }
        setIsValidCoupon(false);
      }
    },
    [billingAccountId, providerId, fetcher, resetCouponAfterValid]
  );

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchCoupon(couponCode);
  };

  useEffect(() => {
    if (couponTypingRef.current || !couponCode) return;
    fetchCoupon(couponCode);
  }, [fetchCoupon, couponCode]);

  return (
    <form onSubmit={handleSubmit} data-testid="couponForm">
      <Input
        id="couponCode"
        name="couponCode"
        type="text"
        value={couponCode}
        placeholder="Enter coupon code"
        onChange={handleChange}
        isInvalid={!!errorMessage}
        disabled={loading}
        onBlur={handleBlur}
        icon={
          isValidCoupon && couponCode ? (
            <CheckBadgeIcon className="h-5 text-green-500" />
          ) : (
            <Loader show={loading} className="text-white" spin />
          )
        }
        showIcon={loading || isValidCoupon}
        className={!errorMessage ? 'shadow-sm border-gray-200' : undefined}
      />
      <InputError message={errorMessage} testId="coupon" />
    </form>
  );
};

export const CouponInput: React.FC<CouponInputProps> = ({ isLoading, ...props }) => {
  if (isLoading) return <SkeletonBorder className="mb-2" />;
  return <CouponInputContent {...props} />;
};
