import { ProductFullAttribute } from 'API';
import { AttributeAggregateItem } from './AttributeAggregateItems/AttributeAggregateItem/AttributeAggregateItem';
import { AttributeAggregateNoItem } from './AttributeAggregateNoItem/AttributeAggregateNoItem';

interface AttributeAggregateProps {
  groupLabel: string;
  attributes: ProductFullAttribute[];
  selectedTeeth: string[];
  orderItemId: string;
}

export const AttributeAggregate: React.FC<AttributeAggregateProps> = ({
  attributes,
  groupLabel,
  selectedTeeth,
  orderItemId,
}) => {
  if (!selectedTeeth.length) return <AttributeAggregateNoItem key={groupLabel} label={groupLabel} isRequired={true} />;
  return (
    <div data-testid="attribute-aggregate" className="col-span-4 grid grid-cols-4 gap-x-6 gap-y-4">
      {selectedTeeth.map(selectedTooth => (
        <AttributeAggregateItem
          key={selectedTooth}
          groupLabel={groupLabel}
          attributes={attributes}
          selectedTooth={selectedTooth}
          orderItemId={orderItemId}
        />
      ))}
    </div>
  );
};
