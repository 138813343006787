import { usePrevious } from 'hooks/use-previous';
import _ from 'lodash';
import {
  OrderModuleActionsContext,
  OrderModuleContext,
  TechnicalPreferencesByProductCode,
  useOrderEntryContext,
} from 'providers/OrderModuleProvider';
import { ShortcutKeysContext } from 'providers/ShortcutKeysProvider';
import { ToastContext } from 'providers/ToastProvider';
import { useCallback, useContext, useEffect } from 'react';
import { getTechnicalPreferences } from 'shared/api/technical-preferences.api';
import { ShortCutKeys } from 'shared/constants/shortcut-keys.constants';
import { ToastNotificationType } from 'shared/enums';
import { getDefaultOrderItemInput } from 'shared/helpers/order-entry/order-entry.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import Product from '../Product/Product';
import TabStrip from '../TabStrip/TabStrip';

/**
 * Props for the Products component.
 */
interface ProductsProps {
  /**
   * Flag indicating whether classifications are loading.
   */
  isClassificationsLoading: boolean;
  /**
   * Flag indicating whether it's an order update.
   */
  isOrderUpdate?: boolean;
}

/**
 * Represents the list of products in the order.
 * @param isClassificationsLoading - Flag indicating whether classifications are loading.
 * @param isOrderUpdate - Flag indicating whether it's an order update. (optional)
 * @returns JSX element representing the Products component.
 */
const Products: React.FC<ProductsProps> = ({ isClassificationsLoading, isOrderUpdate }) => {
  const shortcutKeyCtx = useContext(ShortcutKeysContext);
  const { order } = useContext(OrderModuleContext);
  const { patchOrder, setTechnicalPreferencesByProductCode } = useContext(OrderModuleActionsContext);
  const { selectedOrderItemId, setSelectedOrderItemId } = useOrderEntryContext();
  const prevProviderId = usePrevious(order.providerId);
  const toast = useContext(ToastContext);

  const { fetcher: preferencesByAccountAndProviderFetcher, loading: isPreferencesLoading } =
    useLazyQueryFetcher(getTechnicalPreferences);

  /**
   * Retrieves technical preferences using the provided billing account and provider IDs.
   */
  const loadTechnicalPreferences = useCallback(async () => {
    if (order.billingAccountId && order.providerId && order.providerId !== prevProviderId) {
      try {
        const preferencesByAccountAndProvider = await preferencesByAccountAndProviderFetcher({
          input: {
            billingAccountId: order.billingAccountId,
            providerId: order.providerId,
          },
        });
        const technicalPreferencesByProductCode: TechnicalPreferencesByProductCode = {};
        preferencesByAccountAndProvider.preferences.forEach(preference => {
          const { productCode } = preference;
          // Initializes the array of preferences for this product code.
          if (!technicalPreferencesByProductCode[productCode]) {
            technicalPreferencesByProductCode[productCode] = [];
          }
          technicalPreferencesByProductCode[productCode].push(preference);
        });
        setTechnicalPreferencesByProductCode(technicalPreferencesByProductCode);
      } catch (err) {
        const error = err as Error;
        if (error.name !== 'NotFoundError') {
          // Resets technical preferences on error upon receiving an error condition.
          // That way, existing preferences don't carry over to a new billing account or provider selection.
          setTechnicalPreferencesByProductCode({});
          toast.notify('Error loading technical preferences.', ToastNotificationType.Error);
        }
      }
    }
  }, [
    order.billingAccountId,
    order.providerId,
    preferencesByAccountAndProviderFetcher,
    prevProviderId,
    setTechnicalPreferencesByProductCode,
    toast,
  ]);

  useEffect(() => {
    loadTechnicalPreferences();
  }, [loadTechnicalPreferences]);
  /**
   * Add product to order items, update order context, and set as selected product.
   */
  const addProductHandler = useCallback(() => {
    const newOrder = _.cloneDeep(order);
    const newItem = getDefaultOrderItemInput();
    newOrder.orderItems.push(newItem);

    patchOrder({
      orderItems: newOrder.orderItems,
    });

    setSelectedOrderItemId(newItem.id);
  }, [order, patchOrder, setSelectedOrderItemId]);

  useEffect(() => {
    if (shortcutKeyCtx.keyPressed === ShortCutKeys.AltP) {
      addProductHandler();
      shortcutKeyCtx.reset();
    }
  }, [addProductHandler, shortcutKeyCtx]);

  useEffect(() => {
    // If there are no order items, add one.
    if (!order.orderItems.length) {
      addProductHandler();
    }

    // If there is no selected product or no matching order item with selected product id, set to first in array.
    if (
      !!order.orderItems.length &&
      (!selectedOrderItemId || !order.orderItems.some(item => item.id === selectedOrderItemId))
    ) {
      setSelectedOrderItemId(order.orderItems[0].id);
    }
  }, [order.orderItems, order.orderItems.length, selectedOrderItemId, addProductHandler, setSelectedOrderItemId]);

  /**
   * Remove a product from the tab list
   * @param id - removing item id
   */
  const removeProductHandler = (id: string) => {
    const productCount = order.orderItems.length;
    const newOrder = _.cloneDeep(order);
    const foundOrderItemIndex = newOrder.orderItems.findIndex(item => item.id === id);
    const currSelectedIndex = newOrder.orderItems.findIndex(item => item.id === selectedOrderItemId);

    if (foundOrderItemIndex > -1) {
      // If there is only one item, set current one to default and update selected to new order item id.
      if (newOrder.orderItems.length === 1) {
        const newOrderItemDefault = getDefaultOrderItemInput();
        newOrder.orderItems[foundOrderItemIndex] = newOrderItemDefault;
      } else {
        newOrder.orderItems.splice(foundOrderItemIndex, 1);
        // set a new selection if the user is removing the product they're currently on
        // or to keep the selection on the same item when removing an element in the list
        if (
          (id === selectedOrderItemId && foundOrderItemIndex === productCount - 1) ||
          currSelectedIndex > foundOrderItemIndex
        ) {
          setSelectedOrderItemId(newOrder.orderItems[currSelectedIndex - 1].id);
        } else {
          setSelectedOrderItemId(newOrder.orderItems[currSelectedIndex].id);
        }
      }

      patchOrder({
        orderItems: newOrder.orderItems,
      });
    }
  };

  return (
    <div className="border-t bg-white flex flex-col flex-1 overflow-auto">
      <TabStrip
        selectedProductId={selectedOrderItemId}
        onTabSelect={(id: string) => {
          setSelectedOrderItemId(id);
        }}
        onTabAdd={addProductHandler}
        onTabRemove={removeProductHandler}
      />
      <div className="bg-gray-50 flex-1 overflow-auto pb-20">
        {order.orderItems.map(orderItem => {
          return (
            <Product
              key={orderItem.id}
              selectedProductId={selectedOrderItemId}
              orderItem={orderItem}
              billingAccountId={order.billingAccountId}
              onProductRemove={() => removeProductHandler(orderItem.id)}
              isOrderUpdate={isOrderUpdate}
              isLoadingProductInformation={isPreferencesLoading || isClassificationsLoading}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Products;
