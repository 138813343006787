import { ROUTES } from 'components/navigation/Constants';
import React from 'react';
import { Link } from 'react-router-dom';
/**
 * this page is rendered, whenever a user tries to access a route
 * which is not registered in router.tsx or doesn't exist
 *
 */
const NotFoundPage: React.FC = () => {
  return (
    <div className="h-full w-full flex items-center justify-around">
      <div className="text-center">
        <h2 className="text-2xl">Page Not Found</h2>
        <Link to={ROUTES.ROOT} className="text-blue-500 hover:underline">
          Back Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
