import { FC } from 'react';
import { CaseHistoryAdditionalDetailsObject } from 'shared/constants/case-detail.constants';
import { AdditionalDetailsPreference } from './AdditionalDetailsPreference';

/**
 * Props of the PreferenceOverrideDetails component
 */
export interface PreferenceOverrideDetailsProps {
  /**
   * The old data object containing additional details of the case history.
   */
  oldData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The new data object containing additional details of the case history.
   */
  newData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The ID of the component.
   */
  id: string;
}

/**
 * Component for displaying preference override details.
 * @param oldData - The old data object containing additional details of the case history.
 * @param newData - The new data object containing additional details of the case history.
 * @param id - The ID of the component.
 * @returns A JSX element representing the PreferenceOverrideDetails component.
 */
export const PreferenceOverrideDetails: FC<PreferenceOverrideDetailsProps> = ({ oldData, newData, id }) => {
  const items = [
    {
      name: 'Default',
      oldValue: oldData?.Default,
      newValue: newData?.Default,
    },
    {
      name: 'Shipping Preference',
      oldValue: oldData?.ShippingPreference,
      newValue: newData?.ShippingPreference,
    },
    {
      name: 'Technical Preference',
      oldValue: oldData?.TechnicalPreference,
      newValue: newData?.TechnicalPreference,
    },
  ];
  return (
    <div>
      {items.map(item => (
        <AdditionalDetailsPreference
          key={item.name}
          caseHistoryPreferenceObject={{
            oldData: item.oldValue,
            newData: item.newValue,
            name: item.name,
          }}
          id={id}
        />
      ))}
    </div>
  );
};
