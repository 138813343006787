import { MagnifyingGlassIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { AccountRole, CustomerSearchRecord, ProviderStatus } from 'API';
import Input from 'components/common/Input/Input';
import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { accountSearch } from 'shared/api/account.api';
import { SearchState } from 'shared/enums';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import AccountLookupResults from './AccountLookupResults';

/**
 * Props for the AccountLookup component.
 */
interface AccountLookupProps {
  /** Function to handle selection of a search record. */
  onSearchRecordSelect: (searchRecord: CustomerSearchRecord) => void;
}

/**
 * Form values for the AccountLookup component.
 */
interface FormValues {
  /** The account search term. */
  accountSearch: string;
}

/**
 * Component for searching accounts and displaying search results.
 * @param onSearchRecordSelect - Function to handle selection of a search record.
 * @returns JSX element representing the AccountLookup component.
 */
const AccountLookup: React.FC<AccountLookupProps> = ({ onSearchRecordSelect }) => {
  const [accountSearchResults, setAccountSearchResults] = useState<CustomerSearchRecord[]>([]);
  const [searchState, setSearchState] = useState<SearchState>(SearchState.Initial);
  const [lastSearchedTerm, setLastSearchedTerm] = useState<string>('');
  const searchTermElement = useRef<HTMLInputElement>(null);
  const { fetcher } = useLazyQueryFetcher(accountSearch);

  useEffect(() => {
    searchTermElement.current?.focus();
  }, []);

  const search = (values: FormValues) => {
    setAccountSearchResults([]);
    setSearchState(SearchState.InProgress);
    (async () => {
      try {
        const searchTerm = values.accountSearch;
        setLastSearchedTerm(searchTerm);
        const accountSearchResults = await fetcher({
          searchTerm,
          accountRole: [AccountRole.Owner, AccountRole.Associate],
          providerStatus: [ProviderStatus.Active],
        });
        setAccountSearchResults(accountSearchResults);
      } catch (e) {
        setSearchState(SearchState.Error);
        return;
      }
      setSearchState(SearchState.Completed);
    })();
  };

  const initialValues: FormValues = {
    accountSearch: '',
  };

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } = useFormik({
    initialValues,
    onSubmit: search,
  });

  const clearInput = () => {
    setFieldValue('accountSearch', '');
    setSearchState(SearchState.Initial);
    setAccountSearchResults([]);
  };

  return (
    <div className="flex gap-4 p-6">
      <div className="flex-grow w-full">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Account Lookup</h3>
        <div className="mt-2">
          <p className="text-sm text-gray-500 max-w-xl">Search by account #, name, phone #, or license #.</p>
        </div>
        <div className="mt-6 relative rounded-md md:w-80">
          <form onSubmit={handleSubmit}>
            <Input
              name="accountSearch"
              id="accountSearch"
              type="text"
              value={values.accountSearch}
              onChange={handleChange}
              onBlur={handleBlur}
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 pl-10 sm:text-sm border-gray-300 rounded-md shadow-sm"
              ref={searchTermElement}
              data-qa="searchTermInput"
              data-testid="searchTermInput"
            />
            <button
              type="submit"
              className="absolute inset-y-0 left-0 pl-3 flex items-center cursor-pointer"
              data-qa="accountMagnifyingGlassIcon"
            >
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </button>
            {values.accountSearch && (
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                data-qa="accountMagnifyingGlassIcon"
                onClick={clearInput}
              >
                <XCircleIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </button>
            )}
          </form>
        </div>
        <AccountLookupResults
          accountSearchResults={accountSearchResults}
          searchState={searchState}
          searchTerm={lastSearchedTerm}
          onSearchRecordSelect={searchRecord => onSearchRecordSelect(searchRecord)}
        />
      </div>
    </div>
  );
};

export default AccountLookup;
