import { Account } from 'API';
import React, { PropsWithChildren, useState } from 'react';

/**
 * Represents the account context shape.
 */
interface Context {
  /**
   * The account information.
   */
  account?: Account;
}

/**
 * Represents the actions context shape.
 */
interface ActionsContext {
  /**
   * Function to set the account information.
   * @param account - The account to set.
   */
  setAccount: (account?: Account) => void;
}

/**
 * Initial context for order module actions.
 */
export const OrderModuleActionsInitialContext: ActionsContext = {
  setAccount: (account?: Account) => {
    console.log('Account: ', account);
  },
};
/**
 * Context for managing the account information.
 */
export const OrderEntryAccountContext = React.createContext<Context>({});

/**
 * Context for managing order entry account actions.
 */
export const OrderEntryAccountActionsContext = React.createContext<ActionsContext>(OrderModuleActionsInitialContext);

/**
 * Provider component for managing order entry account.
 */
export const OrderEntryAccountProvider: React.FC<PropsWithChildren<Partial<Context>>> = ({ children, ...props }) => {
  const [account, setAccount] = useState<Account | undefined>(props.account);

  return (
    <>
      <OrderEntryAccountContext.Provider value={{ account }}>
        <OrderEntryAccountActionsContext.Provider value={{ setAccount }}>
          {children}
        </OrderEntryAccountActionsContext.Provider>
      </OrderEntryAccountContext.Provider>
    </>
  );
};
