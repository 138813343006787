import { Address, AddressType } from 'API';
import { cn, getSeparatedShippingAddress, getShippingAddress } from 'shared/utils';

/**
 * Props for the ShippingAddressText component.
 */
interface ShippingAddressTextProps {
  /**
   * The address object to be displayed.
   */
  address: Address | undefined | null;
  /**
   * Boolean indicating whether to separate the address into two lines.
   */
  separateIntoTwoLine?: boolean;
  /**
   * Additional class name for the component.
   */
  className?: string;
}

/**
 * A component to display shipping address text.
 * @param address - The address object to be displayed.
 * @param separateIntoTwoLine - Boolean indicating whether to separate the address into two lines.
 * @param className - Additional class name for the component.
 * @returns JSX.Element representing the ShippingAddressText component.
 */
export const ShippingAddressText: React.FC<ShippingAddressTextProps> = ({
  address,
  separateIntoTwoLine,
  className,
}) => {
  const separatedShippingAddress = getSeparatedShippingAddress(address);
  if (!separatedShippingAddress) return null;

  return (
    <div className={cn('flex items-end', className)}>
      <span data-qa="shippingAddressLabel" data-testid="shippingAddressLabel">
        {separateIntoTwoLine ? (
          <div>
            {separatedShippingAddress.line1}
            <br />
            {separatedShippingAddress.line2}
          </div>
        ) : (
          getShippingAddress(address)
        )}
      </span>
      <span
        className={cn('text-gray-500 ml-1 uppercase', {
          'text-orange-500': address?.type === AddressType.Alternate,
        })}
        data-qa="addressTypeLabel"
        data-testid="addressTypeLabel"
      >
        ({address?.type || 'SHIPPING'})
      </span>
    </div>
  );
};
