import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'components/Authentication/msalAuthConfig';
import React, { useCallback, useMemo, useReducer } from 'react';
import { toast } from 'react-hot-toast';
import { ErrorMessage } from 'shared/enums';
import { datadogRumSetUser } from 'shared/lib/datadog-rum';
import { AnalyticsService } from 'shared/services/analytics.service';
import { UserInfo } from 'types/common';

/**
 * Represents the authentication context.
 */
interface AuthContextType {
  user: UserInfo | null;
  account: AccountInfo | null;
  isLoggedIn: boolean;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  setUser: (user: UserInfo | null) => void;
  setAccount: (account: AccountInfo | null) => void;
  reset: () => void;
  setAuth: ({ user, account }: { user: UserInfo | null; account: AccountInfo | null }) => void;
}

/**
 * Initial state for the authentication context.
 */
const initialState: AuthContextType = {
  user: null,
  account: null,
  isLoggedIn: false,
  signIn: () => Promise.resolve(),
  signOut: () => Promise.resolve(),
  setUser: () => null,
  setAccount: () => null,
  reset: () => null,
  setAuth: () => null,
};

/**
 * Authentication context for managing user authentication state.
 */
const AuthContext = React.createContext<AuthContextType>(initialState);

/**
 * Reducer action types for the authentication context.
 */
type ReducerActionType =
  | {
      type: 'SET_USER';
      payload: AuthContextType['user'];
    }
  | {
      type: 'SET_ACCOUNT';
      payload: AuthContextType['account'];
    }
  | {
      type: 'RESET';
    };

/**
 * Reducer function for the authentication context.
 * @param state - Current state of the authentication context.
 * @param action - Action to be performed on the state.
 * @returns New state after performing the action.
 */
const reducer = (state: AuthContextType, action: ReducerActionType) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'SET_ACCOUNT':
      return { ...state, account: action.payload };
    case 'RESET':
      return { ...state, user: null, account: null };
    default:
      return state;
  }
};

/**
 * Provider component for managing authentication state and functionality.
 */
export const AuthProvider: React.FC = ({ children }) => {
  const { instance: msalInstance } = useMsal();

  const [state, dispatch] = useReducer(reducer, initialState);

  /**
   * Sets the authentication state.
   * @param user - User information.
   * @param account - Account information.
   */
  const setUser = useCallback((user: AuthContextType['user']) => {
    datadogRumSetUser(user);
    if (user) {
      AnalyticsService.identify(user);
    } else {
      AnalyticsService.reset();
    }
    dispatch({ type: 'SET_USER', payload: user });
  }, []);

  const setAccount = useCallback((account: AuthContextType['account']) => {
    dispatch({ type: 'SET_ACCOUNT', payload: account });
  }, []);

  const reset = useCallback(() => {
    datadogRumSetUser(null);
    AnalyticsService.reset();
    dispatch({ type: 'RESET' });
  }, []);

  const setAuth: AuthContextType['setAuth'] = useCallback(
    ({ user, account }) => {
      if (user) {
        user.roles = user.roles || [];
      }

      setUser(user);
      setAccount(account);
    },
    [setUser, setAccount]
  );

  /**
   * Initiates the sign-in process.
   */
  const signIn = useCallback(async () => {
    try {
      await msalInstance.loginRedirect({
        ...loginRequest,
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message || ErrorMessage.GENERAL_ERROR_MESSAGE);
      reset();
    }
  }, [reset, msalInstance]);

  /**
   * Signs the user out.
   */
  const signOut = useCallback(async () => {
    try {
      await msalInstance.logoutRedirect({
        account: state.account,
      });
    } catch (err) {
      const error = err as Error;
      toast.error(error.message || ErrorMessage.GENERAL_ERROR_MESSAGE);
    }
  }, [state, msalInstance]);

  const isLoggedIn = !!state.user;

  const value = useMemo(
    () => ({
      ...state,
      isLoggedIn,
      signIn,
      signOut,
      setUser,
      setAccount,
      reset,
      setAuth,
    }),
    [isLoggedIn, reset, setAccount, setUser, signIn, signOut, setAuth, state]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

/**
 * Hook for accessing authentication context.
 * @returns Authentication context.
 */
export const useAuth = () => React.useContext(AuthContext);
