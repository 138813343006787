import { FC, useMemo } from 'react';
import { convertDeductionToItems } from 'shared/helpers/order-detail/order-detail.helper';
import { CreatedOrder } from 'shared/models';

/**
 * Functional component responsible for rendering other discounts section.
 * It takes an array of invoice cases as props and displays any applied discounts.
 *
 * @param invoiceCases - Array<CreatedOrder> An array of invoice cases containing information about applied discounts.
 */
export const OtherDiscountSection: FC<{ invoiceCases: CreatedOrder[] }> = ({ invoiceCases }) => {
  // Memoized calculation of discount items from applied discounts in invoice cases
  const discountItems = useMemo(() => {
    return invoiceCases.flatMap(invoiceCase => {
      return convertDeductionToItems(invoiceCase.appliedDiscounts || []);
    });
  }, [invoiceCases]);

  // If there is no discount applied, return N/A.
  if (!discountItems.length) return <>{'N/A'}</>;

  return (
    <>
      {discountItems.map(item => {
        return (
          <div className="mb-2" key={item.discountId}>
            {item.description}: {item.amount} <br />
            <span className="text-gray-500 text-sm">({item.reason})</span>
          </div>
        );
      })}
    </>
  );
};
