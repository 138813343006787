import { graphqlOperation } from '@aws-amplify/api-graphql';
import { GraphQLSubscription } from '@aws-amplify/api';
import { API } from 'aws-amplify';
import {
  QueryWorkOrderArgs,
  SubscriptionWorkOrderAvailableArgs,
  WorkOrder,
  WorkOrderAvailableSubscription,
  WorkOrderQuery,
} from 'API';
import { workOrder } from 'graphql/queries';
import { workOrderSubscription } from 'graphql/subscriptions';
import Http from './http';

/**
 * Get an order by orderNumber.
 * @param input - order number.
 * @returns WorkOrderResult
 */
export const getWorkOrder = async (input: QueryWorkOrderArgs): Promise<WorkOrder> => {
  const response = await Http.handleGraphqlOperation<WorkOrderQuery>(workOrder, input);
  return Http.processGraphqlResponse(response.workOrder, 'WorkOrder');
};

/**
 * Listen for a work order ready to print.
 * @param input - order number.
 * @returns A subscription object, not subscribed to anything yet
 */
export const subscribeToWorkOrder = (input: SubscriptionWorkOrderAvailableArgs) => {
  return API.graphql<GraphQLSubscription<WorkOrderAvailableSubscription>>(
    graphqlOperation(workOrderSubscription, input)
  );
};
