/**
 * Props for the RequiredMessage component.
 */
interface RequiredMessageProps {
  /** The error message to be displayed. */
  errorMessage?: string;
  /** The name of the field that is required. */
  fieldName?: string;
}

/**
 * Component for rendering a message indicating a required field.
 *
 * @param errorMessage - The error message to be displayed.
 * @param fieldName - The name of the field that is required.
 * @returns JSX.Element - The rendered component.
 */
const RequiredMessage: React.FC<RequiredMessageProps> = ({ errorMessage, fieldName }) => {
  return (
    <>
      {(errorMessage || fieldName) && (
        <p data-testid="requiredMessage" className="mb-1 ml-px mt-1 text-xs text-red-600">
          {errorMessage ? errorMessage : `${fieldName} is required`}
        </p>
      )}
    </>
  );
};

export default RequiredMessage;
