import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FC, useMemo } from 'react';
import { cn } from 'shared/utils';
import Button from '../Button/Button';

/**
 * Props for the TablePagination component.
 */
interface TablePaginationProps {
  /**
   * Current page number.
   */
  page: number;
  /**
   * Function to set the current page number.
   */
  setPage: (page: number) => void;
  /**
   * Total number of items in the pagination.
   */
  totalCount: number;
  /**
   * Number of items per page.
   */
  pageSize?: number;
  /**
   * Determines if the pagination border should be rounded.
   */
  borderRounded?: boolean;
}

/**
 * Component for pagination in a table.
 * @param page - Current page number.
 * @param setPage - Function to set the current page number.
 * @param totalCount - Total number of items in the pagination.
 * @param pageSize - Number of items per page.
 * @param borderRounded - Determines if the pagination border should be rounded.
 * @returns JSX.Element representing the TablePagination component.
 */
export const TablePagination: FC<TablePaginationProps> = ({
  page,
  setPage,
  totalCount,
  pageSize = 10,
  borderRounded,
}) => {
  const totalPages = Math.ceil(totalCount / pageSize);
  const currentPageCount = Math.ceil(totalCount % pageSize);

  /**
   * Returns a small range of page numbers that includes the currently selected page.
   */
  const pageNumbers = useMemo(() => {
    // The max number of pages we can display at a given time.
    const maxNumberOfPagesToDisplay = 5;
    // We will display up to the maximum number of total pages to display at a time.
    const pagesToDisplayAtATime = totalPages > maxNumberOfPagesToDisplay ? maxNumberOfPagesToDisplay : totalPages;
    // The current page will appear in the middle of the range.
    const currentPagePosition = Math.ceil(pagesToDisplayAtATime / 2);
    // The base number is the number at which the range of page numbers will start.
    // This number must be at least 1.
    let baseNumber = (page - currentPagePosition >= 0 ? page - currentPagePosition : 0) + 1;
    // Prevents the base number from being higher than the total number of pages.
    if (baseNumber + pagesToDisplayAtATime > totalPages) {
      const baseNumberWhenCloseToLastPage = totalPages - pagesToDisplayAtATime;
      baseNumber = (baseNumberWhenCloseToLastPage >= 0 ? baseNumberWhenCloseToLastPage : 0) + 1;
    }
    // Returns an array of numbers starting at i + baseNumber and continuing for length pagesToDisplayAtATime.
    return Array.from({ length: pagesToDisplayAtATime }).map((_, i) => i + baseNumber);
  }, [page, totalPages]);

  const previousPage = page - 1;
  const nextPage = page + 1;

  const fromCount = (page - 1) * pageSize + 1;
  const isLastPage = page === totalPages;
  const toCount = isLastPage && currentPageCount !== 0 ? fromCount + currentPageCount - 1 : page * pageSize;

  // Determines whether the skip forward and skip backward buttons should be displayed.
  // If the current range of page numbers includes the min or max number, that respective option will be disabled.
  const isAbleToSkipBackward = useMemo(() => pageNumbers.includes(1), [pageNumbers]);
  const isAbleToSkipForward = useMemo(() => pageNumbers.includes(totalPages), [pageNumbers, totalPages]);
  return (
    <div
      className={cn('flex items-center justify-between py-3 border border-gray-300 px-4 md:px-6', {
        'rounded-b-xl': borderRounded,
      })}
    >
      <div className="hidden md:inline-flex">
        <p className="text-sm text-gray-700">
          Showing <span className="font-medium">{fromCount}</span> to <span className="font-medium">{toCount}</span> of{' '}
          <span className="font-medium">{totalCount}</span> results
        </p>
      </div>
      <div>
        <nav className="relative z-0 inline-flex rounded-none shadow-sm -space-x-px" aria-label="Pagination">
          <Button
            onClick={() => setPage(1)}
            disabled={isAbleToSkipBackward}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-none hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Next</span>
            <ChevronDoubleLeftIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
          <Button
            onClick={() => setPage(previousPage)}
            disabled={page === 1}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-none hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Previous</span>
            <ChevronLeftIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
          {pageNumbers.map(number => (
            <Button
              key={number}
              onClick={() => setPage(number)}
              className={classNames(
                'relative items-center px-4 py-2 text-sm font-medium bg-white border border-gray-300 focus:z-10 focus:outline-none rounded-none hidden md:inline-flex',
                {
                  'bg-indigo-500 text-white': page === number,
                  'hover:bg-gray-50 text-gray-700': page !== number,
                }
              )}
            >
              {number}
            </Button>
          ))}
          <Button
            onClick={() => setPage(nextPage)}
            disabled={isLastPage}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-none hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Next</span>
            <ChevronRightIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
          <Button
            onClick={() => setPage(totalPages)}
            disabled={isAbleToSkipForward}
            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-none hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <span className="sr-only">Next</span>
            <ChevronDoubleRightIcon className="w-5 h-5" aria-hidden="true" />
          </Button>
        </nav>
      </div>
    </div>
  );
};
