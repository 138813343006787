import classNames from 'classnames';
import React from 'react';
import RequiredIndicator from './RequiredIndicator';

/**
 * Props for the Label component.
 */
interface LabelProps {
  /**
   * The ID of the input element the label is for.
   */
  htmlFor?: string;
  /**
   * Flag indicating if the input is required.
   */
  required?: boolean;
  /**
   * Flag indicating if the label should be displayed inline.
   */
  inline?: boolean;
  /**
   * Additional class name for the label.
   */
  labelClassName?: string;
}

/**
 * Component for displaying a label for an input element.
 * @param htmlFor - The ID of the input element the label is for.
 * @param required - Flag indicating if the input is required.
 * @param inline - Flag indicating if the label should be displayed inline.
 * @param labelClassName - Additional class name for the label.
 * @returns JSX.Element representing the Label component.
 */
const Label: React.FC<LabelProps> = ({ htmlFor, required, inline, labelClassName, children }) => {
  return (
    <div className={classNames({ inline: inline, flex: !inline })}>
      <label
        htmlFor={htmlFor}
        className={classNames('text-sm font-medium text-gray-700', { 'block mb-1': !inline }, labelClassName)}
        data-testid={`${htmlFor || ''}Label`}
      >
        {children}
      </label>
      {required && <RequiredIndicator />}
    </div>
  );
};

export default Label;
