import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { ProductFullAttribute } from 'API';
import classNames from 'classnames';
import Dropdown from 'components/common/Dropdown/Dropdown';
import MultiSelectDropdown from 'components/common/MultiSelectDropdown/MultiSelectDropdown';
import { usePrevious } from 'hooks/use-previous';
import { find } from 'lodash';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AttributeName, AttributeType } from 'shared/enums';
import { DropdownModel, LocalOrderProductAttributeInput } from 'shared/models';
import { getSelectedToothRange } from 'shared/utils';

/**
 * Props for the Pontic component.
 */
interface PonticProps {
  /**
   * The ID of the item associated with the pontic.
   */
  itemId: string;
  /**
   * The list of attributes for the pontic.
   */
  attributes: ProductFullAttribute[];
  /**
   * The pontic information.
   */
  pontic: LocalOrderProductAttributeInput;
  /**
   * Callback function to update the selected pontic.
   */
  updateSelectedPontic: (pontic: string, ponticId: string, ponticValue?: string) => void;
  /**
   * Callback function to remove the pontic.
   */
  removePontic: (ponticId: string) => void;
  /**
   * List of pontics in the order.
   */
  ponticsInOrder: LocalOrderProductAttributeInput[];
}

/**
 * The Pontic component represents a pontic in the order.
 * @param itemId - The ID of the item associated with the pontic.
 * @param attributes - The list of attributes for the pontic.
 * @param pontic - The pontic information.
 * @param updateSelectedPontic - Callback function to update the selected pontic.
 * @param removePontic - Callback function to remove the pontic.
 * @param ponticsInOrder - List of pontics in the order.
 * @returns JSX element representing the Pontic component.
 */
const Pontic: React.FC<PonticProps> = ({
  itemId,
  attributes,
  pontic,
  updateSelectedPontic,
  removePontic,
  ponticsInOrder,
}) => {
  const { getOrderItemSelectedTeeth, patchOrder } = useContext(OrderModuleActionsContext);
  const { order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const [selectedTooth, setSelectedTooth] = useState<string[]>([]);

  const ponticTeethSelected = ponticsInOrder.find(p => p.id === pontic.id)?.value;
  const ponticDesignSelected = ponticsInOrder.find(p => p.id === pontic.id)?.name;
  const orderItem = order.orderItems.find(item => item.id === itemId);
  const allPontics = orderItem?.attributes.filter(item => item.type === AttributeType.PonticDesign);
  const isFirstItem = allPontics?.findIndex(data => data.id === pontic.id) === 0;
  const missingTooth = find(orderItem?.addOns, { name: AttributeType.MissingTooth });

  const selectedTeeth = useMemo(() => {
    const selectedTeethValues = getOrderItemSelectedTeeth(itemId);
    return getSelectedToothRange(orderItem?.addOns, selectedTeethValues, AttributeType.PonticDesign);
  }, [getOrderItemSelectedTeeth, itemId, orderItem?.addOns]);

  const prevSelectedTeeth = usePrevious(selectedTeeth);
  const prevMissingTooth = usePrevious(missingTooth);

  //reset all pontics and set the first default field
  const resetAllPontics = useCallback(() => {
    const orderItems = [...order.orderItems];
    const ponticAttributes = orderItem?.attributes.filter(data => data.type === AttributeType.PonticDesign);
    if (prevMissingTooth?.value !== missingTooth?.value) {
      ponticAttributes?.forEach(pontic => {
        const toothValue = pontic.value.replace(/#/g, '').split(',');
        const missingToothArray = missingTooth?.value.replace(/#/g, '').split(',');
        const hasMissingValue = toothValue.filter(tooth => missingToothArray?.includes(tooth))?.length;
        if (hasMissingValue) {
          pontic.value = '';
        }
      });
    } else {
      ponticAttributes?.forEach(pontic => (pontic.value = ''));
    }
    patchOrder({
      orderItems: orderItems,
    });
  }, [order, patchOrder, missingTooth, orderItem, prevMissingTooth]);

  useEffect(() => {
    if (prevSelectedTeeth && selectedTeeth.length !== prevSelectedTeeth.length) {
      resetAllPontics();
      setSelectedTooth([]);
    }
  }, [selectedTeeth, prevSelectedTeeth, resetAllPontics, pontic, isFirstItem]);
  /**
   * map existing pontic items
   */
  useEffect(() => {
    const orderItem = order.orderItems.find(i => i.id === itemId);
    if (orderItem) {
      const ponticItem = orderItem.attributes.find(i => i.id === pontic.id);
      if (ponticItem) {
        const ponticValues = ponticItem.value
          .split(',')
          .filter(item => item !== '')
          .map(item => item.replace('#', ''));
        if (ponticValues) {
          setSelectedTooth(ponticValues);
        }
      }
    }
  }, [order.orderItems, itemId, pontic.id]);

  const toothArrayToString = (arrayOfTeeth: string[]) => {
    return arrayOfTeeth
      .map(tooth => +tooth)
      .sort((a, b) => {
        return a - b;
      })
      .join(',');
  };

  /**
   * Retrieves all selected Pontic Design names from the order module
   */
  const getSelectedPonticDesignNames = useCallback((): string[] => {
    const orderItem = order.orderItems.find(i => i.id === itemId);
    return (orderItem?.attributes || []).filter(a => a.type === AttributeName.PonticDesign).map(a => a.name);
  }, [order.orderItems, itemId]);

  const transformPonticOptions = (): DropdownModel[] => {
    return attributes
      .filter(a => a.type === AttributeName.PonticDesign)
      .map(a => {
        return {
          primaryLabel: a.name,
          secondaryLabel: a.name === AttributeName.PonticModifyLap ? '(Lab Default)' : undefined,
          value: a.name,
        };
      });
  };
  const checkAllToothEntered = () => {
    return prevSelectedTeeth?.length === 0;
  };
  return (
    <div className="grid grid-cols-4 gap-x-6 gap-y-4">
      <span className="font-medium text-indigo-800">
        <MultiSelectDropdown
          dropdownOptions={selectedTeeth.map((tooth: string) => {
            return {
              label: `#${tooth}`,
              value: tooth,
            };
          })}
          values={selectedTooth.map(t => ({ value: t.toString(), label: '#' + t.toString() }))}
          onSelectedValueChange={selected => {
            if (toothArrayToString(selectedTooth) !== toothArrayToString(selected.map(select => select.value))) {
              updateSelectedPontic(
                isFirstItem && !pontic.name ? AttributeName.PonticModifyLap : pontic.name,
                pontic?.id ?? '',
                toothArrayToString(selected.map(select => select.value))
              );
              setSelectedTooth(selected.map(tooth => tooth.value));
            }
          }}
          dataQa="ponticToothNumberInput"
          onSubmitAlert={onSubmitAlertCount > 0}
          isDisabled={checkAllToothEntered()}
          isRequired={!!ponticDesignSelected}
        />
      </span>
      <div className="relative w-full">
        <Dropdown
          selected={{
            primaryLabel: pontic.name,
            value: pontic.name,
            secondaryLabel: pontic.name === AttributeName.PonticModifyLap ? '(Lab Default)' : undefined,
          }}
          setSelected={selected => {
            updateSelectedPontic(selected.value, pontic?.id ?? '', pontic.value ?? '');
          }}
          data={transformPonticOptions().filter(
            a => !getSelectedPonticDesignNames().includes(a.primaryLabel) || a.primaryLabel === pontic.name
          )}
          onSubmitAlert={onSubmitAlertCount > 0}
          isRequired={!!ponticTeethSelected}
        />
      </div>
      <div className={classNames('relative align-left w-6', 'top-2')}>
        <button
          className="relative w-8"
          onClick={() => removePontic(pontic?.id ?? '')}
          id="removeTrackingNumberButton"
          data-qa="removeTrackingNumberButton"
          data-testid="removeTrackingNumberButton"
        >
          <MinusCircleIcon className="text-gray-400 h-5 w-5 cursor-pointer" />
        </button>
      </div>
    </div>
  );
};

export default Pontic;
