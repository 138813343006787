import { EnclosedItemCategory, OrderType } from 'API';
import classNames from 'classnames';
import { CouponInput } from 'components/common/CouponInput/CouponInput';
import EnclosedItems from 'components/common/EnclosedItems/EnclosedItems';
import RequiredIndicator from 'components/common/RequiredIndicator';

import { DisplayDiscountItems } from 'components/SpecialDiscount/DisplayDiscountItems/DisplayDiscountItems';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { FC, Fragment, useContext, useMemo } from 'react';
import { getDiscountCouponItem } from 'shared/helpers/invoice/invoice.helper';
import { getOrderType } from 'shared/helpers/order-entry/order-entry.helper';
import { LocalOrderEnclosedItem } from 'shared/models';
import ExternalCase from '../ExternalCase/ExternalCase';
import Notes from '../Notes';
import TrackingNumbers from '../TrackingNumbers/TrackingNumbers';
import UploadCard from '../UploadCard/UploadCard';

/**
 * Properties for the `RenderPanelCard` component.
 */
interface RenderPanelCardProps {
  /** Title of the panel card. */
  title?: string;
  /** Indicates if the panel card is required. */
  isRequired?: boolean;
  /** Indicates if the panel card is the last child. */
  lastChild?: boolean;
}

/**
 * Component responsible for rendering a panel card with title and content.
 * @param title - Title of the panel card.
 * @param isRequired - Indicates if the panel card is required.
 * @param lastChild - Indicates if the panel card is the last child.
 * @returns JSX element representing the panel card.
 */
const RenderPanelCard: FC<RenderPanelCardProps> = ({ title, isRequired = false, lastChild = false, children }) => {
  return (
    <div
      className={classNames('flex-col border-b border-gray-200 pb-4 mb-5', { 'mb-0 pb-0 border-none': !!lastChild })}
      data-testid={title?.replace(' ', '')}
    >
      <div className="font-medium text-sm text-gray-700 mb-2">
        {title}
        {isRequired && <RequiredIndicator />}
      </div>
      {children}
    </div>
  );
};

/**
 * Component responsible for rendering the right panel of the order details page.
 * @returns JSX element representing the RightPanel component
 */
export const RightPanel: React.FC = () => {
  const { order, orderLoading, orderId, onSubmitAlertCount } = useContext(OrderModuleContext);
  const { patchOrder } = useContext(OrderModuleActionsContext);

  const onChangeEnclosedItem = (items: LocalOrderEnclosedItem[]) => {
    patchOrder({ enclosedItems: items });
  };

  const couponItems = useMemo(() => {
    if (!order.coupons || !order.coupons.length) {
      return [];
    }
    return order.coupons.map(coupon => getDiscountCouponItem(coupon, order.orderNumber || ''));
  }, [order.coupons, order.orderNumber]);

  //Return enclosed items and add error when submitted if item.itemCode or item.quantity is missing
  const enclosedItems: LocalOrderEnclosedItem[] = useMemo(() => {
    return order.enclosedItems.map(item => {
      let errors: LocalOrderEnclosedItem['errors'];
      if (onSubmitAlertCount > 0) {
        errors = {
          itemCode: !item.itemCode ? 'Please select an item code' : '',
          quantity: !item.quantity ? 'Please enter a quantity' : '',
        };
      }
      return {
        ...item,
        errors,
      };
    });
  }, [onSubmitAlertCount, order.enclosedItems]);

  return (
    <>
      <div className="w-96">
        <div className="flex flex-col bg-white h-full p-6">
          <RenderPanelCard
            title="External Case ID"
            isRequired={getOrderType(order.externalOrderNumber, order.orderSource) === OrderType.Digital}
          >
            <ExternalCase />
          </RenderPanelCard>
          <RenderPanelCard
            title="Tracking #"
            isRequired={!(order.externalOrderNumber && order.orderSource && order.inboundTrackingNumbers.length > 0)}
          >
            <TrackingNumbers />
          </RenderPanelCard>
          <RenderPanelCard>
            <EnclosedItems
              addButtonText="Add Enclosed Items"
              isDataLoading={orderLoading}
              category={EnclosedItemCategory.Inbound}
              enclosedItems={enclosedItems}
              onChangeItem={onChangeEnclosedItem}
              onRemoveItem={onChangeEnclosedItem}
            />
          </RenderPanelCard>
          <RenderPanelCard title="Files">
            <UploadCard
              fileAttachments={order.fileAttachments}
              orderId={orderId}
              onFileAttachmentsChange={files => {
                patchOrder({
                  fileAttachments: files,
                });
              }}
            />
          </RenderPanelCard>
          <RenderPanelCard title="Coupon">
            <Fragment>
              <CouponInput
                isLoading={orderLoading}
                billingAccountId={order.billingAccountId}
                providerId={order.providerId}
                onUpdateCoupon={coupon => {
                  if (!coupon) return;
                  patchOrder({
                    coupons: [coupon],
                  });
                }}
              />
              <DisplayDiscountItems
                items={couponItems}
                onDelete={index => {
                  patchOrder({
                    coupons: order.coupons?.filter((_, i) => i !== index) || [],
                  });
                }}
                className="mt-2"
              />
            </Fragment>
          </RenderPanelCard>
          <RenderPanelCard title="Notes" lastChild={true}>
            <Notes />
          </RenderPanelCard>
        </div>
      </div>
    </>
  );
};
