import Dropdown from 'components/common/Dropdown/Dropdown';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { useMemo } from 'react';
import { DropdownModel } from 'shared/models';
import { convertToCamelCase } from 'shared/utils';

/**
 * Props for the AttributeSelectDropdown component.
 */
interface AttributeSelectDropdownProps {
  /**
   * The currently selected value.
   */
  value: string;
  /**
   * Options for the dropdown.
   */
  options: DropdownModel[];
  /**
   * Callback function to handle value change.
   */
  onValueChange: (value: string) => void;
  /**
   * Label for the dropdown.
   */
  label: string;
  /**
   * Indicates whether the form has been submitted.
   */
  isSubmitted: boolean;
  /**
   * Indicates whether the dropdown is required.
   */
  isRequired?: boolean;
  /**
   * Indicates whether the dropdown is disabled.
   */
  isDisabled?: boolean;
  /**
   * Additional class name for styling.
   */
  className?: string;
  /**
   * Indicates whether the dropdown can be reset.
   */
  isAbleToReset?: boolean;
  /**
   * Callback function to reset the dropdown value.
   */
  onValueReset?: () => void;
  /**
   * Indicates whether to show label when disabled.
   */
  isShowLabelWhenDisabled?: boolean;
  /**
   * Default value for the dropdown.
   */
  defaultValue?: string;
}

/**
 * The AttributeSelectDropdown component displays a dropdown for selecting attributes.
 * If the number of options exceeds 5, it becomes a searchable dropdown.
 * @param value - The currently selected value.
 * @param label - Label for the dropdown.
 * @param options - Options for the dropdown.
 * @param onValueChange - Callback function to handle value change.
 * @param isSubmitted - Indicates whether the form has been submitted.
 * @param isRequired - Indicates whether the dropdown is required.
 * @param isDisabled - Indicates whether the dropdown is disabled.
 * @param className - Additional class name for styling.
 * @param isAbleToReset - Indicates whether the dropdown can be reset.
 * @param onValueReset - Callback function to reset the dropdown value.
 * @param isShowLabelWhenDisabled - Indicates whether to show label when disabled.
 * @param defaultValue - Default value for the dropdown.
 * @returns JSX element representing the AttributeSelectDropdown component.
 */
const AttributeSelectDropdown: React.FC<AttributeSelectDropdownProps> = ({
  value,
  label,
  isRequired,
  options,
  onValueChange,
  isSubmitted,
  isDisabled,
  className,
  isAbleToReset,
  onValueReset,
  isShowLabelWhenDisabled,
  defaultValue,
}) => {
  // TODO: Not sure if using a label for the QA tag works, considering attributes won't always have a label.
  const dataQa = label ? convertToCamelCase(...label.split(' '), 'attribute searchable', 'searchable') : '';
  // NOTE: UX logic: If more than 5 options, make it a searchable dropdown (combobox).

  const selectedValue = useMemo(() => {
    if (!value) return { primaryLabel: '', value: '' };

    const foundOption = options.find(option => option.value === value);
    const defaultLabel = defaultValue === value ? '(Default)' : '';
    const option: DropdownModel = {
      primaryLabel: foundOption?.primaryLabel ?? value,
      value,
      secondaryLabel: defaultLabel,
    };

    return option;
  }, [defaultValue, options, value]);

  return (
    <div>
      {options.length > 5 ? (
        <SearchableDropdown
          options={options.map(value => value.value)}
          selected={value}
          onSelectedChange={value => {
            onValueChange(value);
          }}
          dataQa={dataQa}
          label={label}
          disabled={isDisabled}
          onSubmitAlert={isSubmitted}
          isRequired={isRequired}
          placeholder="Select"
          className={className}
          isAbleToReset={isAbleToReset}
          onValueReset={onValueReset}
        />
      ) : (
        <Dropdown
          data={options}
          label={label}
          selected={selectedValue}
          setSelected={select => {
            onValueChange(select.value);
          }}
          isRequired={isRequired}
          onSubmitAlert={isSubmitted}
          disabled={isDisabled}
          className={className}
          isAbleToReset={isAbleToReset}
          onValueReset={onValueReset}
          isShowLabelWhenDisabled={isShowLabelWhenDisabled}
        />
      )}
    </div>
  );
};

export default AttributeSelectDropdown;
