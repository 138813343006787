import { OrderStatus } from 'API';
import { FC } from 'react';
import { cn, getCaseStatusRecord } from 'shared/utils';

/**
 * Props for the CaseStatusBadge component.
 */
interface CaseStatusBadgeProps {
  /** The status of the case. */
  status: OrderStatus;
  /** The data-testid attribute for testing. */
  testId?: string;
}

/**
 * Component for rendering a badge indicating the status of a case.
 *
 * @param status - The status of the case.
 * @param testId - The data-testid attribute for testing.
 * @returns JSX.Element | null - The rendered badge, or null if no display name is found for the status.
 */
export const CaseStatusBadge: FC<CaseStatusBadgeProps> = ({ status, testId }) => {
  const caseStatus = getCaseStatusRecord(status);
  if (!caseStatus.displayName) return null;
  return (
    <div className="pt-0.5" data-testid={testId}>
      <span className={cn('text-sm font-medium rounded-full px-3 py-1', caseStatus.textColor, caseStatus.bgColor)}>
        {caseStatus.displayName}
      </span>
    </div>
  );
};
