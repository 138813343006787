import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

/**
 * Function to generate the MSAL configuration object.
 * @param clientId - The client ID of the application registered in Azure AD.
 * @param tenantId - The tenant ID of the Azure AD tenant.
 * @returns The MSAL configuration object.
 */
export const getMsalConfig = ({ clientId, tenantId }: { clientId: string; tenantId: string }) => {
  const authority = `https://login.microsoftonline.com/${tenantId}`;

  const msalConfig: Configuration = {
    auth: {
      clientId: clientId,
      authority: authority,
      redirectUri: '/redirect',
      postLogoutRedirectUri: '/logout',
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        logLevel: LogLevel.Error, // Only show error logs in the console
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };

  return msalConfig;
};
/**
 * Request object for the login redirect flow.
 * Add here scopes for id token to be used at MS Identity Platform endpoints.
 */
export const loginRequest: RedirectRequest = {
  scopes: ['User.Read.All', 'profile'],
};
/**
 * Configuration object for MS Graph API.
 * Add here the endpoints for MS Graph API services you would like to use.
 */
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
} as const;
