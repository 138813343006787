import { FC } from 'react';
import { LabelValue } from './LabelValue';

/**
 * Props of the ProcessDisplay component
 */
export interface ProcessDisplayProps {
  /**
   * Boolean indicating whether the process is overridden.
   */
  isProcessOverride: boolean | null | undefined;
  /**
   * The process to be displayed.
   */
  process: string | null | undefined;
}

/**
 * Component for displaying a process with an optional indication of override.
 * @param isProcessOverride - Boolean indicating whether the process is overridden.
 * @param process - The process to be displayed.
 * @returns A JSX element representing the ProcessDisplay component.
 */
export const ProcessDisplay: FC<ProcessDisplayProps> = ({ isProcessOverride, process }) => {
  if (!process) return null;
  return (
    <div className="flex items-center">
      <LabelValue name="Process" value={process} />
      {isProcessOverride && <span className="text-orange-500">(override)</span>}
    </div>
  );
};
