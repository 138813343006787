import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { KeyCode } from 'shared/enums';
import { convertToCurrency } from 'shared/utils';
import Input from '../Input/Input';
import Label from '../Label';
import RequiredMessage from '../RequiredMessage/RequiredMessage';
import { ResetButton } from '../ResetButton';

interface NumberInputProps {
  errorMessage?: string;
  label?: string;
  id: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  onSubmitAlert?: boolean;
  rightSideText?: string;
  placeholder?: string;
  className?: string;
  isAbleToReset?: boolean;
  onValueReset?: () => void;
  infoMessageWhenEmpty?: string;
}

/**
 * An input field for entering and viewing currency values.
 * @param errorMessage - An error message to display.
 * @param id - An ID to use for this component.
 * @param label - A label to display above the field.
 * @param isDisabled - Whether this component should appear disabled.
 * @param isRequired - Whether this component is required.
 * @param onSubmitAlert - Whether this field should render an error on submit.
 * @param value - The current order value of the currency field.
 * @param onChange - Handler for modifying the value of the currency field.
 * @param onBlur - Handler for clicking out of the currency field.
 * @param placeholder - A placeholder to show.
 * @param productPreference - The product preference for this item.
 * @param rightSideText - Optional text to display.
 * @param className - A class name to use for this field.
 * @param isAbleToReset - Whether this field can be reset to its preferred value.
 * @param onValueReset - Handler for resetting this field to its preferred value.
 * @param infoMessageWhenEmpty - An informational message to display whenever the value is empty.
 * @returns an input field for entering and viewing currency values.
 */
const CurrencyInput: React.FC<NumberInputProps> = ({
  errorMessage,
  id,
  label,
  isDisabled,
  isRequired,
  onSubmitAlert,
  value,
  onChange,
  onBlur,
  placeholder,
  rightSideText,
  className,
  isAbleToReset,
  onValueReset,
  infoMessageWhenEmpty,
}) => {
  const [defaultValue, setDefaultValue] = useState<string>();
  const [isTouched, setIsTouched] = useState<boolean>(false);

  enum DisplayValueFormat {
    Currency = 'currency',
    Number = 'number',
  }

  // By default, shows currency format.
  const [displayValueFormat, setDisplayValueFormat] = useState(DisplayValueFormat.Currency);

  const hasValidationError = !infoMessageWhenEmpty && isRequired && (isTouched || onSubmitAlert) && value === '';

  useEffect(() => {
    setDefaultValue(prevState => {
      prevState && onChange('');
      return '';
    });
  }, [onChange]);

  const getDropdownStyles = () => {
    if (isDisabled) {
      return 'bg-gray-100 border-gray-300';
    } else if (infoMessageWhenEmpty && !value) {
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 bg-yellow-50';
    } else if (hasValidationError) {
      return 'focus:ring-red-500 focus:border-red-500 border-red-500 pr-2';
    } else if (defaultValue && defaultValue === value) {
      return 'focus:ring-teal-500 focus:border-teal-500 border-teal-500 bg-teal-100';
    } else if (defaultValue && defaultValue !== value) {
      return 'focus:ring-orange-500 focus:border-orange-500 border-orange-500 bg-orange-100';
    } else {
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
    }
  };

  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} />;

  return (
    <>
      {!!label && (
        <Label required={isRequired} data-testid={`currencyInputLabel_${id}`} htmlFor={id} labelClassName="flex">
          {label}
          {resetButton}
        </Label>
      )}
      {!label && resetButton}
      <div className={classNames('flex gap-2 relative rounded-md', className)}>
        {rightSideText && (
          <div className={`absolute h-full right-0 top-0 flex items-center text-gray-400 text-sm 'mr-2`}>
            <p>{rightSideText}</p>
          </div>
        )}
        <Input
          data-testid={`currencyInput_${id}`}
          type="text"
          id={id}
          value={
            displayValueFormat === DisplayValueFormat.Currency
              ? convertToCurrency(Number(value.replace(/[^0-9.]/g, '')))
              : value
          }
          isFullWidth={true}
          placeholder={placeholder}
          onChange={e => {
            const newValue = e.target.value;
            // Prevents the input field from accepting non-currency values.
            if (!newValue || (newValue !== '.' && /^[0-9]+(\.[0-9]{0,2})?$/.test(newValue))) {
              onChange(newValue);
            }
          }}
          onKeyDown={e => {
            if (e.key === KeyCode.Backspace && value === '0') {
              onChange('');
            }
          }}
          onBlur={e => {
            setIsTouched(true);
            // Shows currency format whenever blurred.
            setDisplayValueFormat(DisplayValueFormat.Currency);
            if (onBlur) {
              onBlur(e);
            }
          }}
          onFocus={() => {
            // Shows number format whenever focused.
            setDisplayValueFormat(DisplayValueFormat.Number);
          }}
          className={`w-full border outline-none focus:outline-none block sm:text-sm rounded-md shadow-sm ${getDropdownStyles()}`}
          data-qa={id}
          disabled={isDisabled}
        />
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          {hasValidationError && <ExclamationCircleIcon className="h-5 text-red-500" />}
        </div>
      </div>
      {hasValidationError && <RequiredMessage fieldName={errorMessage || label} />}
      {infoMessageWhenEmpty && !value && (
        <p data-testid="infoMessageWhenEmpty" className="mb-1 ml-px mt-1 text-xs text-yellow-600">
          {infoMessageWhenEmpty}
        </p>
      )}
    </>
  );
};

export default CurrencyInput;
