import { SkeletonBorder } from 'components/common/Skeleton';

/**
 * Props for the ListInfoItem component.
 */
interface ListInfoItemProps {
  /**
   * Specifies whether the component is in loading state.
   */
  isLoading?: boolean;
  /**
   * Label for the list item.
   */
  label: string;
  /**
   * Test ID for testing purposes.
   */
  testId: string;
}

/**
 * Component for displaying the item in a ListTypeBox.
 * @param props - The props for the ListInfoItem component.
 * @returns JSX element representing the list item.
 */
export const ListInfoItem: React.FC<ListInfoItemProps> = ({ isLoading, testId, label, children }) => {
  return (
    <div className="grid grid-cols-2 gap-2 text-sm">
      <label className="mr-4 leading-6 font-medium text-gray-500">{label}</label>
      <label className="text-gray-900 leading-6" data-qa={testId} data-testid={testId}>
        {isLoading ? <SkeletonBorder className="h-5 w-40" /> : children}
      </label>
    </div>
  );
};
