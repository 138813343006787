import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

/**
 * Props for the TogglePanel component.
 */
interface TogglePanelProps {
  /** Boolean flag indicating whether the panel is currently shown or hidden. */
  showPanel: boolean;
  /** Function to update the state of the panel (show/hide). */
  updatePanelState: (showRightPanel: boolean) => void;
}

/**
 * Component representing a toggle button for showing/hiding a panel.
 * @param showPanel - Boolean flag indicating whether the panel is currently shown or hidden.
 * @param updatePanelState - Function to update the state of the panel (show/hide).
 * @returns A toggle button component.
 */
export const TogglePanel: React.FC<TogglePanelProps> = ({ showPanel, updatePanelState }) => {
  const updateTogglePanelState = () => {
    updatePanelState(!showPanel);
  };

  return (
    <button
      type="button"
      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={updateTogglePanelState}
      data-qa={showPanel ? 'collapseSidePanelButton' : 'expandSidePanelButton'}
    >
      <span className="sr-only">Close panel</span>
      {showPanel ? (
        <ChevronDoubleRightIcon className="h-4 w-4 mx-auto" aria-hidden="true" />
      ) : (
        <ChevronDoubleLeftIcon className="h-4 w-4 mx-auto" aria-hidden="true" />
      )}
    </button>
  );
};
