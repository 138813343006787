import { OrderItem } from 'API';
import { ToastContext } from 'providers/ToastProvider';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ToastNotificationType } from 'shared/enums';
import { getCaseProductTitle } from 'shared/helpers/invoice/invoice.helper';
import { printWorkOrder } from 'shared/helpers/printing/print.helper';
import { getOrderDetailPath } from 'shared/helpers/route.helper';

/**
 * Props interface for the CaseSubmissionSummary component.
 */
interface CaseSubmissionSummaryProps {
  /**
   * The order ID associated with the case submission summary.
   */
  orderId: string;
  /**
   * An array of order items for the case submission summary.
   */
  orderItems: OrderItem[];
  /**
   * A boolean flag indicating whether reprinting the work order is disabled.
   */
  disableReprint: boolean;
}

/**
 * Component for displaying the submission summary of a case.
 * @param orderId - The order ID associated with the case submission summary.
 * @param orderItems - An array of order items for the case submission summary.
 * @param disableReprint - A boolean flag indicating whether reprinting the work order is disabled.
 * @returns JSX element representing the CaseSubmissionSummary component.
 */
const CaseSubmissionSummary: React.FC<CaseSubmissionSummaryProps> = ({ orderId, orderItems, disableReprint }) => {
  const toast = useContext(ToastContext);
  const reprintWorkOrderHandler = async () => {
    try {
      await printWorkOrder({ orderNumber: orderId });
    } catch (e) {
      const error = e as Error;
      console.error('Error printing work order.', e);
      toast.notify(error.message || `Error opening work order. Please try again later.`, ToastNotificationType.Error);
    }
  };

  return !orderId ? null : (
    <div className="flex flex-col bg-white p-6 shadow rounded-lg">
      <div className="text-gray-900 font-medium text-base mb-4">Last Case</div>
      <div className="text-sm text-gray-900 font-medium mb-1">
        Case #
        <Link
          to={getOrderDetailPath(orderId)}
          className="text-indigo-600 focus:text-indigo-900 ml-1"
          data-qa="orderIdLink"
        >
          {orderId}
        </Link>
      </div>

      <div className="text-gray-500 mb-6">
        {orderItems.map((item: OrderItem, index: number) => (
          <div key={index}>{getCaseProductTitle(item)}</div>
        ))}
      </div>

      <div className="flex gap-4">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-qa="reprintInvoiceButton"
          onClick={reprintWorkOrderHandler}
          disabled={disableReprint}
        >
          Reprint Work Order
        </button>
      </div>
    </div>
  );
};

export default CaseSubmissionSummary;
