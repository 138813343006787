import { ROUTES } from 'components/navigation/Constants';
import TaskButton from './TaskButton';

/**
 * Entry point for creating a new task
 *
 * @returns - A JSX element representing the StartTask component
 */
const StartTask: React.FC = () => {
  return (
    <div className="">
      <h2 className="text-xl font-bold text-gray-900">Start a task</h2>
      <div className="mt-2 p-6 border border-gray-300 rounded bg-white">
        <div className="gap-4 mb-4">
          <TaskButton id="orderEntry" caption="Case Entry" navigationPath={ROUTES.ORDER_ENTRY} />
        </div>
        <div className="gap-4 mb-2">
          <TaskButton id="invoicing" caption="Invoicing" navigationPath={ROUTES.INVOICE_ORDER_LOOKUP} />
        </div>
      </div>
    </div>
  );
};

export default StartTask;
