import { FC } from 'react';
import { MANAGER_WORK_ITEM_EVENTS, getCaseHistoryEventTypeDisplay } from 'shared/constants/case-detail.constants';
import { CaseHistoryEventType } from 'shared/enums/history-event-type';
import { ChangesForTargetField } from './ChangesForTargetField';
import { ProcessDisplay, ProcessDisplayProps } from './ProcessDisplay';

/**
 * Props of the ManagerWorkItemEvent component.
 */
export interface ManagerWorkItemEventProps extends ProcessDisplayProps {
  /**
   * The type of case history event.
   */
  eventType: CaseHistoryEventType;
  /**
   * The reason for the manager work item event.
   */
  reason: string | undefined | null;
  /**
   * The old manufacturing status.
   */
  OldManufacturingStatus: string | undefined;
  /**
   * The new manufacturing status.
   */
  NewManufacturingStatus: string | undefined;
}

/**
 * Component for displaying manager work item events.
 * @param eventType - The type of case history event.
 * @param reason - The reason for the manager work item event.
 * @param OldManufacturingStatus - The old manufacturing status.
 * @param NewManufacturingStatus - The new manufacturing status.
 * @returns A JSX element representing the ManagerWorkItemEvent component.
 */
export const ManagerWorkItemEvent: FC<ManagerWorkItemEventProps> = ({
  eventType,
  reason,
  isProcessOverride,
  process,
  OldManufacturingStatus,
  NewManufacturingStatus,
}) => {
  return (
    <>
      {MANAGER_WORK_ITEM_EVENTS.map(localEventType => {
        return (
          localEventType === eventType && (
            <div key={localEventType}>
              <div className="font-semibold mb-1">{getCaseHistoryEventTypeDisplay(localEventType)}:</div>
              <ProcessDisplay isProcessOverride={isProcessOverride} process={process} />
            </div>
          )
        );
      })}

      <div className="mt-1">
        <ChangesForTargetField
          oldFieldValue={OldManufacturingStatus}
          newFieldValue={NewManufacturingStatus}
          fieldName="Manufacturing Status"
        />
        {reason && (
          <div className="mt-1">
            <div className="font-semibold">Reason:</div>
            <div>{reason}</div>
          </div>
        )}
      </div>
    </>
  );
};
