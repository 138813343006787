import { GraphQLSubscription } from '@aws-amplify/api';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import {
  Account,
  DoctorLabelAvailableSubscription,
  DoctorPickupLabelQuery,
  DoctorPickupLabelQueryVariables,
  Invoice,
  InvoiceAccountQuery,
  InvoiceAccountQueryVariables,
  InvoiceAvailableSubscription,
  InvoiceInput,
  InvoiceMutation,
  OrderInvoiceQuery,
  QueryOrderInvoiceArgs,
  ShippingLabelAvailableSubscription,
  ShippingOptionsInput as ShippingOptionsQueryVariables,
  ShippingOptionsQuery,
  ShippingOptionsWrapper,
  ShippingPreferencesQuery,
  ShippingPreferencesQueryVariables,
  SubscriptionDoctorLabelAvailableArgs,
  SubscriptionInvoiceAvailableArgs,
  SubscriptionShippingLabelAvailableArgs,
} from 'API';
import { API } from 'aws-amplify';
import { invoice as invoiceQuery } from 'graphql/mutations';
import {
  doctorPickupLabel,
  getShippingOptions as getShippingOptionsQuery,
  invoiceAccount,
  orderInvoice,
  shippingPreferencesQuery,
} from 'graphql/queries';
import { doctorLabelSubscription, invoiceSubscription, shippingLabelSubscription } from 'graphql/subscriptions';
import Http from './http';

export type InvoiceAccount = Pick<
  Account,
  | `__typename`
  | `standing`
  | `status`
  | `providers`
  | `finance`
  | `emailAddresses`
  | `phoneNumbers`
  | `practiceName`
  | `currencyCode`
  | `billingAccountId`
  | `addresses`
>;
/**
 * Takes in and InvoiceInput object and sends the query to the server which returns a clean invoice
 * @param input - object for getting the invoice details
 * @returns Promise of the shipping options wrapper of graphql
 */
export const getShippingOptions = async (input: ShippingOptionsQueryVariables): Promise<ShippingOptionsWrapper> => {
  const response = await Http.handleGraphqlOperation<ShippingOptionsQuery>(getShippingOptionsQuery, { input });
  return Http.processGraphqlResponse(response.shippingOptions, 'ShippingOptionsWrapper');
};

/**
 * Invoices a given order
 * @param input - request body for invoking invoice
 *
 * @returns a response body representing the invoiced order
 */
export const invoice = async (input: InvoiceInput) => {
  const response = await Http.handleGraphqlOperation<InvoiceMutation>(invoiceQuery, { input });
  return Http.processGraphqlResponse(response.invoice, 'OrderWrapper');
};

/**
 * Get an invoice by order number.
 * @param input - order number.
 * @returns WorkOrderResult - represents either work order or an error object
 */
export const getInvoice = async (input: QueryOrderInvoiceArgs): Promise<Invoice> => {
  const response = await Http.handleGraphqlOperation<OrderInvoiceQuery>(orderInvoice, input);
  return Http.processGraphqlResponse(response.orderInvoice, 'Invoice');
};

/**
 * Get doctor pick up label html.
 * @param input - order number.
 * @returns DoctorPickupLabelResults - represents doctor pickup results
 */
export const getDoctorPickupLabel = async (input: DoctorPickupLabelQueryVariables) => {
  const response = await Http.handleGraphqlOperation<DoctorPickupLabelQuery>(doctorPickupLabel, input);
  return Http.processGraphqlResponse(response.doctorPickupLabel, 'DoctorPickupLabel');
};

/**
 * Listen for a work order ready to print.
 * @param input - order number, session id details
 * @returns A subscription object, not subscribed to anything yet
 */
export const subscribeToInvoice = (input: SubscriptionInvoiceAvailableArgs) => {
  return API.graphql<GraphQLSubscription<InvoiceAvailableSubscription>>(graphqlOperation(invoiceSubscription, input));
};

/**
 * Listen for a work order ready to print.
 * @param input - order number, session id details
 * @returns A subscription object, not subscribed to anything yet
 */
export const subscribeToShippingLabel = (input: SubscriptionShippingLabelAvailableArgs) => {
  return API.graphql<GraphQLSubscription<ShippingLabelAvailableSubscription>>(
    graphqlOperation(shippingLabelSubscription, input)
  );
};

/**
 * Returns an Account based on the accountId
 * @param input - billingAccountId details
 * @returns invoice account of interest
 */
export const invoiceAccountLookup = async (input: InvoiceAccountQueryVariables): Promise<InvoiceAccount> => {
  const response = await Http.handleGraphqlOperation<InvoiceAccountQuery>(invoiceAccount, input);
  return Http.processGraphqlResponse(response.account, 'Account');
};

/**
 * Returns shipping preferences for given billingAccountId
 * @param input - billing account id details
 * @returns Shipping preferences
 */
export const shippingPreferences = async (input: ShippingPreferencesQueryVariables) => {
  const response = await Http.handleGraphqlOperation<ShippingPreferencesQuery>(shippingPreferencesQuery, input);
  return Http.processGraphqlResponse(response.shippingPreferences, 'ShippingPreferences');
};

/**
 * listens to doctor pickup label
 * @param input - order number, session id details
 * @returns a subscription to doctor pickup label record
 */
export const subscribeToDoctorPickupLabel = (input: SubscriptionDoctorLabelAvailableArgs) => {
  return API.graphql<GraphQLSubscription<DoctorLabelAvailableSubscription>>(
    graphqlOperation(doctorLabelSubscription, input)
  );
};
