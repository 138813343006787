import classNames from 'classnames';
import { forwardRef } from 'react';
import Label from '../Label';

/**
 * Props for the RadioInput component.
 */
export interface RadioInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /** The label for the radio input. */
  label: string;
}

/**
 * Component for rendering a radio input.
 *
 * @param label - The label for the radio input.
 * @param id - The id for the radio input.
 * @param required - Indicates if the radio input is required.
 * @param className - Additional CSS classes for styling.
 * @param rest - Additional props for the radio input element.
 * @param ref - Ref forwarded to the radio input element.
 * @returns JSX.Element - The rendered component.
 */
export const RadioInput = forwardRef<HTMLInputElement, RadioInputProps>(
  ({ label, id, required, className, ...rest }, ref) => {
    const identifier = id || label;
    return (
      <div className="flex items-center gap-2">
        <input
          type="radio"
          id={identifier}
          className={classNames('focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300', className)}
          {...rest}
          ref={ref}
        />
        <Label htmlFor={identifier} required={required} inline={true}>
          {label}
        </Label>
      </div>
    );
  }
);
