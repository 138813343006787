import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { ROUTES } from 'components/navigation/Constants';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useNavigate } from 'react-router-dom';
import { print, PrintType } from 'shared/api/print.api';
import { DO_NOT_SHIP_PREFERENCE_ENUM } from 'shared/enums';

/**
 * Props for the DoNotShipElementModal component.
 */
interface DoNotShipElementModalProps {
  /** The message indicating the do not ship condition. */
  doNotShipMessage: string;
  /** Function to close the modal. */
  onCloseModal: () => void;
}

/**
 * Modal component for displaying the do not ship preference.
 * @param doNotShipMessage - The message indicating the do not ship condition.
 * @param onCloseModal - Function to close the modal.
 * @returns The rendered DoNotShipElementModal component.
 */
export const DoNotShipElementModal: React.FC<DoNotShipElementModalProps> = ({ doNotShipMessage, onCloseModal }) => {
  const { setInvoiceShippingInput, invoiceCases, account, invoiceShippingInput } = useInvoicingDetail();
  const navigate = useNavigate();

  /**
   * Handles stopping invoicing and printing the preference details.
   */
  const handleStopInvoicing = () => {
    setInvoiceShippingInput({ doNotShipPreference: DO_NOT_SHIP_PREFERENCE_ENUM.NONE });
    if (account?.billingAccountId) {
      let providerAndOrderNumberHtml = '';

      // iterate through each case and appends provider & ordernumber to the html
      invoiceCases.forEach(
        invoiceCase =>
          (providerAndOrderNumberHtml += `<div>Doctor/Provider Name:${invoiceCase.providerName}</div><div>Case ID: ${invoiceCase.orderNumber}</div>`)
      );

      const html = `
        <html>
        <body>
        <div style="padding-top: 20px; margin-left: 30px">
        <div>Account ID: ${account?.billingAccountId}</div>
        ${providerAndOrderNumberHtml}
        <div>Preference Status:  ${invoiceShippingInput.doNotShipPreference}</div>
        <div>Preference: ${doNotShipMessage}</div>
        <div/>
        </body>
        </html>
        `;

      // sent to printer
      print(PrintType.HTML, [{ printContent: html, displayContent: html }]);
      navigate({ pathname: ROUTES.INVOICE_ORDER_LOOKUP }, { replace: true });
    }
  };

  return (
    <DialogBox
      title="Do not ship preference found"
      onCancel={onCloseModal}
      cancelText="Invoice Anyway"
      confirmText="Stop Invoicing"
      onConfirm={handleStopInvoicing}
      icon={<ExclamationTriangleIcon className="h-5 w-5 text-red-600" />}
      outSideClickClose={false}
      showCloseButton={false}
    >
      <div className="text-sm text-gray-500 mt-1">
        This account has the following preference:
        <ul className="list-inside list-disc mt-1">
          <li className="ml-3" data-testid="doNotShipMessage">
            {doNotShipMessage}
          </li>
        </ul>
      </div>
    </DialogBox>
  );
};
