import { ShippingOption } from 'API';
import Label from 'components/common/Label';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useMemo } from 'react';
import { NonNullableRecord } from 'types/generic';
import { CarrierListsElement } from './CarrierListsElement/CarrierListsElement';
import { ShippingMethodElement } from './ShippingMethodElement/ShippingMethodElement';

/**
 * Props for the ContentLoaded component.
 */
interface ContentLoadedProps {
  /** The data grouped by carrier. */
  groupByCarrierData: Record<string, ShippingOption[]> | null;
}

/**
 * Component to render the carrier lists and shipping method elements.
 * @param groupByCarrierData - The data grouped by carrier.
 * @returns The CarrierElementData component.
 */
const CarrierElementData: React.FC<NonNullableRecord<ContentLoadedProps>> = ({ groupByCarrierData }) => {
  return (
    <>
      <CarrierListsElement groupByCarrierData={groupByCarrierData} />
      <ShippingMethodElement groupByCarrierData={groupByCarrierData} />
    </>
  );
};

/**
 * Component to render the content based on the grouped carrier data.
 * @param groupByCarrierData - The data grouped by carrier.
 * @returns The ContentLoaded component.
 */
const ContentLoaded: React.FC<ContentLoadedProps> = ({ groupByCarrierData }) => {
  if (!groupByCarrierData) return <div className="text-sm text-gray-500">No Carriers found.</div>;
  return <CarrierElementData groupByCarrierData={groupByCarrierData} />;
};

/**
 * Props for the CarrierElement component.
 */
interface CarrierElementProps {
  /** The shipping options. */
  shippingOptions: ShippingOption[];
}

/**
 * Component to render the carrier element.
 * @param carrierData - The data for the carrier element.
 * @returns The CarrierElement component.
 */
export const CarrierElement: React.FC<CarrierElementProps> = ({ shippingOptions }) => {
  const { invoiceShippingInput } = useInvoicingDetail();
  const packageType = invoiceShippingInput.packageType;

  const groupByCarrierData = useMemo(() => {
    if (!packageType || !shippingOptions.length) return null;

    // filter shipping options by package type
    const filteredShippingOptions = shippingOptions.filter(option => option.package === packageType);

    // group shipping options by carrier
    const result = filteredShippingOptions.reduce((acc, option) => {
      if (!acc[option.carrier]) {
        acc[option.carrier] = [];
      }
      acc[option.carrier].push(option);
      return acc;
    }, {} as Record<string, ShippingOption[]>);

    return result;
  }, [packageType, shippingOptions]);

  return (
    <div className="flex flex-col gap-2">
      <Label required htmlFor="carrier">
        Carrier
      </Label>
      <ContentLoaded groupByCarrierData={groupByCarrierData} />
    </div>
  );
};
