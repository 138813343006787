import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { FC } from 'react';

/**
 * Props for the SeparateCaseModal component.
 */
interface SeparateCaseModalProps {
  /**
   * Indicates whether the modal is shown.
   */
  showModal: boolean;
  /**
   * Callback function invoked when the modal is closed.
   */
  onClose: () => void;
  /**
   * Callback function invoked when the submit button is clicked.
   */
  onSubmit: () => void;
}

/**
 * The SeparateCaseModal component displays a modal to inform the user that a separate case will be created for a product.
 * @param showModal - Indicates whether the modal is shown.
 * @param onClose - Callback function invoked when the modal is closed.
 * @param onSubmit - Callback function invoked when the submit button is clicked.
 * @returns JSX element representing the SeparateCaseModal component.
 */
export const SeparateCaseModal: FC<SeparateCaseModalProps> = ({ showModal, onClose, onSubmit }) => {
  return (
    <>
      {showModal && (
        <DialogBox
          title="A separate case will be created for this product"
          icon={<ExclamationTriangleIcon className="h-5 w-5 text-red-600" />}
          onCancel={onClose}
          confirmText="Okay"
          confirmButtonVariant="primary"
          titleClassName="w-11/12"
          onConfirm={() => {
            onSubmit();
            onClose();
          }}
        >
          <p className="text-sm text-gray-500">
            An RMA case will be created and bundled with this case since return products and new products can't be part
            of the same case.
          </p>
        </DialogBox>
      )}
    </>
  );
};
