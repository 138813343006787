import { AlertMessage } from 'components/common/Alert/AlertMessage';
import { FloatingTopContent } from 'components/common/FloatingTopContent/FloatingTopContent';
import ModuleContainer from 'components/common/ModuleContainer';
import ModuleHeader from 'components/common/ModuleHeader';
import OrderSearch from 'components/common/OrderSearch/OrderSearch';
import { AlertModalContext } from 'providers/AlertModalProvider';

import { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { invoiceAccountLookup } from 'shared/api/invoice.api';
import { getOrder, getOrderInvoiceLookup } from 'shared/api/order.api';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ToastNotificationType } from 'shared/enums';
import { ACTIONS } from 'shared/enums/permission';
import {
  BUNDLE_CASE_NUMBER_KEY,
  isSplitBundle,
  validateOrderItemsSpecialOrderParts,
} from 'shared/helpers/invoice/invoice.helper';
import { validateCaseDetailForInvoicing } from 'shared/helpers/util.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { cn, isCreatedOrder, isLegacyOrderData, isPendingOrderData } from 'shared/utils';
import InvoiceCaseSummary from '../components/invoicing/InvoiceCaseSummary/InvoiceCaseSummary';

import { ArrowLeftIcon, CheckIcon } from '@heroicons/react/24/solid';
import { OrderSplitBundle } from 'API';
import { uniqBy } from 'lodash';
import { concatOrderNumber } from 'shared/helpers/order-entry/order-entry.helper';
import { getInvoiceCreatePath } from 'shared/helpers/route.helper';
import { useInvoicePrintingLabelSubscription } from 'shared/hooks/useInvoicePrintingLabelSubscription';
import { CreatedOrder } from 'shared/models';
import { InvoicedOrder } from 'shared/models/invoicing-shipping';

/**
 * Input props for {@link ShowInvoicedToast}
 */
interface ShowInvoicedToastProps {
  /**
   * id of bundle
   */
  bundleId?: string;

  /**
   * list of invoiced orders
   */
  invoicedOrders: InvoicedOrder[];
}

/**
 * renders a toast message indicating completion of an invoice
 */
const ShowInvoicedToast: FC<ShowInvoicedToastProps> = ({ bundleId, invoicedOrders }) => {
  const endText = `${invoicedOrders.length > 1 ? 'have' : 'has'} been invoiced successfully`;
  return (
    <FloatingTopContent>
      <div className="flex flex-col gap-2 w-full">
        {bundleId ? (
          <div className="flex justify-around">
            <AlertMessage
              message={`Bundle ${bundleId} (Case # ${concatOrderNumber(invoicedOrders)}) ${endText}`}
              type={ToastNotificationType.Success}
              showCloseIcon
              className="w-3/5"
            />
          </div>
        ) : (
          <div className="flex justify-around">
            <AlertMessage
              message={`Case # ${concatOrderNumber(invoicedOrders)} ${endText}`}
              type={ToastNotificationType.Success}
              showCloseIcon
            />
          </div>
        )}
      </div>
    </FloatingTopContent>
  );
};

const InvoiceOrderLookupPage: React.FC = () => {
  const [processing, setProcessing] = useState<boolean>(false);
  const [isInvoicingBundle, setInvoicingBundle] = useState<boolean>(false);
  const [splitBundles, setSplitBundles] = useState<OrderSplitBundle | undefined>();
  const [ordersScanned, setOrdersScanned] = useState<(CreatedOrder | { orderNumber: string })[]>([]);
  const [validationError, setValidationError] = useState<string>('');
  const [invoicingBundledOrder, setInvoicingBundledOrder] = useState<string>('');
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const navigate = useNavigate();
  const { fetcher: fetchInvoiceAccountLookup } = useLazyQueryFetcher(invoiceAccountLookup);
  const { fetcher: getOrderFetcher } = useLazyQueryFetcher(getOrder);
  const { fetcher: getOrderInvoiceLookupFetcher } = useLazyQueryFetcher(getOrderInvoiceLookup);

  const { invoicedOrders, bundleId } = useInvoicePrintingLabelSubscription();

  const invoiceCasePermission = useRoleBasedAccessControl(ACTIONS.INVOICE_CASE);
  const alert = useContext(AlertModalContext);

  const navigateToInvoicing = useCallback(
    (orderNumber: string, scannedOrders?: (CreatedOrder | { orderNumber: string })[]) => {
      const bundledOrders = splitBundles?.bundledOrderNumbers;
      const navState = { ordersScanned: scannedOrders || [], bundleId: splitBundles?.bundleId, bundledOrders };
      navigate(
        {
          pathname: getInvoiceCreatePath(orderNumber),
        },
        {
          state: navState,
        }
      );
    },
    [navigate, splitBundles?.bundleId, splitBundles?.bundledOrderNumbers]
  );

  /**
   * function to handle any submission of search query in order search field
   *
   * @param orderNumber - submitted order number
   */
  const orderSearchHandler = useCallback(
    async (orderNumber: string) => {
      let isSuccessful = false;
      try {
        if (!invoiceCasePermission.allowUpdate) {
          alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
          navigate(-1);
          return;
        }

        setProcessing(true);
        setValidationError('');
        if (orderNumber.trim().length === 0) {
          throw Error('Case Id is required.');
        }

        const order = await getOrderInvoiceLookupFetcher({ orderNumber });
        const error = new Error();
        error.name = 'ValidationError';

        if (!order) {
          error.message = 'Case does not exist. Please try another case number.';
          throw error;
        }

        if (!isCreatedOrder(order)) {
          error.message = 'Case cannot be invoiced.';
          throw error;
        }

        // Validates special order parts. If an error message is returned, prevents the case from being invoiced.
        const specialOrderPartsErrorMessage = validateOrderItemsSpecialOrderParts(order.orderItems);
        if (specialOrderPartsErrorMessage) {
          error.message = 'Unable to invoice this order: ' + specialOrderPartsErrorMessage;
          throw error;
        }
        const account = await fetchInvoiceAccountLookup({ billingAccountId: order.billingAccountId || '' });

        validateCaseDetailForInvoicing(order, account);

        if (order?.bundles && isSplitBundle(order.bundles, order.orderNumber) && !isInvoicingBundle) {
          setInvoicingBundle(true);
          if (order.bundles.splitBundle) {
            setSplitBundles(order.bundles.splitBundle);
          }
          setOrdersScanned([{ orderNumber }]);
          setInvoicingBundledOrder(orderNumber);
          setProcessing(false);
        } else if (isInvoicingBundle) {
          const invoicingCase = await getOrderFetcher({ orderNumber });

          if (!invoicingCase) throw new Error('Order data not found.');

          const isPendingOrLegacy = isLegacyOrderData(invoicingCase) || isPendingOrderData(invoicingCase);

          if (isPendingOrLegacy) {
            throw new Error(`Order is ${invoicingCase.__typename}. Unable to invoice.`);
          }

          const isBundle = splitBundles?.bundledOrderNumbers.find(odr => odr.orderNumber === orderNumber);
          if (isBundle) {
            const isAlreadyScanned = ordersScanned.findIndex(odr => odr.orderNumber === orderNumber) > -1;
            if (!isAlreadyScanned) {
              const newOrdersScannedList = [...ordersScanned, invoicingCase];
              setOrdersScanned(newOrdersScannedList);
              const isLast =
                splitBundles?.bundledOrderNumbers &&
                splitBundles?.bundledOrderNumbers?.length === newOrdersScannedList?.length;
              if (isLast) {
                const scannedOrders = newOrdersScannedList.filter(data => data.orderNumber !== invoicingBundledOrder);
                navigateToInvoicing(invoicingBundledOrder, scannedOrders);
              }
            }
          } else {
            throw new Error('Case is not part of this bundle');
          }
        } else {
          navigateToInvoicing(order.orderNumber);
        }
        isSuccessful = true;
      } catch (error) {
        if (error instanceof Error) {
          let errorMsg = 'Error loading case.';
          if (error.name === 'NotFoundError') {
            errorMsg = 'Case does not exist. Please try another case number.';
          } else {
            errorMsg = error.message;
          }
          setValidationError(errorMsg);
        }
      } finally {
        setProcessing(false);
      }
      return isSuccessful;
    },
    [
      alert,
      fetchInvoiceAccountLookup,
      invoiceCasePermission.allowUpdate,
      invoicingBundledOrder,
      isInvoicingBundle,
      navigate,
      navigateToInvoicing,
      ordersScanned,
      splitBundles?.bundledOrderNumbers,
      getOrderFetcher,
      getOrderInvoiceLookupFetcher,
    ]
  );

  /**
   * Handle scenario where a bundle case number is passed in as query param.
   * Submit the case to order search to start the bundle process.
   * This covers scenario where the user clicks invoice from case details.
   * Ensures we enforce all the cases getting scanned.
   */
  useEffect(() => {
    const caseNumber = searchParams.get(BUNDLE_CASE_NUMBER_KEY);
    if (caseNumber) {
      orderSearchHandler(caseNumber);
      // Navigate back to current route to clear query param.
      navigate('.');
    }
  }, [navigate, orderSearchHandler, searchParams]);

  /**
   * function to handle any changes in order search field
   */
  const valueChangeHandler = () => {
    setValidationError('');
  };

  /**
   * Determines whether scanned orders includes a certain order with order number provided
   * @param orderNumber - order number
   * @returns true if order is present, otherwise false
   */
  const checkOrderScanned = (orderNumber: string) => {
    return !!ordersScanned.find(item => item.orderNumber === orderNumber);
  };

  const InvoicingBundleHeader = () => (
    <>
      {isInvoicingBundle && (
        <div className="flex mb-5 w-full ml-5 items-center">
          <button onClick={() => clearBundleStates()}>
            <ArrowLeftIcon className="flex-none mr-8 h-8 w-8 text-indigo-600" aria-hidden="true" />
          </button>
          <div className=" font-medium">{`Invoicing Bundle ${splitBundles?.bundleId}`}</div>
        </div>
      )}
    </>
  );

  const getBundledOrdersArray = () => {
    if (splitBundles?.bundledOrderNumbers.length) {
      //considering the entered first case number wont be there on its own bundles.splitBundles.bundledOrderNumbers data
      return uniqBy(
        [{ orderNumber: invoicingBundledOrder }, ...splitBundles?.bundledOrderNumbers],
        item => item.orderNumber
      );
    } else {
      return [];
    }
  };

  const InvoicingBundleFooter = () => {
    const bundledOrdersArray = getBundledOrdersArray();
    return (
      <>
        {!!splitBundles?.bundledOrderNumbers.length && (
          <div className="px-20 mt-5">
            {bundledOrdersArray?.map(odr => (
              <div className="flex font-medium text-sm" key={odr.orderNumber}>
                Case# <div className="font-normal ml-1">{odr.orderNumber}</div>
                {checkOrderScanned(odr.orderNumber) && <CheckIcon className="h-5 w-5 text-green-500 ml-2" />}
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  /**
   * resets the bundle states
   */
  const clearBundleStates = () => {
    setInvoicingBundle(false);
    setSplitBundles(undefined);
    setOrdersScanned([]);
    setInvoicingBundledOrder('');
  };

  return (
    <ModuleContainer>
      <ModuleHeader title="Invoicing" />
      {!!invoicedOrders.length && <ShowInvoicedToast bundleId={bundleId} invoicedOrders={invoicedOrders} />}
      <div
        className={cn('rounded-2xl m-auto bg-white py-10', {
          'pt-5': isInvoicingBundle,
        })}
      >
        <InvoicingBundleHeader />
        <div className="px-20">
          <OrderSearch
            id="caseSearch"
            onOrderSearch={orderSearchHandler}
            showProgress={processing}
            label={isInvoicingBundle ? 'Scan Other Cases in Bundle' : 'Case #'}
            errorMessage={validationError}
            onValueChange={valueChangeHandler}
          />
        </div>
        <InvoicingBundleFooter />
      </div>
      {!!invoicedOrders.length && (
        <div className="fixed bottom-8 right-8 space-y-4">
          <InvoiceCaseSummary invoicedOrders={invoicedOrders} />
        </div>
      )}
    </ModuleContainer>
  );
};

export default InvoiceOrderLookupPage;
