import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import Images from 'assets/images';
import { useNavigate } from 'react-router-dom';

interface SignInLayoutProps {
  showBackButton?: boolean;
}

/**
 * Renders a layout with sign in flow
 *
 * @param children - child views to rendered within this component
 * @param showBackButton - whether to show a back button
 */
export const SignInLayout: React.FC<SignInLayoutProps> = ({ children, showBackButton }) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="h-full flex items-center bg-indigo-700">
      <div className="w-full">
        <div className="block m-auto max-w-lg mb-4 p-8 bg-white shadow rounded-2xl">
          <div className="flex items-center">
            {showBackButton && (
              <button type="button" onClick={goBack}>
                <ArrowLeftIcon className="flex-none -mr-0.5 h-6 w-6 text-indigo-700" aria-hidden="true" />
              </button>
            )}
            <img data-testid="logo" src={Images.LMSSignInLogo} alt="logo" className="mx-auto" />
          </div>
          <p data-testid="title" className="text-center text-3xl font-extrabold font-sans text-gray-900 mb-8 mt-6">
            Sign in to LMS
          </p>
          {children}
        </div>
        <div className="flex justify-center">
          <p className="text-center text-base text-white leading-6 font-medium">
            Need Help? Contact{' '}
            <a href="mailto:ITSupport@glidewelldental.com" className=" underline" target="_blank" rel="noreferrer">
              IT Support
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};
