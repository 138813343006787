import Images from 'assets/images';
import React, { useMemo, useState } from 'react';

/**
 * Props of the overlay loader context.
 */
export interface OverlayLoaderState {
  /**
   * Function to show the overlay loader with a message.
   * @param message - The message to display.
   */
  show: (message: string) => void;
  /**
   * Function to hide the overlay loader.
   */
  hide: () => void;
}

/**
 * Context for managing the overlay loader.
 */
export const OverlayLoaderContext = React.createContext<OverlayLoaderState>({
  show: () => {
    console.log();
  },
  hide: () => {
    console.log();
  },
});

/**
 * Provider component for managing the overlay loader state.
 */
export const OverlayLoaderProvider: React.FC = ({ children }) => {
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [loaderMessage, setLoaderMessage] = useState<string>('');

  const state = useMemo(
    () => ({
      show: (message: string) => {
        setShowLoader(true);
        setLoaderMessage(message);
      },
      hide: () => {
        setShowLoader(false);
        setLoaderMessage('');
      },
    }),
    []
  );

  return (
    <>
      {showLoader && (
        <div className="fixed w-screen h-screen top-0 left-0 right-0 bottom-0 bg-gray-50 z-20 bg-opacity-50">
          <div className="absolute top-1/2 left-1/2 text-center text-gray-600 text-sm">
            <div className="border bg-white rounded-lg p-4 shadow-sm">
              <img src={Images.Spinner} alt="loader" className="h-16 w-16" />
            </div>
            <div className="bg-white">{loaderMessage}</div>
          </div>
        </div>
      )}
      <OverlayLoaderContext.Provider value={state}>{children}</OverlayLoaderContext.Provider>
    </>
  );
};
