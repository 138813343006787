import {
  Account,
  AccountStanding,
  AccountStatus,
  AddressType,
  AttributeDisplayType,
  Brand,
  CurrencyCode,
  EmailType,
  EnclosedItemCategory,
  Order,
  OrderStatus,
  PackageType,
  PhoneType,
  PracticeType,
  Product,
  ProductAttributeValueType,
  ProductComponentType,
  ProductFullAttribute,
  ProductType,
  ProviderStatus,
  Salutation,
} from 'API';
import moment from 'moment';
import { getProductAttribute } from 'shared/helpers/order-entry/order-entry.helper';
import { LocalOrderEnclosedItem } from 'shared/models';

/**
 * generates a order instance in default settings
 */
export const getDefaultOrder = (): Order => {
  const data: Order = {
    __typename: 'Order',
    addresses: [],
    originFacility: '',
    billingAccountId: '30-0',
    originFacilityId: 30,
    providerId: '',
    providerName: '',
    coupons: [],
    createdDate: '',
    enclosedItems: [],
    fileAttachments: [],
    orderItems: [],
    shipping: {
      __typename: 'Shipping',
      address: {
        __typename: 'Address',
        street1: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
        type: AddressType.Shipping,
      },
      inboundTrackingNumbers: [],
      outboundTrackingNumbers: [],
      packageType: PackageType.Box,
      codAmount: 0,
    },
    orderNumber: '123456',
    status: OrderStatus.Forwarded,
    patientFirstName: '',
    patientLastName: '',
    patientId: '',
    utcConversionTimeZoneCode: moment.tz.guess(),
  };
  return data;
};

/**
 * default enclosed items
 */
export const enclosedItems: LocalOrderEnclosedItem[] = [
  {
    itemCode: 'ABC',
    createdDate: '2023-01-01',
    type: 'ABC',
    quantity: 1,
    createdBy: 'test',
    shippingType: EnclosedItemCategory.Inbound,
  },
  {
    itemCode: 'DEF',
    createdDate: '2023-01-01',
    type: 'DEF',
    quantity: 1,
    createdBy: 'test',
    shippingType: EnclosedItemCategory.Inbound,
  },
];

/**
 * generates a account mock data
 */
export const getAccountMockData = (): Account => {
  return {
    __typename: 'Account',
    type: 'GND - General Dentist',
    billingAccountId: '10-0',
    currencyCode: CurrencyCode.Usd,
    standing: AccountStanding.Good,
    status: AccountStatus.Active,
    addresses: [
      {
        __typename: 'Address',
        type: AddressType.Shipping,
        street1: '4141 MacArthur Blvd.',
        street2: null,
        street3: null,
        city: 'Newport Beach',
        state: 'CA',
        zipcode: '92660',
        country: 'USA',
        neighborhood: null,
        division: null,
      },
    ],
    phoneNumbers: [
      {
        __typename: 'Phone',
        type: PhoneType.Office,
        number: '(800)854-7256',
        extension: '222',
        country: 'USA',
      },
    ],
    emailAddresses: [
      {
        __typename: 'Email',
        type: EmailType.Office,
        emailAddress: 'ask@glidewelldental.com',
      },
    ],
    labOrigin: 'CROWN WORLD IRVINE 3',
    labOriginId: 101,
    practiceId: 129831101,
    practiceName: 'Ayden Hilll Dentistry - the best',
    practiceType: PracticeType.Lab,
    providers: [
      {
        __typename: 'AccountProvider',

        providerId: 2117,
        firstName: 'Owner',
        lastName: '2',
        middleName: null,
        suffix: null,
        salutation: Salutation.Dr,
        providerStatus: ProviderStatus.Retired,
      },
      {
        __typename: 'AccountProvider',

        providerId: 505622,
        firstName: 'Winter',
        lastName: 'Rivas',
        middleName: 'Monroe',
        suffix: null,
        salutation: null,
        providerStatus: ProviderStatus.Active,
      },
    ],
  };
};

/**
 * a product mock instance
 */
export const productMockData: Product = {
  __typename: 'Product',
  attributes: [
    {
      __typename: 'ProductAttribute',
      defaultValue: null,
      isRequired: true,
      attribute: {
        __typename: 'Attribute',
        attributeOptions: [],
        attributeRules: [],
        attributeValueType: ProductAttributeValueType.Diagram,
        isPricing: false,
        name: 'Tooth String',
        type: 'Tooth String',
      },
      name: 'Tooth String',
      type: 'Tooth String',
    },
    {
      __typename: 'ProductAttribute',
      defaultValue: null,
      isRequired: false,
      attribute: {
        __typename: 'Attribute',
        attributeOptions: [],
        attributeRules: [],
        attributeValueType: ProductAttributeValueType.String,
        isPricing: false,
        name: 'Adapt to Partial',
        type: 'Add-On',
      },
      name: 'Adapt to Partial',
      type: 'Add-On',
    },
    {
      __typename: 'ProductAttribute',
      defaultValue: null,
      isRequired: false,
      attribute: {
        __typename: 'Attribute',
        attributeOptions: [],
        attributeRules: [],
        attributeValueType: ProductAttributeValueType.Number,
        isPricing: false,
        name: 'Study Model',
        type: 'Service',
      },
      name: 'Study Model',
      type: 'Service',
    },
    {
      __typename: 'ProductAttribute',
      defaultValue: 'HI-TECH/ALL CERAMIC',
      isRequired: false,
      attribute: {
        __typename: 'Attribute',
        attributeOptions: [],
        attributeRules: [],
        attributeValueType: ProductAttributeValueType.String,
        isPricing: false,
        name: 'Department',
        type: 'Report Grouping',
      },
      name: 'Department',
      type: 'Report Grouping',
    },
    {
      __typename: 'ProductAttribute',
      defaultValue: 'HI-TECH/ALL CERAMIC',
      isRequired: true,
      attribute: {
        __typename: 'Attribute',
        attributeOptions: [
          'COMBO/PARTIAL',
          'HI-TECH/ALL CERAMIC',
          'DENTURE/VALPLAST',
          'IMPLANT FULL ARCH',
          'THERMOFORM',
        ],
        attributeRules: [],
        attributeValueType: ProductAttributeValueType.Enum,
        isPricing: false,
        name: 'Department',
        type: 'Department',
      },
      name: 'Department',
      type: 'Department',
    },
  ],
  components: [
    {
      __typename: 'ProductComponent',
      abbreviation: 'FX',
      type: ProductComponentType.ProsthesisType,
      value: 'Fixed',
    },
    {
      __typename: 'ProductComponent',
      abbreviation: 'NM',
      type: ProductComponentType.MaterialClass,
      value: 'Non-Metal',
    },
    {
      __typename: 'ProductComponent',
      abbreviation: 'BZ',
      type: ProductComponentType.Material,
      value: 'BruxZir Solid Zirconia',
    },
    {
      __typename: 'ProductComponent',
      abbreviation: 'SI',
      type: ProductComponentType.RestorationType,
      value: 'Single',
    },
  ],
  productCode: 'FX-NM-BZ-SI',
  productName: 'BruxZir Solid Zirconia Single',
  type: ProductType.Product,
};

/**
 * a mock product brand mock data
 */
export const productBrandMockData: Brand[] = [
  {
    __typename: 'Brand',
    siteId: '30',
    brandName: 'Riverside Dental Ceramics',
    products: [
      {
        __typename: 'BrandProduct',
        productCode: 'FX-NM-BZ-SI',
        daysInLab: {
          __typename: 'BrandDaysInLab',
          internal: 5,
          external: 5,
          digital: 3,
        },
        attributes: [
          {
            __typename: 'BrandAttribute',
            name: 'Tooth Number System',
            type: 'Tooth String',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Stump Shade',
            type: 'Shade',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Shade System',
            type: 'Shade',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Printed Model',
            type: 'Service',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Post',
            type: 'Add-On',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Rest',
            type: 'Add-On',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Pink Porcelain',
            type: 'Add-On',
            daysInLab: {
              __typename: 'BrandDaysInLab',
              internal: 2,
              external: 4,
              digital: null,
            },
          },
        ],
      },
    ],
  },
  {
    __typename: 'Brand',
    siteId: '10',
    brandName: 'Glidewell Laboratories',
    products: [
      {
        __typename: 'BrandProduct',
        productCode: 'FX-NM-BZ-SI',
        daysInLab: {
          __typename: 'BrandDaysInLab',
          internal: 5,
          external: 5,
          digital: 3,
        },
        attributes: [
          {
            __typename: 'BrandAttribute',
            name: 'Tooth Number System',
            type: 'Tooth String',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Stump Shade',
            type: 'Shade',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Shade System',
            type: 'Shade',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Printed Model',
            type: 'Service',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Post',
            type: 'Add-On',
            daysInLab: null,
          },
          {
            __typename: 'BrandAttribute',
            name: 'Pink Porcelain',
            type: 'Add-On',
            daysInLab: null,
          },
        ],
      },
    ],
  },
];

/**
 * a default product mock attribute
 */
export const productAttribute: ProductFullAttribute = {
  ...getProductAttribute(),
  displayName: 'Process Attribute',
  name: 'Process Attribute',
  type: 'Process Attribute',
  attributeOptions: ['Option 1', 'Option 2', 'Option 3'],
  attributeRules: [],
  attributeValueType: ProductAttributeValueType.Enum,
  isPricing: true,
  isCustomAttribute: true,
  isVisible: true,
  attributeGroupName: 'Restorative Attribute',
  displayType: AttributeDisplayType.ToothDown,
  defaultValue: '',
  isRequired: false,
  rank: null,
};
