import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import {
  CaseHistoryAdditionalDetailsObject,
  CaseHistoryAdditionalDetailsOrderItem,
} from 'shared/constants/case-detail.constants';
import { convertToCurrency } from 'shared/utils';

interface AdditionalDetailsChangeItemHOCProps {
  label: string;
  isBold?: boolean;
}

/**
 * Returns a change item HOC with a label.
 * @param children - The child components of this HOC.
 * @param label - The label to use for this change item.
 * @returns a change item HOC with a label.
 */
const AdditionalDetailsChangeItemHOC: FC<AdditionalDetailsChangeItemHOCProps> = ({ children, label }) => {
  if (!children) return <></>;
  return (
    <div className="flex flex-col gap-1 mt-1">
      <div className="font-bold">{`${label}:`}</div>
      {children}
    </div>
  );
};

/**
 * Props of the OrderItemLists component.
 */
interface OrderItemListsProps {
  /**
   * The list of order items.
   */
  items: CaseHistoryAdditionalDetailsOrderItem['Attributes'];
  /**
   * The label for the list.
   */
  label: string;
}

/**
 * Component for displaying the attributes as a list with its unit price.
 * @param items - The list of order items.
 * @param label - The label for the list.
 * @returns A JSX element representing the OrderItemLists component.
 */
const OrderItemLists: FC<OrderItemListsProps> = ({ items, label }) => {
  if (!items || !items.length) return null;
  return (
    <AdditionalDetailsChangeItemHOC label={label}>
      <ul className="list-disc">
        {items.map(({ Name, Value, UnitPrice, Quantity }, index: number) => {
          return (
            <li className="ml-6" key={`${Name}-${Value}-${index}`}>
              {Name}: {(Value || Quantity) ?? convertToCurrency(+UnitPrice)}
            </li>
          );
        })}
      </ul>
    </AdditionalDetailsChangeItemHOC>
  );
};

/**
 * Component for displaying order item attributes detail.
 *
 * @param orderItem - The orderItem passed.
 * @returns A JSX element representing the OrderItemAttributesDetail component.
 */
export const OrderItemAttributesDetail: FC<CaseHistoryAdditionalDetailsOrderItem> = orderItem => {
  const { AddOns, Attributes, ProductCode, ReturnType, ReturnReasons, Services } = orderItem;
  // Defaulting while destructuring objects doesn't work for null values. Need to set a default value by other means. Please see LMS1-7240.
  const returnReasons = ReturnReasons || [];
  return (
    <div className="mb-2">
      <strong>{ProductCode}</strong>
      <AdditionalDetailsChangeItemHOC label="Return Type">{ReturnType}</AdditionalDetailsChangeItemHOC>
      <AdditionalDetailsChangeItemHOC label="Return Reasons">
        {returnReasons.length > 0 && (
          <ul className="list-disc ml-6">
            {returnReasons.map((returnReason, index) => (
              <li key={`return-reason-${returnReason}-${index}`}>{returnReason}</li>
            ))}
          </ul>
        )}
      </AdditionalDetailsChangeItemHOC>
      <OrderItemLists items={Attributes} label="Attributes" />
      <OrderItemLists items={AddOns} label="Addons" />
      <OrderItemLists items={Services} label="Services" />
    </div>
  );
};

/**
 * Props of the OrderItemDetails component.
 */
export interface OrderItemDetailsProps {
  /**
   * The old additional details.
   */
  oldData: CaseHistoryAdditionalDetailsObject | null;
  /**
   * The new additional details.
   */
  newData: CaseHistoryAdditionalDetailsObject | null;
}

/**
 * Component for displaying order item details.
 * @param oldData - The old additional details.
 * @param newData - The new additional details.
 * @returns A JSX element representing the OrderItemDetails component.
 */
export const OrderItemDetails: FC<OrderItemDetailsProps> = ({ oldData, newData }) => {
  const { OrderItems: oldOrderItems = [] } = oldData || {};
  const { OrderItems: newOrderItems = [] } = newData || {};

  const hasOldOrderItemsLength = !!oldOrderItems.length;
  const hasNewOrderItemsLength = !!newOrderItems.length;
  if (!hasOldOrderItemsLength && !hasNewOrderItemsLength) return null;
  return (
    <>
      <div className="flex flex-1 justify-between">
        {hasOldOrderItemsLength && (
          <div className="block">
            {oldOrderItems.map((item, index) => (
              <div key={`old-${item.ProductCode}-${index}`}>
                <OrderItemAttributesDetail {...item} />
              </div>
            ))}
          </div>
        )}
        {hasOldOrderItemsLength && hasNewOrderItemsLength && (
          <div className="mx-6">
            <ArrowRightIcon className="w-5 h-5" />
          </div>
        )}
        {hasNewOrderItemsLength && (
          <div className="block">
            {newOrderItems.map((item, index) => (
              <div key={`new-${item.ProductCode}-${index}`}>
                <OrderItemAttributesDetail {...item} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
