import { useEffect, useState } from 'react';
import { WINDOW_EVENT_TYPE_OFFLINE, WINDOW_EVENT_TYPE_ONLINE } from 'shared/constants/network-status.constants';

/**
 * Check whether the navigator is online or not.
 * Used for initial validating of the network when this hook is loaded.
 * @returns boolean.
 */
const getOnLineStatus = () => {
  return typeof navigator !== 'undefined' && typeof navigator.onLine === 'boolean' ? navigator.onLine : true;
};

/**
 * A hook to hold an up-to-date value of the online status of the browser.
 * The initial value uses navigator.onLine.
 * @returns the online status.
 */
export const useNetworkOnline = () => {
  const [status, setStatus] = useState(getOnLineStatus());

  const setOnline = () => setStatus(true);
  const setOffline = () => setStatus(false);

  useEffect(() => {
    window.addEventListener(WINDOW_EVENT_TYPE_ONLINE, setOnline);
    window.addEventListener(WINDOW_EVENT_TYPE_OFFLINE, setOffline);

    return () => {
      window.removeEventListener(WINDOW_EVENT_TYPE_ONLINE, setOnline);
      window.removeEventListener(WINDOW_EVENT_TYPE_OFFLINE, setOffline);
    };
  }, []);

  return status;
};
