import { OrderProductAttributeInput, ProductAttributeValueType, ProductFullAttribute, TechnicalPreference } from 'API';
import NumberInput from 'components/common/NumberInput/NumberInput';
import Radio from 'components/common/Radio/Radio';
import { ColorCheckBoxProps } from 'components/invoicing/ShippingDetail/ColorCheckBox/ColorCheckBox';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useMemo } from 'react';
import { getSelectedToothRange } from 'shared/utils';
import AttributeCheckbox from '../../Inputs/AttributeCheckbox/AttributeCheckbox';
import AttributeSelectDropdown from '../../Inputs/AttributeSelectDropdown/AttributeSelectDropdown';
import AttributeTextInput from '../../Inputs/AttributeTextInput/AttributeTextInput';
import MultiSelectToothDropdown from '../../Inputs/MultiSelectToothDropdown/MultiSelectToothDropdown';

/**
 * Props for the Attribute component.
 */
interface AttributeProps {
  /**
   * The attribute to be displayed.
   */
  attribute: ProductFullAttribute;
  /**
   * The ID of the item.
   */
  itemId: string;
  /**
   * Function to handle attribute changes.
   */
  onAttributeChange: (attributeInput: OrderProductAttributeInput) => void;
  /**
   * Optional label for the attribute.
   */
  label?: string;
  /**
   * Optional tooth number associated with the attribute.
   */
  tooth?: string;
  /**
   * Optional technical preference.
   */
  technicalPreference?: TechnicalPreference;
}

/**
 * The Attribute component handles the display and interaction of various attributes for an order item.
 * @param attribute - The attribute to be displayed.
 * @param itemId - The ID of the item.
 * @param onAttributeChange - Function to handle attribute changes.
 * @param label - Optional label for the attribute.
 * @param tooth - Optional tooth number associated with the attribute.
 * @param technicalPreference - Optional technical preference.
 * @returns JSX element representing the Attribute component.
 */
const Attribute: React.FC<AttributeProps> = ({
  attribute,
  itemId,
  onAttributeChange,
  technicalPreference,
  label = '',
  tooth,
}) => {
  const { order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const { getOrderItemSelectedTeeth } = useContext(OrderModuleActionsContext);
  const foundIndex = order.orderItems.findIndex(item => item.id === itemId);
  const targetOrderItem = order.orderItems[foundIndex];
  const orderId = targetOrderItem?.id + '-' + foundIndex;
  const itemTeeth = useMemo(() => {
    const selectedTeethValues = getOrderItemSelectedTeeth(itemId);
    return getSelectedToothRange(targetOrderItem?.addOns, selectedTeethValues);
  }, [getOrderItemSelectedTeeth, targetOrderItem?.addOns, itemId]);
  // If the attribute uses its target tooth number as its value, uses a different method for retrieving its context and uses its name for its value.
  const attributeContext = useMemo(() => {
    return targetOrderItem?.attributes?.find(
      attr => attr.name === attribute.name || (tooth && attr.type === attribute.name && attr.value === tooth)
    );
  }, [attribute.name, tooth, targetOrderItem?.attributes]);
  const attributeValue = tooth ? attributeContext?.name : attributeContext?.value;

  // By default, no special styling is applied. If there is a technical preference, special styling is applied in relation to whether the preference is selected or not.
  let inputClassName = undefined;
  let isAbleToResetToDefaultPreference = false;
  let radioClass = undefined;
  let variant: ColorCheckBoxProps['variant'] = undefined;
  const productPreferenceValue = technicalPreference?.attributeValue;
  if (productPreferenceValue && attributeContext?.value === productPreferenceValue) {
    inputClassName = 'border border-teal-500 bg-teal-100';
    variant = 'teal';
    radioClass = 'text-teal-500 focus:ring-teal-500 border-4 ';
    isAbleToResetToDefaultPreference = false;
  } else if (productPreferenceValue && attributeContext?.value !== productPreferenceValue) {
    inputClassName = 'border border-orange-500 bg-orange-100';
    isAbleToResetToDefaultPreference = true;
    variant = 'orange';
    radioClass = 'text-orange-500 focus:ring-orange-500 border-4 ';
  }

  /**
   * Reusable function for handling attribute changes where there are common values for all
   * attribute changes.
   */
  const onAttributeChangeWrapper = useCallback(
    (value: string, quantity?: number) => {
      const { name, type } = attribute;

      onAttributeChange({
        name,
        type,
        value,
        quantity: quantity ?? 1,
      });
    },
    [attribute, onAttributeChange]
  );

  /**
   * Resets the attribute value to the default technical preference.
   */
  const onValueResetToDefaultPreference = () => {
    onAttributeChangeWrapper(productPreferenceValue || '');
  };

  const { isCustomAttribute } = attribute;
  const { attributeValueType, attributeOptions } = attribute;

  // If isCustomAttribute is true, the attribute is not displayed. We already handled custom attributes in the AttributeCustomized component.
  if (isCustomAttribute) return null;

  return (
    <>
      {attributeValueType === ProductAttributeValueType.String && (
        <AttributeTextInput
          label={label}
          id={attribute.name}
          className={inputClassName}
          value={attributeValue || ''}
          onValueChange={value => onAttributeChangeWrapper(value)}
          isRequired={attribute.isRequired ?? false}
          isSubmitted={onSubmitAlertCount > 0}
          isAbleToReset={isAbleToResetToDefaultPreference}
          onValueReset={onValueResetToDefaultPreference}
        />
      )}
      {attributeValueType === ProductAttributeValueType.Enum && (
        <AttributeSelectDropdown
          label={label}
          className={inputClassName}
          options={attributeOptions.map(option => {
            return { value: option, primaryLabel: option };
          })}
          value={attributeValue || ''}
          onValueChange={value => onAttributeChangeWrapper(value)}
          isRequired={attribute.isRequired ?? false}
          isSubmitted={onSubmitAlertCount > 0}
          isAbleToReset={isAbleToResetToDefaultPreference}
          onValueReset={onValueResetToDefaultPreference}
        />
      )}
      {attributeValueType === ProductAttributeValueType.Bool && (
        <AttributeCheckbox
          attribute={attribute}
          checked={attributeContext ? attributeContext.value === 'Yes' : false}
          onChange={(checked: boolean) => {
            // If a user unchecks a checkbox, the attribute value will reset to empty string, as if they never selected the field previously.
            // Previously, we would send a value of "No", but this resulted in unnecessary attributes being displayed on the case details screen.
            // This change resolved LMS1-3447.
            const value = checked ? 'Yes' : '';
            onAttributeChangeWrapper(value);
          }}
          isRequired={attribute.isRequired ?? false}
          label={label}
          isAbleToReset={isAbleToResetToDefaultPreference}
          onValueReset={onValueResetToDefaultPreference}
          variant={variant}
        />
      )}
      {attributeValueType === ProductAttributeValueType.Number && (
        <NumberInput
          id={attribute.name}
          className={inputClassName}
          value={attributeValue ?? 0}
          label={label}
          onChange={value => onAttributeChangeWrapper(value)}
          isRequired={attribute.isRequired ?? false}
          isAbleToReset={isAbleToResetToDefaultPreference}
          onValueReset={onValueResetToDefaultPreference}
          onSubmitAlert={onSubmitAlertCount > 0}
          min={1}
          disableDecimal
        />
      )}
      {attributeValueType === ProductAttributeValueType.Radio && (
        <Radio
          id={orderId + '-radio-' + attribute.name.replace(/ /g, '')}
          className={inputClassName}
          radioClass={radioClass}
          value={attributeValue || ''}
          label={label}
          options={attributeOptions.map(option => {
            return { value: option, label: option };
          })}
          onValueChange={value => onAttributeChangeWrapper(value)}
          isAbleToReset={isAbleToResetToDefaultPreference}
          onValueReset={onValueResetToDefaultPreference}
        />
      )}
      {attributeValueType === ProductAttributeValueType.ToothSelection && (
        <MultiSelectToothDropdown
          itemTeeth={itemTeeth}
          attributeValue={attributeValue || ''}
          onSelectedValueChange={selected => onAttributeChangeWrapper(selected)}
          testId="attributeToothStringInput"
          isRequired={attribute.isRequired ?? false}
          className={inputClassName}
          label={label}
        />
      )}
    </>
  );
};

export default Attribute;
