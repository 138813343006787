import { NoSymbolIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, FlagIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { Account, AccountStatus, AddressType, CustomerSearchRecord, ProviderStatus } from 'API';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import Label from 'components/common/Label';
import Loader from 'components/common/Loader/Loader';
import Modal from 'components/common/Modal';
import { useFormik } from 'formik';
import moment from 'moment';
import { useAlertModal } from 'providers/AlertModalProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { OrderEntryAccountActionsContext, OrderEntryAccountContext } from 'providers/OrderEntryAccountProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { accountLookup } from 'shared/api/account.api';
import { ACCOUNT_NUMBER_REGEX } from 'shared/constants/constants';
import { ErrorMessage } from 'shared/enums';
import { isFormikFieldInvalid } from 'shared/helpers/form.helper';
import { getAccountClosedReasonText, getDefaultAddress } from 'shared/helpers/order-entry/order-entry.helper';
import { getProviderName } from 'shared/helpers/provider.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { LocalOrderInput } from 'shared/models';
import { isAccountCreditHold } from 'shared/utils';
import * as Yup from 'yup';
import AccountLookup from '../AccountLookup/AccountLookup';

const AccountIdSchema = Yup.object().shape({
  accountId: Yup.string().required('Account # is required'),
});

/**
 * Represents the form values for the Account Number component.
 */
interface FormValue {
  /** The account ID entered in the form. */
  accountId: string;
}

/**
 * Props interface for the AccountNumber component.
 */
interface AccountNumberProps {
  /**
   * Optional boolean flag to indicate whether to show a skeleton loader while loading.
   */
  skeletonLoader?: boolean;
}

/**
 * Represents the data structure for the last fetched account details.
 */
interface LastFetchedData {
  /** The account details fetched from the API. */
  account: Account;
  /** Partial order input data associated with the account. */
  data: Partial<LocalOrderInput>;
}

const accountHoldError = 'Account is on Credit Hold';
const accountClosedText = 'Account is Closed';
const accountLeadError = 'Account is Lead';

/**
 * Represents the Account Number component.
 * This component allows users to search for an account by account number.
 * It provides functionality to fetch account details and display relevant information.
 * @param skeletonLoader - Indicates whether to show a skeleton loader while loading.
 */
const AccountNumber: React.FC<AccountNumberProps> = ({ skeletonLoader }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastSearchedAccount, setLastSearchedAccount] = useState<string>('');
  const [accountStanding, setAccountStanding] = useState<AccountStatus>();
  const [showAccountLookup, setShowAccountLookup] = useState<boolean>(false);
  const accountNumberElementRef = useRef<HTMLInputElement>(null);
  const { account } = useContext(OrderEntryAccountContext);
  const { setAccount } = useContext(OrderEntryAccountActionsContext);
  const { order, onSubmitAlertCount } = useContext(OrderModuleContext);
  const { patchOrder, isOrderItemExchangeReturnType } = useContext(OrderModuleActionsContext);
  const { getManufacturingLocation } = useManufacturingLocation();
  const [lastFetchedData, setLastFetchedData] = useState<LastFetchedData | undefined>(undefined);
  const alert = useAlertModal();
  const navigate = useNavigate();
  const [hasError, setHasError] = useState<boolean>(false);
  const { fetcher } = useLazyQueryFetcher(accountLookup);

  useEffect(() => {
    accountNumberElementRef.current?.focus();
  }, []);

  /**
   * To handle submissions through formik.
   * @param data - form data.
   */
  const submitHandler = (data: FormValue) => {
    accountSearchHandler(data.accountId);
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldError } = useFormik({
    initialValues: {
      accountId: order.billingAccountId || '',
    },
    validationSchema: AccountIdSchema,
    onSubmit: submitHandler,
    validateOnBlur: false,
    validate(values) {
      if (!ACCOUNT_NUMBER_REGEX.test(values.accountId)) {
        return {
          accountId: ErrorMessage.INVALID_ACCOUNT_NUMBER,
        };
      }
    },
  });

  // Reset Patch Order
  const resetEntry = useCallback(() => {
    patchOrder({
      billingAccountId: '',
      providerId: '',
      providerName: '',
      utcConversionTimeZone: moment.tz.guess(),
      originFacility: '',
      originFacilityId: -1,
      shippingAddress: getDefaultAddress(),
    });
    setAccount(undefined);
  }, [patchOrder, setAccount]);

  const cancelEntryAndShowModal = useCallback(
    (modalText: { title: string; message: string }, navBack: boolean) => {
      if (navBack) {
        resetEntry();
      }
      alert.show(modalText.title, modalText.message, () => {
        return navBack ? navigate(-1) : null;
      });
    },
    [alert, navigate, resetEntry]
  );

  /**
   * Shared logic for searching for a new account that handles different error cases.
   * @param accountId -t he account id to search.
   * @param selectedProviderId - (optional) provider id to set
   */
  const accountSearchHandler = useCallback(
    async (accountId: string, selectedProviderId?: string) => {
      setIsLoading(true);
      setLastSearchedAccount(accountId);
      setFieldValue('accountId', accountId, true);
      setAccountStanding(undefined);

      try {
        const newAccount = await fetcher({ accountId });
        setAccount(newAccount);
        const { closedReason, standing, status } = newAccount;

        if (status === AccountStatus.Closed) {
          const msg = {
            title: 'Case creation is not allowed because Closed account status',
            message: `Account # ${accountId}\nReason: ${getAccountClosedReasonText(
              closedReason
            )}\n\nThis case cannot be entered because the account is closed. Place on hold for review.`,
          };
          setFieldError('accountId', accountClosedText);
          setHasError(true);
          cancelEntryAndShowModal(msg, false);
        } else if (status === AccountStatus.Prospect) {
          const msg = {
            title: 'Case creation is not allowed because Lead account status',
            message: `Account # ${accountId}\n\nThis case cannot be entered because the account is a Lead account. Place on hold for review.`,
          };
          setFieldError('accountId', accountLeadError);
          setHasError(true);
          cancelEntryAndShowModal(msg, false);
        } else if (isAccountCreditHold(standing)) {
          const msg = {
            title: ErrorMessage.CASE_CREATION_NOT_ALLOWED_CREDIT_HOLD,
            message: `Account # ${accountId}\n\nThis case cannot be entered because the account is on Credit Hold. Place on hold for review`,
          };
          setFieldError('accountId', accountHoldError);
          setHasError(true);
          cancelEntryAndShowModal(msg, false);
        } else {
          // We only care about shipping addresses so filter and set on account object.
          const shippingAddresses = newAccount.addresses.filter(
            a => a.type.toLowerCase() === AddressType.Shipping.toLowerCase()
          );
          newAccount.addresses = shippingAddresses;

          setAccountStanding(newAccount.status);
          let street1 = '';
          let street2 = '';
          let city = '';
          let state = '';
          let zipcode = '';
          let country = '';

          if (newAccount.addresses.length === 1) {
            street1 = newAccount.addresses[0].street1;
            street2 = newAccount.addresses[0]?.street2 || '';
            city = newAccount.addresses[0].city;
            state = newAccount.addresses[0].state;
            zipcode = newAccount.addresses[0].zipcode;
            country = newAccount.addresses[0].country;
          }

          // In LMS1-7572, we established that filtered providers should be getting removed prior to populating the provider dropdown.
          const activeProviders =
            newAccount?.providers?.filter(provider => provider.providerStatus === ProviderStatus.Active) || [];
          // If there is only one active provider available to select, auto-selects it, as per LMS1-7522.
          let newProvider = activeProviders.length === 1 ? activeProviders[0] : undefined;

          if (selectedProviderId) {
            const foundSelectedProvider = newAccount?.providers.find(
              provider => provider.providerId.toString() === selectedProviderId
            );
            if (foundSelectedProvider) {
              newProvider = foundSelectedProvider;
            }
          }

          const orderShipping = order.shippingAddress;
          let newShippingAddress = {
            street1,
            street2,
            city,
            state,
            zipcode,
            country,
            type: AddressType.Shipping,
          };

          /**
           * If billing account is the same and we currently have an address, keep it.
           * Covers case edit scenario where we need to set the order shipping address.
           */

          if (
            accountId === order.billingAccountId &&
            orderShipping.street1 &&
            orderShipping.city &&
            orderShipping.state
          ) {
            newShippingAddress = {
              ...order.shippingAddress,
              street2: order.shippingAddress.street2 || '',
            };
          }

          const manufacturingLocation = getManufacturingLocation(newAccount.labOriginId);
          const dispatchedData = {
            billingAccountId: accountId,
            providerId: newProvider ? newProvider.providerId.toString() : undefined,
            providerName: newProvider ? getProviderName(newProvider) : undefined,
            shippingAddress: newShippingAddress,
            utcConversionTimeZone: manufacturingLocation ? manufacturingLocation.tzIdentifier : moment.tz.guess(),
            originFacility: newAccount.labOrigin,
            originFacilityId: newAccount.labOriginId,
          };

          patchOrder(dispatchedData);
          setLastFetchedData({
            data: dispatchedData,
            account: newAccount,
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          if (e.name === 'NotFoundError') {
            setFieldError('accountId', ErrorMessage.INVALID_ACCOUNT_NUMBER);
          } else {
            setFieldError('accountId', 'Error Loading Account');
          }
          resetEntry();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [
      setFieldValue,
      patchOrder,
      setFieldError,
      cancelEntryAndShowModal,
      setAccount,
      order.shippingAddress,
      order.billingAccountId,
      getManufacturingLocation,
      resetEntry,
      fetcher,
    ]
  );

  useEffect(() => {
    // !isLoading added so this doesn't get called more than once.
    if (!account && order.billingAccountId && !isLoading) {
      accountSearchHandler(order.billingAccountId, order.providerId);
    }
  }, [order.billingAccountId, account, isLoading, accountSearchHandler, order.providerId]);

  /**
   * Called when account search is used and we select a provider/account.
   * @param searchRecord -  the search record to set in account details.
   */
  const accountLookupSelectHandler = (searchRecord: CustomerSearchRecord) => {
    const billingAccountId = searchRecord.account.billingAccountId;

    setShowAccountLookup(false);
    accountSearchHandler(billingAccountId, `${searchRecord.providerId}`);
  };

  let icon = <Loader show={isLoading} className="text-white" spin />;
  const showIcon =
    isLoading ||
    (accountStanding === AccountStatus.Active &&
      lastSearchedAccount === order.billingAccountId &&
      !errors.accountId &&
      values.accountId === lastSearchedAccount) ||
    errors.accountId === accountHoldError ||
    errors.accountId === accountClosedText;

  if (accountStanding === AccountStatus.Active && lastSearchedAccount === order.billingAccountId && !hasError) {
    icon = <CheckBadgeIcon className="h-5 text-green-500" data-testid="valid-indicator" />;
  }

  if (errors.accountId === accountHoldError) {
    icon = <FlagIcon className="h-4 text-red-500" />;
  }

  if (errors.accountId === accountClosedText) {
    icon = <NoSymbolIcon className="h-5 text-gray-500" />;
  }

  useEffect(() => {
    if (onSubmitAlertCount > 0 && !values.accountId) {
      setFieldError('accountId', 'Account # is required');
    }
  }, [onSubmitAlertCount, values.accountId, setFieldError]);

  const onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const accId = e.target.value;
    if (ACCOUNT_NUMBER_REGEX.test(accId) && !order.providerId && lastSearchedAccount !== accId) {
      accountSearchHandler(accId);
      handleBlur(e);
    }
  };

  const reloadLastSearchValue = () => {
    if (lastFetchedData && lastFetchedData.data) {
      const data = lastFetchedData.data;
      patchOrder({
        billingAccountId: data?.billingAccountId,
        providerId: data.providerId,
        providerName: data.providerName,
        shippingAddress: data.shippingAddress,
        utcConversionTimeZone: data.utcConversionTimeZone,
        originFacility: data.originFacility,
        originFacilityId: data.originFacilityId,
      });
      setAccount(lastFetchedData.account);
      setAccountStanding(lastFetchedData?.account?.status);
    }
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
    setAccountStanding(undefined);
    setHasError(false);
    if (lastSearchedAccount === e.target.value) {
      reloadLastSearchValue();
    } else {
      resetEntry();
    }
  };

  const hasExchangeItem = useMemo(() => isOrderItemExchangeReturnType(), [isOrderItemExchangeReturnType]);

  return (
    <div>
      <Label htmlFor="accountId" required>
        Account #
      </Label>
      <div className="flex">
        <form onSubmit={handleSubmit} className="flex-grow" data-testid="accountIdForm">
          <Input
            name="accountId"
            id="accountId"
            type="text"
            value={values.accountId}
            onChange={text => onChangeValue(text)}
            onBlur={onBlur}
            isInvalid={isFormikFieldInvalid(!!touched.accountId, errors.accountId)}
            ref={accountNumberElementRef}
            className="w-full"
            icon={icon}
            showIcon={showIcon}
            skeletonLoader={skeletonLoader}
            autoFocus
            placeholder="Search by Account #"
            disabled={hasExchangeItem}
          />
          <InputError message={errors.accountId} />
        </form>
        <div className="pl-2 mt-1/2">
          <button
            type="button"
            className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setShowAccountLookup(true)}
            data-qa="searchAccountButton"
            disabled={hasExchangeItem}
          >
            <MagnifyingGlassIcon className="text-indigo-600 h-4 w-4" aria-hidden="true" />
          </button>
        </div>
      </div>
      {showAccountLookup && (
        <Modal onClose={() => setShowAccountLookup(false)} width="w-3/4">
          <AccountLookup onSearchRecordSelect={searchRecord => accountLookupSelectHandler(searchRecord)} />
        </Modal>
      )}
    </div>
  );
};

export default AccountNumber;
