import { AttributeDisplayType, ProductFullAttribute } from 'API';
import { useMemo } from 'react';
import { filterAttributesByDisplayType, getAttributeToothValue } from 'shared/helpers/order-entry/order-entry.helper';
import { AttributeDisplayTypeToothDown } from './AttributeDisplayTypeToothDown/AttributeDisplayTypeToothDown';

interface AttributeCustomizedProps {
  attributes: ProductFullAttribute[];
  selectedTeeth: string[];
  orderItemId: string;
}

export const AttributeCustomized: React.FC<AttributeCustomizedProps> = ({ attributes, selectedTeeth, orderItemId }) => {
  const localSelectedTeeth = useMemo(() => {
    const toothConcatValue = getAttributeToothValue(selectedTeeth);
    return toothConcatValue ? toothConcatValue.split(',') : [];
  }, [selectedTeeth]);

  // Filter attributes by display type ToothDown
  const toothDownAttributes = useMemo(() => {
    return filterAttributesByDisplayType(attributes, AttributeDisplayType.ToothDown);
  }, [attributes]);

  return (
    <AttributeDisplayTypeToothDown
      attributes={toothDownAttributes}
      selectedTeeth={localSelectedTeeth}
      orderItemId={orderItemId}
    />
  );
};
