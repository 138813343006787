import { MinusCircleIcon } from '@heroicons/react/24/solid';
import { CaseDiscountType } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import NumberInput from 'components/common/NumberInput/NumberInput';
import Toggle, { ToggleOption } from 'components/common/Toggle';
import { usePreviousRef } from 'hooks/use-previous-ref';
import { FC, useCallback, useMemo } from 'react';
import { PART_OF_CASE_DISCOUNT_TYPE } from 'shared/constants/invoice-data.constants';
import { DiscountType } from 'shared/enums';
import { ToggleState } from 'shared/enums/toggle-state';
import { uniqueDiscountItem } from 'shared/helpers/order-detail/order-detail.helper';
import { convertToCurrency, isPositiveNumberWithDecimal } from 'shared/utils';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import { DiscountState } from 'stores/useAddDiscountModalStore/createSpecialDiscountSlice';
import { OrderItemDropdownModel } from 'types/dropdown-menu';
import { DiscountModalType } from '../../types';

/**
 * Props for the DiscountCaseItem component.
 */
interface DiscountCaseItemProps extends DiscountState {
  /**
   * Callback function to delete a discount.
   */
  deleteDiscount: (key: string) => void;
  /**
   * Callback function to update a discount.
   */
  updateDiscount: (discount: Partial<DiscountState>, key: string) => void;
  /**
   * Callback function to set discount errors.
   */
  setDiscountErrors: (errors: Partial<DiscountState['errors']>) => void;
  /**
   * List of order item options.
   */
  orderItemOptions: OrderItemDropdownModel[];
}

/**
 * Component for displaying a discount item in the discount modal.
 *
 * @param isPercentage - Indicates if the discount is a percentage.
 * @param percentageValue - The value of the percentage discount.
 * @param dollarAmount - The dollar amount of the discount.
 * @param deleteDiscount - Callback function to delete a discount.
 * @param updateDiscount - Callback function to update a discount.
 * @param errors - Errors related to the discount.
 * @param setDiscountErrors - Callback function to set discount errors.
 * @param orderItemOptions - List of order item options.
 * @returns a discount case item component.
 */
export const DiscountCaseItem: FC<DiscountCaseItemProps> = ({
  isPercentage,
  percentageValue,
  dollarAmount,
  deleteDiscount,
  updateDiscount,
  errors,
  setDiscountErrors,
  orderItemOptions,
  ...discount
}) => {
  const previousPercentageRef = usePreviousRef(percentageValue);
  const previousDollarRef = usePreviousRef(dollarAmount);
  const selectedDiscountType = useAddDiscountModalStore(state => state.specialDiscount.selectedDiscountType);
  const selectedDiscounts = useAddDiscountModalStore(state => state.specialDiscount.discounts);
  const maxDiscountValue = useAddDiscountModalStore(state => state.maxDiscountValue);

  const getMaxTotalDollarAmount = useAddDiscountModalStore(state => state.getMaxTotalDollarAmount);
  const discountModalType = useAddDiscountModalStore(state => state.discountModalType);

  const isDeductionModalOpened = discountModalType === DiscountModalType.SpecialDiscount;
  // this condition is for outbound shipping for deductions, not for outbound shipping for credits
  const isOutboundShippingForDeduction =
    isDeductionModalOpened && discount.itemId === PART_OF_CASE_DISCOUNT_TYPE.OUTBOUND_SHIPPING;

  const { maxDollar, maxPercentage } = maxDiscountValue[discount.itemId] || {};

  const selectedItem = useMemo(() => {
    const matchingItem = orderItemOptions.find(
      item => uniqueDiscountItem(item.primaryLabel, item.orderItemId) === discount.itemId
    );
    const matchingItemUniqueValue = matchingItem
      ? uniqueDiscountItem(matchingItem.primaryLabel, matchingItem.orderItemId)
      : '';

    return {
      ...matchingItem,
      primaryLabel: matchingItemUniqueValue,
      value: matchingItemUniqueValue,
    };
  }, [discount.itemId, orderItemOptions]);

  /**
   * determines if dollar field should be disabled if Part of Case + Outbound shipping are selected
   *
   * for further details, refer https://glidewell.atlassian.net/browse/LMS1-6031
   */
  const shouldDisableDollar = useCallback(
    (productCode?: string) => {
      return selectedDiscountType === CaseDiscountType.PartOfCase && productCode === DiscountType.OutboundShipping;
    },
    [selectedDiscountType]
  );

  const disableDollar = useMemo(
    () => shouldDisableDollar(selectedItem.productCode),
    [shouldDisableDollar, selectedItem.productCode]
  );

  const toggleOptions = useMemo<ToggleOption[]>(() => {
    return [
      { value: ToggleState.Percentage },
      {
        value: ToggleState.Dollar,
        disabled: disableDollar,
        toolTipText: disableDollar
          ? 'Outbound shipping discounts may only be applied using a percentage amount'
          : undefined,
      },
    ];
  }, [disableDollar]);

  const dropdownOptions = useMemo(() => {
    const selectDiscountValues = selectedDiscounts.map(selectedDiscount => selectedDiscount.itemId);

    /**
     * Filter out currently selected discount types unless it is the current type we are on.
     * This way the user cannot select the same option more than once.
     */
    return orderItemOptions
      .map(option => {
        const uniqueLabel = uniqueDiscountItem(option.primaryLabel, option.orderItemId);
        return { ...option, primaryLabel: uniqueLabel, value: uniqueLabel };
      })
      .filter(option => {
        /**
         * Remove special parts price and notes from dropdownOptions
         */
        return !selectDiscountValues.includes(option.value) || option.value === selectedItem.value;
      });
  }, [orderItemOptions, selectedDiscounts, selectedItem.value]);

  const availableAmount = selectedItem.availableAmount || 0;
  const currentMaxDiscountAmount = getMaxTotalDollarAmount(selectedItem.value, availableAmount);

  const toggleDiscountAmount = (value: string) => {
    const isPercentage = value.endsWith(ToggleState.Percentage);
    const data = { isPercentage, dollarAmount: 0, percentageValue: 0 };
    updateDiscount(data, selectedItem.value);
    setDiscountErrors({ dollarAmount: false, percentageValue: false });
  };

  const onDiscountItemIdChange = (selectedItem: OrderItemDropdownModel) => {
    const dataToUpdate: Partial<DiscountState> = {
      itemId: selectedItem.value,
      productCode: selectedItem.productCode,
      description: selectedItem.description,
      dollarAmount: 0,
      percentageValue: 0,
      orderItemId: selectedItem.orderItemId,
    };

    if (shouldDisableDollar(selectedItem.productCode)) {
      dataToUpdate.isPercentage = true;
    }

    updateDiscount(dataToUpdate, selectedItem.value);
    setDiscountErrors({ itemId: !selectedItem.value });
  };

  /**
   * Handle percent input change.
   * Don't update if not a positive number or if not on a shipping option and there is no max value.
   * Note: shipping options won't always have a max so need to allow user to add value regardless.
   * @param value - value to update to.
   */
  const onPercentageValueChange = (value: string) => {
    if (!isPositiveNumberWithDecimal(value) || (!isOutboundShippingForDeduction && !availableAmount)) return;

    const num = +value;

    // For Outbound Shipping for deductions can be any value, so don't need to check max.
    if (!isOutboundShippingForDeduction) {
      const previousPercentage = previousPercentageRef.current || 0;
      const currentMaxPercentage = maxPercentage - previousPercentage + num;
      const currentMaxDiscountAmountValue = +(availableAmount * (currentMaxPercentage / 100) + maxDollar).toFixed(2);

      if (currentMaxDiscountAmountValue > availableAmount) return;
    }

    updateDiscount({ percentageValue: num }, selectedItem.value);
    setDiscountErrors({ percentageValue: false });
  };

  /**
   * Handle dollar input change.
   * Don't update if not a positive number or if not on a shipping option and there is no max value.
   * Note: shipping options won't always have a max so need to allow user to add value regardless.
   * @param value - value to update to.
   */
  const onDollarValueChange = (value: string) => {
    if (!isPositiveNumberWithDecimal(value) || (!isOutboundShippingForDeduction && !availableAmount)) return;

    const num = +value;

    // For Outbound Shipping for deductions can be any value, so don't need to check max.
    if (!isOutboundShippingForDeduction) {
      const previousDollar = previousDollarRef.current || 0;
      const currentMaxDollar = maxDollar - previousDollar + num;
      const currentDollarPercentage = +((currentMaxDollar / availableAmount) * 100).toFixed(2);
      const currentMaxPercentage = maxPercentage + currentDollarPercentage;

      if (currentMaxPercentage > 100) return;
    }

    updateDiscount({ dollarAmount: num }, selectedItem.value);
    setDiscountErrors({ dollarAmount: false });
  };

  return (
    <div className="mb-2">
      <div className="flex gap-2 justify-start items-center flex-1">
        <div className="flex-grow max-w-xs">
          <Dropdown
            data={dropdownOptions}
            selected={selectedItem}
            setSelected={onDiscountItemIdChange}
            placeholder="Select"
            errorMessage={errors.itemId ? `${discountModalType} value is Required` : ''}
          />
        </div>

        <Toggle
          options={toggleOptions}
          selectedOption={toggleOptions[isPercentage ? 0 : 1]}
          onToggle={toggleState => toggleDiscountAmount(toggleState.value)}
          className="w-24 flex-none"
          dataQa="discountAmountToggle"
          selectedClassName="bg-indigo-500 border-indigo-600 text-white"
        />
        <NumberInput
          id="percentage"
          value={percentageValue || 0}
          onChange={onPercentageValueChange}
          placeholder="0"
          min={0}
          max={100}
          isDisabled={!isPercentage}
          inputRightPadding="pr-1"
          errorMessage={errors.percentageValue ? 'Percentage is required' : ''}
        />
        <NumberInput
          id="dollar"
          value={dollarAmount || 0}
          onChange={onDollarValueChange}
          placeholder="$0"
          isDisabled={isPercentage}
          inputRightPadding="pr-1"
          errorMessage={errors.dollarAmount ? 'Amount is required' : ''}
        />

        <div className="flex items-center mt-2">
          <button
            onClick={() => {
              deleteDiscount(selectedItem.value);
            }}
            disabled={selectedDiscounts.length === 1}
          >
            <MinusCircleIcon className="text-gray-400 h-5 w-5 cursor-pointer" />
          </button>
        </div>
      </div>
      {!isOutboundShippingForDeduction && (
        <div className="text-sm text-gray-800 mt-1 text-right">
          Max. {currentMaxDiscountAmount ? convertToCurrency(currentMaxDiscountAmount) : '$0'}
        </div>
      )}
    </div>
  );
};
