import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { KeyCode } from 'shared/enums';
import Label from '../Label';
import RequiredMessage from '../RequiredMessage/RequiredMessage';
import { ResetButton } from '../ResetButton';

/**
 * Props for the NumberInput component.
 */
interface NumberInputProps {
  /** Error message to display. */
  errorMessage?: string;
  /** Label for the input. */
  label?: string;
  /** Unique identifier for the input. */
  id: string;
  /** Value of the input. */
  value: string | number;
  /** Callback function for when the value changes. */
  onChange: (value: string) => void;
  /** Callback function for when the input loses focus. */
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  /** Indicates if the input is disabled. */
  isDisabled?: boolean;
  /** Indicates if the input is required. */
  isRequired?: boolean;
  /** Minimum value allowed for the input. */
  min?: number;
  /** Maximum value allowed for the input. */
  max?: number;
  /** Indicates if an alert should be shown on submit. */
  onSubmitAlert?: boolean;
  /** Additional padding for the input's right side. */
  inputRightPadding?: string;
  /** Text to display on the right side of the input. */
  rightSideText?: string;
  /** Placeholder text for the input. */
  placeholder?: string;
  /** Additional CSS class for styling. */
  className?: string;
  /** Indicates if the input is able to be reset. */
  isAbleToReset?: boolean;
  /** Callback function for resetting the input value. */
  onValueReset?: () => void;
  /** Warning text to display when the value is empty. */
  warningText?: string;
  /** Indicates if decimal values are disabled. */
  disableDecimal?: boolean;
}

/**
 * Component for a number input field.
 *
 * @param errorMessage - Error message to display.
 * @param label - Label for the input.
 * @param id - Unique identifier for the input.
 * @param value - Value of the input.
 * @param onChange - Callback function for when the value changes.
 * @param onBlur - Callback function for when the input loses focus.
 * @param isDisabled - Indicates if the input is disabled.
 * @param isRequired - Indicates if the input is required.
 * @param min - Minimum value allowed for the input.
 * @param max - Maximum value allowed for the input.
 * @param onSubmitAlert - Indicates if an alert should be shown on submit.
 * @param inputRightPadding - Additional padding for the input's right side.
 * @param rightSideText - Text to display on the right side of the input.
 * @param placeholder - Placeholder text for the input.
 * @param className - Additional CSS class for styling.
 * @param isAbleToReset - Indicates if the input is able to be reset.
 * @param onValueReset - Callback function for resetting the input value.
 * @param warningText - Warning text to display when the value is empty.
 * @param disableDecimal - Indicates if decimal values are disabled.
 * @returns JSX.Element - The rendered component.
 */
const NumberInput: React.FC<NumberInputProps> = ({
  errorMessage,
  id,
  label,
  inputRightPadding,
  isDisabled,
  isRequired,
  onSubmitAlert,
  value,
  onChange,
  onBlur,
  min,
  max,
  placeholder,
  rightSideText,
  className,
  isAbleToReset,
  onValueReset,
  warningText,
  disableDecimal,
}) => {
  const [defaultValue, setDefaultValue] = useState<number>(0);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const noValue = useMemo(() => !value || value === '0', [value]);
  const hasValidationError = (isRequired && (isTouched || onSubmitAlert) && noValue) || errorMessage;
  const isShowWarningText = !!warningText && noValue;

  useEffect(() => {
    setDefaultValue(prevState => {
      prevState && onChange('0');
      return 0;
    });
  }, [onChange]);

  const getDropdownStyles = () => {
    if (isDisabled) {
      return 'bg-gray-100 border-gray-300';
    } else if (hasValidationError) {
      return 'focus:ring-red-500 focus:border-red-500 border-red-500';
    } else if (defaultValue && defaultValue === value) {
      return 'focus:ring-teal-500 focus:border-teal-500 border-teal-500 bg-teal-100';
    } else if (defaultValue && defaultValue !== value) {
      return 'focus:ring-orange-500 focus:border-orange-500 border-orange-500 bg-orange-100';
    } else {
      return 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300';
    }
  };

  const resetButton = onValueReset && !!isAbleToReset && <ResetButton onValueReset={onValueReset} />;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    /**
     * When we click the down key from 1 the input is set to 0 but after that the 0 keeps its position on the left side even though it's
     * not getting deleted while editing using the keyboard like 011,05 etc.
     */
    while (inputValue.charAt(0) === '0') {
      inputValue = inputValue.slice(1);
    }
    if (disableDecimal && inputValue.includes('.')) {
      inputValue = inputValue.split('.')[0];
    }
    onChange(inputValue);
  };

  return (
    <div className="flex-1">
      {label && (
        <Label data-testid={`${id}AttributeNumberLabel`} required={isRequired}>
          {label}
        </Label>
      )}
      {!label && resetButton}
      <div className={classNames('rounded-md block text-sm w-full', className)}>
        <div className="flex flex-row relative">
          <input
            data-testid={`numberInput_${id}`}
            type="number"
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={e => onInputChange(e)}
            onKeyDown={e => {
              if (e.key === KeyCode.Backspace && value === 0) {
                onChange('0');
              }
            }}
            onBlur={e => {
              setIsTouched(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
            min={min ?? 0}
            max={max ?? Number.MAX_VALUE}
            style={{ paddingRight: inputRightPadding ?? '2rem' }}
            className={classNames(
              `w-full border outline-none focus:bg-white focus:outline-none block sm:text-sm rounded-md shadow-sm ${getDropdownStyles()}`,
              {
                'bg-white': !isShowWarningText,
                'bg-yellow-50': isShowWarningText,
              }
            )}
            data-qa={id}
            disabled={isDisabled}
          />
          {rightSideText && !hasValidationError && (
            <div className={`absolute h-full right-0 top-0 flex items-center text-gray-500 text-sm mr-2`}>
              <p>{rightSideText}</p>
            </div>
          )}
          <div className={'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'}>
            {hasValidationError && <ExclamationCircleIcon className="h-5 text-red-500" />}
          </div>
        </div>
      </div>
      {!hasValidationError && isShowWarningText && (
        <p className="mb-1 ml-px mt-1 text-xs text-yellow-500">{warningText}</p>
      )}
      {hasValidationError && <RequiredMessage errorMessage={errorMessage} fieldName={label} />}
    </div>
  );
};

export default NumberInput;
