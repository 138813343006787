import { OrderStatus, ReturnType } from 'API';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import { TableColumn, TableRow } from 'components/common/Table/types';
import { Link } from 'react-router-dom';
import { getOrderDetailPath } from 'shared/helpers/route.helper';

export interface CaseTableRow extends TableRow {
  orderNumber: string;
  patientName: string;
  providerName: string;
  status: OrderStatus;
  invoiceDate: string;
  totalAmount: string;
  totalCreditedAmount: string;
  providerId: string;
}

export const caseTableColumns: TableColumn<CaseTableRow>[] = [
  {
    id: 'orderNumber',
    name: 'CASE #',
    render({ row }) {
      return (
        <Link to={getOrderDetailPath(row.orderNumber)} className="text-indigo-600 focus:text-indigo-900 ml-1">
          {row.orderNumber}
        </Link>
      );
    },
  },
  {
    id: 'patientName',
    name: 'PATIENT',
  },
  {
    id: 'providerName',
    name: 'PROVIDER',
  },
  {
    id: 'status',
    name: 'STATUS',
    render({ row }) {
      return <CaseStatusBadge status={row.status} />;
    },
  },
  {
    id: 'invoiceDate',
    name: 'INVOICE DATE',
  },
  {
    id: 'totalAmount',
    name: 'INVOICE AMOUNT',
  },
  {
    id: 'totalCreditedAmount',
    name: 'CREDIT AMOUNT',
  },
];

export const caseProductTableColumns: TableColumn[] = [
  {
    id: 'itemId',
    name: 'SELECT',
  },
  {
    id: 'productName',
    name: 'PRODUCT',
  },
  {
    id: 'quantity',
    name: 'QTY',
  },
  {
    id: 'toothString',
    name: 'TOOTH #',
  },
  {
    id: 'shade',
    name: 'SHADE',
  },
];

export const PRODUCT_RETURN_REQUIRED_VALIDATION_RETURN_TYPES = [ReturnType.ReturnForCredit];
