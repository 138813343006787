import { useEffect, useRef } from 'react';
import { Location } from 'react-router-dom';

/**
 * hook to provide last used location
 * @param location - location
 */
export const usePreviousLocation = (location: Location) => {
  const prevLocationRef = useRef(location);

  useEffect(() => {
    prevLocationRef.current = location;
  }, [location]);

  return prevLocationRef.current;
};
