import { OrderProductAttribute, TechnicalPreference } from 'API';

type GetAttributeColorI = OrderProductAttribute & { productPreferences: TechnicalPreference[] };

/**
 * Returns the correct color to use for a target attribute based on whether it has a preference.
 * @param props - The target attribute's name, value and potential matching product preference.
 * @returns the correct color for the attribute.
 */
export const getAttributeColor = (props: GetAttributeColorI) => {
  const { name, value, productPreferences } = props;
  const matchingAttributePreference = productPreferences.find(
    preference => preference && preference.attributeName === name
  );
  let colorToUse = 'text-gray-500';
  if (matchingAttributePreference && matchingAttributePreference?.attributeValue === value) {
    colorToUse = 'text-teal-500';
  } else if (matchingAttributePreference && matchingAttributePreference?.attributeValue !== value) {
    colorToUse = 'text-orange-500';
  }
  return colorToUse;
};
