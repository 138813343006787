import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import { OrderStatus } from 'API';
import DropdownMenuButton from 'components/common/DropdownMenuButton';
import CancelCaseModal from 'pages/OrderDetailPage/CaseDetailStatusContent/CancelCaseModal/CancelCaseModal';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { FC, useContext, useMemo, useState } from 'react';
import { KebabMenuType, kebabMenus } from 'shared/constants/case-detail.constants';
import { extractMenuObjectFromArray } from 'shared/helpers/dropdown-menu.helper';
import AddCaseToBundleModal from './AddCaseToBundleModal';

const addToBundleKebabMenu = {
  id: KebabMenuType.AddToBundle,
  name: 'Add Case To Bundle',
  icon: <PlusCircleIcon className="w-5 h-5" />,
  visibilities: Object.values(OrderStatus),
};

/**
 * Represents the props for the TabHeaderDropdownMenu component.
 */
interface TabHeaderDropdownMenuProps {
  /**
   * Indicates whether it is an existing order.
   */
  isExistingOrder: boolean;
}

/**
 * Component for the tab header dropdown menu.
 *
 * @param isExistingOrder - Indicates whether it is an existing order.
 * @returns a tab header dropdown menu component.
 */
export const TabHeaderDropdownMenu: FC<TabHeaderDropdownMenuProps> = ({ isExistingOrder }) => {
  const [showAddCaseToBundleModal, setShowAddCaseToBundleModal] = useState(false);
  const [showCancelCaseModal, setShowCancelCaseModal] = useState(false);
  const { orderStatus } = useContext(OrderModuleContext);

  const isDataCaptureOrder = orderStatus === OrderStatus.Pending;

  const caseEntryMenuItems = extractMenuObjectFromArray([...kebabMenus, addToBundleKebabMenu]);

  const menuItems = useMemo(() => {
    return [addToBundleKebabMenu, ...kebabMenus].filter(item => {
      if (isDataCaptureOrder && item.id === KebabMenuType.CancelCase) {
        return true;
      } else if (!isExistingOrder && item.id === KebabMenuType.AddToBundle) {
        return true;
      } else {
        return false;
      }
    });
  }, [isDataCaptureOrder, isExistingOrder]);

  const onMenuClick = (menu: string) => {
    if (menu === caseEntryMenuItems.cancelCase) {
      setShowCancelCaseModal(true);
    } else if (menu === caseEntryMenuItems.addToBundle) {
      setShowAddCaseToBundleModal(true);
    }
  };

  return (
    <>
      {menuItems.length > 0 && (
        <DropdownMenuButton
          menuItems={menuItems}
          onMenuClick={onMenuClick}
          rightIcon={<EllipsisVerticalIcon className="h-5 w-5" aria-label="chevron-down" />}
        />
      )}
      {showAddCaseToBundleModal && <AddCaseToBundleModal onClose={() => setShowAddCaseToBundleModal(false)} />}
      {showCancelCaseModal && (
        <CancelCaseModal showModal={showCancelCaseModal} onCloseModal={() => setShowCancelCaseModal(false)} />
      )}
    </>
  );
};
