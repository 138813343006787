import MultiSelectDropdown from 'components/common/MultiSelectDropdown/MultiSelectDropdown';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useMemo } from 'react';
import { DropdownOption } from 'shared/models';

/**
 * Props for the MultiSelectToothDropdown component.
 */
interface MultiSelectToothDropdownProps {
  /**
   * The list of teeth for the dropdown.
   */
  itemTeeth: string[];
  /**
   * The selected value of the dropdown.
   */
  attributeValue: string;
  /**
   * Callback function to handle selected value change.
   */
  onSelectedValueChange: (selected: string) => void;
  /**
   * Test ID for testing purposes.
   */
  testId?: string;
  /**
   * Indicates whether the dropdown is required.
   */
  isRequired?: boolean;
  /**
   * Additional class name for styling.
   */
  className?: string | undefined;
  /**
   * Label for the dropdown.
   */
  label?: string;
  /**
   * Title for the required message.
   */
  requiredMessageTitle?: string;
  /**
   * Error message to display.
   */
  errorMessage?: string;
}

/**
 * Returns the teeth string value.
 * @param teeth - The teeth value.
 * @returns The formatted teeth string value.
 */
const getTeethStringValue = (teeth: string) => {
  const teethString = teeth.replace('#', '');
  return `#${teethString}`;
};

/**
 * Handles the teeth value.
 * @param teeth - The teeth value.
 * @param teethOptions - The options for teeth.
 * @returns The formatted teeth value.
 */
const teethValueHandler = (teeth: string | undefined, teethOptions: DropdownOption[]) => {
  if (!teeth) {
    return [];
  }
  // If list of single teeth selections then make into array split by "," and map to selected value
  const teethArray = teeth.split(',');
  // Filter out any teeth that are not in the teethOptions array
  const filteredTeeth = teethArray.filter(teeth => teethOptions.some(option => option.value.includes(teeth)));
  return filteredTeeth.map(teeth => {
    const teethValue = getTeethStringValue(teeth);
    return { value: teethValue, label: teethValue };
  });
};

/**
 * The MultiSelectToothDropdown component displays a multi-select dropdown for selecting teeth.
 * @param itemTeeth - The list of teeth for the dropdown.
 * @param attributeValue - The selected value of the dropdown.
 * @param onSelectedValueChange - Callback function to handle selected value change.
 * @param testId - Test ID for testing purposes.
 * @param isRequired - Indicates whether the dropdown is required.
 * @param className - Additional class name for styling.
 * @param label - Label for the dropdown.
 * @param requiredMessageTitle - Title for the required message.
 * @param errorMessage - Error message to display.
 * @returns JSX element representing the MultiSelectToothDropdown component.
 */
const MultiSelectToothDropdown: React.FC<MultiSelectToothDropdownProps> = ({
  itemTeeth,
  attributeValue,
  onSelectedValueChange,
  testId,
  isRequired,
  className,
  label,
  requiredMessageTitle,
  errorMessage,
}) => {
  const { onSubmitAlertCount } = useContext(OrderModuleContext);
  const onValueChange = (selected: DropdownOption[]) => {
    const returnSelected = selected.map(item => item.value).join(',');
    onSelectedValueChange(returnSelected);
  };
  const dropdownOptions = useMemo(() => {
    return itemTeeth.map(teeth => {
      return {
        label: getTeethStringValue(teeth),
        value: getTeethStringValue(teeth),
      };
    });
  }, [itemTeeth]);

  const selectedValues = useMemo(() => {
    return teethValueHandler(attributeValue, dropdownOptions);
  }, [attributeValue, dropdownOptions]);

  return (
    <MultiSelectDropdown
      dropdownOptions={dropdownOptions}
      values={selectedValues}
      onSelectedValueChange={onValueChange}
      onSubmitAlert={onSubmitAlertCount > 0}
      isRequired={isRequired ?? false}
      className={className}
      label={label}
      dataQa={testId || ''}
      requiredMessageTitle={requiredMessageTitle}
      errorMessage={errorMessage}
    />
  );
};

export default MultiSelectToothDropdown;
