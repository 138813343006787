import { ChevronRightIcon, DocumentMagnifyingGlassIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, FlagIcon, QuestionMarkCircleIcon, TagIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { AccountSearchRecord, AccountStanding, AccountStatus, CustomerSearchRecord } from 'API';
import classNames from 'classnames';
import Loader from 'components/common/Loader/Loader';
import { SearchState } from 'shared/enums';
import { getAccountStandingText } from 'shared/helpers/order-entry/order-entry.helper';

/**
 * Props for the AccountLookupResults component.
 */
interface AccountLookupResultsProps {
  /** Array of account search results. */
  accountSearchResults: CustomerSearchRecord[];
  /** State of the search. */
  searchState: SearchState;
  /** The search term used. */
  searchTerm: string;
  /** Function to handle selection of a search record. */
  onSearchRecordSelect: (searchRecord: CustomerSearchRecord) => void;
}

/**
 * Component for displaying search results of account lookup.
 * @param accountSearchResults - Array of account search results.
 * @param searchState - State of the search.
 * @param onSearchRecordSelect - Function to handle selection of a search record.
 * @param searchTerm - The search term used.
 * @returns JSX element representing the AccountLookupResults component.
 */
const AccountLookupResults: React.FC<AccountLookupResultsProps> = ({
  accountSearchResults,
  searchState,
  onSearchRecordSelect,
  searchTerm,
}) => {
  const accountStatusClosed = (status: AccountStatus) => {
    return status === AccountStatus.Closed || status === AccountStatus.Deleted;
  };

  const displayAccountStanding = (accountSearch: AccountSearchRecord) => {
    let textColor = 'text-gray-500';
    let textContent = getAccountStandingText(accountSearch.standing);
    let Icon = QuestionMarkCircleIcon;

    if (accountStatusClosed(accountSearch.status)) {
      textContent = 'Account Closed';
      Icon = XCircleIcon;
    } else {
      switch (accountSearch.standing) {
        case AccountStanding.AlwaysOnCod:
        case AccountStanding.OnCod:
          textColor = 'text-yellow-500';
          Icon = TagIcon;
          break;
        case AccountStanding.AlwaysOnCreditHold:
        case AccountStanding.OnCreditHold:
          textColor = 'text-red-500';
          Icon = FlagIcon;
          break;
        case AccountStanding.Good:
          textColor = 'text-green-500';
          Icon = CheckBadgeIcon;
          break;
      }
    }

    return (
      <div className={`${textColor} flex`}>
        <Icon className="w-6 pr-1.5" />
        <div>{textContent}</div>
      </div>
    );
  };

  const displayMessage = (searchState: SearchState) => {
    if (searchState === SearchState.Error || searchState === SearchState.Completed) {
      const Icon = searchState === SearchState.Error ? ExclamationTriangleIcon : DocumentMagnifyingGlassIcon;
      const dataTestId = searchState === SearchState.Error ? 'errorMessage' : 'noResultsMessage';
      const titleContent = searchState === SearchState.Error ? 'Error searching accounts' : 'No results found';
      const messageContent =
        searchState === SearchState.Error ? (
          'Please try again later.'
        ) : (
          <>
            Unable to find results for '{searchTerm}'.
            <br />
            Try searching for something else.
          </>
        );

      return (
        <div className="py-28">
          <Icon
            className={classNames('h-14 mx-auto font-thin', {
              'text-red-500': searchState === SearchState.Error,
              'text-indigo-500': searchState === SearchState.Completed,
            })}
          />
          <div className="text-center font-medium text-gray-900" data-testid={dataTestId}>
            {titleContent}
          </div>
          <div className="mt-2 text-center text-sm text-gray-500">{messageContent}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={classNames('h-108 max-w-7xl mt-6 overflow-auto', {
        'border border-gray-200 rounded-lg': searchState !== SearchState.Initial,
      })}
    >
      {searchState !== SearchState.Initial && (
        <table
          className="min-w-full divide-y divide-gray-200"
          data-qa="searchResultTable"
          data-testid="searchResultTable"
        >
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Account #
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Account Standing
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Provider
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Phone #
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Shipping Address
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                License #
              </th>
              <th scope="col" className="relative px-6 py-3">
                <span className="sr-only">Select</span>
              </th>
            </tr>
          </thead>
          <tbody className="max-h-96 overflow-auto" data-testid="searchResultTableBody">
            {accountSearchResults.map((searchRecord, index) => (
              <tr
                key={`${searchRecord.providerId}-${index}`}
                className={classNames('border-b', {
                  'bg-gray-50 text-gray-500': accountStatusClosed(searchRecord.account.status),
                  'bg-white': !accountStatusClosed(searchRecord.account.status),
                })}
              >
                {/* Account # */}
                <td className="px-6 py-4 whitespace-nowrap text-sm" title={searchRecord.account.billingAccountId}>
                  {searchRecord.account.billingAccountId}
                </td>
                {/* Account standing */}
                <td className="px-6 py-4 whitespace-nowrap text-sm" title={searchRecord.account.standing}>
                  {displayAccountStanding(searchRecord.account)}
                </td>
                {/* Provider */}
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm overflow-hidden"
                  style={{ maxWidth: '180px' }}
                  title={searchRecord.fullName}
                >
                  {searchRecord.fullName}
                </td>
                {/* Phone # */}
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm overflow-hidden"
                  style={{ maxWidth: '150px' }}
                  title={searchRecord.account.phoneNumbers.map(item => item.number).join(', ')}
                >
                  {searchRecord.account.phoneNumbers.map(item => item.number).join(', ')}
                </td>
                {/* Shipping address */}
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm overflow-hidden overflow-ellipsis"
                  style={{ maxWidth: '100px' }}
                  title={searchRecord.account.shippingAddress}
                >
                  {searchRecord.account.shippingAddress}
                </td>
                {/* License # */}
                <td
                  className="px-6 py-4 whitespace-nowrap text-sm overflow-hidden overflow-ellipsis"
                  style={{ maxWidth: '100px' }}
                  title={searchRecord.licenses.join(', ')}
                >
                  {searchRecord.licenses.join(', ')}
                </td>
                {/* Select button */}
                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <button
                    className="text-indigo-600 hover:text-indigo-900 disabled:opacity-60 cursor-pointer flex flex-row items-center"
                    onClick={() => onSearchRecordSelect(searchRecord)}
                    disabled={accountStatusClosed(searchRecord.account.status)}
                    data-qa="accountSelectLink"
                    data-testid="accountSelectLink"
                  >
                    Select
                    <ChevronRightIcon className="h-5 w-5 ml-3" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* No results found / Error searching accounts */}
      {!accountSearchResults.length && displayMessage(searchState)}
      {searchState === SearchState.InProgress && (
        <div className="text-center pt-40">
          <Loader show={true} className="m-auto w-8 h-8" spin />
        </div>
      )}
    </div>
  );
};

export default AccountLookupResults;
