import { OrderEntryStatusModal } from 'components/order-entry/OrderEntryStatusModal/OrderEntryStatusModal';
import { useAuth } from 'providers/AuthProvider';
import { useManufacturingLocation } from 'providers/ManufacturingLocationProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useToast } from 'providers/ToastProvider';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { verifyAddress } from 'shared/api/address-verification.api';
import { ToastNotificationType } from 'shared/enums';
import { convertLocalOrderInputToOrder } from 'shared/helpers/order-detail/order-detail.helper';
import { generateNewRmaOrder, getDefaultAddress } from 'shared/helpers/order-entry/order-entry.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { CreatedOrder } from 'shared/models';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';

/**
 * Props for the SplittingCaseModal component.
 */
interface SplittingCaseModalProps {
  /**
   * Callback function invoked when the modal is closed.
   */
  onClose: () => void;
  /**
   * Message displayed in the modal.
   */
  message: string;
  /**
   * The target order to split.
   */
  targetOrder: CreatedOrder;
  /**
   * Array of selected product IDs.
   */
  selectedProducts: string[];
  /**
   * Indicates whether the case is new or pending.
   */
  isNewOrPendingCase: boolean;
}

/**
 * The SplittingCaseModal component displays a modal for splitting a case into separate cases or creating an RMA case.
 * @param onClose - Callback function invoked when the modal is closed.
 * @param message - Message displayed in the modal.
 * @param targetOrder - The target order to split.
 * @param selectedProducts - Array of selected product IDs.
 * @param isNewOrPendingCase - Indicates whether the case is new or pending.
 * @returns JSX element representing the SplittingCaseModal component.
 */
export const SplittingCaseModal: React.FC<SplittingCaseModalProps> = ({
  targetOrder,
  selectedProducts,
  onClose,
  message,
  isNewOrPendingCase,
}) => {
  const { user } = useAuth();
  const toast = useToast();
  const currentUserName = user?.displayName || '';
  const onCloseRef = useRef(onClose);

  const { order, products, orderId, originalOrderIsLegacy } = useContext(OrderModuleContext);
  const { setOrderData } = useContext(OrderModuleActionsContext);
  const [isLoading, setIsLoading] = useState(false);
  const { patchOrder } = useContext(OrderModuleActionsContext);
  const addOrder = useBundleSplitCaseStore(state => state.addOrder);
  const updateOrder = useBundleSplitCaseStore(state => state.updateOrder);
  const checkIfOrderIsExisting = useBundleSplitCaseStore(state => state.checkIfOrderIsExisting);
  const { fetcher: validateAddress } = useLazyQueryFetcher(verifyAddress);
  const { getManufacturingLocationByName } = useManufacturingLocation();
  const title = useMemo(() => {
    return isNewOrPendingCase ? 'Creating RMA case' : 'Splitting into separate cases';
  }, [isNewOrPendingCase]);

  useEffect(() => {
    onCloseRef.current = onClose;
  }, [onClose]);

  useEffect(() => {
    if (!targetOrder) return;
    const generateNewRmaOrderFn = async () => {
      try {
        setIsLoading(true);
        // As per LMS1-5203, enclosed items, coupons and notes from the original order do not get copied over.
        const targetOrderToCopyOver: CreatedOrder = {
          ...targetOrder,
          enclosedItems: [],
          coupons: [],
          notes: undefined,
        };
        const address = targetOrderToCopyOver.shipping?.address;

        /**
         * It was established in LMS1-7584 that address information should not be carried over for legacy RMA orders.
         * Here, we will reset the shipping address to the default value so that way,
         * the UI will see the address is empty and will select the default address for this provider.
         * This is to avoid a potential invalid address error, when attempting to validate this address further down below.
         */
        if (originalOrderIsLegacy && targetOrderToCopyOver.shipping) {
          targetOrderToCopyOver.shipping.address = {
            ...getDefaultAddress(),
            __typename: 'Address',
          };
        } else if (targetOrderToCopyOver.shipping && address && !address.country) {
          const response = await validateAddress(address);
          if (!response.verifications.delivery.success) {
            throw new Error('Address not valid');
          } else {
            targetOrderToCopyOver.shipping.address = { ...address, country: response.country };
          }
        }

        const result = await generateNewRmaOrder({
          targetOrder: targetOrderToCopyOver,
          order,
          currentUserName,
          selectedProducts,
          products,
          originalOrderIsLegacy,
        });

        result.orderItems = result.orderItems.map(item => {
          /**
           * If there is no manufacturingLocationId (can happen with legacy cases LMS1-6637),
           * look up by location name and set.
           */
          if (!item.manufacturingLocationId) {
            const location = getManufacturingLocationByName(item.manufacturingLocation);
            if (location?.locationId) item.manufacturingLocationId = location.locationId;
          }
          return item;
        });

        setIsLoading(false);
        if (!result) throw new Error('Failed to generate new RMA order');
        onCloseRef.current();

        if (isNewOrPendingCase && checkIfOrderIsExisting(result.orderNumber || '')) {
          return toast.notify('This case already exists in the bundle', ToastNotificationType.Error);
        }

        if (isNewOrPendingCase) {
          patchOrder(result);
          updateOrder({ id: orderId, isInvalid: false });
        } else {
          const updateOrder = { ...order };

          patchOrder(updateOrder);
          addOrder(result);
        }

        /**
         * We need below logic to properly set the correct value for customized attributes in the order, after we redirects to the order entry page.
         * Check the line no. 151-155 in AggregateAttributeTypeEnum component for more details.
         */
        const orderData = convertLocalOrderInputToOrder(result);
        setOrderData(orderData);
      } catch (err) {
        const error = err as Error;
        setIsLoading(false);
        toast.notify(error.message, ToastNotificationType.Error);
      }
    };
    generateNewRmaOrderFn();
  }, [
    addOrder,
    checkIfOrderIsExisting,
    currentUserName,
    isNewOrPendingCase,
    order,
    orderId,
    patchOrder,
    products,
    selectedProducts,
    targetOrder,
    toast,
    updateOrder,
    validateAddress,
    getManufacturingLocationByName,
    originalOrderIsLegacy,
    setOrderData,
  ]);

  return <>{isLoading && <OrderEntryStatusModal onClose={onClose} title={title} message={message} isLoading />}</>;
};
