import { LocalOrderProductAttributeInput } from 'shared/models';
import { Attribute, ProductAttribute, ProductAttributeValueType } from '../../../API';
import { AttributeType } from '../../enums';

/**
 * checks if an attribute is a quantity attribute
 * @param attribute - attribute of interest
 */
export const isQuantityAttribute = (attribute: Pick<ProductAttribute, 'type' | 'name'>) => {
  return attribute.type === AttributeType.Quantity && attribute.name === AttributeType.Quantity;
};

/**
 * checks if an attribute is a pricing attribute
 * @param attribute - attribute to check if it is a pricing attribute
 */
export const isTierPricingAttribute = (
  attribute: Pick<Attribute, 'type'> & { attributeValueType: Attribute['attributeValueType'] | undefined }
) => {
  return (
    attribute.type === AttributeType.TierPricing && attribute.attributeValueType === ProductAttributeValueType.Number
  );
};

/**
 * Return the quantity of the attribute.
 * If the attribute is a quantity attribute or Tier Pricing attribute, return the value as the quantity.
 * @param attribute - attribute to get quantity from.
 * @returns the quantity.
 */
export const getAttributeQuantity = (
  attribute: Pick<LocalOrderProductAttributeInput, 'type' | 'name' | 'value'> & {
    attributeValueType: ProductAttributeValueType | undefined;
  }
) => {
  if (!attribute.value) return 0;
  if (isQuantityAttribute(attribute) || isTierPricingAttribute(attribute)) return parseInt(attribute.value) ?? 1;
  return 1;
};
