import { AlertId, Order, OrderItem, OrderLinkType, ReturnType, UpdateCaseAlertStatusesInput } from 'API';
import { ROUTES } from 'components/navigation/Constants';
import { CreateRmaErrorModal } from 'components/order-entry/RMA/Modals/CreateRmaErrorModal';
import { OrderAccountDetails } from 'components/order-entry/RightPanel/AlertCard/AlertCard';
import { useInvoiceLocationState } from 'hooks/useInvoiceLocationState';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { useAuth } from 'providers/AuthProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { OverlayLoaderContext } from 'providers/OverlayLoaderProvider';
import { ShortcutKeysContext } from 'providers/ShortcutKeysProvider';
import { ToastContext } from 'providers/ToastProvider';
import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { updateCaseAlertStatuses } from 'shared/api/case-alerts.api';
import { getOrder } from 'shared/api/order.api';
import { getProductClassifications } from 'shared/api/product.api';
import { NEW_CASE } from 'shared/constants/constants';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ShortCutKeys } from 'shared/constants/shortcut-keys.constants';
import { ErrorMessage, ToastNotificationType } from 'shared/enums';
import { ACTIONS } from 'shared/enums/permission';
import { convertOrderToLocalOrderInputWithCoupons, orderLocked } from 'shared/helpers/order-detail/order-detail.helper';
import {
  getBaseProductOrderItem,
  getBundleSuccessToastMessage,
  handleSubmitOrder,
} from 'shared/helpers/order-entry/order-entry.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { LocalOrderInput } from 'shared/models';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { HandleSubmitOrderAlertData } from 'types/order-entry';
import { shallow } from 'zustand/shallow';
import AccountDetail from '../AccountDetail/AccountDetail';
import Products from '../Products/Products/Products';
import { RelatedCases } from '../RelatedCases/RelatedCases';
import { AlertPanel } from '../RightPanel/AlertPanel';
import { RightPanel } from '../RightPanel/RightPanel/RightPanel';
import { ExchangeCaseCreationModal } from './ExchangeCaseCreatedModal';

const defaultCreateRmaModalData: HandleSubmitOrderAlertData = {
  title: '',
  description: '',
  isExchange: false,
};

/**
 * Represents a ref for the OrderEntryContainer component, allowing access to its submitHandler function.
 */
interface OrderEntryContainerRef {
  /**
   * A function to trigger the submission of the order entry process.
   *
   * @returns A promise that resolves when the submission process is complete.
   */
  submitHandler: () => Promise<void>;
}

/**
 * Props for the OrderEntryContainer component.
 */
interface OrderEntryContainerProps {
  /**
   * The unique identifier of the order.
   */
  orderId: string;

  /**
   * The order number representing the order.
   * Optional, used for displaying additional information.
   */
  orderNumber?: string;

  /**
   * Indicates whether the order is part of a bundle split case.
   * Optional, influences the processing or display of the order.
   */
  isBundleSplitCase?: boolean;
}

/**
 * Manages the order entry process, including fetching and managing order data, handling user interactions,
 * and facilitating the order entry process.
 *
 * @param props - The props for the OrderEntryContainer component.
 * @returns A React component representing the OrderEntryContainer.
 */
export const OrderEntryContainer = React.forwardRef<OrderEntryContainerRef, OrderEntryContainerProps>(
  ({ isBundleSplitCase, orderId, ...props }, ref) => {
    const [createRmaErrorModalData, setCreateRmaErrorModalData] = useState(defaultCreateRmaModalData);
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const alert = useContext(AlertModalContext);
    const overlayLoader = useContext(OverlayLoaderContext);
    const toast = useContext(ToastContext);
    const [showLoadingExchangeModal, setShowLoadingExchangeModal] = useState(false);
    const [exchangePendingOrders, setExchangePendingOrders] = useState<Array<Order>>([]);
    const isNewCasePage = pathname.includes(ROUTES.NEW_ORDER);
    const { fetcher } = useLazyQueryFetcher(updateCaseAlertStatuses);

    const orderLength = useBundleSplitCaseStore(state => state.orders.length);
    const { order, orderStatus, noPatientInfoChecked, classifications, caseAlerts, onSubmitAlertCount } =
      useContext(OrderModuleContext);
    const {
      patchOrder,
      setOrderLoading,
      isValidOrder,
      setProductClassifications,
      setOrderStatus,
      setSubmitAlertCount,
      setOrderData,
    } = useContext(OrderModuleActionsContext);

    const { user } = useAuth();
    const currentUserName = useMemo(() => {
      return user?.displayName || '';
    }, [user?.displayName]);
    const currentTechId = useMemo(() => {
      return user?.employeeId || '';
    }, [user?.employeeId]);

    const { checkIfBundleSplitIsActive, bundledSplitCases, findOrder, addInitialOrderData } = useBundleSplitCaseStore(
      state => ({
        checkIfBundleSplitIsActive: state.checkIfBundleSplitIsActive,
        bundledSplitCases: state.bundledSplitCases,
        findOrder: state.findOrder,
        addInitialOrderData: state.addInitialOrderData,
      }),
      shallow
    );

    const shortcutKeyCtx = useContext(ShortcutKeysContext);
    const { state } = useLocation();
    const redirectPath = state?.redirectPath;
    const searchParams = new URLSearchParams(search);
    const { id: orderUpdateId } = useParams<{ id: string }>();
    const orderNumber = searchParams.get('orderNumber') || orderUpdateId || '';
    const isRedirectToOrderEntry = searchParams.has('isRedirectToOrderEntry');
    const isOrderUpdate = !!orderUpdateId;
    const {
      loading: classificationsLoading,
      error: classificationsError,
      data: classificationsData,
      initialized: classificationsInitialized,
    } = useQueryFetcher(getProductClassifications);
    const isSkipOrderQuery = !orderNumber || props.orderNumber === NEW_CASE || checkIfBundleSplitIsActive();
    const {
      loading,
      error: orderError,
      data: orderData,
      initialized,
    } = useQueryFetcher(getOrder, {
      orderNumber,
      skip: isSkipOrderQuery,
    });

    const invoiceLocationState = useInvoiceLocationState();

    const createCasePermission = useRoleBasedAccessControl(ACTIONS.CREATE_CASE);
    const editCasePermission = useRoleBasedAccessControl(ACTIONS.EDIT_CASE);

    /**
     * If we are in bundle split case mode, we need to find the order in context and patch it.
     */
    useEffect(() => {
      if (isOrderUpdate || !isBundleSplitCase || !orderId) return;
      const foundOrder = findOrder(orderId);
      if (!foundOrder) return;
      const { isInvalid, id, ...rest } = foundOrder;
      patchOrder(rest);
    }, [isBundleSplitCase, orderId, findOrder, patchOrder, isOrderUpdate]);

    useEffect(() => {
      if (orderUpdateId && !editCasePermission.allowUpdate) {
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
        navigate(-1);
        return;
      } else if (!orderUpdateId && !createCasePermission.allowCreate) {
        alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
        navigate(-1);
      }
    }, [alert, createCasePermission.allowCreate, editCasePermission.allowUpdate, navigate, orderUpdateId]);

    /**
     * Maintains alerts that are ready to be applied.
     */
    const alertsToApply: AlertId[] = useMemo(() => {
      const alerts: AlertId[] = [];
      let applyCaseAlertError = '';
      caseAlerts.forEach(caseAlert => {
        const { alertId, initialStatus, status } = caseAlert;
        const isValidAlert = alertId && status;
        if (!isValidAlert) {
          applyCaseAlertError =
            'One or more of the case alerts you selected is not valid and therefore was not applied.';
        } else if (initialStatus !== status) {
          // Only submits alerts where the statuses have changed.
          alerts.push({
            alertId,
            status,
          });
        }
      });
      if (applyCaseAlertError) {
        toast.notify(applyCaseAlertError, ToastNotificationType.Error);
      }
      return alerts;
    }, [caseAlerts, toast]);

    /**
     * Make sure we set the createdBy property to current user name on the order in context.
     */
    useEffect(() => {
      patchOrder({ createdBy: currentUserName });
    }, [currentUserName, patchOrder]);

    /**
     * Process classifications data once it is done loading.
     */
    const handleClassificationsEvent = useCallback(() => {
      if (classificationsLoading || !classificationsInitialized) return;
      if (classificationsError) {
        console.error('Error retrieving product classifications.', classificationsError);
        return;
      }
      if (classificationsData) {
        setProductClassifications(classificationsData);
      }
    }, [
      classificationsData,
      classificationsError,
      classificationsInitialized,
      classificationsLoading,
      setProductClassifications,
    ]);

    useEffect(() => {
      handleClassificationsEvent();
    }, [handleClassificationsEvent]);

    /**
     * Process order data once it is done being fetched.
     * and convert fetched order data to local order input.
     */
    useEffect(() => {
      const handleOrderEvent = async () => {
        // If isSkipOrderQuery is true, Don't do anything.
        if (isSkipOrderQuery) return;
        // We will only set the order loading to true if we are not skipping the order query.
        setOrderLoading(true);
        if (loading || !initialized) return;

        if (orderError) {
          return toast.notify(ErrorMessage.Order_Get_Fail, ToastNotificationType.Error);
        }

        try {
          setOrderData(orderData); // Raw order data response
          const newData = await convertOrderToLocalOrderInputWithCoupons(orderData, currentUserName);
          if (orderData && newData) {
            setOrderStatus(orderData.status);

            // By default, all existing case data will be transferred over, to enable case creation of pending orders.
            const existingDataToUse: LocalOrderInput = newData;

            // A new exchange case will have the linked order type of ExchangeNewCase.
            const isNewExchangeCase = newData.linkedOrder?.linkType === OrderLinkType.ExchangeNewCase;

            // As per LMS1-7205, if the case is a new exchange case, only basic information (billing account section + material name/restoration type) will be transferred over.
            if (isNewExchangeCase) {
              existingDataToUse.orderItems = newData.orderItems.map(getBaseProductOrderItem);
            }

            patchOrder(existingDataToUse);

            // By storing initial order data, We can easily validate with local input order value changed scenario in the edit case page
            addInitialOrderData(orderId, newData);
          }
        } catch (err) {
          console.error('Error converting order data:', err);
          toast.notify(ErrorMessage.Order_Get_Fail, ToastNotificationType.Error);
        } finally {
          setOrderLoading(false);
        }
      };
      handleOrderEvent();
    }, [
      currentUserName,
      initialized,
      loading,
      orderData,
      orderError,
      isSkipOrderQuery,
      patchOrder,
      setOrderLoading,
      setOrderStatus,
      toast,
      addInitialOrderData,
      orderId,
      setOrderData,
    ]);

    /**
     * isReturnExchange only applies for new cases page. not case edit.
     */
    const isReturnExchange = useMemo(() => {
      if (!order) return false;
      const isExchangeWithProductReturned = order.orderItems.some(item => {
        return item.returnType === ReturnType.Exchange;
      });
      return isNewCasePage && isExchangeWithProductReturned;
    }, [isNewCasePage, order]);

    const isReturnForCredit = !!order.orderItems?.find(data => data.returnType === ReturnType.ReturnForCredit);

    const handleRedirectAfterOrderSubmit = useCallback(
      (orderId = '', orderItems: OrderItem[] = [], errorMessage?: string) => {
        // If bundledSplitCases is not empty, that means, the current case is redirected from the bundle split page.
        // Currently, this condition used for exchange pending cases. If the case is redirected from the bundle split page
        if (bundledSplitCases.length) {
          const successMessage = getBundleSuccessToastMessage(bundledSplitCases);
          toast.notify(successMessage, ToastNotificationType.Success);
          return navigate(ROUTES.ORDER_ENTRY);
        }

        // If we are in case edit, we need to redirect to the previous page. But if isRedirectToOrderEntry is exist, we need to redirect to the order entry page.
        if (orderUpdateId && !isRedirectToOrderEntry) {
          toast.notify(`Successfully updated case details`, ToastNotificationType.Success);
          const redirectedFromInvoice = redirectPath?.includes('invoice');
          // Cannot access the invoice page again if no patient information is available.
          if (noPatientInfoChecked && redirectedFromInvoice) {
            navigate(ROUTES.ROOT);
          } else {
            navigate(redirectPath || -1, {
              state: isOrderUpdate ? invoiceLocationState : null, // if we are in case edit, we need to pass the state to the next page, The reason is, Currently Invoicing Bundle split page expects this state to work properly.
            });
          }
        } else {
          navigate(
            {
              pathname: ROUTES.ORDER_ENTRY,
            },
            {
              state: { orderId, errorMessage, orderItems, isReturnForCredit },
            }
          );
        }
      },
      [
        orderUpdateId,
        isRedirectToOrderEntry,
        toast,
        redirectPath,
        noPatientInfoChecked,
        navigate,
        isOrderUpdate,
        invoiceLocationState,
        isReturnForCredit,
        bundledSplitCases,
      ]
    );

    const submitOrder = useCallback(async () => {
      return handleSubmitOrder({
        order,
        orderUpdateId,
        overlayLoader,
        currentUserName,
        toast,
        setCreateRmaErrorModalData,
        hasAppliedAlerts: alertsToApply.length > 0,
      });
    }, [alertsToApply, order, orderUpdateId, overlayLoader, currentUserName, toast]);

    const handlePostSubmit = useCallback(
      async (orderId = '', orderItems: OrderItem[] = [], isRedirectAfterOrderSubmit = false) => {
        try {
          // If there aren't any alerts to apply, no need to submit them (the service will return an error).
          if (alertsToApply.length > 0) {
            const updateCaseAlertStatusesRequest: UpdateCaseAlertStatusesInput = {
              alertIds: alertsToApply,
              appliedBy: currentUserName,
              appliedByTechId: currentTechId,
              orderNumber: orderId,
            };
            await fetcher(updateCaseAlertStatusesRequest);
          }
        } catch (err) {
          console.error('Failed to submit case alert selections:', err);
          toast.notify(`Failed to submit your case alert selections`, ToastNotificationType.Error);
        } finally {
          if (isRedirectAfterOrderSubmit) {
            handleRedirectAfterOrderSubmit(orderId, orderItems);
          }
          overlayLoader.hide();
        }
      },
      [alertsToApply, currentTechId, currentUserName, handleRedirectAfterOrderSubmit, overlayLoader, toast, fetcher]
    );

    const caseAlertInfo = useMemo(
      (): OrderAccountDetails => ({
        billingAccountId: order.billingAccountId,
        patientFirstName: order.patientFirstName || '',
        patientLastName: order.patientLastName || '',
        providerId: order.providerId,
        noPatientInfo: noPatientInfoChecked,
      }),
      [order, noPatientInfoChecked]
    );

    /**
     * Handles close of the create RMA error modal.
     */
    const handleCreateRmaErrorModalClose = () => {
      setCreateRmaErrorModalData(defaultCreateRmaModalData);
    };

    /**
     * Handles submitting an order.
     * @returns void
     */
    const submitHandler = useCallback(async () => {
      setSubmitAlertCount(onSubmitAlertCount + 1);

      // Takes the first order error, if one exists.
      const submitError = isValidOrder();
      if (submitError) {
        const { title, message } = submitError;
        alert.show(title, message);
        return;
      }

      // If the order is a return/exchange, shows a confirmation modal.
      if (isReturnExchange) {
        setShowLoadingExchangeModal(true);
      } else {
        overlayLoader.show('Saving order');
      }

      const result = await submitOrder();
      // If the result is undefined, then there is no need to continue.
      if (!result) {
        // Closed the loading exchange modal, if it is open.
        setShowLoadingExchangeModal(false);
        return;
      }

      const resultOrderNumber = result.orderNumber;
      const resultOrderItems = result.orderItems || [];
      if (resultOrderNumber) {
        const isRedirectAfterOrderSubmit = isReturnExchange ? false : true;
        await handlePostSubmit(resultOrderNumber, resultOrderItems, isRedirectAfterOrderSubmit);
      }
      if (isReturnExchange) {
        // If this is an exchange order and the resulting order is successfully created, we need to redirect to the order entry page.
        // Will be handled in the ExchangeCaseCreationModal component.
        setExchangePendingOrders([result]);
        setShowLoadingExchangeModal(false);
      }
    }, [
      setSubmitAlertCount,
      onSubmitAlertCount,
      isValidOrder,
      isReturnExchange,
      submitOrder,
      alert,
      overlayLoader,
      handlePostSubmit,
    ]);

    /**
     * We need to set the submit handler in the ref so that we can invoke it from the tab header.
     * We need to re-run this effect when the order length changes, so that we can always get the latest submit handler.
     */
    useImperativeHandle(
      ref,
      () => {
        const defaultSubmitHandler = () => {
          console.warn('No submit handler defined!, orderLength', orderLength);
          return Promise.resolve();
        };
        return {
          submitHandler: orderLength ? submitHandler : defaultSubmitHandler,
        };
      },
      [submitHandler, orderLength]
    );

    useEffect(() => {
      if (shortcutKeyCtx.keyPressed === ShortCutKeys.AltEnter) {
        submitHandler();
        shortcutKeyCtx.reset();
      }
    }, [shortcutKeyCtx, shortcutKeyCtx.keyPressed, submitHandler]);

    const isOrderLocked = useMemo(() => {
      return !isNewCasePage && orderNumber && orderLocked(orderStatus);
    }, [isNewCasePage, orderNumber, orderStatus]);

    return (
      <>
        {isOrderLocked && <div className="h-full absolute inset-0 bg-gray-50 z-10 opacity-60 cursor-not-allowed"></div>}
        <div className="flex-1 relative z-0 flex overflow-hidden">
          <main className="flex-1 relative z-0 focus:outline-none bg-gray-50">
            <div className="flex flex-col absolute inset-0 pb-2">
              <AccountDetail />
              <Products
                isClassificationsLoading={classificationsLoading || !classifications.length}
                isOrderUpdate={orderUpdateId ? true : false}
              />
            </div>
          </main>
          <aside className="border-l bg-white border-gray-400 overflow-y-auto">
            <RightPanel />
          </aside>

          <aside className="border-l bg-gray-50 border-gray-400 overflow-y-auto">
            <RelatedCases isEditMode={!!orderUpdateId} />
          </aside>

          <aside className="border-l bg-white border-gray-400 overflow-y-auto">
            <AlertPanel orderAccountDetails={caseAlertInfo} />
          </aside>
        </div>
        {createRmaErrorModalData.title && (
          <CreateRmaErrorModal
            title={createRmaErrorModalData.title}
            onClose={handleCreateRmaErrorModalClose}
            isExchange={createRmaErrorModalData.isExchange ?? false}
            handleRedirectAfterOrderSubmit={handleRedirectAfterOrderSubmit}
          >
            {createRmaErrorModalData.description}
          </CreateRmaErrorModal>
        )}
        <ExchangeCaseCreationModal
          isProcessingExchange={showLoadingExchangeModal}
          exchangePendingOrders={exchangePendingOrders}
          setExchangePendingOrders={setExchangePendingOrders}
          isRedirectingToOrderEntry
        />
      </>
    );
  }
);
