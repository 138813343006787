import Loader from '../Loader/Loader';

/**
 * Props for the InitialLoader component.
 */
interface InitialLoaderProps {
  /** The text to display alongside the loader. */
  text?: string;
}

/**
 * Component for displaying an initial loader with optional text.
 *
 * @param text - The text to display alongside the loader.
 * @returns JSX.Element - The rendered component.
 */
export const InitialLoader: React.FC<InitialLoaderProps> = ({ text }) => {
  return (
    <div className="h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto">
        <div className="flex min-h-40 bg-white p-6 gap-2">
          <Loader show={true} className="px-1 text-white" spin />
          {text && <div className="text-gray-800 text-xl"> {text}</div>}
        </div>
      </div>
    </div>
  );
};
