import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { ProductAttributeValueType, ProductFullAttribute, TechnicalPreference } from 'API';
import classNames from 'classnames';
import SearchableDropdown from 'components/common/SearchableDropdown';
import { OrderModuleContext } from 'providers/OrderModuleProvider';
import { useContext, useMemo } from 'react';
import { SpecialOrderParts } from 'shared/enums/special-order-parts';
import { LocalOrderProductAttributeInput } from 'shared/models';
import AddOnAndServiceItemValue from './AddOnAndServiceItemValue/AddOnAndServiceItemValue';

export interface AddOnAndServiceItemI {
  addOnInOrder: LocalOrderProductAttributeInput;
  addonsAndServices: ProductFullAttribute[];
  index: number;
  itemTeeth: string[];
  removeAddOnHandler: (index: number, addOnName: string, clearField: boolean, itemChildNames: string[]) => void;
  updateAddOnValueHandler: (
    addOn: string,
    index: number,
    addOnType?: ProductAttributeValueType,
    addOnValue?: string
  ) => void;
  addOnsAndServicesInOrder: LocalOrderProductAttributeInput[];
  technicalPreferences?: TechnicalPreference[];
}

/**
 * Generates a React component for a target add-on/service item.
 * @param props - Contains the data necessary for construction an add-on/service item.
 * @returns A React component for a given add-on/service item.
 */
export const AddOnAndServiceItem = (props: AddOnAndServiceItemI) => {
  const {
    addOnInOrder,
    addonsAndServices,
    addOnsAndServicesInOrder,
    index,
    itemTeeth,
    removeAddOnHandler,
    updateAddOnValueHandler,
    technicalPreferences,
  } = props;
  const { onSubmitAlertCount } = useContext(OrderModuleContext);

  /** Special order parts amount is the parent add-on for the special order parts add-on row.
   * We must apply special logic for this particular add-on, since it is controlled by the Special Order Parts restorative attribute.
   * An example of the Special Order Parts restorative attribute can be found by selecting Obsidian and White High Noble Implant Bridge and opting for "Single Stage" as a restorative attribute.
   * When this checkbox is selected and the order is submitted, the Special Order Parts add-on will be added the next time the order is loaded.
   * This add-on can only be removed if the Special Order Parts restorative attribute is unchecked.
   * There will be no minus button for removing it, as per a conversation with Josh and Emi from the UX team.
   * */
  const addOnName = addOnInOrder.name || '';
  const isSpecialOrderPartsAmount = addOnName === SpecialOrderParts.Amount;

  const targetAddOnAndServiceItem = addonsAndServices.find(addon => addon.name === addOnInOrder.name);
  const addOnsAndServicesInOrderNames = addOnsAndServicesInOrder?.map?.(addOnInOrder => addOnInOrder?.name) || [];

  // Retrieves all of the children names for all add-ons and services for this product.
  const allChildNames = useMemo(() => {
    return addonsAndServices.flatMap(attribute => {
      return (
        attribute.attributeRules?.flatMap(childAttribute => {
          return childAttribute.displayAttributes.flatMap(childAttributeName => {
            return childAttributeName.name;
          });
        }) || []
      );
    });
  }, [addonsAndServices]);

  // Retrieves all of the children names for this particular add-on.
  const itemChildrenNames = useMemo(() => {
    return (
      targetAddOnAndServiceItem?.attributeRules?.flatMap(childAttribute => {
        return childAttribute.displayAttributes.flatMap(childAttributeName => {
          return childAttributeName.name;
        });
      }) || []
    );
  }, [targetAddOnAndServiceItem]);

  // Determines whether the target add-on or service is a child.
  const isAddOnAChildAndShouldBeSkipped = useMemo(() => {
    let isAChild = false;
    addonsAndServices.forEach(attribute => {
      attribute.attributeRules?.forEach(rule => {
        rule.displayAttributes.forEach(displayAttribute => {
          if (displayAttribute.name === addOnInOrder.name) {
            isAChild = true;
          }
        });
      });
    });
    return isAChild;
  }, [addOnInOrder.name, addonsAndServices]);

  // If the target add-on or service is a child, skips it.
  if (isAddOnAChildAndShouldBeSkipped) return null;
  const getOptions = () => {
    return addonsAndServices
      .filter(addOns => {
        return (
          addOns.name === addOnInOrder.name ||
          (!addOnsAndServicesInOrderNames.includes(addOns.name) &&
            !allChildNames.includes(addOns.name) &&
            addOns.name !== SpecialOrderParts.Amount) // Special Order Parts Amount is only toggle-able via selecting the Special Order Parts attribute checkbox.
        );
      })
      .map(addOns => addOns.name);
  };

  return (
    <div className="flex flex-rows">
      <div
        data-testid={addOnName}
        // When addOnName is empty, default to half width
        className={classNames('w-1/2 mb-3 grid grid-flow-col gap-6', {
          'w-full': addOnName,
        })}
      >
        {/* Dropdown that displays the list of attributes that are add-ons/services */}
        <SearchableDropdown
          options={getOptions()}
          selected={addOnName}
          overwriteName={isSpecialOrderPartsAmount ? 'Special Order Parts' : undefined}
          onSelectedChange={selected => {
            updateAddOnValueHandler(selected, index);
            if (!selected) {
              removeAddOnHandler(index, addOnInOrder.name, false, itemChildrenNames);
            }
          }}
          dataQa="addOnSearchable"
          disabled={isSpecialOrderPartsAmount}
          onSubmitAlert={onSubmitAlertCount > 0}
          placeholder="Search"
          isRequired={false}
        />

        <AddOnAndServiceItemValue
          addOnInOrder={addOnInOrder}
          itemTeeth={itemTeeth}
          technicalPreferences={technicalPreferences}
          updateAddOnValueHandler={updateAddOnValueHandler}
          index={index}
          targetAddOnAndServiceItem={targetAddOnAndServiceItem}
          isRequired
        />

        {itemChildrenNames.map((itemChildName: string) => {
          // Adds input fields to the UI for children belonging to target parent add-on/service item.
          const targetChildAddOnAndServiceItem = addonsAndServices.find(addon => addon.name === itemChildName);
          const addOnAndServicesIndex = addOnsAndServicesInOrderNames.findIndex(
            addonName => addonName === itemChildName
          );
          const childAddOnInOrder = addOnsAndServicesInOrder.find(addon => addon.name === itemChildName);
          return (
            childAddOnInOrder && (
              <AddOnAndServiceItemValue
                addOnInOrder={childAddOnInOrder}
                itemTeeth={itemTeeth}
                technicalPreferences={technicalPreferences}
                updateAddOnValueHandler={updateAddOnValueHandler}
                index={addOnAndServicesIndex}
                targetAddOnAndServiceItem={targetChildAddOnAndServiceItem}
                key={childAddOnInOrder.name + '-' + addOnAndServicesIndex}
              />
            )
          );
        })}
      </div>
      {/* Button for removing a selected add-on/service. Special order parts must be removed by unselecting the Special Order Parts attribute checkbox (please see note above). */}
      {!isSpecialOrderPartsAmount && (
        <div className="w-8 flex-none leading-none pt-2">
          <button
            className="m-auto px-3 flex-none w-8"
            onClick={() => removeAddOnHandler(index, addOnInOrder?.name, true, itemChildrenNames)}
            id="removeAddOnButton"
            data-qa="removeAddOnButton"
            data-testid="removeAddOnButton"
          >
            <MinusCircleIcon className="text-gray-400 h-5 w-5 cursor-pointer" />
          </button>
        </div>
      )}
    </div>
  );
};
