import React from 'react';
import { SignInLayout } from 'layouts/SignInLayout';

/**
 * Renders a sign with Email page
 */
const SignInWithEmail: React.FC = () => {
  return (
    <SignInLayout showBackButton>
      <h1>Email Login Form</h1>
    </SignInLayout>
  );
};

export default SignInWithEmail;
