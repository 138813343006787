import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { ClientSideNavigation } from 'components/ClientSideNavigation/ClientSideNavigation';
import { InitialLoader } from 'components/common/InitialLoader/InitialLoader';
import { usePrevious } from 'hooks/use-previous';
import { usePreviousLocation } from 'hooks/usePreviousLocation';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { findRouteConfig } from 'shared/helpers/route.helper';
import { datadogRumSetUser } from 'shared/lib/datadog-rum';
import { AnalyticsService } from 'shared/services/analytics.service';
import { ROUTES } from './Constants';

/**
 * Root route component responsible for handling authentication events, setting document title, and handling navigation redirection.
 */
export const RootRoute = () => {
  const { instance: msalInstance } = useMsal();
  const location = useLocation();
  const params = useParams();
  const prevLocation = usePreviousLocation(location);
  const isRedirectPath = prevLocation.pathname === ROUTES.REDIRECT_PATH;
  const [isRedirecting, setIsRedirecting] = useState(isRedirectPath);
  const prevPathname = usePrevious(location.pathname);
  const prevHref = usePrevious(window.location.href);

  /**
   * Set the document title whenever the pathname changes.
   */
  useEffect(() => {
    const foundRoute = findRouteConfig(location.pathname, params);
    document.title = foundRoute?.title || 'Not Found';
  }, [location.pathname, params]);

  /**
   * Call Analytics `page` whenever the pathname changes.
   */
  useEffect(() => {
    if (location.pathname !== prevPathname) {
      AnalyticsService.page({ path: location.pathname, referrer: prevHref });
    }
  }, [location.pathname, prevHref, prevPathname]);

  /**
   * Set up event callbacks for authentication events and handle redirection state.
   */
  useEffect(() => {
    const eventId = msalInstance.addEventCallback((event: EventMessage) => {
      if (event.eventType === EventType.LOGIN_SUCCESS) {
        const payload = event.payload as AuthenticationResult | null;
        if (payload) {
          msalInstance.setActiveAccount(payload.account);
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        datadogRumSetUser(null);
        AnalyticsService.reset();
      }

      if (event.eventType === EventType.HANDLE_REDIRECT_END) {
        setIsRedirecting(false);
      }
    });

    return () => {
      if (eventId) {
        msalInstance.removeEventCallback(eventId);
      }
    };
  }, [location.pathname, msalInstance]);

  return (
    <ClientSideNavigation instance={msalInstance}>
      {isRedirecting ? <InitialLoader /> : <Outlet />}
    </ClientSideNavigation>
  );
};
