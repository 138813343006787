import Loader from 'components/common/Loader/Loader';
import { useToast } from 'providers/ToastProvider';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToastNotificationType } from 'shared/enums';
import { printInvoice, printShippingLabel } from 'shared/helpers/printing/print.helper';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import { InvoicedOrder } from 'shared/models/invoicing-shipping';
import { cn, convertToCurrency } from 'shared/utils';
import { InvoiceCaseSelectModal, InvoiceCaseSelectModalProps } from './InvoiceCaseSelectModal';

/**
 * Props interface for the InvoiceSubmissionSummary component.
 */
interface InvoiceSubmissionSummaryProps {
  invoicedOrders: InvoicedOrder[]; //Array of invoiced orders.
}
/**
 * Component to display a summary of invoiced orders and provide options to reprint invoice or shipping label.
 * @param invoicedOrders - Array of invoiced orders.
 */
const InvoiceSubmissionSummary: React.FC<InvoiceSubmissionSummaryProps> = ({ invoicedOrders }) => {
  const [showModal, setShowModal] = useState(false);
  const [reprintType, setReprintType] = useState<InvoiceCaseSelectModalProps['type']>('invoice');
  const [isReprintingInvoice, setIsReprintingInvoice] = useState(false);
  const [isReprintingShippingLabel, setIsReprintingShippingLabel] = useState(false);

  const enableInvoiceBtn = invoicedOrders.some(order => !order.disableInvoiceReprint);
  const enableShippingLabelReprint = invoicedOrders.some(order => !order.disableShippingLabelReprint);

  const toast = useToast();

  /**
   * Handles the confirmation action.
   * Triggers the reprint submit handler and closes the modal if successful.
   */
  const reprintSubmitHandler = async (orderNumber: string, type: InvoiceCaseSelectModalProps['type']) => {
    const isInvoiceReprint = type === 'invoice';
    const reprintFetcher = isInvoiceReprint ? printInvoice : printShippingLabel;
    const foundOrder = invoicedOrders.find(order => order.orderNumber === orderNumber);

    if (!foundOrder) {
      toast.notify(`Order ${orderNumber} not found in invoicedOrders.`, ToastNotificationType.Error);
      return false;
    }

    if (!foundOrder.invoiceTotalAmount) {
      toast.notify(
        `${isInvoiceReprint ? 'Invoice' : 'Shipping Label'} is not generated yet. Please try again after some time.`,
        ToastNotificationType.Error
      );
      return false;
    }

    try {
      await reprintFetcher(orderNumber);
      return true;
    } catch {
      toast.notify(
        `${isInvoiceReprint ? 'Error printing invoice' : 'Error printing shipping label'}. Please try again later.`,
        ToastNotificationType.Error
      );
      return false;
    }
  };

  /**
   * Handles the reprint action for a single order or opens the modal for selecting a case to reprint.
   * If there's only one order, directly triggers the reprint action, otherwise opens the modal.
   * @param reprintType - Type of the reprint action ('invoice' or 'shippingLabel').
   */
  const onReprintHandle = async (reprintType: InvoiceCaseSelectModalProps['type']) => {
    if (invoicedOrders.length === 1) {
      const setLoading = reprintType === 'invoice' ? setIsReprintingInvoice : setIsReprintingShippingLabel;
      setLoading(true);
      await reprintSubmitHandler(invoicedOrders[0].orderNumber, reprintType);
      setLoading(false);
    } else {
      setReprintType(reprintType);
      setShowModal(true);
    }
  };

  return (
    <div className=" bg-white p-6 shadow rounded-lg">
      <div className="text-gray-900 font-medium text-base mb-4">Last Invoiced</div>
      <div className="flex flex-col gap-4">
        {invoicedOrders.map(order => (
          <div
            key={order.orderNumber}
            className={cn('flex flex-col gap-1 justify-start items-start', {
              'flex-row justify-between items-center': !order.invoiceTotalAmount,
            })}
          >
            <div className="text-sm text-gray-900 font-medium">
              Case #
              <Link
                to={getOrderDetailPath(order.orderNumber)}
                className="text-indigo-600 underline focus:text-indigo-900 ml-1"
                data-qa="orderIdLink"
              >
                {order.orderNumber}
              </Link>
            </div>

            {order.invoiceTotalAmount ? (
              <div className="text-sm text-gray-500" data-qa="invoiceTotalLabel">
                Invoice Total {convertToCurrency(order.invoiceTotalAmount)}
              </div>
            ) : (
              <div className="text-sm text-gray-500 flex items-center gap-1">
                <Loader show spin className="w-4 h-4" />
                Please wait...
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="flex gap-4 mt-6">
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-qa="reprintInvoiceButton"
          onClick={() => {
            onReprintHandle('invoice');
          }}
          disabled={!enableInvoiceBtn || isReprintingInvoice}
        >
          Reprint Invoice
        </button>
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          data-qa="reprintShippingLabelButton"
          onClick={() => {
            onReprintHandle('shippingLabel');
          }}
          disabled={!enableShippingLabelReprint || isReprintingShippingLabel}
        >
          Reprint Shipping Label
        </button>
      </div>
      {showModal && (
        <InvoiceCaseSelectModal
          type={reprintType}
          reprintSubmitHandler={reprintSubmitHandler}
          invoicedOrders={invoicedOrders}
          onClose={() => setShowModal(false)}
        />
      )}
    </div>
  );
};

export default InvoiceSubmissionSummary;
