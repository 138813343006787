import { Brand, BrandDaysInLab, OverrideFieldType, ProductFullAttribute } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import Input from 'components/common/Input/Input';
import Label from 'components/common/Label';
import NumberInput from 'components/common/NumberInput/NumberInput';
import { getDefaultLabOriginId } from 'configurations/DefaultLabOrigin';
import { usePrevious } from 'hooks/use-previous';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { DropdownModel, LocalOrderItemInput, LocalOrderProductAttributeInput } from 'shared/models';
import ConfigService from 'shared/services/config.service';

enum OverrideField {
  ProductionFacility = 'productionFacility',
  DaysInLab = 'daysInLab',
}

interface IProps {
  productDepartmentAttribute: ProductFullAttribute;
  orderItem: LocalOrderItemInput;
  productBrands: Brand[];
  isOrderUpdate?: boolean;
}

/**
 * Production Facility:
 * Defaults to the production facility associated whth the lab that the billing account id belongs to.
 * On edit, should load the production facility that was set during case creation.
 * Department:
 * Defaults to the department attribute default value coming from Product Admin (if available).
 * On edit, should load the department set during case creation.
 * Days In Lab:
 * Is set to the days in lab assigned to the product for the specific manufacturing location (production facility).
 * Days in lab is updated when Add-Ons and/or Services are added that have days in lab set in Product Admin.
 * On edit, should load the values that were set during case creation.
 * @param productBrands - The list of product brands.
 * @param productDepartmentAttribute - The product department attribute.
 * @param orderItem - The order item.
 * @param isOrderUpdate - Indicates whether the component is used for updating an existing order. (optional)
 * @returns JSX element representing the ProductionFacility component.
 */
const ProductionFacility: React.FC<IProps> = ({
  productBrands,
  productDepartmentAttribute,
  orderItem,
  isOrderUpdate,
}) => {
  const { updateOrderItem } = useContext(OrderModuleActionsContext);
  const { order, onSubmitAlertCount, isDigitalOrder } = useContext(OrderModuleContext);
  const manufacturingLocation = orderItem.manufacturingLocation;
  const selectedDaysInLab = orderItem.daysInLab;
  const orderItemId = orderItem.id;
  const addOnString = JSON.stringify(orderItem.addOns);
  const attributesString = JSON.stringify(orderItem.attributes);
  const initialLoadRef = useRef(true);

  const selectedProductionFacility = useMemo(() => {
    return {
      primaryLabel: manufacturingLocation,
      value: manufacturingLocation,
    };
  }, [manufacturingLocation]);

  //setting attribute defaultValue value to department field(readonly)
  const selectedDepartmentValue = useMemo<string>(() => {
    if (productDepartmentAttribute.defaultValue) {
      return productDepartmentAttribute.defaultValue;
    } else {
      return '';
    }
  }, [productDepartmentAttribute.defaultValue]);

  const daysInLabAttributeRecord = useMemo(() => {
    const result: { [key: string]: Record<string, BrandDaysInLab> } = {};
    productBrands.forEach(brand => {
      const brandProduct = brand.products[0]; // This brand products array always return the single product only
      result[brand.brandName.toLowerCase()] = brandProduct.attributes.reduce((acc, attribute) => {
        if (attribute.daysInLab) {
          acc[attribute.name.toLowerCase()] = attribute.daysInLab;
        }
        return acc;
      }, {} as Record<string, BrandDaysInLab>);
    });

    return result;
  }, [productBrands]);

  /**
   * Returns the days in lab for a target production facility based on the matching product in the productBrands response and the order type (i.e. digital).
   * @param productionFacility - The target production facility.
   * @returns the days in lab for the target production facility.
   */
  const getDaysInLabForProductionFacility = useCallback(
    (productionFacility: string) => {
      const productBrand = productBrands.find(b => b.brandName.toLowerCase() === productionFacility.toLowerCase());
      const targetProduct = productBrand?.products.find(product => product.productCode === orderItem.productCode);
      const daysInLabOptions = targetProduct?.daysInLab;
      // Use DefaultDaysInLab in Settings if no days in lab is set for the product. See comment from Quenton in LMS1-6335.
      const defaultDaysInLab = Number(ConfigService.getConfigValue('Settings', 'DefaultDaysInLab')) || 0;
      if (isDigitalOrder) return daysInLabOptions?.digital ?? defaultDaysInLab;
      return daysInLabOptions?.external ?? defaultDaysInLab;
    },
    [orderItem.productCode, productBrands, isDigitalOrder]
  );

  const calculateTotalDaysInLab = useCallback(
    (items: LocalOrderProductAttributeInput[], productionFacility: string) => {
      const productionFacilityLowerCase = productionFacility.toLowerCase();
      return items.reduce((acc, item) => {
        const itemName = item.name.toLowerCase();
        const targetAttribute = daysInLabAttributeRecord[productionFacilityLowerCase]?.[itemName];
        // As per team conversation with Mazen, Quenton and Di, digital orders should use the internal field for days in lab.
        // Internal is being used in the place of the digital field, which we currently would not be able to set in Product Admin.
        const daysInLab = targetAttribute?.[isDigitalOrder ? 'internal' : 'external'] || 0;
        return acc + daysInLab;
      }, 0);
    },
    [daysInLabAttributeRecord, isDigitalOrder]
  );

  const getDaysInLabForAddedAttributes = useCallback(
    productionFacility => {
      const daysInLabProductionFacility = getDaysInLabForProductionFacility(productionFacility);
      /**
       * NOTE: using json stringify and parse so that we know when the arrary is updated.
       * Otherwise, if a property is updated in one of the objects in the array, this function won't re-render.
       */
      const addOns = JSON.parse(addOnString) as LocalOrderProductAttributeInput[];
      const attributes = JSON.parse(attributesString) as LocalOrderProductAttributeInput[];

      /**
       * As per the Quenton's comment in https://glidewell.atlassian.net/browse/LMS1-5933?focusedCommentId=75980,
       * We need to remove the duplicate attributes from the list to avoid double counting days in lab.
       */
      const removedDuplicateAttributes = attributes.filter((attribute, index) => {
        const isAttributeEmpty = !attribute.value;
        // If the attribute is empty, we don't want to count it in the days in lab.
        if (isAttributeEmpty) return false;
        const currentIndex = attributes.findIndex(a => a.name === attribute.name && a.type === attribute.type);
        return currentIndex === index;
      });

      const totalAddonDaysInLab = calculateTotalDaysInLab(addOns, productionFacility);
      const totalAttributesDaysInLab = calculateTotalDaysInLab(removedDuplicateAttributes, productionFacility);
      return daysInLabProductionFacility + totalAddonDaysInLab + totalAttributesDaysInLab;
    },
    [getDaysInLabForProductionFacility, calculateTotalDaysInLab, addOnString, attributesString]
  );

  const calculatedDaysInLab = useMemo(
    () => getDaysInLabForAddedAttributes(selectedProductionFacility.value),
    [selectedProductionFacility.value, getDaysInLabForAddedAttributes]
  );

  const prevCalculatedDaysInLab = usePrevious(calculatedDaysInLab);

  // The default days in lab for the currently selected production facility.
  const defaultDaysInLab = useMemo(
    () => getDaysInLabForAddedAttributes(selectedProductionFacility.value),
    [getDaysInLabForAddedAttributes, selectedProductionFacility.value]
  );

  const updateProductionFacility = useCallback(
    (productionFacility: string) => {
      const prodFacilityLowerCase = productionFacility.toLowerCase();
      const locationData = productBrands.find(data => data.brandName.toLowerCase() === prodFacilityLowerCase);
      const calculatedDaysInLab = getDaysInLabForAddedAttributes(productionFacility);
      updateOrderItem(orderItemId, {
        manufacturingLocation: productionFacility,
        manufacturingLocationId: locationData?.siteId ? +locationData.siteId : 0,
        daysInLab: calculatedDaysInLab,
      });
    },
    [productBrands, getDaysInLabForAddedAttributes, updateOrderItem, orderItemId]
  );

  /**
   * Returns the default site id.
   * Defaults to the brand associated with the default lab origin id if there is no originFacilityId set on the order yet.
   */
  const getDefaultProductBrand = useCallback(() => {
    const defaultBrand = productBrands.find(p => p.siteId === getDefaultLabOriginId()) || productBrands[0];

    // originFacilityId gets set on the order when a billing account id is searched.
    if (!order.originFacilityId) {
      return defaultBrand;
    }

    return productBrands.find(p => p.siteId === order.originFacilityId.toString()) || defaultBrand;
  }, [order.originFacilityId, productBrands]);

  useEffect(() => {
    if (order.originFacilityId && !selectedProductionFacility.value) {
      if (initialLoadRef.current && isOrderUpdate && orderItem?.manufacturingLocation) return; // if it order update and manufacturingLocation is already present ie, it is not a newly added orderItem, Then don't set default production facility

      // Update order with production facility
      const defaultProductionFacility = getDefaultProductBrand()?.brandName || '';
      updateProductionFacility(defaultProductionFacility);
    }
  }, [
    getDefaultProductBrand,
    isOrderUpdate,
    order.originFacilityId,
    orderItem?.manufacturingLocation,
    selectedProductionFacility.value,
    updateProductionFacility,
  ]);

  /**
   * Checks if the new selected value for days in lab overrides the default value.
   * If it does, adds it to the overrides array for this order.
   * @param newDaysInLab - The new value selected for days in lab.
   */
  const handleOverrideCheck = useCallback(
    (fieldName: string, defaultValue: string, newValue: string) => {
      // Filters the overwritten field to avoid duplicates. It will be re-evaluated in the next if statement.
      let orderOverrides =
        orderItem.overrides?.filter(override => {
          // If the field is production facility, also clears days in lab, since the UI will reset its value.
          const isProductionFacility = fieldName === OverrideField.ProductionFacility;
          return isProductionFacility
            ? override.field !== OverrideField.ProductionFacility && override.field !== OverrideField.DaysInLab
            : override.field !== fieldName;
        }) || [];
      // When changing the production facility, resets the days in lab override for this product, since that value would have reset.
      if (fieldName === OverrideField.ProductionFacility) {
        orderOverrides = orderOverrides.filter(orderOverride => orderOverride.field !== OverrideField.DaysInLab);
      }
      // If the value for days in lab does not match the default, adds it to the list of overwritten attributes.
      if (defaultValue && newValue !== defaultValue) {
        orderOverrides.push({
          new: newValue,
          old: defaultValue,
          type: OverrideFieldType.Default,
          field: fieldName,
        });
      }

      // Adds the updates overrides to the target order item.
      updateOrderItem(orderItemId, { overrides: orderOverrides });
    },
    [orderItem.overrides, orderItemId, updateOrderItem]
  );

  /**
   * Update the days in lab for the given product and production facility whenever order data changes that could affect the days in lab
   * (e.g. addons, services, providerId which reloads technical preferences, etc).
   * Should not run on initial load of case update page (why we have initialLoadRef) so that the set value on the order is used.
   * If 'isOrderUpdate' is true and 'initialLoadRef' is still true, it skips the update to avoid unintended re-execution.
   * If 'isOrderUpdate' is false, it directly updates the 'daysInLab'.
   */
  useEffect(() => {
    if (initialLoadRef.current && isOrderUpdate) {
      initialLoadRef.current = false;
      return;
    }

    if (calculatedDaysInLab !== prevCalculatedDaysInLab) {
      handleOverrideCheck(OverrideField.DaysInLab, String(defaultDaysInLab), String(calculatedDaysInLab));
      updateOrderItem(orderItemId, { daysInLab: calculatedDaysInLab });
    }
  }, [
    orderItemId,
    calculatedDaysInLab,
    isOrderUpdate,
    updateOrderItem,
    handleOverrideCheck,
    defaultDaysInLab,
    prevCalculatedDaysInLab,
  ]);

  /**
   * Handles production facility select.
   * @param e - The new production facility value.
   */
  const handleProductionFacilitySelect = (e: DropdownModel) => {
    updateProductionFacility(e.value);
    const defaultProductBrand = getDefaultProductBrand();
    // Update order with production facility
    const defaultProductionFacility = defaultProductBrand?.brandName || '';
    handleOverrideCheck(OverrideField.ProductionFacility, defaultProductionFacility, e.value);
  };

  /**
   * Handles days in lab select.
   * @param newDaysInLab - The new days in lab value.
   */
  const handleDaysInLabSelect = (newDaysInLab: string) => {
    if (initialLoadRef.current && isOrderUpdate) {
      return;
    }
    updateOrderItem(orderItemId, { daysInLab: +newDaysInLab });
    handleOverrideCheck(OverrideField.DaysInLab, String(defaultDaysInLab), newDaysInLab);
  };

  return (
    <div className="grid grid-cols-6 gap-6">
      <div className="col-span-3">
        <Dropdown
          label="Production Facility"
          isRequired
          selected={selectedProductionFacility}
          data={productBrands.map(p => ({ primaryLabel: p.brandName, value: p.brandName }))}
          setSelected={handleProductionFacilitySelect}
          onSubmitAlert={!!onSubmitAlertCount}
        />
      </div>
      <div className="col-span-2">
        <Input
          id="department"
          label="Department"
          name="department"
          type="text"
          value={selectedDepartmentValue}
          isRequired
          readOnly
        />
      </div>
      <div className="col-span-1">
        <Label required>Days in Lab</Label>
        <NumberInput
          id={`daysInLabInput`}
          value={selectedDaysInLab}
          min={1}
          onChange={handleDaysInLabSelect}
          onSubmitAlert={!!onSubmitAlertCount}
          isRequired
        />
      </div>
    </div>
  );
};

export default ProductionFacility;
