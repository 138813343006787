import { ErrorMessage } from 'shared/enums';

export const InternalErrorName = 'InternalError';
export const ACCOUNT_CREDIT_HOLD_ERROR_NAME = 'AccountCreditHoldError';
export const INVALID_INVOICE_STATUSES_ERROR_NAME = 'InvalidInvoiceStatusError';
export const CANNOT_INVOICE_CALL_CENTER_ERROR_NAME = 'CannotInvoiceCallCenterError';
export const CANNOT_INVOICE_CANCEL_DISCARD_ERROR_NAME = 'CannotInvoiceCancelDiscardError';
export const CANNOT_INVOICE_PATIENT_INFO_ERROR_NAME = 'CannotInvoicePatientInfoError';
export const CANNOT_INVOICE_MISSING_ALLOY_ERROR_NAME = 'CannotInvoiceMissingAlloyError';
export const CANNOT_INVOICE_DISCOUNT_AMOUNT_ERROR_NAME = 'CannotInvoiceDiscountAmountError';

/**
 * Create and return and error.
 * @param message - message for the error.
 * @param name - name of error to help identify the type of error.
 * @returns Error
 */
export const getError = (message: string, name?: string): Error => {
  const error = new Error(message);
  if (name) {
    error.name = name;
  }
  return error;
};

/**
 * represents an error encountered while invoicing an order
 */
export class CannotInvoiceStatusError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = INVALID_INVOICE_STATUSES_ERROR_NAME;
  }
}

/**
 * represents an error encountered while invoicing a call center
 */
export class CannotInvoiceCallCenterError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = CANNOT_INVOICE_CALL_CENTER_ERROR_NAME;
  }
}

/**
 * represents an error encountered while discarding invoice
 */
export class CannotInvoiceCancelDiscardError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = CANNOT_INVOICE_CANCEL_DISCARD_ERROR_NAME;
  }
}

/**
 * represents an error encountered if a patient info is absent
 */
export class CannotInvoicePatientInfoError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || `Missing patient info`;
    this.name = CANNOT_INVOICE_PATIENT_INFO_ERROR_NAME;
  }
}

/**
 * represents an error encountered if alloy is missing type or weight
 */
export class CannotInvoiceMissingAlloyError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || ErrorMessage.MISSING_ALLOY_TYPE_OR_WEIGHT;
    this.name = CANNOT_INVOICE_MISSING_ALLOY_ERROR_NAME;
  }
}

/**
 * represents an error encountered if account is on credit hold
 */
export class CannotInvoiceCreditHoldError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || 'Account on credit hold';
    this.name = ACCOUNT_CREDIT_HOLD_ERROR_NAME;
  }
}

/**
 * represents an error encountered if total discount amount is greater than total amount
 */
export class CannotInvoiceDiscountAmountError extends Error {
  constructor(message?: string) {
    super(message);
    this.message = message || ErrorMessage.TOTAL_DISCOUNT_AMOUNT_ERROR_MESSAGE;
    this.name = CANNOT_INVOICE_DISCOUNT_AMOUNT_ERROR_NAME;
  }
}
