const blackListedTextStrings = ['null', ''];

/**
 * checks if a given text is blacklisted
 *
 * @param text - text which needs to be checked whether blacklisted
 */
export const isValidCaseAlertData = (text: string): boolean => {
  return !blackListedTextStrings.includes(text.trim().toLowerCase());
};
