import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { FC } from 'react';
import { CaseHistoryAdditionalDetailsTracking } from 'shared/constants/case-detail.constants';

/**
 * Props of ShippingTrackingNumbers component.
 */
export interface ShippingTrackingNumbersProps {
  /**
   * The name of the shipping tracking option that will be rendered.
   */
  name: string;
  /**
   * An array of old tracking numbers.
   */
  oldTrackingNumbers: CaseHistoryAdditionalDetailsTracking[] | undefined;
  /**
   * An array of new tracking numbers.
   */
  newTrackingNumbers: CaseHistoryAdditionalDetailsTracking[] | undefined;
}

/**
 * Component for displaying shipping tracking numbers.
 * @param name - The name of the shipping tracking option that will be rendered.
 * @param oldTrackingNumbers - An array of old tracking numbers.
 * @param newTrackingNumbers - An array of new tracking numbers.
 * @returns A JSX element representing the ShippingTrackingNumbers component.
 */
export const ShippingTrackingNumbers: FC<ShippingTrackingNumbersProps> = ({
  name,
  oldTrackingNumbers,
  newTrackingNumbers,
}) => {
  /**
   * Boolean indicating whether there are old tracking numbers.
   */
  const hasOldTrackingNumbersLength = !!oldTrackingNumbers?.length;

  /**
   * Boolean indicating whether there are new tracking numbers.
   */
  const hasNewTrackingNumbersLength = !!newTrackingNumbers?.length;

  // If there are no old or new tracking numbers, return null
  if (!hasOldTrackingNumbersLength && !hasNewTrackingNumbersLength) return null;

  return (
    <div className="flex flex-col gap-2">
      <div className="font-semibold">{name}</div>
      <div className="flex flex-1 justify-between items-start">
        {hasOldTrackingNumbersLength && (
          <div className="flex flex-col gap-1">
            {oldTrackingNumbers.map(item => {
              return <div key={item.TrackingNumber}>{item.TrackingNumber}</div>;
            })}
          </div>
        )}
        {hasOldTrackingNumbersLength && hasNewTrackingNumbersLength && (
          <div className="mx-6">
            <ArrowRightIcon className="w-5 h-5" />
          </div>
        )}
        {hasNewTrackingNumbersLength && (
          <div className="flex flex-col gap-1">
            {newTrackingNumbers.map(item => {
              return <div key={item.TrackingNumber}>{item.TrackingNumber}</div>;
            })}
          </div>
        )}
      </div>
    </div>
  );
};
