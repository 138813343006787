import React, { useState } from 'react';
import Hotkeys from 'react-hot-keys';

/**
 * Props of the shortcut keys context.
 */
interface IShortcutKeys {
  /**
   * The key that was pressed.
   */
  keyPressed: string;

  /**
   * Function to reset the pressed key.
   */
  reset: () => void;
}

/**
 * Context for managing shortcut keys.
 */
export const ShortcutKeysContext = React.createContext<IShortcutKeys>({
  keyPressed: '',
  reset: () => {
    console.log();
  },
});

/**
 * Provider component for managing shortcut keys state.
 */
export const ShortcutKeysProvider: React.FC = ({ children }) => {
  const [key, setKey] = useState<string>('');
  const state = {
    keyPressed: key,
    reset: () => {
      setKey('');
    },
  };
  const keyUpHandler = (key: string) => {
    setKey(key);
  };

  return (
    <>
      <ShortcutKeysContext.Provider value={state}>
        <Hotkeys
          keyName="alt,alt+enter,alt+p,alt+c,alt+m"
          onKeyUp={keyUpHandler}
          filter={() => {
            return true;
          }}
        >
          {children}
        </Hotkeys>
      </ShortcutKeysContext.Provider>
    </>
  );
};
