import { ProviderStatus } from 'API';
import Dropdown from 'components/common/Dropdown/Dropdown';
import { OrderEntryAccountContext } from 'providers/OrderEntryAccountProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getProviderName } from 'shared/helpers/provider.helper';
import { DropdownModel } from 'shared/models';

/**
 * Props for the ProviderSelector component.
 */
interface ProviderProps {
  /**
   * Indicates whether to show a skeleton loader.
   */
  skeletonLoader?: boolean;
}

/**
 * The ProviderSelector component allows users to select a provider from a dropdown.
 * @param skeletonLoader - Indicates whether to display a skeleton loader while loading data.
 * @returns JSX element representing the ProviderSelector component.
 */
const ProviderSelector: React.FC<ProviderProps> = ({ skeletonLoader }) => {
  const [dropdownData, setDropdownData] = useState<DropdownModel[]>([]);
  const { account } = useContext(OrderEntryAccountContext);
  const { order, onSubmitAlertCount, originalOrderIsLegacy } = useContext(OrderModuleContext);
  const { patchOrder, isOrderItemExchangeReturnType } = useContext(OrderModuleActionsContext);

  useEffect(() => {
    if (account) {
      // Business logic: only show active providers.
      // Map to structure that Dropdown component is expecting.
      const data = account.providers
        .filter(provider => provider.providerStatus === ProviderStatus.Active)
        .map(provider => ({
          primaryLabel: getProviderName(provider),
          value: provider.providerId.toString(),
        }));
      setDropdownData(() => {
        return [{ primaryLabel: '', value: '' }, ...data];
      });
    }
  }, [account, order.providerId]);

  const onSelect = (selected: DropdownModel) => {
    const foundProvider = account?.providers.find(provider => provider.providerId.toString() === selected.value);
    if (foundProvider) {
      patchOrder({
        providerId: `${foundProvider.providerId}`,
        providerName: getProviderName(foundProvider),
      });
    } else {
      patchOrder({
        providerId: '',
        providerName: '',
      });
    }
  };

  // Retrieves the selected provider data from the current order object.
  const selectedProvider = useMemo(() => {
    // Checks that both the provider name and id are in the order object.
    // If not, the selection is not valid and we'll use empty string.
    const hasProviderNameAndId = order.providerName && order.providerId;
    return {
      primaryLabel: (hasProviderNameAndId && order.providerName) || '',
      value: (hasProviderNameAndId && order.providerId) || '',
    };
  }, [order.providerId, order.providerName]);

  const hasExchangeItem = useMemo(() => isOrderItemExchangeReturnType(), [isOrderItemExchangeReturnType]);

  return (
    <div>
      <Dropdown
        label="Provider"
        data={dropdownData}
        disabled={(hasExchangeItem && !originalOrderIsLegacy) || !account?.providers}
        selected={selectedProvider}
        setSelected={onSelect}
        isRequired={true}
        onSubmitAlert={!!onSubmitAlertCount}
        skeletonLoader={skeletonLoader}
        isShowLabelWhenDisabled={hasExchangeItem && !originalOrderIsLegacy}
      />
    </div>
  );
};

export default ProviderSelector;
