import Images from 'assets/images';
import Button, { ButtonProps } from 'components/common/Button/Button';
import Modal, { ModalProps } from 'components/common/Modal';
import { FC, ReactNode } from 'react';

/**
 * Props for the OrderEntryStatusModal component.
 */
interface OrderEntryStatusModalProps extends Omit<ModalProps, 'onClose'> {
  /**
   * Callback function to handle the modal close event.
   */
  onClose?: () => void;
  /**
   * The title displayed in the modal.
   */
  title: string;
  /**
   * The message displayed in the modal.
   */
  message: string;
  /**
   * Indicates whether the modal is in a loading state.
   */
  isLoading?: boolean;
  /**
   * The text displayed on the action button.
   */
  actionText?: string;
  /**
   * The variant of the action button.
   */
  actionVariant?: ButtonProps['variant'];
  /**
   * Callback function to handle the action button click event.
   */
  onAction?: () => void;
  /**
   * Custom icon to be displayed in the modal.
   */
  icon?: ReactNode;
}

/**
 * The OrderEntryStatusModal component displays a modal with status information during the order entry process.
 * It can show loading spinners, icons, and action buttons.
 * @param onClose - Callback function to handle the modal close event.
 * @param title - The title displayed in the modal.
 * @param message - The message displayed in the modal.
 * @param isLoading - Indicates whether the modal is in a loading state.
 * @param actionText - The text displayed on the action button.
 * @param actionVariant - The variant of the action button.
 * @param onAction - Callback function to handle the action button click event.
 * @param icon - Custom icon to be displayed in the modal.
 * @returns JSX element representing the OrderEntryStatusModal.
 */
export const OrderEntryStatusModal: FC<OrderEntryStatusModalProps> = ({
  onClose,
  isLoading,
  message,
  title,
  actionText,
  actionVariant = 'bordered',
  onAction,
  icon,
}) => {
  const handleClose = () => {
    onClose?.();
  };
  return (
    <Modal onClose={handleClose} width="w-1/4" outSideClickClose={false} showCloseButton={false}>
      <div className="p-6">
        <div className="flex flex-col gap-2 justify-start items-center text-center">
          {isLoading && <img src={Images.Spinner} alt="spinner" width={80} height={80} className="animate-spin" />}
          {!isLoading && icon}
          <div className="text-lg font-medium text-gray-900 mt-4">{title}</div>
          <p className="text-sm text-gray-500">{message}</p>
          {actionText && (
            <Button variant={actionVariant} onClick={onAction} className="mt-4">
              {actionText}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};
