import { useCallback, useState, useEffect } from 'react';

type DefaultValue<T2 extends (() => unknown) | unknown> = T2 extends () => infer R ? R : T2;

/**
 * store and retrieve values from Storage
 * @param key - key to be mapped
 * @param defaultValue - default value to return in case there is no value mapped to key
 * @param storageObject - storage objects available
 */
function useStorage<T1 extends string, T2 extends (() => unknown) | unknown, T3 extends Storage | null>(
  key: T1,
  defaultValue?: T2,
  storageObject?: T3
) {
  const [value, setValue] = useState<DefaultValue<T2>>(() => {
    if (!storageObject) return;
    const jsonValue = storageObject.getItem(key);
    if (jsonValue !== null) return JSON.parse(jsonValue);

    if (typeof defaultValue === 'function') {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (!storageObject) return;
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const removeValue = useCallback(() => {
    setValue(undefined as DefaultValue<T2>);
  }, []);

  return [value, setValue, removeValue] as const;
}

/**
 * store and retrieve values from localStorage
 * @param key - key to be mapped
 * @param defaultValue - default value to return in case there is no value mapped to key
 */
export function useLocalStorage<T2 extends (() => unknown) | unknown>(key: string, defaultValue?: T2) {
  return useStorage<string, T2, typeof window.localStorage>(
    key,
    defaultValue,
    typeof window !== 'undefined' ? window.localStorage : undefined
  );
}

/**
 * store and retrieve values from sessionStorage
 * @param key - key to be mapped
 * @param defaultValue - default value to return in case there is no value mapped to key
 */
export function useSessionStorage<T2 extends (() => unknown) | unknown>(key: string, defaultValue?: T2) {
  return useStorage<string, T2, typeof window.sessionStorage>(
    key,
    defaultValue,
    typeof window !== 'undefined' ? window.sessionStorage : undefined
  );
}
