import { CheckBadgeIcon, FlagIcon, QuestionMarkCircleIcon, TagIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { AccountStanding, AccountStatus } from 'API';
import classNames from 'classnames';
import { accountStatusClosed, getAccountStandingText } from 'shared/helpers/order-entry/order-entry.helper';

/**
 * Props for the AccountStandingBadge component.
 */
interface AccountStandingProps {
  /**
   * The account status.
   */
  accountStatus: AccountStatus;
  /**
   * The account standing.
   */
  accountStanding: AccountStanding;
}

/**
 * Component for displaying an account standing badge.
 * @param accountStatus - The account status.
 * @param accountStanding - The account standing.
 * @returns JSX.Element representing the AccountStandingBadge component.
 */
export const AccountStandingBadge = ({ accountStatus, accountStanding }: AccountStandingProps) => {
  let textColor = 'text-gray-500';
  let textContent = getAccountStandingText(accountStanding);
  let Icon = QuestionMarkCircleIcon;

  if (accountStatusClosed(accountStatus)) {
    textContent = 'Account Closed';
    Icon = XCircleIcon;
  } else {
    switch (accountStanding) {
      case AccountStanding.AlwaysOnCod:
      case AccountStanding.OnCod:
        textColor = 'text-yellow-600';
        Icon = TagIcon;
        break;
      case AccountStanding.AlwaysOnCreditHold:
      case AccountStanding.OnCreditHold:
        textColor = 'text-red-500';
        Icon = FlagIcon;
        break;
      case AccountStanding.Good:
        textColor = 'text-green-500';
        Icon = CheckBadgeIcon;
        break;
    }
  }

  return (
    <div className={classNames('flex items-center gap-1 ml-3', textColor)}>
      <Icon className="w-5" />
      <label className="text-sm">{textContent}</label>
    </div>
  );
};
