import { LmsOrder } from 'API';
import { AttributeName } from 'shared/enums';
import { getPatientFullName } from 'shared/utils';
import { ProductTitleAndValue } from './TitleAndValues/Product';
import { StandardTitleAndValue } from './TitleAndValues/Standard';

/**
 * Props for the LinkedCaseInfo component.
 */
interface LinkedCaseInfoProps {
  /**
   * The order number.
   */
  orderNumber: string;
  /**
   * The first name of the patient.
   */
  patientFirstName: string;
  /**
   * The last name of the patient.
   */
  patientLastName: string;
  /**
   * The order items.
   */
  orderItems: LmsOrder['orderItems'];
  /**
   * The name of the provider.
   */
  providerName?: string;
}

/**
 * The LinkedCaseInfo component displays information about a linked case.
 * @param orderNumber - The order number.
 * @param patientFirstName - The first name of the patient.
 * @param patientLastName - The last name of the patient.
 * @param orderItems - The order items.
 * @param providerName - The name of the provider.
 * @returns JSX element representing the LinkedCaseInfo component.
 */
export const LinkedCaseInfo: React.FC<LinkedCaseInfoProps> = ({
  orderNumber,
  patientFirstName,
  patientLastName,
  orderItems,
  providerName,
}) => {
  return (
    <div className="p-4">
      <div className="text-gray-900 font-medium mb-3">Case #{orderNumber}</div>
      <StandardTitleAndValue
        title="Patient Name"
        value={getPatientFullName(patientFirstName, patientLastName)}
        className="mb-3"
      />
      {providerName && <StandardTitleAndValue title="Provider Name" value={providerName} className="mb-3" />}
      {orderItems.map((orderItem, index) => {
        const targetOrderItemShade =
          orderItem.attributes.find(attribute => attribute.name === AttributeName.BodyShade)?.value || 'N/A';
        const targetOrderItemToothString =
          orderItem.attributes
            .find(attribute => attribute.name === AttributeName.ToothString)
            ?.value.replace(/#/g, '') || 'N/A';
        return (
          <div key={`linked-case-${orderItem.productCode}-${index}`}>
            <div>{orderItem.productName}</div>
            <div className="ml-2">
              <ProductTitleAndValue title="Product Code" value={orderItem.productCode} />
              <ProductTitleAndValue title="Tooth #" value={targetOrderItemToothString} />
              <ProductTitleAndValue title="Shade" value={targetOrderItemShade} />
            </div>
          </div>
        );
      })}
    </div>
  );
};
