import { WifiIcon, XMarkIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { useNetworkOnline } from 'hooks/use-network-online/use-network-online';
import { usePrevious } from 'hooks/use-previous';
import { useEffect, useRef, useState } from 'react';
import {
  NETWORK_OFFLINE_MESSAGE,
  NETWORK_RECONNECTED_MESSAGE,
  NETWORK_STATUS_TEXT_TEST_ID,
  NETWORK_STATUS_WIFI_ICON_TEST_ID,
  OFFLINE_ICON_COLOR,
  RECONNECTED_ICON_COLOR,
} from 'shared/constants/network-status.constants';

export const NetworkIndicator = () => {
  const [statusText, setStatusText] = useState<string>('');
  const [showBanner, setShowBanner] = useState(false);
  const isOnline = useNetworkOnline();
  const prevIsOnline = usePrevious(isOnline);
  const timeout = useRef<null | NodeJS.Timeout>(null);

  /**
   * Handle updating the message based on the network status.
   */
  useEffect(() => {
    setStatusText(!isOnline ? NETWORK_OFFLINE_MESSAGE : NETWORK_RECONNECTED_MESSAGE);
  }, [isOnline]);

  /**
   * Show the banner when isOnline is false or when the isOnline value changes.
   */
  useEffect(() => {
    if (!isOnline || (typeof prevIsOnline !== 'undefined' && prevIsOnline !== isOnline)) {
      setShowBanner(true);
    }
  }, [isOnline, prevIsOnline]);

  /**
   * Handle showing the banner based on the online status and previous online status value.
   */
  useEffect(() => {
    if (typeof prevIsOnline !== 'undefined' && !prevIsOnline && isOnline) {
      timeout.current = setTimeout(() => {
        setShowBanner(false);
        timeout.current = null;
      }, 5000);
    }
  }, [isOnline, prevIsOnline]);

  /**
   * Handle clearing the timeout on unmount.
   */
  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
    };
  }, []);

  return !showBanner ? null : (
    <div
      id="sticky-banner"
      tabIndex={-1}
      className="fixed top-0 start-0 z-50 flex justify-between w-full p-4 bg-gray-600 bg-opacity-95"
    >
      <div className="flex items-center mx-auto">
        <p className="flex items-center text-sm font-normal text-white">
          <span className="inline-flex p-1 me-3 rounded-full w-6 h-6 items-center justify-center flex-shrink-0">
            <WifiIcon
              className={classNames({
                [OFFLINE_ICON_COLOR]: !isOnline,
                [RECONNECTED_ICON_COLOR]: isOnline,
              })}
              data-testid={NETWORK_STATUS_WIFI_ICON_TEST_ID}
            />
            <span className="sr-only">Wifi Icon</span>
          </span>
          <span data-testid={NETWORK_STATUS_TEXT_TEST_ID}>{statusText}</span>
        </p>
      </div>
      <div className="flex items-center">
        <button
          data-dismiss-target="#sticky-banner"
          type="button"
          className="flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:text-white rounded-lg text-sm p-1.5"
          onClick={() => setShowBanner(false)}
        >
          <XMarkIcon />
          <span className="sr-only">Close banner</span>
        </button>
      </div>
    </div>
  );
};
