import { CancelCaseAlertDetail } from 'API';
import { CaseAlertDetailItemHOC } from './detail-item-hoc.constants';

export interface CancelCaseAlertI {
  cancelCase: CancelCaseAlertDetail;
  className?: string;
}

export const CancelCaseAlert = ({ className, cancelCase }: CancelCaseAlertI) => {
  const { product, reason, caseContent, notes } = cancelCase;

  return (
    <div className={className}>
      <CaseAlertDetailItemHOC title="Cancel Case">Product: {product || 'N/A'}</CaseAlertDetailItemHOC>
      <CaseAlertDetailItemHOC title="Reason" className="my-3">
        {reason}
      </CaseAlertDetailItemHOC>
      <CaseAlertDetailItemHOC title="Case Contents">{caseContent}</CaseAlertDetailItemHOC>
      {notes && (
        <CaseAlertDetailItemHOC title="Notes" className="mt-3">
          {notes}
        </CaseAlertDetailItemHOC>
      )}
    </div>
  );
};
