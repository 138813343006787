import { FC, useEffect, useState } from 'react';
import { cn } from 'shared/utils';

/**
 * Props for the CheckIcon component.
 */
interface CheckIconProps {
  /** Additional CSS classes for styling. */
  className?: string;
}

/**
 * Icon component to represent a checkmark.
 * @param className - Additional CSS classes for styling.
 * @returns The CheckIcon component.
 */
export const CheckIcon: FC<CheckIconProps> = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" className={className}>
      <path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z" />
    </svg>
  );
};

/**
 * Props for the ColorCheckBox component.
 */
export interface ColorCheckBoxProps {
  /** The variant color of the checkbox (teal, orange, or undefined). */
  variant: 'teal' | 'orange' | undefined;
  /** The label for the checkbox. */
  label: string;
  /** Additional CSS classes for styling the label. */
  labelClassName?: string;
  /** Specifies whether the checkbox is clickable. */
  isClickable?: boolean;
  /** Specifies whether the checkbox is checked. */
  checked?: boolean;
  /** Function called when the checkbox state changes. */
  onChange?: (checked: boolean) => void;
  /** Specifies whether the checkbox is disabled. */
  disabled?: boolean;
  /** Test id for testing purposes. */
  testId?: string;
}

/**
 * Checkbox component with colored indicator.
 * @param variant - The variant color of the checkbox (teal, orange, or undefined).
 * @param label - The label for the checkbox.
 * @param labelClassName - Additional CSS classes for styling the label.
 * @param isClickable - Specifies whether the checkbox is clickable.
 * @param checked - Specifies whether the checkbox is checked.
 * @param onChange - Function called when the checkbox state changes.
 * @param disabled - Specifies whether the checkbox is disabled.
 * @param testId - Test id for testing purposes.
 * @returns The ColorCheckBox component.
 */
export const ColorCheckBox: FC<ColorCheckBoxProps> = ({
  variant,
  label,
  checked = false,
  labelClassName,
  isClickable,
  onChange,
  disabled,
  testId,
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div
      className={cn('flex flex-row gap-1 justify-start items-center cursor-default', {
        'cursor-pointer': isClickable,
        'pointer-events-none': disabled,
      })}
      onClick={() => {
        if (!isClickable || disabled) return;
        onChange?.(!isChecked);
        setIsChecked(!isChecked);
      }}
      data-testid={`${testId}CheckBoxContainer`}
    >
      <div
        className={cn(`w-4 h-4 rounded-2sm border-2 bg-gray-50 border-gray-300 relative `, {
          'bg-teal-100 border-teal-500': variant === 'teal',
          'bg-orange-100 border-orange-500': variant === 'orange',
          'bg-indigo-500 border-indigo-500': !variant && isChecked,
          'opacity-40 cursor-not-allowed': disabled,
        })}
        data-testid={`${testId}Radio`}
      >
        {isChecked && (
          <div data-testid={`${testId}Checked`}>
            <CheckIcon
              className={cn('fill-current text-gray-800 w-6 h-6', {
                'text-teal-800': variant === 'teal',
                'text-orange-800': variant === 'orange',
                'text-white': !variant,
              })}
            />
          </div>
        )}
      </div>

      <div data-testid={`${testId}Label`} className={cn('text-gray-700 text-sm font-medium', labelClassName)}>
        {label}
      </div>
    </div>
  );
};
