import { DeliveryMethod, Invoice, ShippingLabel } from 'API';
import { getDoctorPickupLabel, getInvoice } from 'shared/api/invoice.api';
import { getOrderShipping } from 'shared/api/order.api';
import { PrintType, openHtmlInWindow, openLabelPrintPage, print } from 'shared/api/print.api';
import { getWorkOrder } from 'shared/api/workorder.api';
import { PrintHtmlType } from 'shared/enums/print-html-type';

/**
 * Print the invoice.
 * If no HTML is passed in, fetch the order invoice html.
 * @param orderNumber - order number
 * @param html - html content to print as invoice
 * @param callback - triggers on successful fetching of invoice total
 */
export const printInvoice = async (orderNumber: string, html?: string, callback?: (invoiceTotal: number) => void) => {
  let invoiceData: Invoice | undefined;

  if (html) {
    invoiceData = {
      __typename: 'Invoice',
      html,
    };
  } else {
    invoiceData = await getInvoice({ orderNumber });
  }

  if (!invoiceData) {
    throw new Error('Failed to get invoice data.');
  } else {
    if (callback) {
      const freshOrder = await getOrderShipping({ orderNumber });
      const totalAmount = freshOrder.totalAmount ?? 0;
      callback(totalAmount);
    }
    try {
      await print(
        PrintType.HTML,
        [{ printContent: invoiceData.html, displayContent: invoiceData.html }],
        PrintHtmlType.Invoice
      );
    } catch (error) {
      openHtmlInWindow(invoiceData.html);
      throw new Error('Error printing the invoice, please try printing manually.');
    }
  }
};

interface IPrintData {
  printLabel: ShippingLabel;
  displayLabel: ShippingLabel;
}

/**
 * EPL2 is the shipping label that needs to be sent to the printer.
 * Return EPL2 as print label and the display label can be png.
 * Need display label to support fallback popup since we can't pull up an epl2 file in a window.
 * @param labels - the labels to print.
 * @returns print and display labels.
 */
const getLabelToPrint = (labels: ShippingLabel[]): IPrintData | undefined => {
  if (!labels.length) return;

  const epl2Label = labels.find(label => {
    return label.type.toLowerCase() === 'epl2';
  });

  const pngLabel = labels.find(label => {
    return label.type.toLowerCase() === 'png';
  });

  return {
    printLabel: epl2Label || labels[0],
    displayLabel: pngLabel || labels[0],
  };
};

/**
 * Print the shipping label (doctor label or EasyPost label) by first fetching the current case details.
 * Only fetch case details if label data is not passed in as parameter.
 * @param orderNumber - the order number for the order associated with the labels.
 * @param printInbound - true/false to print inbound labels.
 * @param labelUrls - label urls so, we don't have to fetch.
 */
export const printShippingLabel = async (orderNumber: string, printInbound?: boolean, labelUrls?: ShippingLabel[]) => {
  const shippingLabelData: IPrintData[] = [];
  let shouldValidateLabelExists = true;
  if (!labelUrls) {
    const freshOrder = await getOrderShipping({ orderNumber });
    const outboundTrackingNumbers = freshOrder.shipping?.outboundTrackingNumbers;
    const inboundTrackingNumbers = freshOrder.shipping?.inboundTrackingNumbers;
    // If doctor pickup then print doctor pickup label, if not check to see if there is outbound tracking and print outbound shipping
    if (freshOrder.shipping?.deliveryMethod === DeliveryMethod.DoctorPickup) {
      shouldValidateLabelExists = false;
      const doctorPickupLabel = await getDoctorPickupLabel({ orderNumber });

      if (doctorPickupLabel) {
        try {
          await print(PrintType.HTML, [
            { printContent: doctorPickupLabel.html, displayContent: doctorPickupLabel.html },
          ]);
        } catch (e) {
          openHtmlInWindow(doctorPickupLabel.html);
          throw new Error('Error printing doctor pickup label, please try printing manually.');
        }
      } else {
        throw new Error(`Could not find doctor pickup label or label is not ready for printing yet.`);
      }
    } else {
      if (outboundTrackingNumbers && outboundTrackingNumbers.length) {
        const labelToPrint = getLabelToPrint(
          outboundTrackingNumbers[outboundTrackingNumbers.length - 1].shippingLabelUrls || []
        );
        if (labelToPrint) {
          shippingLabelData.push(labelToPrint);
        }
      }

      // Add inbound shipping.
      if (printInbound && inboundTrackingNumbers && inboundTrackingNumbers.length) {
        const labelToPrint = getLabelToPrint(
          inboundTrackingNumbers[inboundTrackingNumbers.length - 1].shippingLabelUrls || []
        );
        if (labelToPrint) {
          shippingLabelData.push(labelToPrint);
        }
      }
    }
  } else {
    const labelToPrint = getLabelToPrint(labelUrls);
    if (labelToPrint) {
      shippingLabelData.push(labelToPrint);
    }
  }

  // get the shipping label url
  if (shippingLabelData.length) {
    try {
      await print(
        PrintType.ShippingLabel,
        shippingLabelData.map(label => {
          return {
            printContent: label.printLabel.url,
            displayContent: label.displayLabel.url,
          };
        })
      );
    } catch (e) {
      shippingLabelData.forEach(labelData => {
        openLabelPrintPage(labelData.displayLabel.url);
      });
      throw new Error(`Error printing shipping label, please try printing manually.`);
    }
  } else if (shouldValidateLabelExists) {
    throw new Error(`Could not find shipping label or label is not ready for printing yet.`);
  }
};

interface PrintWorkOrderI {
  orderNumber: string;
  html?: string;
  isOpenHtmlInWindow?: boolean;
}

/**
 * Print the work order.
 * If no HTML passed in, fetch the work order HTML.
 * @param orderNumber - order number
 * @param html - html content to be printed
 * @param isOpenHtmlInWindow - indicates whether this should be open in a new window
 */
export const printWorkOrder = async ({ orderNumber, html, isOpenHtmlInWindow }: PrintWorkOrderI) => {
  let workOrderHtml = html || '';

  if (!workOrderHtml) {
    try {
      const workOrder = await getWorkOrder({
        orderNumber,
      });
      workOrderHtml = workOrder.html;
    } catch (e) {
      console.error('Error fetching work order.', e);
    }
  }

  if (!workOrderHtml) {
    throw new Error('Failed to get work order HTML.');
  } else if (isOpenHtmlInWindow) {
    openHtmlInWindow(workOrderHtml);
  } else {
    try {
      await print(
        PrintType.HTML,
        [{ printContent: workOrderHtml, displayContent: workOrderHtml }],
        PrintHtmlType.WorkOrder
      );
    } catch (error) {
      openHtmlInWindow(workOrderHtml);
      throw new Error('Failed to auto print the work order, please try printing manually.');
    }
  }
};
