import React from 'react';

/**
 * Props for the InputError component.
 */
interface InputErrorProps {
  /**
   * The error message to display.
   */
  message?: string;
  /**
   * Flag to force hide the error message.
   */
  forceHide?: boolean;
  /**
   * Test ID for the error message element.
   */
  testId?: string;
}

/**
 * Component for displaying an error message for an input.
 * @param message - The error message to display.
 * @param forceHide - Flag to force hide the error message.
 * @param testId - Test ID for the error message element.
 * @returns JSX.Element representing the InputError component.
 */
const InputError: React.FC<InputErrorProps> = ({ message, forceHide, testId }) => {
  const testIdValue = `${testId || ''}ErrorMessage`;
  return !message || !!forceHide ? null : (
    <p className="text-xs text-red-600 mt-1" data-testid={testIdValue} data-qa={testIdValue}>
      {message}
    </p>
  );
};

export default InputError;
