import { ProductReturnRequirement } from 'API';
import { getDefaultOrder } from 'mocks/order-entry.mock';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useMemo } from 'react';
import { getOutboundApplicableIndex } from 'shared/utils';
import InvoiceSummary from './InvoiceSummary';

/**
 * Component responsible for rendering the skeleton loading state for the invoice summary.
 *
 * @returns JSX.Element The skeleton loading state for the invoice summary.
 */
const SkeletonLoading = () => {
  return <InvoiceSummary isLoading={true} invoiceCase={getDefaultOrder()} />;
};

interface InvoiceSummaryListsProps {
  productReturnRequirements: ProductReturnRequirement[];
}

/**
 * Component responsible for rendering the list of invoice summaries.
 *
 * @param productReturnRequirements - The product return requirements for each invoice case.
 */
const InvoiceSummaryLists = ({ productReturnRequirements }: InvoiceSummaryListsProps) => {
  const { invoiceCases } = useInvoicingDetail();

  // Memoized calculation to determine the index of the next case where shipping charges are applicable
  const shippingApplicableNextCaseIndex = useMemo(() => {
    return getOutboundApplicableIndex(invoiceCases, productReturnRequirements);
  }, [invoiceCases, productReturnRequirements]);

  return (
    <div className="flex flex-col gap-6">
      {invoiceCases.map((invoiceCase, index) => (
        <InvoiceSummary
          key={invoiceCase.orderNumber}
          invoiceCase={invoiceCase}
          isChargeApplicableCase={index === shippingApplicableNextCaseIndex}
        />
      ))}
    </div>
  );
};

interface RightPanelProps {
  isLoading: boolean;
  productReturnRequirements: ProductReturnRequirement[];
}
/**
 * Component responsible for rendering the right panel containing the invoice summary.
 *
 * @param isLoading - Flag indicating whether the component is in a loading state.
 * @param productReturnRequirements - The product return requirements for each invoice case.
 */
const RightPanel: React.FC<RightPanelProps> = ({ isLoading, ...props }) => {
  return (
    <div className="flex-none w-88 3xl:w-80 bg-white border-l p-6">
      <div className="font-medium text-gray-900 mb-3">Invoice Summary</div>
      {isLoading ? <SkeletonLoading /> : <InvoiceSummaryLists {...props} />}
    </div>
  );
};

export default RightPanel;
