import { OrderItem } from 'API';
import { useAppConfig } from 'providers/AppProvider';
import { SubscriptionsContext } from 'providers/SubscriptionProvider';
import { useToast } from 'providers/ToastProvider';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { subscribeToWorkOrder } from 'shared/api/workorder.api';
import { ToastNotificationType } from 'shared/enums';
import { printWorkOrder } from 'shared/helpers/printing/print.helper';
import { BundledSplitCase } from 'stores/useBundleSplitCaseStore';
import { v4 as uuidv4 } from 'uuid';
import CaseSubmissionSummary from '../CaseSubmissionSummary/CaseSubmissionSummary';

/**
 * Component for displaying the submission summary of a bundled split case.
 * @param orderNumber - The order number associated with the bundle split case.
 * @param orderItems - An array of order items for the bundle split case.
 * @returns JSX element representing the BundleSplitCaseSubmissionSummary component.
 */
export const BundleSplitCaseSubmissionSummary: React.FC<BundledSplitCase> = ({ orderNumber = '', orderItems }) => {
  const [disableReprint, setDisableReprint] = useState(true);
  const [subscribeCalled, setSubscribeCalled] = useState(false);
  const subscriptions = useContext(SubscriptionsContext);
  const { sessionId } = useAppConfig();
  const toast = useToast();
  const navigate = useNavigate();

  /**
   * Handle subscribing to work order when coming from order entry page.
   * There will be an orderId in location state if coming from order entry page.
   */
  useEffect(() => {
    if (!subscribeCalled) {
      const subId = uuidv4();
      const sub = subscribeToWorkOrder({
        OrderNumber: orderNumber || '',
        SessionId: sessionId,
      }).subscribe({
        next: async () => {
          // no need to keep this open
          sub.unsubscribe();
          try {
            await printWorkOrder({ orderNumber: orderNumber || '' });
          } catch (e) {
            const error = e as Error;
            console.error('Error printing work order.', error);
            toast.notify(error.message || 'Error printing work order.', ToastNotificationType.Error);
          } finally {
            setDisableReprint(false);
          }
        },
        error: error => console.warn(error),
      });

      // add this sub to context so we could access it from anywhere
      subscriptions.addSubscription({ uuid: subId, subscription: sub });
      setSubscribeCalled(true);
      navigate('.', { relative: 'route', replace: true, state: undefined });
    }
  }, [navigate, orderNumber, sessionId, subscribeCalled, subscriptions, toast]);

  const apiOrderItems = useMemo<OrderItem[]>(() => {
    return orderItems.map(item => {
      return {
        ...item,
        __typename: 'OrderItem',
        addOns: item.addOns?.map(addOn => {
          return {
            ...addOn,
            __typename: 'OrderProductAttribute',
          };
        }),
        overrides: item.overrides?.map(override => {
          return {
            ...override,
            __typename: 'OverrideField',
          };
        }),
        attributes: item.attributes?.map(attribute => {
          return {
            ...attribute,
            __typename: 'OrderProductAttribute',
          };
        }),
        services: item.services?.map(service => {
          return {
            ...service,
            __typename: 'OrderProductAttribute',
          };
        }),
        itemId: item.itemId || '',
      };
    });
  }, [orderItems]);

  return <CaseSubmissionSummary orderId={orderNumber} orderItems={apiOrderItems} disableReprint={disableReprint} />;
};
