import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { DialogBox } from 'components/common/Dialog/DialogBox';
import { useAuth } from 'providers/AuthProvider';
import { useToast } from 'providers/ToastProvider';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { unInvoice } from 'shared/api/uninvoice.api';
import { ToastNotificationType } from 'shared/enums';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { AnalyticsService } from 'shared/services/analytics.service';

interface UnInvoiceModalProps {
  showModal: boolean;
  timezone: string;
  accountNumber: string;
  onCloseModal: () => void;
  onConfirmUnInvoice?: (success?: boolean) => void;
}

export const UnInvoiceModal: FC<UnInvoiceModalProps> = ({
  showModal,
  timezone,
  accountNumber,
  onCloseModal,
  onConfirmUnInvoice,
}) => {
  const { id: orderNumber } = useParams<{ id: string }>();
  const { fetcher, loading } = useLazyQueryFetcher(unInvoice);
  const { user } = useAuth();
  const toast = useToast();

  const handleUnInvoice = async () => {
    if (!orderNumber) return toast.notify('Invalid order number', ToastNotificationType.Error);
    try {
      const { success } = await fetcher({
        input: { orderNumber, updatedBy: user?.displayName || '', utcConversionTimeZone: timezone },
      });
      AnalyticsService.track(AnalyticsEventName.CaseUninvoiced, {
        caseNumber: orderNumber,
        accountNumber,
      });
      if (!success) throw new Error('Failed to uninvoice this case');
      toast.notify(`Case # ${orderNumber} has been uninvoiced successfully.`, ToastNotificationType.Success);
      onCloseModal();
      onConfirmUnInvoice?.(success);
    } catch (err) {
      const error = err as Error;
      toast.notify(error.message || 'Failed to uninvoice this case.', ToastNotificationType.Error);
    }
  };

  return (
    <>
      {showModal && (
        <DialogBox
          title="Uninvoice this case?"
          onCancel={onCloseModal}
          icon={<ExclamationTriangleIcon className="h-6 w-6 text-red-600" />}
          confirmText="Yes, Uninvoice"
          confirmButtonDisabled={loading}
          onConfirm={handleUnInvoice}
        >
          <div className="text-base text-gray-500">
            Are you sure you want to uninvoice this case? You will have to invoice it again.
          </div>
        </DialogBox>
      )}
    </>
  );
};
