import Modal from 'components/common/Modal';
import { OrderEntryLookupForm } from 'pages/OrderEntryLookupPage/OrderEntryLookupForm/OrderEntryLookupForm';
import { useAuth } from 'providers/AuthProvider';
import { useToast } from 'providers/ToastProvider';
import { getOrder } from 'shared/api/order.api';
import { NEW_CASE } from 'shared/constants/constants';
import { ToastNotificationType } from 'shared/enums';
import { convertOrderToLocalOrderInputWithCoupons } from 'shared/helpers/order-detail/order-detail.helper';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { generateBundleOrderRecord, useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';
import { shallow } from 'zustand/shallow';

/**
 * Represents the props for the AddCaseToBundleModal component.
 */
interface AddCaseToBundleModalProps {
  /**
   * Function to close the modal.
   */
  onClose: () => void;
}

/**
 * Component for adding a case to a bundle modal.
 *
 * @param onClose - Function to close the modal.
 * @returns an add case to bundle modal component.
 */
const AddCaseToBundleModal: React.FC<AddCaseToBundleModalProps> = ({ onClose }) => {
  const { addOrder } = useBundleSplitCaseStore(state => ({ addOrder: state.addOrder }), shallow);
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const toast = useToast();
  const { fetcher: getOrderFetcher } = useLazyQueryFetcher(getOrder);

  const setPendingOrderResult = async (orderNumber: string) => {
    try {
      const result = await getOrderFetcher({
        orderNumber,
      });
      const data = await convertOrderToLocalOrderInputWithCoupons(result, currentUserName);
      if (data) {
        addOrder(data);
      } else {
        toast.notify('Order data was not found', ToastNotificationType.Error);
      }
    } catch (error) {
      toast.notify((error as Error).message || 'Failed to load order', ToastNotificationType.Error);
    }
  };

  const onSubmit = async (orderNumber: string) => {
    if (orderNumber === NEW_CASE) {
      addOrder(generateBundleOrderRecord(orderNumber));
    } else {
      await setPendingOrderResult(orderNumber);
    }
    onClose();
  };
  return (
    <Modal onClose={onClose} width="w-1/5" outSideClickClose={true} showCloseButton={false}>
      <OrderEntryLookupForm onSubmit={onSubmit} className="py-10 px-6" />
    </Modal>
  );
};

export default AddCaseToBundleModal;
