/**
 * Component for a container used to wrap modules.
 * @param children - The child components or elements.
 * @returns JSX.Element representing the ModuleContainer component.
 */
const ModuleContainer: React.FC = ({ children }) => {
  return <div className="flex flex-col min-w-0 flex-1 overflow-hidden bg-gray-50 relative">{children}</div>;
};

export default ModuleContainer;
