import Label from 'components/common/Label';
import { FC } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';

/**
 * Notes component for entering optional notes.
 * @returns JSX element Notes component
 */
export const Notes: FC = () => {
  const note = useAddDiscountModalStore(state => state.specialDiscount.note);
  const setNote = useAddDiscountModalStore(state => state.specialDiscount.setNote);

  return (
    <div>
      <Label>Notes (Optional)</Label>
      <div className="w-full">
        <textarea
          className="rounded-md show-sm border-gray-300 text-sm w-full"
          placeholder="Enter a note"
          value={note}
          onChange={e => setNote(e.target.value)}
        ></textarea>
      </div>
    </div>
  );
};
