import { useEffect, useMemo, useState } from 'react';
import EnclosedItemEntry from './EnclosedItemEntry';

import { EnclosedItem, EnclosedItemCategory } from 'API';
import { SkeletonBox } from 'components/common/Skeleton';
import { map, sortBy } from 'lodash';
import { useAuth } from 'providers/AuthProvider';
import { getEnclosedItems } from 'shared/api/order.api';
import { getDefaultOrderEnclosedItem } from 'shared/helpers/invoice/invoice.helper';
import { useQueryFetcher } from 'shared/hooks/useQueryFetcher';
import { LocalOrderEnclosedItem } from 'shared/models';
import AddButton from '../AddButton';

/**
 * Renders a skeleton loader for loading state visualization.
 * @param count - The number of skeleton loaders to render.
 */
const SkeletonEnclosedItems: React.FC<{ count: number }> = ({ count }) => {
  return (
    <div className="flex flex-col gap-2">
      {Array.from({ length: count }).map((_, index) => (
        <div key={index} className="flex flex-row items-center gap-2" data-testid="skeleton-box">
          <SkeletonBox className="w-9/12 h-5" />
          <SkeletonBox className="w-3/12 h-5" />
        </div>
      ))}
    </div>
  );
};

interface EnclosedItemsDataProps extends EnclosedItemsProps {
  enclosedItems: LocalOrderEnclosedItem[];
  enclosedItemOptions: EnclosedItem[];
  addButtonText: string;
  isDisabledAddButton?: boolean;
}

/**
 * Renders the data section of the enclosed items component.
 * @param enclosedItemOptions - The available options for enclosed items.
 * @param enclosedItems - The currently selected enclosed items.
 * @param addButtonText - The text for the add button.
 * @param onChangeItem - Handles the change of an enclosed item.
 * @param onRemoveItem - Handles the removal of an enclosed item.
 * @param isDisabledAddButton - Specifies whether the add button should be disabled.
 * @param blockEnclosedItems - Specifies whether adding new items is blocked.
 * @param invalidCodeText - The text for an invalid item code.
 * @param category - The category of enclosed items.
 */
const EnclosedItemsData: React.FC<EnclosedItemsDataProps> = ({
  enclosedItemOptions,
  enclosedItems: enclosedItemLists,
  addButtonText,
  onChangeItem,
  onRemoveItem,
  isDisabledAddButton,
  blockEnclosedItems,
  invalidCodeText,
  category,
}) => {
  const [enclosedItems, setEnclosedItems] = useState([...enclosedItemLists]);
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';

  useEffect(() => {
    setEnclosedItems([...enclosedItemLists]);
  }, [enclosedItemLists]);

  const onAddItemHandle = () => {
    const newAddedEnclosedItem = getDefaultOrderEnclosedItem(currentUserName, category);
    const newEnclosedItems = [...enclosedItems, newAddedEnclosedItem];
    setEnclosedItems(newEnclosedItems);
    onChangeItem(newEnclosedItems, newAddedEnclosedItem, newEnclosedItems.length - 1);
  };

  const onChangeItemHandle = (
    index: number,
    key: keyof Pick<LocalOrderEnclosedItem, 'itemCode' | 'quantity'>,
    value: string
  ) => {
    const updateEnclosedItem = enclosedItems[index];
    if (!updateEnclosedItem) return;
    if (key === 'itemCode') {
      const foundItem = enclosedItemOptions.find(item => item.itemCode === value);
      updateEnclosedItem.itemCode = value;
      updateEnclosedItem.type = foundItem?.type || '';
      // As per LMS1-4726, if the enclosed item code is being changed, the enclosed item should be treated as if it were being replaced and the createdBy/createdDate should be updated.
      updateEnclosedItem.createdBy = currentUserName;
      updateEnclosedItem.createdDate = new Date().toISOString();

      // Default quantity to 1.
      if (value) {
        updateEnclosedItem.quantity = 1;
      } else {
        updateEnclosedItem.quantity = 0;
      }
    } else if (key === 'quantity') {
      updateEnclosedItem.quantity = +value;
    }

    if (updateEnclosedItem.errors) {
      updateEnclosedItem.errors[key] = '';
    }

    // Sets the shipping type to the correct category (inbound for case create/edit and outbound for invoicing).
    updateEnclosedItem.shippingType = category;

    const updatedEnclosedItems = [...enclosedItems];
    setEnclosedItems(updatedEnclosedItems);
    onChangeItem(updatedEnclosedItems, updatedEnclosedItems[index], index);
  };

  const onRemoveItemHandle = (index: number) => {
    const filteredEnclosedItems = enclosedItems.filter((_, i) => i !== index);
    setEnclosedItems(filteredEnclosedItems);
    onRemoveItem(filteredEnclosedItems);
  };

  const enclosedDropdownOptions = useMemo(() => sortBy(map(enclosedItemOptions, 'itemCode')), [enclosedItemOptions]);

  return (
    <div className="flex flex-col">
      {enclosedItemLists.map((enclosedItem, index) => (
        <EnclosedItemEntry
          key={`${enclosedItem.itemCode}-${index}`}
          selectedItem={enclosedItem}
          selectedItemOptions={enclosedDropdownOptions}
          onRemoveItem={() => onRemoveItemHandle(index)}
          onChangeItem={(key, value) => onChangeItemHandle(index, key, value)}
          invalidCodeText={invalidCodeText}
        />
      ))}
      {!blockEnclosedItems && (
        <AddButton
          id="addEnclosedItemButton"
          disabled={isDisabledAddButton}
          text={addButtonText}
          onClick={onAddItemHandle}
        />
      )}
    </div>
  );
};

interface EnclosedItemsProps {
  skeletonLoadingCount?: number;
  isDataLoading?: boolean;
  category: EnclosedItemCategory;
  enclosedItems?: LocalOrderEnclosedItem[];
  addButtonText?: string;
  blockEnclosedItems?: boolean;
  invalidCodeText?: string;
  onChangeItem: (items: LocalOrderEnclosedItem[], item: LocalOrderEnclosedItem, index: number) => void;
  onRemoveItem: (items: LocalOrderEnclosedItem[]) => void;
}

const EnclosedItems: React.FC<EnclosedItemsProps> = ({
  category,
  isDataLoading,
  onChangeItem: onChangeItemHandle,
  onRemoveItem: onRemoveItemHandle,
  enclosedItems = [],
  skeletonLoadingCount = 1,
  addButtonText = 'Add Item',
  blockEnclosedItems,
  invalidCodeText,
}) => {
  const { data: items, loading } = useQueryFetcher(getEnclosedItems, { category: category });

  const enclosedItemOptions = useMemo(() => {
    if (!items) return [];
    const itemCodes = enclosedItems.map(item => item.itemCode);
    return items.filter(item => !itemCodes.includes(item.itemCode));
  }, [items, enclosedItems]);
  const enclosedItemsSelected = useMemo(
    () => enclosedItems.filter(item => item.shippingType === category),
    [enclosedItems, category]
  );
  const isLoading = isDataLoading || loading;

  return (
    <div className="flex flex-col">
      <label
        className="block mb-2 text-sm font-medium text-gray-700"
        htmlFor="enclosedItems"
        data-qa="enclosedItemsLabel"
        data-testid="enclosedItemsLabel"
      >
        Enclosed Items
      </label>
      {isLoading && <SkeletonEnclosedItems count={skeletonLoadingCount} />}
      {!isLoading && (
        <EnclosedItemsData
          enclosedItemOptions={enclosedItemOptions}
          enclosedItems={enclosedItemsSelected}
          onChangeItem={onChangeItemHandle}
          onRemoveItem={onRemoveItemHandle}
          addButtonText={addButtonText}
          isDisabledAddButton={enclosedItemOptions.length === 0}
          blockEnclosedItems={blockEnclosedItems}
          invalidCodeText={invalidCodeText}
          category={category}
        />
      )}
    </div>
  );
};

export default EnclosedItems;
