import { OrderSearchInputFields, OrderStatus } from 'API';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import { TableColumn } from 'components/common/Table/types';
import { CountTab, SearchedOrderMinimum } from 'shared/models';
import { SearchFilterFacet } from 'types/common';

export const tabs: CountTab[] = [
  { name: 'All Cases', count: '0' },
  { name: 'Deleted Cases', count: '0' },
];

/**
 * filter facet configuration
 */
export const searchFilterFacets: SearchFilterFacet[] = [
  {
    id: 'type',
    name: 'Type',
    options: [
      {
        id: 'patientName',
        name: 'Patient Name',
        value: OrderSearchInputFields.PatientName,
      },
      {
        id: 'providerName',
        name: 'Provider Name',
        value: OrderSearchInputFields.ProviderName,
      },
      {
        id: 'caseNumber',
        name: 'Case #',
        value: OrderSearchInputFields.CaseNumber,
      },
      {
        id: 'accountNumber',
        name: 'Account #',
        value: OrderSearchInputFields.AccountNumber,
      },
    ],
  },
  {
    id: 'caseStatus',
    name: 'Case Status',
    options: [
      {
        id: 'manufacturing',
        name: 'Manufacturing',
        value: [OrderStatus.Manufacturing, OrderStatus.InLab],
      },
      {
        id: 'invoiced',
        name: 'Invoiced',
        value: [OrderStatus.Invoiced, OrderStatus.InvoicePending],
      },
      {
        id: 'shipped',
        name: 'Shipped',
        value: OrderStatus.Shipped,
      },
      {
        id: 'cancelled',
        name: 'Cancelled',
        value: OrderStatus.Cancelled,
      },
      {
        id: 'deleted',
        name: 'Deleted',
        value: OrderStatus.Deleted,
      },
    ],
  },
];

export const searchTableColumns = (
  navigateToOrderDetails: (orderNumber: string) => void
): TableColumn<SearchedOrderMinimum>[] => {
  return [
    {
      id: 'orderNumber',
      name: 'CASE #',
      render({ row }) {
        return (
          <button
            onClick={() => navigateToOrderDetails(row.orderNumber)}
            className="text-indigo-600 focus:text-indigo-900 ml-1"
          >
            {row.orderNumber}
          </button>
        );
      },
    },
    {
      id: 'patientName',
      name: 'PATIENT NAME',
      sortable: true,
    },
    {
      id: 'productDescription',
      name: 'DESCRIPTION',
    },
    {
      id: 'billingAccountId',
      name: 'ACCOUNT #',
    },
    {
      id: 'providerName',
      name: 'PROVIDER',
      sortable: true,
    },
    {
      id: 'labReceivedDate',
      name: 'RECEIVED',
      sortable: true,
      type: 'date',
    },
    {
      id: 'invoiceDate',
      name: 'INVOICED',
      sortable: true,
      type: 'date',
    },
    {
      id: 'status',
      name: 'CASE STATUS',
      render({ row }) {
        return <CaseStatusBadge status={row.status} />;
      },
    },
  ];
};
