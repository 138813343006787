import { ListItem, ReturnType } from 'API';
import classNames from 'classnames';
import Dropdown from 'components/common/Dropdown/Dropdown';
import MultiSelectDropdown from 'components/common/MultiSelectDropdown/MultiSelectDropdown';
import YesNo from 'components/common/YesNo/YesNo';
import _ from 'lodash';
import { AlertModalContext } from 'providers/AlertModalProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import React, { useContext, useEffect, useMemo } from 'react';
import { PERMISSION_DENIED, PERMISSION_DENIED_TEXT } from 'shared/constants/role-based-access-control';
import { ErrorMessage, ToastNotificationType } from 'shared/enums';
import { ACTIONS } from 'shared/enums/permission';
import { OrderType, ProductState } from 'shared/enums/product';
import { getReturnTypeLabel } from 'shared/helpers/return-type.helper';
import { useRoleBasedAccessControl } from 'shared/hooks/useRoleBasedAccessControl';
import { DropdownModel, DropdownOption, LocalOrderItemInput } from 'shared/models';
import { cn, nullFunction } from 'shared/utils';
import { getProductName, isDigitalOrder } from '../../../../shared/helpers/order-entry/order-entry.helper';
import ProductSearch from '../../Products/ProductSearch/ProductSearch';

enum ContainerColor {
  Default = 'yellow',
  Exchange = 'blue',
  Return = 'red',
  Remake = 'purple',
  Adjust = 'yellow', // TODO: Figure out which color to use for adjust
}

/**
 * Props for the RMAType component.
 */
interface RMATypeProps {
  /**
   * The order item associated with the RMA type.
   */
  orderItem: LocalOrderItemInput;

  /**
   * The list of return reasons.
   */
  returnReasons: ListItem[] | null;

  /**
   * Indicates whether return reasons are currently being loaded.
   */
  isReturnReasonsLoading: boolean;

  /**
   * The error encountered while loading return reasons.
   */
  returnReasonsError: Error | null;

  /**
   * Indicates whether this is an order update.
   */
  isOrderUpdate: boolean;

  /**
   * The state of the product.
   */
  productState: ProductState;

  /**
   * Indicates whether this is a legacy order.
   */
  isLegacyOrder: boolean;
}

/**
 * Component responsible for managing the RMA (Return Merchandise Authorization) type selection and related actions.
 *
 * @param orderItem - The order item associated with the RMA type.
 * @param returnReasons - The list of return reasons.
 * @param isReturnReasonsLoading - Indicates whether return reasons are currently being loaded.
 * @param returnReasonsError - The error encountered while loading return reasons.
 * @param isOrderUpdate - Indicates whether this is an order update.
 * @param productState - The state of the product.
 * @param isLegacyOrder - Indicates whether this is a legacy order.
 * @returns an RMA type component.
 */
export const RMAType: React.FC<RMATypeProps> = ({
  orderItem,
  returnReasons,
  isReturnReasonsLoading,
  returnReasonsError,
  isOrderUpdate,
  productState,
  isLegacyOrder,
}) => {
  const { products, onSubmitAlertCount, order } = useContext(OrderModuleContext);
  const { updateOrderItem, getMaterialAndResorationType, isValidProductReturnRequired, hasValidReturnTypes } =
    useContext(OrderModuleActionsContext);

  const returnType = orderItem.returnType;
  const billingAccountId = order.billingAccountId;

  const isExchange = returnType === ReturnType.Exchange;

  const editReturnTypePermission = useRoleBasedAccessControl(ACTIONS.EDIT_RETURN_TYPE);
  const hasEditAccess =
    editReturnTypePermission.allowAll || editReturnTypePermission.allowUpdate || editReturnTypePermission.allowCreate;
  const blockEditReturnValue = !hasEditAccess && isOrderUpdate;

  const toast = useContext(ToastContext);
  const alert = useContext(AlertModalContext);

  // Maps the ReturnType enum to the dropdown option format.
  const returnTypesOptions: DropdownModel[] = useMemo(() => {
    const options =
      isOrderUpdate || isLegacyOrder
        ? [ReturnType.Adjust, ReturnType.Remake, ReturnType.Exchange]
        : Object.keys(ReturnType);
    return (
      options.map(item => {
        return {
          primaryLabel: getReturnTypeLabel(item as ReturnType),
          value: item,
        };
      }) || []
    );
  }, [isOrderUpdate, isLegacyOrder]);

  const returnReasonOptions: DropdownOption[] = useMemo(
    () =>
      returnReasons?.map((item: ListItem) => {
        return {
          label: item.name,
          value: item.name,
        };
      }) || [],
    [returnReasons]
  );

  const selectedReturnReasons: DropdownOption[] = useMemo(() => {
    return (
      orderItem.returnReasons?.map((reason: string) => {
        return {
          label: reason,
          value: reason,
        };
      }) || []
    );
  }, [orderItem.returnReasons]);

  /**
   * Returns a formatted DropdownModel selection for the currently selected returnType.
   * @returns formatted DropdownModel selection.
   */
  const getReturnTypeSelection = () => {
    return returnType
      ? { primaryLabel: getReturnTypeLabel(returnType), value: returnType }
      : { primaryLabel: '', value: '' };
  };

  useEffect(() => {
    // If unable to load return reasons, shows a toast error message.
    if (returnReasonsError) {
      toast.notify(returnReasonsError.message || 'Unable to load return reasons', ToastNotificationType.Error);
    }
  }, [returnReasonsError, toast]);

  /**
   * Sets the color to be used for this container, based on the current return type selection for this order item.
   * Returns the container color.
   */
  const containerColor = useMemo(() => {
    let colorToSet: ContainerColor = ContainerColor.Default;
    switch (orderItem.returnType) {
      case ReturnType.Adjust:
        colorToSet = ContainerColor.Adjust;
        break;
      case ReturnType.Exchange:
        colorToSet = ContainerColor.Exchange;
        break;
      case ReturnType.Remake:
        colorToSet = ContainerColor.Remake;
        break;
      case ReturnType.ReturnForCredit:
        colorToSet = ContainerColor.Return;
        break;
    }
    return colorToSet;
  }, [orderItem.returnType]);

  /**
   * Updates the target order item with an updated version, provided by a handler method.
   * @param updatedOrderItemToPatch - The updated order item to be patched.
   */
  const handleOrderItemPatch = (updatedOrderItemToPatch: LocalOrderItemInput) => {
    updateOrderItem(orderItem.id, updatedOrderItemToPatch);
  };

  /**
   * Handler method that runs whenever a return type is selected.
   * @param selected - The selected return type.
   */
  const onReturnTypeSelect = (selected: DropdownModel) => {
    const selectedReturnType = ReturnType[selected.value as ReturnType];
    const localOrderItem = _.cloneDeep(orderItem);
    localOrderItem.returnType = selectedReturnType;
    handleOrderItemPatch(localOrderItem);
  };

  /**
   * Handler method that runs whenever a return reason is selected.
   * @param selected - The selected return reason(s).
   */
  const onReturnReasonSelect = (selected: DropdownOption[]) => {
    const localOrderItem = _.cloneDeep(orderItem);
    localOrderItem.returnReasons = selected.map(reason => reason.value);
    handleOrderItemPatch(localOrderItem);
  };

  /**
   * Handler method that runs whenever a user selects whether the target product has been returned.
   * @param selected - Whether the target product is returned.
   */
  const onIsProductReturnedSelect = (isReturned: boolean) => {
    const localOrderItem = _.cloneDeep(orderItem);
    localOrderItem.isOldProductReturned = isReturned;
    handleOrderItemPatch(localOrderItem);
  };

  const editCaseHandler = () => {
    alert.show(PERMISSION_DENIED, PERMISSION_DENIED_TEXT);
  };

  const oldProductReturnedErrorMessage = useMemo(() => {
    if (!onSubmitAlertCount) return '';
    const isValid = isValidProductReturnRequired(orderItem);
    return !isValid ? ErrorMessage.PRODUCT_RETURN_REQUIRED : '';
  }, [onSubmitAlertCount, isValidProductReturnRequired, orderItem]);

  const onMaterialAndRestorationSelect = (productCode: string | null) => {
    const productClassification = productCode ? getMaterialAndResorationType(productCode) : undefined;

    if (!productClassification) {
      orderItem.newProduct = undefined;
    } else {
      const { materialName, restorationName } = productClassification;
      const newProduct = products.find(
        product => product.materialName === materialName && product.restorationName === restorationName
      );

      if (newProduct) {
        orderItem.newProduct = {
          productCode: newProduct.productCode,
          productName: getProductName(materialName, restorationName),
        };
      } else {
        orderItem.newProduct = undefined;
      }
    }

    handleOrderItemPatch(orderItem);
  };

  const newProductClassification = useMemo(() => {
    if (!isExchange) return;
    const newProductCode = orderItem.newProduct?.productCode;
    if (!newProductCode) return;
    return getMaterialAndResorationType(newProductCode);
  }, [orderItem.newProduct?.productCode, getMaterialAndResorationType, isExchange]);

  const returnTypeErrorMessage = useMemo<string | undefined>(() => {
    if (onSubmitAlertCount <= 0 || hasValidReturnTypes()) return;
    return ErrorMessage.INVALID_RETURN_TYPE_TITLE;
  }, [onSubmitAlertCount, hasValidReturnTypes]);

  return (
    <div className={classNames([`bg-${containerColor}-50`], 'w-full px-6 py-4')}>
      <div className="mb-4 text-sm">
        Previous Case:{' '}
        {isDigitalOrder(order.localMetadata.previousOrderType) ? OrderType.DIGITAL : OrderType.CONVENTIONAL}
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-4 gap-x-6 gap-y-4">
        <div onClick={() => (blockEditReturnValue ? editCaseHandler() : nullFunction())}>
          <div
            className={cn({
              'pointer-events-none': blockEditReturnValue,
            })}
          >
            <Dropdown
              data={returnTypesOptions}
              label="Return Type"
              selected={getReturnTypeSelection()}
              setSelected={onReturnTypeSelect}
              isRequired={true}
              onSubmitAlert={!!onSubmitAlertCount}
              errorMessage={returnTypeErrorMessage}
            />
          </div>
        </div>
        <div className="col-span-2">
          <MultiSelectDropdown
            dropdownOptions={returnReasonOptions}
            label="Return Reason"
            values={selectedReturnReasons}
            onSelectedValueChange={onReturnReasonSelect}
            dataQa="returnReasonSelect"
            isRequired={true}
            isLoading={isReturnReasonsLoading}
            placeholder="Select one or more"
            onSubmitAlert={!!onSubmitAlertCount}
          />
        </div>
        <div>
          <YesNo
            isRequired={true}
            value={orderItem.isOldProductReturned}
            label="Old product returned?"
            onValueChange={onIsProductReturnedSelect}
            errorMessage={oldProductReturnedErrorMessage}
          />
        </div>
      </div>
      {isExchange && (
        <ProductSearch
          materialLabel="New Material"
          productState={productState}
          billingAccountId={billingAccountId}
          disabled={productState === ProductState.AttributesLoading}
          onMaterialAndRestorationSelect={onMaterialAndRestorationSelect}
          initialMaterialName={newProductClassification ? newProductClassification.materialName : ''}
          initialRestorationName={newProductClassification ? newProductClassification.restorationName : ''}
        />
      )}
    </div>
  );
};
