import {
  Account,
  AccountStanding,
  Address,
  AddressInput,
  AddressType,
  CollectionType,
  Email,
  EmailType,
  LegacyOrder,
  Maybe,
  Order,
  OrderItem,
  OrderStatus,
  PendingOrder,
  Phone,
  PhoneType,
  ProductReturnRequirement,
  ReturnType,
  RmaOrder,
  RmaOrderItem,
  RmaOrderItemInput,
  Shipping,
  ShippingChargeType,
  ShippingOptionsInput,
} from 'API';
import classNames from 'classnames';
import { DesignFileTypes, DocumentFileTypes, ImageFileTypes } from 'configurations/OrderEntryConfigurations';
import { find, map } from 'lodash';
import moment from 'moment-timezone';
import {
  AttributeType,
  FileType,
  ILegacyOrderData,
  IOrderData,
  IPendingOrderData,
  IRmaOrderData,
  OrderTypeName,
} from 'shared/enums';
import { twMerge } from 'tailwind-merge';
import { DisplayDiscountItemType, OrderTypenameCheckParam } from 'types/common';
import { v4 as uuidv4 } from 'uuid';
import { InvoiceAccount } from './api/invoice.api';
import { productReturnRequirement } from './api/product.api';
import { CaseStatus } from './constants/constants';
import { caseInvoiceCompleteStatuses, caseInvoicedStatuses } from './constants/invoice.constants';
import { fetchCouponsByCode } from './helpers/coupon.helper';
import { getDiscountCouponItem } from './helpers/invoice/invoice.helper';
import { CreatedOrder, LocalOrderProductAttributeInput } from './models';

const dateShortFormat = 'MM/DD/YYYY';
const dateLongFormat = 'MMMM DD, YYYY';
const timeFormat = 'h:mm A';

/**
 * determines if a file chosen is a Document or Image or a Design file
 *
 * @param fileName - name of file whose file type needs to be known
 * @returns suggesting file type
 *
 */
export const getFileType = (fileName: string): FileType => {
  const extension: string | undefined = fileName.split('.').pop();
  if (!extension) {
    return FileType.Document;
  }
  if (DocumentFileTypes.some(d => d === extension.toLowerCase())) {
    return FileType.Document;
  }

  if (ImageFileTypes.some(i => i === extension.toLowerCase())) {
    return FileType.Image;
  }

  if (DesignFileTypes.some(d => d === extension.toLowerCase())) {
    return FileType.Design;
  }
  return FileType.Document;
};

/**
 * converts seconds provided, into Human readable (Mm : Ss) format
 *
 * @param seconds - represents time period in seconds which needs to be formatted
 *
 * @example
 *
 * const duration = getFormattedTimeDuration(80)
 * console.log(duration) // prints '1m 20s'
 */
export const getFormattedTimeDuration = (seconds: number): string => {
  const minutes = Math.trunc(seconds / 60);
  seconds = seconds % 60;
  return minutes > 0 ? `${minutes}m ${seconds}s` : `${seconds}s`;
};

/**
 * converts the byte size of file provided, into Human readable format ( MB or KB or B)
 *
 * @param fileSizeInBytes - size of file of interest in bytes
 */
export const getFormattedFileSize = (fileSizeInBytes: number): string => {
  const mb = 1000000;
  const kb = 1000;
  if (fileSizeInBytes > mb) {
    return `${(fileSizeInBytes / mb).toFixed()} MB`;
  } else if (fileSizeInBytes > kb) {
    return `${(fileSizeInBytes / kb).toFixed()} KB`;
  } else {
    return `${fileSizeInBytes.toFixed()} B`;
  }
};

/**
 * replaces a positional tokens in target, with corresponding elements provided in replacementArray
 *
 *
 * @param target - string whose positional targets needs to replaced
 * @param replacementArray - array of replacements, which needs to substituted in target
 *
 * @example
 *
 *```ts
 * const replacedString = replaceToken('test {1}', ['one', 'two'])
 * console.log(replacedString) // prints 'test one two;
 * ```
 */
export const replaceToken = (target: string, replacementArray: Array<string>): string => {
  // This replace will only find 'tokens' in the form of {0}. Malformed may not be caught.
  if (!target) return '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return target.replace(/({\d})/g, (j: any) => {
    return replacementArray[j.replace(/{/, '').replace(/}/, '')];
  });
};

/**
 * Validates if a given value is a valid Date object.
 *
 * @param date - The value to be validated.
 * @returns Returns true if the value is a valid Date object, otherwise returns false.
 */
export const validateDate = (date: Date) => {
  if (Object.prototype.toString.call(date) !== '[object Date]') return false;
  // it is a date
  if (isNaN(date.getTime())) {
    // d.valueOf() could also work
    // date is not valid
    return false;
  } else {
    // date is valid
    return true;
  }
};

/**
 * Checks if a value is a valid Date object.
 * @param value - The value to be checked.
 * @returns True if the value is a valid Date object, false otherwise.
 */
export const isDate = (value: unknown): value is Date => {
  try {
    if (!value) return false;
    const date = new Date(value as string);
    return validateDate(date);
  } catch (error) {
    return false;
  }
};

/**
 * formats an input value to human-readable Date format
 *
 * @param input - value to be formatted
 * @param timezone - timezone of interest
 * @param returnWithTime - should include Time in returned output
 *
 * @returns Human readable formatted date
 * sample output, January 11, 2022
 */
export const getFormattedDate = (input?: string, timezone?: string, returnWithTime?: boolean): string => {
  if (!input) {
    return '';
  }

  try {
    return moment
      .tz(input, timezone || moment.tz.guess())
      .format(returnWithTime ? `${dateLongFormat}, ${timeFormat}` : dateLongFormat);
  } catch {
    return '';
  }
};

/**
 * formats an input value to human-readable Date format
 * @param input - value to be formatted
 * @param timezone - timezone of interest
 *
 * @returns Human readable formatted date in extended format
 * sample output, `January 11, 2022 at 08:49 AM`
 */
export const getDateAndTimeInLongFormat = (input?: Date | null | string, timezone?: string | null): string => {
  const date = moment.tz(input ? new Date(input) : new Date(), timezone || moment.tz.guess());
  return date.format(`MMMM DD, YYYY [at] ${timeFormat}`);
};

/**
 * formats an input value to human-readable Date format
 * @param input - value to be formatted
 * @param timezone - timezone of interest
 * @param customText - text to substituted in formatted output
 *
 * @returns Human readable formatted date in shorthand format
 * sample output, 01/11/2022 08:49 AM
 */
export const getDateAndTimeInShortFormat = (
  input: Date | string,
  timezone?: Maybe<string>,
  customText?: string
): string => {
  if (!input) {
    return '';
  }

  try {
    return moment
      .tz(input, timezone || moment.tz.guess())
      .format(`${dateShortFormat}${customText ? ` [${customText}] ` : ' '}${timeFormat}`);
  } catch {
    return '';
  }
};

/**
 * checks if given date is valid
 * @param date - date of interest, which needs to be checked for validness
 * @returns true if date provided is valid, else false
 */
export const isValidDate = (date: Date): boolean => {
  return date instanceof Date && !isNaN(date.getTime());
};

/**
 * formats an input value to human-readable Date format
 * @param input - value to be formatted
 * @param timezone - timezone of interest
 *
 * @returns human readable formatted date in shorthand form
 * sample output, 01/11/2022
 */
export const getDateInShortFormat = (input: Date | string | undefined, timezone?: string | null): string => {
  if (!input) return '';
  return moment.tz(input, timezone || moment.tz.guess()).format(dateShortFormat);
};

/**
 * checks if character is a numerical
 * @param value - value of interest, which needs to be checked if it is a number
 * @returns true if value is a number, else false
 */
export const isNumeric = (value: string): boolean => {
  return /^\d+$/.test(value?.trim());
};

/**
 * checks if a given value is valid tooth number, i.e. 1 - 32
 * @param value - value which needs to be checked if it is a valid tooth number
 * @returns true if value is valid tooth number, else false
 */
export const isValidToothNumber = (value: string): boolean => {
  if (value.length !== 0 && (!isNumeric(value) || +value <= 0 || +value > 32)) {
    return false;
  }
  return true;
};

/**
 * converts a given currency value to required currency denomination
 * @param value - price which needs to be converted
 * @param currencyCode - code of required currency
 *
 * @returns formatted converted currency
 * sample output, 1 =\> "$1.00"
 */
export const convertToCurrency = (value: number | null | undefined, currencyCode = 'USD'): string => {
  const intl = new Intl.NumberFormat('en-US', { style: 'currency', currency: currencyCode, minimumFractionDigits: 2 });
  return intl.format(value ?? 0);
};

/**
 * removes spaces in a string provided
 *
 * @returns a string without any spaces
 * "one Ctrl Two" =\> "oneCtrlTwo"
 */
export const removeSpaces = (value: string): string => {
  return value.replace(/\s/g, '');
};

/**
 * formats a given string into camel cased string
 * @returns camel cased string output
 * sample output: "Order Number Input" =\> "orderNumberInput"
 */
export const convertToCamelCase = (...input: string[]): string => {
  const parsedInput: string[] = [];
  // Pull out input items that have space an add to new array
  input.forEach(item => {
    const splitItem = item.split(' ');
    parsedInput.push(...splitItem);
  });
  return parsedInput
    .filter(word => word.length > 0)
    .map((word, index) => {
      return (index === 0 ? word[0].toLowerCase() : word[0].toUpperCase()) + word.slice(1).toLowerCase();
    })
    .join('');
};

/**
 * Returns camel case conversion to title case.
 * @param value - The string value to convert.
 * @returns camel case conversion to title case (i.e. "productionFacility" =\> "Production Facility")
 */
export const convertCamelCaseToTitleCase = (value = '') => {
  return value.replace(/([A-Z])/g, ' $1').replace(/^./, firstChar => firstChar.toUpperCase());
};

// "ONE TWO THREE" => "One Two Three"
export const convertToTitleCase = (sentence: string) => {
  return sentence.replace(/\w\S*/g, txt => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getAlphaNumericCharacters = (value: string): string => {
  const alphanumericRegex = /^[a-zA-Z0-9 ]*$/g;
  if (alphanumericRegex.test(value)) {
    return value;
  } else {
    return value.slice(0, value.length - 1);
  }
};

// Valid alphanumeric regex for this project, consisting of standard English characters, all positive numbers, and certain accented characters.
export const alphanumericRegexPattern = 'a-zA-Z0-9À-úàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ';

/**
 * Formats a patient name so that it only includes alphanumeric characters, apostrophes, and spaces.
 * @param value - The patient name to be formatted.
 * @returns formatted patient name.
 */
export const formatPatientName = (value: string) => {
  const regex = new RegExp(`[^${alphanumericRegexPattern}|' ]`, 'g');
  return value.replace(regex, '');
};

/**
 * Formats a patient ID so that it only includes alphanumeric characters and dashes.
 * @param value - The patient ID to be formatted.
 * @returns formatted patient ID.
 */
export const formatPatientId = (value: string) => {
  const regex = new RegExp(`[^${alphanumericRegexPattern}|-]`, 'g');
  return value.replace(regex, '');
};

export const UPPER_TEETH: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
export const LOWER_TEETH: number[] = [32, 31, 30, 29, 28, 27, 26, 25, 24, 23, 22, 21, 20, 19, 18, 17];
export const ALL_TEETH = UPPER_TEETH.concat(LOWER_TEETH).sort((a, b) => {
  if (a < b) {
    return -1;
  }
  if (b > a) {
    return 1;
  }
  return 0;
});

export const downloadBlobFile = (blob: Blob, fileName: string): void => {
  const url = window.URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();

  window.URL.revokeObjectURL(url);
};

/**
 * This is a TypeScript function that merges multiple class names into a single string using the
 * `classNames` library.
 * @param inputs - The `inputs` parameter is a rest parameter that allows the function to accept any
 * number of arguments as an array. In this case, the `inputs` parameter is of type
 * `classNames.ArgumentArray`, which is a type alias for `Array<string | undefined | false | null>`.
 * This means that
 * @returns The `cn` function is returning the result of calling the `twMerge` function with the result
 * of calling the `classNames` function with the spread `inputs` array. The `classNames` function is a
 * utility function that concatenates class names together, while the `twMerge` function is a utility
 * function that merges tailwind classes together. Therefore, the `cn` function is returning a string
 */
export function cn(...inputs: classNames.ArgumentArray) {
  return twMerge(classNames(inputs));
}

export const getShippingAddress = (shippingAddress: Shipping['address'] | AddressInput) => {
  if (!shippingAddress) return '';
  const address = [shippingAddress.street1];
  if (shippingAddress.street2) {
    address.push(shippingAddress.street2);
  }
  address.push(shippingAddress.city);
  address.push(`${shippingAddress.state} ${shippingAddress.zipcode}`);
  return address.join(', ');
};

export const getSeparatedShippingAddress = (shippingAddress: Shipping['address']) => {
  if (!shippingAddress) return null;
  const { street1, street2, city, state, zipcode } = shippingAddress;
  const line1 = `${street1}${street2 ? ` ${street2}` : ''}`;
  const line2 = `${city} ${state} ${zipcode}`;

  return {
    line1,
    line2,
  };
};

/**
 * The function checks if a given order status is invoiced or pending invoicing.
 * @param caseStatus - an optional parameter of type `OrderStatus`. It
 * represents the current status of an order case. If it is not provided, the function will return
 * `false`.
 * @returns The function `checkCaseIsInvoiced` returns a boolean value. It returns `true` if the
 * `caseStatus` parameter is one of the invoiced statuses (`OrderStatus.Invoiced`,
 * `OrderStatus.InvoicePending`, or `OrderStatus.Shipped`), and `false` otherwise. If the `caseStatus`
 * parameter is not provided, the function returns `false`.
 */
export const checkCaseIsInvoiced = (caseStatus?: OrderStatus) => {
  if (!caseStatus) return false;
  return caseInvoicedStatuses.includes(caseStatus);
};

/**
 * Checks if a case invoice is completed based on the provided case status.
 * @param caseStatus - The case status to check.
 * @returns True if the case invoice is completed, false otherwise.
 */
export const checkCaseIsInvoiceCompleted = (caseStatus?: OrderStatus) => {
  if (!caseStatus) return false;
  return caseInvoiceCompleteStatuses.includes(caseStatus);
};

export const getEmailAddress = (emailAddresses: Email[] | undefined, type: EmailType) => {
  if (!emailAddresses || !emailAddresses.length) return '';
  return emailAddresses.find(email => email.type === type)?.emailAddress || '';
};

export const getPhone = (phoneNumbers: Phone[] | undefined, type: PhoneType): Omit<Phone, '__typename'> | undefined => {
  // The services team has requested that we not send them phone information if the phone number is blank. Please see LMS1-7140.
  const foundNumber = phoneNumbers?.find(phone => phone.type === type && !!phone.number);
  if (!phoneNumbers || !phoneNumbers.length || !foundNumber) return undefined;
  return foundNumber;
};

export const getPhoneNumber = (phoneNumbers: Phone[] | undefined, type: PhoneType) => {
  return getPhone(phoneNumbers, type)?.number || '';
};

export const getAddress = (addresses: Address[] | undefined, type: AddressType) => {
  if (!addresses || !addresses.length) return null;
  return addresses.find(address => address.type === type);
};

/**
 * Order that phone number types should be used for accounts.
 * Need this since accounts can have multiple phone numbers and there is no guarantee that
 * any one type exists.
 * @param phoneNumbers - the numbers to sort.
 * @returns the phone number we found.
 */
export const getPhoneToUse = (phoneNumbers: Phone[]) => {
  const officePhoneNumber = getPhone(phoneNumbers, PhoneType.Office);
  const homePhoneNumber = getPhone(phoneNumbers, PhoneType.Home);
  return officePhoneNumber || homePhoneNumber;
};

/**
 * Returns true if the target product code is for the COD Returned Item product.
 * @param productCode - The product code to be checked.
 * @returns true if the target product code is for the COD Returned Item product.
 */
export const isProductCodReturnedItem = (productCode: string) => productCode === 'SV-SV-CI-LO';

/**
 * Returns whether the target account standing is OnCod or AlwaysOnCod.
 * OnCod means the customer is required to pay on delivery due to past issues.
 * AlwaysOnCod means the customer insists on paying on delivery.
 * @param accountStanding - The target account standing to be checked.
 * @returns whether the target account standing is on COD.
 */
export const isAccountOnCod = (accountStanding?: AccountStanding) => {
  const isAccountStandingOnCod = accountStanding === AccountStanding.OnCod;
  const isAccountStandingAlwaysOnCod = accountStanding === AccountStanding.AlwaysOnCod;
  return isAccountStandingOnCod || isAccountStandingAlwaysOnCod;
};
/**
 * Creates the shippingOptionsInput required to request the shipping options by using the getRequestOptions function
 * @param invoiceCase - The main LMS case for which the shipping will be created
 * @param invoiceCases - All the invoice cases, this allows bundled orders to be shown in the secondary reference
 * @param account - The account which requests the invoice
 * @param subtotalAmount - Cost without shipping
 * @param codAmount - COD amount if any
 * @returns a request body {@link ShippingOptionsInput}
 */
export const getShippingOptionsRequestBody = (
  invoiceCase: CreatedOrder,
  invoiceCases: CreatedOrder[],
  account: InvoiceAccount | null,
  subtotalAmount: number | null,
  codAmount: number
): ShippingOptionsInput => {
  const billingAccountId = invoiceCase.billingAccountId;
  const originFacilityId = invoiceCase.originFacilityId;
  const recipientAddress = invoiceCase.shipping?.address;

  if (!billingAccountId) throw new Error('Billing Account ID does not exist');
  if (!originFacilityId) throw new Error('Origin Facility ID does not exist');
  if (!recipientAddress) throw new Error('Recipient Address does not exist');
  if (!account) throw new Error('Account does not exist');

  const officeEmail = getEmailAddress(account.emailAddresses, EmailType.Office);
  const personalEmail = getEmailAddress(account.emailAddresses, EmailType.Personal);

  const invoiceNumbers = invoiceCases
    .filter(c => c.orderNumber !== invoiceCase.orderNumber) // Every order that's different to the main one
    .map(i => i.orderNumber) // we just want the order numbers
    .join(', '); // if more than one case is bundled this will join them together in an ordered manner

  const shippingOptionsInput: ShippingOptionsInput = {
    invoiceNumber: invoiceCase.orderNumber,
    originFacilityId: originFacilityId,
    recipient: {
      name: invoiceCase.providerName,
      company: account.practiceName,
      address: {
        city: recipientAddress.city,
        country: recipientAddress.country,
        state: recipientAddress.state,
        street1: recipientAddress.street1,
        street2: recipientAddress.street2 || '',
        zipcode: recipientAddress.zipcode,
      },
      email: {
        emailAddress: officeEmail || personalEmail,
      },
      phone: {
        number: getPhoneToUse(account.phoneNumbers)?.number ?? '',
      },
    },
    primaryReferenceField: `${invoiceCase.orderNumber}/${billingAccountId}`,
    secondaryReferenceField: invoiceNumbers || invoiceCase.orderNumber,
  };

  if (isAccountOnCod(account.standing)) {
    const shippingCharges = invoiceCase?.shipping?.shippingCharges;
    const inboundShippingRate = find(shippingCharges, { type: ShippingChargeType.Inbound })?.totalAmount || 0;
    const collectionAmount =
      codAmount + (subtotalAmount || 0) + inboundShippingRate + (invoiceCase.totalTaxAmount || 0);
    // As per LMS1-7537, we should only attach collectOnDelivery for orders with a collection amount over 0.
    if (collectionAmount && collectionAmount > 0) {
      shippingOptionsInput.collectOnDelivery = {
        collectionAmount: collectionAmount,
        collectionMethod: CollectionType.Cash,
      };
    }
  }

  return shippingOptionsInput;
};

export const getDiscountCoupons = async (invoiceCases: CreatedOrder[]) => {
  const coupons: DisplayDiscountItemType[] = [];
  await Promise.all(
    invoiceCases.map(async invoiceCase => {
      if (invoiceCase?.coupons && invoiceCase.coupons.length) {
        const freshCoupons = await fetchCouponsByCode(invoiceCase.coupons);
        const invoiceCoupons = freshCoupons.map(coupon => getDiscountCouponItem(coupon, invoiceCase.orderNumber));
        coupons.push(...invoiceCoupons);
      }
    })
  );
  return coupons;
};

/**
 * checks if account is on credit hold
 * @param accountStanding - standing status of given account
 * @returns - true if status is on credit hold, otherwise false
 */
export const isAccountCreditHold = (accountStanding: Account['standing'] | undefined) => {
  if (!accountStanding) return false;
  const accountCreditHolds = [AccountStanding.AlwaysOnCreditHold, AccountStanding.OnCreditHold];
  return accountCreditHolds.includes(accountStanding);
};

/**
 * checks if a value is positive number
 * @param value - whose values needs to be checked
 * @returns true if value is a positive number, otherwise false
 */
export const isPositiveNumber = (value: string): boolean => {
  return /^\d*$/.test(value) && +value > 0;
};

/**
 * checks if a value is positive decimal number
 * @param value - whose values needs to be checked
 * @returns - true if value is a positive decimal, otherwise false
 */
export const isPositiveNumberWithDecimal = (value: string): boolean => {
  return /^\d*\.?\d*$/.test(value);
};

export const generateUniqueId = () => uuidv4();

export const isRestorationTypeBridge = (type: string): boolean => {
  return type.includes('Bridge');
};
export const isRestorationTypeSingle = (type: string): boolean => {
  return type.includes('Single');
};

/**
 * a function which returns null,
 * can be used in conditional rendering
 */
export const nullFunction = () => null;

/**
 * Get the selected tooth array with missing teeth filtered out.
 * @param attributeItems - attributes to use to validate properties.
 * @param selectedToothValues - the array of selected teeth.
 * @param attributeType - attribute type we are acting on.
 * @returns filtered tooth list.
 */
export const getSelectedToothRange = (
  attributeItems: LocalOrderProductAttributeInput[] | undefined,
  selectedToothValues: string[],
  attributeType?: string
) => {
  if (attributeType === AttributeType.MissingTooth) return selectedToothValues;
  const missingTooth = find(attributeItems, { name: AttributeType.MissingTooth });
  if (!missingTooth || !missingTooth.value) return selectedToothValues;

  const missingToothArray = missingTooth.value.replace(/#/g, '').split(',');
  return selectedToothValues.filter(tooth => !missingToothArray.includes(tooth));
};

export const getPatientFullName = (
  patientFirstName: Maybe<string> | undefined,
  patientLastName: Maybe<string> | undefined
) => {
  return `${patientFirstName ?? ''} ${patientLastName ?? ''}`.trim();
};

export const isOrderTypename = <T extends OrderTypenameCheckParam>(order: T): order is T & IOrderData => {
  return isOrderData(order);
};

export const isRmaOrderTypename = <T extends OrderTypenameCheckParam>(order: T): order is T & IRmaOrderData => {
  return isRmaOrderData(order);
};

export const isPendingOrderTypename = <T extends OrderTypenameCheckParam>(order: T): order is T & IPendingOrderData => {
  return isPendingOrderData(order);
};

export const isLegacyOrderTypename = <T extends OrderTypenameCheckParam>(order: T): order is T & ILegacyOrderData => {
  return isLegacyOrderData(order);
};

export const isOrderData = (order: OrderTypenameCheckParam): order is Order => {
  return order?.__typename === OrderTypeName.Order;
};

export const isRmaOrderData = (order: OrderTypenameCheckParam): order is RmaOrder => {
  return order?.__typename === OrderTypeName.RmaOrder;
};

export const isPendingOrderData = (order: OrderTypenameCheckParam): order is PendingOrder => {
  return order?.__typename === OrderTypeName.PendingOrder;
};

/**
 * Checks if an order has been entered into our system
 */
export const isCreatedOrder = (order: OrderTypenameCheckParam): order is CreatedOrder => {
  return isOrderData(order) || isRmaOrderData(order);
};

/**
 * checks if the order is originated form a legacy system like Elektra
 * @param order - represents order which needs to be checked if it's a Legacy order or not
 */
export const isLegacyOrderData = (order: OrderTypenameCheckParam): order is LegacyOrder => {
  return order?.__typename === OrderTypeName.LegacyOrder;
};

/**
 * fetches product return requirements for given order Items
 * @param orderItems - order items whose return requirements are to be fetched
 * @returns - list of fetched product return requirements
 */
export const getProductReturnRequirements = async (
  orderItems: Array<{
    isOldProductReturned?: RmaOrderItem['isOldProductReturned'];
    productCode: RmaOrderItem['productCode'];
  }> = []
) => {
  /**
   * For any order item whose old product was not returned,
   * Makes a request to verify if a return is required.
   */
  const productReturnPromises: Promise<ProductReturnRequirement>[] = [];
  orderItems.forEach(orderItem => {
    if (!orderItem.isOldProductReturned) {
      productReturnPromises.push(productReturnRequirement(orderItem.productCode));
    }
  });
  return await Promise.all(productReturnPromises);
};

export interface ProductReturnRequirementData {
  returnRequiredProductCodes: string[];
  nonReturnRequiredProductCodes: string[];
  hasProductIsReturnRequired: boolean;
  hasProductIsNonReturnRequired: boolean;
}

/**
 * Fetches the product return requirements for the given order items.
 *
 * @param orderItems - An array of RmaOrderItemInput objects representing the order items.
 * @returns A Promise that resolves to a ProductReturnRequirementData object containing the return requirements.
 */
export const fetchOrderItemWithReturnRequirement = async (
  orderItems: RmaOrderItemInput[] = []
): Promise<ProductReturnRequirementData> => {
  const productReturnRequirements = await getProductReturnRequirements(orderItems);

  const returnRequiredProductCodes: string[] = [];
  const nonReturnRequiredProductCodes: string[] = [];
  productReturnRequirements.forEach(requirement => {
    if (requirement.isReturnRequired) {
      returnRequiredProductCodes.push(requirement.productCode);
    } else {
      nonReturnRequiredProductCodes.push(requirement.productCode);
    }
  });

  return {
    returnRequiredProductCodes,
    nonReturnRequiredProductCodes,
    hasProductIsReturnRequired: returnRequiredProductCodes.length > 0,
    hasProductIsNonReturnRequired: nonReturnRequiredProductCodes.length > 0,
  };
};

type OutboundApplicableIndexCase = {
  __typename: string;
  orderItems: Array<{
    __typename: string;
    productCode: OrderItem['productCode'];
    returnType?: RmaOrderItem['returnType'];
    isOldProductReturned?: RmaOrderItem['isOldProductReturned'];
  }>;
};

/**
 * Find the index of the first case that can include outbound shipping.
 * @param invoiceCases - the cases we are invoicing.
 * @param productReturnRequirements - the product return requirements for the cases.
 * @returns the index of the first case that can include outbound shipping. Returns -1 if no cases can include shipping.
 */
export const getOutboundApplicableIndex = (
  invoiceCases: OutboundApplicableIndexCase[],
  productReturnRequirements: ProductReturnRequirement[]
): number => {
  return invoiceCases.findIndex(item => {
    /**
     * Business requirement to charge shipping on RMA Remake cases that did not have their old product returned,
     * despite it being required for that particular product.
     */
    return (
      !isRmaOrderTypename(item) ||
      !item.orderItems ||
      item.orderItems?.some(rmaOrderItem => {
        const { returnType, isOldProductReturned, productCode } = rmaOrderItem;
        const isReturnRequired =
          productReturnRequirements?.find(requirement => requirement.productCode === productCode)?.isReturnRequired ||
          false;
        const isNonReturnedProductWithReturnRequirement = !isOldProductReturned && isReturnRequired;
        return returnType === ReturnType.Remake && isNonReturnedProductWithReturnRequirement;
      })
    );
  });
};

/**
 * Return the case that is eligible to be charged if there is one.
 * @param invoiceCases - the cases to check for charge eligibility.
 * @param productReturnRequirements - the product return requirements for the cases.
 * @returns the charge eligible case or undefined.
 */
export const getOutboundApplicableCase = <T extends OutboundApplicableIndexCase>(
  invoiceCases: T[],
  productReturnRequirements: ProductReturnRequirement[]
) => {
  if (!invoiceCases.length) return;
  const index = getOutboundApplicableIndex(invoiceCases, productReturnRequirements);
  if (index === -1) return;
  return invoiceCases[index]; // Return the applicable index or the first case.
};

export const invalidInvoiceCaseStatuses = [
  OrderStatus.Cancelled,
  OrderStatus.Deleted,
  OrderStatus.OnHold,
  OrderStatus.Pending,
  OrderStatus.CallCenter,
];

/**
 * Check if a case is in an invalid invoice status.
 * Special case: If a case is cancelled however the status reason is BackToCustomer,
 * The case is allowed to be invoiced (to be sent back to the customer).
 * @param caseStatus - Status of the case
 * @param statusReason - Action to do for cancellation
 * @returns if a case is invalid for invoicing
 */
export const checkCaseInvalidInvoiceStatus = (caseStatus?: OrderStatus, statusReason?: string | null) => {
  if (!caseStatus) return false;

  if (caseStatus === OrderStatus.Cancelled && statusReason === 'Send back to the customer') {
    return false;
  }

  return invalidInvoiceCaseStatuses.includes(caseStatus);
};

/**
 * Retrieves the bundle ID from the given order.
 * @param order - The order object.
 * @returns The bundle ID if it exists, otherwise null.
 */
export const getBundleId = (order: CreatedOrder | undefined | null) => {
  if (!order) return null;
  if (checkCaseIsInvoiced(order.status)) return null;
  return order.bundles?.splitBundle?.bundleId || null;
};

/**
 * Retrieves information about a split bundle case.
 * @param order - The order object.
 * @returns An object containing information about the split bundle case.
 */
export const getSpiltBundleCaseInfo = (order: CreatedOrder | undefined | null) => {
  const bundleId = getBundleId(order) || '';
  const orderNumber = order?.orderNumber;
  const bundledOrders = order?.bundles?.splitBundle?.bundledOrderNumbers || [];
  const detachedOrders = order?.bundles?.splitBundle?.detachedOrderNumbers || [];
  const bundledOrderNumbers = map(bundledOrders, 'orderNumber');
  const isBundle = !!bundleId && !!orderNumber && bundledOrderNumbers.includes(orderNumber);

  return {
    bundleId, // The bundle ID of the split bundle case. detachedOrders also have a bundle ID, So if you really want to check if a case is a bundle, use `isBundle` before directly use the `bundleId`.
    isBundle, // Whether the case is a bundle or not. Before direct
    bundledOrders,
    detachedOrders,
    bundledOrderNumbers,
  };
};

/**
 * Parses the given JSON string or object into the specified type.
 * If the input is a string, it will be parsed using JSON.parse().
 * If the input is already of the specified type, it will be returned as is.
 * If there is an error parsing the JSON, an error message will be logged and null will be returned.
 *
 * @param json - The JSON string or object to parse.
 * @returns The parsed object of type T, or null if there was an error parsing the JSON.
 */
export const jsonParser = <T>(json: unknown) => {
  try {
    if (typeof json === 'string') {
      return JSON.parse(json) as T;
    } else {
      return json as T;
    }
  } catch (e) {
    const message = 'Error parsing json';
    console.error(message, e, json);
    return null;
  }
};

/**
 * @param value - object of interest, whose type needs to be checked
 * @returns if value passed is an object or not
 */
export const isObject = (value: unknown): value is Record<PropertyKey, unknown> => {
  return typeof value === 'object' && value !== null;
};

/**
 * Checks if all fields in an object are empty.
 * @param obj - The object to check.
 * @returns True if all fields are empty, false otherwise.
 */
export const isObjectFieldEmpty = (obj: Record<PropertyKey, unknown>) => {
  return Object.values(obj).every(val => !val);
};

/**
 * Retrieves the case status record based on the provided status.
 * @param status - The status of the order.
 * @returns An object containing the display name, text color, and background color of the case status.
 */
export const getCaseStatusRecord = (
  status: OrderStatus | undefined | null
): { displayName: string; textColor: string; bgColor: string } => {
  const fallback = {
    displayName: '',
    textColor: '',
    bgColor: '',
  };
  if (!status) return fallback;
  return CaseStatus[status] || fallback;
};

/**
 * When a case is invoiced, the account shipping COD amount will be available and should be used as the COD amount.
 * For orders that have not yet been invoiced, the shipping COD amount will not be available and so we should use
 * the COD amount on the target billing account instead.
 * @param accountCodAmount - the target account COD amount.
 * @param orderShippingCodAmount - the COD amount for the target order.
 * @param orderStatus - the status of the order.
 * @returns the COD amount to use for the target order.
 */
export const getOrderCodAmount = (
  accountCodAmount: number,
  orderShippingCodAmount: number,
  orderStatus: OrderStatus | undefined
) => {
  if (checkCaseIsInvoiced(orderStatus)) {
    return orderShippingCodAmount;
  }

  return accountCodAmount;
};
