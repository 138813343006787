import { AdditionalDetailsFallback, AdditionalDetailsFallbackProps } from '../AdditionalDetailsFallback';
/**
 * Props of the AdditionalDetailsShippingFallback component.
 */
type AdditionalDetailsShippingFallbackProps<TData extends Record<string, unknown>> =
  AdditionalDetailsFallbackProps<TData>;

/**
 * Component for displaying additional shipping details.
 * @param props - The properties of the AdditionalDetailsShippingFallback component.
 * @returns JSX element representing the AdditionalDetailsShippingFallback component.
 */
export const AdditionalDetailsShippingFallback = <TData extends Record<string, unknown>>(
  props: AdditionalDetailsShippingFallbackProps<TData>
) => {
  return <AdditionalDetailsFallback {...props} />;
};
