import { Credit, CreditType, Deduction, DiscountType } from 'API';
import moment from 'moment';
import { couponLookup } from 'shared/api/discount.api';

/**
 * The function checks if a coupon has expired based on its expiration date.
 * @param expiration - The `expiration` parameter is a string representing the expiration date
 * of a coupon.
 * @returns a boolean value indicating whether the coupon has expired or not.
 */
export const isCouponExpired = (expiration: string) => {
  return moment(expiration).endOf('day').isBefore(moment());
};

/**
 * fetches a list of coupon details by code
 * @param couponCodes - list of coupon codes
 */
export const fetchCouponsByCode = async (couponCodes: string[]) => {
  if (!couponCodes.length) return [];
  try {
    return await Promise.all(couponCodes.map(code => couponLookup({ couponCode: code })));
  } catch (error) {
    console.error(`Error while fetching coupons: ${couponCodes.join(', ')}.`, error);
    return [];
  }
};

/**
 * formats deduction amount basing on discount/credit type
 * @param amount - amount to be formatted
 * @param type - discount/credit type
 */
export const formatDeductionAmount = (amount: number, type: DiscountType | CreditType): string => {
  if (type === DiscountType.Amount || type === CreditType.Dollar) {
    return '-$' + amount.toFixed(2).toString();
  } else if (type === DiscountType.Percentage || type === CreditType.Percentage) {
    return amount + '%';
  } else {
    return amount ? String(amount) : 'N/A';
  }
};

/**
 * @returns discount type basing on deduction type
 * @param deduction - deduction type
 */
export const getDeductionType = (deduction: Deduction | Credit) => {
  return deduction.__typename === 'Credit' ? deduction.caseDiscountType : deduction.type;
};
