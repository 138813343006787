import { useAuth } from 'providers/AuthProvider';
import { useLocation } from 'react-router-dom';
import Button from './Button/Button';

interface ModuleHeaderProps {
  /**
   * The title of the module header.
   */
  title: string;
}

// TODO: sign out button for time being. Will be removed later
/**
 * Component for the sign out button.
 */
const SignOutButton: React.FC = () => {
  const { signOut } = useAuth();
  const location = useLocation();
  const handleSignOut = async () => {
    await signOut();
  };

  if (location.pathname !== '/') return null;

  return (
    <div>
      <Button onClick={handleSignOut} variant="bordered">
        Sign Out
      </Button>
    </div>
  );
};

/**
 * Component for the module header.
 * @param title - The title of the module header.
 * @param children - Optional children components or elements.
 * @returns JSX.Element representing the ModuleHeader component.
 */
const ModuleHeader: React.FC<ModuleHeaderProps> = ({ title, children }) => {
  return (
    <div className="flex items-center justify-between relative z-10 flex-shrink-0 bg-white shadow p-6">
      <div className="flex-1 min-w-0">
        <div className="flex justify-between">
          <h2 className="text-3xl font-bold text-gray-900">{title}</h2>
          <SignOutButton />
        </div>
      </div>
      {children}
    </div>
  );
};

export default ModuleHeader;
