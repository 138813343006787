import { FC } from 'react';

/**
 * Props of the LabelValue component.
 */
interface LabelValueProps {
  /**
   * The name of the label.
   */
  name: string;
  /**
   * The value to be displayed.
   */
  value: string;
}

/**
 * Component for displaying a label and its corresponding value.
 * @param name - The name of the label.
 * @param value - The value to be displayed.
 * @returns A JSX element representing the LabelValue component.
 */
export const LabelValue: FC<LabelValueProps> = ({ name, value }) => {
  return (
    <div className="flex items-center gap-1">
      <div className="font-semibold">{name}:</div>
      {value}
    </div>
  );
};
