import ModuleContainer from 'components/common/ModuleContainer';
import ModuleHeader from 'components/common/ModuleHeader';
import { ROUTES } from 'components/navigation/Constants';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsEventName } from 'shared/enums/analytics';
import { AnalyticsService } from 'shared/services/analytics.service';
import { AutoSearchInput } from './SearchDetailPage/AutoSearchInput/AutoSearchInput';
import { SearchDetailPage } from './SearchDetailPage/SearchDetailPage';

/**
 * Renders a page providing search functionality
 * user can search using case, account or patient info
 * search results are rendered once search term is submitted via {@link SearchDetailPage}
 * search results can be narrowed using various filters available like case status, case, account etc.,
 *
 * @see SearchDetailPage
 */
const SearchLookupPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const termValue = searchParams.get('term') || '';

  const [searchValue, setSearchValue] = useState(termValue);

  /**
   * function to handle updating local state of search value
   * also, triggers an analytics event
   *
   * @param value - submitted search value
   */
  const onChangeSearchValue = (value: string) => {
    setSearchValue(value);
    if (!value.length) return;
    AnalyticsService.track(AnalyticsEventName.SearchSubmitted, { searchText: value });
  };

  /**
   * function to handle submitted search value
   *
   * @param value - submitted search value
   */
  const setValueAndShowFilter = (value: string) => {
    onChangeSearchValue(value);
    if (!value.length) return;
    navigate(`${ROUTES.SEARCH_CASE_PATH}?term=${value}`);
  };

  return (
    <ModuleContainer>
      <ModuleHeader title="Search" />
      {location.pathname === ROUTES.SEARCH_CASE_PATH ? (
        <SearchDetailPage searchValue={searchValue} onChangeSearchValue={onChangeSearchValue} />
      ) : (
        <div className="flex flex-grow items-center justify-around">
          <AutoSearchInput
            searchValue={searchValue}
            placeholder="Search by case, account, or patient"
            setSearchValue={setValueAndShowFilter}
            autoFocus
          />
        </div>
      )}
    </ModuleContainer>
  );
};

export default SearchLookupPage;
