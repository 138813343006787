import { Dialog } from '@headlessui/react';
import { MapPinIcon } from '@heroicons/react/24/solid';
import { Address, AddressType } from 'API';
import Loader from 'components/common/Loader/Loader';
import SelectDropdown from 'components/common/SelectDropdown/SelectDropdown';
import { ShippingCountries } from 'configurations/OrderEntryConfigurations';
import { ToastContext } from 'providers/ToastProvider';
import { useContext, useState } from 'react';
import { verifyAddress } from 'shared/api/address-verification.api';
import { CountryCode, ToastNotificationType } from 'shared/enums';
import { useLazyQueryFetcher } from 'shared/hooks/useLazyQueryFetcher';
import { convertToTitleCase } from 'shared/utils';
import AddressSelection from './AddressSelection';
import AlternateAddressInput from './AlternateAddressInput';

/**
 * Props interface for the AlternateAddress component.
 */
interface AlternateAddressProps {
  /**
   * Callback function invoked when the component should be closed.
   */
  onClose: () => void;
  /**
   * Callback function invoked when the address is saved.
   * @param address - The address to be saved.
   */
  onSave: (address: Address) => void;
}

const defaultAddress: Address = {
  street1: '',
  street2: '',
  city: '',
  state: '',
  country: '',
  zipcode: '',
  type: AddressType.Alternate,
  __typename: 'Address',
};

/**
 * Component for entering and verifying an alternate address.
 * @param onClose - Callback function invoked when the component should be closed.
 * @param onSave - Callback function invoked when the address is saved.
 * @returns JSX element representing the AlternateAddress component.
 */
const AlternateAddress: React.FC<AlternateAddressProps> = ({ onClose, onSave }) => {
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>(CountryCode.UnitedStates);
  const [enteredAddress, setEnteredAddress] = useState<Address>(defaultAddress);
  const [suggestedAddress, setSuggestedAddress] = useState<Address>(defaultAddress);
  const [selectedAddress, setSelectedAddress] = useState<Address>();
  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addressVerified, setAddressVerified] = useState<boolean>(false);
  const toast = useContext(ToastContext);
  const { fetcher } = useLazyQueryFetcher(verifyAddress);

  const addressChangeHandler = (address: Address) => {
    address.country = selectedCountry;
    setEnteredAddress(address);
  };

  const validateAddress = async () => {
    setFormTouched(true);
    if (!enteredAddress.street1 || !enteredAddress.city || !enteredAddress.state || !enteredAddress.zipcode) {
      return;
    }

    setIsLoading(true);
    const countryName = ShippingCountries.filter(c => c.id === selectedCountry)[0].name;

    let verifiedAddress;

    try {
      verifiedAddress = await fetcher({ ...enteredAddress, country: countryName });
      if (!verifiedAddress.verifications.delivery.success) {
        throw new Error('Address not verified');
      } else {
        setAddressVerified(true);
      }
    } catch {
      toast.notify('Unable to verify the address.', ToastNotificationType.Error);
    } finally {
      setIsLoading(false);
    }

    if (verifiedAddress?.verifications.delivery.success) {
      const suggestedAddress: Address = {
        street1: convertToTitleCase(verifiedAddress.street1),
        street2: verifiedAddress.street2 ? convertToTitleCase(verifiedAddress.street2) : '',
        city: convertToTitleCase(verifiedAddress.city),
        state: verifiedAddress.country === 'PR' ? 'PR' : verifiedAddress.state || '',
        country: verifiedAddress.country,
        zipcode: verifiedAddress.zip,
        type: AddressType.Alternate,
        __typename: 'Address',
      };
      setSuggestedAddress(suggestedAddress);
      setSelectedAddress(suggestedAddress);
    } else {
      setSuggestedAddress(defaultAddress);
      setSelectedAddress(enteredAddress);
    }
  };

  const saveAddress = async () => {
    if (selectedAddress) {
      onSave(selectedAddress);
    }
  };

  const countryChangeHandler = (country: CountryCode) => {
    setSelectedCountry(country);
    setEnteredAddress({ ...enteredAddress, state: '' });
  };

  return (
    <>
      <div className="flex gap-4 p-6">
        <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray-400 flex-none">
          <MapPinIcon className="h-5 w-5 text-white" aria-hidden="true" />
        </div>
        <div>
          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
            Alternate Address
          </Dialog.Title>
          <div className="mt-2">
            <p className="text-sm text-gray-500 max-w-xl">
              Add an alternate address to ship this order only. This address will not be saved to the account.
            </p>
            {addressVerified && (
              <p className="text-sm max-w-xl pt-6">
                <strong>Verify Address</strong>{' '}
                <span className="text-gray-500">
                  - A more precise version of the address was found. If correct, use the suggested address to ensure
                  accurate delivery.
                </span>
              </p>
            )}
          </div>
          {isLoading ? (
            <div className="text-center my-6">
              <Loader show spin className="text-white block m-auto h-20" />
              <div className="mt-1 text-blue-500">Verifying Address</div>
            </div>
          ) : (
            <>
              {!addressVerified && (
                <div>
                  <div className="w-1/2 pt-2 mt-4">
                    <SelectDropdown
                      label="Country/Region"
                      options={ShippingCountries.map(c => {
                        return { value: c.id, label: c.name };
                      })}
                      onValueChange={value => countryChangeHandler(value as CountryCode)}
                      value={selectedCountry}
                      id="countrySelect"
                      hideDefaultOption
                    />
                  </div>

                  <AlternateAddressInput
                    address={enteredAddress}
                    onAddressChange={addressChangeHandler}
                    touched={formTouched}
                    countryCode={selectedCountry}
                  />
                </div>
              )}
            </>
          )}
          {addressVerified && (
            <AddressSelection
              enteredAddress={enteredAddress}
              suggestedAddress={suggestedAddress}
              isAddressVerified={addressVerified}
              onAddressSelection={selectedAddress => setSelectedAddress(selectedAddress)}
              onEditAddress={() => setAddressVerified(false)}
            />
          )}
        </div>
      </div>
      {!isLoading && (
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b-lg">
          <button
            type="button"
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => (addressVerified ? saveAddress() : validateAddress())}
            data-qa="doneButton"
          >
            Done
          </button>
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => onClose()}
            data-qa="cancelButton"
          >
            Cancel
          </button>
        </div>
      )}
    </>
  );
};

export default AlternateAddress;
