import { ProductFullAttribute } from 'API';
import { useFetchCacheStore } from 'stores/useFetchCacheStore';
import { AggregateAttributeRule } from './AggregateAttributeRule';

interface AggregateChildAttributeContentProps {
  /**
   * The attribute object that contains the attribute rules and the name of the attribute
   */
  attribute: ProductFullAttribute;
  /**
   * The selected tooth of the user
   */
  selectedTooth: string;
  /**
   * The product code of the product
   */
  productCode: string;
  /**
   * The order item id of the order item
   */
  orderItemId: string;
}
/**
 * This component is responsible for rendering the content of the rules of the attribute
 */
const AggregateChildAttributeContent: React.FC<AggregateChildAttributeContentProps> = ({
  attribute,
  selectedTooth,
  productCode,
  orderItemId,
}) => {
  const attributeNameValue = attribute.name;
  const attributeRules = attribute.attributeRules || [];
  return (
    <>
      {attributeRules.map((attributeRule, index) => (
        <AggregateAttributeRule
          key={index}
          attributeRule={attributeRule}
          selectedTooth={selectedTooth}
          productCode={productCode}
          attributeNameValue={attributeNameValue}
          orderItemId={orderItemId}
        />
      ))}
    </>
  );
};

interface AggregateChildAttributeProps {
  /**
   * The name of the attribute
   */
  attributeName: string;
  /**
   * The type of the attribute
   */
  attributeType: string;
  /**
   * The selected tooth of the user
   */
  selectedTooth: string;
  /**
   * The product code of the product
   */
  productCode: string;
  /**
   * The order item id of the order item
   */
  orderItemId: string;
}
/**
 * This component is responsible for rendering the child attributes of the aggregate attribute
 */
export const AggregateChildAttribute: React.FC<AggregateChildAttributeProps> = ({
  attributeName,
  attributeType,
  selectedTooth,
  productCode,
  orderItemId,
}) => {
  const findProductFullAttributeByOption = useFetchCacheStore(state => state.findProductFullAttributeByOption);
  const productFullAttribute = findProductFullAttributeByOption(productCode, {
    name: attributeName,
    type: attributeType,
  });
  // If the attributeName and attributeType combination is not found in the productFullAttributes, don't render anything
  if (!productFullAttribute) return null;
  return (
    <AggregateChildAttributeContent
      attribute={productFullAttribute}
      selectedTooth={selectedTooth}
      productCode={productCode}
      orderItemId={orderItemId}
    />
  );
};
