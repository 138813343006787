import {
  addressFragment,
  appliedCreditsFragment,
  baseOrderItemFragment,
  orderAttachmentFragment,
  orderEnclosedItemFragment,
  orderFragment,
  rmaOrderFragment,
  shippingFragment,
} from './fragments';

export const createOrder = /* GraphQL */ `
  mutation createOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      ... on PendingOrder {
        __typename
        orderNumber
        billingAccountId
        status
        shipping {
          ...shippingFragment
        }
        orderItems {
          ...baseOrderItemFragment
        }
        fileAttachments {
          ...orderAttachmentFragment
        }
        enclosedItems {
          ...orderEnclosedItemFragment
        }
      }
      ... on Order {
        ...orderFragment
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${baseOrderItemFragment}
  ${orderFragment}
`;

export const updateOrderStatus = /* GraphQL */ `
  mutation updateOrderStatus($input: UpdateOrderStatusInput!) {
    updateOrderStatus(input: $input) {
      ... on Order {
        __typename
        orderNumber
        billingAccountId
        status
        statusReason
        pONumber
        sCINumber
        type
        paymentTerms
        transactionId
        authorizationId
        authorizationAmount
        creditRequestedDate
        originatingSystem
        orderSource
        providerId
        providerName
        patientId
        patientFirstName
        patientLastName
        labReceivedDate
        customerDueDate
        subtotalAmount
        totalDiscountAmount
        totalFreightCharges
        totalTaxAmount
        totalAmount
        currencyCode
        invoiceBy
        invoiceDate
        originFacilityId
        departmentId
        externalOrderNumber
        addresses {
          ...addressFragment
        }
        shipping {
          ...shippingFragment
        }
        orderItems {
          ...baseOrderItemFragment
        }
        fileAttachments {
          ...orderAttachmentFragment
        }
        enclosedItems {
          ...orderEnclosedItemFragment
        }
        coupons
        notes
        utcConversionTimeZoneCode
        createdBy
        createdDate
        updatedBy
        updatedDate
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${addressFragment}
  ${shippingFragment}
  ${baseOrderItemFragment}
  ${orderAttachmentFragment}
  ${orderEnclosedItemFragment}
`;

export const updateOrder = /* GraphQL */ `
  mutation updateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      ... on PendingOrder {
        __typename
        orderNumber
        billingAccountId
        status
        shipping {
          ...shippingFragment
        }
        orderItems {
          ...baseOrderItemFragment
        }
        fileAttachments {
          ...orderAttachmentFragment
        }
        enclosedItems {
          ...orderEnclosedItemFragment
        }
      }
      ... on Order {
        ...orderFragment
      }
      ... on GenericError {
        __typename
        message
      }
      ... on NotFoundError {
        __typename
        message
      }
    }
  }
  ${orderFragment}
  ${shippingFragment}
  ${baseOrderItemFragment}
  ${orderAttachmentFragment}
  ${orderEnclosedItemFragment}
`;

export const invoice = /* GraphQL */ `
  mutation invoice($input: InvoiceInput!) {
    invoice(input: $input) {
      __typename
      ... on OrderWrapper {
        orders {
          ...orderFragment
        }
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${orderFragment}
`;

export const uninvoice = /* GraphQL */ `
  mutation uninvoice($input: UnInvoiceOrderInput!) {
    uninvoice(input: $input) {
      __typename
      ... on UnInvoiceResponse {
        success
      }
      ... on GenericError {
        message
      }
    }
  }
`;

export const updateCaseAlertStatuses = /* GraphQL */ `
  mutation updateCaseAlertStatuses($input: UpdateCaseAlertStatusesInput!) {
    updateCaseAlertStatuses(input: $input) {
      __typename
      ... on UpdateCaseAlertsSuccess {
        status
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const createRmaOrder = /* GraphQL */ `
  mutation createRmaOrder($input: CreateRmaOrderInput!) {
    createRmaOrder(input: $input) {
      __typename
      ... on RmaOrder {
        ...rmaOrderFragment
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${rmaOrderFragment}
`;

export const createDeduction = /* GraphQL */ `
  mutation createDeduction($input: CreateDeductionInput!) {
    createDeduction(input: $input) {
      __typename
      ... on Deduction {
        discountId
        orderNumber
        reason
        type
        deductions {
          productCode
          discountType
          value
          discountAmount
          orderItemId
        }
        createdBy
        notes
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const deleteDeductions = /* GraphQL */ `
  mutation deleteDeduction($discountId: String!) {
    deleteDeduction(discountId: $discountId) {
      __typename
      ... on DeleteDeductionResponse {
        result
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const updateDeductions = /* GraphQL */ `
  mutation updateDeduction($discountId: String!, $input: CreateDeductionInput!) {
    updateDeduction(discountId: $discountId, input: $input) {
      __typename
      ... on Deduction {
        discountId
        orderNumber
        reason
        deductions {
          productCode
          discountType
          value
          discountAmount
        }
        createdBy
        notes
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const createCredit = /* GraphQL */ `
  mutation createCredit($input: CreateCreditInput!) {
    createCredit(input: $input) {
      __typename
      ...appliedCreditsFragment
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${appliedCreditsFragment}
`;

export const deleteCredit = /* GraphQL */ `
  mutation deleteCredit($creditNumber: String!, $input: DeleteCreditInput!) {
    deleteCredit(creditNumber: $creditNumber, input: $input) {
      __typename
      ... on DeleteCreditResponse {
        result
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;

export const createBundleSplitOrder = /* GraphQL */ `
  mutation createBundleSplitOrder($input: BundleSplitOrderInput!) {
    createBundleSplitOrder(input: $input) {
      __typename
      ... on BundleSplitOrder {
        orders {
          ...orderFragment
        }
        rmaOrders {
          ...rmaOrderFragment
        }
      }
      ... on GenericError {
        message
      }
      ... on BadRequestError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
  ${orderFragment}
  ${rmaOrderFragment}
`;

export const removeBundledOrder = /* GraphQL */ `
  mutation removeBundledOrder($bundleId: String!, $orderNumber: String!) {
    removeBundledOrder(bundleId: $bundleId, orderNumber: $orderNumber) {
      __typename
      ... on RemoveBundledOrderResponse {
        result
      }
      ... on GenericError {
        message
      }
      ... on NotFoundError {
        message
      }
    }
  }
`;
