import { MinusCircleIcon } from '@heroicons/react/24/outline';
import { SearchedOrder } from 'API';
import { CaseStatusBadge } from 'components/common/CaseStatusBadge/CaseStatusBadge';
import { TableColumn } from 'components/common/Table/types';
import { Link } from 'react-router-dom';
import { firstTwoAttributes, orderLocked } from 'shared/helpers/order-detail/order-detail.helper';
import { getOrderDetailPath } from 'shared/helpers/route.helper';
import { checkCaseIsInvoiced, cn, getDateInShortFormat, getPatientFullName } from 'shared/utils';

export type CaseBundlesWithTimezone = SearchedOrder & { isDetached?: boolean };

/**
 * Returns columns for the case bundles tables.
 * @param currentOrderNumber - The current order number.
 * @returns columns for the case bundles tables.
 */
export const caseBundlesColumns = (
  currentOrderNumber: string,
  chargedOrderNumber: string,
  splitCases?: string[],
  isOutbound?: boolean,
  onRemoveClick?: (row: SearchedOrder) => void
): TableColumn<CaseBundlesWithTimezone>[] => {
  const columns: TableColumn<CaseBundlesWithTimezone>[] = [
    {
      id: 'orderNumber',
      name: 'Case #',
      render: ({ row }) => {
        const orderNumber = row.orderNumber;
        const isSplitCase = !checkCaseIsInvoiced(row.status) && !!splitCases?.find(data => data === orderNumber);
        const isCurrentOrder = orderNumber === currentOrderNumber;
        if (isCurrentOrder) {
          return (
            <div>
              {orderNumber} <span className="text-sm text-gray-400">(This case)</span>
            </div>
          );
        } else {
          return (
            <Link
              className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex"
              data-qa="viewBundledCaseLink"
              to={{
                pathname: getOrderDetailPath(row.orderNumber),
              }}
            >
              {orderNumber}
              {isSplitCase && <span className="text-sm text-gray-400 ml-1">(Split Case)</span>}
            </Link>
          );
        }
      },
    },
    {
      id: 'patientFirstName',
      name: 'Patient Name / ID',
      render: ({ row }) => {
        const { patientFirstName = '', patientLastName = '', patientId } = row;
        const patientFullName = getPatientFullName(patientFirstName, patientLastName);
        return <div>{patientFullName || patientId || 'N/A'}</div>;
      },
    },
    {
      id: 'orderItems',
      name: 'Product',
      render: ({ row }) => {
        return (
          <>
            {row.orderItems.map((item, index) => (
              <div key={index}>
                {item.productName}&nbsp;
                <span className="text-gray-500">{firstTwoAttributes(item)}</span>
              </div>
            ))}
          </>
        );
      },
    },
    {
      id: 'status',
      name: 'Case Status',
      render: ({ row }) => <CaseStatusBadge status={row.status} />,
    },
    {
      id: 'receivedDate',
      name: 'Received Date',
      render: ({ row }) =>
        row.labReceivedDate ? getDateInShortFormat(row.labReceivedDate, row.utcConversionTimeZoneCode) : 'N/A',
    },
    {
      id: 'invoiceDate',
      name: 'Invoice Date',
      render: ({ row }) =>
        row.invoiceDate ? getDateInShortFormat(row.invoiceDate, row.utcConversionTimeZoneCode) : 'N/A',
    },
  ];

  if (chargedOrderNumber) {
    columns.push({
      id: 'chargedOrderNumber',
      name: '',
      render: ({ row }) => {
        const isCaseInvoiced = checkCaseIsInvoiced(row.status);
        return row.orderNumber !== chargedOrderNumber ? null : (
          <div
            className={cn('text-sm', {
              'text-orange-600': !isCaseInvoiced,
              'text-gray-500': isCaseInvoiced,
            })}
          >
            {isCaseInvoiced ? 'Has shipping charge' : 'Will be charged shipping'}
          </div>
        );
      },
    });
  }

  if (isOutbound) {
    columns.push({
      id: 'removeBundle',
      name: '',
      render: ({ row }) =>
        isOutbound &&
        !orderLocked(row.status) &&
        !row.isDetached && (
          <button className="flex items-center" onClick={() => onRemoveClick && onRemoveClick(row)}>
            <MinusCircleIcon className="text-red-500 mr-2 h-5 w-5" />
            <div className="text-red-700 text-sm font-medium text-left">Remove From Bundle</div>
          </button>
        ),
    });
  }

  return columns;
};
