import { DropdownMenu } from 'types/dropdown-menu';

/**
 * returns a map of dropdown item keyed to its id, from a list of menu items
 * @param menus - list of menus
 */
export const extractMenuObjectFromArray = <TMenu extends DropdownMenu>(menus: Array<TMenu>) => {
  return menus.reduce((acc, menu) => {
    const id = menu.id as TMenu['id'];
    acc[id] = menu.name;
    return acc;
  }, {} as Record<TMenu['id'], TMenu['name']>);
};
