import Modal from 'components/common/Modal';
import { FC, useEffect } from 'react';
import { useAddDiscountModalStore } from 'stores/useAddDiscountModalStore';
import AddSpecialDiscount from './AddSpecialDiscount/AddSpecialDiscount';
import { DiscountModalType } from './types';

/**
 * Props for the AddSpecialDiscountModal component.
 */
interface AddSpecialDiscountModalProps {
  /** The type of discount modal. */
  type: DiscountModalType;
  /** Boolean flag indicating whether the modal should be shown. */
  showModal: boolean;
  /** Function to handle modal closure. */
  onClose: () => void;
  /** Function to handle applying the discount. */
  onApply: () => void;
  /** Boolean flag indicating whether the modal is in a loading state. */
  isLoading?: boolean;
}

/**
 * Component for displaying the modal to add a special discount.
 *
 * @param type - The type of discount modal.
 * @param showModal - Boolean flag indicating whether the modal should be shown.
 * @param onClose - Function to handle modal closure.
 * @param onApply - Function to handle applying the discount.
 * @param isLoading - Boolean flag indicating whether the modal is in a loading state.
 * @returns JSX.Element AddSpecialDiscountModal component
 */
export const AddSpecialDiscountModal: FC<AddSpecialDiscountModalProps> = ({
  type,
  showModal,
  onClose,
  onApply,
  isLoading,
}) => {
  const resetSpecialDiscount = useAddDiscountModalStore(state => state.resetSpecialDiscount);

  // Reset special discount value whenever this modal is opened
  useEffect(() => {
    if (!showModal) return;
    resetSpecialDiscount();
  }, [resetSpecialDiscount, showModal]);

  return (
    <>
      {showModal && (
        <Modal onClose={onClose} width="w-1/2">
          <AddSpecialDiscount onApply={onApply} isLoading={isLoading} onClose={onClose} type={type} />
        </Modal>
      )}
    </>
  );
};
