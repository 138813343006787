import { Address } from 'API';
import Input from 'components/common/Input/Input';
import InputError from 'components/common/InputError';
import SelectDropdown from 'components/common/SelectDropdown/SelectDropdown';
import { States } from 'configurations/OrderEntryConfigurations';
import { useCallback } from 'react';
import { CountryCode } from 'shared/enums';

/**
 * Props interface for the AlternateAddressInput component.
 */
interface AlternateAddressInputProps {
  /**
   * The address object being edited.
   */
  address: Address;
  /**
   * Callback function invoked when the address changes.
   * @param address - The updated address object.
   */
  onAddressChange: (address: Address) => void;
  /**
   * Boolean flag indicating whether the form has been touched.
   */
  touched: boolean;
  /**
   * The country code for the address.
   */
  countryCode: CountryCode;
}

/**
 * Component for entering alternate address details.
 * @param address - The address object being edited.
 * @param onAddressChange - Callback function invoked when the address changes.
 * @param touched - Boolean flag indicating whether the form has been touched.
 * @param countryCode - The country code for the address.
 * @returns JSX element representing the AlternateAddressInput component.
 */
const AlternateAddressInput: React.FC<AlternateAddressInputProps> = ({
  address,
  onAddressChange,
  touched,
  countryCode,
}) => {
  const countryStateHandler = useCallback(() => {
    if (countryCode === CountryCode.UnitedStates) {
      return States.map(s => {
        return { value: s.abbreviation, label: `${s.abbreviation} - ${s.name}` };
      });
    } else {
      return [{ value: 'PR', label: 'PR' }];
    }
  }, [countryCode]);

  return (
    <>
      <div className="mt-4">
        <Input
          id="street1"
          name="street1"
          label="Street Address"
          value={address.street1}
          onChange={e => onAddressChange({ ...address, street1: e.target.value })}
          placeholder="Street Address or P.O. Box"
          isInvalid={touched && address.street1.trim() === ''}
          isRequired
        />
        <div className="mt-2">
          <Input
            id="street2"
            name="street2"
            value={address.street2 || ''}
            onChange={e => onAddressChange({ ...address, street2: e.target.value })}
            placeholder="Apt, Suite, Unit, Building, Floor, etc."
          />
        </div>
        <InputError message="Street address is required." forceHide={!touched || address.street1.trim() !== ''} />
      </div>
      <div className="flex gap-6 max-w-xl">
        <div className="mt-4 flex-auto">
          <Input
            id="city"
            name="city"
            label="City"
            value={address.city}
            onChange={e => onAddressChange({ ...address, city: e.target.value })}
            isInvalid={touched && address.city.trim() === ''}
            isRequired
          />
          <InputError message="City is required." forceHide={!touched || address.city.trim() !== ''} />
        </div>
        <div className="mt-4 flex-none">
          <SelectDropdown
            label="State"
            value={address.state}
            options={countryStateHandler()}
            onValueChange={(value: string) => onAddressChange({ ...address, state: value })}
            hasError={touched && address.state.trim() === ''}
            errorMessage="State is required."
            id="stateSelect"
            placeholder="Select"
            isRequired
          />
        </div>
        <div className="mt-4 flex-auto">
          <Input
            id="zip"
            name="zip"
            label="Zip Code"
            value={address.zipcode}
            onChange={e => onAddressChange({ ...address, zipcode: e.target.value })}
            isInvalid={touched && address.zipcode.trim() === ''}
            isRequired
          />
          <InputError message="Zip is required." forceHide={!touched || address.zipcode.trim() !== ''} />
        </div>
      </div>
    </>
  );
};

export default AlternateAddressInput;
