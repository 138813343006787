import { AccountInfo, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosRequestConfig } from 'axios';
import { graphConfig, loginRequest } from 'components/Authentication/msalAuthConfig';
import { AZURE_AUTHENTICATION_ERROR } from 'shared/constants/constants';
import { UserInfo } from 'types/common';

/**
 * Checks if the Azure token is expired.
 * @param account - The account information.
 * @param extendExpiresOnInSec - Optional. The number of seconds to extend the expiration date by.
 * @returns A boolean indicating whether the token is expired or not.
 */
export const isAzureTokenExpired = (account: AccountInfo | null, extendExpiresOnInSec?: number): boolean => {
  if (!account) {
    return true;
  }

  const idTokenClaims = account.idTokenClaims as { exp: number };
  const expireDate = new Date(idTokenClaims.exp * 1000);
  if (extendExpiresOnInSec) {
    expireDate.setSeconds(expireDate.getSeconds() + extendExpiresOnInSec);
  }
  const now = new Date();

  return now > expireDate;
};

/**
 * Retrieves the Azure authentication result.
 *
 * @param msalInstance - The instance of IPublicClientApplication.
 * @param account - The account information or null.
 * @returns The authentication result.
 * @throws - If no active account is found.
 * @throws - If an Azure authentication error occurs.
 */
export const getAzureAuthenticationResult = async (
  msalInstance: IPublicClientApplication,
  account: AccountInfo | null
) => {
  try {
    if (!account) {
      throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });

    if (isAzureTokenExpired(response.account)) {
      // If idToken is expired, we get a new one
      return msalInstance.ssoSilent({
        ...loginRequest,
      });
    }

    return response;
  } catch (err) {
    const error = err as Error;
    error.name = AZURE_AUTHENTICATION_ERROR;
    msalInstance.setActiveAccount(null); // Clear the active account
    throw err;
  }
};

const getUserInfoFromIdTokenClaims = (idTokenClaims: AccountInfo['idTokenClaims']): Omit<UserInfo, 'avatar'> => {
  if (!idTokenClaims) {
    throw Error('No idTokenClaims!');
  }
  const { name, preferred_username } = idTokenClaims;

  const firstName = (idTokenClaims.firstName as string) ?? '';
  const lastName = (idTokenClaims.lastName as string) ?? '';
  const email = (idTokenClaims.mail as string) ?? '';
  const employeeId = (idTokenClaims.employeeId as string) ?? '';
  const roles = idTokenClaims.roles ?? [];
  const fullName = `${firstName} ${lastName}`.trim();
  const displayName = name ?? preferred_username ?? fullName;

  return {
    displayName,
    employeeId,
    firstName,
    lastName,
    email,
    roles,
  };
};

const getAvatarUrl = async (accessToken: string) => {
  const bearer = `Bearer ${accessToken}`;

  const options: AxiosRequestConfig = {
    headers: {
      Authorization: bearer,
    },
  };

  let avatar = '';

  try {
    const avatarResponse = await axios.get<Blob>(`${graphConfig.graphMeEndpoint}/photo/$value`, {
      ...options,
      responseType: 'blob',
    });

    if (avatarResponse.data) {
      avatar = URL.createObjectURL(avatarResponse.data);
    }
  } catch {
    avatar = '';
  }

  return avatar;
};

export const getMsalUserInfo = async (
  msalInstance: IPublicClientApplication,
  account: AccountInfo | null
): Promise<UserInfo | null> => {
  try {
    const response = await getAzureAuthenticationResult(msalInstance, account);
    const userInfo = getUserInfoFromIdTokenClaims(response.idTokenClaims as AccountInfo['idTokenClaims']);
    const avatar = await getAvatarUrl(response.accessToken);

    return { ...userInfo, avatar };
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect({
        ...loginRequest,
        account: account || undefined,
      });
      return null;
    }
    throw error;
  }
};
