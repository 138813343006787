import { ShippingOption } from 'API';
import Label from 'components/common/Label';
import Radio from 'components/common/Radio/Radio';
import RequiredMessage from 'components/common/RequiredMessage/RequiredMessage';
import { ShippingServiceLabelKey, getShippingServiceLabel } from 'configurations/InvoiceConfiguration';
import { sortBy, unionBy } from 'lodash';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { useEffect, useMemo } from 'react';

/**
 * Props for the ShippingMethodElement component.
 */
interface ShippingMethodElementProps {
  /**
   * Grouped shipping options by carrier.
   */
  groupByCarrierData: Record<string, ShippingOption[]>;
}

/**
 * Component for selecting the shipping method.
 * @param groupByCarrierData - Grouped shipping options by carrier.
 * @returns JSX element representing the shipping method selection.
 */
export const ShippingMethodElement: React.FC<ShippingMethodElementProps> = ({ groupByCarrierData }) => {
  const { invoiceShippingInput, onSubmitAlertCount, setInvoiceShippingInput } = useInvoicingDetail();

  const {
    shippingService: selectedShippingService,
    carrier: selectedCarrier,
    isSignatureRequired,
    isSignatureRequiredPreference,
    isSaturdayDelivery,
    trackingNumber,
  } = invoiceShippingInput;
  const showShippingMethodRequired = onSubmitAlertCount > 0 && !invoiceShippingInput.shippingService;

  const shippingOptionsByCarrier = useMemo(() => {
    if (!selectedCarrier || !groupByCarrierData) return [];
    const shippingOptions = groupByCarrierData[selectedCarrier];
    return sortBy(shippingOptions, shippingOption => !shippingOption.requiresSignature);
  }, [groupByCarrierData, selectedCarrier]);

  /**
   * Handles the change event when a shipping method is selected.
   * Updates the invoiceShippingInput state with the selected shipping method.
   * @param value - The selected shipping method value.
   */
  const onValueChange = (value: string) => {
    const matchingShippingOptions = shippingOptionsByCarrier.filter(option => option.service === value);
    const requiresSignature = isSignatureRequiredPreference || isSignatureRequired || false;

    // Tries to find the shipping option previously selected by the user
    let selectedShippingOption = matchingShippingOptions.find(
      option => option.requiresSignature === isSignatureRequired && option.isSaturdayDelivery === isSaturdayDelivery
    );
    // If no matching option, then find an optimal option with no Saturday delivery and with the user's signature preference/selection.
    if (!selectedShippingOption) {
      selectedShippingOption = matchingShippingOptions.find(
        option => option.requiresSignature === requiresSignature && !option.isSaturdayDelivery
      );
    }
    // If no matching option so far, attempts to find one with the user's signature preference/selection.
    if (!selectedShippingOption) {
      selectedShippingOption = matchingShippingOptions.find(option => option.requiresSignature === requiresSignature);
    }
    // If no matching option so far, attempts to find one without Saturday delivery.
    if (!selectedShippingOption) {
      selectedShippingOption = matchingShippingOptions.find(option => !option.isSaturdayDelivery);
    }
    // If there is still no option selected, defaults to the 0 index for any option matching the requested service.
    if (!selectedShippingOption) {
      selectedShippingOption = matchingShippingOptions[0];
    }
    const input = {
      shippingService: value,
      isSignatureRequired,
      isSaturdayDelivery: selectedShippingOption?.isSaturdayDelivery ?? false,
    };
    // Only update isSignatureRequired value when SignatureRequiredPreference is required (when the user has selected a shipping method)
    if (isSignatureRequiredPreference) {
      input.isSignatureRequired = selectedShippingOption?.requiresSignature ?? false;
    }
    setInvoiceShippingInput(input);
  };

  const radioOptions = useMemo(() => {
    return unionBy(shippingOptionsByCarrier, 'service').map((shipping: ShippingOption) => {
      const shippingOptionService = shipping.service as ShippingServiceLabelKey;
      return {
        label: getShippingServiceLabel(shippingOptionService),
        value: shipping.service,
      };
    });
  }, [shippingOptionsByCarrier]);

  // Set default shipping service when the shipping service is not selected and external trackingNumber is not added.
  useEffect(() => {
    if (selectedShippingService || !radioOptions.length || trackingNumber) return;
    const defaultShippingService = radioOptions[0].value;
    setInvoiceShippingInput({ shippingService: defaultShippingService });
  }, [selectedShippingService, radioOptions, setInvoiceShippingInput, trackingNumber]);

  if (!shippingOptionsByCarrier.length) return null;

  return (
    <div className="mt-4 sm:max-w-xs">
      <Label required htmlFor="shippingMethod">
        Shipping Method
      </Label>
      <Radio
        id="shippingMethod"
        options={radioOptions}
        value={selectedShippingService}
        hasError={showShippingMethodRequired}
        onValueChange={value => {
          onValueChange(value);
        }}
        disabled={!!invoiceShippingInput.trackingNumberBundledOrder}
      />
      {showShippingMethodRequired && <RequiredMessage fieldName="Shipping method" />}
    </div>
  );
};
