import { PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { OrderStatus } from 'API';
import { useInvoicingDetail } from 'providers/InvoicingDetailModuleProvider';
import { ToastContext } from 'providers/ToastProvider';
import { FC, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastNotificationType } from 'shared/enums';
import { getInvoiceCreatePath, getUpdateCasePath } from 'shared/helpers/route.helper';
import { CreatedOrder } from 'shared/models';
import { checkCaseIsInvoiceCompleted } from 'shared/utils';

/**
 * Props interface for the EditAndRemoveButtonContainer component.
 */
interface EditAndRemoveButtonContainerProps {
  /**
   * The number of cases associated with the order.
   */
  caseCount: number;
  /**
   * The case number.
   */
  caseNumber: string;
}

/**
 * Component for rendering edit and remove buttons for a cases in invoicing page.
 * @param caseCount - The number of cases associated with the order.
 * @param caseNumber - The case number.
 * @returns JSX element representing the component.
 */
export const EditAndRemoveButtonContainer: FC<EditAndRemoveButtonContainerProps> = ({ caseCount, caseNumber }) => {
  const { deleteCase, invoiceCases } = useInvoicingDetail();
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useContext(ToastContext);

  // Find the existing case from the list of invoice cases
  const existingCase = useMemo(() => {
    return invoiceCases.find(invoiceCase => invoiceCase.orderNumber === caseNumber);
  }, [invoiceCases, caseNumber]);

  // Determine whether to hide the edit button based on the case status
  const hideEditButton = existingCase?.status === OrderStatus.Cancelled;

  // Function to navigate to the edit case page
  const goToEditCase = (caseId: string) => {
    navigate(getUpdateCasePath(caseId), {
      state: {
        ...state, // pass the state from the current page to the next page so we can redirect back to the current page with the current state after editing the case details
        redirectPath: getInvoiceCreatePath(caseId),
      },
    });
  };

  // Handler for bundle delete action
  const bundleDeleteHandler = () => {
    if (checkCaseIsInvoiceCompleted(existingCase?.status)) {
      toast.notify(`Cannot delete case that has been invoiced.`, ToastNotificationType.Error);
    } else {
      if (state?.ordersScanned) {
        const ordersScanned = state.ordersScanned.filter((order: CreatedOrder) => order.orderNumber !== caseNumber);
        state.ordersScanned = ordersScanned;
      }
      deleteCase(caseNumber);
    }
  };

  return (
    <div className="flex-none text-right space-x-2 flex items-center">
      {caseCount > 1 && (
        <button
          type="button"
          className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={bundleDeleteHandler}
          data-qa="removeOrderButton"
        >
          <XMarkIcon className="h-4 mr-1 text-gray-500" />
          Remove Case
        </button>
      )}

      {!hideEditButton && (
        <div className="group relative">
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => goToEditCase(caseNumber)}
            data-qa="editOrderButton"
          >
            <PencilIcon className="h-4 mr-1 text-indigo-700" />
            Edit
          </button>
        </div>
      )}
    </div>
  );
};
