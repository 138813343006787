import { OrderType } from 'API';
import { SkeletonBorder } from 'components/common/Skeleton';
import { usePrevious } from 'hooks/use-previous';
import { useAuth } from 'providers/AuthProvider';
import { OrderModuleActionsContext, OrderModuleContext } from 'providers/OrderModuleProvider';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { BaseConstants } from 'shared/constants/constants';
import {
  getCarrierName,
  getDefaultTrackingNumberItem,
  getOrderType,
} from 'shared/helpers/order-entry/order-entry.helper';
import { cn } from 'shared/utils';
import { useBundleSplitCaseStore } from 'stores/useBundleSplitCaseStore';

/**
 * Component responsible for displaying and managing tracking numbers for an order.
 * @returns JSX element representing the TrackingNumbers component.
 */
const TrackingNumbers: React.FC = () => {
  const { orderLoading, order, onSubmitAlertCount, orderId } = useContext(OrderModuleContext);
  const { user } = useAuth();
  const currentUserName = user?.displayName || '';
  const { patchOrder } = useContext(OrderModuleActionsContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const inboundTrackingNumbers = useMemo(() => order.inboundTrackingNumbers, [order.inboundTrackingNumbers]);
  const prevExternalOrderNumber = usePrevious(order.externalOrderNumber);
  const prevOrderSource = usePrevious(order.orderSource);
  const [inputBlurred, setInputBlurred] = useState(false);
  const { id: orderUpdateId } = useParams<{ id: string }>();
  const initialOrderData = useBundleSplitCaseStore(state => state.initialOrderData);
  // Determines whether the tracking number error message should be enabled.
  const isErrorEnabled = useMemo(() => {
    const isOrderUpdate = !!orderUpdateId;
    return inputBlurred || (!orderLoading && isOrderUpdate);
  }, [inputBlurred, orderLoading, orderUpdateId]);

  /**
   * Ensure a new default tracking number item is added to the order if the inboundTrackingNumbers array is empty.
   *
   * If inboundTrackingNumbers is not empty, there is no tracking number entered, the case is digital, and the digital case field(s) change
   * set the inbound tracking number to "No Tracking". This is a business requirement since digital cases won't have tracking numbers.
   */
  useEffect(() => {
    const hasExistingTrackingNumberItem = !!inboundTrackingNumbers.length;
    if (!hasExistingTrackingNumberItem || !inboundTrackingNumbers[0].trackingNumber) {
      const isDigitalAndDigitalPropertiesChanged =
        getOrderType(order.externalOrderNumber, order.orderSource) === OrderType.Digital &&
        (order.externalOrderNumber !== prevExternalOrderNumber || order.orderSource !== prevOrderSource);

      const newTrackingItem = getDefaultTrackingNumberItem(
        isDigitalAndDigitalPropertiesChanged ? BaseConstants.No_Tracking : ''
      );
      newTrackingItem.insertedBy = currentUserName;

      if (!hasExistingTrackingNumberItem || isDigitalAndDigitalPropertiesChanged) {
        patchOrder({
          inboundTrackingNumbers: [newTrackingItem],
        });
      }
    }
  }, [
    currentUserName,
    inboundTrackingNumbers,
    order.externalOrderNumber,
    order.inboundTrackingNumbers,
    order.orderSource,
    patchOrder,
    prevExternalOrderNumber,
    prevOrderSource,
  ]);

  const trackingNumberChangeHandler = useCallback(
    (index: number, enteredText: string) => {
      const items = [...inboundTrackingNumbers];
      items[index] = {
        ...items[index],
        trackingNumber: enteredText,
      };

      patchOrder({
        inboundTrackingNumbers: [...items],
      });
    },
    [inboundTrackingNumbers, patchOrder]
  );

  const trackingNumberBlurHandler = useCallback(
    (index: number, enteredText: string) => {
      const newValue = enteredText.trim();
      const items = [...inboundTrackingNumbers];
      const initialTracking = initialOrderData[orderId]?.inboundTrackingNumbers[index];
      const isTrackingUpdated = initialTracking && initialTracking.trackingNumber !== newValue;

      items[index].trackingNumber = newValue;

      /**
       * If there is intial tracking data (case update / data capture scenarios) and the tracking number was udpated,
       * update the insertion date and user.
       * Else, set back to original values.
       */
      if (initialTracking) {
        items[index] = {
          ...items[index],
          insertionDate: isTrackingUpdated ? new Date().toISOString() : initialTracking.insertionDate,
          insertedBy: isTrackingUpdated ? currentUserName : initialTracking.insertedBy,
        };
      }

      patchOrder({
        inboundTrackingNumbers: [...items],
      });
    },
    [inboundTrackingNumbers, currentUserName, initialOrderData, orderId, patchOrder]
  );

  /**
   * Returns the value to display in the right corner of the Tracking # input field.
   * Defaults to "Unknown" if the input field has been blurred.
   * @param carrierName - the carrier name to display, if it exists.
   * @returns a value to display in the right corner of the Tracking # input field.
   */
  const getCarrierNameToDisplay = useCallback(
    (carrierName: string) => {
      if (carrierName) {
        return carrierName;
      } else if (isErrorEnabled) {
        return BaseConstants.Unknown;
      }
    },
    [isErrorEnabled]
  );

  return (
    <>
      {inboundTrackingNumbers.map((trackingItem, index) => {
        const carrierName = getCarrierName(trackingItem.trackingNumber);
        const carrierNameToDisplay = getCarrierNameToDisplay(carrierName);
        return (
          <div key={index}>
            <div className="flex pb-2">
              {orderLoading ? (
                <SkeletonBorder />
              ) : (
                <div className={cn('relative flex-grow flex flex-row justify-between items-center')}>
                  <input
                    type="text"
                    className={cn(
                      'flex-grow shadow-sm text-sm rounded-md pr-24',
                      (inputBlurred || onSubmitAlertCount > 0) && !carrierName
                        ? 'focus:ring-red-500 focus:border-red-500 border-red-500'
                        : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300'
                    )}
                    value={trackingItem.trackingNumber}
                    onChange={e => trackingNumberChangeHandler(index, e.target.value)}
                    onBlur={e => {
                      setInputBlurred(true);
                      trackingNumberBlurHandler(index, e.target.value.trim());
                    }}
                    placeholder="Scan/enter tracking #"
                    data-testid={`trackingNumber${index}`}
                    data-qa="trackingNumberInput"
                    key={index}
                    ref={inputRef}
                  />

                  {carrierNameToDisplay && (
                    <label
                      className={cn(
                        'pr-2 absolute top-1/2 transform -translate-y-1/2 right-0 text-sm',
                        carrierName ? 'text-gray-600' : 'text-red-600'
                      )}
                      data-qa="carrierLabel"
                      data-testid={`carrier${index}`}
                    >
                      {carrierNameToDisplay}
                    </label>
                  )}
                </div>
              )}
            </div>

            {isErrorEnabled && !carrierName && (
              <p className="mb-1 ml-px mt-px text-xs text-red-500">Tracking number is invalid</p>
            )}
          </div>
        );
      })}
    </>
  );
};

export default TrackingNumbers;
