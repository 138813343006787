import { AuthenticateRoute } from 'components/Authentication/AuthenticateRoute';
import { RootErrorBoundary } from 'components/RootErrorBoundary/RootErrorBoundary';
import NotFoundPage from 'pages/404';
import DashboardPage from 'pages/DashboardPage';
import InvoiceOrderLookupPage from 'pages/InvoiceOrderLookupPage';
import InvoicingPage from 'pages/InvoicingPage/InvoicingPage';
import OrderDetailPage from 'pages/OrderDetailPage/OrderDetailPage';
import OrderEntryBundleSplitPage from 'pages/OrderEntryBundleSplitPage/OrderEntryBundleSplitPage';
import OrderEntryLookupPage from 'pages/OrderEntryLookupPage/OrderEntryLookupPage';
import SearchLookupPage from 'pages/SearchLookupPage/SearchLookupPage';
import SignInWithEmail from 'pages/SignInPage/SignInWithEmail';
import SignInWithSSO from 'pages/SignInPage/SignInWithSSO';
import { SignInRedirectPage } from 'pages/SignInRedirectPage/SignInRedirectPage';
import { SignOutPage } from 'pages/SignOutPage/SignOutPage';
import { CaseDetailModuleProvider } from 'providers/CaseDetailModuleProvider';
import { InvoicingDetailModuleProvider } from 'providers/InvoicingDetailModuleProvider';
import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { SidebarLayout } from '../../layouts/SidebarLayout';
import { ROUTES } from './Constants';
import { RootRoute } from './RootRoute';

/**
 * Define application routes with private and public routes.
 */
export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={ROUTES.ROOT} element={<RootRoute />} errorElement={<RootErrorBoundary />}>
      {/* private routes */}
      <Route element={<AuthenticateRoute />}>
        {/* render with sidebar section */}
        <Route element={<SidebarLayout />}>
          <Route index={true} element={<DashboardPage />} />
          <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
          <Route path={ROUTES.ORDER_ENTRY} element={<OrderEntryLookupPage />} />
          <Route path={ROUTES.NEW_ORDER} element={<OrderEntryBundleSplitPage />} />
          <Route path={ROUTES.UPDATE_ORDER} element={<OrderEntryBundleSplitPage />} />
          <Route path={ROUTES.INVOICE_ORDER_LOOKUP} element={<InvoiceOrderLookupPage />} />
          <Route
            path={ROUTES.ORDER_DETAIL}
            element={
              <CaseDetailModuleProvider>
                <OrderDetailPage />
              </CaseDetailModuleProvider>
            }
          />
          <Route path={ROUTES.SEARCH_PATH} element={<SearchLookupPage />} />
          <Route path={ROUTES.SEARCH_CASE_PATH} element={<SearchLookupPage />} />
        </Route>
        <Route
          path={ROUTES.INVOICE_CREATE}
          element={
            <InvoicingDetailModuleProvider>
              <InvoicingPage />
            </InvoicingDetailModuleProvider>
          }
        />
      </Route>

      {/* public routes */}
      <Route>
        <Route path={ROUTES.SIGN_IN_PATH}>
          <Route index={true} element={<SignInWithSSO />} />
          <Route path={ROUTES.SIGN_IN_SSO_PATH} element={<SignInWithSSO />} />
          <Route path={ROUTES.SIGN_IN_EMAIL_PATH} element={<SignInWithEmail />} />
        </Route>
        <Route path={ROUTES.SIGN_OUT_PATH} element={<SignOutPage />} />
        <Route path={ROUTES.REDIRECT_PATH} element={<SignInRedirectPage />} />
        <Route path={'*'} element={<NotFoundPage />} />
      </Route>
    </Route>
  )
);
